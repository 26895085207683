import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { loader } from 'graphql.macro';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import ReusableTable from '../../../../../shared/table/table';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import HeaderRow from '../../../../../shared/table/headerRow';
import ImagesModal from '../../../../../shared/imagesModal/imagesModal';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';

import PeriodFilter from '../../../../../shared/analyticsComponents/periodFilter';
import InquiryDetailsInformation from '../../components/inquiryDetailsInformation';
import {
  getInquiriesTableFilterableColumns,
  inquiriesTableColumns,
} from '../../../../../shared/utils/constants';
import { getLocalDate, checkIfMomentDateIsValid } from '../../../../../shared/utils';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';

const allInquiries = loader(
  '../../../../../graphql/queries/operation_management/all_inquiries.graphql',
);
const inquiryQuery = loader('../../../../../graphql/queries/operation_management/inquiry.graphql');

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    minHeight: 'calc(100% - 74px)',
    width: '100%',
  },
  headerButtonArea: {
    marginLeft: 16,
    alignItems: 'center',
    height: '100%',
  },
  routeDescriptionContainer: {
    backgroundColor: theme.variables.VHGreyBackground,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
  },
  descriptionTitle: {
    color: theme.variables.cTextDark,
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '32px',
    userSelect: 'none',
    marginBottom: '24px',
  },
  topButtonGroupContainer: {
    marginRight: 16,
    marginTop: -6,
  },
  btn: {
    fontSize: 17,
    textTransform: 'none',
    boxShadow: theme.variables.defaultBoxShadow,
    borderRadius: 32.5,
    paddingTop: 10.5,
    paddingBottom: 10.5,
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 16,
  },
  priorityContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  priorityIndicator: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 8,
  },
}));

const PRIORITY_COLORS = {
  critical: '#DD2C2C',
  high: '#EC6B6B',
  medium: '#E8B46F',
  low: '#EFE169',
  none: '#C0C0C0',
};

const Inquiries = ({ userLayoutConfig, handleConfigChange, activeProjects }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [period, setPeriod] = useState('next_month');
  const [availableDays] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [selectedInquiries, setSelectedInquiries] = useState([]);
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const [endDate, setEndDate] = useState(moment().add(1, 'months').endOf('month'));
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [searchString, setSearchString] = useState('');

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    inquiriesTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );

  const detailsSerializer = ({ inquiry }) => ({
    id: inquiry.id,
    issueType: inquiry.issueType,
    wasteFraction:
      inquiry?.wasteFraction?.wasteTypes?.edges
        ?.map((wasteType) => wasteType?.node?.name)
        .join(', ') || '',
    wasteCategory: inquiry?.wasteFraction?.wasteCategory,
    wasteVolume: inquiry.wasteVolume,
    priority: (
      <span className={classes.priorityContainer}>
        <span
          className={classes.priorityIndicator}
          style={{
            backgroundColor: PRIORITY_COLORS[inquiry.priority],
          }}
        />
        {inquiry.priority}
      </span>
    ),
    deadline: getLocalDate(inquiry.deadline),
    description: inquiry.description,
    images: inquiry.images?.edges.map((imageEdge) => imageEdge.node.image),
    locationOfIncident: inquiry.location,
    contactName: inquiry.name,
    contactPhone: inquiry.phone,
    address: inquiry?.contactAddress?.address,
  });

  const [getRouteDetails] = useLazyQuery(inquiryQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => setSelectedInquiry(detailsSerializer(data)),
  });

  const extraFilter = useMemo(
    () => (
      <Grid container className={classes.headerButtonArea}>
        <FormControl className={classNames(classes.formControl, classes.dateSelector)}>
          <PeriodFilter
            availableDays={availableDays}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            period={period}
            setPeriod={setPeriod}
            futurePeriod
          />
        </FormControl>
      </Grid>
    ),
    [
      classes.headerButtonArea,
      classes.formControl,
      classes.dateSelector,
      availableDays,
      startDate,
      endDate,
      period,
    ],
  );
  const queryExtraVariables = useMemo(
    () => ({
      search: searchString,
      startDate: checkIfMomentDateIsValid(startDate),
      endDate: checkIfMomentDateIsValid(endDate),
      activeProjects,
    }),
    [searchString, startDate, endDate, activeProjects],
  );
  const updateCache = useCallback(async () => {
    //  ToDo remove obj from cache
  }, []);

  const inquiriesSerializer = ({ allInquiries: { edges: arr = [], totalCount = 0 } = {} }) => ({
    totalCount,
    items: arr.map(({ node }) => ({
      id: node.id,
      issueType: node.issueType,
      wasteFraction: `${
        node?.wasteFraction?.wasteTypes?.edges
          ?.map((wasteType) => wasteType?.node?.name)
          .join(', ') || ''
      } - ${node?.wasteFraction?.wasteCategory || ''}`,
      wasteVolume: node.wasteVolume,
      priority:
        (
          <span className={classes.priorityContainer}>
            <span
              className={classes.priorityIndicator}
              style={{
                backgroundColor: PRIORITY_COLORS[node.priority],
              }}
            />
            {node.priority}
          </span>
        ) || '-',

      deadline: getLocalDate(node.deadline) || '-',
    })),
  });

  const cellsConfig = [
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getInquiriesTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'issueType',
      label: <FormattedMessage id="case_type" defaultMessage="Case type" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'wasteFraction',
      label: <FormattedMessage id="waste_fraction" defaultMessage="Waste fraction" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'wasteVolume',
      label: <FormattedMessage id="size_estimate" defaultMessage="Size estimate" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'priority',
      label: <FormattedMessage id="priority" defaultMessage="Priority" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'deadline',
      label: <FormattedMessage id="deadline" defaultMessage="Deadline" />,
      numeric: false,
      disablePadding: false,
    },
  ];

  const pageTitle = intl.formatMessage({
    id: 'inquiries',
    defaultMessage: 'Inquiries',
  });

  const selectTableRowCallback = (inquiryId) =>
    inquiryId
      ? getRouteDetails({
          variables: {
            id: inquiryId,
          },
        })
      : setSelectedInquiry(null);

  const selectCheckboxCallback = (inquiryIds) => {
    setSelectedInquiries(inquiryIds);
    // TODO suggested routes
  };

  const headCellsToDisplay = cellsConfig.filter((cell) =>
    activeTableColumns.some((tableColumn) => tableColumn === cell.id),
  );

  const handleImagesModalClose = () => setIsImagesModalVisible(false);
  return (
    <Grid container className={classes.pageContainer}>
      <div className="w-100">
        <HeaderRow pageTitle={pageTitle}>
          <div className={classes.topButtonGroupContainer}>
            <Button
              disabled={!selectedInquiries?.length}
              type="button"
              variant="outlined"
              color="primary"
              className={classes.btn}
            >
              <FormattedMessage id="suggested_routes" defaultMessage="Suggested routes" />
            </Button>
            <Button
              disabled={!selectedInquiries?.length}
              type="button"
              variant="outlined"
              color="primary"
              className={classes.btn}
            >
              <FormattedMessage id="new_routes" defaultMessage="New route" />
            </Button>
          </div>
        </HeaderRow>
      </div>
      <div className={classes.contentContainer}>
        <Grid item xs={8}>
          <ReusableTable
            userLayoutConfig={userLayoutConfig}
            updateUserConfig={handleConfigChange}
            cellsConfig={headCellsToDisplay}
            autoCompleteQuery={allInquiries}
            queryExtraVariables={queryExtraVariables}
            extraFilter={extraFilter}
            // TODO: add delete mutation
            deleteItemMutation={() => {}}
            query={allInquiries}
            itemsSerializer={inquiriesSerializer}
            updateCache={updateCache}
            chooseLogoSize={false}
            noLogoLink
            searchSize={3}
            tableContainerStyles={{ padding: '0 32px 32px 32px' }}
            tableComponentWrapperStyles={{ paddingTop: '20px' }}
            selectTableRowCallback={selectTableRowCallback}
            selectCheckboxCallback={selectCheckboxCallback}
            activeTableColumns={activeTableColumns}
            setSearchString={setSearchString}
            tableFontSize={14}
            paginationClass="all-routes-pagination"
            isSearchAutocomplete={false}
            queryName="allInquiries"
            noActionMenu
          />
        </Grid>
        <Grid item xs={4} className={classes.routeDescriptionContainer}>
          <Typography className={classes.descriptionTitle}>
            <FormattedMessage id="description" defaultMessage="Description" />
          </Typography>
          <InquiryDetailsInformation
            selectedInquiry={selectedInquiry}
            openImagDetails={setIsImagesModalVisible}
          />
        </Grid>
      </div>
      <ImagesModal
        isOpen={isImagesModalVisible}
        onClose={handleImagesModalClose}
        images={selectedInquiry?.images}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  activeProjects: state.settings.activeProjects.map((e) => e.id),
});

Inquiries.propTypes = {
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  activeProjects: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default connect(mapStateToProps)(withUserLayoutConfig('inquiry')(Inquiries));
