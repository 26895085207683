import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { connect, getIn } from 'formik';
import { makeStyles } from '@material-ui/core';
import {
  formikComponentMemoizeFieldCheck,
  formikInjectedPropsTypes,
  memoizeFields,
  StyledErrorMessage,
} from './formik';
import HourPickerRow from './HourPickerRow';

const useStyles = makeStyles({
  errorMessageSpacer: {
    marginRight: 8,
  },
});

const ToggledHourPicker = ({ name, format12, formik, disabled }) => {
  const classes = useStyles();
  const selected = getIn(formik.values, name);
  const labelModifierOffset = format12 ? 2 : 0;
  const { setFieldValue } = formik;

  const handleChange = useCallback(
    (event, newValue) => {
      if (disabled) {
        return;
      }
      if (selected.indexOf(newValue) >= 0) {
        setFieldValue(
          name,
          selected.filter((value) => value !== newValue),
        );
      } else {
        const newSelected = [...selected];
        newSelected.push(newValue);
        setFieldValue(name, newSelected);
      }
    },
    [name, disabled, selected, setFieldValue],
  );

  return (
    <Grid container spacing={1}>
      <HourPickerRow
        format12={format12}
        marker="Am"
        valueModifier={0}
        labelModifier={0}
        selected={selected}
        handleChange={handleChange}
      />
      <HourPickerRow
        format12={format12}
        valueModifier={1}
        labelModifier={1}
        selected={selected}
        handleChange={handleChange}
      />
      <HourPickerRow
        format12={format12}
        marker="Pm"
        valueModifier={2}
        labelModifier={2 - labelModifierOffset}
        selected={selected}
        handleChange={handleChange}
      />
      <HourPickerRow
        format12={format12}
        valueModifier={3}
        labelModifier={3 - labelModifierOffset}
        selected={selected}
        handleChange={handleChange}
      />
      <Grid item xs={1} className={classes.errorMessageSpacer} />
      <StyledErrorMessage name={name} />
    </Grid>
  );
};

ToggledHourPicker.propTypes = {
  format12: PropTypes.bool,
  name: PropTypes.string.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  disabled: PropTypes.bool,
};

ToggledHourPicker.defaultProps = {
  format12: false,
  disabled: false,
};

export default connect(
  React.memo(
    ToggledHourPicker,
    memoizeFields(['name', 'format12', formikComponentMemoizeFieldCheck()]),
  ),
);
