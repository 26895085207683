import React from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useIntl } from 'react-intl';

import useGetActiveTab from '../../../../shared/hooks/useGetActiveTab';

const useStyles = makeStyles((theme) => ({
  tabs: {
    '& > div': {
      '& > .MuiTabs-indicator': {
        top: 'inherit',
        bottom: 0,
        backgroundColor: theme.variables.cOrange,
        height: '2',
      },
    },
  },
  tab: {
    textTransform: 'none',
    minHeight: 68,
  },
}));

const HeaderTab = ({ match }) => {
  const classes = useStyles();
  const intl = useIntl();

  const tab = useGetActiveTab(match, 'collector-vehicle');

  const collectorVehicleListString = intl.formatMessage({
    id: 'collector_vehicle',
    defaultMessage: 'Collector Vehicle',
  });
  const vehicleTypeString = intl.formatMessage({
    id: 'vehicle_type',
    defaultMessage: 'Vehicle Type',
  });

  return (
    <Grid>
      <Tabs
        value={tab}
        aria-label="vehicle tab"
        textColor="secondary"
        indicatorColor="secondary"
        className={classes.tabs}
      >
        <Tab
          label={collectorVehicleListString}
          className={classes.tab}
          value="collector-vehicle"
          component={Link}
          to="/app/fleet-management/collector-vehicle"
          tourid="collectorVehicleOverview"
        />
        <Tab
          label={vehicleTypeString}
          className={classes.tab}
          value="vehicle-type"
          component={Link}
          to="/app/fleet-management/vehicle-type"
          tourid="vehicleTypeOverview"
        />
      </Tabs>
    </Grid>
  );
};

HeaderTab.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(HeaderTab);
