import * as Yup from 'yup';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TYPE_OF_COST_VALUES } from '../../components/utils';
import { maxLengthStringMessage, messageNoNegativeValues } from '../../../../../shared/utils';

const driverEditValidationSchema = () =>
  Yup.object().shape({
    // Identification
    userId: Yup.string().required(),
    email: Yup.string()
      .required()
      .email(<FormattedMessage id="validation.invalid_email" defaultMessage="Invalid email" />),
    employeeNumber: Yup.string().max(255, maxLengthStringMessage(255)),
    phone: Yup.string()
      .required()
      .transform((phoneNumberString) => phoneNumberString.replace(/\s|\(|\)/g, ''))
      .test(
        'len',
        <FormattedMessage
          id="validation.number.invalid"
          defaultMessage="Please enter valid number."
        />,
        (val) => {
          if (!val || val === '+') {
            return true;
          }
          return /^\+[1-9]{1}[0-9]{3,14}$/.test(val);
        },
      ),
    vehicleId: Yup.string().required(),
    logo: Yup.string(),

    // Work schedule
    workTimeStart: Yup.mixed().time().required(),
    workTimeEnd: Yup.mixed()
      .required()
      .time()
      .timeGreaterThen('workTimeStart')
      .when('workTimeStart', {
        is: (workTimeStart) => !!workTimeStart,
        then: (schema) => schema.required(),
      }),
    allowedOvertime: Yup.string(),
    allowedMaximumWeeklyHours: Yup.number()
      .required()
      .positive(messageNoNegativeValues)
      .test(
        '',
        <FormattedMessage id="not_valid_hours" defaultMessage="Not valid amount of hours" />,
        (value) => value <= 168,
      ),
    allowedMaximumDailyHours: Yup.number()
      .required()
      .positive(messageNoNegativeValues)
      .test(
        '',
        <FormattedMessage id="not_valid_hours" defaultMessage="Not valid amount of hours" />,
        (value) => value <= 24,
      ),
    scheduleBreak: Yup.bool(),
    breakTimeStart: Yup.mixed()
      .time()
      .timeGreaterThen(
        'workTimeStart',
        <FormattedMessage
          id="validation.breakStart.ValidationGreater"
          defaultMessage="Break should start after work time begins"
        />,
      )
      .timeLesserThen(
        'workTimeEnd',
        <FormattedMessage
          id="validation.breakStart.ValidationLesser"
          defaultMessage="Break should end before work time ends"
        />,
      ),
    breakTimeEnd: Yup.mixed()
      .time()
      .timeGreaterThen('breakTimeStart')
      .timeLesserThen(
        'workTimeEnd',
        <FormattedMessage
          id="validation.breakEnd.ValidationGreater"
          defaultMessage="Break should end before work time ends"
        />,
      )
      .when('breakTimeStart', {
        is: (breakTimeStart) => !!breakTimeStart,
        then: (schema) => schema.required(),
      }),
    breakDuration: Yup.string(),
    daysAvailable: Yup.array().of(Yup.string()).required(),

    // Driver cost
    typeOfCost: Yup.string().required(),
    costPerInterval: Yup.number().when('typeOfCost', {
      is: TYPE_OF_COST_VALUES.fixed,
      then: Yup.number().required().positive(messageNoNegativeValues),
    }),
    paymentInterval: Yup.string().when('typeOfCost', {
      is: TYPE_OF_COST_VALUES.fixed,
      then: Yup.string().required(),
    }),
    costPerHour: Yup.number().when('typeOfCost', {
      is: TYPE_OF_COST_VALUES.variable,
      then: Yup.number().required(),
    }),
    costPerHourForOvertime: Yup.number().when('typeOfCost', {
      is: TYPE_OF_COST_VALUES.variable,
      then: Yup.number().required().positive(messageNoNegativeValues),
    }),

    // Driver permission
    licenseClass: Yup.string().required(),
    number: Yup.string().required().max(255, maxLengthStringMessage(255)),
    region: Yup.string().required(),
    validFrom: Yup.mixed().required().time(),
    validTo: Yup.mixed().required().timeGreaterThen('validFrom'),

    // Driver skills
    skills: Yup.array().of(
      Yup.object().shape({
        code: Yup.string(),
        validFrom: Yup.mixed()
          .time()
          .when('code', {
            is: (code) => !!code,
            then: (schema) => schema.required(),
          }),
        validTo: Yup.mixed().time().timeGreaterThen('validFrom'),
      }),
    ),

    // Driver logistic
    startLocationId: Yup.string(),
    endLocationId: Yup.string(),
  });

export default driverEditValidationSchema;
