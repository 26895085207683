import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import BaseFormikInput from './BaseFormikInput';
import CustomCheckbox from '../StyledCheckbox';
import FormikField from './FormikField';
import FormikFastField from './FormikFastField';

const useStyles = makeStyles({
  menuItem: {
    maxWidth: '50vw',
    display: 'block',
    '&:hover': {
      whiteSpace: 'pre-wrap',
    },
  },
});

const FormikSelect = ({
  name,
  required,
  label,
  placeholder,
  valuesList,
  id,
  readOnly,
  multiSelect,
  className,
  FieldProps,
  onChange,
  filledStyle,
  classes,
  onOptionClick,
  fast,
  withoutNoneChoice,
  labelClass,
  fieldClass,
  disabled,
  ...otherProps
}) => {
  const Field = fast ? FormikFastField : FormikField;
  const selectClasses = useStyles();
  return (
    <BaseFormikInput
      name={name}
      id={id}
      filledStyle={filledStyle}
      onChange={onChange}
      required={required}
      className={className}
    >
      {({ classes: styles, isError, inputId, value }) => (
        <>
          {label && (
            <InputLabel
              required={required}
              error={isError}
              id={`label-${inputId}`}
              shrink
              style={{ zIndex: 9 }}
              classes={{
                root: labelClass,
              }}
            >
              {label}
            </InputLabel>
          )}
          <Field
            id={inputId}
            component={TextField}
            select
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            className={classNames({ [styles.selectEmpty]: !filledStyle }, fieldClass)}
            inputProps={{
              readOnly,
            }}
            SelectProps={{
              labelId: `label-${inputId}`,
              displayEmpty: true,
              multiple: multiSelect,
              /* eslint-disable react/destructuring-assignment */
              renderValue: (renderValue) => {
                if (Array.isArray(renderValue) && renderValue.length) {
                  return renderValue.map((val, index) => {
                    const choice = valuesList.find((item) => val === item.value);
                    return (
                      <React.Fragment key={val}>
                        {choice?.label}
                        {index < renderValue.length - 1 && <>, &nbsp;</>}
                      </React.Fragment>
                    );
                  });
                }
                if (renderValue) {
                  const choice = valuesList.find((item) => renderValue === item.value);
                  if (choice) {
                    return choice.label;
                  }
                }
                return <span className={styles.placeholder}>{placeholder}</span>;
              },
              MenuProps: {
                classes: {
                  paper: classNames(styles.paper, classes.paper, {
                    [styles.filledPaper]: filledStyle,
                  }),
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
            {...FieldProps}
            {...otherProps}
          >
            {!required && !withoutNoneChoice && (
              <MenuItem value="" className={selectClasses.menuItem}>
                <em>
                  <FormattedMessage id="select.blank" defaultMessage="None" />
                </em>
              </MenuItem>
            )}
            {valuesList.map((choice) => {
              const choices = [];
              choices.push(
                <MenuItem
                  key={choice.value}
                  value={choice.value}
                  disabled={choice.disabled}
                  onClick={onOptionClick}
                  className={classNames([selectClasses.menuItem, choice?.className])}
                >
                  {multiSelect && (
                    <CustomCheckbox
                      color="primary"
                      checked={!!value.find((item) => item === choice.value)}
                    />
                  )}
                  {choice.label}
                </MenuItem>,
              );
              if (choice.options && choice.options.length) {
                choice.options.forEach((option) => {
                  choices.push(
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                      className={selectClasses.menuItem}
                      onClick={onOptionClick}
                      // className={classes.menuSubItem}
                    >
                      {multiSelect && (
                        <CustomCheckbox
                          color="primary"
                          checked={!!value.find((item) => item === option.value)}
                        />
                      )}
                      {option.label}
                    </MenuItem>,
                  );
                });
              }
              return choices;
            })}
          </Field>
        </>
      )}
    </BaseFormikInput>
  );
};

FormikSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  required: PropTypes.bool,
  fast: PropTypes.bool,
  filledStyle: PropTypes.bool,
  multiSelect: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  valuesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      className: PropTypes.string,
    }),
  ).isRequired,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  FieldProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  ),
  onOptionClick: PropTypes.func,
  withoutNoneChoice: PropTypes.bool,
  labelClass: PropTypes.string,
  fieldClass: PropTypes.string,
  disabled: PropTypes.bool,
};

FormikSelect.defaultProps = {
  required: false,
  fast: false,
  multiSelect: false,
  label: '',
  placeholder: '',
  readOnly: false,
  filledStyle: false,
  className: '',
  id: '',
  FieldProps: {},
  classes: {},
  onChange: () => {},
  onOptionClick: undefined,
  withoutNoneChoice: false,
  labelClass: '',
  fieldClass: '',
  disabled: false,
};

export default FormikSelect;
