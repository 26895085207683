import React, { useState, useMemo, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MomentPropTypes from 'react-moment-proptypes';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import queryString from 'query-string';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingLayout from '../loading';
import { toastifyError, getWasteFractionFromContainer } from '../utils';
import FilterRow from '../table/filterRow';
import StyledTablePagination from '../table/pagination';
import ConditionalScrollbar from '../conditionalScrollabar/ConditionalScrollbar';
import StyledCheckbox from '../inputs/StyledCheckbox';
import { StyledTableRow, StyledTableCell, StyledTableHead } from './styledComponents';
import {
  generateColumnsForPeriodSteps,
  groupByPeriod,
  getPeriodSteps,
  generatePeriod,
} from './utils';
import withUserLayoutConfig from '../hoc/WithUserLayoutConfig';
import FilledSelect from '../inputs/FilledSelect';
import Address from '../google/location';

const AnalyticsTableLayout = React.forwardRef(
  (
    {
      query,
      savedActiveProjects,
      autocompleteSerializer,
      autoCompleteQuery,
      userLayoutConfig,
      handleConfigChange,
      searchSize,
      paginationClass,
      isSearchAutocomplete,
      leftSideHeaderElements,
      allRowsEnabled,
      isRedirectOnSelect,
      tableComponentWrapperStyles,
      tableContainerClass,
      displayScrollbar,
      multiSelectable,
      startDate,
      endDate,
      classes,
      fractions,
      containerTypes,
      currencyCoef,
      currencySymbol,
      tableRef,
    },
    ref,
  ) => {
    const intl = useIntl();
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [filteredContainerTypes, setFilteredContainerTypes] = useState([]);
    const [filteredWasteFractions, setFilteredWasteFractions] = useState([]);
    const [collectionAnalytics, setCollectionAnalytics] = useState([]);
    const [containers, setContainers] = useState([]);

    const { order, orderBy, pageSize } = userLayoutConfig;

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      handleConfigChange({
        ...userLayoutConfig,
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      });
    };

    const columns = useMemo(
      () => [
        {
          field: 'containerId',
          label: true,
          align: 'left',
          title: <FormattedMessage id="container_id" defaultMessage="Container ID" />,
          rowSpan: 3,
          order: true,
        },
        {
          field: 'location',
          label: true,
          align: 'left',
          title: <FormattedMessage id="location" defaultMessage="Location" />,
          rowSpan: 3,
          className: classes.wrapDescription,
          order: false,
        },
        {
          field: 'containerType',
          label: true,
          align: 'left',
          title: <FormattedMessage id="container_type" defaultMessage="Container type" />,
          rowSpan: 3,
          className: classes.wrapDescription,
          order: true,
        },
        {
          field: 'wasteType',
          label: true,
          align: 'left',
          title: <FormattedMessage id="waste_fraction" defaultMessage="Waste Fraction" />,
          rowSpan: 3,
          order: true,
        },
        {
          field: 'numberOfOverflowings',
          label: true,
          align: 'left',
          title: (
            <FormattedMessage id="numbers_of_overflowing" defaultMessage="Numbers of overflowing" />
          ),
          rowSpan: 3,
          order: true,
        },
        {
          field: 'numberOfEmptyings',
          label: true,
          align: 'left',
          title: (
            <FormattedMessage id="numbers_of_emptyings" defaultMessage="Numbers of emptyings" />
          ),
          rowSpan: 3,
          order: true,
        },
        {
          field: 'avgCollectionEfficiency',
          label: true,
          align: 'left',
          title: (
            <FormattedMessage
              id="avg_collection_efficiency"
              defaultMessage="Avg. collection efficiency"
            />
          ),
          rowSpan: 3,
          order: true,
        },
        {
          field: 'totalCost',
          label: true,
          align: 'left',
          title: <FormattedMessage id="total_cost" defaultMessage="Total cost" />,
          rowSpan: 3,
          order: true,
        },
        {
          field: 'estimatedSavings',
          label: true,
          align: 'left',
          title: <FormattedMessage id="estimated_savings" defaultMessage="Estimated savings" />,
          rowSpan: 3,
          order: true,
        },
      ],
      [classes.wrapDescription],
    );

    const location = useLocation();
    const realPageSize = pageSize > 0 ? pageSize : null;
    const offset = realPageSize > 0 ? realPageSize * page : null;
    const isAllSelected = selected?.indexOf('*') !== -1;
    const isSelected = (id) => selected.indexOf(id) !== -1 || isAllSelected;

    const periodSteps = useMemo(() => generatePeriod(startDate, endDate, getPeriodSteps), [
      endDate,
      startDate,
    ]);

    const columnsData = useMemo(() => generateColumnsForPeriodSteps(columns, periodSteps), [
      periodSteps,
      columns,
    ]);

    const queryFilter = useMemo(() => (queryString.parse(location.search) || {}).query, [
      location.search,
    ]);

    const queryExtraVariables = useMemo(() => {
      const variables = {
        name: searchString,
        activeProjects: savedActiveProjects,
      };
      if (filteredContainerTypes.length) {
        variables.containerTypes = filteredContainerTypes;
      }
      if (filteredWasteFractions.length) {
        let ungroupedWasteFractions = [];
        filteredWasteFractions.forEach((item) => {
          let wFraction = item;
          if (wFraction.includes(',')) {
            wFraction = wFraction.split(',');
          }
          ungroupedWasteFractions = ungroupedWasteFractions.concat(wFraction);
        });
        variables.wasteTypes = ungroupedWasteFractions;
      }
      return variables;
    }, [searchString, savedActiveProjects, filteredWasteFractions, filteredContainerTypes]);

    const queryVariables = useMemo(
      () => ({
        name: queryFilter,
        ...queryExtraVariables,
        pageSize: realPageSize,
        From: startDate,
        To: endDate,
        offset,
        orderBy,
        order,
      }),
      [queryFilter, queryExtraVariables, realPageSize, offset, orderBy, order, endDate, startDate],
    );

    const variablesForAutocomplete = useMemo(() => {
      const { name, ...rest } = queryVariables;
      return rest;
    }, [queryVariables]);

    const extraFilter = useMemo(
      () => (
        <Grid container item xs={10} spacing={2} justify="flex-start">
          <Grid item xs={4}>
            <FilledSelect
              name="containerTypes"
              placeholder={intl.formatMessage({
                id: 'notificationSettings.containerTypes',
                defaultMessage: 'Container types',
              })}
              valuesList={containerTypes}
              required
              multiSelect
              value={filteredContainerTypes}
              className={classes.selectFilter}
              onChange={(e) => setFilteredContainerTypes(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FilledSelect
              name="fractions"
              placeholder={intl.formatMessage({
                id: 'notificationSettings.fractionFilter',
                defaultMessage: 'Waste fractions',
              })}
              valuesList={fractions}
              required
              multiSelect
              value={filteredWasteFractions}
              className={classes.selectFilter}
              onChange={(e) => setFilteredWasteFractions(e.target.value)}
            />
          </Grid>
        </Grid>
      ),
      [
        classes.selectFilter,
        intl,
        fractions,
        containerTypes,
        filteredContainerTypes,
        filteredWasteFractions,
      ],
    );

    const { loading: itemsLoading, data: itemsData = {} } = useQuery(query, {
      onError: toastifyError,
      suspend: false,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first',
      variables: queryVariables,
      onCompleted: (data) => {
        setLoading(true);
        setCollectionAnalytics(() => {
          const serializedData = data.allContainers.edges.map(({ node: container }) => ({
            id: container.id,
            containerId: container.containerId,
            location: (
              <Address
                placeId={container.location.placeId}
                lng={container.location.longitude}
                lat={container.location.latitude}
              />
            ),
            containerType: container.containerType.name,
            wasteType: getWasteFractionFromContainer(container),
            numberOfEmptyings: container.totalCollections,
            numberOfOverflowings: container.totalOverflowings,
            avgCollectionEfficiency: `${container.currentEffective?.toFixed(0) || '-'}%`,
            totalCost: `${((container.totalCost || 0) * currencyCoef).toFixed(
              0,
            )} ${currencySymbol}`,
            estimatedSavings: `${container.estimatedSavings.toFixed(0)} ${currencySymbol}`,
          }));
          setLoading(false);
          return serializedData;
        });
      },
    });

    const handleClick = (event, id) => {
      event.stopPropagation();
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
      if (!multiSelectable) {
        setSelected([id]);
        return;
      }

      if (selected.indexOf('*') === 0) {
        const selectedIndexes = [];
        containers.forEach(
          (tableItem) => tableItem.id !== id && selectedIndexes.push(tableItem.id),
        );
        newSelected = selectedIndexes;
      } else if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    };

    const totalCount = itemsData.allContainers?.totalCount;
    const numSelected = isAllSelected ? totalCount : selected.length;

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        // Abstract value for backed realization select all
        setSelected(['*']);
        return;
      }
      setSelected([]);
    };

    useEffect(() => {
      setContainers(
        collectionAnalytics.map((container) => ({
          ...container,
          ...groupByPeriod(periodSteps, container.logs),
        })),
      );
    }, [periodSteps, collectionAnalytics]);

    useEffect(() => {
      setPage(0);
    }, [queryExtraVariables]);

    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };

    return (
      <div className={classNames(classes.root, 'h-100')} style={tableComponentWrapperStyles}>
        <Grid container className="h-100" ref={tableRef}>
          <LoadingLayout isLoading={loading || itemsLoading} />
          <FilterRow
            query={autoCompleteQuery}
            serializer={autocompleteSerializer}
            queryExtraVariables={variablesForAutocomplete}
            searchSize={searchSize}
            paginationClass={paginationClass}
            setSearchString={setSearchString}
            isSearchAutocomplete={isSearchAutocomplete}
            isRedirectOnSelect={isRedirectOnSelect}
            iconSize={null}
            pagination={
              <StyledTablePagination
                page={page}
                totalCount={itemsData.allContainers?.totalCount || 0}
                rowsPerPage={pageSize}
                setPage={setPage}
                allRowsEnabled={allRowsEnabled}
                setRowsPerPage={(newPageSize) =>
                  handleConfigChange({
                    ...userLayoutConfig,
                    pageSize: newPageSize,
                  })
                }
              />
            }
            leftSideElements={leftSideHeaderElements}
            searchFieldClass={classes.searchField}
            rootClassName={classes.tableFilterContainer}
          >
            {extraFilter}
          </FilterRow>
          <TableContainer
            className={classNames([classes.container, tableContainerClass, 'containers'])}
          >
            <ConditionalScrollbar displayScrollbar={displayScrollbar} ref={ref}>
              <Table stickyHeader aria-label="location list">
                <StyledTableHead>
                  {columnsData.headerRows.map((columnRow, rowIndex) => (
                    <StyledTableRow key={columnRow.id}>
                      {columnRow.row.map((column, columnIndex) => (
                        <>
                          {rowIndex === 0 && columnIndex === 0 && (
                            <StyledTableCell key="checkbox" padding="checkbox" rowSpan={3}>
                              <StyledCheckbox
                                indeterminate={numSelected > 0 && numSelected < totalCount}
                                checked={totalCount > 0 && numSelected === totalCount}
                                onChange={handleSelectAllClick}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            key={column.field}
                            align={column.align || 'center'}
                            rowSpan={column.rowSpan}
                            colSpan={column.colSpan}
                            style={{ ...column.style, fontSize: '11px', height: 24 }}
                            data-fixed={column.fixed}
                          >
                            {column.order ? (
                              <TableSortLabel
                                active={orderBy === column.field}
                                direction={orderBy === column.field ? order : 'asc'}
                                onClick={createSortHandler(column.field)}
                              >
                                <Typography variant="inherit">{column.title}</Typography>
                                {orderBy === column.field ? (
                                  <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            ) : (
                              <Typography variant="inherit">{column.title}</Typography>
                            )}
                          </StyledTableCell>
                        </>
                      ))}
                    </StyledTableRow>
                  ))}
                </StyledTableHead>
                <TableBody>
                  {containers.map((item, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const isItemSelected = isSelected(item.id);
                    return (
                      <StyledTableRow key={item.id} tabIndex={-1}>
                        <StyledTableCell padding="checkbox">
                          <StyledCheckbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, item.id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </StyledTableCell>
                        {columnsData.dynamicColumns.map((column) => {
                          const value = column.field in item ? item[column.field] : '-';
                          return (
                            <StyledTableCell
                              variant="body"
                              key={column.field}
                              align={column.align}
                              className={column.className || ''}
                            >
                              {column.render ? column.render(value) : value}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </ConditionalScrollbar>
          </TableContainer>
        </Grid>
      </div>
    );
  },
);

AnalyticsTableLayout.propTypes = {
  displayScrollbar: PropTypes.bool,
  multiSelectable: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  query: PropTypes.objectOf(PropTypes.any).isRequired,
  savedActiveProjects: PropTypes.string,
  autocompleteSerializer: PropTypes.func,
  autoCompleteQuery: PropTypes.objectOf(PropTypes.any).isRequired,
  startDate: MomentPropTypes.momentObj.isRequired,
  endDate: MomentPropTypes.momentObj.isRequired,
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string,
  }),
  allRowsEnabled: PropTypes.bool,
  searchSize: PropTypes.number,
  tableComponentWrapperStyles: PropTypes.objectOf(PropTypes.string),
  paginationClass: PropTypes.string,
  isSearchAutocomplete: PropTypes.bool,
  leftSideHeaderElements: PropTypes.node,
  tableContainerClass: PropTypes.string,
  isRedirectOnSelect: PropTypes.bool,
  fractions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  containerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  currencyCoef: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  tableRef: PropTypes.func.isRequired,
};

AnalyticsTableLayout.defaultProps = {
  displayScrollbar: false,
  multiSelectable: false,
  savedActiveProjects: '',
  classes: {},
  autocompleteSerializer: () => [],
  userLayoutConfig: {
    iconsSize: null,
  },
  searchSize: null,
  tableComponentWrapperStyles: {},
  paginationClass: '',
  isSearchAutocomplete: true,
  leftSideHeaderElements: null,
  tableContainerClass: null,
  allRowsEnabled: true,
  isRedirectOnSelect: true,
  containerTypes: [],
  fractions: [],
};

export default withUserLayoutConfig('collectionContainers')(AnalyticsTableLayout);
