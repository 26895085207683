import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const ContentHeaderContainer = ({ children, contentClass, size }) => (
  <Grid item xs={size}>
    <Typography variant="h4" gutterBottom className={contentClass}>
      {children}
    </Typography>
  </Grid>
);
ContentHeaderContainer.propTypes = {
  children: PropTypes.element.isRequired,
  contentClass: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default ContentHeaderContainer;
