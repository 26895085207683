export const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const DEFAULT_MAP_CENTER = [8.96548, 56.13573];

export const JOB_TITLE_VALUES = {
  administration: 'Administration',
  accountManager: 'Account manager',
  fleetManager: 'Fleet manager',
  service: 'Service',
  driver: 'Driver',
  routePlanner: 'Route planner',
};

export function getJobTitles(intl) {
  return [
    {
      value: JOB_TITLE_VALUES.administration,
      label: intl.formatMessage({
        id: 'jobTitles.administration',
        defaultMessage: 'Administration',
      }),
    },
    {
      value: JOB_TITLE_VALUES.accountManager,
      label: intl.formatMessage({
        id: 'jobTitles.accountManager',
        defaultMessage: 'Account manager',
      }),
    },
    {
      value: JOB_TITLE_VALUES.fleetManager,
      label: intl.formatMessage({ id: 'jobTitles.fleetManager', defaultMessage: 'Fleet manager' }),
    },
    {
      value: JOB_TITLE_VALUES.service,
      label: intl.formatMessage({ id: 'jobTitles.service', defaultMessage: 'Service' }),
    },
    {
      value: JOB_TITLE_VALUES.driver,
      label: intl.formatMessage({ id: 'jobTitles.driver', defaultMessage: 'Driver' }),
    },
    {
      value: JOB_TITLE_VALUES.routePlanner,
      label: intl.formatMessage({ id: 'jobTitles.route_planner', defaultMessage: 'Route planner' }),
    },
  ];
}

export const getRoutesTableFilterableColumns = (intl) => [
  {
    value: 'stopNumber',
    label: intl.formatMessage({
      id: 'stops',
      defaultMessage: 'Stops',
    }),
  },
  {
    value: 'distance',
    label: intl.formatMessage({
      id: 'distance',
      defaultMessage: 'Distance',
    }),
  },
  {
    value: 'startTime',
    label: intl.formatMessage({
      id: 'start_time',
      defaultMessage: 'Starts at',
    }),
  },
  {
    value: 'endTime',
    label: intl.formatMessage({
      id: 'end_time',
      defaultMessage: 'Ends at',
    }),
  },
  {
    value: 'scheduledDay',
    label: intl.formatMessage({
      id: 'scheduled_day',
      defaultMessage: 'Scheduled day',
    }),
  },
];
export const routesTableColumns = [
  'projectName',
  'stopNumber',
  'distance',
  'startTime',
  'endTime',
  'id',
  'vehicleName',
  'driverFullName',
  'actionMenu',
  'scheduledDay',
];

export const getDriversTableFilterableColumns = (intl) => [
  {
    value: 'name',
    label: intl.formatMessage({
      id: 'location_list.name',
      defaultMessage: 'Name',
    }),
  },
  {
    value: 'status',
    label: intl.formatMessage({
      id: 'driver_list.status',
      defaultMessage: 'Status',
    }),
  },
  {
    value: 'collectedBins',
    label: intl.formatMessage({
      id: 'driver_list.collectedBins',
      defaultMessage: 'Collected bins',
    }),
  },
  {
    value: 'vehicle',
    label: intl.formatMessage({
      id: 'driver_list.vehicle',
      defaultMessage: 'Vehicle',
    }),
  },
];

export const driversTableColumns = ['name', 'status', 'collectedBins', 'vehicle', 'actionMenu'];

export const getLocationsTableFilterableColumns = (intl) => [
  {
    value: 'name',
    label: intl.formatMessage({
      id: 'location_list.name',
      defaultMessage: 'Name',
    }),
  },
  {
    value: 'type',
    label: intl.formatMessage({
      id: 'location_list.type',
      defaultMessage: 'Type',
    }),
  },
  {
    value: 'openingHours',
    label: intl.formatMessage({
      id: 'location_list.opening_hours',
      defaultMessage: 'Opening Hours',
    }),
  },
  {
    value: 'contact',
    label: intl.formatMessage({
      id: 'location_list.contact',
      defaultMessage: 'Contact',
    }),
  },
];

export const locationsTableColumns = ['name', 'type', 'openingHours', 'contact', 'actionMenu'];

export const getVehiclesTableFilterableColumns = (intl) => [
  {
    value: 'name',
    label: intl.formatMessage({
      id: 'vehicle.list.vehicleId',
      defaultMessage: 'Vehicle ID',
    }),
  },
  {
    value: 'status',
    label: intl.formatMessage({
      id: 'vehicles.status',
      defaultMessage: 'Status',
    }),
  },
  {
    value: 'type',
    label: intl.formatMessage({
      id: 'vehicles.type',
      defaultMessage: 'Type',
    }),
  },
  {
    value: 'drivers',
    label: intl.formatMessage({
      id: 'vehicles.driver',
      defaultMessage: 'Driver',
    }),
  },
];

export const vehiclesTableColumns = ['name', 'status', 'type', 'drivers', 'actionMenu'];

export const getVehicleTypesTableFilterableColumns = (intl) => [
  {
    value: 'name',
    label: intl.formatMessage({
      id: 'vehicle_types.type_name',
      defaultMessage: 'Type Name',
    }),
  },
  {
    value: 'type',
    label: intl.formatMessage({
      id: 'vehicle_types.type',
      defaultMessage: 'Type',
    }),
  },
  {
    value: 'body',
    label: intl.formatMessage({
      id: 'vehicle_types.body',
      defaultMessage: 'Body',
    }),
  },
  {
    value: 'bodyMechanization',
    label: intl.formatMessage({
      id: 'vehicle_types.body_mechanization',
      defaultMessage: 'Body Mechanization',
    }),
  },
  {
    value: 'liftingMechanization',
    label: intl.formatMessage({
      id: 'vehicle_types.lifting_mechanization',
      defaultMessage: 'Lifting Mechanization',
    }),
  },
  {
    value: 'loadingLocation',
    label: intl.formatMessage({
      id: 'vehicle_types.loading_location',
      defaultMessage: 'Loading Location',
    }),
  },
];

export const vehicleTypesTableColumns = [
  'name',
  'type',
  'body',
  'bodyMechanization',
  'liftingMechanization',
  'loadingLocation',
  'actionMenu',
];

export const getInquiriesTableFilterableColumns = (intl) => [
  {
    value: 'issueType',
    label: intl.formatMessage({
      id: 'case_type',
      defaultMessage: 'Case Type',
    }),
  },
  {
    value: 'wasteFraction',
    label: intl.formatMessage({
      id: 'waste_fraction',
      defaultMessage: 'Waste Fraction',
    }),
  },
  {
    value: 'wasteVolume',
    label: intl.formatMessage({
      id: 'size_estimate',
      defaultMessage: 'Size estimate',
    }),
  },
  {
    value: 'priority',
    label: intl.formatMessage({
      id: 'priority',
      defaultMessage: 'Priority',
    }),
  },
  {
    value: 'deadline',
    label: intl.formatMessage({
      id: 'deadline',
      defaultMessage: 'Deadline',
    }),
  },
];

export const inquiriesTableColumns = [
  'issueType',
  'wasteFraction',
  'wasteVolume',
  'priority',
  'deadline',
  'actionMenu',
];

export const getContainersTableFilterableColumns = (intl) => [
  {
    value: 'state',
    label: intl.formatMessage({
      id: 'sensor_status',
      defaultMessage: 'Sensor status',
    }),
  },
  {
    value: 'projectName',
    label: intl.formatMessage({
      id: 'project_mame',
      defaultMessage: 'Project name',
    }),
  },
  {
    value: 'address',
    label: intl.formatMessage({
      id: 'address',
      defaultMessage: 'Address',
    }),
  },
  {
    value: 'battery',
    label: intl.formatMessage({
      id: 'battery',
      defaultMessage: 'Battery',
    }),
  },
  {
    value: 'rsi',
    label: intl.formatMessage({
      id: 'RSSI',
      defaultMessage: 'RSSI',
    }),
  },
  {
    value: 'fillLevel',
    label: intl.formatMessage({
      id: 'fill_level',
      defaultMessage: 'Fill level',
    }),
  },
  {
    value: 'fillLevelRaw',
    label: intl.formatMessage({
      id: 'fill_level_raw',
      defaultMessage: 'Fill level (raw)',
    }),
  },
  {
    value: 'sensorId',
    label: intl.formatMessage({
      id: 'sensor_id',
      defaultMessage: 'Sensor ID',
    }),
  },
  {
    value: 'description',
    label: intl.formatMessage({
      id: 'description',
      defaultMessage: 'Description',
    }),
  },
  {
    value: 'containerId',
    label: intl.formatMessage({
      id: 'container_id',
      defaultMessage: 'Container ID',
    }),
  },
  {
    value: 'container_type',
    label: intl.formatMessage({
      id: 'container_type',
      defaultMessage: 'Container type',
    }),
  },
  {
    value: 'lastSeenDate',
    label: intl.formatMessage({
      id: 'last_measurement',
      defaultMessage: 'Last measurement',
    }),
  },
  {
    value: 'measurementSettings',
    label: intl.formatMessage({
      id: 'measurement_settings',
      defaultMessage: 'Measurement settings',
    }),
  },
  {
    value: 'wasteType',
    label: intl.formatMessage({
      id: 'waste_fraction',
      defaultMessage: 'Waste Fraction',
    }),
  },
];

export const containersTableColumns = [
  'state',
  'projectName',
  'address',
  'battery',
  'rsi',
  'fillLevel',
  'fillLevelRaw',
  'sensorId',
  'description',
  'containerId',
  'container_type',
  'lastSeenDate',
  'wasteType',
  'actionMenu',
  'measurementSettings',
];

export const getPickupOrdersTableFilterableColumns = (intl) => [
  {
    value: 'containerId',
    label: intl.formatMessage({
      id: 'container_id',
      defaultMessage: 'Container ID',
    }),
  },
  {
    value: 'location',
    label: intl.formatMessage({
      id: 'location',
      defaultMessage: 'Location',
    }),
  },
  {
    value: 'containerTypes',
    label: intl.formatMessage({
      id: 'container_types',
      defaultMessage: 'Container types',
    }),
  },
  {
    value: 'wasteFraction',
    label: intl.formatMessage({
      id: 'waste_fraction',
      defaultMessage: 'Waste Fraction',
    }),
  },
  {
    value: 'collected',
    label: intl.formatMessage({
      id: 'collected',
      defaultMessage: 'Collected',
    }),
  },
  {
    value: 'byWhom',
    label: intl.formatMessage({
      id: 'by_whom',
      defaultMessage: 'By whom',
    }),
  },
];

export const pickupOrdersTableColumns = [
  'containerId',
  'location',
  'containerTypes',
  'wasteFraction',
  'collected',
  'byWhom',
  'actionMenu',
];

export const notificationsTableColumns = [
  'actionMenu',
  'container_id',
  'location',
  'container_type',
  'waste_fraction',
  'condition',
  'threshold',
  'send_to',
];

export const getNotificationsTableFilterableColumns = (intl) => [
  {
    value: 'container_id',
    label: intl.formatMessage({
      id: 'table_head.container_id',
      defaultMessage: 'Container ID',
    }),
  },
  {
    value: 'location',
    label: intl.formatMessage({
      id: 'table_head.location',
      defaultMessage: 'Location',
    }),
  },
  {
    value: 'container_type',
    label: intl.formatMessage({
      id: 'table_head.container_type',
      defaultMessage: 'Container type',
    }),
  },
  {
    value: 'waste_fraction',
    label: intl.formatMessage({
      id: 'table_head.waste_fraction',
      defaultMessage: 'Waste fraction',
    }),
  },
  {
    value: 'condition',
    label: intl.formatMessage({
      id: 'table_head.condition',
      defaultMessage: 'Condition',
    }),
  },
  {
    value: 'threshold',
    label: intl.formatMessage({
      id: 'table_head.threshold',
      defaultMessage: 'Threshold',
    }),
  },
  {
    value: 'send_to',
    label: intl.formatMessage({
      id: 'table_head.send_to',
      defaultMessage: 'Send to',
    }),
  },
];
