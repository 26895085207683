import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontSize: '14px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleText: {
    color: theme.variables.cSlate,
    lineHeight: '16px',
    paddingBottom: theme.spacing(0.5),
  },
  infoText: {
    color: theme.variables.cEmperor,
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
}));

const InfoLabel = ({ title, text }) => {
  const classes = useStyles();

  const textJSX =
    text && React.isValidElement(text) ? (
      text
    ) : (
      <div className={classes.infoText}>{text || '-'}</div>
    );

  return (
    <Grid item>
      <Grid container justify="space-evenly" alignItems="flex-start" className={classes.root}>
        <Grid item xs={12}>
          <div className={classes.titleText}>{title}</div>
        </Grid>
        <Grid item xs={12}>
          {textJSX}
        </Grid>
      </Grid>
    </Grid>
  );
};

InfoLabel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

InfoLabel.defaultProps = {
  text: '',
};

export default InfoLabel;
