import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import RegisterContainerTooltipedField from './registerContainerTooltipedField';
import { FormikSelect } from '../../../../../../shared/inputs/formik';
import { containerStatuses } from '../../shared/utils';

const SelectContainerStatus = () => {
  const intl = useIntl();
  return (
    <RegisterContainerTooltipedField
      md={5}
      tooltipText={
        <FormattedMessage id="tooltip.containerStatus" defaultMessage="Choose container status" />
      }
    >
      <FormikSelect
        label={intl.formatMessage({
          id: 'label.containerStatus',
          defaultMessage: 'Container status',
        })}
        placeholder={intl.formatMessage({
          id: 'placeholder.containerStatus',
          defaultMessage: 'Choose container status',
        })}
        required
        filledStyle
        name="containerStatus"
        valuesList={containerStatuses}
      />
    </RegisterContainerTooltipedField>
  );
};

export default React.memo(SelectContainerStatus);
