import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import MapWhitePinIcon from '../../../images/icons/mapWhitePin.svg';
import CloseIcon from '../../../images/icons/closeWhite.svg';
import styles from './styles';
import MapComponent from '../map';

import Transition from '../animations/transition';

const MapDialog = ({
  locationCoord: initialLocationCoord,
  onClose,
  classes,
  open,
  showFooter,
  headerTitle,
  getLocation,
  setPlaceId,
}) => {
  const [initialLng, initialLat, initialAddress] = initialLocationCoord || [];
  const [lat, setLat] = useState(initialLat);
  const [lng, setLng] = useState(initialLng);
  const [selectedAddressCoords, setSelectedAddressCoords] = useState([]);
  const [address, setAddress] = useState(initialAddress);

  useEffect(() => {
    if ((initialLng && !lng) || (initialLat && !lat) || (initialAddress && !address)) {
      setLat(initialLat);
      setLng(initialLng);
      setAddress(initialAddress);
    }
  }, [initialLat, initialLng, initialAddress, lat, lng, address]);

  const handleClose = useCallback(() => {
    onClose(initialLocationCoord);
  }, [initialLocationCoord, onClose]);

  const confirm = useCallback(() => {
    onClose([lng, lat, address]);
  }, [lat, lng, address, onClose]);

  const parseGeocoderResult = (result) => {
    const { geometry, formatted_address: newAddress, place_id: placeId } = result;
    const { lng: newLng, lat: newLat } = geometry.location;
    setLat(newLat());
    setLng(newLng());
    setPlaceId(placeId);
    setSelectedAddressCoords([newLat(), newLng()]);
    setAddress(newAddress);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      className="mapDialog"
      TransitionComponent={Transition}
      open={open}
      maxWidth="md"
      fullWidth
      scroll="body"
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          confirm();
        }
      }}
    >
      <div className={classes.dialogHeader}>
        <img src={MapWhitePinIcon} alt="" />
        <div className={classes.dialogTitle}>{headerTitle || 'Map'}</div>
        <Button className={classes.closeBtn} onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </Button>
      </div>
      <div className={classes.dialogBody}>
        <MapComponent
          setPlaceId={setPlaceId}
          clickedAddress={address}
          onLocationSelect={parseGeocoderResult}
          mapHeight={360}
          useSearchPlace
          withCenteredPin
          selectedAddressCoords={selectedAddressCoords.length ? selectedAddressCoords : [lat, lng]}
          onMapClick={(map, customMarkerClicked) => {
            if (getLocation) {
              setLat(map.lat);
              setLng(map.lng);
              setSelectedAddressCoords([map.lat, map.lng]);
              if (!customMarkerClicked) {
                fetch(
                  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${map.lat},${map.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                )
                  .then((res) => res.json())
                  .then((result) => {
                    // Google SDK is using not a CamelCase parameter formatted_address
                    // eslint-disable-next-line camelcase
                    setAddress(result.results[0]?.formatted_address);
                    setPlaceId(result.results[0]?.place_id);
                  });
              }
            }
          }}
        />
      </div>
      {showFooter && (
        <div className={classes.dialogFooter}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            style={{ width: 220 }}
            onClick={confirm}
          >
            <FormattedMessage id="CONFIRM" defaultMessage="CONFIRM" />
          </Button>
        </div>
      )}
    </Dialog>
  );
};

MapDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  locationCoord: PropTypes.arrayOf(PropTypes.any),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  showFooter: PropTypes.bool,
  getLocation: PropTypes.bool,
  headerTitle: PropTypes.string,
  setPlaceId: PropTypes.func,
};

MapDialog.defaultProps = {
  locationCoord: [],
  headerTitle: '',
  onClose: () => {},
  open: false,
  showFooter: false,
  getLocation: false,
  setPlaceId: () => {},
};

export default withStyles(styles)(MapDialog);
