import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { loader } from 'graphql.macro';

import TableView from '@wastehero/storybook/lib/components/Views/Table';
import Button from '@wastehero/storybook/lib/components/Form/Elements/Button';
import Drawer from '@wastehero/storybook/lib/components/Drawer/Drawers/Inquery';
import CreateInquiryModal from '@wastehero/storybook/lib/components/Modal/Modals/Inquiry/Create';
import { Space } from 'antd';
import { getSavedActiveProjects } from '../../../../../shared/utils';
import ContainerSelect from '../../../../../design-system/form/select/container';
import ProjectSelect from '../../../../../design-system/form/select/project';

// eslint-disable-next-line no-unused-vars
const query = loader('./../../../../../graphql/queries/operation_management/all_inquiries.graphql');
const drawerQuery = loader('./../../../../../graphql/queries/operation_management/inquiry.graphql');

const Inquiries = () => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const [drawer, setDrawer] = useState('');
  const reportInquiryMutation = loader(
    './../../../../../graphql/mutations/operation_management/report_inquiry.graphql',
  );

  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      title: 'ID',
      dataIndex: ['node', 'id'],
      key: 'id',
    },
    {
      title: 'Priority',
      dataIndex: ['node', 'priority'],
      key: 'priority',
    },
    {
      title: 'Deadline',
      dataIndex: ['node', 'deadline'],
      key: 'deadline',
    },
    {
      title: 'Waste Volume',
      dataIndex: ['node', 'wasteVolume'],
      key: 'wasteVolume',
    },
    {
      title: 'Issue Type',
      dataIndex: ['node', 'issueType'],
      key: 'issueType',
    },
    {
      title: 'Waste Category',
      dataIndex: ['node', 'wasteFraction', 'wasteCategory'],
      key: 'wasteCategory',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button title="View" size="small" onClick={() => setDrawer(record.node.id)} />
        </Space>
      ),
    },
  ];

  const breadcrumbBar = {
    title: 'Inquiries',
    createButtonTitle: 'Create Inquiry',
    createButtonAction: () => setShowModal(true),
    breadcrumbsLinks: [
      {
        label: 'Operation Management',
        path: '/route1',
      },
      {
        label: 'Inquiries',
        path: '/route2',
      },
    ],
  };

  const queryVariablesFormatter = (queryVariables, filterValues, pagination, filters, sorter) => {
    const sortBy = {};
    const dataPagination = { pageSize: 10 };
    const dateRange = filterValues.find((filter) => filter.key === 'dateRange');
    const dateFilter = dateRange
      ? { From: dateRange.value.end.toDateString(), To: dateRange.value.start.toDateString() }
      : {};

    if (sorter) {
      sortBy.orderBy = sorter.columnKey;

      if (sorter.order) {
        sortBy.order = sorter.order === 'descend' ? 'desc' : 'asc';
      }
    }

    if (pagination) {
      dataPagination.pageSize = pagination.pageSize;
      dataPagination.offset = pagination.pageSize * (pagination.current - 1);
    }

    return {
      ...queryVariables,
      ...sortBy,
      ...dataPagination,
      ...dateFilter,
    };
  };

  const queryResultFormatter = (values) => ({
    data: values?.allInquiries.edges || [],
    pagination: {
      total: values?.allInquiries.totalCount,
    },
  });
  const queryVariables = {
    activeProjects: savedActiveProjects,
  };

  const tableProps = {
    antdTableProps: {
      columns,
      rowKey: (record) => record.node.id,
    },
  };

  const queryTableProps = {
    query,
    queryResultFormatter,
    queryVariablesFormatter,
    queryVariables,
    tableProps,
  };

  const drawerQueryVariablesFormatter = (qvs) => ({ ...qvs });

  const drawerQueryVariables = {
    id: drawer,
  };

  return (
    <>
      <TableView queryTableProps={queryTableProps} breadcrumbBar={breadcrumbBar} />
      <Drawer
        visible={!!drawer}
        onClose={() => setDrawer('')}
        query={drawerQuery}
        queryVariablesFormatter={drawerQueryVariablesFormatter}
        queryVariables={drawerQueryVariables}
      />
      <CreateInquiryModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        mutation={reportInquiryMutation}
        mutationVariablesFormatter={(values) =>
          // console.log('mutationVariablesFormatter', values);
          values
        }
        volumeSetting="L"
        weightSetting="KG"
        projectSelect={<ProjectSelect multiple={false} />}
        containerSelect={<ContainerSelect multiple />}
        onSuccess={() => setShowModal(false)}
      />
    </>
  );
};

Inquiries.propTypes = {};

export default Inquiries;
