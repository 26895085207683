import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Formik, Form } from 'formik';
import { loader } from 'graphql.macro';
import InputLabel from '@material-ui/core/InputLabel';
import LoadingLayout from '../../../../shared/loading';
import {
  measuringDistanceChoices,
  drivingDistanceChoices,
  weightChoices,
  volumeChoices,
  temperatureChoices,
  timezones,
  currencyList,
  clockFormatList,
} from './defaults/data';
import SettingsUnitSettings from './ui/SettingsUnitSettings';
import {
  saveCacheRead,
  toastifyError,
  maxLengthStringMessage,
  messageWrongCoords,
} from '../../../../shared/utils';
import getMe from './helpers';
import { setUser } from '../../../../../actions';
import TooltippedUserControl from '../../../../shared/tooltip/TooltippedUserControl';
import { USER, checkIfRegularUser } from '../../../../shared/utils/settings';
import { FormikSelect, FormikImageDropzone } from '../../../../shared/inputs/formik';
import { handleClosePopup } from '../../containers/routes/shared/utils';
import MapDialog from '../../../../shared/mapDialog';
import FormikTextField from '../../../../shared/inputs/formik/FormikTextField';
import FormikLocationInput from '../../../../shared/inputs/formik/FormikLocationInput';

const projectQuery = loader('./../../../../graphql/queries/core/project.graphql');
const updateProjectSettingsMutation = loader(
  './../../../../graphql/mutations/core/update_project_settings.graphql',
);
const createProjectSettingsMutation = loader(
  './../../../../graphql/mutations/core/create_project_settings.graphql',
);
const allCompanies = loader('./../../../../graphql/queries/core/all_companies.graphql');

const allProjectsQuery = loader('./../../../../graphql/queries/core/all_projects.graphql');

const updateCache = (queryName, forCompany) => async (store, data) => {
  const {
    data: {
      [queryName]: { project },
    },
  } = data;
  const {
    data: { allCompanies: allCompaniesData },
  } = await saveCacheRead({ query: allCompanies });

  const filteredEdges = allCompaniesData.edges.map((company) => {
    const deepCopyCompany = JSON.parse(JSON.stringify(company));
    const { node } = company;

    if (node.projectSet.edges) {
      deepCopyCompany.node.projectSet.edges = node.projectSet.edges.filter(
        ({ node: projectNode }) => projectNode.id !== project.id,
      );
    }

    if (node.id === (forCompany || project.company.id)) {
      deepCopyCompany.node.projectSet.edges = [
        ...deepCopyCompany.node.projectSet.edges,
        {
          node: project,
          __typename: 'ProjectTypeEdge',
        },
      ];
    }

    return deepCopyCompany;
  });
  store.writeQuery({
    query: allCompanies,
    data: { allCompanies: { ...allCompaniesData, edges: filteredEdges } },
  });
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: 15,
    fontWeight: 700,
    color: theme.variables.VHLightBlack,
  },
  unitSettingsTooltip: {
    marginLeft: -20,
  },
  disableEditText: {
    fontWeight: 400,
    lineHeight: '1.5rem',
    color: '#9aa8ad',
    fontSize: '1rem',
    padding: '0 16px 16px 16px',
  },
}));

const projectSchema = Yup.object().shape({
  name: Yup.string().label('Name').max(150, maxLengthStringMessage(150)).required(),
  description: Yup.string().max(250, maxLengthStringMessage(250)),
  timezone: Yup.string().label('Timezone').required(),
  currency: Yup.string().label('Currency').required(),
  measuringDistance: Yup.string(),
  drivingDistance: Yup.string(),
  weight: Yup.string(),
  volume: Yup.string(),
  temperature: Yup.string(),
  clockFormat12: Yup.string().label('Hour Format').required(),
  latitude: Yup.number()
    .label('Latitude')
    .min(-90, messageWrongCoords(90))
    .max(90, messageWrongCoords(90)),
  longitude: Yup.number()
    .label('Longitude')
    .min(-180, messageWrongCoords(180))
    .max(180, messageWrongCoords(180)),
  placeId: Yup.string(),
});

const ProjectSettings = ({ projectId, me, handleSetUser, history, forCompany }) => {
  const classes = useStyles();
  const intl = useIntl();
  const client = useApolloClient();

  const [isMapDialogueOpen, setIsMapDialogueOpen] = useState(false);

  const [projectLoading, setProjectLoading] = useState(false);
  const [projectData, setProjectData] = useState({});

  const [newPhoto, setNewPhoto] = useState('');
  const [newPhotoUrl, setNewPhotoUrl] = useState('');

  const [globalLoading, setGlobalLoading] = useState(false);

  const [chosenCompany, setChosenCompany] = useState();

  const isProjectOwner = me.ownProjectsIds.includes(projectId);

  const [updateProjectSettings] = useMutation(updateProjectSettingsMutation);
  const [createProjectSettings] = useMutation(createProjectSettingsMutation, {
    refetchQueries: [{ query: allProjectsQuery, variables: { isVisibleCompany: false } }],
  });
  const { data } = useQuery(allCompanies);

  useEffect(() => {
    if (data) {
      setChosenCompany(
        data.allCompanies.edges.find((company) => company.node.id === forCompany)?.node,
      );
    }
  }, [data, forCompany]);

  const handleChangePhotoUrl = (e) => {
    if (e.file) {
      const reader = new FileReader();
      reader.readAsDataURL(e.file);
      reader.onload = () => {
        setNewPhoto(reader.result);
        setNewPhotoUrl(e.url);
      };
    } else {
      setNewPhoto('');
      setNewPhotoUrl('');
    }
  };

  const handleChangeUnitSetting = (handler) => (event, newValue) => {
    if (newValue) {
      handler(newValue);
    }
  };

  const getProject = useCallback(() => {
    setProjectLoading(true);
    saveCacheRead({
      query: projectQuery,
      variables: {
        id: projectId,
        withSettings: true,
      },
    })
      .then(({ data: { project } = {} }) => {
        setProjectData(project);
      })
      .finally(() => {
        setProjectLoading(false);
      });
  }, [projectId]);

  const isRegularUser = checkIfRegularUser(me) && !isProjectOwner;

  useEffect(() => {
    if (projectId) {
      getProject();
    }
  }, [projectId, getProject]);

  const loading = projectLoading || globalLoading;

  useEffect(() => {
    const { logo } = projectData;

    if (logo) {
      setNewPhotoUrl(logo);
    }
  }, [projectData]);

  const formSubmitHandler = (values, { setSubmitting, setFieldError }) => {
    const variables = {
      ...values,
      settingsId: projectId ? projectData.settings.id : '',
      projectId: projectId ? projectData.id : '',
      companyId: forCompany || '',
      logo: newPhoto || newPhotoUrl,
      clockFormat12: values.clockFormat12 === '12',
      latitude: values.latitude || null,
      longitude: values.longitude || null,
    };
    if (
      !projectId &&
      chosenCompany.projectSet.edges.some((projectNode) => projectNode.node.name === values.name)
    ) {
      setFieldError(
        'name',
        <FormattedMessage
          id="project_name_exists"
          defaultMessage="Project with name {name} already exists in this company"
          values={{ name: values.name }}
        />,
      );
      setSubmitting(false);
      return;
    }
    setGlobalLoading(true);
    // eslint-disable-next-line consistent-return
    return (projectId ? updateProjectSettings : createProjectSettings)({
      variables,
      update: updateCache(projectId ? 'updateProjectSettings' : 'createProjectSettings'),
    })
      .then(
        ({
          data: {
            [projectId ? 'updateProjectSettings' : 'createProjectSettings']: { project },
          },
        }) => {
          if (!projectId && project.id) {
            history.push(`/app/settings/${project.id}/project-settings`);
          }
          toast.info(
            intl.formatMessage({
              id: 'toast.project_saved',
              defaultMessage: 'Project settings successfully saved',
            }),
          );
          setProjectData(project);
        },
      )
      .then(async () => {
        await getMe({ client, handleSetUser });
      })
      .catch((error) => {
        setSubmitting(false);
        toastifyError(error);
      })
      .finally(() => {
        setGlobalLoading(false);
      });
  };

  const readOnly = projectId
    ? projectData.userAccessLevel === USER
    : !(me.isAdmin || me.isMaster || me.isReseller);

  const { settings = {} } = projectData || {};
  const { dashboardLocation } = settings;

  return (
    <div>
      {loading ? (
        <LoadingLayout isLoading={loading} />
      ) : (
        <Formik
          initialValues={{
            name: projectData?.name || '',
            photo: projectData?.logo || '',
            description: projectData?.description || '',
            timezone: settings.timezone || '',
            currency: settings.currency || '',
            clockFormat12: settings.clockFormat12 ? '12' : '24',
            latitude: dashboardLocation ? dashboardLocation?.latitude : '',
            longitude: dashboardLocation ? dashboardLocation?.longitude : '',
            measuringDistance: settings.measuringDistance || measuringDistanceChoices[0].value,
            drivingDistance: settings.drivingDistance || drivingDistanceChoices[0].value,
            weight: settings.weight || weightChoices[0].value,
            volume: settings.volume || volumeChoices[0].value,
            temperature: settings.temperature || temperatureChoices[0].value,
            placeId: dashboardLocation ? dashboardLocation?.placeId : '',
          }}
          onSubmit={formSubmitHandler}
          validationSchema={projectSchema}
        >
          {({ submitForm, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form className="w-100 m-t-40 p-b-50">
              <LoadingLayout isLoading={isSubmitting} />
              <Grid
                container
                alignItems="center"
                justify="center"
                className="m-b-10"
                direction="column"
                spacing={4}
              >
                <Grid container item xs={8} tourid="projectDetails" className="p-b-0">
                  {isRegularUser && (
                    <FormattedMessage
                      id="no_permission_edit_project_settings"
                      defaultMessage="You don’t have permission to add or edit project settings"
                    >
                      {(text) => <span className={classes.disableEditText}>{text}</span>}
                    </FormattedMessage>
                  )}
                  <Grid container item xs={12} spacing={2} justify="center" alignItems="flex-end">
                    <Grid item xs={6}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.name"
                            defaultMessage="Enter a name for the project"
                          />
                        }
                      >
                        <FormikTextField
                          label={intl.formatMessage({
                            id: 'label.name',
                            defaultMessage: 'Project name',
                          })}
                          required
                          name="name"
                          InputProps={{
                            readOnly,
                          }}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <Grid container item xs={12} md={6} direction="column" alignItems="stretch">
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.logo"
                            defaultMessage="Choose project logo"
                          />
                        }
                      >
                        <InputLabel className={classes.label}>Project logo</InputLabel>
                        <FormikImageDropzone
                          size="small"
                          name="photo"
                          id="photo"
                          onChange={handleChangePhotoUrl}
                        />
                      </TooltippedUserControl>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2} justify="center" className="p-t-10">
                    <Grid item xs={12}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.description"
                            defaultMessage="Enter a description for the project"
                          />
                        }
                      >
                        <FormikTextField
                          name="description"
                          label={intl.formatMessage({
                            id: 'label.description',
                            defaultMessage: 'Project description',
                          })}
                          multiline
                          rows={5}
                          InputProps={{
                            readOnly,
                          }}
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={8} spacing={2} justify="center" className="p-t-0">
                  <Grid container item xs={12} tourid="projectPreferences1">
                    <Grid item xs={12}>
                      <FormikLocationInput required={false} disabled={isRegularUser} />
                    </Grid>
                    <Grid item xs={12} className="m-t-10">
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.timezone"
                            defaultMessage="Choose the time zone of the project area"
                          />
                        }
                      >
                        <FormikSelect
                          required
                          name="timezone"
                          label={intl.formatMessage({
                            id: 'label.timezone',
                            defaultMessage: 'Time zone',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.timezone',
                            defaultMessage: 'Choose time zone',
                          })}
                          valuesList={timezones}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(value) => {
                            setFieldValue('timezone', value !== 'CET' ? value : '');
                          }}
                          filledStyle
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <Grid item xs={12} className="m-t-10">
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.currency"
                            defaultMessage="Choose the currency used in the project area"
                          />
                        }
                      >
                        <FormikSelect
                          required
                          name="currency"
                          label={intl.formatMessage({
                            id: 'label.currency',
                            defaultMessage: 'Currency',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.currency',
                            defaultMessage: 'Choose currency',
                          })}
                          valuesList={currencyList}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(value) => setFieldValue('currency', value)}
                          filledStyle
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <Grid item xs={12} className="m-t-10 m-b-10">
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.clockFormat"
                            defaultMessage="Choose from 12-hour clock or 24-hour clock"
                          />
                        }
                      >
                        <FormikSelect
                          required
                          name="clockFormat12"
                          label={intl.formatMessage({
                            id: 'label.clockFormat',
                            defaultMessage: 'Hour format',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.clockFormat',
                            defaultMessage: 'Hour format',
                          })}
                          valuesList={clockFormatList}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(value) => setFieldValue('clockFormat12', value)}
                          filledStyle
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} tourid="projectPreferences2">
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.unitSettings"
                          defaultMessage="Select the units for container dimensions, driving distance, content weight, content volume and temperature in this project"
                        />
                      }
                      tooltipStyle={classes.unitSettingsTooltip}
                    />
                    <SettingsUnitSettings
                      groups={[
                        {
                          label: intl.formatMessage({
                            id: 'label.measuring_length',
                            defaultMessage: 'Measuring length',
                          }),
                          value: values.measuringDistance,
                          handleChange: handleChangeUnitSetting((value) =>
                            setFieldValue('measuringDistance', value),
                          ),
                          valuesList: measuringDistanceChoices,
                        },
                        {
                          label: intl.formatMessage({
                            id: 'label.driving_distance',
                            defaultMessage: 'Driving distance',
                          }),
                          value: values.drivingDistance,
                          handleChange: handleChangeUnitSetting((value) =>
                            setFieldValue('drivingDistance', value),
                          ),
                          valuesList: drivingDistanceChoices,
                        },
                        {
                          label: intl.formatMessage({
                            id: 'label.weight',
                            defaultMessage: 'Weight',
                          }),
                          value: values.weight,
                          handleChange: handleChangeUnitSetting((value) =>
                            setFieldValue('weight', value),
                          ),
                          valuesList: weightChoices,
                        },
                        {
                          label: intl.formatMessage({
                            id: 'label.volume',
                            defaultMessage: 'Volume',
                          }),
                          value: values.volume,
                          handleChange: handleChangeUnitSetting((value) =>
                            setFieldValue('volume', value),
                          ),
                          valuesList: volumeChoices,
                        },
                        {
                          label: intl.formatMessage({
                            id: 'label.temperature',
                            defaultMessage: 'Temperature',
                          }),
                          value: values.temperature,
                          handleChange: handleChangeUnitSetting((value) =>
                            setFieldValue('temperature', value),
                          ),
                          valuesList: temperatureChoices,
                        },
                      ]}
                      disabled={isRegularUser}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={10} spacing={2} justify="center">
                  <Grid item xs={3} container justify="flex-end">
                    <Button
                      variant="outlined"
                      className="w-60 p-r-40 settings-control-button"
                      startIcon={<ChevronLeftIcon />}
                      type="button"
                      disabled
                    >
                      <Typography variant="body1">
                        <FormattedMessage id="back" defaultMessage="Back" />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={3} container justify="center">
                    {!readOnly && (
                      <Button
                        variant="outlined"
                        className="w-60 settings-control-button"
                        type="submit"
                        onClick={submitForm}
                        tourid="saveButton"
                      >
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.save"
                              defaultMessage="Save the details of the project"
                            />
                          }
                          avoidIcon
                        >
                          <Typography variant="body1">
                            <FormattedMessage id="save" defaultMessage="Save" />
                          </Typography>
                        </TooltippedUserControl>
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={3} container justify="flex-start" tourid="nextButton">
                    {projectId ? (
                      <Link
                        to={`/app/settings/${projectId}/container-settings`}
                        className="masked-link w-60"
                      >
                        <Button
                          variant="outlined"
                          className="w-100 p-l-40 settings-control-button"
                          endIcon={<ChevronRightIcon />}
                          type="button"
                        >
                          <TooltippedUserControl
                            tooltipText={
                              <FormattedMessage
                                id="tooltip.next"
                                defaultMessage="Move to container settings"
                              />
                            }
                            avoidIcon
                          >
                            <Typography variant="body1" className="p-l-20">
                              <FormattedMessage id="next" defaultMessage="Next" />
                            </Typography>
                          </TooltippedUserControl>
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="outlined"
                        className="w-60 p-r-40 settings-control-button"
                        endIcon={<ChevronRightIcon />}
                        disabled
                        type="button"
                      >
                        <Typography variant="body1">
                          <FormattedMessage id="next" defaultMessage="Next" />
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <MapDialog
                locationCoord={[values.longitude || 0, values.latitude || 0]}
                open={isMapDialogueOpen}
                onClose={handleClosePopup(setFieldValue, setFieldTouched, setIsMapDialogueOpen)}
                showFooter
                showSearch
                getLocation
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

ProjectSettings.propTypes = {
  projectId: PropTypes.string.isRequired,
  forCompany: PropTypes.string,
  handleSetUser: PropTypes.func.isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isReseller: PropTypes.bool,
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

ProjectSettings.defaultProps = {
  forCompany: '',
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user, true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(withRouter(ProjectSettings)));
