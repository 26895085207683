import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { connect as connectToFormik, getIn } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import Transition from '../../../../../../shared/animations/transition';
import {
  formikComponentMemoizeValueCheck,
  formikInjectedPropsTypes,
  memoizeFields,
} from '../../../../../../shared/inputs/formik';
import FixedLoadingLayout from '../../../../../../shared/loading/fixed';
import { createPickupSettingsSerializer } from '../../shared/utils';
import { getSavedActiveProjects, toastifyError } from '../../../../../../shared/utils';
import StyledTextField from '../../../../../../shared/inputs/StyledTextField';

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    padding: 20,
    minHeight: '60vh',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  roundedBtn: {
    borderRadius: 32.5,
  },
}));

const updatePickupSettingsMutation = loader(
  '../../../../../../graphql/mutations/operation_management/update_or_create_pickup_settings.graphql',
);

const allPickupSettingsQuery = loader(
  '../../../../../../graphql/queries/operation_management/all_pickup_settings.graphql',
);

const updatePickupSettingsCache = (variables) => async (
  store,
  { data: { updateOrCreatePickupSettings: { pickupSettings } = {} } = {} } = {},
) => {
  try {
    const query = {
      query: allPickupSettingsQuery,
      variables,
    };

    const { allPickupSettings } = await store.readQuery(query);

    store.writeQuery({
      ...query,
      data: {
        allPickupSettings: {
          ...allPickupSettings,
          edges: [
            ...allPickupSettings.edges.filter(
              ({ node: { id: nodeId } }) => nodeId !== pickupSettings.id,
            ),
            { node: pickupSettings, __typename: 'PickupSettingsTypeEdge' },
          ],
        },
      },
    });
  } catch (e) {
    window.console.log(e);
  }
};

const SavePickupPreset = ({ open, onClose, formik }) => {
  const classes = useStyles();
  const { values } = formik;
  const intl = useIntl();
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const project = getIn(values, 'selectedProject');
  const [name, setName] = useState('');
  const [fieldError, setFieldError] = useState('');

  const [updatePickupSettings, { loading }] = useMutation(updatePickupSettingsMutation);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSave = useCallback(() => {
    if (!name) {
      setFieldError(
        intl.formatMessage({
          id: 'defaultValidation.required',
          defaultMessage: 'This field is required',
        }),
      );
    } else {
      const pickupSettingsData = createPickupSettingsSerializer(values);
      updatePickupSettings({
        variables: { ...pickupSettingsData, name, project },
        update: updatePickupSettingsCache({ activeProjects: savedActiveProjects, project }),
      })
        .then(() => {
          handleClose();
        })
        .catch(toastifyError);
    }
  }, [handleClose, intl, name, project, savedActiveProjects, updatePickupSettings, values]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      fullWidth
      scroll="body"
      TransitionComponent={Transition}
      aria-labelledby="selectPickupPreset"
      aria-describedby="selectPickupPreset-description"
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onSave();
        }
      }}
    >
      <DialogTitle
        id="register-container-dialog"
        onClose={handleClose}
        className={classes.dialogTitle}
      >
        <FormattedMessage id="selectStaticCollection" defaultMessage="Saved settings" />
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <Grid container className="m-10">
          <FixedLoadingLayout open={loading} />
          <Grid container direction="column" alignItems="flex-start">
            <Grid container item justify="center">
              <StyledTextField
                name="pickupSettingsName"
                value={name}
                error={fieldError}
                onChange={setName}
                label={
                  <FormattedMessage
                    id="project_settings.presets.name"
                    defaultMessage="Project settings preset name"
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing className="m-b-15">
        <Grid container justify="center">
          <Grid item xs={5}>
            <Button
              onClick={onSave}
              color="secondary"
              fullWidth
              variant="contained"
              className={classes.roundedBtn}
            >
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

SavePickupPreset.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

export default connectToFormik(
  React.memo(
    SavePickupPreset,
    memoizeFields([
      'open',
      'classes',
      'onClose',
      formikComponentMemoizeValueCheck('selectedProject'),
    ]),
  ),
);
