import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const MuiMaskedInput = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
  />
);

MuiMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default MuiMaskedInput;
