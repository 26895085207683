import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TooltippedUserControl from '../../../../shared/tooltip/TooltippedUserControl';

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.variables.chipBackgroundColor,
    marginRight: '8px',
    marginTop: '8px',
    '& svg.MuiSvgIcon-root': {
      height: '18px',
      width: '18px',
    },
    '&:hover': {
      backgroundColor: theme.variables.cLightGray,
    },
  },
  newChip: {
    animation: '$blink 4s ease-out',
  },
  '@keyframes blink': {
    '0%': {
      backgroundColor: theme.variables.chipBackgroundColor,
    },
    '15%': {
      backgroundColor: theme.variables.cOrange,
    },
    '30%': {
      backgroundColor: theme.variables.chipBackgroundColor,
    },
    '45%': {
      backgroundColor: theme.variables.cOrange,
    },
    '60%': {
      backgroundColor: theme.variables.chipBackgroundColor,
    },
    '75%': {
      backgroundColor: theme.variables.cOrange,
    },
    '100%': {
      backgroundColor: theme.variables.chipBackgroundColor,
    },
  },
}));

let lastContainerTypeId = '';

const ContainerTypeChip = ({
  node,
  isRegularUser,
  isNewContainerType,
  editContainerType,
  toggleMessageConfirm,
  removeContainerType,
  setValues,
}) => {
  const [highlightChip, setHighlightChip] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (isNewContainerType && lastContainerTypeId !== node.id) {
      lastContainerTypeId = node.id;
      setHighlightChip(true);
    }
  }, [isNewContainerType, node]);

  if (isRegularUser) {
    return (
      <Chip
        onClick={editContainerType(node, setValues)}
        label={<>{node.name}</>}
        className={classes.chip}
      />
    );
  }

  return (
    <Chip
      label={
        <>
          {node.name}
          <IconButton size="small" onClick={editContainerType(node, setValues)}>
            <TooltippedUserControl
              tooltipText={
                <FormattedMessage
                  id="tooltip.container_type.edit"
                  defaultMessage="Click to edit this container type’s details"
                />
              }
              avoidIcon
            >
              <EditIcon />
            </TooltippedUserControl>
          </IconButton>
          <IconButton
            size="small"
            onClick={
              node.containerSet.totalCount > 0 ? toggleMessageConfirm : removeContainerType(node)
            }
          >
            <TooltippedUserControl
              tooltipText={
                <FormattedMessage
                  id="tooltip.container_type.delete"
                  defaultMessage="Click to delete this container type"
                />
              }
              avoidIcon
            >
              <DeleteIcon />
            </TooltippedUserControl>
          </IconButton>
        </>
      }
      className={`${classes.chip} ${highlightChip ? classes.newChip : ''}`}
    />
  );
};

ContainerTypeChip.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    containerSet: PropTypes.shape({ totalCount: PropTypes.number }),
  }).isRequired,
  isRegularUser: PropTypes.bool.isRequired,
  isNewContainerType: PropTypes.bool.isRequired,
  editContainerType: PropTypes.func.isRequired,
  toggleMessageConfirm: PropTypes.func.isRequired,
  removeContainerType: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default ContainerTypeChip;
