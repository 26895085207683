import React, { useMemo } from 'react';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { toastifyError } from '../../../../../shared/utils';
import LoadingLayout from '../../../../../shared/loading';
import DetailsSkeleton from '../../components/detailsSkeleton';
import InfoLabel from '../../components/infoLabel';
import InfoBox from '../../components/infoBox';
import StyledStatus from '../../components/styledStatus';
import OpeningHours from '../../components/openingHours';
import { daysOptions } from '../../../settings/project/defaults/data';
import {
  joinElementsCallback,
  PAYMENT_INTERVALS_OPTIONS,
  REGION_OPTIONS,
} from '../../components/utils';
import { StyledListLink } from '../../components/linkHoverStyle';

const driverQuery = loader('./../../../../../graphql/queries/fleet_management/driver.graphql');

const DriverDetails = ({ match }) => {
  const { params: { id: driverId } = {} } = match;

  const intl = useIntl();

  const { loading: driverLoading, data: { driver = {} } = {} } = useQuery(driverQuery, {
    onError: toastifyError,
    variables: { id: driverId },
  });

  const projectsNames = useMemo(
    () =>
      driver.user?.userprojectSet
        ? driver.user?.userprojectSet.edges.reduce((newProjectsNames, { node: { project } }) => {
            if (project) {
              newProjectsNames.push({ id: project.id, name: project.name });
            }
            return newProjectsNames;
          }, [])
        : [],
    [driver],
  );

  const skillsName = useMemo(
    () =>
      (driver?.driverSkills?.edges || []).reduce(
        (newSkillsName, { node: { id, skill, validFrom, validTo } }) => [
          ...newSkillsName,
          {
            id,
            name: skill.name,
            code: skill.code,
            validFrom,
            validTo,
          },
        ],
        [],
      ),
    [driver],
  );

  const driverDays = (driver?.daysAvailable || []).map((day) =>
    daysOptions.find(({ value }) => day === value),
  );

  const pageTitle = intl.formatMessage({
    id: 'driver.details.pageTitle',
    defaultMessage: 'Driver details',
  });

  const mainInfoHeadImageAltText = intl.formatMessage({
    id: 'driver.details.mainInfoHeadImageAltText',
    defaultMessage: 'Driver Image',
  });

  return (
    <Grid container className="h-100">
      <LoadingLayout isLoading={driverLoading} />
      <DetailsSkeleton
        pageTitle={pageTitle}
        mainInfoHeadData={{
          imageSrc: driver.user?.logo,
          imageAltText: mainInfoHeadImageAltText,
          name:
            driver.user?.firstName && driver.user?.lastName
              ? `${driver.user?.firstName} ${driver.user?.lastName}`
              : driver.user?.username,
          description: `${driver.vehicle?.manufacturerYear} ${driver.vehicle?.manufacturer} ${driver.vehicle?.model}`,
          settingsLink: `${match.url}/edit`,
          children: (
            <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
              <InfoLabel
                title={
                  <FormattedMessage id="driver.details.projectLabel" defaultMessage="Project" />
                }
                text={projectsNames.map((project) => (
                  <>
                    <StyledListLink
                      key={project.id}
                      to={`/app/settings/${project?.id || `#`}`}
                      text={`${project?.name || '-'}`}
                      disableFlex
                    />
                    {', '}
                  </>
                ))}
              />
              <InfoLabel
                title={
                  <FormattedMessage id="location.details.statusLabel" defaultMessage="Status" />
                }
                text={<StyledStatus status={driver.status || ''} />}
              />
            </Grid>
          ),
        }}
      >
        <>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={
              <FormattedMessage
                id="driver.details.vehicleIdentification"
                defaultMessage="Vehicle identification"
              />
            }
          >
            <InfoLabel
              title={
                <FormattedMessage id="driver.details.vehicleName" defaultMessage="Vehicle ID" />
              }
              text={`${driver.vehicle?.manufacturerYear} ${driver.vehicle?.manufacturer} ${driver.vehicle?.model}`}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.licensePlateNumber"
                  defaultMessage="Employee number"
                />
              }
              text={driver.employeeNumber}
            />
            <InfoLabel
              title={<FormattedMessage id="driver.details.email" defaultMessage="Email" />}
              text={driver.user?.email}
            />
            <InfoLabel
              title={<FormattedMessage id="driver.details.phone" defaultMessage="Phone number" />}
              text={driver.user?.phoneNumber}
            />
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={
              <FormattedMessage
                id="driver.details.driverPermission"
                defaultMessage="Driver permission"
              />
            }
          >
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.licenseNumber"
                  defaultMessage="License number"
                />
              }
              text={driver.license?.number}
            />
            <InfoLabel
              title={
                <FormattedMessage id="driver.details.licenseClass" defaultMessage="License class" />
              }
              text={driver.license?.licenseClass}
            />
            <InfoLabel
              title={<FormattedMessage id="driver.details.validFrom" defaultMessage="Valid from" />}
              text={
                driver.license?.validInRange?.lower &&
                moment(driver.license?.validInRange?.lower).format('YYYY-MM-D')
              }
            />
            <InfoLabel
              title={<FormattedMessage id="driver.details.validTo" defaultMessage="Valid to" />}
              text={
                driver.license?.validInRange?.upper &&
                moment(driver.license?.validInRange?.upper).format('YYYY-MM-D')
              }
            />
            <InfoLabel
              title={
                <FormattedMessage id="driver.details.region" defaultMessage="License region" />
              }
              text={REGION_OPTIONS.find(({ value }) => value === driver.license?.region)?.label}
            />
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={<FormattedMessage id="driver.details.schedule" defaultMessage="Work schedule" />}
          >
            <InfoLabel
              title={
                <FormattedMessage id="driver.details.workingHours" defaultMessage="Working hours" />
              }
              text={
                <OpeningHours
                  openingTime={driver?.workTimeStart}
                  closingTime={driver?.workTimeEnd}
                />
              }
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.allowedWorkingDays"
                  defaultMessage="Allowed working days"
                />
              }
              text={
                driverDays.length
                  ? driverDays
                      .map(({ value, label }) => (
                        <React.Fragment key={value}>{label}</React.Fragment>
                      ))
                      .reduce(joinElementsCallback(', '), [])
                  : '-'
              }
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.allowedDailyHours"
                  defaultMessage="Allowed daily hours"
                />
              }
              text={driver?.allowedMaximumDailyHours}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.allowedWeeklyHours"
                  defaultMessage="Allowed weekly hours"
                />
              }
              text={driver?.allowedMaximumWeeklyHours}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.allowedOvertime"
                  defaultMessage="Allowed overtime"
                />
              }
              text={driver?.allowedOvertime}
            />
            {driver?.breakTimeStart && driver?.breakTimeEnd ? (
              <InfoLabel
                title={
                  <FormattedMessage id="driver.details.breakTime" defaultMessage="Break time" />
                }
                text={
                  <OpeningHours
                    openingTime={driver?.breakTimeStart}
                    closingTime={driver?.breakTimeEnd}
                  />
                }
              />
            ) : (
              <InfoLabel
                title={
                  <FormattedMessage
                    id="driver.details.breakDuration"
                    defaultMessage="Break duration"
                  />
                }
                text={driver?.breakDuration}
              />
            )}
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={<FormattedMessage id="driver.details.driverCost" defaultMessage="Driver cost" />}
          >
            <InfoLabel
              title={
                <FormattedMessage id="driver.details.costMethod" defaultMessage="Cost method" />
              }
              text={driver.cost?.isFixedCost ? 'Fixed cost' : 'Not fixed cost'}
            />
            {driver.cost?.isFixedCost && (
              <>
                <InfoLabel
                  title={
                    <FormattedMessage
                      id="driver.details.paymentInterval"
                      defaultMessage="Payment interval"
                    />
                  }
                  text={
                    PAYMENT_INTERVALS_OPTIONS.find(
                      ({ value }) => value === driver.cost?.paymentInterval,
                    )?.label
                  }
                />
                <InfoLabel
                  title={
                    <FormattedMessage
                      id="driver.details.costPerInterval"
                      defaultMessage="Cost per interval"
                    />
                  }
                  text={driver.cost?.costPerInterval}
                />
              </>
            )}
            {!driver.cost?.isFixedCost && (
              <>
                <InfoLabel
                  title={
                    <FormattedMessage
                      id="driver.details.costPerHour"
                      defaultMessage="Cost per hour"
                    />
                  }
                  text={driver.cost?.costPerHour}
                />
                <InfoLabel
                  title={
                    <FormattedMessage
                      id="driver.details.costPerOvertimeHour"
                      defaultMessage="Cost per overtime hour"
                    />
                  }
                  text={driver.cost?.costPerHourForOvertime}
                />
              </>
            )}
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={
              <FormattedMessage
                id="driver.details.driverLogistic"
                defaultMessage="Driver logistic"
              />
            }
          >
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.startLocation"
                  defaultMessage="Start location"
                />
              }
              text={
                driver.startLocation ? (
                  <StyledListLink
                    key={driver.startLocation.id}
                    to={`/app/fleet-management/locations/${driver.startLocation.id}`}
                    text={driver.startLocation.name}
                    disableFlex
                  />
                ) : (
                  ''
                )
              }
            />
            <InfoLabel
              title={
                <FormattedMessage id="driver.details.endLocation" defaultMessage="End location" />
              }
              text={
                driver.endLocation ? (
                  <StyledListLink
                    key={driver.endLocation.id}
                    to={`/app/fleet-management/locations/${driver.endLocation.id}`}
                    text={driver.endLocation.name}
                    disableFlex
                  />
                ) : (
                  ''
                )
              }
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="driver.details.geofenceArea"
                  defaultMessage="Assign geofence area"
                />
              }
              text={driver.service?.geofenceArea}
            />
          </InfoBox>
          {skillsName.length ? (
            <InfoBox
              lg={3}
              xs={12}
              md={4}
              title={
                <FormattedMessage id="driver.details.driverSkill" defaultMessage="Driver skill" />
              }
            >
              {skillsName.map((item) => (
                <div key={item.id}>
                  <InfoLabel
                    title={`${item.code}: ${item.name}`}
                    text={
                      <>
                        <FormattedMessage
                          id="driver.details.validFromTo"
                          defaultMessage="Valid from {from} to {to}"
                          values={{
                            from: item.validFrom,
                            to: item.validTo,
                          }}
                        />
                      </>
                    }
                  />
                </div>
              ))}
            </InfoBox>
          ) : null}
        </>
      </DetailsSkeleton>
    </Grid>
  );
};

DriverDetails.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(DriverDetails);
