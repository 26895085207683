import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '50%',
    border: `1px solid ${theme.variables.cOrange}`,
    color: theme.variables.VHBlue,
  },
}));

const UserLogo = ({ logoUrl }) => {
  const classes = useStyles();

  return <div className={classes.container} style={{ backgroundImage: `url(${logoUrl})` }} />;
};

UserLogo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
};

export default UserLogo;
