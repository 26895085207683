import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: theme.variables.VHBlue,
    background: 'rgba(255,255,255,0.5)',
    zIndex: 1200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LoadingLayout = ({ isLoading }) => {
  const classes = useStyles();
  return isLoading ? (
    <div className={classes.root}>
      <CircularProgress size={50} />
    </div>
  ) : null;
};

LoadingLayout.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingLayout.defaultProps = {
  isLoading: false,
};

export default LoadingLayout;
