import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import useFilledInputStyles from '../inputs/formik/styles';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    '& .MuiTablePagination-spacer': {
      flex: 'unset',
    },
    '& .MuiToolbar-root': {
      paddingLeft: 0,
    },
    '& div.MuiTablePagination-toolbar': {
      minHeight: 'unset',
      height: '100%',
    },
    '& div.MuiInputBase-input, & div.MuiInputBase-root': {
      fontSize: 14,
      width: 61,
      borderRadius: 8,
    },
    '& div.MuiInputBase-input.MuiSelect-select': {
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 5,
      paddingRight: 30,
      fontSize: '11px',
      fontWeight: 500,
    },
    '& .MuiSvgIcon-root': {
      right: 4,
    },
    '& .MuiTablePagination-selectRoot': {
      marginRight: 4,
    },
    '& .MuiTablePagination-actions': {
      marginLeft: 4,
    },
  },
  buttonClass: {
    color: theme.variables.cCharcoal,
    backgroundColor: theme.variables.cAntiFlashWhite,
    borderRadius: 8,
    padding: 4,
    '&:first-child': {
      marginRight: 4,
      marginLeft: 0,
    },
  },

  selectLabel: {
    color: theme.variables.VHDarkGrey,
    fontSize: '12px',
    fontWeight: 500,
  },
  disabled: {
    '&.MuiButtonBase-root': {
      backgroundColor: theme.variables.cAntiFlashWhite,
    },
  },
}));
export const DEFAULT_PAGE_SIZE = 10;

const StyledTablePagination = ({
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  allRowsEnabled,
  generateButton,
}) => {
  const classes = useStyles();
  const inputClasses = useFilledInputStyles({
    inFormik: false,
    backgroundColor: 'cAntiFlashWhite',
    borderRadius: 8,
  });
  const intl = useIntl();
  const allText = intl.formatMessage({ id: 'all', defaultMessage: 'All' });

  const iconProps = {
    classes: {
      root: classes.buttonClass,
      disabled: classes.disabled,
    },
    variant: 'contained',
  };

  const rowsPerPageOptions = allRowsEnabled
    ? [5, DEFAULT_PAGE_SIZE, 20, 50, 100, { label: allText, value: -1 }]
    : [5, DEFAULT_PAGE_SIZE, 20, 50, 100];

  return (
    <Grid container wrap="nowrap" justify="flex-end">
      <Grid item>{generateButton && generateButton}</Grid>
      <Grid item>
        <TablePagination
          component={Grid}
          item
          container
          alignItems="center"
          justify="flex-end"
          xs="auto"
          className={classNames(classes.paginationContainer, 'h-100', inputClasses.input)}
          backIconButtonProps={iconProps}
          nextIconButtonProps={iconProps}
          backIconButtonText={intl.formatMessage({
            id: 'pagination.previous_page',
            defaultMessage: 'Previous page',
          })}
          nextIconButtonText={intl.formatMessage({
            id: 'pagination.next_page',
            defaultMessage: 'Next page',
          })}
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          classes={{
            caption: classes.selectLabel,
          }}
          onChangePage={(event, newPage) => setPage(newPage)}
          labelRowsPerPage={intl.formatMessage({ id: 'pagination.show', defaultMessage: 'Show' })}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            MenuProps: {
              classes: {
                paper: classNames(inputClasses.paper, inputClasses.filledPaper),
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          labelDisplayedRows={({ from, to, count }) =>
            intl.formatMessage(
              {
                id: 'pagination.paginationInfo',
                defaultMessage: '{from} to {to}({count})',
                description: 'Pagination text like 1-10 of 20 ',
              },
              { from, to: rowsPerPage > 0 ? to : count, count },
            )
          }
          onChangeRowsPerPage={(event) => {
            setRowsPerPage(event.target.value);
            setPage(0);
          }}
        />
      </Grid>
    </Grid>
  );
};

StyledTablePagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  allRowsEnabled: PropTypes.bool,
  generateButton: PropTypes.element,
};

StyledTablePagination.defaultProps = {
  allRowsEnabled: true,
  generateButton: null,
};

export default StyledTablePagination;
