import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const ComingSoon = () => (
  <Grid
    container
    justify="center"
    alignItems="center"
    spacing={4}
    className="dashboard h-100 w-100 p-t-20"
  >
    <Grid item xs={12}>
      <Typography variant="h4" align="center">
        <FormattedMessage
          id="new_software.first_row"
          defaultMessage="New software releases are expected in August 2020."
        />
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h5" align="center">
        <FormattedMessage
          id="new_software.second_row"
          defaultMessage="Out-of-the-box Analytics Features will include"
        />
      </Typography>
    </Grid>

    <Grid item>
      <List className="element">
        <ListItem className="item">
          <ListItemIcon>
            <span>•</span>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
            <FormattedMessage
              id="new_software.third_row"
              defaultMessage="Plug in existing collection cost to understand the potential cost savings"
            />
          </ListItemText>
        </ListItem>
        <ListItem className="item">
          <ListItemIcon>
            <span>•</span>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
            <FormattedMessage
              id="new_software.fourth_row"
              defaultMessage="Potential reduction of number of emptying’s with dynamic pickups"
            />
          </ListItemText>
        </ListItem>
        <ListItem className="item">
          <ListItemIcon>
            <span>•</span>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
            <FormattedMessage
              id="new_software.fifth_row"
              defaultMessage="Smart bin infrastructure suggestions"
            />
          </ListItemText>
        </ListItem>
        <ListItem className="item">
          <ListItemIcon>
            <span>•</span>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
            <FormattedMessage
              id="new_software.six_row"
              defaultMessage="Collection efficiency statistics based on fractions and regions"
            />
          </ListItemText>
        </ListItem>
        <ListItem className="item">
          <ListItemIcon>
            <span>•</span>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
            <FormattedMessage
              id="new_software.seven_row"
              defaultMessage="Tilt data understanding if the bins are being treated too harsh during collection"
            />
          </ListItemText>
        </ListItem>
        <ListItem className="item">
          <ListItemIcon>
            <span>•</span>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
            <FormattedMessage id="new_software.nine_row" defaultMessage="Temperature behavior" />
          </ListItemText>
        </ListItem>
        <ListItem className="item">
          <ListItemIcon>
            <span>•</span>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
            <FormattedMessage
              id="new_software.tenth_row"
              defaultMessage="Month on month number of overflowing bins"
            />
          </ListItemText>
        </ListItem>
      </List>
    </Grid>
  </Grid>
);

export default ComingSoon;
