/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { useRouteMatch } from 'react-router';
import ReusableTable from '../../../../../shared/table/table';
import HeaderRow from '../../../../../shared/table/headerRow';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import FilledSelect from '../../../../../shared/inputs/FilledSelect';
import PeriodFilter from '../../../../../shared/analyticsComponents/periodFilter';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';
import { getSavedActiveProjects, checkIfMomentDateIsValid } from '../../../../../shared/utils';
import {
  getPickupOrdersTableFilterableColumns,
  pickupOrdersTableColumns,
} from '../../../../../shared/utils/constants';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';
import useGetActiveTab from '../../../../../shared/hooks/useGetActiveTab';
import PickupOrdersHeadTabs from './components/PickupOrdersHeadTabs';
import { getUserSettings } from '../../../../../shared/utils/settings';
import { pickupMethods } from '../../../containers/routes/shared/utils';
import Address from '../../../../../shared/google/location';

const allPickupOrdersGql = loader(
  './../../../../../graphql/queries/operation_management/all_pickup_orders.graphql',
);

const pickupOrdersSelectValues = loader(
  './../../../../../graphql/queries/operation_management/pickup_orders_select_values.graphql',
);

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: 'calc(100% - 74px)',
  },
  indicator: {
    display: 'none',
  },
  selected: {
    border: '1px solid',
    borderColor: theme.variables.cAntiFlashWhite,
    backgroundColor: theme.variables.cAntiFlashWhite,
    borderRadius: '4px',
  },
  headerButtonArea: {
    alignItems: 'center',
    height: '100%',
    display: 'flex',
  },
  select: {
    textAlign: 'center',
    width: 'inherit',
    maxWidth: theme.spacing(22.5),
    marginLeft: 16,
    '& > .MuiFormControl-root': {
      '& > .MuiInput-formControl': {
        '& > .MuiSelect-select': {
          paddingLeft: '24px !important',
          paddingRight: '43px !important',
        },
      },
    },
  },
  searchField: {
    width: '100%',
  },
}));

const PickupOrders = ({ handleConfigChange, userLayoutConfig }) => {
  const match = useRouteMatch();
  const activeTab = useGetActiveTab(match, 'collected');
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const settings = useSelector((state) => getUserSettings(state));

  const [containerTypes, setContainerTypes] = useState([]);
  const [wasteFractions, setWasteFractions] = useState([]);
  const [selectedContainerTypes, setSelectedContainerTypes] = useState([]);
  const [selectedWasteFraction, setSelectedWasteFraction] = useState([]);
  const [pickupMethod, setPickupMethod] = useState('');
  const [searchString, setSearchString] = useState('');
  const [period, setPeriod] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (activeTab === 'planned') {
      setPeriod('next_month');
      setStartDate(moment().startOf('month'));
      setEndDate(moment().endOf('month'));
    } else if (activeTab === 'collected') {
      setPeriod('last_month');
      setStartDate(moment().subtract(1, 'months').startOf('month'));
      setEndDate(moment().subtract(1, 'months').endOf('month'));
    }
  }, [activeTab]);

  const classes = useStyles();

  const intl = useIntl();

  const updateCache = useCallback(async () => {
    //  ToDo remove obj from cache
  }, []);

  useQuery(pickupOrdersSelectValues, {
    variables: {
      activeProjects: savedActiveProjects,
    },
    onCompleted: ({ allContainerTypes, allWasteFractions } = {}) => {
      setContainerTypes(allContainerTypes?.edges?.map((item) => item.node));
      setWasteFractions(
        allWasteFractions?.edges?.map((item) => ({
          value: item?.node?.id,
          label: item?.node?.wasteTypes?.edges
            ?.map((wasteType) => wasteType?.node?.name)
            .join(', '),
        })) || '-',
      );
    },
  });
  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    pickupOrdersTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );

  const queryExtraVariables = useMemo(
    () => ({
      name: searchString,
      createdAt_Lte: checkIfMomentDateIsValid(endDate),
      createdAt_Gte: checkIfMomentDateIsValid(startDate),
      completedAt_Isnull: activeTab !== 'collected',
      activeProjects: savedActiveProjects,
      orderType_Icontains: 'pickup',
      containerTypeIds: selectedContainerTypes?.length ? selectedContainerTypes : undefined,
      wasteFractionIds: selectedWasteFraction?.length ? selectedWasteFraction : undefined,
      pickupMethod: pickupMethod === 'all' ? null : pickupMethod,
    }),
    [
      savedActiveProjects,
      searchString,
      startDate,
      endDate,
      activeTab,
      selectedContainerTypes,
      selectedWasteFraction,
      pickupMethod,
    ],
  );

  const pickupOrdersSerializer = ({
    allPickupOrders: { edges: arr = [], totalCount = 0 } = {},
  }) => ({
    totalCount,
    items: arr.map(({ node }) => ({
      id: node?.id,
      containerId: node?.container?.containerId,
      location: (
        <Address
          placeId={node.container?.location?.placeId}
          lat={node.container?.location?.latitude}
          lng={node.container?.location?.longitude}
        />
      ),
      wasteFraction: `${
        node?.container?.wasteFraction?.wasteTypes?.edges
          ?.map((wasteType) => wasteType?.node?.name)
          .join(', ') || ''
      } - ${node?.container?.wasteFraction?.wasteCategory || ''}`,
      containerTypes: node.container?.containerType.name,
      collected:
        node?.completedAt && node?.route?.project?.id
          ? settings[node?.route?.project?.id]?.getDateTime(moment(node?.completedAt))
          : '-',
      byWhom: node.completedAt
        ? `${node.route?.driver?.user?.firstName} ${node.route?.driver?.user?.lastName}`.trim() ||
          node.route?.driver?.user?.email
        : '-',
    })),
  });

  const cellsConfig = [
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getPickupOrdersTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'containerId',
      label: <FormattedMessage id="container_id" defaultMessage="Container ID" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'location',
      label: <FormattedMessage id="location" defaultMessage="Location" />,
      numeric: false,
      noFilter: true,
      disableOrder: true,
      disablePadding: false,
    },
    {
      id: 'containerTypes',
      label: <FormattedMessage id="Container_types" defaultMessage="Container types" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'wasteFraction',
      label: <FormattedMessage id="waste_fraction" defaultMessage="Waste fraction" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'collected',
      label: <FormattedMessage id="collected" defaultMessage="Collected" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'byWhom',
      label: <FormattedMessage id="by_whom" defaultMessage="By whom" />,
      numeric: false,
      disablePadding: false,
    },
  ];

  const selectTableRowCallback = () => {};

  const pageTitle = intl.formatMessage({
    id: 'collection_plan',
    defaultMessage: 'Collection plan',
  });

  const headCellsToDisplay = cellsConfig.filter((cell) =>
    activeTableColumns.some((tableColumn) => tableColumn === cell.id),
  );

  const extraFilter = useMemo(
    () => (
      <Grid className={classes.headerButtonArea}>
        <FormControl className={classNames(classes.formControl, classes.dateSelector)}>
          <PeriodFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            period={period}
            setPeriod={setPeriod}
            futurePeriod
            hidePeriodSelector
          />
        </FormControl>
        <FilledSelect
          name="containerTypes"
          placeholder={intl.formatMessage({
            id: 'Container_type',
            defaultMessage: 'Container types',
          })}
          multiSelect
          valuesList={containerTypes}
          className={classes.select}
          value={selectedContainerTypes}
          onChange={(e) => {
            setSelectedContainerTypes(
              e.target.value?.includes('')
                ? selectedContainerTypes.length === containerTypes.length
                  ? []
                  : containerTypes.map((item) => item.value)
                : e.target.value,
            );
          }}
          noneValueText={intl.formatMessage({
            id: 'all',
            defaultMessage: 'All',
          })}
        />
        <FilledSelect
          name="wasteFractions"
          placeholder={intl.formatMessage({
            id: 'collection_plan.waste_fractions',
            defaultMessage: 'Waste fractions',
          })}
          multiSelect
          valuesList={wasteFractions}
          className={classes.select}
          value={selectedWasteFraction}
          onChange={(e) => {
            setSelectedWasteFraction(
              e.target.value?.includes('')
                ? selectedWasteFraction.length === wasteFractions.length
                  ? []
                  : wasteFractions.map((item) => item.value)
                : e.target.value,
            );
          }}
          noneValueText={intl.formatMessage({
            id: 'all',
            defaultMessage: 'All',
          })}
        />
        <FilledSelect
          name="pickupMethods"
          placeholder={intl.formatMessage({
            id: 'pickup_method',
            defaultMessage: 'Pickup method',
          })}
          valuesList={pickupMethods}
          className={classes.select}
          value={pickupMethod}
          onChange={(e) => setPickupMethod(e.target.value)}
          required
        />
      </Grid>
    ),
    [
      classes.headerButtonArea,
      classes.formControl,
      classes.dateSelector,
      classes.select,
      startDate,
      endDate,
      period,
      intl,
      containerTypes,
      selectedContainerTypes,
      wasteFractions,
      selectedWasteFraction,
      pickupMethod,
    ],
  );
  return (
    <div className={classes.pageContainer}>
      <HeaderRow pageTitle={pageTitle}>
        <PickupOrdersHeadTabs />
      </HeaderRow>
      <ReusableTable
        userLayoutConfig={userLayoutConfig}
        updateUserConfig={handleConfigChange}
        cellsConfig={headCellsToDisplay}
        queryExtraVariables={queryExtraVariables}
        extraFilter={extraFilter}
        // TODO: add delete mutation
        deleteItemMutation={() => {}}
        query={allPickupOrdersGql}
        autoCompleteQuery={allPickupOrdersGql}
        itemsSerializer={pickupOrdersSerializer}
        updateCache={updateCache}
        chooseLogoSize={false}
        noLogoLink
        searchSize={3}
        tableContainerStyles={{ padding: '0 32px 32px 32px' }}
        tableComponentWrapperStyles={{ paddingTop: '20px' }}
        selectTableRowCallback={selectTableRowCallback}
        activeTableColumns={activeTableColumns}
        setSearchString={setSearchString}
        tableFontSize={14}
        isSearchAutocomplete={false}
        queryName="allPickupOrders"
        noCheckboxes
        noRowSelection
        excludeEditOption
        searchFieldClass={classes.searchField}
        paginationClass="pickup-orders-pagination"
      />
    </div>
  );
};

PickupOrders.propTypes = {
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default withUserLayoutConfig('pickupOrders')(PickupOrders);
