import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const FormikRadioGroup = ({ field, form: { touched, errors }, options, ...props }) => (
  <>
    <RadioGroup {...field} {...props} name={field.name}>
      {options.map((option) => (
        <FormControlLabel
          value={option.value}
          control={<Radio />}
          label={option.label}
          key={option.label}
          labelPlacement="start"
        />
      ))}
    </RadioGroup>

    {touched[field.name] && errors[field.name] && <>{errors[field.name]}</>}
  </>
);

FormikRadioGroup.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default FormikRadioGroup;
