import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { loader } from 'graphql.macro';
import { Space } from 'antd';
import moment from 'moment';
import Drawer from '@wastehero/storybook/lib/components/Drawer/Drawers/Routes';
import TableView from '@wastehero/storybook/lib/components/Views/Table';
import Button from '@wastehero/storybook/lib/components/Form/Elements/Button';
import CreatePickupSettingsModal from '@wastehero/storybook/lib/components/Modal/Modals/PickupSettings/Create';
import { getMapCenter } from '../../../../../shared/utils/settings';
import ProjectSelect from '../../../../../design-system/form/select/project';
import { getSavedActiveProjects } from '../../../../../shared/utils';

// eslint-disable-next-line no-unused-vars
const tableQuery = loader(
  './../../../../../graphql/queries/operation_management/all_pickup_settings.graphql',
);

const drawerQuery = loader('./../../../../../graphql/queries/operation_management/route.graphql');
const drawerRouteGeoJsonQuery = loader(
  './../../../../../graphql/queries/operation_management/route_geo_json.graphql',
);
const createMutation = loader(
  './../../../../../graphql/mutations/operation_management/update_or_create_pickup_settings.graphql',
);

const PickupSettings = () => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const mapCenter = useSelector((state) => getMapCenter(state));
  const [drawer, setDrawer] = useState('');
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      title: 'ID',
      dataIndex: ['node', 'id'],
      key: 'id',
    },
    {
      title: 'name',
      dataIndex: ['node', 'name'],
      key: 'name',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button title="View" size="small" onClick={() => setDrawer(record.node.id)} />
        </Space>
      ),
    },
  ];

  const breadcrumbBar = {
    title: 'Pickup Settings',
    createButtonTitle: 'Create Pickup Settings',
    createButtonAction: () => setShowModal(true),
    breadcrumbsLinks: [
      {
        label: 'Containers',
        path: '/route1',
      },
      {
        label: 'Pickup Settings',
        path: '/route2',
      },
    ],
  };

  const queryVariablesFormatter = (
    queryVariables,
    // filterValues,
    // pagination,
    // filters,
    // sorter,
    // extra,
  ) =>
    // console.log(
    //   'queryVariablesFormatter',
    //   queryVariables,
    //   filterValues,
    //   pagination,
    //   filters,
    //   sorter,
    //   extra,
    // );
    ({
      ...queryVariables,
    });

  const queryResultFormatter = (values) => ({
    data: values?.allPickupSettings.edges || [],
    pagination: {
      total: values?.allPickupSettings.totalCount,
    },
  });

  const queryVariables = {
    containerIsNull: true,
    activeProjects: savedActiveProjects,
  };

  const tableProps = {
    antdTableProps: {
      columns,
      rowKey: (record) => record.node.id,
    },
  };

  const drawerQueryVariablesFormatter = (qvs) => ({ ...qvs });

  const drawerQueryVariables = {
    id: drawer,
  };

  const queryTableProps = {
    query: tableQuery,
    queryResultFormatter,
    queryVariablesFormatter,
    queryVariables,
    tableProps,
  };
  return (
    <>
      <TableView queryTableProps={queryTableProps} breadcrumbBar={breadcrumbBar} />;
      <Drawer
        visible={!!drawer}
        onClose={() => setDrawer('')}
        query={drawerQuery}
        queryVariablesFormatter={drawerQueryVariablesFormatter}
        queryVariables={drawerQueryVariables}
        queryGeoJson={drawerRouteGeoJsonQuery}
        mapBoxProps={{
          center: {
            longitude: mapCenter.lng,
            latitude: mapCenter.lat,
          },
        }}
      />
      <CreatePickupSettingsModal
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        mutation={createMutation}
        mutationVariablesFormatter={(values) => ({
          ...values,
          pickupDay: Object.entries(values.pickupDay).map((key) => ({
            timeTo: moment(key[1].time.end).format('HH:mm:ss'),
            timeFrom: moment(key[1].time.start).format('HH:mm:ss'),
            weekday: key[0],
          })),
          containerStatus: 'available',
          project: values.project.value,
        })}
        projectSelect={<ProjectSelect multiple={false} required />}
        onSuccess={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

PickupSettings.propTypes = {};

export default PickupSettings;
