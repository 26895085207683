import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { connect as reduxConnect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import { connect, Form, getIn } from 'formik';
import {
  formikComponentMemoizeValueCheck,
  FormikImageDropzone,
  FormikPhoneField,
  FormikSelect,
  FormikTextField,
  memoizeFields,
} from '../../../../../shared/inputs/formik';
import formikInjectedPropsTypes from '../../../../../shared/inputs/formik/formikPropTypes';
import SubmitButtonsSet from '../../../../../shared/buttons/submitButtons';
import UploadImageButton from '../../../../../shared/buttons/uploadImageButton';
import {
  LICENSE_OPTIONS,
  PAYMENT_INTERVALS_OPTIONS,
  REGION_OPTIONS,
  TYPE_OF_COST,
  TYPE_OF_COST_VALUES,
} from '../../components/utils';
import SelectDepots from '../../../../../shared/apiPopulatedSelects/selectDepots';
import { daysOptions } from '../../../settings/project/defaults/data';
import MultiSelectDriverSkill from '../../../../../shared/apiPopulatedSelects/multiSelectDriverSkill';
import DateRangeFields from '../../components/DateRangeFields';
import SelectVehicles from '../../../../../shared/apiPopulatedSelects/selectVehicle';
import FormikTimeDiffFields from '../../../../../shared/inputs/formik/FormikTimeDiffFields';
import FormikButtonSwitch from '../../../../../shared/inputs/formik/FormikButtonSwitch';
import SelectUser from '../../../../../shared/apiPopulatedSelects/selectUser';
import FormikMaskedInput from '../../../../../shared/inputs/formik/FormikMaskedInput';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const useStyles = makeStyles((theme) => ({
  zoneLabel: {
    color: theme.variables.cEmperor,
    fontWeight: 500,
    fontSize: 22,
  },
  root: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 45,
  },
  paddedRows: {
    '&>div': {
      paddingBottom: 20,
    },
  },
  optionsText: {
    fontWeight: 'bold',
    fontSize: 16,
    right: 0,
    color: theme.variables.VHBlue,
    display: 'inline-block',
    '&:hover': {
      backgroundColor: theme.variables.cSnow,
      cursor: 'pointer',
    },
    width: '100%',
    textAlign: 'end',
    marginTop: 6,
  },
}));

const DriverEditFrom = ({ setPhotoFile, selectedUser, formik, endLocationVisibility, me }) => {
  const intl = useIntl();
  const classes = useStyles();

  const typeOfCost = getIn(formik.values, 'typeOfCost');
  const scheduleBreak = getIn(formik.values, 'scheduleBreak');
  const [endLocationVisible, setEndLocationVisible] = useState(endLocationVisibility);
  const [isRegularUser] = useRegularUser();

  const changeImage = useCallback(
    ({ file }) => {
      setPhotoFile(file);
    },
    [setPhotoFile],
  );

  useEffect(() => {
    if (!endLocationVisible) {
      formik.setFieldValue('endLocationId', formik.values.startLocationId);
    }
  }, [endLocationVisible, formik]);

  return (
    <Form className="w-100">
      <Grid container className={classNames(classes.root, classes.paddedRows)}>
        <Grid container item xs={12} justify="space-between" className={classes.paddedRows}>
          {isRegularUser && (
            <Grid item xs={12} className="text-align-center">
              <FormattedMessage
                id="no_permission_add_driver"
                defaultMessage="You don’t have permission to add driver"
              >
                {(text) => <span className="no-permission-text">{text}</span>}
              </FormattedMessage>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="location.form.identification" defaultMessage="Identification" />
            </Typography>
          </Grid>
          <Grid container item xs={12} md={9} justify="space-between">
            <Grid container item xs={12} md={7} className={classes.paddedRows}>
              <Grid item xs={12}>
                <SelectUser
                  name="userId"
                  autoFocus
                  extraFilter={{
                    isDriver: false,
                    jobTitle: 'Driver',
                  }}
                  isProjectOwner={!!me.ownProjectsIds.length}
                  ownProjectsIds={me.ownProjectsIds}
                  onChange={(user) => {
                    if (user) {
                      formik.setFieldValue('logo', user?.logo);
                      formik.setFieldValue('email', user?.email);
                      formik.setFieldValue('phone', user?.phoneNumber);
                    }
                  }}
                  extraValues={selectedUser ? [selectedUser] : []}
                  disabled={!!selectedUser}
                  label={intl.formatMessage({
                    id: 'location.form.label.invitedDrivers',
                    defaultMessage: 'Invited drivers',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'location.form.placeholder.invitedDrivers',
                    defaultMessage: 'Select driver from already invited to platform',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  name="employeeNumber"
                  label={intl.formatMessage({
                    id: 'driver.form.label.employeeNumber',
                    defaultMessage: 'Employee number',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'driver.form.placeholder.employeeNumber',
                    defaultMessage: 'e.g. abc123',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectVehicles required activeProjects={me.activeProjectsIds} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              className={classes.paddedRows}
              direction="column"
              wrap="nowrap"
            >
              <Grid item>
                <FormikTextField
                  name="email"
                  label={intl.formatMessage({
                    id: 'driver.form.label.email',
                    defaultMessage: 'Email',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'driver.form.placeholder.email',
                    defaultMessage: 'e.g.',
                  })}
                  required
                />
              </Grid>
              <Grid item>
                <FormikPhoneField
                  preferredCountries={['dk', 'gr', 'gb']}
                  defaultCountry="dk"
                  regions="europe"
                  name="phone"
                  label={intl.formatMessage({
                    id: 'driver.form.label.phone',
                    defaultMessage: 'Phone',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'driver.form.placeholder.phone',
                    defaultMessage: 'e.g.',
                  })}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={2}>
            <FormikImageDropzone size="small" onChange={changeImage} name="logo">
              <UploadImageButton />
            </FormikImageDropzone>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-between" className={classes.paddedRows}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="driver.form.workSchedule" defaultMessage="Work schedule" />
            </Typography>
          </Grid>
          <Grid container item xs={12} md={12} spacing={3}>
            <Grid container item xs={12} md={9} lg={7} spacing={3}>
              <Grid container item xs={12} md={6} alignItems="center" justify="space-between">
                <FormikTimeDiffFields
                  fromName="workTimeStart"
                  toName="workTimeEnd"
                  labelInline={false}
                  label={intl.formatMessage({
                    id: 'vehicle.label.workTime',
                    defaultMessage: 'Work time',
                  })}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikMaskedInput
                  name="allowedOvertime"
                  mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
                  type="text"
                  label={intl.formatMessage({
                    id: 'vehicle.label.allowedOvertime',
                    defaultMessage: 'Allowed overtime',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'vehicle.placeholder.allowedOvertime',
                    defaultMessage: 'e.g. 00:00:00',
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikSelect
                  name="daysAvailable"
                  filledStyle
                  multiSelect
                  placeholder={intl.formatMessage({
                    id: 'vehicle.placeholder.available_workdays',
                    defaultMessage: 'Choose available workdays',
                  })}
                  label={intl.formatMessage({
                    id: 'vehicle.label.available_workdays',
                    defaultMessage: 'Available workdays',
                  })}
                  valuesList={daysOptions}
                  required
                />
              </Grid>
              <Grid container item xs={12} md={6} spacing={1}>
                <Grid container item xs={12} md={6}>
                  <FormikTextField
                    name="allowedMaximumDailyHours"
                    type="number"
                    inputProps={{ min: '0', max: '24' }}
                    label={intl.formatMessage({
                      id: 'vehicle.label.allowedDailyHours',
                      defaultMessage: 'Allowed daily hours',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'vehicle.placeholder.allowedDailyHours',
                      defaultMessage: 'e.g. 8',
                    })}
                    required
                  />
                </Grid>
                <Grid container item xs={12} md={6}>
                  <FormikTextField
                    name="allowedMaximumWeeklyHours"
                    type="number"
                    inputProps={{ min: '0', max: '168' }}
                    label={intl.formatMessage({
                      id: 'vehicle.label.allowedWeeklyHours',
                      defaultMessage: 'Allowed weekly hours',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'vehicle.placeholder.allowedWeeklyHours',
                      defaultMessage: 'e.g. 40',
                    })}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={3} lg={5} spacing={3}>
              <Grid item xs={12}>
                <FormikButtonSwitch
                  label={intl.formatMessage({
                    id: 'vehicle.label.scheduleBreak',
                    defaultMessage: 'Schedule break',
                  })}
                  name="scheduleBreak"
                  valuesList={[
                    {
                      value: true,
                      label: intl.formatMessage({ id: 'yes', defaultMessage: 'Yes' }),
                    },
                    { value: false, label: intl.formatMessage({ id: 'no', defaultMessage: 'No' }) },
                  ]}
                />
              </Grid>
              {scheduleBreak && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <FormikTimeDiffFields
                    fromName="breakTimeStart"
                    toName="breakTimeEnd"
                    labelInline={false}
                    label={intl.formatMessage({
                      id: 'vehicle.label.breakTime',
                      defaultMessage: 'Break time',
                    })}
                  />
                </Grid>
              )}
              {!scheduleBreak && (
                <Grid item xs={12} md={12} lg={6}>
                  <FormikMaskedInput
                    name="breakDuration"
                    mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
                    type="text"
                    label={intl.formatMessage({
                      id: 'vehicle.label.breakDuration',
                      defaultMessage: 'Break duration',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'vehicle.placeholder.breakDuration',
                      defaultMessage: 'e.g. 00:00:00',
                    })}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-between" className={classes.paddedRows}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="driver.form.driverCost" defaultMessage="Driver cost" />
            </Typography>
          </Grid>
          <Grid container item xs={12} md={12} spacing={3}>
            <Grid container item xs={12} md={6}>
              <FormikSelect
                name="typeOfCost"
                filledStyle
                label={intl.formatMessage({
                  id: 'location.form.label.typeOfCost',
                  defaultMessage: 'Type of cost',
                })}
                valuesList={TYPE_OF_COST}
                clearField={() => formik.setFieldValue('startLocationId', '')}
                required
              />
            </Grid>
            {typeOfCost === TYPE_OF_COST_VALUES.variable && (
              <>
                <Grid item xs={12} md={3}>
                  <FormikTextField
                    name="costPerHour"
                    type="number"
                    label={intl.formatMessage({
                      id: 'driver.form.label.costPerHour',
                      defaultMessage: 'Cost per hour',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'location.form.placeholder.typeOfCost',
                      defaultMessage: 'e.g. 2$',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormikTextField
                    name="costPerHourForOvertime"
                    type="number"
                    label={intl.formatMessage({
                      id: 'driver.form.label.costPerHourForOvertime',
                      defaultMessage: 'Cost per overtime hour',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'location.form.placeholder.typeOfCost',
                      defaultMessage: 'e.g. 2$',
                    })}
                  />
                </Grid>
              </>
            )}
            {typeOfCost === TYPE_OF_COST_VALUES.fixed && (
              <>
                <Grid item xs={12} md={3}>
                  <FormikSelect
                    name="paymentInterval"
                    filledStyle
                    label={intl.formatMessage({
                      id: 'driver.form.label.paymentInterval',
                      defaultMessage: 'Payment interval',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'driver.form.placeholder.paymentInterval',
                      defaultMessage: 'Choose daily, monthly, yearly',
                    })}
                    valuesList={PAYMENT_INTERVALS_OPTIONS}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormikTextField
                    name="costPerInterval"
                    type="number"
                    label={intl.formatMessage({
                      id: 'driver.form.label.costPerInterval',
                      defaultMessage: 'Cost per interval',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'location.form.placeholder.typeOfCost',
                      defaultMessage: 'e.g. 2$',
                    })}
                    required
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-between" className={classes.paddedRows}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="driver.form.driverLogistic" defaultMessage="Driver logistic" />
            </Typography>
          </Grid>
          <Grid container item xs={12} md={12} spacing={3}>
            <Grid container item xs={12} md={6}>
              <SelectDepots
                name="startLocationId"
                label={
                  endLocationVisible
                    ? intl.formatMessage({
                        id: 'location.form.label.startLocation',
                        defaultMessage: 'Start location',
                      })
                    : intl.formatMessage({
                        id: 'location.form.label.location',
                        defaultMessage: 'Location',
                      })
                }
                placeholder={
                  endLocationVisible
                    ? intl.formatMessage({
                        id: 'location.form.placeholder.startLocation',
                        defaultMessage: 'Choose start location',
                      })
                    : intl.formatMessage({
                        id: 'location.form.placeholder.startendLocation',
                        defaultMessage: 'Choose start and end location',
                      })
                }
                multiSelect={false}
                clearField={() => formik.setFieldValue('startLocationId', '')}
              />
              {!endLocationVisible && (
                <Typography
                  className={classes.optionsText}
                  onClick={() => setEndLocationVisible(true)}
                >
                  <FormattedMessage
                    id="driver.form.choose_end_location"
                    defaultMessage="Choose different end location"
                  />
                </Typography>
              )}
            </Grid>
            <Grid container item xs={12} md={6}>
              <Collapse className="w-100" in={endLocationVisible}>
                <SelectDepots
                  name="endLocationId"
                  label={intl.formatMessage({
                    id: 'location.form.label.endLocation',
                    defaultMessage: 'End location',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'location.form.placeholder.endLocation',
                    defaultMessage: 'Choose end location',
                  })}
                  multiSelect={false}
                />
                <Typography
                  className={classes.optionsText}
                  onClick={() => setEndLocationVisible(false)}
                >
                  <FormattedMessage
                    id="driver.form.same_end_location"
                    defaultMessage="Choose same location for start and end"
                  />
                </Typography>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-between" className={classes.paddedRows}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="driver.form.driverSkills" defaultMessage="Driver skills" />
            </Typography>
          </Grid>
          <Grid container item xs={12} md={12} spacing={3}>
            <MultiSelectDriverSkill
              name="skills"
              defaultValue={{
                skillId: '',
                validFrom: null,
                validTo: null,
              }}
              serializer={({ skillId }) => skillId}
              extraInputs={({ index }) => (
                <Grid item xs={12} md={6} lg={5}>
                  <DateRangeFields prefix={`skills[${index}]`} showLabel={index === 0} />
                </Grid>
              )}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-between" className={classes.paddedRows}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage
                id="driver.form.driverPermission"
                defaultMessage="Driver permission"
              />
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6} spacing={3}>
            <Grid container item xs={12}>
              <FormikSelect
                name="licenseClass"
                filledStyle
                placeholder={intl.formatMessage({
                  id: 'driver.placeholder.licence_class',
                  defaultMessage: 'Choose licence class',
                })}
                label={intl.formatMessage({
                  id: 'driver.label.licence_class',
                  defaultMessage: 'Licence class',
                })}
                valuesList={LICENSE_OPTIONS}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                name="number"
                label={intl.formatMessage({
                  id: 'driver.form.label.licence_number',
                  defaultMessage: 'License number',
                })}
                placeholder={intl.formatMessage({
                  id: 'driver.form.placeholder.licence_number',
                  defaultMessage: 'e.g. 12345678',
                })}
                required
              />
            </Grid>
            <Grid container item xs={7}>
              <DateRangeFields showLabel />
            </Grid>
            <Grid container item xs={12}>
              <FormikSelect
                name="region"
                filledStyle
                placeholder={intl.formatMessage({
                  id: 'driver.placeholder.licence_region',
                  defaultMessage: 'Choose licence region',
                })}
                label={intl.formatMessage({
                  id: 'driver.label.licence_region',
                  defaultMessage: 'Region',
                })}
                valuesList={REGION_OPTIONS}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        {!isRegularUser && <SubmitButtonsSet />}
      </Grid>
    </Form>
  );
};

DriverEditFrom.propTypes = {
  setPhotoFile: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  endLocationVisibility: PropTypes.bool.isRequired,
  me: PropTypes.shape({
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
    activeProjectsIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

DriverEditFrom.defaultProps = {
  selectedUser: null,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default reduxConnect(mapStateToProps)(
  connect(
    React.memo(
      DriverEditFrom,
      memoizeFields([
        'setPhotoFile',
        'selectedUser',
        'editPage',
        formikComponentMemoizeValueCheck('typeOfCost'),
        formikComponentMemoizeValueCheck('scheduleBreak'),
        formikComponentMemoizeValueCheck('startLocationId'),
      ]),
    ),
  ),
);
