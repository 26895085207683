import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tour from 'reactour';
import Mascot from './mascot';
import * as TutorialSteps from './tutorialSteps';

const useStyles = makeStyles({
  nextButton: {
    position: 'absolute',
    right: 30,
    bottom: 20,
  },
  skipButton: {
    position: 'absolute',
    bottom: 20,
  },
  previousButton: {
    position: 'absolute',
    bottom: 25,
  },
});

const Tutorial = ({ isTutorialOpen, onCloseTutorial, tutorialType, history }) => {
  const [displayMascot, setDisplayMascot] = useState(true);
  const [disableInteraction, setDisableInteraction] = useState(true);
  const [isWelcomeMessage, setIsWelcomeMessage] = useState(false);
  const [tourStepNumber, setTourStepNumber] = useState(0);
  const [isUserNavigation, setIsUserNavigation] = useState(false);
  const [enterNowOption, setEnterNowOption] = useState(false);
  const [currentPageStepNumber, setCurrentPageStepNumber] = useState(0);

  const navigateOnUserClick = (url, isEnterNowOption) => {
    if (url) {
      setIsUserNavigation(true);
      const clearListener = history.listen((location) => {
        if (location.pathname.includes(url)) {
          setIsUserNavigation(false);
          const newStepNo = tourStepNumber + (isEnterNowOption ? 2 : 1);
          setCurrentPageStepNumber(newStepNo);
          setTourStepNumber(newStepNo);
          clearListener();
        }
      });
    } else {
      setIsUserNavigation(false);
    }
  };

  const exitTutorial = () => {
    onCloseTutorial(false);
    setDisplayMascot(false);
    setDisableInteraction(false);
    setTourStepNumber(0);
    setCurrentPageStepNumber(0);
    setIsWelcomeMessage(false);
    setIsUserNavigation(false);
    setEnterNowOption(false);
  };

  const classes = useStyles();
  const SkipTourButton = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        exitTutorial();
      }}
      disabled={false}
      className={classes.skipButton}
    >
      <FormattedMessage id="tutorial.skip_tutorial" defaultMessage="Skip" />
    </Button>
  );
  const NextTourButton = () => {
    const intl = useIntl();
    let buttonText;
    if (isWelcomeMessage) {
      buttonText = intl.formatMessage({
        id: 'tutorial.continue',
        defaultMessage: 'Continue',
      });
    } else if (enterNowOption) {
      buttonText = intl.formatMessage({
        id: 'tutorial.button.enter',
        defaultMessage: 'Enter details now',
      });
    } else {
      buttonText = intl.formatMessage({ id: 'tutorial.button.next', defaultMessage: 'Next' });
    }
    return (
      <>
        {(enterNowOption || !isUserNavigation) && (
          <Button variant="contained" color="primary" className={classes.nextButton}>
            {buttonText}
          </Button>
        )}
      </>
    );
  };
  const DoneButton = () => (
    <Button variant="contained" color="primary" className={classes.nextButton}>
      <FormattedMessage id="tutorial.done" defaultMessage="Done" />
    </Button>
  );

  const PrevButton = () => (
    <>
      {tourStepNumber - 1 >= currentPageStepNumber && (
        <ArrowBackIcon className={classes.previousButton} />
      )}
    </>
  );

  const nextButtonClick = () => {
    setTourStepNumber(tourStepNumber + 1);
  };

  const previousButtonClick = () => {
    setTourStepNumber(tourStepNumber - 1);
  };

  const tourStepsGenerator = (tutorialSteps) => {
    const tourSteps = tutorialSteps.map((step) =>
      Object({
        selector: step.isIntro ? '[tourid="mascot"]' : `[tourid="${step.highlightID}"]`,
        content: step.tutorialContent,
        action: (node) => {
          if (node) {
            setDisplayMascot(step.isIntro);
            setDisableInteraction(!step.enableInteraction);
            setIsWelcomeMessage(step.isWelcomeMessage);
            setEnterNowOption(step.enterNowOption);
            navigateOnUserClick(step.userNavigate, step.enterNowOption);
          } else {
            nextButtonClick();
          }
        },
        position: step.isIntro ? 'top' : null,
        style: {
          minWidth: step.isBig ? '30%' : '',
          paddingLeft: 40,
          paddingright: 40,
          paddingTop: 20,
          paddingBottom: step.isWelcomeMessage ? 75 : 50,
          display: step.tutorialContent ? '' : 'none',
        },
      }),
    );
    return tourSteps;
  };

  const getSteps = () => {
    switch (tutorialType) {
      case 'supportCenter':
        return tourStepsGenerator(TutorialSteps.supportCenterSteps);
      case 'adminCreateCompany':
        return tourStepsGenerator(TutorialSteps.adminTutorialSteps);
      case 'demoAccount':
        return tourStepsGenerator(TutorialSteps.demoAccountSteps);
      default:
        return [];
    }
  };

  const steps = getSteps();

  return (
    <>
      {steps.length > 0 && (
        <>
          <Tour
            steps={steps}
            isOpen={isTutorialOpen}
            onRequestClose={() => {
              exitTutorial();
            }}
            lastStepNextButton={<DoneButton />}
            rounded={5}
            showNumber={false}
            className="tutorial-mascot-dialogue"
            closeWithMask={false}
            disableInteraction={disableInteraction}
            maskClassName={displayMascot ? 'tutorial-no-mask' : ''}
            nextButton={<NextTourButton />}
            prevButton={isWelcomeMessage && steps.length > 1 ? <SkipTourButton /> : <PrevButton />}
            goToStep={tourStepNumber}
            nextStep={nextButtonClick}
            prevStep={previousButtonClick}
            showNavigation={false}
            disableKeyboardNavigation={isUserNavigation ? ['right', 'left'] : ['left']}
            scrollDuration={500}
          />
          {isTutorialOpen && <Mascot displayMascot={displayMascot} />}
        </>
      )}
    </>
  );
};

Tutorial.propTypes = {
  isTutorialOpen: PropTypes.bool.isRequired,
  onCloseTutorial: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  tutorialType: PropTypes.string.isRequired,
};

export default withRouter(Tutorial);
