import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  makeStyles,
  Typography,
  InputAdornment,
  Button,
  IconButton,
  DialogTitle,
  Grid,
  Dialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PasswordIcon from '../../../../../../../images/icons/password.svg';
import LoadingLayout from '../../../../../../shared/loading';
import { FormikTextField } from '../../../../../../shared/inputs/formik';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    zIndex: 10,
    padding: 20,
  },
  content: {
    margin: theme.spacing(2),
    padding: 20,
  },
  title: {
    color: '#024559',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 20,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  textField: {
    width: '100%',
    marginTop: 20,
  },
  textFieldIcon: {
    marginLeft: 10,
    opacity: 0.2,
  },
  submitBtn: {
    width: 400,
    height: 40,
    marginTop: 50,
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const userPasswordChange = () =>
  Yup.object().shape({
    currentPassword: Yup.string().required(),
    newPassword: Yup.string()
      .required()
      .min(
        8,
        <FormattedMessage
          id="password.validation.to_short"
          defaultMessage="Password is too short - should be 8 chars minimum."
        />,
      ),
    repeatPassword: Yup.string()
      .min(
        8,
        <FormattedMessage
          id="password.validation.to_short"
          defaultMessage="Password is too short - should be 8 chars minimum."
        />,
      )
      .oneOf(
        [Yup.ref('newPassword'), null],
        <FormattedMessage id="password.validation.match" defaultMessage="Passwords must match" />,
      )

      .required(),
  });

const adminPasswordChange = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required()
      .min(
        8,
        <FormattedMessage
          id="password.validation.to_short"
          defaultMessage="Password is too short - should be 8 chars minimum."
        />,
      ),
    repeatPassword: Yup.string()
      .min(
        8,
        <FormattedMessage
          id="password.validation.to_short"
          defaultMessage="Password is too short - should be 8 chars minimum."
        />,
      )
      .oneOf(
        [Yup.ref('newPassword'), null],
        <FormattedMessage id="password_match" defaultMessage="Passwords must match" />,
      )

      .required(),
  });

const ChangePasswordDialogue = ({ open, onConfirm, onClose, masterEdit }) => {
  const classes = useStyles();
  const intl = useIntl();

  const confirmPasswordChange = (formVariables, { setSubmitting, resetForm }) => {
    onConfirm({
      ...formVariables,
    })
      .then(() => resetForm())
      .catch(() => setSubmitting());
  };

  const localClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={localClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <DialogTitle>
        <Typography className={classes.title}>
          {masterEdit ? (
            <FormattedMessage id="reset_password" defaultMessage="Reset password" />
          ) : (
            <FormattedMessage id="change_password" defaultMessage="Change password" />
          )}
        </Typography>
        <IconButton className={classes.closeButton} onClick={localClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          repeatPassword: '',
        }}
        onSubmit={confirmPasswordChange}
        validationSchema={masterEdit ? adminPasswordChange() : userPasswordChange()}
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            className={classes.content}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                submitForm();
              }
            }}
          >
            <LoadingLayout isLoading={isSubmitting} />
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} md={9}>
                {!masterEdit && (
                  <FormikTextField
                    id="currentPassword"
                    name="currentPassword"
                    type="password"
                    required
                    autoComplete="none"
                    className={classes.textField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className={classes.textFieldIcon}
                            src={PasswordIcon}
                            alt="PasswordIcon"
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={intl.formatMessage({
                      id: 'current_password',
                      defaultMessage: 'Current Password',
                    })}
                    label={intl.formatMessage({
                      id: 'current_password',
                      defaultMessage: 'Current Password',
                    })}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={9}>
                <FormikTextField
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  required
                  autoComplete="new-password"
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          className={classes.textFieldIcon}
                          src={PasswordIcon}
                          alt="PasswordIcon"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={intl.formatMessage({
                    id: 'placeholder.password',
                    defaultMessage: "E.g. 'pa$$w0rd'",
                  })}
                  label={intl.formatMessage({
                    id: 'label.password',
                    defaultMessage: "E.g. 'Password'",
                  })}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <FormikTextField
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  required
                  autoComplete="new-password"
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          className={classes.textFieldIcon}
                          src={PasswordIcon}
                          alt="PasswordIcon"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={intl.formatMessage({
                    id: 'confirm_password',
                    defaultMessage: 'Confirm Password',
                  })}
                  label={intl.formatMessage({
                    id: 'confirm_password',
                    defaultMessage: 'Confirm Password',
                  })}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center" className={classes.submitBtn}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  className="w-100"
                  type="button"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  <FormattedMessage id="change_password" defaultMessage="Change password" />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

ChangePasswordDialogue.propTypes = {
  open: PropTypes.bool.isRequired,
  masterEdit: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangePasswordDialogue;
