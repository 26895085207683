import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect, getIn } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormikTextField from '../../../../shared/inputs/formik/FormikTextField';
import { formikInjectedPropsTypes, memoizeFields } from '../../../../shared/inputs/formik';

const useStyles = makeStyles((theme) => ({
  addText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.variables.VHBlue,
    display: 'inline-block',
    '&:hover': {
      backgroundColor: theme.variables.cSnow,
      cursor: 'pointer',
    },
  },
  inputFiled: {
    marginBottom: 15,
  },
}));

const AddMoreInputs = ({
  label,
  placeholder,
  addText,
  hideCountForFirst,
  name,
  required,
  formik,
  customInput,
  customInputClasses,
  inputType,
  extraAdornment,
}) => {
  const value = getIn(formik.values, name);
  const { setFieldValue } = formik;
  const classes = useStyles();

  useEffect(() => {
    if (!value.length) {
      setFieldValue(name, ['']);
    }
  }, [value.inputFiled, name, value.length, setFieldValue]);

  const handleAdd = useCallback(() => {
    setFieldValue(name, [...value, '']);
  }, [name, setFieldValue, value]);

  const handleRemoveItem = useCallback(
    (index) => () => {
      if (value.length > 1) {
        setFieldValue(name, [...value.slice(0, index), ...value.slice(index + 1)]);
      } else {
        setFieldValue(name, ['']);
      }
    },
    [name, setFieldValue, value],
  );

  const CustomInputComponent = customInput;
  return (
    <div>
      {value.map((inputControl, index) =>
        customInput ? (
          <CustomInputComponent
            label={
              <>
                {label} {hideCountForFirst && index === 0 ? '' : index + 1}
              </>
            }
            customClasses={customInputClasses}
          />
        ) : (
          <FormikTextField
            key={index}
            required={required}
            className={classes.inputFiled}
            name={`${name}[${index}]`}
            type={inputType}
            placeholder={placeholder}
            label={
              <>
                {label} {hideCountForFirst && index === 0 ? '' : index + 1}
              </>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {extraAdornment}
                  <IconButton
                    aria-label="remove item"
                    color="inherit"
                    onClick={handleRemoveItem(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ),
      )}
      <Typography className={classes.addText} onClick={handleAdd}>
        {addText}
      </Typography>
    </div>
  );
};

AddMoreInputs.propTypes = {
  addText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  hideCountForFirst: PropTypes.bool,
  customInput: PropTypes.string,
  inputType: PropTypes.oneOf(['text', 'number']),
  customInputClasses: PropTypes.objectOf(PropTypes.string),
  extraAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

AddMoreInputs.defaultProps = {
  hideCountForFirst: false,
  customInput: null,
  inputType: 'text',
  customInputClasses: {},
  extraAdornment: null,
};

export default connect(
  React.memo(
    AddMoreInputs,
    memoizeFields([
      'addText',
      'name',
      'label',
      'placeholder',
      'hideCountForFirst',
      ({ name, formik }) => getIn(formik.values, name).join(', '),
    ]),
  ),
);
