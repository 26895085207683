import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useSelector, connect } from 'react-redux';
import FilledSelect from '../../../../../shared/inputs/FilledSelect';
import { DEPOT_TYPES, DEPOTS_TYPE_VALUES } from '../../components/utils';
import AddButton from '../../components/addButton';
import ListPageTemplate from '../../components/listPageTemplate';
import OpeningHours from '../../components/openingHours';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import {
  locationsTableColumns,
  getLocationsTableFilterableColumns,
} from '../../../../../shared/utils/constants';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';
import {
  getSavedActiveProjects,
  getUsersAvailableProjects,
  saveCacheRead,
} from '../../../../../shared/utils';
import ButtonTooltip from '../../../../../shared/tooltip/ButtonTooltip';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const allDepotsQuery = loader(
  './../../../../../graphql/queries/fleet_management/all_depots.graphql',
);
const filterDepotsQuery = loader(
  './../../../../../graphql/queries/fleet_management/filter_depots.graphql',
);
const deleteDepotMutation = loader(
  './../../../../../graphql/mutations/fleet_management/delete_depot.graphql',
);

const useStyles = makeStyles(() => ({
  select: {
    textAlign: 'center',
  },
}));

const depotAutocompleteSerializer = ({ allDepots: { edges: arr = [] } = {} }) =>
  arr.map(({ node }) => ({
    primaryText: node.name,
    secondaryText: node.contactPerson?.name,
    logo: node.logo,
    id: node.id,
  }));

const LocationsList = ({ userLayoutConfig, handleConfigChange, me, activeCompanies }) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));
  const classes = useStyles();
  const intl = useIntl();
  const [depotTypeFilter, setDepotTypeFilter] = useState('');
  const [isRegularUser] = useRegularUser();

  const depotSerializer = ({ allDepots: { edges: arr = [], totalCount = 0 } = {} }) => ({
    totalCount,
    items: arr.map(({ node }) => ({
      name: node.name,
      type: DEPOT_TYPES.find(({ value }) => value === node.depotType)?.label,
      openingHours: <OpeningHours closingTime={node.closingTime} openingTime={node.openingTime} />,
      contact: node.contactPerson?.name,
      logo: node.logo,
      id: node.id,
      allowActions: !!me.ownProjectsIds.length,
    })),
  });

  const queryExtraVariables = useMemo(
    () => ({
      type: depotTypeFilter === DEPOTS_TYPE_VALUES.all ? null : depotTypeFilter,
      activeProjects: savedActiveProjects,
      activeCompanies,
    }),
    [depotTypeFilter, savedActiveProjects, activeCompanies],
  );

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    locationsTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );
  const cellsConfig = [
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getLocationsTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      label: <FormattedMessage id="location_list.name" defaultMessage="Name" />,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'type',
      label: <FormattedMessage id="location_list.type" defaultMessage="Type" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'openingHours',
      label: <FormattedMessage id="location_list.opening_hours" defaultMessage="Opening Hours" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'contact',
      label: <FormattedMessage id="location_list.contact" defaultMessage="Contact" />,
      numeric: false,
      disablePadding: false,
    },
  ];

  const [deleteDepot] = useMutation(deleteDepotMutation);

  const extraFilter = useMemo(
    () => (
      <>
        <Grid item xs={5}>
          <FilledSelect
            name="location_type"
            placeholder={intl.formatMessage({
              id: 'location_type',
              defaultMessage: 'Location type',
            })}
            valuesList={DEPOT_TYPES}
            required
            className={classes.select}
            value={depotTypeFilter}
            onChange={(e) => setDepotTypeFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={5} tourid="addLocation">
          <ButtonTooltip
            text={
              <FormattedMessage
                id={
                  // eslint-disable-next-line no-nested-ternary
                  isRegularUser
                    ? 'tooltip.no_permission_add_location'
                    : usersAvailableProjects?.length
                    ? 'tooltip.add_vehicle'
                    : 'no_available_projects'
                }
                defaultMessage={
                  // eslint-disable-next-line no-nested-ternary
                  isRegularUser
                    ? "You don't have permission to add location"
                    : usersAvailableProjects?.length
                    ? 'Click to add a new location for fleet management'
                    : 'Please add at least one project'
                }
              />
            }
          >
            <AddButton
              text={<FormattedMessage id="location_type.label.add" defaultMessage="Add location" />}
              disabled={
                !(
                  me.isAdmin ||
                  me.isSuperuser ||
                  me.isMaster ||
                  me.isReseller ||
                  me.ownProjectsIds.length
                )
              }
            />
          </ButtonTooltip>
        </Grid>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [depotTypeFilter, intl, classes.select, usersAvailableProjects.length],
  );

  const updateCache = useCallback(
    (depot) => async (cache) => {
      const depotType = DEPOT_TYPES.find(({ label }) => depot.type === label)?.value;

      const allDepotsFullQuery = {
        query: allDepotsQuery,
        variables: {
          type: depotType || null,
          activeCompanies,
        },
      };

      const {
        data: { allDepots: allDepotsData },
      } = await saveCacheRead(allDepotsFullQuery);

      const newDepotsData = {
        ...allDepotsData,
        edges: allDepotsData.edges.filter(({ node }) => node.id !== depot.id),
      };

      cache.writeQuery({
        ...allDepotsFullQuery,
        data: {
          allDepots: newDepotsData,
        },
      });
    },
    [activeCompanies],
  );

  return (
    <ListPageTemplate
      userLayoutConfig={userLayoutConfig}
      updateUserConfig={handleConfigChange}
      cellsConfig={cellsConfig}
      autocompleteSerializer={depotAutocompleteSerializer}
      autoCompleteQuery={filterDepotsQuery}
      queryExtraVariables={queryExtraVariables}
      extraFilter={extraFilter}
      deleteItemMutation={deleteDepot}
      query={allDepotsQuery}
      itemsSerializer={depotSerializer}
      pageTitle={intl.formatMessage({
        id: 'locations.overview_page',
        defaultMessage: 'Location overview',
      })}
      updateCache={updateCache}
      activeTableColumns={activeTableColumns}
      excludeDeleteOption={!!me.ownProjectsIds.length}
      disableSelect
    />
  );
};

LocationsList.propTypes = {
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string.isRequired,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  me: PropTypes.shape({
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isMaster: PropTypes.bool,
    isReseller: PropTypes.bool,
  }).isRequired,
  activeCompanies: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
  activeCompanies: Array.from(
    new Set(state.settings.user.activeProjects.edges.map(({ node }) => node.company.id)),
  ),
});

export default connect(mapStateToProps)(withUserLayoutConfig('locations')(LocationsList));
