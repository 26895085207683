import React from 'react';
import { Checkbox } from 'formik-material-ui';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CheckIcon from '../../../../images/icons/checkboxOn.svg';
import CheckboxIcon from '../../../../images/icons/checkboxOff.svg';
import FormikField from './FormikField';
import FormikFastField from './FormikFastField';

const FormikCheckbox = ({ fast, ...props }) => {
  const Field = fast ? FormikFastField : FormikField;

  return (
    <Field
      component={Checkbox}
      type="checkbox"
      {...props}
      checkedIcon={<img src={CheckIcon} alt="checked" />}
      icon={<img src={CheckboxIcon} alt="unchecked" />}
    />
  );
};

FormikCheckbox.propTypes = {
  fast: PropTypes.bool,
};

FormikCheckbox.defaultProps = {
  fast: false,
};

export default withStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
}))(FormikCheckbox);
