import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import RoutesPage from './routes/Routes';
import CreateRoutePage from './routes/CreateRoute';
import InquiriesPage from './routes/Inquiries';

const Routes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/routes`} component={RoutesPage} />
    <Route exact path={`${match.url}/routes/create`} component={CreateRoutePage} />
    <Route exact path={`${match.url}/inquiries`} component={InquiriesPage} />
  </Switch>
);
Routes.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};
export default Routes;
