import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useMutation, useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { toastifyError } from '../../../../../shared/utils';
import LoadingLayout from '../../../../../shared/loading';
import UserList from './usersListLayout';
import ConfirmDeletionDialog from '../../../../../shared/dialog/ConfirmDeletionDialog';
import ConfirmDialog from '../../../../../shared/dialog/ConfirmDialog';

const allCompanies = loader('./../../../../../graphql/queries/core/all_companies.graphql');
const removeUserFromProjectQuery = loader(
  './../../../../../graphql/mutations/core/remove_user_from_project.graphql',
);
const deleteUserQuery = loader('./../../../../../graphql/mutations/core/delete_user.graphql');

const allCompaniesQuery = {
  query: allCompanies,
  variables: { withUsers: true },
};

const ProjectsWithUsersList = ({ me }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [deletingUser, setDeletingUser] = useState({});
  const [removeFromProject, setRemoveFromProject] = useState({});
  const [deletePOError, setDeletePOError] = useState(false);

  const [removeUserFromProjectMutation] = useMutation(removeUserFromProjectQuery, {
    awaitRefetchQueries: true,
    refetchQueries: [allCompaniesQuery],
  });

  const [deleteUserMutation] = useMutation(deleteUserQuery, {
    awaitRefetchQueries: true,
    refetchQueries: [allCompaniesQuery],
  });

  const {
    loading: companiesLoading,
    data: { allCompanies: { edges: companies = [] } = {} } = {},
  } = useQuery(allCompaniesQuery.query, { variables: { ...allCompaniesQuery.variables } });

  const handleDeleteUser = (func, project, userProject) => {
    if (userProject.isSuper) {
      const projectOwners = project.userprojectSet.edges.filter(({ node }) => node.isSuper);
      if (projectOwners.length === 1) {
        setDeletePOError(true);
        return;
      }
    }
    func(userProject);
  };

  const confirmRemoveUserFromProject = () => {
    setLoading(true);
    const userProjectId = removeFromProject.id;
    removeUserFromProjectMutation({
      variables: {
        userProjectId,
      },
      async update(
        cache,
        {
          data: {
            deleteUserFromProject: { status },
          },
        },
      ) {
        if (status !== 'Success') {
          return;
        }
        const { allCompanies: cachedCompanies } = cache.readQuery(allCompaniesQuery);
        const filteredEdges = cachedCompanies.edges.map((company) => {
          const deepCopyCompany = JSON.parse(JSON.stringify(company));
          const {
            node: { projectSet },
          } = company;
          if (projectSet.edges) {
            deepCopyCompany.node.projectSet.edges = projectSet.edges.map(
              ({ node: project, ...projectData }) => {
                const projectCopy = JSON.parse(JSON.stringify(project));

                projectCopy.userprojectSet.edges = project.userprojectSet.edges.filter(
                  ({ node }) => node.id !== userProjectId,
                );
                return { node: projectCopy, ...projectData };
              },
            );
          }
          return deepCopyCompany;
        });
        cache.writeQuery({
          ...allCompaniesQuery,
          data: { allCompanies: { ...cachedCompanies, edges: filteredEdges } },
        });
      },
    })
      .catch((error) => toastifyError(error))
      .finally(() => {
        setRemoveFromProject({});
        setLoading(false);
      });
  };

  const confirmDeleteUser = () => {
    setLoading(true);
    const userId = deletingUser.user.id;
    deleteUserMutation({
      variables: {
        userId,
      },
      async update(
        cache,
        {
          data: {
            deleteUser: { status },
          },
        },
      ) {
        if (status !== 'Success') {
          return;
        }
        const { allCompanies: cachedCompanies } = cache.readQuery(allCompaniesQuery);
        const filteredEdges = cachedCompanies.edges.map((company) => {
          const deepCopyCompany = JSON.parse(JSON.stringify(company));

          const {
            node: { projectSet },
          } = company;
          if (projectSet.edges) {
            deepCopyCompany.node.projectSet.edges = projectSet.edges.map(
              ({ node: project, ...projectData }) => {
                const projectCopy = JSON.parse(JSON.stringify(project));

                projectCopy.userprojectSet.edges = project.userprojectSet.edges.filter(
                  ({ node }) => node.user.id !== userId,
                );
                return { node: projectCopy, ...projectData };
              },
            );
          }
          return deepCopyCompany;
        });
        cache.writeQuery({
          ...allCompaniesQuery,
          data: { allCompanies: { ...cachedCompanies, edges: filteredEdges } },
        });
      },
    })
      .catch((error) => toastifyError(error))
      .finally(() => {
        setDeletingUser({});
        setLoading(false);
      });
  };

  return (
    <Grid container justify="center">
      <LoadingLayout isLoading={loading || companiesLoading} />
      <Grid container justify="center" item xs={12} md={8}>
        <Grid item xs={12} className="m-b-20 m-t-20">
          <Typography variant="h4" align="center">
            <FormattedMessage id="user_management" defaultMessage="User management" />
          </Typography>
        </Grid>
        <Paper className="w-100 m-b-20 m-t-20">
          {companies.map(({ node: company }) =>
            company.projectSet && company.projectSet.edges.length ? (
              <Grid item xs={12} key={company.id}>
                {company.projectSet.edges.map(({ node: project }) => (
                  <React.Fragment key={project.id}>
                    <UserList
                      me={me}
                      key={project.id}
                      project={project}
                      deleteHandle={(userProject) =>
                        handleDeleteUser(setDeletingUser, project, userProject)
                      }
                      removeFromProject={(userProject) =>
                        handleDeleteUser(setRemoveFromProject, project, userProject)
                      }
                      usersList={project.userprojectSet.edges}
                    />
                    <Divider />
                  </React.Fragment>
                ))}
              </Grid>
            ) : null,
          )}
        </Paper>
      </Grid>
      <ConfirmDeletionDialog
        open={!!removeFromProject.id}
        onConfirm={() => confirmRemoveUserFromProject()}
        onClose={() => setRemoveFromProject({})}
        title={
          removeFromProject.user?.firstName && removeFromProject.user?.lastName
            ? `${removeFromProject.user.firstName} ${removeFromProject.user.lastName}`
            : intl.formatMessage({ id: 'this_user', defaultMessage: 'this user' })
        }
        helpText={intl.formatMessage({
          id: 'help_text.remove_user_from_project',
          defaultMessage: 'This will remove it from this project',
        })}
      />
      <ConfirmDeletionDialog
        open={!!deletingUser.id}
        onConfirm={() => confirmDeleteUser()}
        onClose={() => setDeletingUser({})}
        title={
          deletingUser.user?.firstName && deletingUser.user?.lastName
            ? `${deletingUser.user.firstName} ${deletingUser.user.lastName}`
            : intl.formatMessage({ id: 'this_user', defaultMessage: 'this user' })
        }
        helpText={intl.formatMessage({
          id: 'help_text.delete_user',
          defaultMessage: 'This will delete the account and remove it from this project',
        })}
      />
      <ConfirmDialog
        open={deletePOError}
        onClose={() => {
          setDeletePOError(false);
        }}
        title={
          <FormattedMessage
            id="delete_po_error"
            defaultMessage="You are the only one Project Owner on the project"
          />
        }
        noDelete
      />
    </Grid>
  );
};

ProjectsWithUsersList.propTypes = {
  me: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isMaster: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(ProjectsWithUsersList);
