import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from '../../shared/styles';
import FaqContainer from '../../shared/faqContainer';
import ListStyled from '../../shared/listStyled';

const FaqsSensor = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <FaqContainer
        title={<FormattedMessage id="faqs_sensor_header" defaultMessage="FAQs" />}
        objects={[
          {
            header: (
              <FormattedMessage
                id="faqs_sensor_question_one"
                defaultMessage="How do I clean the sensor"
              />
            ),
            texts: [
              <FormattedMessage
                id="faqs_sensor_item_q1_first"
                defaultMessage="Follow the instructions in <linkText>Sensor > Maintenance > Cleaning</linkText>."
                values={{
                  linkText: (text) => (
                    <Link to="sensor-maintenance" className={classes.links}>
                      {text}
                    </Link>
                  ),
                }}
              />,
              <FormattedMessage
                id="faqs_sensor_item_q1_second"
                defaultMessage="We recommend using an ultra-fine microfiber cloth (suitable for the use of cleaning glass lenses) and isopropyl alcohol as a cleaning agent. Use safety goggles when cleaning to protect your eyes. Read more about these items in the <linkText>Equipment for Installation section.</linkText>"
                values={{
                  linkText: (text) => (
                    <Link to="sensor-equipment-install" className={classes.links}>
                      {text}
                    </Link>
                  ),
                }}
              />,
              <FormattedMessage
                id="faqs_sensor_item_q1_third"
                defaultMessage="Moisten a microfiber cloth with isopropyl alcohol. The cloth should be damp but not dripping, so be sure to squeeze any excess solution from the cloth."
              />,
              <FormattedMessage
                id="faqs_sensor_item_q1_fourth"
                defaultMessage="Wipe the moistened cloth over the glass surface in smooth motions. Do not press hard on the glass surface or rub repeatedly in one spot. If pooling or streaks occur, you may have too much solution on the cloth."
              />,
              <FormattedMessage
                id="faqs_sensor_item_q1_fifth"
                defaultMessage="Ensure there are no visible impurities left on the glass. You may wish to wipe the glass surface with a dry microfiber cloth if you have used too much solution and it hasn't evaporated."
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="faqs_sensor_question_two"
                defaultMessage="How often should I clean the sensor?"
              />
            ),
            texts: [
              <FormattedMessage
                id="faqs_sensor_item_q2_first"
                defaultMessage="It is important that the glass on the sensors remain clean to ensure they provide accurate readings. WasteHero recommends cleaning the sensors every  6-12 months"
              />,
              <FormattedMessage
                id="faqs_sensor_item_q2_second"
                defaultMessage="Cleaning should be more regular if the sensors:"
              />,
              <ListStyled
                items={[
                  <FormattedMessage
                    id="faqs_sensor_item_q2_list_item_first"
                    defaultMessage="are exposed to adverse weather conditions"
                  />,
                  <FormattedMessage
                    id="faqs_sensor_item_q2_list_item_second"
                    defaultMessage="are taking a high frequency of measurements"
                  />,
                  <FormattedMessage
                    id="faqs_sensor_item_q2_list_item_third"
                    defaultMessage="are exposed to waste fractions with oily residue"
                  />,
                ]}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="faqs_sensor_question_third"
                defaultMessage="How do I install the sensor?"
              />
            ),
            texts: [
              <FormattedMessage
                id="faqs_sensor_item_q3_first"
                defaultMessage="Link to the Installation Manual once it is written."
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="faqs_sensor_question_fourth"
                defaultMessage="Should the container surface be cleaned prior to sensor installation?"
              />
            ),
            texts: [
              <FormattedMessage
                id="faqs_sensor_item_q4_first"
                defaultMessage="If the container surface is contaminated with any foreign material you must clean it prior to installation of the sensor, otherwise it may interrupt the device installation process."
              />,
              <FormattedMessage
                id="faqs_sensor_item_q4_second"
                defaultMessage="Do this by using sandpaper to remove any solid impurities from the from the surface. Then, moisten a microfiber cloth with isopropyl alcohol and wipe over the surface area to remove any foreign material or contamination. If the alcohol doesn’t evaporate, use a clean, dry microfiber cloth to wipe the surface dry."
              />,
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default FaqsSensor;
