import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const SupportTextPage = ({ title, texts, subTitle }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
          {subTitle}
        </Typography>
      </Grid>
      <Grid item xs={11}>
        {texts.map((text, index) => (
          <Typography
            key={index}
            variant="body1"
            gutterBottom
            className={classes.textContent}
            component="div"
          >
            {text}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

SupportTextPage.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

SupportTextPage.defaultProps = {
  title: '',
  texts: [],
  subTitle: '',
};

export default SupportTextPage;
