const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minHeight: '100%',
    background: theme.variables.cWhite,
  },
  card: {
    padding: '40px 47px',
    boxSizing: 'border-box',
    borderRadius: 3,
  },
  fieldRow: {
    marginTop: 20,
    position: 'relative',
  },
  textField: {
    width: '100%',
  },
  paddedSelect: {
    width: '100%',
    paddingLeft: 35,
  },
  submitBtn: {
    height: 52,
    marginTop: 50,
    fontSize: 16,
    borderRadius: 32.5,
    width: '50%',
  },
  submitBtnAdmin: {
    height: 42,
    marginTop: 10,
    fontSize: 12,
    borderRadius: 32.5,
    width: '80%',
  },
  btn_toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left_group: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
  },
  right_group: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  formLabel: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: `${theme.variables.cLightPeach}!important`,
    color: `${theme.variables.cWhite}!important`,
  },
  submitBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  errorMessage: {
    marginTop: 20,
    fontSize: 14,
    minHeight: 30,
    color: theme.variables.cLightRed,
  },
  userTypesButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userTypeButton: {
    width: 'calc(33.33% - 4px)',
    borderRadius: 32.5,
    textTransform: 'none',
    height: 44,
    margin: '0 4px',
  },
  subtitleText: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.variables.VHDarkGrey,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  titleText: {
    fontSize: 24,
    lineHeight: '30px',
    color: theme.variables.cEmperor,
    fontWeight: 'bold',
    marginBottom: 32,
  },
  text: {
    lineHeight: '20px',
    color: theme.variables.cEmperor,
    maxWidth: 800,
  },
  projectSettingsStepTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: theme.variables.VHDarkGrey,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default styles;
