import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}));

const emulateClick = (id, onClick) => (event) => {
  const click = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  document.getElementById(id).dispatchEvent(click);
  if (onClick) {
    onClick(event);
  }
};

const SettingsSelect = ({
  required,
  label,
  value,
  onChange,
  valuesList,
  readOnly,
  id,
  onClick,
  className,
  InputLabelProps,
}) => {
  const classes = useStyles();

  const inputLabel = React.useRef(null);

  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const inputId = id || 'settingsSelect';

  return (
    <FormControl required={required} className={`${classes.formControl} ${className}`}>
      <InputLabel
        ref={inputLabel}
        id="settingsSelectLabel"
        style={{ zIndex: 1 }}
        htmlFor={inputId}
        onClick={emulateClick(inputId, onClick)}
        {...InputLabelProps}
      >
        {label}
      </InputLabel>
      <Select
        id={inputId}
        value={value}
        onChange={onChange}
        labelWidth={labelWidth}
        onClick={onClick}
        style={{
          marginTop: 0,
        }}
        inputProps={{
          readOnly,
        }}
      >
        {!required && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {valuesList.map((choice) => (
          <MenuItem key={choice.value} value={choice.value} disabled={choice.disabled}>
            {choice.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SettingsSelect.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  valuesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  InputLabelProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
};

SettingsSelect.defaultProps = {
  required: false,
  label: '',
  readOnly: false,
  className: '',
  id: '',
  onClick: null,
  InputLabelProps: {},
  valuesList: [],
};

export default SettingsSelect;
