import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AttachDeviceToContainerPanel from '../shared/attachDeviceToContainer';
import { FormikSelect, FormikTextField } from '../../../../../shared/inputs/formik';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import SelectProjects from '../../../../../shared/apiPopulatedSelects/selectProjects';

function getSteps() {
  return [
    <FormattedMessage id="steps.select_project" defaultMessage="Select project" />,
    <FormattedMessage id="steps.select_sensor_ID" defaultMessage="Select sensor ID" />,
    <FormattedMessage id="steps.select_container" defaultMessage="Select container" />,
    <FormattedMessage
      id="steps.select_measurement_settings"
      defaultMessage="Select measurement settings"
    />,
  ];
}

function getStepContent(
  step,
  allContainers,
  allMeasurementSettings,
  setSelectedProject,
  setFieldValue,
  classes,
  values,
  setOpenRegisterContainer,
  setOpenRegisterMS,
  intl,
  setValues,
) {
  switch (step) {
    case 0:
      return (
        <Grid item xs={12}>
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.choose_project"
                defaultMessage="Choose the project to which the container belongs to"
              />
            }
            tooltipStyle={classes.tooltipContainer}
          >
            <SelectProjects
              name="projectId"
              onChange={(value) => {
                setSelectedProject((prevProjectId) => {
                  if (prevProjectId !== value) {
                    setFieldValue('containerId', '', false);
                    return value;
                  }
                  return prevProjectId;
                });
              }}
            />
          </TooltippedUserControl>
        </Grid>
      );
    case 1:
      return (
        <Grid item xs={12} className={classes.textFieldContainer}>
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage id="tooltip.deviceId" defaultMessage="Enter unique device id" />
            }
          >
            <FormikTextField
              name="deviceId"
              required
              type="text"
              className={classes.textField}
              placeholder={intl.formatMessage({
                id: 'placeholder.deviceId',
                defaultMessage: 'E.g. 5126812059612395068',
              })}
              label={intl.formatMessage({ id: 'label.deviceId', defaultMessage: 'Sensor ID' })}
            />
          </TooltippedUserControl>
        </Grid>
      );
    case 2:
      return (
        <Grid item xs={12} className={classes.containerFormWrapper}>
          <AttachDeviceToContainerPanel
            values={values}
            containerId={values.containerId}
            canUnAssign={false}
            setAndValidateContainerId={(containerId) => {
              if (containerId && containerId !== values.containerId) {
                const selectedContainer = allContainers.find(({ value }) => value === containerId);
                if (selectedContainer) {
                  setValues({
                    ...values,
                    internalHeight: Number(
                      (selectedContainer.containerType.height - values.offset).toFixed(3),
                    ),
                    containerId,
                  });
                }
              }
            }}
            resetContainerSelect={() => {
              setFieldValue('internalHeight', 0);
              setFieldValue('containerId', '');
            }}
            selectOrCreate
            onCreate={() => {
              setOpenRegisterContainer(true);
            }}
          />
        </Grid>
      );
    case 3:
      return allMeasurementSettings.length ? (
        <Grid item xs={12} container spacing={5}>
          <Grid item xs={8}>
            <TooltippedUserControl
              tooltipText={
                <FormattedMessage
                  id="tooltip.measurement_settings"
                  defaultMessage="Select measurement settings for device"
                />
              }
            >
              <FormikSelect
                required
                filledStyle
                name="measurementId"
                valuesList={allMeasurementSettings}
                label={intl.formatMessage({
                  id: 'label.measurement_settings',
                  defaultMessage: 'Measurement settings',
                })}
                placeholder={intl.formatMessage({
                  id: 'placeholder.measurement_settings',
                  defaultMessage: 'Select measurement settings',
                })}
              />
            </TooltippedUserControl>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="space-between"
            alignItems="center"
            className="p-t-50"
          >
            <FormattedMessage id="or" defaultMessage="or" />
            &nbsp;
            <Button onClick={() => setOpenRegisterMS(true)} variant="outlined" type="button">
              <FormattedMessage id="create_custom" defaultMessage="Create custom" />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <span className={classes.horizontalSpan}>
          <Typography>
            <FormattedMessage
              id="no_measurement_registered"
              defaultMessage="No measurement settings registered."
            />
          </Typography>
          <NavLink
            to={`/app/settings/${values.projectId}/measurement-settings`}
            className={classes.navLink}
          >
            <Typography>
              <FormattedMessage
                id="link.add_measurement"
                defaultMessage="Click to add new measurement settings."
              />
            </Typography>
          </NavLink>
        </span>
      );
    default:
      return 'Unknown step';
  }
}

const getStepFields = (step) => {
  switch (step) {
    case 0:
      return {
        allFields: ['projectId'],
        required: ['projectId'],
      };
    case 1:
      return {
        allFields: ['deviceId'],
        required: ['deviceId'],
      };
    case 2:
      return {
        allFields: ['containerId', 'lid', 'internalHeight', 'offset', 'deviceAngle'],
        required: ['containerId', 'lid', 'internalHeight', 'offset'],
      };
    case 3:
      return {
        allFields: ['measurementId'],
        required: ['measurementId'],
      };
    default:
      return {
        allFields: [],
        required: [],
      };
  }
};
/*
  Check all fields is valid and has value(return True if all is valid)
*/
const checkStepFields = (errors, value, step) => {
  const fields = getStepFields(step);
  return fields.required.every((field) => !errors[field] && value[field]);
};

export { getStepContent, getSteps, getStepFields, checkStepFields };
