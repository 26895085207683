import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from '../../shared/styles';
import ListStyled from '../../shared/listStyled';
import FaqContainer from '../../shared/faqContainer';

const PlatformContainer = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <FaqContainer
        title={<FormattedMessage id="platform_container_header" defaultMessage="Containers" />}
        text={[
          <FormattedMessage
            id="platform_container_abstract_first"
            defaultMessage="In the <bold>Containers</bold> section, you will be presented with an overview of the containers within your project in a map and list view. Here is where you'll add new containers to your project. You can also get detailed insight into specific containers including history of fill-levels and dates the container was emptied."
            values={{ bold: (text) => <b>{text}</b> }}
          />,
          <FormattedMessage
            id="platform_container_abstract_second"
            defaultMessage="Remember that to add a new container type, you will need to navigate to <bold>Settings > Container Settings</bold>."
            values={{ bold: (text) => <b>{text}</b> }}
          />,
        ]}
        faqs={<FormattedMessage id="platform_platform_container_faqs" defaultMessage="FAQs" />}
        objects={[
          {
            header: (
              <FormattedMessage
                id="platform_container_question_one"
                defaultMessage=" What's the difference between a container and a container type?"
              />
            ),
            texts: [
              <ListStyled
                items={[
                  <FormattedMessage
                    id="platform_container_item_q1_first"
                    defaultMessage="A container is the individual physical container on the street."
                  />,
                  <FormattedMessage
                    id="platform_container_item_q1_second"
                    defaultMessage="A container type is a pre-set of settings you can apply when you register a new physical container."
                  />,
                  <FormattedMessage
                    id="platform_container_item_q1_third"
                    defaultMessage="Different container types will depend on variables such as capacity, emplacement, or vehicle coupling."
                  />,
                ]}
              />,
              <FormattedMessage
                id="platform_container_item_q1_fourth"
                defaultMessage="The purpose of fixed container types is to speed up the new container registration process. Create a container type just once in settings, then use it for the same type of container going forwards."
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_container_question_two"
                defaultMessage="How do I add a new container type?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_container_item_q2_first"
                defaultMessage="<linkText>Link to Container Settings FAQs</linkText> "
                values={{
                  linkText: (text) => (
                    <Link to="platform-settings" className={classes.links}>
                      {text}
                    </Link>
                  ),
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_container_question_third"
                defaultMessage="How do I add a new waste fraction type?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_container_item_q3_first"
                defaultMessage="<linkText>Link to Waste Fraction Settings FAQs</linkText>"
                values={{
                  linkText: (text) => (
                    <Link to="platform-settings" className={classes.links}>
                      {text}
                    </Link>
                  ),
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_container_question_fourth"
                defaultMessage="How do I add a new container?"
              />
            ),
            texts: [
              <ListStyled
                items={[
                  <FormattedMessage
                    id="platform_container_item_q4_first"
                    defaultMessage="Navigate to <bold>Containers</bold> on the main menu, then <bold>Add Container</bold> in the sub-menu. Here is where you will set up your new container. Enter a unique ID and description for the container."
                    values={{ bold: (text) => <b>{text}</b> }}
                  />,
                  <FormattedMessage
                    id="platform_container_item_q4_second"
                    defaultMessage="Enter the location of the container. A pop-up box will appear; begin typing in the address of the container then select the correct location from the list and click confirm. Click upload image to select a photo from your files to add an image of your container (optional)."
                  />,
                  <FormattedMessage
                    id="platform_container_item_q4_third"
                    defaultMessage="Select the container type and waste fraction from the drop-down lists. This will show the existing container types and waste fractions that you've created in settings. Tick edit measurements if you need to change the internal container dimensions."
                  />,
                  <FormattedMessage
                    id="platform_container_item_q4_fourth"
                    defaultMessage="Select how you’ll register the container based on whether you have a new/existing sensor or not. If you don't have a sensor yet, you’re finished with adding the container once you click <bold>Register without sensor</bold>. Remember you can add the sensor later on."
                    values={{ bold: (text) => <b>{text}</b> }}
                  />,
                ]}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_container_question_fifth"
                defaultMessage="How do I view the details of a specific container?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_container_item_q5_second"
                defaultMessage="Click <bold>Detailed</bold> to view all details of your chosen container."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_container_question_sixth"
                defaultMessage="How can I change how the list of containers is sorted?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_container_item_q6_first_"
                defaultMessage="The order of the containers is sorted by the last measurement by default, but you can choose to sort by any of the header options.
              For example, you may want to view the containers in the list based on highest or lowest fill-levels."
              />,
              <FormattedMessage
                id="platform_container_item_q6_second"
                defaultMessage="Click the header title <bold>Fill level</bold> once for the descending value <bold>(signified by a down arrow)</bold>, and again for the ascending value <bold>(signified by an up arrow)</bold>. Repeat this action for any header option that you would like to sort by."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_container_question_seventh"
                defaultMessage="Is it possible to filter the list of containers in the list?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_container_item_q7_first"
                defaultMessage="You can filter the type of containers that appear in the list by container type, fill-level, and waste type. The container type and waste type will depend on the settings you have configured for your containers."
              />,
              <FormattedMessage
                id="platform_container_item_q7_second"
                defaultMessage="Filter by using the blue filter toggle button on the right border of the screen, then tick which container types you want to view in the list. Use the fill-level slider to select the fill-level you want to see from 0% to 100% fullness. Finally, select the waste type of the containers that you want to view in the list. Clear these settings by clicking <bold>CLEAR ALL.</bold>"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default PlatformContainer;
