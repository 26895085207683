import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid, Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import MapComponent from '../../../../../shared/map';
import ShowLogModal from './components/logModal';
import BatteryIndicator from './components/tableCell/BatteryIndicator';
import styles from './styles';
import {
  getSavedActiveProjects,
  getWasteFractionFromContainer,
  getDeviceFromContainer,
  toastifyError,
  getUsersAvailableProjects,
  saveCacheRead,
  uniqueWasteFractions,
} from '../../../../../shared/utils';
import Settings, { getActiveProjects, getUserSettings } from '../../../../../shared/utils/settings';
import { filterContainerDeviceOptions, filterValues, getFillIndicatorColor } from './utils';
import TableActionMenu from './components/tableActionMenu/TableActionMenu';
import ReusableTable from '../../../../../shared/table/table';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';
import {
  getContainersTableFilterableColumns,
  containersTableColumns,
} from '../../../../../shared/utils/constants';
import Address from '../../../../../shared/google/location';
import FilledSelect from '../../../../../shared/inputs/FilledSelect';
import ContainerDetails from './components/containerDetails/ContainerDetails';
import { generateContainerTypes } from '../../../../../shared/notificationComponents/utils';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const deleteContainerQuery = loader(
  './../../../../../graphql/mutations/devices/delete_container.graphql',
);

const allContainersQueryGql = loader(
  './../../../../../graphql/queries/devices/all_containers.graphql',
);

const allContainerQuery = loader(
  './../../../../../graphql/queries/devices/all_containers_dashboard.graphql',
);

const containersAnalytics = loader(
  './../../../../../graphql/queries/devices/containers_analytics.graphql',
);

const containersAutocompleteGql = loader(
  './../../../../../graphql/queries/containers/containers_autocomplete.graphql',
);

const getContainer = loader('./../../../../../graphql/queries/devices/one_container.graphql');

const getFilterData = loader(
  './../../../../../graphql/queries/containers/container_filters.graphql',
);

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    overflowX: 'auto',
  },
  addButtonLink: {
    textDecoration: 'none',
  },
  selectFilter: {
    padding: '0px 12px',
    minWidth: 0,
    width: 'auto',
    maxWidth: '155px',
    '& .MuiInput-input, & .MuiInputBase-input': {
      '&.MuiSelect-select': {
        padding: '6px 35px 6px 15px',
      },
    },
  },
  accordion: {
    alignItems: 'center',
    marginBottom: '0 !important',
    backgroundColor: '#eff4f6',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.14)',
    '& > *': {
      marginBottom: '0 !important',
    },
  },
  tableContainer: {
    paddingLeft: '20px',
    paddingRight: '10px',
    marginBottom: '5px',
    minHeight: '38vh',
  },
  wrapDescription: {
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  searchField: {
    padding: 0,
  },
  headerContainersTitle: {
    fontSize: '16px',
    marginLeft: '20px',
    fontWeight: 'bold',
    lineHeight: '1.75',
    color: '#939393',
  },
  headerCurrentContainerTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.75',
  },
  button: {
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    margin: '10px 4px',
    padding: '8px 32px',
    border: '1px solid',
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    '& .MuiTab-wrapper': {
      alignItems: 'start',
    },
    '&.Mui-selected': {
      color: theme.variables.VHOrange,
    },
    opacity: 1,
    paddingLeft: '20px',
    padding: 0,
    minHeight: 0,
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'bold',
    color: '#024559',
    margin: '6px 0',
  },
  overviewTitle: {
    borderBottom: '1px solid #DF9838',
    paddingBottom: '5px',
  },
  containerImage: {
    textAlign: 'center',
    '& > img': {
      width: '154px',
      height: '154px',
    },
  },
  containerAttributeTitle: {
    margin: '6px 5px 5px 41px',
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#989dac',
    lineHeight: 'normal',
  },
  containerAttributeValue: {
    margin: '6px 30px 5px 5px',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#555555',
    lineHeight: 'normal',
    maxWidth: '70%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  divider: {
    margin: '5px 30px 5px 41px',
  },
  dateTabs: {
    '&.Mui-selected': {
      backgroundColor: '#ccc',
    },
    opacity: 1,
    minWidth: 0,
    fontSize: '14px',
    fontWeight: 500,
    color: '#474e62',
    width: '116px',
    height: '36px',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#fafafa',
    marginRight: '4px',
    textTransform: 'none',
  },
  chartActionButton: {
    margin: '0px 7px',
    backgroundColor: '#f0f2f7',
    padding: '10px 16px',
    border: 'none',
    borderRadius: '22px',
    position: 'relative',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  chartActionsText: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#989dac',
    textAlign: 'center',
    marginTop: '2px',
  },
  chartBlock: {
    position: 'relative',
    height: '23vh !important',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  absoluteCentered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  emptyPhotosBlock: {
    height: '100%',
    width: '100%',
    padding: '78px 100px',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    border: '2px dashed #c4c7d2',
    position: 'relative',
    borderRadius: '26px',
  },
  dragAndDropBlock: {
    width: 'max-content',
  },
  browseButton: {
    font: '14px',
    lineHeight: '24px',
    color: '#024559',
    border: '1px solid #024559',
    marginLeft: '15px',
    borderRadius: '22px',
    padding: '5px 32px',
    textTransform: 'none',
  },
  selectContainer: {
    '& .MuiSelect-root': {
      paddingTop: '23px',
      paddingBottom: '7px',
    },
  },
  notificationsSelectField: {
    padding: '4px 12px 4px 18px',
    minWidth: 0,
    width: '100%',
    '& .MuiInput-input, & .MuiInputBase-input': {
      '&.MuiSelect-select': {
        padding: '6px 35px 6px 15px',
      },
    },
    '& .MuiFormLabel-root	': {
      fontSize: '12px',
      color: theme.variables.VHLightBlack,
    },
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
  },
  showMasked: {
    textAlign: 'right',
  },
}));

const ContainersMap = ({
  handleConfigChange,
  userLayoutConfig,
  savedActiveProjects,
  settings,
  user,
  history,
  activeProjects,
  selectedContainerFromDashboard,
}) => {
  const classes = useStyles();
  const [currentActiveContainer, setCurrentActiveContainer] = useState(null);
  const [selectedContainerCoords, setSelectedContainerCoords] = useState(null);
  const [showLog, setShowLog] = useState(false);
  const [allWasteFractions, setAllWasteFractions] = useState([]);
  const [allContainerTypes, setAllContainerTypes] = useState([]);
  const [filterContainerDevice, setFilterContainerDevice] = useState(null);
  const [filteredContainerTypes, setFilteredContainerTypes] = useState([]);
  const [filteredWasteFractions, setFilteredWasteFractions] = useState([]);
  const [allContainersList, setAllContainersList] = useState([]);
  const [selectedRowDetails, setSelectedRowDetails] = useState(null);
  const [isNeedRefetch, setIsNeedRefetch] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [searchStringTimeoute, setSearchStringTimeout] = useState(null);
  const [isRegularUser] = useRegularUser();

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    containersTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );
  const [activeDateTab, setActiveTab] = useState('month');
  const scrollbarRef = useRef(null);

  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));

  const uniqueObjectsFilter = (arr) =>
    Array.from(new Set(arr?.map((a) => a.name))).map((name) => arr?.find((a) => a.name === name)) ||
    [];

  const getDateFromTab = useMemo(() => {
    const date = {
      from: moment(),
      to: moment(),
    };

    switch (activeDateTab) {
      case 'day':
        date.from = date.from.subtract(1, 'day');
        break;
      case 'week':
        date.from = date.from.subtract(7, 'days');
        break;
      case 'month':
        date.from = date.from.subtract(1, 'months');
        break;
      case 'quarter':
        date.from = date.from.subtract(3, 'months');
        break;
      case 'year':
        date.from = date.from.subtract(1, 'years');
        break;
      case 'allTime':
        date.from = moment(0);
        break;
      default:
        break;
    }
    return date;
  }, [activeDateTab]);

  const handleSetSearchString = (value) => {
    if (searchStringTimeoute) {
      window.clearTimeout(searchStringTimeoute);
      setSearchStringTimeout(null);
    }
    setSearchStringTimeout(
      window.setTimeout(() => {
        setSearchString(value);
      }, 1000),
    );
  };

  const [getContainerFiltersData] = useLazyQuery(getFilterData, {
    variables: {
      activeProjects: savedActiveProjects,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (filtersResponse) => {
      if (filtersResponse?.allContainerTypes?.edges) {
        setAllContainerTypes(
          uniqueObjectsFilter(filtersResponse.allContainerTypes.edges.map((item) => item.node)),
        );
      }
      if (filtersResponse?.allWasteFractions?.edges) {
        setAllWasteFractions(
          uniqueWasteFractions(filtersResponse.allWasteFractions.edges.map((item) => item.node)),
        );
      }
    },
  });

  useEffect(() => {
    if (!allWasteFractions.length && !allContainerTypes.length) {
      getContainerFiltersData();
    }
  }, [allContainerTypes, allWasteFractions, getContainerFiltersData]);

  const [getContainerDetails, { data: detailsData, loading: detailsLoading }] = useLazyQuery(
    getContainer,
    {
      variables: {
        fillLevelMeasurementSetCreatedAt_Gte: moment().startOf('month'),
        fillLevelMeasurementSetCreatedAt_Lte: moment().startOf('month').add(1, 'months'),
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const onSelectContainer = useCallback((selectedContainer) => {
    setSelectedContainerCoords([
      selectedContainer?.location?.latitude,
      selectedContainer?.location?.longitude,
    ]);
    setCurrentActiveContainer(selectedContainer);
    setSelectedRowDetails(selectedContainer?.id);
  }, []);

  useEffect(() => {
    if (selectedContainerFromDashboard) {
      onSelectContainer(selectedContainerFromDashboard);
    }
  }, [selectedContainerFromDashboard, onSelectContainer]);

  useEffect(() => {
    if (detailsData) {
      const { container } = detailsData;
      onSelectContainer(container);
    }
  }, [detailsData, onSelectContainer]);

  const intl = useIntl();

  const fractions = useMemo(
    () =>
      allWasteFractions.map((node) => ({
        value: node.id,
        label: getWasteFractionFromContainer({ wasteFraction: node }),
      })),
    [allWasteFractions],
  );

  const containerTypes = useMemo(
    () => generateContainerTypes(allContainerTypes.map((node) => node)),
    [allContainerTypes],
  );

  const queryExtraVariables = useMemo(() => {
    const variables = {
      name: searchString || '',
      activeProjects: savedActiveProjects,
      sensor_Isnull: filterContainerDevice !== filterValues.all ? filterContainerDevice : undefined,
      triggerManualRefresh: isNeedRefetch,
    };
    if (filteredContainerTypes.length) {
      variables.containerTypes = filteredContainerTypes;
    }
    if (filteredWasteFractions.length) {
      let ungroupedWasteFractions = [];
      filteredWasteFractions.forEach((item) => {
        let wFraction = item;
        if (wFraction.includes(',')) {
          wFraction = wFraction.split(',');
        }
        ungroupedWasteFractions = ungroupedWasteFractions.concat(wFraction);
      });
      variables.wasteTypes = ungroupedWasteFractions;
    }
    return variables;
  }, [
    searchString,
    savedActiveProjects,
    filteredWasteFractions,
    filteredContainerTypes,
    filterContainerDevice,
    isNeedRefetch,
  ]);

  const [deleteContainer] = useMutation(deleteContainerQuery, {
    variables: { containerId: currentActiveContainer?.id },
    refetchQueries: [
      { query: allContainersQueryGql, variables: queryExtraVariables },
      { query: containersAnalytics, variables: { activeProjects: savedActiveProjects } },
    ],
    awaitRefetchQueries: true,
    onError: (err) => toastifyError(err),
    onCompleted: () => {
      setIsNeedRefetch(Math.random());
    },
    update: async (
      cache,
      {
        data: {
          deleteContainer: { status },
        },
      },
    ) => {
      if (status !== 'Success') {
        throw new Error(`Container could not be removed: ${status}`);
      }

      /* Update cached containers on Dashboard page */
      const allContainerFullQuery = {
        query: allContainerQuery,
        variables: { activeProjects: savedActiveProjects },
      };

      const {
        data: { allContainers: allContainersQueryData },
      } = await saveCacheRead(allContainerFullQuery);

      const newContainerData = {
        ...allContainersQueryData,
        edges: allContainersQueryData.edges.filter(
          ({ node }) => node.id !== currentActiveContainer?.id,
        ),
      };

      cache.writeQuery({
        ...allContainerFullQuery,
        data: {
          allContainers: newContainerData,
        },
      });

      setAllContainersList(newContainerData.edges);
    },
  });

  const onShowLogByPin = (container) => {
    setCurrentActiveContainer(container);
    setShowLog(true);
  };

  const handleAddContainerClick = (e) => {
    if (!usersAvailableProjects?.length) {
      e.preventDefault();
    }
  };

  const onChangeFilterContainerDevice = (event) => {
    setFilterContainerDevice(event.target.value);
    // TODO filter containers  filterContainers();
  };

  const extraFilter = useMemo(
    () => (
      <Grid container item xs={10} spacing={2} justify="flex-start">
        <Grid item xs={4}>
          <FilledSelect
            name="containerTypes"
            placeholder={intl.formatMessage({
              id: 'notificationSettings.containerTypes',
              defaultMessage: 'Container types',
            })}
            valuesList={containerTypes}
            required
            multiSelect
            value={filteredContainerTypes}
            className={classes.selectFilter}
            onChange={(e) => setFilteredContainerTypes(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <FilledSelect
            name="fractions"
            placeholder={intl.formatMessage({
              id: 'notificationSettings.fractionFilter',
              defaultMessage: 'Waste fractions',
            })}
            valuesList={fractions}
            required
            multiSelect
            value={filteredWasteFractions}
            className={classes.selectFilter}
            onChange={(e) => setFilteredWasteFractions(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <FilledSelect
            placeholder={intl.formatMessage({
              id: 'label.filterBySensors',
              defaultMessage: 'Sensor',
            })}
            value={filterContainerDevice}
            name="filterContainerDevice"
            onChange={onChangeFilterContainerDevice}
            valuesList={filterContainerDeviceOptions}
            className={classes.selectFilter}
            required
            renderFalsyValues
          />
        </Grid>
      </Grid>
    ),
    [
      classes.selectFilter,
      filterContainerDevice,
      intl,
      fractions,
      containerTypes,
      filteredContainerTypes,
      filteredWasteFractions,
    ],
  );

  const handleCloseShowLog = () => setShowLog(false);

  const goToAttachDevice = () => {
    history.push(`/app/containers/attach-to/${currentActiveContainer?.id}`);
  };

  const showLogHandler = () => {
    setShowLog(true);
  };

  const hasSensor =
    currentActiveContainer?.deviceToContainerSet?.edges?.filter(({ node }) => node.endDate === null)
      .length > 0;

  const noDataFormattedMessage = <FormattedMessage id="-" defaultMessage="-" />;

  const containersSerializer = ({ allContainers: { edges: arr = [], totalCount = 0 } = {} }) => ({
    totalCount,
    items: arr.map(({ node }) => ({
      id: node.id,
      state: (
        <span
          className="stateIndicator"
          style={{
            background: getFillIndicatorColor(
              node.measurement ? node.measurement.fillPercentage : undefined,
            ),
          }}
        />
      ),
      projectName: node.project.name,
      address: (
        <Address
          placeId={node.location.placeId}
          lng={node.location.longitude}
          lat={node.location.latitude}
        />
      ),
      battery: node.measurement ? (
        <BatteryIndicator batteryLevel={node.measurement.batteryPercentage} />
      ) : (
        noDataFormattedMessage
      ),
      rsi:
        node?.measurement?.rsi || node?.measurement?.rsi === 0
          ? node.measurement.rsi
          : noDataFormattedMessage,
      fillLevel:
        (node.measurement && `${Math.round(node.measurement.fillPercentage || 0)}%`) ||
        noDataFormattedMessage,
      fillLevelRaw: node.measurement
        ? settings[node.project.id]?.getMeasuringDistance(node.measurement.fillLevel)
        : noDataFormattedMessage,
      sensorId: getDeviceFromContainer(node)?.device?.devId || noDataFormattedMessage,
      description: /\S/.test(node?.description) ? node.description : noDataFormattedMessage,
      containerId: node.containerId,
      container_type: node.containerType.name,
      lastSeenDate: node.measurement
        ? settings[node.project.id]?.getDateTime(node.measurement.createdAt)
        : noDataFormattedMessage,
      measurementSettings:
        getDeviceFromContainer(node)?.measurementSettings?.name || noDataFormattedMessage,
      wasteType: getWasteFractionFromContainer(node),
    })),
  });

  const cellsConfig = [
    {
      id: 'state',
      label: <FormattedMessage id="fill_status" defaultMessage="Status" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'projectName',
      label: <FormattedMessage id="project_name" defaultMessage="Project name" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'address',
      label: <FormattedMessage id="address" defaultMessage="Address" />,
      numeric: false,
      noFilter: true,
      disableOrder: true,
      className: classes.wrapDescription,
    },
    {
      id: 'battery',
      label: <FormattedMessage id="battery" defaultMessage="Battery" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'rsi',
      label: <FormattedMessage id="RSSI" defaultMessage="RSSI" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'fillLevel',
      label: <FormattedMessage id="fill_level" defaultMessage="Fill level" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'fillLevelRaw',
      label: <FormattedMessage id="fill_level_raw" defaultMessage="Fill level (raw)" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'sensorId',
      label: <FormattedMessage id="sensor_id" defaultMessage="Sensor ID" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'description',
      label: <FormattedMessage id="description" defaultMessage="Description" />,
      numeric: false,
      disablePadding: false,
      className: classes.wrapDescription,
    },
    {
      id: 'containerId',
      label: <FormattedMessage id="container_id" defaultMessage="Container ID" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'container_type',
      label: <FormattedMessage id="container.types" defaultMessage="Container type" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'lastSeenDate',
      label: <FormattedMessage id="last_measurement" defaultMessage="Last measurement" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'wasteType',
      label: <FormattedMessage id="containersmap.waste_fraction" defaultMessage="Waste fraction" />,
      numeric: false,
      disablePadding: false,
      className: classes.wrapDescription,
    },
    {
      id: 'measurementSettings',
      label: <FormattedMessage id="measurement_settings" defaultMessage="Measurement settings" />,
      numeric: false,
      disablePadding: false,
      noFilter: true,
      className: classes.wrapDescription,
    },
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getContainersTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
  ];

  const headCellsToDisplay = cellsConfig.filter((cell) =>
    activeTableColumns.some((tableColumn) => tableColumn === cell.id),
  );

  const updateCache = useCallback(async () => {
    //  ToDo remove obj from cache
  }, []);

  const selectTableRowCallback = (containerId) => {
    if (!containerId) {
      setCurrentActiveContainer(null);
      return;
    }
    if (containerId === currentActiveContainer?.id) {
      return;
    }

    getContainerDetails({
      variables: {
        id: containerId,
        fillLevelMeasurementSetCreatedAt_Gte: getDateFromTab.from,
        fillLevelMeasurementSetCreatedAt_Lte: getDateFromTab.to,
      },
    });
  };

  useEffect(() => {
    if (currentActiveContainer) {
      getContainerDetails({
        variables: {
          id: currentActiveContainer.id,
          fillLevelMeasurementSetCreatedAt_Gte: getDateFromTab.from,
          fillLevelMeasurementSetCreatedAt_Lte: getDateFromTab.to,
        },
      });
    }
  }, [currentActiveContainer, getDateFromTab, getContainerDetails]);

  const [getAllContainers] = useLazyQuery(allContainersQueryGql, {
    // Yet another double request on this page. Because Map get only paginated data without oportunity get no paginate data
    onError: toastifyError,
    suspend: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: queryExtraVariables,
    onCompleted: (data) => {
      setAllContainersList(data.allContainers.edges);
    },
  });

  useEffect(() => {
    getAllContainers();
  }, [getAllContainers]);

  return (
    <Grid container spacing={0} className="containers h-100">
      <Grid item xs={12} className="fullHeightCol listCol">
        <Grid container className={classes.accordion} justify="space-between">
          <FormattedMessage id="pageName.containers" defaultMessage="Containers">
            {(txt) => (
              <p>
                <span className={classes.headerContainersTitle}>{txt}</span>
                <span className={classes.headerCurrentContainerTitle}>
                  {' '}
                  / {currentActiveContainer?.containerId}
                </span>
              </p>
            )}
          </FormattedMessage>
          <Grid>
            <Tooltip
              title={
                isRegularUser ? (
                  <FormattedMessage
                    id="tooltip.no_permission_add_containers"
                    defaultMessage="You don’t have permission to add container"
                  />
                ) : (
                  ''
                )
              }
            >
              <NavLink
                to={isRegularUser ? '/app/containers' : '/app/containers/add'}
                onClick={!isRegularUser && handleAddContainerClick}
                className={classes.addButtonLink}
              >
                <Tooltip
                  disableHoverListener={!!usersAvailableProjects?.length}
                  title={
                    <FormattedMessage
                      id={
                        usersAvailableProjects?.length
                          ? 'tooltip.add.container'
                          : 'no_available_projects'
                      }
                      defaultMessage={
                        usersAvailableProjects?.length
                          ? 'Click to add new container'
                          : 'Please add at least one project'
                      }
                    />
                  }
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    tourid="addContainer"
                    disabled={isRegularUser}
                  >
                    <FormattedMessage id="add_container" defaultMessage="Add container" />
                  </Button>
                </Tooltip>
              </NavLink>
            </Tooltip>
          </Grid>
        </Grid>
        <ContainerDetails
          classes={classes}
          container={currentActiveContainer}
          usersAvailableProjects={usersAvailableProjects}
          setActiveDateTab={setActiveTab}
          activeDateTab={activeDateTab}
          allContainersList={allContainersList}
          detailsLoading={detailsLoading}
        />
        <Grid container xs={12} className="tableWrapper m-t-10" tourid="containersTable">
          <Grid item xs={9}>
            <ReusableTable
              excludeDeleteOption={isRegularUser}
              excludeEditOption={isRegularUser}
              userLayoutConfig={userLayoutConfig}
              updateUserConfig={handleConfigChange}
              cellsConfig={headCellsToDisplay}
              autoCompleteQuery={containersAutocompleteGql}
              queryExtraVariables={queryExtraVariables}
              extraFilter={extraFilter}
              query={allContainersQueryGql}
              deleteItemMutation={deleteContainer}
              itemsSerializer={containersSerializer}
              updateCache={updateCache}
              chooseLogoSize={false}
              noLogoLink
              searchSize={3}
              tableContainerClass={classes.tableContainer}
              tableComponentWrapperStyles={{
                paddingBottom: '0',
              }}
              selectTableRowCallback={selectTableRowCallback}
              activeTableColumns={activeTableColumns}
              tableFontSize={12}
              queryName="allContainers"
              paginationClass="all-routes-pagination"
              noCheckboxes
              additionalActionMenu={[
                <TableActionMenu
                  goToAttachDevice={goToAttachDevice}
                  showLogHandler={showLogHandler}
                  user={{
                    company: user.company,
                    isAdmin: user.isAdmin,
                    isReseller: user.isReseller,
                  }}
                  hasSensor={hasSensor}
                  isRegularUser={isRegularUser}
                />,
              ]}
              selectedRowDetails={selectedRowDetails}
              itemGroups={activeProjects}
              displayScrollbar
              ref={scrollbarRef}
              allRowsEnabled={false}
              isRedirectOnSelect={false}
              setSearchString={handleSetSearchString}
              searchFieldClass={classes.searchField}
              isSearchAutocomplete={false}
              objectNameKey="containerId"
            />
          </Grid>
          <Grid item xs={3}>
            <div className="h-max-100 flex-grow-1" tourid="containersMap">
              <MapComponent
                zoom={[13]}
                showContainers
                selectedContainerCoords={selectedContainerCoords}
                containersCluster={allContainersList}
                selectedContainer={currentActiveContainer}
                hideContainerInfoPopup
                onSelectContainer={(container) => {
                  onSelectContainer(container);
                }}
                onShowLog={(container) => {
                  onShowLogByPin(container);
                }}
                mapHeight="44vh"
              />
            </div>
          </Grid>
        </Grid>
        {showLog && (
          <ShowLogModal onClose={() => handleCloseShowLog()} container={currentActiveContainer} />
        )}
      </Grid>
    </Grid>
  );
};

ContainersMap.propTypes = {
  savedActiveProjects: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  activeProjects: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  user: PropTypes.shape({
    company: PropTypes.shape({
      id: PropTypes.string,
    }),
    activeProjectsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isDemo: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isReseller: PropTypes.bool.isRequired,
  }).isRequired,
  settings: PropTypes.objectOf(PropTypes.instanceOf(Settings).isRequired).isRequired,
  theme: PropTypes.shape({
    variables: PropTypes.shape({
      VHBlue: PropTypes.string,
    }),
  }).isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleConfigChange: PropTypes.func.isRequired,
  selectedContainerFromDashboard: PropTypes.shape({
    id: PropTypes.string,
    location: PropTypes.shape({
      longitude: PropTypes.number,
      latitude: PropTypes.number,
    }),
  }),
};

ContainersMap.defaultProps = {
  selectedContainerFromDashboard: null,
};

const mapStateToProps = (state) => ({
  user: state.settings.user,
  savedActiveProjects: getSavedActiveProjects(state),
  activeProjects: getActiveProjects(state),
  settings: getUserSettings(state),
  selectedContainerFromDashboard: state.settings.selectedContainerFromDashboard,
});

export default connect(mapStateToProps)(
  withUserLayoutConfig('containers')(withStyles(styles, { withTheme: true })(ContainersMap)),
);
