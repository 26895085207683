import { loader } from 'graphql.macro';
import { allContainerQuery, attachDeviceSerializer, getQueryForDeviceType } from '../shared/utils';
import { getFieldsErrors, toastifyError } from '../../../../../shared/utils';

const allMeasurementSettingsQuery = loader(
  './../../../../../graphql/queries/devices/all_measurement_settings.graphql',
);
const fillLevelDeviceQuery = loader(
  './../../../../../graphql/queries/devices/fill_level_device.graphql',
);
const oneContainer = loader('./../../../../../graphql/queries/devices/one_container.graphql');

export const updateDeviceCache = (operation, selectedContainer) => async (store, deviceData) => {
  const savedDevice = deviceData.data[operation].fillLevelDevice;

  try {
    /*
       Add to existed container new device to container relation
       ToDo remove old connection
    */
    const data = await store.readQuery(allContainerQuery);
    const container = data.allContainers.edges.find((obj) => selectedContainer === obj.node.id);

    container.node.deviceToContainerSet.edges = [
      ...container.node.deviceToContainerSet.edges,
      ...savedDevice.containerDevice.edges.filter(
        ({ node }) => node.container.id === selectedContainer,
      ),
    ];

    store.writeQuery({ ...allContainerQuery, data });
  } catch (e) {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      window.console.log(e);
    }
  }

  try {
    /*
       Add to existed one container new device to container relation
       ToDo remove old connection
    */
    const oneContainerQuery = { query: oneContainer, variables: { id: selectedContainer } };
    const oneContainerData = await store.readQuery(oneContainerQuery);

    oneContainerData.container.deviceToContainerSet.edges = [
      ...oneContainerData.container.deviceToContainerSet.edges,
      ...savedDevice.containerDevice.edges.filter(
        ({ node }) => node.container.id === selectedContainer,
      ),
    ];

    store.writeQuery({ ...oneContainerQuery, data: oneContainerData });
  } catch (e) {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      window.console.log(e);
    }
  }

  try {
    /*
       Add new device or update existed in device specific query
    */
    const [query, queryName, typename] = getQueryForDeviceType(savedDevice);
    const data = await store.readQuery({ query });
    data[queryName].edges = data[queryName].edges.filter((obj) => savedDevice.id !== obj.node.id);
    data[queryName].edges = [
      ...data[queryName].edges,
      {
        node: savedDevice,
        __typename: typename,
      },
    ];
    store.writeQuery({ query, data });
  } catch (e) {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      window.console.log(e);
    }
  }

  try {
    /*
     Remove measurement from cache if it custom
   */
    const customMeasurementSettings = savedDevice.containerDevice.edges
      .map(({ node }) => node?.measurementSettings || {})
      .filter(({ custom }) => custom);

    const data = await store.readQuery({
      query: allMeasurementSettingsQuery,
      variables: { projectId: '' },
    });

    data.allMeasurementSettings.edges = data.allMeasurementSettings.edges.filter(
      ({ node: measurement }) => customMeasurementSettings.every(({ id }) => measurement.id !== id),
    );

    store.writeQuery({
      query: allMeasurementSettingsQuery,
      variables: { projectId: '' },
      data,
    });
  } catch (e) {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      window.console.log(e);
    }
  }

  /*
   Update device cache
  */
  store.writeQuery({
    query: fillLevelDeviceQuery,
    variables: { id: savedDevice.id },
    data: { fillLevelDevice: savedDevice },
  });
};

const attachDeviceHandler = (attachDevice, onSubmit = () => {}) => async (
  values,
  setSubmitting,
  setFieldError,
) => {
  try {
    const variables = attachDeviceSerializer(values);
    await attachDevice({
      variables,
      update: updateDeviceCache('attachDeviceToContainer', variables.containerId),
    });

    setSubmitting(false);
    onSubmit();
  } catch (e) {
    toastifyError(e);
    if (e.graphQLErrors && e.graphQLErrors.length) {
      e.graphQLErrors.forEach((graphQLError) => {
        if (graphQLError?.context?.field) {
          setFieldError(graphQLError.context.field, graphQLError.message);
        }
      });
    }
    setSubmitting(false);
    throw e;
  }
};

const checkDeviceHandler = (selectedProject, checkDeviceId) => async (deviceId) => {
  try {
    const variables = {
      projectId: selectedProject,
      deviceId,
    };

    const {
      data: {
        checkDeviceId: { status },
      },
    } = await checkDeviceId({ variables });
    return status;
  } catch (e) {
    if (e.graphQLErrors && e.graphQLErrors.length) {
      const fieldsErrors = getFieldsErrors(e);
      if (fieldsErrors.length) {
        return fieldsErrors[0].message;
      }
    }
    return e.message;
  }
};

export { checkDeviceHandler, attachDeviceHandler };
