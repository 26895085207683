const styles = (theme) => ({
  paddedRow: {
    marginBottom: theme.spacing(5),
  },
  wrap: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0 20px 0',
    boxSizing: 'border-box',
  },
  submitRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    color: theme.variables.cEmperor,
  },
  rememberMe: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    height: 'inherit',
    width: 'inherit',
    marginRight: 7,
  },
  submitBtn: {
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    borderRadius: 32.5,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(5.5),
  },
  errorMessage: {
    marginTop: 20,
    fontSize: 14,
    minHeight: 30,
    color: theme.variables.VHRed,
  },
  forgotPassword: {
    textAlign: 'right',
    color: theme.variables.VHBlue,
    textDecoration: 'none',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signInText: {
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: '30px',
  },
});

export default styles;
