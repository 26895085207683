import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import FormikTextField from '../../../../../shared/inputs/formik/FormikTextField';
import FormikSelect from '../../../../../shared/inputs/formik/FormikSelect';

const volumeUnitOptions = [
  {
    value: 'm³',
    label: 'm³',
  },
  {
    value: 'L',
    label: 'L',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  inputContainer: {
    paddingTop: '23px !important',
  },
}));

const SettingsCustomizeVolumeDialog = ({
  open,
  onClose,
  volumeUnit,
  volumeValue,
  setVolumeValue,
  setVolumeUnit,
  validateForm,
  setFieldTouched,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [localVolumeUnit, setLocalVolumeUnit] = React.useState(volumeUnit);
  const [localVolumeValue, setLocalVolumeValue] = React.useState(volumeValue);

  const saveValues = () => {
    setFieldTouched('customVolume');
    setFieldTouched('customVolumeUnit');
    validateForm().then((validationResult) => {
      if (validationResult.customVolume || validationResult.customVolumeUnit) return;
      setVolumeValue(localVolumeValue);
      setVolumeUnit(localVolumeUnit);
      onClose();
    });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          saveValues();
        }
      }}
    >
      <DialogTitle>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.root}>
        <Grid container spacing={2} justify="center">
          <Grid container item xs={6} spacing={2} justify="center" direction="column">
            <Grid item xs className={classes.inputContainer}>
              <FormikTextField
                name="customVolume"
                label={intl.formatMessage({ id: 'volume', defaultMessage: 'Enter volume' })}
                required
                type="number"
                className={classNames('w-100')}
                onChange={(value) => setLocalVolumeValue(value)}
                value={localVolumeValue}
              />
            </Grid>
            <Grid container item xs justify="flex-start" className={classes.selectContainer}>
              <FormikSelect
                required
                filledStyle
                name="customVolumeUnit"
                label={intl.formatMessage({
                  id: 'custom_volume_unit',
                  defaultMessage: 'Custom volume unit',
                })}
                value={localVolumeUnit}
                onChange={(value) => setLocalVolumeUnit(value)}
                placeholder={intl.formatMessage({
                  id: 'volume.placeholder.unit',
                  defaultMessage: 'Select unit',
                })}
                valuesList={volumeUnitOptions}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="center">
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                className="w-100 m-t-20"
                onClick={saveValues}
              >
                <FormattedMessage id="ok" defaultMessage="OK" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

SettingsCustomizeVolumeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  volumeUnit: PropTypes.string,
  volumeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setVolumeValue: PropTypes.func.isRequired,
  setVolumeUnit: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

SettingsCustomizeVolumeDialog.defaultProps = {
  volumeValue: 0,
  volumeUnit: '',
};

export default SettingsCustomizeVolumeDialog;
