import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { loader } from 'graphql.macro';
import FormikSelect from '../inputs/formik/FormikSelect';
import NoItemsForSelect from './noItemsForSelect';
import InputSkeleton from '../inputs/InputSkeleton';

const allVehiclesQuery = loader('./../../graphql/queries/fleet_management/all_vehicles.graphql');

const SelectVehicles = ({ required, activeProjects }) => {
  const { loading, data: { allVehicles: { edges: vehicles = [] } = {} } = {} } = useQuery(
    allVehiclesQuery,
    {
      variables: {
        activeProjects,
      },
    },
  );

  const intl = useIntl();

  const allVehicles = useMemo(
    () =>
      vehicles.map(({ node }) =>
        Object({
          value: node.id,
          label: node.name,
        }),
      ),
    [vehicles],
  );

  return (
    <Grid item xs={12}>
      {loading ? (
        <InputSkeleton />
      ) : (
        <>
          {allVehicles.length > 0 ? (
            <FormikSelect
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.vehicles',
                defaultMessage: 'Choose the vehicle',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.vehicles',
                defaultMessage: 'Vehicles',
              })}
              required={required}
              filledStyle
              name="vehicleId"
              valuesList={allVehicles}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <NoItemsForSelect
              link="/app/fleet-management/collector-vehicle/add"
              message={
                <FormattedMessage id="no_vehicles" defaultMessage="No vehicles registered." />
              }
              linkText={
                <FormattedMessage id="add_vehicles" defaultMessage="Click to add new vehicles." />
              }
            />
          )}
        </>
      )}
    </Grid>
  );
};

SelectVehicles.propTypes = {
  required: PropTypes.bool,
  activeProjects: PropTypes.arrayOf(PropTypes.string),
};

SelectVehicles.defaultProps = {
  required: true,
  activeProjects: undefined,
};

export default React.memo(SelectVehicles);
