import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AutocompleteFilter from './autocompleteFilter';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 28,
    paddingLeft: 35,
    paddingRight: 35,
  },
}));

const FilterRow = ({ children, query, serializer, iconSize, pagination, queryExtraVariables }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} justify="space-between" className={classes.root}>
      <Grid item xs={12} lg={4}>
        <AutocompleteFilter
          query={query}
          serializer={serializer}
          queryExtraVariables={queryExtraVariables}
        />
      </Grid>
      <Grid item container xs={12} lg={4}>
        {children}
      </Grid>
      <Grid item xs={4} lg={1}>
        {iconSize}
      </Grid>
      <Grid item xs={8} lg="auto">
        {pagination}
      </Grid>
    </Grid>
  );
};

FilterRow.propTypes = {
  children: PropTypes.element,
  query: PropTypes.objectOf(PropTypes.any),
  queryExtraVariables: PropTypes.objectOf(PropTypes.any),
  serializer: PropTypes.func,
  pagination: PropTypes.element,
  iconSize: PropTypes.element,
};

FilterRow.defaultProps = {
  query: null,
  children: null,
  serializer: () => [],
  pagination: null,
  queryExtraVariables: {},
  iconSize: null,
};

export default FilterRow;
