import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';

import CheckIcon from '../../../../../../images/icons/check-2.svg';
import CrossIcon from '../../../../../../images/icons/cross.svg';

const useStyles = makeStyles((theme) => ({
  chipButton: {
    display: 'none',
    paddingBottom: 0,
    paddingTop: 0,
  },
  selectAllButton: {
    height: 24,
    width: 24,
    marginLeft: 3,
    marginRight: 3,
    padding: '0 !important',
    minWidth: 0,
    backgroundColor: theme.variables.cWhite,
    boxShadow: theme.variables.defaultBoxShadow,
  },
  editInput: {
    width: '70%',
    borderRadius: 4,
    borderColor: theme.variables.cLightGray,
  },
}));

const EditableLabel = ({ label, value, setWasteTypeIdToDelete, getParamsForWasteTypeEdit }) => {
  const [editMode, setEditMode] = useState(false);
  const [fieldValue, setFieldValue] = useState(label);
  const classes = useStyles();

  const handleCloseEditMode = (event) => {
    event.preventDefault();
    setEditMode(false);
    setFieldValue(label);
  };

  const handleApplyChanges = (event) => {
    event.preventDefault();
    setEditMode(false);
    getParamsForWasteTypeEdit({ variables: { selfId: value, name: fieldValue } });
  };

  const handleDeleteWasteType = () => {
    setWasteTypeIdToDelete(value);
  };

  return !editMode ? (
    <>
      {label}
      <div>
        <IconButton
          size="small"
          className={classes.chipButton}
          onClick={(event) => {
            event.preventDefault();
            setEditMode(true);
          }}
        >
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.waste_fraction.edit"
                defaultMessage="Click to edit this waste fraction’s details"
              />
            }
            avoidIcon
          >
            <EditIcon />
          </TooltippedUserControl>
        </IconButton>
        <IconButton size="small" className={classes.chipButton} onClick={handleDeleteWasteType}>
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.waste_fraction.delete"
                defaultMessage="Click to delete this waste fraction"
              />
            }
            avoidIcon
          >
            <DeleteIcon />
          </TooltippedUserControl>
        </IconButton>
      </div>
    </>
  ) : (
    <>
      <input
        className={classes.editInput}
        value={fieldValue}
        onChange={(event) => setFieldValue(event.target.value)}
      />
      <div>
        <Button className={classes.selectAllButton} onClick={handleCloseEditMode}>
          <img src={CrossIcon} alt="cancel" />
        </Button>
        <Button className={classes.selectAllButton} onClick={handleApplyChanges}>
          <img src={CheckIcon} alt="apply" />
        </Button>
      </div>
    </>
  );
};

EditableLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setWasteTypeIdToDelete: PropTypes.func.isRequired,
  getParamsForWasteTypeEdit: PropTypes.func.isRequired,
};

export default EditableLabel;
