import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Client } from '@googlemaps/google-maps-services-js';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import Information from './detailTabs/Information';
import Photos from './detailTabs/Photos';
import Notifications from './detailTabs/Notifications';
import LoadingLayout from '../../../../../../../shared/loading';
import ContainerIcon from '../../icons/container.png';
import { TranslationContext } from '../../../../../../../../translations';

const ContainerDetails = ({
  classes,
  container,
  activeDateTab,
  setActiveDateTab,
  allContainersList,
  detailsLoading,
}) => {
  const { locale } = useContext(TranslationContext);
  const [activeTab, setActiveTab] = useState('information');
  const [containerAddress, setContainerAddress] = useState('');

  useEffect(() => {
    if (container && Object.keys(container).length) {
      const client = new Client({});
      const requestParams = {
        language: locale,
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      };

      if (container.location.placeId) {
        requestParams.place_id = container.location.placeId;
      } else {
        const { latitude, longitude } = container.location;
        requestParams.latlng = [latitude, longitude];
      }

      client
        .reverseGeocode({
          params: requestParams,
        })
        .then((response) => {
          const [first] = response?.data?.results || [];
          // Google SDK is using not a CamelCase parameter formatted_address
          // eslint-disable-next-line camelcase
          setContainerAddress(first?.formatted_address || '');
        })
        .catch(window.console.dir);
    }
  }, [container, locale]);

  const intl = useIntl();

  const renderDetailTab = () => {
    switch (activeTab) {
      case 'information':
        return (
          <Information
            classes={classes}
            container={container}
            activeDateTab={activeDateTab}
            setActiveDateTab={setActiveDateTab}
          />
        );
      case 'photos':
        return <Photos classes={classes} />;
      case 'notifications':
        return <Notifications classes={classes} allContainersList={allContainersList} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Grid container className="position-relative">
        <LoadingLayout isLoading={detailsLoading} />
        <Grid xs={1} className="m-b-5" style={{ borderRight: '1px solid #f0f2f7' }}>
          <Typography variant="body1" className={`${classes.headerContainersTitle} m-t-20 m-b-50`}>
            <FormattedMessage id="overview" defaultMessage="Overview">
              {(txt) => <span className={classes.overviewTitle}>{txt}</span>}
            </FormattedMessage>
          </Typography>
          <Tabs
            value={activeTab}
            orientation="vertical"
            onChange={(e, value) => setActiveTab(value)}
            className={classes.tabs}
            aria-label="container_details.tabs"
          >
            <Tab
              label={intl.formatMessage({
                id: 'container_details.information',
                defaultMessage: 'Information',
              })}
              value="information"
              className={classes.tab}
            />
          </Tabs>
        </Grid>
        <Grid xs={3} style={{ borderRight: '1px solid #f0f2f7' }}>
          <div className={`${classes.containerImage} m-t-20`}>
            <img src={container?.photoUrl || ContainerIcon} alt="container" />
          </div>
          <Grid container justify="space-between" wrap="nowrap">
            <p className={classes.containerAttributeTitle}>Address</p>
            <Tooltip title={containerAddress} placement="top">
              <p className={classes.containerAttributeValue}>{containerAddress}</p>
            </Tooltip>
          </Grid>
          <Grid container justify="space-between">
            <p className={classes.containerAttributeTitle}>Current fill-level</p>
            <p className={classes.containerAttributeValue}>
              {container?.measurement?.fillPercentage
                ? `${container?.measurement?.fillPercentage?.toFixed(2)}%`
                : '-'}
            </p>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container justify="space-between">
            <p className={classes.containerAttributeTitle}>Last serviced</p>
            <p className={classes.containerAttributeValue}>
              {container?.containerLog
                ? moment(container?.containerLog.createdAt).format('DD/MM/YYYY HH:mm')
                : '-'}
            </p>
          </Grid>
          <Grid container justify="space-between">
            <p className={classes.containerAttributeTitle}>Next scheduled pickup</p>
            <p className={classes.containerAttributeValue}>
              {container?.scheduledPickup
                ? moment(container?.scheduledPickup).format('DD/MM/YYYY')
                : '-'}
            </p>
          </Grid>
          <Grid container justify="space-between">
            <p className={classes.containerAttributeTitle}>Number of collections</p>
            <p className={classes.containerAttributeValue}>{container?.totalCollections}</p>
          </Grid>
          <Grid container justify="space-between">
            <p className={classes.containerAttributeTitle}>Number of overflows</p>
            <p className={classes.containerAttributeValue}>{container?.totalOverflowings}</p>
          </Grid>
          <Grid container justify="space-between">
            <p className={classes.containerAttributeTitle}>Avg. collection level</p>
            <p className={classes.containerAttributeValue}>
              {container?.efficient ? `${container?.efficient?.toFixed(2)}%` : '-'}
            </p>
          </Grid>
        </Grid>
        <Grid item xs={8} className="p-15">
          {renderDetailTab()}
        </Grid>
      </Grid>
    </>
  );
};

ContainerDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  container: PropTypes.shape({
    containerId: PropTypes.string,
    measurement: PropTypes.shape({
      fillPercentage: PropTypes.number,
    }),
    location: PropTypes.shape({
      longitude: PropTypes.number,
      latitude: PropTypes.number,
      placeId: PropTypes.string,
    }),
    containerLog: PropTypes.shape({
      createdAt: PropTypes.string,
    }),
    scheduledPickup: PropTypes.string,
    totalOverflowings: PropTypes.number,
    totalCollections: PropTypes.number,
    efficient: PropTypes.number,
    photoUrl: PropTypes.string,
  }),
  allContainersList: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  ),
  activeDateTab: PropTypes.string.isRequired,
  setActiveDateTab: PropTypes.func.isRequired,
  detailsLoading: PropTypes.bool.isRequired,
};

ContainerDetails.defaultProps = {
  container: {},
  allContainersList: [],
};

export default ContainerDetails;
