import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect, getIn } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddPhoto from '../../../../images/icons/addPhoto.svg';
import { MAX_FILE_SIZE } from '../../utils/constants';
import formikInjectedPropsTypes from './formikPropTypes';
import { formikComponentMemoizeFieldCheck, memoizeFields } from './memoraize';

const useStyles = makeStyles((theme) => ({
  noPhoto: {
    // height: '100%',
  },
  noImage: {
    fontSize: 14,
    color: theme.variables.cSlate,
    fontWeight: 500,
    paddingTop: 8,
    lineHeight: '24px',
  },
  photo: {
    maxHeight: '100%',
    minHeight: 1,
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  noPhotoContainer: {
    backgroundColor: theme.variables.cWhite,
    border: `2px dashed ${theme.variables.cLightGray}`,
    padding: 15,
  },
  photoContainer: {
    width: '100%',
    height: ({ size }) => {
      switch (size) {
        case 'big':
          return 278;
        case 'small':
          return 204;
        default:
          return 278;
      }
    },
    borderRadius: 26,
    boxSizing: 'border-box',
    cursor: 'pointer',
    outline: 'none',
  },
  withPhotoContainer: {
    // ToDo use 'contain'?
    background: ({ imageUrl }) => `no-repeat center/cover url(${imageUrl})`,
  },
}));
const defaultFormats = ['image/jpeg', 'image/png', 'image/jpg'];

const FormikImageDropzone = ({ name, onChange, formik, size, children, disabled }) => {
  const value = getIn(formik.values, name);
  const intl = useIntl();
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const url = URL.createObjectURL(file);
        if (file.size > MAX_FILE_SIZE) {
          toast.error(
            intl.formatMessage({
              id: 'image.too_large',
              defaultMessage: 'This image is too large. Max size 5mb',
            }),
          );
          return;
        }
        if (!defaultFormats.includes(file.type)) {
          toast.error(
            intl.formatMessage({
              id: 'image.wrong_format',
              defaultMessage: 'This file should be image',
            }),
          );
          return;
        }
        formik.setFieldValue(name, url);
        onChange({ url, file });
      });
    },
    [intl, onChange, formik, name],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const classes = useStyles({ size, imageUrl: value });

  const { ref, ...rootProps } = getRootProps();

  return (
    <RootRef rootRef={ref}>
      <>
        <Grid
          container
          item
          justify="center"
          direction="column"
          alignItems="center"
          {...rootProps}
          className={classNames(classes.photoContainer, {
            [classes.noPhotoContainer]: !value,
            [classes.withPhotoContainer]: value,
          })}
        >
          <input {...getInputProps()} accept="image/*" name={name} disabled={disabled} />
          {!value && (
            <>
              <img
                className={classes.noPhoto}
                src={AddPhoto}
                alt={intl.formatMessage({
                  id: 'container_photo',
                  defaultMessage: 'Container Photo',
                })}
              />
              <span className={classes.noImage}>
                <FormattedMessage id="image.add_photo" defaultMessage="Add Photo" />
              </span>
            </>
          )}
        </Grid>
        {React.Children.map(children, (child) => React.cloneElement(child, { imageInputRef: ref }))}
      </>
    </RootRef>
  );
};

FormikImageDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  size: PropTypes.oneOf(['big', 'small']),
  onChange: PropTypes.func,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  children: PropTypes.element,
  disabled: PropTypes.bool,
};

FormikImageDropzone.defaultProps = {
  id: '',
  size: 'big',
  onChange: () => {},
  children: null,
  disabled: false,
};

export default connect(
  React.memo(
    FormikImageDropzone,
    memoizeFields(['name', 'id', 'onChange', 'size', formikComponentMemoizeFieldCheck()]),
  ),
);
