import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import PlusBlackIcon from '../../../../../../../../images/icons/plusBlack.svg';
import StackIcon from '../../../../../../../../images/icons/stack.svg';

const TableActionMenu = ({
  goToAttachDevice,
  showLogHandler,
  user,
  hasSensor,
  isRegularUser = false,
}) => (
  <>
    {!isRegularUser && !!(user.company || user.isAdmin || user.isReseller) && !hasSensor && (
      <div>
        <MenuItem onClick={goToAttachDevice}>
          <img src={PlusBlackIcon} alt="" style={{ marginRight: 10 }} />
          <FormattedMessage id="add_sensor" defaultMessage="Attach sensor" />
        </MenuItem>
      </div>
    )}
    <MenuItem onClick={showLogHandler}>
      <img src={StackIcon} alt="" style={{ marginRight: 10 }} />
      <FormattedMessage id="log" defaultMessage="Log" />
    </MenuItem>
  </>
);

TableActionMenu.defaultProps = {
  user: {},
  hasSensor: false,
  isRegularUser: false,
};

TableActionMenu.propTypes = {
  goToAttachDevice: PropTypes.func.isRequired,
  showLogHandler: PropTypes.func.isRequired,
  user: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      logo: PropTypes.string,
      dashboardLocation: PropTypes.shape({
        id: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
    isAdmin: PropTypes.bool,
    isReseller: PropTypes.bool,
  }),
  hasSensor: PropTypes.bool,
  isRegularUser: PropTypes.bool,
};

export default TableActionMenu;
