import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from 'react-intl';
import DriverSettings from '../../../../../images/icons/driverSettings.svg';
import DriverSettingsGrey from '../../../../../images/icons/driverSettingsGrey.svg';
import { getUsersAvailableProjects } from '../../../../shared/utils';
import ButtonTooltip from '../../../../shared/tooltip/ButtonTooltip';
import useMayDriverEdit from '../hooks/useMayDriverEdit';
import { useHasDriverAddPageAccess } from '../hooks/useDriverManagmentPageAccess';

const useStyles = makeStyles(() => ({
  roundedBtn: {
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    border: '1px solid',
  },
  submitBtn: {
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'bold',
    },
    '& .MuiAvatar-img': {
      width: 25,
      height: 26,
    },
  },
}));

const resolveTooltipText = (projectsAmount, permissionToEdit, isRegularUser) => {
  if (isRegularUser) {
    return (
      <FormattedMessage
        id="tooltip.no_permission_to_settings_driver"
        defaultMessage="You don't have permission for driver settings"
      />
    );
  }
  if (!permissionToEdit) {
    return (
      <FormattedMessage
        id="tooltip.edit.driver.settings.no_permission"
        defaultMessage="You can edit your info only once"
      />
    );
  }
  if (!projectsAmount) {
    return (
      <FormattedMessage
        id="no_available_projects"
        defaultMessage="Please add at least one project"
      />
    );
  }
  return (
    <FormattedMessage
      id="tooltip.edit.driver.settings"
      defaultMessage="Click to edit driver settings"
    />
  );
};

const SettingsButton = ({ text, match, isRegularUser }) => {
  const classes = useStyles();
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));
  const driverUserMayEdit = useMayDriverEdit();

  const hasDriverAddPageAccess = useHasDriverAddPageAccess(isRegularUser);
  const isDisabled = !hasDriverAddPageAccess;

  return (
    <ButtonTooltip
      text={resolveTooltipText(usersAvailableProjects?.length, driverUserMayEdit, isRegularUser)}
    >
      <Button
        component={Link}
        variant="outlined"
        color="primary"
        className={classNames(classes.submitBtn, classes.roundedBtn, 'w-100', 'h-100')}
        startIcon={<Avatar src={isDisabled ? DriverSettingsGrey : DriverSettings} />}
        type="button"
        to={`${match.url}/add`}
        disabled={isDisabled || isRegularUser}
      >
        {text}
      </Button>
    </ButtonTooltip>
  );
};

SettingsButton.propTypes = {
  text: PropTypes.element.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  isRegularUser: PropTypes.bool,
};

SettingsButton.defaultProps = {
  isRegularUser: false,
};

export default withRouter(SettingsButton);
