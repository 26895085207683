import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import ProjectList from './project/projectList';
import Settings from './project/settings';
import CompanySettings from './project/companySettings';
import WhenNotFound from '../../../shared/whenNotFound';
import SettingsMenu from './menu';

const Routes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/`} component={SettingsMenu} />
    <Route exact path={`${match.url}/company/:id/`} component={CompanySettings} />
    <Route exact path={`${match.url}/create-project/:forCompany`} component={Settings} />
    <Route exact path={`${match.url}/projects-overview`} component={ProjectList} />
    <Route exact path={`${match.url}/:id`} component={SettingsMenu} />
    <Route exact path={`${match.url}/:id/:step?`} component={Settings} />
    <Route component={WhenNotFound} />
  </Switch>
);

Routes.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Routes;
