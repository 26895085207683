import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: '#fff',
    height: (props) => props.styleHeight,
    border: '1px solid #f0f2f7',
  },
  title: {
    borderBottom: '1px solid #f0f2f7',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#626469',
    padding: theme.spacing(2),
  },
  content: {
    padding: (props) => (props.styleContentPadding ? theme.spacing(2) : 0),
    height: '100%',
  },
}));

const Drawer = (props) => {
  const { title, children, styleContentPadding, styleHeight } = props;
  const classes = useStyles({ styleContentPadding, styleHeight });

  return (
    <div className={classes.drawer}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

Drawer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  styleContentPadding: PropTypes.bool,
  styleHeight: PropTypes.string,
};

Drawer.defaultProps = {
  styleHeight: '100%',
  styleContentPadding: true,
};

export default React.memo(Drawer);
