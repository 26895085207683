import { useSelector } from 'react-redux';
import { getUsersAvailableProjects } from '../../../../shared/utils';
import useMayDriverEdit from './useMayDriverEdit';
import useCurrentUser from '../../../../shared/hooks/useCurrentUser';

export function useHasDriverAddPageAccess(isRegularUser) {
  const usersAvailableProjects = useSelector(getUsersAvailableProjects);
  const driverUserMayEdit = useMayDriverEdit();

  return !(!usersAvailableProjects?.length || !driverUserMayEdit || isRegularUser);
}

export function useHasDriverEditPageAccess() {
  const me = useCurrentUser();
  return me.isAdmin || me.isMaster || me.isReseller || me.isSuperuser || !!me.ownProjectsIds.length;
}
