import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { loader } from 'graphql.macro';
import { useSelector } from 'react-redux';
import NoItemsForSelect from './noItemsForSelect';
import MultiRowSelect from './multiRowSelect';
import { getSavedActiveProjects, toastifyError } from '../utils';

const allWasteFractionsQuery = loader(
  './../../graphql/queries/wastacollector/all_waste_fractions.graphql',
);

const MultiSelectWasteFraction = ({
  inputClassName,
  name,
  wasteFractionFieldName,
  extraInputs,
  defaultValue,
  serializer,
  queryVariables,
  onChange,
}) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const intl = useIntl();

  const {
    loading,
    data: { allWasteFractions: { edges: wasteFractions = [] } = {} } = {},
    refetch,
  } = useQuery(allWasteFractionsQuery, {
    suspend: false,
    notifyOnNetworkStatusChange: true,
    variables: {
      activeProject: savedActiveProjects,
      ...queryVariables,
    },
  });

  useEffect(() => {
    refetch({
      activeProjects: savedActiveProjects,
      ...queryVariables,
    }).catch(toastifyError);
  }, [queryVariables, refetch, savedActiveProjects]);

  const serializedWasteFractions = useMemo(
    () =>
      wasteFractions
        .map(({ node }) => ({
          value: node.id,
          label: `${node.wasteType.join(', ')} - ${node.wasteCategory} (${node.project.name})`,
        }))
        .sort((a, b) => {
          if (a.label === b.label) {
            return 0;
          }
          return a.label > b.label ? 1 : -1;
        })
        .filter((fraction) => fraction.label),
    [wasteFractions],
  );

  const noItems = useMemo(
    () => (
      <NoItemsForSelect
        link="/app/settings/"
        message={
          <FormattedMessage
            id="no_waste_fractions"
            defaultMessage="No waste fractions registered."
          />
        }
        linkText={
          <FormattedMessage
            id="add_waste_fractions"
            defaultMessage="Click to add new waste fraction."
          />
        }
      />
    ),
    [],
  );

  return (
    <MultiRowSelect
      getIdFromFormikValue={serializer}
      getItem={(id) => wasteFractions.find(({ node }) => node.id === id)?.node}
      inputClassName={classNames(inputClassName, 'm-r-5')}
      defaultValue={defaultValue}
      noItems={noItems}
      label={intl.formatMessage({
        id: 'multiWasteFractionsSelect.label.wasteFractions',
        defaultMessage: 'Waste fractions',
      })}
      loading={loading}
      valuesList={serializedWasteFractions}
      name={name}
      fieldName={wasteFractionFieldName}
      renderRow={extraInputs}
      maxInputsQuantity={wasteFractions.length}
      onChange={onChange}
    />
  );
};

MultiSelectWasteFraction.propTypes = {
  inputClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  wasteFractionFieldName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  extraInputs: PropTypes.func,
  serializer: PropTypes.func,
  onChange: PropTypes.func,
  queryVariables: PropTypes.objectOf(PropTypes.string),
};

MultiSelectWasteFraction.defaultProps = {
  inputClassName: '',
  wasteFractionFieldName: '',
  defaultValue: '',
  serializer: (val) => val,
  onChange: () => {},
  queryVariables: {},
};

export default React.memo(MultiSelectWasteFraction);
