import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { StyledTableCell, StyledTableRow } from './styledTableComponents';

const useStyles = makeStyles((theme) => ({
  dummyText: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.variables.VHDarkGrey,
    margin: '30px auto',
  },
}));

const EmptyResults = () => {
  const classes = useStyles();

  return (
    <StyledTableRow>
      <StyledTableCell colSpan="100%">
        <p className={classes.dummyText}>
          There are no results
          <br />
          Try to enter another request
        </p>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default EmptyResults;
