import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Tabs, Tab, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from 'recharts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useStyles from '../styles';
import styles from '../wrapStyles';
import AnalyticsTableLayout from '../../../../shared/analyticsComponents/analyticsTableLayout';
import { getRangeForPeriod } from '../../../../shared/analyticsComponents/utils';
import {
  getSavedActiveProjects,
  toastifyError,
  getWasteFractionFromContainer,
  uniqueWasteFractions,
} from '../../../../shared/utils';
import { generateContainerTypes } from '../../../../shared/notificationComponents/utils';
import LoadingLayout from '../../../../shared/loading';
import SettingsPopup from '../settingsPopup';
import PieChart from '../../dashboard/components/PieChart';
import { currencyList } from '../../settings/project/defaults/data';
import ArrowIcon from '../../../../../images/icons/arrowBottom.svg';
import useOnScreen from '../../../../shared/hooks/useOnScreen';
import { getWeightToVolumeCoef } from '../components/utils';
import useRegularUser from '../../../../shared/hooks/useRegularUser';
import ButtonTooltip from '../../../../shared/tooltip/ButtonTooltip';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.variables.cAntiFlashWhite,
    color: theme.variables.VHDarkGrey,
    fontSize: '11px',
    paddingRight: '10px',
  },
  body: {
    fontSize: '12px',
    paddingRight: '10px',
  },
  root: {
    height: '26px',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    height: '26px',
  },
}))(TableRow);

const allContainersQuery = loader(
  '../../../../graphql/queries/analytics/containers_analytics.graphql',
);

const containersAutocompleteGql = loader(
  '../../../../graphql/queries/containers/containers_autocomplete.graphql',
);

const collectionAnalyticsQuery = loader(
  '../../../../graphql/queries/analytics/collection_analytics.graphql',
);

const getFilterData = loader('../../../../graphql/queries/containers/container_filters.graphql');

const Overview = ({
  units: { currency, weightUnit, volumeUnit },
  savedActiveProjects,
  handleConfigChange,
  analyticsConfig,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const visibleElementRef = useRef();
  const analyticsTableRef = useRef();
  const analyticsInfoRef = useRef();

  const isAnalyticVisible = useOnScreen(visibleElementRef);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isRegularUser] = useRegularUser();
  const [period, setPeriod] = useState(null);
  const [allWasteFractions, setAllWasteFractions] = useState([]);
  const [currencyCoef, setCurrencyCoef] = useState(1);
  const [allContainerTypes, setAllContainerTypes] = useState([]);
  const [costOverviewType, setCostOverviewType] = useState('totalCostOverview');
  const [costOverviewPeriod, setCostOverviewPeriod] = useState('selected');
  const [chartType, setChartType] = useState('daily');
  const [comparisonPeriodType, setComparisonPeriodType] = useState();
  const [selectedCombinations, setSelectedCombinations] = useState([]);
  const [customPeriodFrom, setCustomPeriodFrom] = useState(null);
  const [customPeriodTo, setCustomPeriodTo] = useState(null);
  const [customPeriodComparisonFrom, setCustomPeriodComparisonFrom] = useState(null);
  const [customPeriodComparisonTo, setCustomPeriodComparisonTo] = useState(null);

  useEffect(() => {
    switch (analyticsConfig.analyticsCalculationType) {
      case 'previous_period':
        setPeriod(analyticsConfig.analyticsPreviousPeriod);
        setComparisonPeriodType('previous_period');
        break;
      case 'historical_data':
        setPeriod(analyticsConfig.analyticsHistoricalPeriod);
        setComparisonPeriodType('historical_data');
        break;
      case 'custom_period':
        setCustomPeriodFrom(analyticsConfig.analyticsCustomPeriodFrom);
        setCustomPeriodTo(analyticsConfig.analyticsCustomPeriodTo);
        setCustomPeriodComparisonFrom(analyticsConfig.analyticsCustomPeriodComparisonFrom);
        setCustomPeriodComparisonTo(analyticsConfig.analyticsCustomPeriodComparisonTo);
        setComparisonPeriodType('custom_period');
        break;
      default:
        break;
    }
  }, [
    analyticsConfig.analyticsCalculationType,
    analyticsConfig.analyticsCustomPeriodFrom,
    analyticsConfig.analyticsCustomPeriodTo,
    analyticsConfig.analyticsPreviousPeriod,
    analyticsConfig.analyticsHistoricalPeriod,
    analyticsConfig.analyticsCustomPeriodComparisonFrom,
    analyticsConfig.analyticsCustomPeriodComparisonTo,
    setPeriod,
    setComparisonPeriodType,
  ]);

  const startDate = useMemo(() => getRangeForPeriod(period).start, [period]);

  const endDate = useMemo(() => getRangeForPeriod(period).end, [period]);

  const currencyObj = currencyList.find(({ value }) => value === currency);
  const currencySymbol = currencyObj.symbol || currencyObj.value;

  const weightToVolumeCoef = useMemo(() => getWeightToVolumeCoef(weightUnit, volumeUnit), [
    weightUnit,
    volumeUnit,
  ]);

  const [
    getBussinessAnalytics,
    {
      data: { businessAnalytics = {} } = {},
      loading: collectionLoading,
      refetch: businessAnalyticsRefetch,
    },
  ] = useLazyQuery(collectionAnalyticsQuery, {
    onError: toastifyError,
    suspend: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  const handleCalculateAnalytics = (
    periodType,
    selectedPreviousPeriod = null,
    customDates = {},
  ) => {
    if (periodType === 'historical_data') {
      if (comparisonPeriodType === 'historical_data') {
        businessAnalyticsRefetch();
      } else {
        setComparisonPeriodType(periodType);
      }
    } else if (periodType === 'previous_period') {
      setComparisonPeriodType('previous_period');
      setPeriod(selectedPreviousPeriod);
    } else if (periodType === 'custom_period') {
      setComparisonPeriodType('custom_period');
      setCustomPeriodFrom(customDates.customPeriodFrom);
      setCustomPeriodTo(customDates.customPeriodTo);
      setCustomPeriodComparisonFrom(customDates.customPeriodComparisonFrom);
      setCustomPeriodComparisonTo(customDates.customPeriodComparisonTo);
    }
  };

  const createPieChartData = (total, greenWhenRaise) => [
    {
      name: intl.formatMessage({ id: 'selected_period', defaultMessage: 'Selected period' }),
      value: Math.abs(total),
      fill: (total > 0 && greenWhenRaise) || (total < 0 && !greenWhenRaise) ? '#50b42c' : '#d53f3f',
    },
    {
      name: intl.formatMessage({ id: 'comparison_period', defaultMessage: 'Comparison period' }),
      value: total < 100 ? 100 - Math.abs(total) : 0,
      fill: '#e4e7ee',
    },
  ];

  useEffect(() => {
    if (analyticsConfig && comparisonPeriodType) {
      const variables = {
        periodType: comparisonPeriodType,
        period,
        selectedCombinations,
        activeProjects: savedActiveProjects,
        From: startDate,
        To: endDate,
        comparisonFrom: null,
        comparisonTo: null,
      };

      switch (comparisonPeriodType) {
        case 'historical_data':
          variables.comparisonFrom = startDate;
          variables.comparisonTo = endDate;
          variables.period = period;
          break;
        case 'custom_period':
          variables.From = customPeriodFrom;
          variables.To = customPeriodTo;
          variables.comparisonFrom = customPeriodComparisonFrom;
          variables.comparisonTo = customPeriodComparisonTo;
          break;
        case 'previous_period':
          variables.comparisonFrom = moment(startDate - (endDate - startDate));
          variables.comparisonTo = startDate;
          break;
        default:
          variables.comparisonFrom = moment(startDate - (endDate - startDate));
          variables.comparisonTo = startDate;
      }

      getBussinessAnalytics({
        variables,
      });
    }
  }, [
    startDate,
    endDate,
    savedActiveProjects,
    getBussinessAnalytics,
    selectedCombinations,
    comparisonPeriodType,
    analyticsConfig,
    customPeriodComparisonFrom,
    customPeriodComparisonTo,
    customPeriodFrom,
    customPeriodTo,
    period,
  ]);

  useEffect(() => {
    fetch(`https://api.exchangerate.host/convert?from=USD&to=${currency}&amount=1`)
      .then((res) => res.json())
      .then((res) => {
        setCurrencyCoef(res.result);
      });
  }, [currency]);

  const chartData = useMemo(() => {
    const dataArray = [];
    if (businessAnalytics?.stats?.containerLogs?.length) {
      businessAnalytics.stats.containerLogs.forEach((emptying) => {
        const item = {
          date: moment(emptying.day),
          value: emptying.totalCount,
        };

        if (period === 'day') {
          item.date = item.date.format('HH:mm');
        } else if (['week', 'month', 'quarter'].includes(period)) {
          item.date = item.date.format('DD/MM');
        } else {
          item.date = item.date.format('DD/MM/YY');
        }

        dataArray.push(item);
      });
    }
    return dataArray;
  }, [businessAnalytics?.stats?.containerLogs, period]);

  const chartAggregatedData = useMemo(() => {
    const dataArray = [];
    let total = 0;
    if (businessAnalytics?.stats?.containerLogs?.length) {
      businessAnalytics.stats.containerLogs.forEach((emptying) => {
        total += emptying.totalCount;
        const item = {
          date: moment(emptying.day),
          value: total,
        };

        if (period === 'day') {
          item.date = item.date.format('HH:mm');
        } else if (['week', 'month', 'quarter'].includes(period)) {
          item.date = item.date.format('DD/MM');
        } else {
          item.date = item.date.format('DD/MM/YY');
        }

        dataArray.push(item);
      });
    }

    return dataArray;
  }, [businessAnalytics?.stats?.containerLogs, period]);

  const uniqueObjectsFilter = (arr) =>
    Array.from(new Set(arr?.map((a) => a.name))).map((name) => arr?.find((a) => a.name === name)) ||
    [];

  const fractions = useMemo(
    () =>
      allWasteFractions.map((node) => ({
        value: node.id,
        label: getWasteFractionFromContainer({ wasteFraction: node }),
        project: node.project.id,
      })),
    [allWasteFractions],
  );

  const containerTypes = useMemo(
    () => generateContainerTypes(allContainerTypes.map((node) => node)),
    [allContainerTypes],
  );

  const [getContainerFiltersData, { loading: isLoading }] = useLazyQuery(getFilterData, {
    variables: {
      activeProjects: savedActiveProjects,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (filtersResponse) => {
      if (filtersResponse?.allContainerTypes?.edges) {
        setAllContainerTypes(
          uniqueObjectsFilter(filtersResponse.allContainerTypes.edges.map((item) => item.node)),
        );
      }
      if (filtersResponse?.allWasteFractions?.edges) {
        setAllWasteFractions(
          uniqueWasteFractions(filtersResponse.allWasteFractions.edges.map((item) => item.node)),
        );
      }
    },
  });

  useEffect(() => {
    getContainerFiltersData();
  }, [getContainerFiltersData]);

  const createOverviewRows = useCallback(
    (data) => {
      if (!data || !containerTypes.length || !fractions.length) return [];

      const rows = [];

      for (let i = 0; i < Object.keys(data).length; i += 1) {
        const costOverviewInfo = data[i];

        rows.push({
          combination: `${costOverviewInfo.containerType.name} + ${getWasteFractionFromContainer(
            costOverviewInfo,
          )}`,
          collection: costOverviewInfo.avgCollection,
          overflowing: costOverviewInfo.avgOverflowing,
          costPerTonne: costOverviewInfo.avgDisposal,
          incomePerTonne: costOverviewInfo.avgIncome,
        });
      }
      return rows;
    },
    [containerTypes?.length, fractions?.length],
  );

  const createTotalCostOverviewRows = useCallback(
    (data) => {
      if (!data || !containerTypes.length || !fractions.length) return [];

      const rows = [];

      for (let i = 0; i < Object.keys(data).length; i += 1) {
        const costOverviewInfo = data[i];

        rows.push({
          combination: `${costOverviewInfo.containerType.name} + ${getWasteFractionFromContainer(
            costOverviewInfo,
          )}`,
          collection: costOverviewInfo.totalCollection,
          overflowing: costOverviewInfo.totalOverflowing,
          costPerTonne: costOverviewInfo.totalDisposal,
          incomePerTonne: costOverviewInfo.totalIncome,
        });
      }

      return rows;
    },
    [containerTypes?.length, fractions?.length],
  );

  const costOverviewRows = useMemo(
    () =>
      costOverviewPeriod === 'selected'
        ? createOverviewRows(businessAnalytics?.costOverview)
        : createOverviewRows(businessAnalytics?.comparisonCostOverview),
    [costOverviewPeriod, businessAnalytics, createOverviewRows],
  );

  const totalCostOverviewRows = useMemo(
    () =>
      costOverviewPeriod === 'selected'
        ? createTotalCostOverviewRows(businessAnalytics?.costOverview)
        : createTotalCostOverviewRows(businessAnalytics?.comparisonCostOverview),
    [costOverviewPeriod, businessAnalytics, createTotalCostOverviewRows],
  );

  const memoMoment = useMemo(() => moment(), []);

  const handleScroll = () => {
    const elementToScroll = isAnalyticVisible ? analyticsTableRef : analyticsInfoRef;
    elementToScroll.current.scrollIntoView({ behavior: 'smooth' });
  };

  const calculateTotal = (dataObject) => {
    if (dataObject) {
      const comparison = Math.round(dataObject.comparison);
      const selected = Math.round(dataObject.selected);

      if (selected && comparison) {
        return (selected / comparison) * 100 - 100;
      }

      if (selected && !comparison) {
        return 100;
      }

      if (!selected && comparison) {
        return -100;
      }

      if (!selected && comparison) {
        return -100;
      }

      return 0;
    }

    return 0;
  };

  return (
    <Grid container spacing={0} className="h-100" ref={analyticsInfoRef}>
      <Button
        onClick={handleScroll}
        variant="outlined"
        color="primary"
        className={classes.toggleButton}
        type="button"
      >
        {isAnalyticVisible ? (
          <>
            <img src={ArrowIcon} alt="arrow" />
            <FormattedMessage id="go_to_table" defaultMessage="Go to table" />
          </>
        ) : (
          <>
            <img src={ArrowIcon} className={classes.arrowTop} alt="arrow" />
            <FormattedMessage id="go_to_analytics" defaultMessage="Go to analytics" />
          </>
        )}
      </Button>
      <Grid
        item
        xs={12}
        className={classNames('fullHeightCol listCol', classes.analyticsContainer)}
      >
        <LoadingLayout loading={collectionLoading} />
        <Grid container className={classes.header} id="information" justify="space-between">
          <Grid container item xs={9} wrap="nowrap" justify="space-between">
            <FormattedMessage id="pageName.analytics" defaultMessage="Overview">
              {(txt) => <span className={classes.headerTitle}>{txt}</span>}
            </FormattedMessage>
          </Grid>
        </Grid>
        <Grid container className={classes.mainContainer}>
          <Grid container xs={12} wrap="nowrap" justify="flex-end">
            {['previous_period', 'historical_data'].includes(comparisonPeriodType) && (
              <Grid container>
                <Tabs
                  onChange={(e, value) => setPeriod(value)}
                  value={period}
                  className={classes.dateTabs}
                  aria-label="tabs.date_tabs"
                >
                  <Tab
                    label={intl.formatMessage({
                      id: 'tabs.day',
                      defaultMessage: 'Day',
                    })}
                    value="day"
                    className={classes.dateTab}
                  />
                  <Tab
                    label={intl.formatMessage({
                      id: 'tabs.week',
                      defaultMessage: 'Week',
                    })}
                    value="week"
                    className={classes.dateTab}
                  />
                  <Tab
                    label={intl.formatMessage({
                      id: 'tabs.month',
                      defaultMessage: 'Month',
                    })}
                    value="month"
                    className={classes.dateTab}
                  />
                  <Tab
                    label={intl.formatMessage({
                      id: 'tabs.quarter',
                      defaultMessage: 'Quarter',
                    })}
                    value="quarter"
                    className={classes.dateTab}
                  />
                  <Tab
                    label={intl.formatMessage({
                      id: 'tabs.year',
                      defaultMessage: 'Year',
                    })}
                    value="year"
                    className={classes.dateTab}
                  />
                </Tabs>
              </Grid>
            )}

            <Grid item>
              <ButtonTooltip
                text={
                  isRegularUser
                    ? intl.formatMessage({
                        id: 'tooltip.no_permission_analytics_settings',
                        defaultMessage: "You don't have permission for settings",
                      })
                    : ''
                }
              >
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.costSettingsButton}
                  onClick={() => setDialogOpen(true)}
                  tourid="comparisonPeriod"
                  disabled={isRegularUser}
                >
                  <FormattedMessage id="settings" defaultMessage="Settings" />
                </Button>
              </ButtonTooltip>
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.chartsInfoContainer}>
            <Grid item xs={2} className={classes.chartInfoBlock}>
              <FormattedMessage id="savings_total_cost" defaultMessage="Savings (total cost)">
                {(txt) => <span className={classes.chartBlockTitle}>{txt}</span>}
              </FormattedMessage>
              <PieChart
                height={13}
                centeredElement={
                  <span className={classes.chartCenterElement}>
                    {Math.abs(calculateTotal(businessAnalytics?.comparisonSaving).toFixed(0))}%
                  </span>
                }
                activeOnHover={false}
                data={createPieChartData(
                  calculateTotal(businessAnalytics?.comparisonSaving).toFixed(0),
                  false,
                )}
                innerRadius="65%"
              />
              <Grid container>
                <Grid item xs={6}>
                  <FormattedMessage id="current_expenses" defaultMessage="Current expenses">
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {currencyCoef &&
                      currencySymbol &&
                      (businessAnalytics?.comparisonSaving?.selected * currencyCoef).toFixed(
                        0,
                      )}{' '}
                    {currencySymbol}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <FormattedMessage id="previous_expenses" defaultMessage="Previous expenses">
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {currencyCoef &&
                      currencySymbol &&
                      (businessAnalytics?.comparisonSaving?.comparison * currencyCoef).toFixed(
                        0,
                      )}{' '}
                    {currencySymbol}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.chartInfoBlock}>
              <FormattedMessage id="reduced_collections" defaultMessage="Reduced collections">
                {(txt) => <span className={classes.chartBlockTitle}>{txt}</span>}
              </FormattedMessage>
              <PieChart
                height={13}
                centeredElement={
                  <span className={classes.chartCenterElement}>
                    {Math.abs(calculateTotal(businessAnalytics?.reducedCollections).toFixed(0))}%
                  </span>
                }
                activeOnHover={false}
                data={createPieChartData(
                  calculateTotal(businessAnalytics?.reducedCollections).toFixed(0),
                  false,
                )}
                innerRadius="65%"
              />
              <Grid container>
                <Grid item xs={6}>
                  <FormattedMessage id="current_collections" defaultMessage="Current collections">
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {Math.round(businessAnalytics?.reducedCollections?.selected)}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <FormattedMessage id="previous_collections" defaultMessage="Previous collections">
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {Math.round(businessAnalytics?.reducedCollections?.comparison)}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.chartInfoBlock}>
              <FormattedMessage
                id="improved_fill_efficiency"
                defaultMessage="Improved fill-level efficiency"
              >
                {(txt) => <span className={classes.chartBlockTitle}>{txt}</span>}
              </FormattedMessage>
              <PieChart
                height={13}
                centeredElement={
                  <span className={classes.chartCenterElement}>
                    {Math.abs(calculateTotal(businessAnalytics?.fillLevelEfficiency).toFixed(0))}%
                  </span>
                }
                activeOnHover={false}
                data={createPieChartData(
                  calculateTotal(businessAnalytics?.fillLevelEfficiency).toFixed(0),
                  true,
                )}
                innerRadius="65%"
              />
              <Grid container>
                <Grid item xs={6}>
                  <FormattedMessage
                    id="current_avg_fill_level"
                    defaultMessage="Current avg. fill-level"
                  >
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {businessAnalytics?.fillLevelEfficiency?.selected.toFixed(0)} %
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <FormattedMessage
                    id="previous_avg_fill_level"
                    defaultMessage="Previous avg. fill-level"
                  >
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {businessAnalytics?.fillLevelEfficiency?.comparison.toFixed(0)} %
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.chartInfoBlock}>
              <FormattedMessage id="reduced_overflows" defaultMessage="Reduced overflows">
                {(txt) => <span className={classes.chartBlockTitle}>{txt}</span>}
              </FormattedMessage>
              <PieChart
                height={13}
                centeredElement={
                  <span className={classes.chartCenterElement}>
                    {Math.abs(calculateTotal(businessAnalytics?.reduceOverflows).toFixed(0))}%
                  </span>
                }
                activeOnHover={false}
                data={createPieChartData(
                  calculateTotal(businessAnalytics?.reduceOverflows).toFixed(0),
                  false,
                )}
                innerRadius="65%"
              />
              <Grid container>
                <Grid item xs={6}>
                  <FormattedMessage id="current_overflows" defaultMessage="Current overflows">
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {Math.round(businessAnalytics?.reduceOverflows?.selected)}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <FormattedMessage id="previous_overflows" defaultMessage="Previous overflows">
                    {(txt) => <p className={classes.periodText}>{txt}</p>}
                  </FormattedMessage>
                  <p className={classes.periodValue}>
                    {Math.round(businessAnalytics?.reduceOverflows?.comparison)}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.chartInfoBlock}>
              <FormattedMessage id="reduction" defaultMessage="reduction">
                {(txt) => (
                  <span className={classes.chartBlockTitle}>
                    CO<sub>2</sub> {txt}
                  </span>
                )}
              </FormattedMessage>
              <div className={classes.disabledBlock}>
                <PieChart
                  height={13}
                  centeredElement={<span className={classes.chartCenterElement}>0%</span>}
                  activeOnHover={false}
                  data={createPieChartData(0)}
                  innerRadius="65%"
                />
                <p className={classes.periodText}>Under development</p>
              </div>
            </Grid>
            <Grid item xs={2} className={classes.chartInfoBlock}>
              <FormattedMessage id="distance" defaultMessage="Distance">
                {(txt) => <span className={classes.chartBlockTitle}>{txt}</span>}
              </FormattedMessage>
              <div className={classes.disabledBlock}>
                <PieChart
                  height={13}
                  centeredElement={<span className={classes.chartCenterElement}>0%</span>}
                  activeOnHover={false}
                  data={createPieChartData(0)}
                  innerRadius="65%"
                />
                <p className={classes.periodText}>Under development</p>
              </div>
            </Grid>
          </Grid>
          <Grid container xs={12} ref={visibleElementRef}>
            <Grid item xs={6} className={classes.infoBlock}>
              <FormattedMessage id="potential_savings" defaultMessage="Potential savings">
                {(txt) => <span className={classes.topInfoTitle}>{txt}</span>}
              </FormattedMessage>
              <Grid container xs={12}>
                <Grid item xs={3} className={classes.infoSubBlock}>
                  <p className={classes.infoValue}>
                    {(businessAnalytics?.estimatedSavings * currencyCoef).toFixed(0)}{' '}
                    {currencySymbol}
                  </p>
                  <FormattedMessage
                    id="estimated_annual_savings"
                    defaultMessage="Estimated annual savings"
                  >
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </Grid>
                <Grid item xs={3} className={classes.infoSubBlock}>
                  <p className={classes.infoValue}>
                    {(businessAnalytics?.decreasedCollections * currencyCoef).toFixed(0)}{' '}
                    {currencySymbol}
                  </p>
                  <FormattedMessage
                    id="decrease_number_of_collections"
                    defaultMessage="Decreased number of collections"
                  >
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </Grid>
                <Grid item xs={3} className={classes.infoSubBlock}>
                  <p className={classes.infoValue}>-</p>
                  <FormattedMessage
                    id="decrease_co2_emission"
                    defaultMessage="Decrease CO2 emission"
                  >
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </Grid>
                <Grid item xs={3} className={classes.infoSubBlock}>
                  <p className={classes.infoValue}>
                    {(businessAnalytics?.decreasedOverflowingBins * currencyCoef).toFixed(0)}{' '}
                    {currencySymbol}
                  </p>
                  <FormattedMessage
                    id="decrease_in_overflowing_bins"
                    defaultMessage="Decrease in overflowing bins"
                  >
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.infoBlock}>
              <FormattedMessage id="current_stats" defaultMessage="Current stats">
                {(txt) => <span className={classes.topInfoTitle}>{txt}</span>}
              </FormattedMessage>
              <Grid container xs={12}>
                <div className={classNames('w-20', classes.infoSubBlock)}>
                  <p className={classes.infoValue}>
                    {businessAnalytics?.stats?.containers?.totalCount}
                  </p>
                  <FormattedMessage id="containers" defaultMessage="Containers">
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </div>
                <div className={classNames('w-20', classes.infoSubBlock)}>
                  <p className={classes.infoValue}>
                    {Math.round(businessAnalytics?.reducedCollections?.selected)}
                  </p>
                  <FormattedMessage
                    id="number_of_collections"
                    defaultMessage="Number of collections"
                  >
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </div>
                <div className={classNames('w-20', classes.infoSubBlock)}>
                  <p className={classes.infoValue}>
                    {businessAnalytics?.fillLevelEfficiency?.selected?.toFixed(0)} {'%'}
                  </p>
                  <FormattedMessage
                    id="avg_collection_efficiency"
                    defaultMessage="Average collection efficiency"
                  >
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </div>
                <div className={classNames('w-20', classes.infoSubBlock)}>
                  <p className={classes.infoValue}>
                    {(businessAnalytics?.costPerEmptyingAvg * currencyCoef).toFixed(0)}{' '}
                    {currencySymbol}
                  </p>
                  <FormattedMessage
                    id="avg_cost_per_emptying"
                    defaultMessage="Avg. cost per emptying"
                  >
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </div>
                <div className={classNames('w-20', classes.infoSubBlock)}>
                  <p className={classes.infoValue}>
                    {Math.round(businessAnalytics?.reduceOverflows?.selected)}
                  </p>
                  <FormattedMessage id="number_of_overflows" defaultMessage="Number of overflows">
                    {(txt) => <p className={classes.infoLabel}>{txt}</p>}
                  </FormattedMessage>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} className={classes.bottomInfoBlock}>
              <Grid container justify="space-between">
                <Grid item>
                  <FormattedMessage id="emptyings" defaultMessage="Emptyings">
                    {(txt) => <span className={classes.topInfoTitle}>{txt}</span>}
                  </FormattedMessage>
                </Grid>
                <Grid item>
                  <Tabs
                    onChange={(e, value) => setChartType(value)}
                    value={chartType}
                    className={classNames(classes.dateTabs, classes.chartButtonMargin)}
                    aria-label="tabs.chartType"
                  >
                    <Tab
                      label={intl.formatMessage({
                        id: 'tabs.daily',
                        defaultMessage: 'Daily',
                      })}
                      value="daily"
                      className={classes.dateTab}
                    />
                    <Tab
                      label={intl.formatMessage({
                        id: 'tabs.aggregated',
                        defaultMessage: 'Aggregated',
                      })}
                      value="aggregated"
                      className={classes.dateTab}
                    />
                  </Tabs>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <div className={classes.chartBlock}>
                  <FormattedMessage id="no_date" defaultMessage="No data">
                    {(txt) => (
                      <span
                        className={classes.absoluteCentered}
                        style={{ display: chartData.length ? 'none' : 'inline' }}
                      >
                        {txt}
                      </span>
                    )}
                  </FormattedMessage>
                  <ResponsiveContainer width="99.9%" heigth="85%">
                    <AreaChart
                      data={
                        chartType === 'daily'
                          ? chartData.map((item) => ({ ...item, value: +item.value }))
                          : chartAggregatedData.map((item) => ({ ...item, value: +item.value }))
                      }
                      margin={{
                        top: 15,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="5 5" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <ChartTooltip />
                      <Area type="monotone" dataKey="value" stroke="#df9838" fill="#e58e3e" />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              className={classNames(classes.bottomInfoBlock, classes.bottomInfoBlockPadding)}
            >
              <Grid container xs={12} wrap="nowrap" justify="space-between">
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <FormattedMessage id="cost_overview" defaultMessage="Cost overview">
                        {(txt) => <span className={classes.topInfoTitle}>{txt}</span>}
                      </FormattedMessage>
                    </Grid>
                    <Grid item>
                      <Tabs
                        onChange={(e, value) => setCostOverviewPeriod(value)}
                        value={costOverviewPeriod}
                        className={classes.selectedComparisonTabs}
                        aria-label="tabs.data_type_tabs"
                      >
                        <Tab
                          label={intl.formatMessage({
                            id: 'tabs.selected',
                            defaultMessage: 'Selected',
                          })}
                          value="selected"
                          className={classes.selectedComparisonTab}
                        />
                        <Tab
                          label={intl.formatMessage({
                            id: 'tabs.comparison',
                            defaultMessage: 'Comparison',
                          })}
                          value="comparison"
                          className={classes.selectedComparisonTab}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Tabs
                    onChange={(e, value) => setCostOverviewType(value)}
                    value={costOverviewType}
                    className={classes.dataTypeTabs}
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="tabs.data_type_tabs"
                  >
                    <Tab
                      label={intl.formatMessage({
                        id: 'tabs.data_type_dynamic',
                        defaultMessage: 'Total сost overview',
                      })}
                      value="totalCostOverview"
                      className={classes.dataTypeTab}
                    />
                    <Tab
                      label={intl.formatMessage({
                        id: 'tabs.data_type_static',
                        defaultMessage: 'Cost overview ',
                      })}
                      value="costOverview"
                      className={classes.dataTypeTab}
                    />
                  </Tabs>
                </Grid>
              </Grid>
              <TableContainer className={classes.costOverviewTableContainer}>
                <Scrollbars autoHeight>
                  {costOverviewType === 'costOverview' ? (
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Combination</StyledTableCell>
                          <StyledTableCell align="right">Collections</StyledTableCell>
                          <StyledTableCell align="right">Overflowing</StyledTableCell>
                          <StyledTableCell align="right">Disposal</StyledTableCell>
                          <StyledTableCell align="right">Income</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {costOverviewRows?.map((row) => (
                          <StyledTableRow key={row.combination}>
                            <StyledTableCell component="th" scope="row">
                              {row.combination}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.collection * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.overflowing * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.costPerTonne * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.incomePerTonne * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Combination</StyledTableCell>
                          <StyledTableCell align="right">Collections (total)</StyledTableCell>
                          <StyledTableCell align="right">Overflowing (total)</StyledTableCell>
                          <StyledTableCell align="right">Disposal (total)</StyledTableCell>
                          <StyledTableCell align="right">Income (total)</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {totalCostOverviewRows?.map((row) => (
                          <StyledTableRow key={row.combination}>
                            <StyledTableCell component="th" scope="row">
                              {row.combination}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.collection * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.overflowing * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.costPerTonne * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {(row.incomePerTonne * currencyCoef).toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </Scrollbars>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            item
            id="analyticsTable"
            xs={12}
            className={classNames('m-t-5', classes.analyticsTableContainer)}
          >
            <AnalyticsTableLayout
              startDate={
                businessAnalytics?.stats?.containerLogs?.length
                  ? moment(businessAnalytics.stats.containerLogs[0].day)
                  : memoMoment
              }
              endDate={
                businessAnalytics?.stats?.containerLogs.length
                  ? moment(
                      businessAnalytics.stats.containerLogs[
                        businessAnalytics.stats.containerLogs.length - 1
                      ].day,
                    )
                  : memoMoment
              }
              tableRef={analyticsTableRef}
              updateUserConfig={handleConfigChange}
              autoCompleteQuery={containersAutocompleteGql}
              query={allContainersQuery}
              searchSize={3}
              paginationClass="all-routes-pagination"
              allRowsEnabled={false}
              isRedirectOnSelect={false}
              isSearchAutocomplete={false}
              tableComponentWrapperStyles={{
                paddingBottom: '0',
              }}
              displayScrollbar
              tableContainerClass={classes.tableContainer}
              multiSelectable
              savedActiveProjects={savedActiveProjects}
              classes={classes}
              fractions={fractions}
              containerTypes={containerTypes}
              period={period}
              currencyCoef={currencyCoef}
              currencySymbol={currencySymbol}
            />
          </Grid>
          {dialogOpen && (
            <SettingsPopup
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              classes={classes}
              fractions={fractions}
              containerTypes={containerTypes}
              isLoading={isLoading}
              weightToVolumeCoef={weightToVolumeCoef}
              currencyCoef={currencyCoef}
              currencySymbol={currencySymbol}
              weightSymbol={weightUnit}
              volumeSymbol={volumeUnit}
              handleCalculateAnalytics={handleCalculateAnalytics}
              businessAnalyticsRefetch={businessAnalyticsRefetch}
              selectedCombinations={selectedCombinations}
              setSelectedCombinations={setSelectedCombinations}
              customPeriodComparisonFrom={customPeriodComparisonFrom}
              customPeriodComparisonTo={customPeriodComparisonTo}
              customPeriodTo={customPeriodTo}
              customPeriodFrom={customPeriodFrom}
              period={period}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Overview.propTypes = {
  savedActiveProjects: PropTypes.string.isRequired,
  handleConfigChange: PropTypes.func.isRequired,
  analyticsConfig: PropTypes.shape({
    analyticsCalculationType: PropTypes.string,
    analyticsPreviousPeriod: PropTypes.string,
    analyticsCustomPeriodFrom: PropTypes.string,
    analyticsCustomPeriodTo: PropTypes.string,
    analyticsHistoricalPeriod: PropTypes.string,
    analyticsCustomPeriodComparisonFrom: PropTypes.string,
    analyticsCustomPeriodComparisonTo: PropTypes.string,
  }).isRequired,
  units: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    weightUnit: PropTypes.string.isRequired,
    volumeUnit: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  savedActiveProjects: getSavedActiveProjects(state),
  units: state.settings.user.activeProjects.edges.length
    ? {
        currency: state.settings.user.activeProjects.edges[0].node.settings.currency,
        weightUnit: state.settings.user.activeProjects.edges[0].node.settings.weight,
        volumeUnit: state.settings.user.activeProjects.edges[0].node.settings.volume,
      }
    : {
        currency: 'USD',
        volumeUnit: 'L',
        weightUnit: 'KG',
      },
  analyticsConfig: {
    analyticsCalculationType: state.settings.userLayoutConfig.analyticsCalculationType.toLowerCase(),
    analyticsPreviousPeriod: state.settings.userLayoutConfig.analyticsPreviousPeriod.toLowerCase(),
    analyticsCustomPeriodFrom: state.settings.userLayoutConfig.analyticsCustomPeriodFrom,
    analyticsCustomPeriodTo: state.settings.userLayoutConfig.analyticsCustomPeriodTo,
    analyticsCustomPeriodComparisonFrom:
      state.settings.userLayoutConfig.analyticsCustomPeriodComparisonFrom,
    analyticsCustomPeriodComparisonTo:
      state.settings.userLayoutConfig.analyticsCustomPeriodComparisonTo,
    analyticsHistoricalPeriod: state.settings.userLayoutConfig.analyticsHistoricalPeriod.toLowerCase(),
  },
});

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Overview));
