import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    backgroundColor: '#f0f2f7',
    padding: theme.spacing(0, 1),
    flexGrow: 1,
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      backgroundColor: '#df9838',
      color: 'black',
      '&:hover': {
        backgroundColor: '#df9838',
        color: 'black',
      },
    },
  },
}))(ToggleButtonGroup);

const SettingsDropdownUnitSettings = ({ groups, disabled }) =>
  groups.map((group) => (
    <Grid item xs={12} key={group.label}>
      <Typography variant="subtitle2" className="p-l-5">
        {group.label}:
      </Typography>
      <StyledToggleButtonGroup
        className="w-100"
        value={group.value}
        onChange={!disabled && group.handleChange}
        exclusive
      >
        {group.valuesList.map((value) => (
          <ToggleButton value={value.value} key={value.value}>
            {value.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Grid>
  ));

SettingsDropdownUnitSettings.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string,
      handleChange: PropTypes.func,
      valuesList: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
      ),
    }),
  ).isRequired,
  disabled: PropTypes.bool,
};

SettingsDropdownUnitSettings.defaultPropTypes = {
  disabled: false,
};
export default SettingsDropdownUnitSettings;
