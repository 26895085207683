import React, { useCallback, useMemo } from 'react';
import { loader } from 'graphql.macro';
import FormikAsyncSelect from '@wastehero/storybook/lib/components/Form/Elements/Select/Async/Formik';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getSavedActiveProjects } from '../../../../shared/utils';

const query = loader('./../../../../graphql/queries/fleet_management/all_vehicles.graphql');

const VehicleSelect = ({ multiple, name, label, required }) => {
  const availableProjects = useSelector((state) => getSavedActiveProjects(state));
  const queryResultFormatter = useCallback(
    (values) =>
      (values?.allVehicles?.edges || []).map(({ node }) => ({
        key: node.id,
        label: node.name,
        value: node.id,
      })),
    [],
  );

  const queryVariablesFormatter = useCallback(
    (search, queryVariables) => ({
      ...queryVariables,
    }),
    [],
  );

  const queryVariables = useMemo(
    () => ({
      activeProjects: availableProjects,
    }),
    [availableProjects],
  );

  // const onSelect = useCallback((value, option) => {
  //   console.log('onSelect', value, option);
  // }, []);

  const antd = {
    label,
    labelCol: { span: 24, offset: 0 },
    required,
  };

  return (
    <FormikAsyncSelect
      mode={multiple ? 'multiple' : undefined}
      name={name}
      query={query}
      queryResultFormatter={queryResultFormatter}
      queryVariablesFormatter={queryVariablesFormatter}
      queryVariables={queryVariables}
      // onSelect={onSelect}
      antd={antd}
    />
  );
};

VehicleSelect.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

VehicleSelect.defaultProps = {
  multiple: true,
  label: 'Vehicle',
  name: 'vehicle',
  required: false,
};

export default VehicleSelect;
