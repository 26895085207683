import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from './reducers';

const logger = (...args) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    window.console.log(...args);
  }
};

const dispatchLogger = () => (next) => (action) => {
  // logger('dispatching', action);
  const result = next(action);
  // logger('next state', store.getState());
  return result;
};

const crashReporter = () => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    logger('Caught an exception!', err);
    throw err;
  }
};

const bindMiddleware = (middleware) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    return composeWithDevTools(applyMiddleware(middleware));
  }

  return applyMiddleware(middleware);
};

const store = createStore(
  createRootReducer(),
  compose(bindMiddleware(dispatchLogger, crashReporter)),
);
export default store;
