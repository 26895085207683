import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import {
  FormikRadioGroup,
  FormikFastField,
  FormikSelect,
  FormikTextField,
} from '../../../../../shared/inputs/formik';

import RegisterMeasurementDialog from '../../../settings/project/registerMeasurementDialog';
import { SelectType } from './types';
import NoItemsForSelect from '../../../../../shared/apiPopulatedSelects/noItemsForSelect';
import { allContainerQuery } from './utils';
import AutocompleteFilter from '../../../../../shared/autocompleteFilter/autocompleteFilter';
import { getWasteFractionFromContainer } from '../../../../../shared/utils';

const containerQuery = loader('./../../../../../graphql/queries/devices/one_container.graphql');

const useStyles = makeStyles((theme) => ({
  textFieldContainer: {
    width: '100%',
    '& > .MuiTextField-root': {
      width: '100%',
    },
  },
  selectWrapper: {
    '& .MuiSelect-root': {
      paddingTop: 23,
      paddingBottom: 7,
    },
  },
  deleteIconBtn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  container: {
    marginTop: 16,
    width: '100%',
  },
  roundedButton: {
    borderRadius: 32.5,
    '&.MuiButton-root': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.variables.cEmperor,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0,
  },
}));

const AttachDeviceToContainerPanel = ({
  itemReadonly,
  canUnAssign,
  setAndValidateContainerId,
  values,
  handleRemoveAttachedDevice,
  pathToField,
  selectOrCreate,
  onCreate,
  setFieldValue,
  projectId,
  allMeasurementSettings,
  resetContainerSelect,
  containerId,
}) => {
  const [openRegisterMS, setOpenRegisterMS] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const classes = useStyles();
  const intl = useIntl();

  const handleChange = (nextValue) => {
    if (`${nextValue}`.match(/\d.\d{5,}/)) {
      setFieldValue(`${pathToField}internalHeight`, `${nextValue}`.slice(0, 7));
    } else {
      setFieldValue(`${pathToField}internalHeight`, nextValue);
    }
  };
  const containerAutocompleteSerializer = ({ allContainers: { edges: arr = [] } = {} }) =>
    arr.map(({ node }) => ({
      primaryText: node?.containerId,
      secondaryText: getWasteFractionFromContainer(node),
      logo: node?.photoUrl,
      id: node.id,
    }));

  const [getContainerDetails, { data: containerDetailsData }] = useLazyQuery(containerQuery, {
    onCompleted: ({ container }) => {
      setSelectedContainer(container);
      if (setAndValidateContainerId) {
        setAndValidateContainerId(container?.id);
      }
    },
  });

  useEffect(() => {
    if (containerDetailsData) {
      setSelectedContainer(containerDetailsData.container);
      if (setAndValidateContainerId) {
        setAndValidateContainerId(containerDetailsData.container?.id);
      }
    }
  }, [containerDetailsData, setSelectedContainer, setAndValidateContainerId]);

  useEffect(() => {
    if (containerId) {
      getContainerDetails({
        variables: {
          id: containerId,
        },
      });
    }
  }, [containerId, getContainerDetails, values.containerId]);

  const customOnSubmit = (newValue) => {
    if (newValue) {
      getContainerDetails({
        variables: {
          id: newValue.id,
        },
      });
    }
    setAndValidateContainerId(containerDetailsData?.container?.id);
  };
  const content = (
    <Grid container spacing={4} justify="center" alignItems="center">
      <Grid item xs={12} container>
        <Grid item xs={selectOrCreate ? 8 : 12}>
          {itemReadonly ? (
            <Typography align="center" className={classes.title}>
              <FormattedMessage id="sensor:" defaultMessage="Sensor:" />
              {` ${values.devId}`}
            </Typography>
          ) : (
            <AutocompleteFilter
              containerId={containerDetailsData?.containerId}
              imperativeValue={selectedContainer?.containerId}
              query={allContainerQuery.query}
              serializer={containerAutocompleteSerializer}
              queryExtraVariables={{ activeProjects: values?.projectId }}
              isRedirectOnSelect={false}
              customOnInputChange={resetContainerSelect}
              customOnSubmit={customOnSubmit}
              notPreventRequestOnEmptyField
            />
          )}
        </Grid>
        {selectOrCreate && (
          <Grid item xs={3} container justify="space-around" alignItems="center">
            <span>
              <FormattedMessage id="or" defaultMessage="or" />
              &nbsp;
            </span>
            <Button
              onClick={onCreate}
              variant="outlined"
              type="button"
              className={classes.roundedButton}
            >
              <FormattedMessage id="Create" defaultMessage="Create" />
            </Button>
          </Grid>
        )}
      </Grid>
      {canUnAssign && (
        <Grid container item xs={12} justify="center">
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.unassign_sensor"
                defaultMessage="Click to remove the assignment of this sensor"
              />
            }
            avoidIcon
            tooltipStyle="h-100"
          >
            <Button
              onClick={handleRemoveAttachedDevice}
              variant="outlined"
              type="button"
              className={classes.roundedButton}
            >
              <FormattedMessage id="button.unassign_sensor" defaultMessage="Unassign sensor" />
            </Button>
          </TooltippedUserControl>
        </Grid>
      )}
      <Grid item>
        <TooltippedUserControl
          tooltipText={
            <FormattedMessage id="tooltip.lid_type" defaultMessage="Choose the lid type" />
          }
          avoidIcon
        >
          <FormikFastField
            component={FormikRadioGroup}
            aria-label="lid"
            name={`${pathToField}lid`}
            options={[
              { value: 'top', label: 'Top Lid' },
              { value: 'side', label: 'Side Lid' },
            ]}
            className="p-l-5"
            row
          />
        </TooltippedUserControl>
      </Grid>
      <Grid container item xs={12} justify="space-between">
        <Grid item xs={12} md={3} className={classes.textFieldContainer}>
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.correctHeight"
                defaultMessage="Enter the distance from the sensor glass to the bottom of the container"
              />
            }
          >
            <FormikTextField
              fast
              name={`${pathToField}internalHeight`}
              required={false}
              onChange={handleChange}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label={intl.formatMessage({
                id: 'label.internalHeight',
                defaultMessage: 'Sensor distance',
              })}
              placeholder={intl.formatMessage({
                id: 'placeholder.internalHeight',
                defaultMessage: 'E.g. 1.95',
              })}
            />
          </TooltippedUserControl>
        </Grid>
        <Grid item xs={12} md={3} className={classes.textFieldContainer}>
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.offset"
                defaultMessage="Enter the distance between sensor glass and container top"
              />
            }
          >
            <FormikTextField
              fast
              name={`${pathToField}offset`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label={intl.formatMessage({ id: 'label.offset', defaultMessage: 'Sensor offset' })}
              placeholder={intl.formatMessage({
                id: 'placeholder.offset',
                defaultMessage: 'E.g. 0.045',
              })}
            />
          </TooltippedUserControl>
        </Grid>
        {values.lid === 'side' && (
          <Grid item xs={12} md={3} className={classes.textFieldContainer}>
            <TooltippedUserControl
              tooltipText={
                <FormattedMessage
                  id="tooltip.deviceAngle"
                  defaultMessage="Enter the angle at which the sensor is placed"
                />
              }
            >
              <FormikTextField
                fast
                name={`${pathToField}deviceAngle`}
                required={false}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">°</InputAdornment>,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label={intl.formatMessage({
                  id: 'label.deviceAngle',
                  defaultMessage: 'Sensor Angle',
                })}
                placeholder={intl.formatMessage({
                  id: 'placeholder.deviceAngle',
                  defaultMessage: 'E.g. 45',
                })}
              />
            </TooltippedUserControl>
          </Grid>
        )}
      </Grid>
      {projectId ? (
        <Grid item xs={12} container className={classes.textFieldContainer} justify="space-between">
          {allMeasurementSettings.length ? (
            <Grid item xs={8}>
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.measurementSettings.select"
                    defaultMessage="Select measurement settings for device"
                  />
                }
              >
                <FormikSelect
                  fast
                  required
                  name={`${pathToField}measurementId`}
                  valuesList={allMeasurementSettings}
                  label={intl.formatMessage({
                    id: 'label.measurementSettings',
                    defaultMessage: 'Measurement settings',
                  })}
                  filledStyle
                />
              </TooltippedUserControl>
            </Grid>
          ) : (
            <Grid item xs={9} container alignItems="center">
              <NoItemsForSelect
                link={`/app/settings/${projectId}/measurement-settings`}
                message={
                  <FormattedMessage
                    id="no_measurement_settings"
                    defaultMessage="No measurement settings registered."
                  />
                }
                linkText={
                  <FormattedMessage
                    id="add_measurement_settings"
                    defaultMessage="Click to add new."
                  />
                }
              />
            </Grid>
          )}
          {values.measurementId && values.customMeasurementId === values.measurementId ? (
            <Grid
              item
              xs={3}
              container
              justify="center"
              alignItems="center"
              className={classNames({
                'p-t-25': allMeasurementSettings.length,
              })}
            >
              <Button onClick={() => setOpenRegisterMS(true)} variant="outlined" type="button">
                <FormattedMessage id="edit_custom" defaultMessage="Edit custom" />
              </Button>
            </Grid>
          ) : (
            <Grid
              item
              xs={3}
              container
              justify="space-between"
              alignItems="center"
              className="p-t-25"
            >
              <Button
                onClick={() => setOpenRegisterMS(true)}
                variant="outlined"
                type="button"
                className={classes.roundedButton}
              >
                <FormattedMessage id="create_custom" defaultMessage="Create custom" />
              </Button>
            </Grid>
          )}
          <RegisterMeasurementDialog
            open={openRegisterMS}
            projectId={projectId}
            editId={
              (values.customMeasurementId === values.measurementId && values.customMeasurementId) ||
              ''
            }
            onClose={(measurement = {}) => {
              if (measurement.id) {
                setFieldValue(`${pathToField}measurementId`, measurement.id || '', true);
                setFieldValue(`${pathToField}customMeasurementId`, measurement.id || '', true);
              }
              setOpenRegisterMS(false);
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} md={8}>
        <Divider light={false} variant="middle" />
      </Grid>
    </Grid>
  );

  return <div className={classes.container}>{content}</div>;
};

AttachDeviceToContainerPanel.propTypes = {
  pathToField: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  handleRemoveAttachedDevice: PropTypes.func,
  onCreate: PropTypes.func,
  selectOrCreate: PropTypes.bool,
  itemReadonly: PropTypes.bool,
  canUnAssign: PropTypes.bool,
  projectId: PropTypes.string,
  setFieldValue: PropTypes.func,
  allMeasurementSettings: PropTypes.arrayOf(SelectType),
  setAndValidateContainerId: PropTypes.func,
  resetContainerSelect: PropTypes.func,
  containerId: PropTypes.string,
};

AttachDeviceToContainerPanel.defaultProps = {
  handleRemoveAttachedDevice: null,
  itemReadonly: false,
  canUnAssign: true,
  pathToField: '',
  values: {},
  setFieldValue: () => {},
  allMeasurementSettings: [],
  projectId: '',
  selectOrCreate: false,
  onCreate: () => {},
  setAndValidateContainerId: null,
  resetContainerSelect: null,
  containerId: '',
};

export default React.memo(AttachDeviceToContainerPanel);
