import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PropTypes from 'prop-types';
import useStyles from './styles';

const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
    margin: 5,
  },
})(AccordionSummary);

const Panel = ({ texts, header }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid item xs={11}>
      <Accordion onChange={handleChange}>
        <IconLeftAccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        >
          <div className={classes.question}>{header}</div>
        </IconLeftAccordionSummary>
        {texts.map((text, index) => (
          <AccordionDetails key={index} className={classes.details}>
            <Typography component="div" variant="body1" gutterBottom className={classes.textPanel}>
              {text}
            </Typography>
          </AccordionDetails>
        ))}
        <Grid item xs={12}>
          <Divider className={classes.mainDivider} />
        </Grid>
      </Accordion>
    </Grid>
  );
};
Panel.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ),
};
Panel.defaultProps = {
  header: '',
  texts: [],
};

export default Panel;
