import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TooltippedUserControl from './TooltippedUserControl';

const ButtonTooltip = ({ children, text }) => (
  <TooltippedUserControl tooltipStyle="h-100" tooltipText={text} avoidIcon>
    <Grid item xs={12} className="h-100">
      {children}
    </Grid>
  </TooltippedUserControl>
);

ButtonTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default ButtonTooltip;
