import React from 'react';
import { Hidden, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import CreateDeviceForm from './CreateDeviceForm';
import CreateDeviceDialog from './CreateDeviceDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    paddingTop: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.variables.cEmperor,
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
  },
}));

const CreateDevicePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden smUp>
        <CreateDeviceDialog />
      </Hidden>
      <Typography variant="h5" align="center" className={classes.title}>
        <FormattedMessage id="adding_sensor" defaultMessage="Adding Sensor" />
      </Typography>
      <CreateDeviceForm />
    </div>
  );
};

export default CreateDevicePage;
