import { Button, Grid, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import formikInjectedPropsTypes from '../inputs/formik/formikPropTypes';
import { memoizeFields } from '../inputs/formik';

export const SAVE_BUTTONS_VARIANTS = {
  saveAndAdd: 'save_and_add_another',
  saveAndContinue: 'save_and_continue_editing',
  save: 'save',
};

export const actionHandler = ({
  resetForm,
  reInitForm,
  history,
  baseUrl,
  toDetail,
  onEditPage,
}) => ({ obj, action }) => {
  const detailPage = `${baseUrl}/${obj.id}`;
  const addPage = `${baseUrl}/add`;
  const editPage = `${baseUrl}/${obj.id}/edit`;
  if (action === SAVE_BUTTONS_VARIANTS.save) {
    if (toDetail) {
      history.push(detailPage);
    } else {
      history.push(baseUrl);
    }
  } else if (action === SAVE_BUTTONS_VARIANTS.saveAndContinue) {
    if (!onEditPage) {
      history.push(editPage);
    }
    reInitForm(obj);
  } else if (action === SAVE_BUTTONS_VARIANTS.saveAndAdd) {
    if (onEditPage) {
      history.push(addPage);
    }
    resetForm();
  }
};

const SubmitButtonsSet = ({ classes, formik, saveAndEdit }) => (
  <Grid container item xs={12} justify="center">
    <Grid container item xs={12} md={8} justify="space-evenly">
      <Grid item xs={12} md={3}>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          className={classes.btn}
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.setFieldValue('action', SAVE_BUTTONS_VARIANTS.saveAndAdd);
            formik.submitForm();
          }}
        >
          <FormattedMessage
            id="button.save_and_add_another"
            defaultMessage="Save and add another"
          />
        </Button>
      </Grid>
      {saveAndEdit && (
        <Grid item xs={12} md={3}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            className={classes.btn}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.setFieldValue('action', SAVE_BUTTONS_VARIANTS.saveAndContinue);
              formik.submitForm();
            }}
          >
            <FormattedMessage
              id="button.save_and_continue_editing"
              defaultMessage="Save and continue editing"
            />
          </Button>
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          className={classes.btn}
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.setFieldValue('action', SAVE_BUTTONS_VARIANTS.save);
            formik.submitForm();
          }}
        >
          <FormattedMessage id="button.save" defaultMessage="Save" />
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

SubmitButtonsSet.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  saveAndEdit: PropTypes.bool,
};

SubmitButtonsSet.defaultProps = {
  saveAndEdit: true,
};

const styles = (theme) => ({
  btn: {
    width: '100%',
    fontSize: 17,
    textTransform: 'none',
    boxShadow: theme.variables.defaultBoxShadow,
    borderRadius: 32.5,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(5.5),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default withStyles(styles)(
  connect(
    React.memo(SubmitButtonsSet, memoizeFields(['classes', ({ formik }) => formik.isSubmitting])),
  ),
);
