import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import RegisterContainerTooltipedField from './registerContainerTooltipedField';
import { FormikSelect } from '../../../../../../shared/inputs/formik';
import { pickupMethods } from '../../shared/utils';

const SelectPickupMethod = ({ changePickupMethod }) => {
  const intl = useIntl();
  return (
    <RegisterContainerTooltipedField
      md={5}
      tooltipText={
        <FormattedMessage id="tooltip.collectionMethod" defaultMessage="Choose collection method" />
      }
    >
      <FormikSelect
        label={intl.formatMessage({
          id: 'label.collectionMethod',
          defaultMessage: 'Collection method',
        })}
        placeholder={intl.formatMessage({
          id: 'placeholder.collectionMethod',
          defaultMessage: 'Choose collection method',
        })}
        required
        filledStyle
        name="pickupMethod"
        valuesList={pickupMethods}
        onChange={changePickupMethod}
      />
    </RegisterContainerTooltipedField>
  );
};

SelectPickupMethod.propTypes = {
  changePickupMethod: PropTypes.func.isRequired,
};

export default React.memo(SelectPickupMethod);
