import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const ImageContainer = ({ children, imageAlign }) => (
  <Grid item xs={6} align={imageAlign}>
    {children}
  </Grid>
);
ImageContainer.propTypes = {
  children: PropTypes.element.isRequired,
  imageAlign: PropTypes.string,
};

ImageContainer.defaultProps = {
  imageAlign: '',
};

export default ImageContainer;
