import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import useStyles from './formik/styles';
import CustomCheckbox from './StyledCheckbox';

const FilledSelect = ({
  name,
  required,
  placeholder,
  valuesList,
  id,
  readOnly,
  multiSelect,
  className,
  FieldProps,
  onChange,
  filledStyle,
  value,
  noneValueText,
  renderFalsyValues,
  ...otherProps
}) => {
  const classes = useStyles({ inFormik: false, backgroundColor: 'cAntiFlashWhite' });
  const inputId = id || `styledSelect${name}`;
  const allOptionRef = useRef(null);

  return (
    <FormControl className={classNames(classes.formControl, className, classes.input)}>
      <TextField
        id={inputId}
        required={required}
        select
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={value}
        className={classNames({ [classes.selectEmpty]: !filledStyle })}
        inputProps={{
          readOnly,
        }}
        SelectProps={{
          labelId: `label-${inputId}`,
          displayEmpty: true,
          multiple: multiSelect,
          /* eslint-disable react/destructuring-assignment */
          /* eslint-disable react/prop-types */
          renderValue: (renderValue) => {
            if (Array.isArray(renderValue) && renderValue.length) {
              return renderValue.map((val, index) => {
                const choice = valuesList.find((item) => val === item.value);
                return (
                  <span style={{ fontSize: '11px' }} key={`${val} ${index}`}>
                    {choice?.label}
                    {index < renderValue.length - 1 && <>, &nbsp;</>}
                  </span>
                );
              });
            }
            if (renderValue) {
              /* eslint-disable react/prop-types */
              const choice = valuesList.find((item) => renderValue === item.value);
              if (choice) {
                return <span style={{ fontSize: '11px' }}>{choice.label}</span>;
              }
            }
            if (renderFalsyValues) {
              const choice = valuesList.find((item) => renderValue === item.value);
              if (choice) {
                return <span style={{ fontSize: '11px' }}>{choice.label}</span>;
              }
            }
            return (
              <span style={{ fontSize: '11px' }} className={classes.placeholder}>
                {placeholder}
              </span>
            );
          },
          MenuProps: {
            classes: {
              paper: classNames(classes.paper, classes.filledPaper),
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
        {...FieldProps}
        {...otherProps}
      >
        {!required && (
          <MenuItem value="">
            <span ref={allOptionRef} tabIndex="-1" style={{ fontSize: '11px', outline: 'none' }}>
              {noneValueText}
            </span>
          </MenuItem>
        )}
        {valuesList.map((choice, i) => (
          <MenuItem
            style={{ fontSize: '11px' }}
            key={choice.value}
            value={choice.value}
            disabled={choice.disabled}
          >
            {multiSelect && (
              <CustomCheckbox
                color="primary"
                allOptionRef={allOptionRef}
                isLast={i === valuesList.length - 1}
                checked={!!value.find((item) => item === choice.value)}
              />
            )}
            <span style={{ fontSize: '11px' }}>{choice.label}</span>
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

FilledSelect.propTypes = {
  required: PropTypes.bool,
  filledStyle: PropTypes.bool,
  multiSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  valuesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  noneValueText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  FieldProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  ),
  renderFalsyValues: PropTypes.bool,
};

FilledSelect.defaultProps = {
  required: false,
  multiSelect: false,
  placeholder: '',
  readOnly: false,
  filledStyle: false,
  className: '',
  id: '',
  FieldProps: {},
  onChange: () => {},
  noneValueText: (
    <em>
      <FormattedMessage id="select.blank" defaultMessage="None" />
    </em>
  ),
  renderFalsyValues: false,
};

export default FilledSelect;
