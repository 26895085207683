import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

const TimeSeriesChart = (props) => {
  const { title, yAxisTitle, series, legend } = props;

  const options = {
    title: {
      text: title,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    xAxis: {
      type: 'datetime',
    },
    legend: {
      enabled: legend,
    },
    series,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

TimeSeriesChart.propTypes = {
  title: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    }),
  ).isRequired,
  legend: PropTypes.bool,
};
TimeSeriesChart.defaultProps = {
  legend: false,
};

export default React.memo(TimeSeriesChart);
