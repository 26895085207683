import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'unset',
    backgroundColor: 'white',
    marginLeft: 40,
    marginTop: 30,
  },
  title: {
    color: theme.variables.VHBlue,
    marginTop: 10,
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.variables.VHBlue,
    fontSize: 20,
    fontWeight: 500,
    paddingTop: 10,
  },
  smallIcon: {
    width: 10,
    height: 10,
    color: 'DF9838',
  },
  listItemText: {
    alignItems: 'flex-start',
    fontSize: theme.typography.pxToRem(17),
  },
  listItem: {
    alignItems: 'flex-start',
  },
  question: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 600,
    color: theme.variables.VHBlue,
  },
  details: {
    flexDirection: 'column',
  },
  textPanel: {
    marginLeft: 50,
    fontSize: theme.typography.pxToRem(17),
  },
  textContent: {
    fontSize: theme.typography.pxToRem(17),
  },
  textBold: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(17),
  },
  mainDivider: {
    marginBottom: 20,
    marginTop: 15,
  },
  links: {
    color: theme.variables.VHOrange,
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  iconStyle: {
    fontSize: 'large',
    color: theme.variables.VHGrey,
  },
  addIconStyle: {
    fontSize: 'large',
    color: theme.variables.VHBlue,
  },
  expandIcon: {
    order: -1,
    margin: 5,
  },
  orangeIcon: {
    color: theme.variables.VHOrange,
    fontSize: 10,
    marginRight: 20,
  },
  storeBadgeImage: {
    maxWidth: 150,
  },
  stepDivider: {
    width: '40%',
    marginBottom: 20,
  },
  img: {
    display: 'block',
    maxWidth: '90%',
    maxHeight: '90%',
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
  avatarClass: {
    color: theme.variables.cWhite,
    backgroundColor: theme.variables.cOrange,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  tableCells: {
    color: theme.variables.VHBlue,
    fontWeight: 500,
    fontSize: 15,
  },
  tableContainer: {
    marginTop: 20,
    overflow: 'hidden',
  },
  listCircle: {
    color: theme.variables.VHOrange,
    fontSize: 15,
  },
  steplistStyle: {
    marginTop: 30,
  },
  searchInput: {
    height: '100%',
    '& .MuiInputBase-root': {
      borderRadius: 25,
      height: '100%',
    },
    '& input': {
      height: 10,
    },
  },
  primaryText: {
    fontSize: theme.spacing(2),
  },
  secondaryText: {
    fontSize: theme.spacing(1.75),
  },
  searchText: {
    color: '#024559',
    fontWeight: 500,
  },
  searchSize: {
    width: 400,
  },
  greenColor: {
    color: theme.variables.cGreen,
  },
  yellowColor: {
    color: theme.variables.cLemon,
  },
  pinkColor: {
    color: theme.variables.cLightRed,
  },
  orangeColor: {
    color: theme.variables.VHOrange,
  },
  greyColor: {
    color: theme.variables.VHGreyLight,
  },
}));
export default useStyles;
