import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import ComingSoon from '../../../shared/defaultPages/comingSoon';
import navData from './navData';

const SupportCategoryRouter = ({ match }) => (
  <Switch>
    {navData.map((nav) =>
      nav.children.map(
        (subCat) =>
          subCat.component && (
            <Route
              path={`${match.url}${subCat.routeLink}`}
              key={subCat.name}
              component={subCat.component}
            />
          ),
      ),
    )}
    <Route component={ComingSoon} />
  </Switch>
);
SupportCategoryRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(SupportCategoryRouter);
