import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EditIcon from '../../../../../images/icons/edit.svg';

const useStyles = makeStyles(() => ({
  actionButton: {
    width: 32,
    borderRadius: 4,
    position: 'relative',
    float: 'right',
  },
}));

const ActionButton = ({ aria, onClick }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <IconButton
      className={classNames('p-15', classes.actionButton)}
      edge="end"
      aria-label="Context menu"
      aria-owns={aria}
      aria-haspopup="true"
      title={intl.formatMessage({ id: 'context_menu', defaultMessage: 'Context menu' })}
      onClick={onClick}
    >
      <img
        src={EditIcon}
        alt={intl.formatMessage({ id: 'context_menu', defaultMessage: 'Context menu' })}
      />
    </IconButton>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  aria: PropTypes.string,
};

ActionButton.defaultProps = {
  aria: '',
};

export default ActionButton;
