import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { loader } from 'graphql.macro';
import SettingsSelect from '../settings/project/ui/SettingsSelect';
import { SettingsSelectOption } from '../../../shared/notificationComponents/types';
import { saveCacheRead } from '../../../shared/utils';
import TooltippedUserControl from '../../../shared/tooltip/TooltippedUserControl';

const allNotificationSettingsQuery = loader(
  './../../../graphql/queries/devices/all_notification_settings.graphql',
);
const createNotificationSettingsMutation = loader(
  './../../../graphql/mutations/devices/create_notification_setting.graphql',
);
const updateNotificationSettingsMutation = loader(
  './../../../graphql/mutations/devices/update_notification_setting.graphql',
);

const CONDITIONS = [
  {
    value: 'Fill level >',
    label: <FormattedMessage id="fill_level_lt" defaultMessage="Fill level gt" />,
  },
  {
    value: 'Fill level <',
    label: <FormattedMessage id="fill_level_gt" defaultMessage="Fill level lt" />,
  },
  {
    value: 'Temperature >',
    label: <FormattedMessage id="temperature_lt" defaultMessage="Temperature gt" />,
  },
  {
    value: 'Temperature <',
    label: <FormattedMessage id="Temperature_gt" defaultMessage="Temperature lt" />,
  },
];

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      width: '60%',
      minWidth: '600px',
      maxWidth: '100%',
    },
  },
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  selectContainer: {
    '& .MuiSelect-root': {
      paddingTop: '23px',
      paddingBottom: '7px',
    },
  },
  descriptionContainer: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
  },
  tableCellSelectContainer: {
    maxWidth: '1px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  label: {
    paddingLeft: 15,
  },
}));

const CreateNotificationSettingsDialog = ({
  open,
  onClose,
  containers,
  allUsers,
  editingItem,
  handleAddNotificationSetting,
  activeProjects,
  setLoading,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const newEditingItem = editingItem
    ? {
        ...editingItem,
        containerId: editingItem.container.id,
      }
    : {
        containerId: '',
        condition: '',
        threshold: '',
        description: '',
        recipients: [],
        users: { edges: [] },
      };

  const [containerId, setContainerId] = useState(newEditingItem.containerId);
  const [condition, setCondition] = useState(newEditingItem.condition);
  const [threshold, setThreshold] = useState(newEditingItem.threshold);
  const [recipients, setRecipients] = useState(newEditingItem.recipients);
  const [users, setUsers] = useState(
    newEditingItem.users.edges.map(({ node }) => ({
      value: node.id,
      label: `${node.username} - ${node.email}`,
      email: node.email,
      username: node.username,
    })),
  );

  const [description, setDescription] = useState(newEditingItem.description);

  const [selectedUser, setSelectedUser] = useState('');
  const [recipient, setRecipient] = useState('');

  const [createNotificationSetting] = useMutation(createNotificationSettingsMutation);
  const [updateNotificationSetting] = useMutation(updateNotificationSettingsMutation);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const variables = {
      containerId,
      condition,
      threshold,
      recipients,
      users: users.map((user) => user.value),
      description,
    };

    if (
      (recipients.length === 0 && users.length === 0) ||
      !(threshold || threshold === 0) ||
      !condition ||
      !containerId
    ) {
      toast.error(
        intl.formatMessage({
          id: 'toast.required_fields',
          defaultMessage: 'Please fill out all required fields',
        }),
      );
      return;
    }

    setLoading(true);

    let mutation = createNotificationSetting;
    let returnQueryName = 'createNotificationSetting';
    let isEdit = false;

    if (editingItem) {
      isEdit = true;
      mutation = updateNotificationSetting;
      variables.selfId = editingItem.id;
      returnQueryName = 'updateNotificationSetting';
    }

    mutation({
      variables,
      update: async (cache, { data: { [returnQueryName]: newNotificationSetting } }) => {
        const {
          data: { allNotificationSettings: allNotificationSettingsData },
        } = await saveCacheRead({
          query: allNotificationSettingsQuery,
          variables: { activeProjects },
        });

        const newNotificationSettings = { ...allNotificationSettingsData };
        if (editingItem) {
          newNotificationSettings.edges = allNotificationSettingsData.edges.filter(
            ({ node }) => node.id !== newNotificationSetting.notificationSetting.id,
          );
        }

        newNotificationSettings.edges = [
          ...newNotificationSettings.edges,
          {
            node: newNotificationSetting.notificationSetting,
            __typename: 'NotificationSettingsTypeEdge',
          },
        ];

        cache.writeQuery({
          query: allNotificationSettingsQuery,
          variables: { activeProjects },
          data: {
            allNotificationSettings: newNotificationSettings,
          },
        });

        handleAddNotificationSetting(newNotificationSetting.notificationSetting, isEdit);
      },
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'toast.notification_sett_saved',
            defaultMessage: 'Notification setting successfully saved',
          }),
        );
      })
      .catch((error) => {
        if (error.graphQLErrors && error.graphQLErrors.length) {
          error.graphQLErrors.forEach((graphQLError) => toast.error(graphQLError.message));
        } else {
          toast.error(error.message);
        }
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const addSelectedUser = () => {
    if (!selectedUser) {
      toast.error(
        intl.formatMessage({
          id: 'select_user',
          defaultMessage: 'Please select a user',
        }),
      );
      return;
    }

    if (users.find((user) => user.value === selectedUser)) {
      toast.error(`User ${selectedUser} was already added`);
      return;
    }

    const fullUser = allUsers.find((user) => user.value === selectedUser);
    setUsers((oldUsers) => [...oldUsers, fullUser]);
    setSelectedUser('');
  };

  const addRecipientFromInput = () => {
    if (!recipient) {
      toast.error(
        intl.formatMessage({
          id: 'select_user',
          defaultMessage: 'Please select a user',
        }),
      );
      return;
    }

    if (recipients.indexOf(recipient) >= 0) {
      return;
    }
    setRecipients((oldRecipients) => [...oldRecipients, recipient]);
    setRecipient('');
  };

  const removeRecipient = (index) => () => {
    setRecipients((oldRecipients) => {
      const newRecipients = [...oldRecipients];
      newRecipients.splice(index, 1);
      return newRecipients;
    });
  };

  const removeUser = (index) => () => {
    setUsers((oldUsers) => {
      const newUsers = [...oldUsers];
      newUsers.splice(index, 1);
      return newUsers;
    });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          formSubmitHandler(event);
        }
      }}
    >
      <DialogTitle>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.root}>
        <Grid container spacing={2} justify="center" direction="column">
          <Grid container item xs={12} spacing={2} justify="center">
            <Grid item xs={5}>
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.select_container"
                    defaultMessage="Choose a container from which you need notification on"
                  />
                }
              >
                <SettingsSelect
                  required
                  label={intl.formatMessage({
                    id: 'label.select_container',
                    defaultMessage: 'Select container',
                  })}
                  value={containerId}
                  onChange={(event) => setContainerId(event.target.value)}
                  valuesList={containers}
                  className={classes.selectContainer}
                />
              </TooltippedUserControl>
            </Grid>
            <Grid item xs={5}>
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.select_condition"
                    defaultMessage="Choose a condition on fill level/temperature etc"
                  />
                }
              >
                <SettingsSelect
                  required
                  label={intl.formatMessage({
                    id: 'select_condition',
                    defaultMessage: 'Select condition',
                  })}
                  value={condition}
                  onChange={(event) => setCondition(event.target.value)}
                  valuesList={CONDITIONS}
                  className={classes.selectContainer}
                />
              </TooltippedUserControl>
            </Grid>
            <Grid item xs={2}>
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.threshold"
                    defaultMessage="Enter a threshold value for the condition to satisfy"
                  />
                }
              >
                <TextField
                  id="threshold"
                  className="w-100"
                  label={intl.formatMessage({ id: 'Threshold', defaultMessage: 'Threshold' })}
                  required
                  value={threshold}
                  type="number"
                  onChange={(event) => setThreshold(event.target.value)}
                />
              </TooltippedUserControl>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} className={classes.descriptionContainer}>
            <TooltippedUserControl
              tooltipText={
                <FormattedMessage
                  id="tooltip.notification.description"
                  defaultMessage="Enter a description for the notification"
                />
              }
              tooltipStyle="w-100"
            >
              <TextField
                id="description"
                className="w-100"
                label={intl.formatMessage({
                  id: 'label.notification.description',
                  defaultMessage: 'Description',
                })}
                value={description}
                multiline
                onChange={(event) => setDescription(event.target.value)}
              />
            </TooltippedUserControl>
          </Grid>
          <Grid container item xs={12} spacing={2} justify="center" className="m-t-10">
            <Grid item xs={6} spacing={2}>
              {/* list of added users */}
              <Paper className="w-100">
                <TableContainer>
                  <Table className="">
                    <colgroup>
                      <col style={{ width: '80%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TooltippedUserControl
                            tooltipText={
                              <FormattedMessage
                                id="tooltip.user_list_for_notification"
                                defaultMessage="Make a users list who is to be notified"
                              />
                            }
                          >
                            <div className={classes.label}>
                              <FormattedMessage id="User" defaultMessage="User" />
                            </div>
                          </TooltippedUserControl>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.tableCellSelectContainer}>
                          <SettingsSelect
                            label={intl.formatMessage({
                              id: 'label.select_user',
                              defaultMessage: 'Select user',
                            })}
                            value={selectedUser}
                            onChange={(event) => setSelectedUser(event.target.value)}
                            valuesList={allUsers}
                            className={classes.selectContainer}
                          />
                        </TableCell>
                        <TableCell align="right" className="w-20">
                          <IconButton onClick={addSelectedUser}>
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {users.map((user, index) => (
                        <TableRow key={`added_user_${user.id}`}>
                          <TableCell>
                            <Typography>{`${user.username} <${
                              user.email || 'no email'
                            }>`}</Typography>
                          </TableCell>
                          <TableCell align="right" className="w-20">
                            <IconButton onClick={removeUser(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={6} spacing={2}>
              {/* list of added recipients by email */}
              <Paper className="w-100">
                <TableContainer>
                  <Table className="">
                    <colgroup>
                      <col style={{ width: '80%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TooltippedUserControl
                            tooltipText={
                              <FormattedMessage
                                id="tooltip.user_list_for_notification"
                                defaultMessage="Make a users list who is to be notified"
                              />
                            }
                          >
                            <div className={classes.label}>
                              <FormattedMessage id="Email" defaultMessage="Email" />
                            </div>
                          </TooltippedUserControl>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <TextField
                            id="recipient"
                            className="w-100"
                            label={intl.formatMessage({
                              id: 'label.or_enter_email',
                              defaultMessage: '...or enter email',
                            })}
                            type="text"
                            value={recipient}
                            onChange={(event) => setRecipient(event.target.value)}
                          />
                        </TableCell>
                        <TableCell align="right" className="w-20">
                          <IconButton onClick={addRecipientFromInput}>
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {recipients.map((email, index) => (
                        <TableRow key={`added_email_${email}`}>
                          <TableCell>
                            <Typography>{email}</Typography>
                          </TableCell>
                          <TableCell align="right" className="w-20">
                            <IconButton onClick={removeRecipient(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-around">
            <Grid item xs={4}>
              <Button variant="contained" color="secondary" className="w-100" onClick={() => {}}>
                <TooltippedUserControl
                  tooltipText={
                    <FormattedMessage
                      id="tooltip.detect_blockages"
                      defaultMessage="Detect blockages"
                    />
                  }
                  avoidIcon
                >
                  <Typography variant="body1">
                    <FormattedMessage id="detect_blockages" defaultMessage="Detect blockages" />
                  </Typography>
                </TooltippedUserControl>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                className="w-100"
                onClick={formSubmitHandler}
              >
                <TooltippedUserControl
                  tooltipText={
                    <FormattedMessage
                      id="tooltip.save_notification_settings"
                      defaultMessage="Click to add this notificaiton"
                    />
                  }
                  avoidIcon
                >
                  <Typography variant="body1">
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </Typography>
                </TooltippedUserControl>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

CreateNotificationSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  containers: PropTypes.arrayOf(PropTypes.shape(SettingsSelectOption)).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape(SettingsSelectOption)).isRequired,
  editingItem: PropTypes.shape({
    id: PropTypes.string,
    container: PropTypes.shape({
      ...SettingsSelectOption,
      id: PropTypes.string,
      containerType: PropTypes.string,
      wasteType: PropTypes.string,
      location: PropTypes.string,
    }),
    condition: PropTypes.string,
    threshold: PropTypes.number,
    recipients: PropTypes.arrayOf(PropTypes.string),
    users: PropTypes.arrayOf(PropTypes.string),
  }),
  handleAddNotificationSetting: PropTypes.func.isRequired,
  activeProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setLoading: PropTypes.func.isRequired,
};

CreateNotificationSettingsDialog.defaultProps = {
  editingItem: null,
};

export default CreateNotificationSettingsDialog;
