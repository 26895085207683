import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { loader } from 'graphql.macro';
import { setUser } from '../../../../../actions';
import StyledButton from '../../../../shared/inputs/StyledButton';
import ContainerIcon from '../../../../../images/icons/settingsContainerIcon.svg';
import MeasurementIcon from '../../../../../images/icons/settingsMeasurementIcon.svg';
import WasteFractionIcon from '../../../../../images/icons/settingsWasteFractionIcon.svg';
import { saveCacheRead } from '../../../../shared/utils';
import LoadingLayout from '../../../../shared/loading';
import {
  allProjectLevelUserPermissions,
  getAvailableUserPermissions,
  getUserPermissionForProject,
  MASTER,
  SUPER,
  updateUserCache,
} from '../../../../shared/utils/settings';
import { getWasteType } from '../../../../shared/analyticsComponents/utils';
import TooltippedUserControl from '../../../../shared/tooltip/TooltippedUserControl';
import { FormikSelect } from '../../../../shared/inputs/formik';
import FormikTextField from '../../../../shared/inputs/formik/FormikTextField';
import { getJobTitles } from '../../../../shared/utils/constants';

const assignUser = loader('./../../../../graphql/mutations/core/assign_user.graphql');
const allWasteFractionsQuery = loader(
  './../../../../graphql/queries/wastacollector/all_waste_fractions.graphql',
);
const allMeasurementSettingsQuery = loader(
  './../../../../graphql/queries/devices/all_measurement_settings.graphql',
);
const allContainerTypesQuery = loader(
  './../../../../graphql/queries/devices/all_container_types.graphql',
);
const projectQuery = loader('./../../../../graphql/queries/core/project.graphql');

const allCompanies = loader('./../../../../graphql/queries/core/all_companies.graphql');

const TabPanel = ({ children, value, index, ...other }) => (
  <Grid
    container
    justify="center"
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-force-tabpanel-${index}`}
    aria-labelledby={`scrollable-force-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Grid>
);

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const projectOverviewValidationSchema = () =>
  Yup.object().shape({
    role: Yup.string().required(),
    jobTitle: Yup.string().required(),
    email: Yup.string()
      .email(<FormattedMessage id="validation.invalid_email" defaultMessage="Invalid email" />)
      .required(),
  });

const a11yProps = (index) => ({
  id: `scrollable-force-tab-${index}`,
  'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    width: '100%',
  },
  root: {
    marginTop: 57,
    paddingRight: 30,
    paddingLeft: 70,
  },
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    minHeight: 230,
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    color: theme.variables.cOrange,
    marginBottom: 32,
    fontSize: 16,
    fontWeight: 500,
  },
  button: {
    width: 200,
    marginBottom: 50,
    marginRight: 40,
  },
  inviteButton: {
    width: 150,
  },
  indicator: {
    bottom: 0,
    top: 'inherit',
    borderRadius: '0 0 5px 5px',
  },
  selected: {
    border: '1px solid',
    borderColor: theme.variables.cAntiFlashWhite,
    backgroundColor: theme.variables.cAntiFlashWhite,
    borderRadius: '4px',
  },
  paper: {
    border: '1px solid',
    borderColor: theme.variables.cAntiFlashWhite,
    borderRadius: '4px',
    padding: '16.5px',
    maxWidth: 450,
  },
  chip: {
    backgroundColor: theme.variables.cAntiFlashWhite,
    marginRight: '8px',
    marginTop: '8px',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    '& > span': {
      padding: '3px 12px',
    },
    '&:hover': {
      height: 'inherit',
      backgroundColor: theme.variables.cLightGray,
      '& > .MuiChip-label': {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  labelTooltip: {
    paddingTop: 4,
  },
  accordion: {
    border: `1px solid ${theme.variables.cLightGray}`,
    padding: 20,
    overflow: 'hidden',
  },
  invitationLabel: {
    color: theme.variables.cOrange,
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 20,
  },
  accordionSummary: {
    marginLeft: 20,
    marginRight: 10,
  },
}));

const SettingsOverview = ({ data, classes, label, getName }) =>
  data &&
  data.length > 0 && (
    <Paper className={classes.paper}>
      <InputLabel shrink>{label}</InputLabel>
      {data.map(({ node }) => (
        <Chip key={node.id} label={getName(node)} className={classes.chip} />
      ))}
    </Paper>
  );

SettingsOverview.propTypes = {
  getName: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
};

const ProjectOverview = ({ projectId, history, me }) => {
  const classes = useStyles();
  const intl = useIntl();
  const JOB_TITLES = getJobTitles(intl);

  const [tabIndex, setTabIndex] = useState(0);
  const [wasteLoading, setWasteLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [containerLoading, setContainerLoading] = useState(false);
  const [measurementLoading, setMeasurementLoading] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [wasteFractions, setWasteFractions] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);
  const [measurement, setMeasurementSettings] = useState([]);
  const [chosenCompanyId, setChosenCompanyId] = useState();

  const [assignUserMutation] = useMutation(assignUser);

  const { data: { allCompanies: { edges: companies = [] } = {} } = {} } = useQuery(allCompanies);

  useEffect(() => {
    setChosenCompanyId(
      companies.find(({ node }) =>
        node?.projectSet?.edges?.some((projectNode) => projectNode?.node?.id === projectId),
      )?.node?.id,
    );
  }, [companies, projectId]);

  useEffect(() => {
    setWasteLoading(true);
    saveCacheRead({ query: allWasteFractionsQuery, variables: { projectId } })
      .then(
        ({
          data: {
            allWasteFractions: { edges },
          },
        }) => {
          setWasteFractions(edges);
        },
      )
      .finally(() => {
        setWasteLoading(false);
      });
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      setProjectLoading(true);
      saveCacheRead({
        query: projectQuery,
        variables: {
          id: projectId,
          withSettings: true,
        },
      })
        .then(({ data: { project } = {} }) => {
          setProjectData(project);
        })
        .finally(() => {
          setProjectLoading(false);
        });
    }
  }, [projectId]);

  useEffect(() => {
    setContainerLoading(true);
    saveCacheRead({ query: allContainerTypesQuery, variables: { projectId } })
      .then(
        ({
          data: {
            allContainerTypes: { edges },
          },
        }) => {
          setContainerTypes(edges);
        },
      )
      .finally(() => {
        setContainerLoading(false);
      });
  }, [projectId]);

  useEffect(() => {
    setMeasurementLoading(true);
    saveCacheRead({ query: allMeasurementSettingsQuery, variables: { projectId } })
      .then(
        ({
          data: {
            allMeasurementSettings: { edges },
          },
        }) => {
          setMeasurementSettings(edges);
        },
      )
      .finally(() => {
        setMeasurementLoading(false);
      });
  }, [projectId]);

  const loading = wasteLoading || containerLoading || measurementLoading || projectLoading;

  const handleSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
    const isMaster = values.role === MASTER;
    const isSuper = values.role === SUPER;
    setProjectLoading(true);
    return assignUserMutation({
      variables: {
        projectIds: [projectId],
        isSuper,
        isMaster,
        isDemo: false,
        email: values.email,
        jobTitle: values.jobTitle,
      },
      update: updateUserCache(projectId),
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'toast.user_assigned',
            defaultMessage: 'User assigned to project',
          }),
        );
        resetForm();
      })
      .catch((e) => {
        if (e.graphQLErrors && e.graphQLErrors.length) {
          e.graphQLErrors.forEach((graphQLError) => {
            if (graphQLError?.context?.field) {
              setFieldError(graphQLError.context.field, graphQLError.message);
            }
          });
        }
        setSubmitting(false);
      })
      .finally(() => setProjectLoading(false));
  };

  const goToPage = (page) => () => {
    history.push(page);
  };

  const permissionForProject = getUserPermissionForProject(me, projectData);
  const availableUserPermissions = getAvailableUserPermissions(permissionForProject);
  const renderAllGroups = allProjectLevelUserPermissions.filter((group) =>
    availableUserPermissions.includes(group.value),
  );

  return (
    <Grid container className={classes.root} justify="center" spacing={5}>
      <LoadingLayout isLoading={loading} />
      <Grid item xs={5} tourid="inviteUser">
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="invite-content"
            id="invite-header"
            className={classes.accordionSummary}
          >
            <TooltippedUserControl
              tooltipText={
                <FormattedMessage
                  id="tooltip.invite_section"
                  defaultMessage="Invite your colleague or partner to get access to this waste management platform"
                />
              }
              tooltipStyle={classes.labelTooltip}
            />
            <Typography className={classes.invitationLabel}>
              <FormattedMessage
                id="label.invite_section"
                defaultMessage="Invite colleagues or partners"
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Formik
              initialValues={{
                role: '',
                jobTitle: '',
                email: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={projectOverviewValidationSchema()}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <LoadingLayout isLoading={isSubmitting} />
                  <FormControl className={classNames(classes.formControl, 'w-70')} required>
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.account_type"
                          defaultMessage="Select type of account to be created. A regular user has basic access to the platform features. The project owner can create containers and change project settings. A partner can have the project owners accessibility and can create new projects."
                        />
                      }
                      tooltipStyle={classes.labelTooltip}
                    />
                    <FormikSelect
                      name="role"
                      label={intl.formatMessage({
                        id: 'label.account_type',
                        defaultMessage: 'Select account type',
                      })}
                      required
                      valuesList={renderAllGroups}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      filledStyle
                    />
                  </FormControl>
                  <FormControl className={classNames(classes.formControl, 'w-70')} required>
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.jobTitle"
                          defaultMessage="Choose a job title for the person to be invited"
                        />
                      }
                      tooltipStyle="w-100 h-100"
                    >
                      <FormikSelect
                        label={intl.formatMessage({
                          id: 'label.jobTitle',
                          defaultMessage: 'Job title',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'placeholder.jobTitle',
                          defaultMessage: 'Job title',
                        })}
                        required
                        name="jobTitle"
                        valuesList={JOB_TITLES}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        filledStyle
                      />
                    </TooltippedUserControl>
                  </FormControl>
                  <FormControl className={classNames(classes.formControl, 'w-70')} required>
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.email"
                          defaultMessage="Enter email address of the person to be invited. The person will get an email containing a link that can be opened to register here as a new user."
                        />
                      }
                    >
                      <FormikTextField
                        label={intl.formatMessage({
                          id: 'label.email',
                          defaultMessage: 'Invite user',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'placeholder.email',
                          defaultMessage: 'Username',
                        })}
                        required
                        type="email"
                        name="email"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </TooltippedUserControl>
                  </FormControl>
                  <FormControl className={classes.formControl} required>
                    <StyledButton
                      className={classes.inviteButton}
                      type="submit"
                      onClick={submitForm}
                      disabled={isSubmitting}
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.invite"
                            defaultMessage="Click to send an invitation"
                          />
                        }
                        tooltipStyle="h-100"
                        avoidIcon
                      >
                        <Typography>
                          <FormattedMessage id="label.invite" defaultMessage="Invite" />
                        </Typography>
                      </TooltippedUserControl>
                    </StyledButton>
                  </FormControl>
                </Form>
              )}
            </Formik>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={6} tourid="projectOverview">
        <Typography className={classes.label} align="center">
          <FormattedMessage id="project_settings" defaultMessage="Project Settings" />
        </Typography>
        <div className={classes.tabRoot}>
          <Tabs
            classes={{
              indicator: classes.indicator,
            }}
            value={tabIndex}
            onChange={(event, newValue) => setTabIndex(newValue)}
            indicatorColor="primary"
            centered
            textColor="primary"
            aria-label="settings overview"
          >
            <Tab
              classes={{
                selected: classes.selected,
              }}
              icon={<img src={ContainerIcon} alt="Container" />}
              label={containerTypes.length}
              {...a11yProps(0)}
            />
            <Tab
              classes={{
                selected: classes.selected,
              }}
              icon={<img src={MeasurementIcon} alt="Measurement" />}
              label={measurement.length}
              {...a11yProps(1)}
            />
            <Tab
              classes={{
                selected: classes.selected,
              }}
              icon={<img src={WasteFractionIcon} alt="Waste fraction" />}
              label={wasteFractions.length}
              {...a11yProps(2)}
            />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <SettingsOverview
              data={containerTypes}
              label={intl.formatMessage({
                id: 'tabs.selected_containers',
                defaultMessage: 'Selected Containers',
              })}
              getName={(node) => node.name}
              classes={classes}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <SettingsOverview
              data={measurement}
              label={intl.formatMessage({
                id: 'tabs.selected_measurements',
                defaultMessage: 'Selected Measurement',
              })}
              getName={(node) => node.name}
              classes={classes}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <SettingsOverview
              data={wasteFractions}
              label={intl.formatMessage({
                id: 'tabs.selected_waste_fractions',
                defaultMessage: 'Selected waste fractions',
              })}
              getName={(node) => getWasteType(node)}
              classes={classes}
            />
          </TabPanel>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <StyledButton
              className={classes.button}
              type="submit"
              onClick={goToPage(`/app/settings/create-project/${chosenCompanyId}`)}
              tourid="createAnotherProject"
            >
              <FormattedMessage id="create_new_project" defaultMessage="Create another project" />
            </StyledButton>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              type="submit"
              onClick={goToPage('/app/settings')}
            >
              <FormattedMessage id="done" defaultMessage="Done" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProjectOverview.propTypes = {
  projectId: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(withRouter(ProjectOverview)));
