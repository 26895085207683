import React, { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useSelector, connect } from 'react-redux';
import {
  getSavedActiveProjects,
  getUsersAvailableProjects,
  saveCacheRead,
} from '../../../../../shared/utils';
import AddButton from '../../components/addButton';
import ListPageTemplate from '../../components/listPageTemplate';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';
import HeaderTab from '../../components/headerTab';
import {
  BODY_MECHANIZATION_TYPES,
  BODY_TYPES,
  LIFT_MECHANIZATION_TYPES,
  LOADING_LOC_TYPES,
} from '../../components/utils';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import {
  vehicleTypesTableColumns,
  getVehicleTypesTableFilterableColumns,
} from '../../../../../shared/utils/constants';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';
import ButtonTooltip from '../../../../../shared/tooltip/ButtonTooltip';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const allVehicleTypesQuery = loader(
  './../../../../../graphql/queries/fleet_management/all_vehicle_types.graphql',
);
const filterVehicleTypesQuery = loader(
  './../../../../../graphql/queries/fleet_management/filter_vehicle_types.graphql',
);
const deleteVehicleTypeQuery = loader(
  './../../../../../graphql/mutations/fleet_management/delete_vehicle_type.graphql',
);

const vehicleTypesAutocompleteSerializer = ({ allVehicleTypes: { edges: arr = [] } = {} }) =>
  arr.map(({ node }) => {
    const body = BODY_TYPES.find(({ value }) => value === node.body)?.label;
    const bodyMechanization = BODY_MECHANIZATION_TYPES.find(
      ({ value }) => value === node.bodyMechanization,
    )?.label;
    return {
      primaryText: node.name,
      secondaryText: (
        <>
          {body} | {bodyMechanization}
        </>
      ),
      id: node.id,
    };
  });

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
}));

const VehicleTypesList = ({ userLayoutConfig, handleConfigChange, me }) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const classes = useStyles();
  const intl = useIntl();
  const [isRegularUser] = useRegularUser();
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));

  const vehicleTypeSerializer = ({
    allVehicleTypes: { edges: arr = [], totalCount = 0 } = {},
  }) => ({
    totalCount,
    items: arr.map(({ node }) => ({
      id: node.id,
      name: node.name,
      type: node.Type,
      isUsed: node.isUsed,
      allowActions: me.ownProjectsIds.length > 0,
      body: BODY_TYPES.find(({ value }) => value === node.body)?.label || '-',
      bodyMechanization:
        BODY_MECHANIZATION_TYPES.find(({ value }) => value === node.bodyMechanization)?.label ||
        '-',
      liftingMechanization:
        LIFT_MECHANIZATION_TYPES.find(({ value }) => value === node.liftingMechanization)?.label ||
        '-',
      loadingLocation:
        LOADING_LOC_TYPES.find(({ value }) => value === node.loadingLocation)?.label || '-',
    })),
  });

  const queryExtraVariables = useMemo(
    () => ({
      activeProjects: savedActiveProjects,
    }),
    [savedActiveProjects],
  );

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    vehicleTypesTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );

  const [deleteVehicleTypeMutation] = useMutation(deleteVehicleTypeQuery, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: allVehicleTypesQuery }],
  });

  const cellsConfig = [
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getVehicleTypesTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      label: <FormattedMessage id="vehicle_types.type_name" defaultMessage="Type Name" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'type',
      label: <FormattedMessage id="vehicles_types.type" defaultMessage="Type" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'body',
      label: <FormattedMessage id="vehicle_types.body" defaultMessage="Body" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'bodyMechanization',
      label: (
        <FormattedMessage
          id="vehicle_types.body_mechanization"
          defaultMessage="Body Mechanization"
        />
      ),
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'liftingMechanization',
      label: (
        <FormattedMessage
          id="vehicle_types.lifting_mechanization"
          defaultMessage="Lifting Mechanization"
        />
      ),
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'loadingLocation',
      label: (
        <FormattedMessage id="vehicle_types.loading_location" defaultMessage="Loading Location" />
      ),
      numeric: false,
      disablePadding: false,
    },
  ];

  const extraFilter = (
    <>
      <Grid container justify="flex-end">
        <Grid item xs={5}>
          <ButtonTooltip
            text={
              <FormattedMessage
                id={
                  // eslint-disable-next-line no-nested-ternary
                  isRegularUser
                    ? 'tooltip.no_permission_add_vehicle_type'
                    : usersAvailableProjects?.length
                    ? 'tooltip.add_vehicle_type'
                    : 'no_available_projects'
                }
                defaultMessage={
                  // eslint-disable-next-line no-nested-ternary
                  isRegularUser
                    ? "You don't have permission to add vehicle type"
                    : usersAvailableProjects?.length
                    ? 'Click to add a new vehicle type that can be used to create new vehicles'
                    : 'Please add at least one project'
                }
              />
            }
          >
            <AddButton
              text={
                <Typography variant="body1" className={classes.buttonText}>
                  <FormattedMessage id="vehicle.add_type" defaultMessage="Add vehicle type" />
                </Typography>
              }
              disabled={
                !(
                  me.isAdmin ||
                  me.isSuperuser ||
                  me.isMaster ||
                  me.isReseller ||
                  me.ownProjectsIds.length
                )
              }
            />
          </ButtonTooltip>
        </Grid>
      </Grid>
    </>
  );

  const allowDeleteItem = (item) => !item.isUsed;

  const updateCache = useCallback(
    (vehicleType) => async (cache) => {
      const allVehicleTypesFullQuery = {
        query: allVehicleTypesQuery,
        variables: {
          activeProjects: savedActiveProjects,
        },
      };

      const {
        data: { allVehicleTypes: allVehicleTypesData },
      } = await saveCacheRead(allVehicleTypesFullQuery);

      const newVehicleTypesData = {
        ...allVehicleTypesData,
        edges: allVehicleTypesData.edges.filter(({ node }) => node.id !== vehicleType.id),
      };

      cache.writeQuery({
        ...allVehicleTypesFullQuery,
        data: {
          allVehicleTypes: newVehicleTypesData,
        },
      });
    },
    [savedActiveProjects],
  );

  return (
    <ListPageTemplate
      pageTitle={intl.formatMessage({ id: 'vehicle_type', defaultMessage: 'Vehicle Type' })}
      endAdornment={<HeaderTab />}
      userLayoutConfig={userLayoutConfig}
      updateUserConfig={handleConfigChange}
      cellsConfig={cellsConfig}
      autocompleteSerializer={vehicleTypesAutocompleteSerializer}
      autoCompleteQuery={filterVehicleTypesQuery}
      queryExtraVariables={queryExtraVariables}
      extraFilter={extraFilter}
      deleteItemMutation={deleteVehicleTypeMutation}
      query={allVehicleTypesQuery}
      itemsSerializer={vehicleTypeSerializer}
      updateCache={updateCache}
      noLogoLink
      chooseLogoSize={false}
      activeTableColumns={activeTableColumns}
      allowDeleteItem={allowDeleteItem}
      deleteItemErrorTitle={
        <>
          <FormattedMessage
            id="vehicle_type.delete_error"
            defaultMessage="Before deleting a vehicle type, please remove it from a vehicles that are using it."
          />
        </>
      }
      disableSelect
    />
  );
};

VehicleTypesList.propTypes = {
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  me: PropTypes.shape({
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isReseller: PropTypes.bool,
    isMaster: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(withUserLayoutConfig('vehicleTypes')(VehicleTypesList));
