import React from 'react';
import { useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import { Select, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectorOption } from '../../analyticsComponents/types';
import RenderGroup from './RenderGroup';
import PaperComponent from './PaperComponent';
import CheckIcon from '../../../../images/icons/check-2.svg';
import CrossIcon from '../../../../images/icons/cross.svg';
import CustomCheckbox from '../StyledCheckbox';

const useStyles = makeStyles((theme) => ({
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: `0 3px 14px 2px rgba(0,0,0,0.12),
      0 5px 5px -3px rgba(0,0,0,0.2),
      0 8px 10px 1px rgba(0,0,0,0.14)`,
    borderRadius: 3,
    width: 255,
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  inputFocused: {
    '& fieldset': {
      borderColor: `${theme.variables.cLightGray} !important`,
      borderWidth: '1px !important',
    },
  },
  inputBase: {
    padding: 10,
    width: '100%',
    backgroundColor: theme.variables.cWhite,
    '& input': {
      borderRadius: 4,
      padding: 8,
      fontSize: 14,
      border: 'none',
    },
    '&>div:hover fieldset': {
      borderColor: `${theme.variables.cLightGray} !important`,
      borderWidth: '1px !important',
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
    '& [role="listbox"]': {
      paddingTop: 0,
    },

    '& .MuiAutocomplete-listbox': {
      maxHeight: 'unset',
    },
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 0,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"], &:hover': {
      backgroundColor: '#FAFAFC',
    },
  },
  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
  },
  optionContainer: {
    display: 'flex',
    width: '90%',
    height: 32,
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: 4,
    margin: '4px 12px',
    borderRadius: 4,
  },
  text: {
    fontSize: 14,
    fontFamily: theme.variables.defaultFont,
    color: theme.variables.cCharcoal,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  selectAllText: {
    fontSize: 12,
    color: '#939393',
  },
  selectedText: {
    fontWeight: 500,
  },
  selectedContainer: {
    backgroundColor: theme.variables.cAntiFlashWhite,
  },
  selectAllButton: {
    height: 24,
    width: 24,
    padding: 0,
    minWidth: 'unset',
    marginLeft: 8,
    backgroundColor: theme.variables.cWhite,
    boxShadow: theme.variables.defaultBoxShadow,
  },
  selectAllButtonContainer: {
    marginLeft: 'auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const preventClick = () => (e) => {
  e.stopPropagation();
  e.preventDefault();
};

const CustomSelect = ({
  value,
  placeholder,
  render,
  noOptionsText,
  setValue,
  extraClasses,
  options = [],
  loading,
  pickUp,
  inputProps,
  ...restProps
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pendingValue, setPendingValue] = React.useState([]);
  const intl = useIntl();

  const open = Boolean(anchorEl);
  const id = open ? 'openedLabel' : undefined;

  const selectAllOption = {
    key: 'selectAllKey',
    value: intl.formatMessage({ id: 'select_all', defaultMessage: 'select all' }),
  };

  const handleClick = (event) => {
    setPendingValue(value);
    setAnchorEl(open ? null : event.currentTarget);
    const isAllSelected = options.every((selectedOption) =>
      pendingValue.some((option) => option.key === selectedOption.key),
    );
    if (isAllSelected) {
      setPendingValue((oldPendingValue) => [...oldPendingValue, selectAllOption]);
    }
  };

  const handleClose = (_, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    // setValue(pendingValue.filter((val) => val.key !== selectAllOption.key));
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleCancel = (e) => {
    preventClick('Cancel')(e);
    setPendingValue(value);
  };

  const handleSubmit = (e) => {
    preventClick('Submit')(e);
    setValue(pendingValue.filter((val) => val.key !== selectAllOption.key));
    handleClose();
  };

  const renderValue = (selected) => {
    if (selected.length === 0) {
      return placeholder;
    }
    return render(selected);
  };

  const groupBy = (option) => option.groupBy;

  const onChange = (event, newValue) => {
    const isSelectAll = newValue.some((option) => option.key === selectAllOption.key);
    const wasSelectAll = pendingValue.some((option) => option.key === selectAllOption.key);
    const isAllSelected = options.every((selectedOption) =>
      newValue.some((option) => option.key === selectedOption.key),
    );

    if (!isAllSelected && isSelectAll && wasSelectAll) {
      return setPendingValue([...newValue.filter((option) => option.key !== selectAllOption.key)]);
    }

    if (isSelectAll) {
      return setPendingValue([...options, selectAllOption]);
    }

    if (wasSelectAll && !isSelectAll) {
      return setPendingValue([]);
    }

    if (isAllSelected) {
      return setPendingValue([...newValue, selectAllOption]);
    }

    return setPendingValue(newValue);
  };

  /* eslint-disable react/destructuring-assignment */
  const RenderOption = (option, { selected }) => (
    <div
      className={classNames({
        [classes.selectedContainer]: selected,
        [classes.optionContainer]: true,
        [classes.selectAllContainer]: option.key === selectAllOption.key,
      })}
    >
      <CustomCheckbox color="primary" checked={selected} />
      <Tooltip title={option.value} aria-label={option.value}>
        <div
          className={classNames({
            [classes.selectedText]: selected,
            [classes.text]: true,
            [classes.selectAllText]: option.key === selectAllOption.key,
          })}
        >
          {option.value}
        </div>
      </Tooltip>
      {option.key === selectAllOption.key && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={classes.selectAllButtonContainer}
          onClick={preventClick('Button Container')}
        >
          <Button className={classes.selectAllButton} onClick={handleCancel}>
            <img src={CrossIcon} alt="cancel" />
          </Button>
          <Button className={classes.selectAllButton} onClick={handleSubmit}>
            <img src={CheckIcon} alt="apply" />
          </Button>
        </div>
      )}
    </div>
  );

  const RenderInput = (params) => (
    <FormControl variant="filled" fullWidth>
      <TextField
        className={classes.inputBase}
        variant="outlined"
        autoFocus
        onClick={preventClick('Text Input')}
        ref={params.InputProps.ref}
        InputProps={{
          classes: { focused: classes.inputFocused },
          ...params.inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
          ...inputProps,
        }}
      />
    </FormControl>
  );

  const isGroupBy = options.length && options.some((option) => !!option.groupBy);

  const sortedOptions =
    pickUp && !isGroupBy
      ? [...options].sort((a, b) => {
          // Display the selected labels first.
          let ai = value.findIndex(({ key }) => key === a.key);
          ai = ai === -1 ? value.length + options.findIndex(({ key }) => key === a.key) : ai;
          let bi = value.findIndex(({ key }) => key === b.key);
          bi = bi === -1 ? value.length + options.findIndex(({ key }) => key === b.key) : bi;
          return ai - bi;
        })
      : [...options];

  const localOptions =
    sortedOptions && sortedOptions.length ? [selectAllOption, ...sortedOptions] : sortedOptions;

  localOptions.sort((a, b) => (a.groupBy || '').localeCompare(b.groupBy || ''));

  return (
    <>
      <FormControl className={classNames(...extraClasses)}>
        <Select
          multiple
          displayEmpty
          value={value.map((option) => ({ value: option.value, key: option.key }))}
          // input={<Input />}
          renderValue={renderValue}
          onChange={(e) => setValue(e.target.value)}
          open={false}
          onOpen={handleClick}
        >
          {sortedOptions &&
            sortedOptions.map((option) => <MenuItem key={option.key} value={option.value} />)}
        </Select>
      </FormControl>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        className={classes.popper}
        onClick={preventClick('Popper')}
      >
        <Autocomplete
          loading={loading}
          open
          onClose={handleClose}
          blurOnSelect
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={onChange}
          groupBy={isGroupBy ? groupBy : null}
          getOptionSelected={(a, b) => a.key === b.key}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText={noOptionsText || 'No labels'}
          renderGroup={({ key, children }) => (
            <RenderGroup
              selectedOptions={pendingValue}
              selectGroup={onChange}
              options={localOptions}
              groupName={localOptions[key].groupBy}
              key={key || 'ungroupedOptions'}
            >
              {children}
            </RenderGroup>
          )}
          PaperComponent={PaperComponent}
          renderOption={RenderOption}
          options={localOptions}
          getOptionLabel={(option) => option.value}
          renderInput={RenderInput}
          {...restProps}
        />
      </Popper>
    </>
  );
};

CustomSelect.propTypes = {
  value: PropTypes.arrayOf(SelectorOption).isRequired,
  extraClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  noOptionsText: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  render: PropTypes.func,
  loading: PropTypes.bool,
  pickUp: PropTypes.bool,
  options: PropTypes.arrayOf(SelectorOption).isRequired,
  inputProps: PropTypes.objectOf(PropTypes.object),
};

CustomSelect.defaultProps = {
  render: (selected) => selected.map((el) => el).join(', '),
  loading: false,
  pickUp: false,
  inputProps: {},
};

export default React.memo(CustomSelect);
