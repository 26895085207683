import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';

import { toast } from 'react-toastify';
import { FormikSelect, FormikTextField } from '../../../../../shared/inputs/formik';
import QrCodeScanner from '../../../../../shared/qrScanner';
import DeviceIdScanner from './DeviceIdScanner';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const allDeviceTypesQuery = loader(
  '../../../../../graphql/queries/devices/all_device_types.graphql',
);

const createMobileDeviceMutation = loader(
  '../../../../../graphql/mutations/devices/create_mobile_device.graphql',
);

const useStyles = makeStyles((theme) => ({
  form: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 700,
  },
  closeScanner: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  scannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up(600)]: {
      maxWidth: 500,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
  },
  submitButton: {
    borderRadius: 32.5,
    minWidth: 200,
    '& .MuiTypography-root': {
      textTransform: 'none',
    },
  },
}));

const CreateDeviceForm = () => {
  const [showScanner, setShowScanner] = useState(false);
  const [isRegularUser] = useRegularUser();
  const intl = useIntl();
  const classes = useStyles();

  const { data: { allDeviceTypes: { edges = [] } = {} } = {}, loading } = useQuery(
    allDeviceTypesQuery,
  );

  const [createMobileDevice] = useMutation(createMobileDeviceMutation);

  const deviceTypeList = useMemo(
    () => edges.map(({ node }) => ({ value: node.id, label: node.name })),
    [edges],
  );

  const handleChangeShowScanner = () => setShowScanner((prev) => !prev);

  const onSubmit = (values, { setSubmitting, setFieldError, resetForm }) => {
    setSubmitting(true);

    createMobileDevice({
      variables: {
        ...values,
      },
    })
      .then(() => {
        resetForm();
        toast.info(
          intl.formatMessage({
            id: 'toast.sensor_created',
            defaultMessage: 'Sensor was successfully created',
          }),
        );
      })
      .catch((e) => {
        if (e.graphQLErrors && e.graphQLErrors.length) {
          e.graphQLErrors.forEach((graphQLError) => {
            if (graphQLError?.context?.field) {
              setFieldError(graphQLError.context.field, graphQLError.message);
            }
          });
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        deviceTypeId: '',
        devId: '',
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        devId: Yup.string().required(),
        deviceTypeId: Yup.string().required(),
      })}
    >
      {({ setFieldValue, isSubmitting }) => (
        <>
          <Form className={classes.form}>
            {showScanner ? (
              <div className={classes.scannerContainer}>
                <QrCodeScanner
                  onScan={(value) => {
                    if (value) {
                      setFieldValue('devId', value);
                      setShowScanner(false);
                    }
                  }}
                />
                <div className={classes.closeScanner}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    onClick={handleChangeShowScanner}
                  >
                    {intl.formatMessage({
                      id: 'close',
                      defaultMessage: 'Close',
                    })}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <Grid container spacing={2}>
                  {isRegularUser && (
                    <Grid item xs={12} className="text-align-center">
                      <FormattedMessage
                        id="no_permission_add_sensor"
                        defaultMessage="You don’t have permission to add sensor"
                      >
                        {(text) => <span className="no-permission-text">{text}</span>}
                      </FormattedMessage>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="sensor.tooltip.sensor_id"
                          defaultMessage="Enter a unique ID for the sensor"
                        />
                      }
                    >
                      <FormikTextField
                        name="devId"
                        placeholder={intl.formatMessage({
                          id: 'sensor.placeholder.dev_id',
                          defaultMessage: 'E.g. 5633256',
                        })}
                        label={intl.formatMessage({
                          id: 'sensor.label.dev_id',
                          defaultMessage: 'Dev ID',
                        })}
                        required
                      />
                    </TooltippedUserControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="sensor.tooltip.device_type"
                          defaultMessage="Choose the device type for the sensor"
                        />
                      }
                    >
                      <FormikSelect
                        label={intl.formatMessage({
                          id: 'label.device_type',
                          defaultMessage: 'Device Type',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'placeholder.device_type',
                          defaultMessage: 'Choose device type',
                        })}
                        required
                        filledStyle
                        name="deviceTypeId"
                        valuesList={deviceTypeList}
                      />
                    </TooltippedUserControl>
                  </Grid>
                  <DeviceIdScanner
                    onShowScanner={handleChangeShowScanner}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                {!isRegularUser && (
                  <div className={classes.row}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting || loading}
                      className={classes.submitButton}
                    >
                      <Typography variant="body1">
                        <FormattedMessage id="container.register" defaultMessage="Register" />
                      </Typography>
                    </Button>
                  </div>
                )}
              </>
            )}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default React.memo(CreateDeviceForm);
