import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import ReactRouterPropTypes from 'react-router-prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Grid, Paper, Typography, InputAdornment, Button, FormControl } from '@material-ui/core';
import { loader } from 'graphql.macro';
import styles from './styles';
import UserIcon from '../../../../../../images/icons/user.svg';
import EmailIcon from '../../../../../../images/icons/email.svg';
import LoadingLayout from '../../../../../shared/loading';
import AddUserIcon from '../../components/addUserIcon';
import { setUser as setUserAction } from '../../../../../../actions';
import { toastifyError } from '../../../../../shared/utils';
import ChangePasswordDialogue from './components/ChangePasswordDialogLayout';
import ConfirmDialog from '../../../../../shared/dialog/ConfirmDialog';
import getMe from '../../../settings/project/helpers';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import {
  FormikPhoneField,
  FormikSelect,
  FormikTextField,
} from '../../../../../shared/inputs/formik';
import { AVAILABLE_LOCALES } from '../../../../../../translations';
import { getJobTitles } from '../../../../../shared/utils/constants';
import { setFieldErrors } from '../../../fleet-management/components/utils';

const getUserQuery = loader('./../../../../../graphql/queries/core/user.graphql');
const updateUserQuery = loader('./../../../../../graphql/mutations/core/update_user.graphql');
const selfPasswordChangeQuery = loader(
  './../../../../../graphql/mutations/core/self_password_change.graphql',
);
const logoutMutationGQL = loader('./../../../../../graphql/mutations/core/logout.graphql');
const useStyles = makeStyles(styles);

const UserEdit = ({ match, handleSetUser, me, history }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const intl = useIntl();
  const JOB_TITLES = getJobTitles(intl);

  const myId = me.id;
  const {
    params: { userId = '', inProjectId = '' },
  } = match;

  const [userImage, setUserImage] = useState(null);
  const [userImageObj, setUserImageObj] = useState(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openDeactivateUser, setOpenDeactivateUser] = useState(false);
  const [logoutMutation] = useMutation(logoutMutationGQL);

  const [updateUserMutation, { loading: waitUserUpdate }] = useMutation(updateUserQuery, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: getUserQuery, variables: { id: userId || myId } }],
  });

  const [selfPasswordChangeMutation, { loading: waitPasswordChange }] = useMutation(
    selfPasswordChangeQuery,
  );

  const { loading: userLoading, data: { user = {} } = {} } = useQuery(getUserQuery, {
    variables: { id: userId || myId },
  });

  useEffect(() => {
    if (!userImage && user.logo) {
      setUserImage(user.logo);
    }
  }, [user.logo, userImage]);

  const logout = async () => {
    const {
      data: { refuseToken = {} },
    } = await logoutMutation({});

    if (!refuseToken.token) {
      window.localStorage.removeItem('reactQLJWT');
      history.push('/login');
    }
  };

  const handleChangePassword = ({ currentPassword, newPassword, repeatPassword }) =>
    selfPasswordChangeMutation({
      variables: {
        userId,
        currentPassword,
        newPassword,
        repeatPassword,
      },
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'password.changed',
            defaultMessage: 'Password successfully changed',
          }),
        );
        setOpenChangePassword(false);
      })
      .catch(toastifyError);

  const saveUserData = (formVariables, { setSubmitting, setFieldError }) => {
    const variables = {
      userId: user.id,
      logo: userImageObj,
      ...formVariables,
      phoneNumber: formVariables.phoneNumber.replace(/\s|\(|\)/g, ''),
    };

    return updateUserMutation({ variables })
      .then(async () => {
        await getMe({ client, handleSetUser });
        toast.info(
          intl.formatMessage({
            id: 'user_settings.saved',
            defaultMessage: 'User settings successfully saved',
          }),
        );
      })
      .catch((err) => {
        const errorBelongsToField = setFieldErrors(err?.graphQLErrors, setFieldError);
        if (!errorBelongsToField) {
          toastifyError(err);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleDeactivateUser = () => {
    const variables = {
      userId: user.id,
      username: user.username,
      isActive: false,
    };

    setOpenDeactivateUser(false);
    logout();

    return updateUserMutation({ variables }).catch((err) => {
      toastifyError(err);
    });
  };

  const loading = userLoading || waitPasswordChange || waitUserUpdate;

  return (
    <Paper className="p-20">
      <Grid container spacing={5} justify="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <FormattedMessage id="account_settings" defaultMessage="Account Settings" />
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <LoadingLayout isLoading={loading} />
          <Formik
            enableReinitialize
            initialValues={{
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              username: user.username || '',
              email: user.email || '',
              phoneNumber: user.phoneNumber || '',
              jobTitle: user?.jobtitle?.title || '',
              language: user.language ? user.language.toLowerCase() : '',
            }}
            onSubmit={saveUserData}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required(),
              lastName: Yup.string().required(),
              username: Yup.string().required(),
              phoneNumber: Yup.string()
                .transform((phoneNumberString) => phoneNumberString.replace(/\s|\(|\)/g, ''))
                .test(
                  'len',
                  <FormattedMessage
                    id="validation.number.invalid"
                    defaultMessage="Please enter valid number."
                  />,
                  (val) => {
                    if (!val || val === '+') {
                      return true;
                    }
                    return /^\+[1-9]{1}[0-9]{3,14}$/.test(val);
                  },
                ),
              jobTitle: Yup.string().required(),
              email: Yup.string().email(
                <FormattedMessage id="validation.email.invalid" defaultMessage="Invalid email" />,
              ),
            })}
          >
            {({ submitForm, isSubmitting }) => (
              <Form className={classes.form}>
                <LoadingLayout isLoading={isSubmitting} />
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <FormControl className={classes.fieldRow} required>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.firstName"
                            defaultMessage="Enter the first name of the user"
                          />
                        }
                      >
                        <FormikTextField
                          name="firstName"
                          id="firstName"
                          autoComplete="none"
                          className={classes.textField}
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img className={classes.textFieldIcon} src={UserIcon} alt="" />
                              </InputAdornment>
                            ),
                          }}
                          label={intl.formatMessage({
                            id: 'label.firstName',
                            defaultMessage: 'First name',
                          })}
                        />
                      </TooltippedUserControl>
                    </FormControl>
                    <FormControl className={classes.fieldRow} required>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.lastName"
                            defaultMessage="Enter the last name of the user"
                          />
                        }
                      >
                        <FormikTextField
                          name="lastName"
                          id="lastName"
                          autoComplete="none"
                          className={classes.textField}
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img className={classes.textFieldIcon} src={UserIcon} alt="" />
                              </InputAdornment>
                            ),
                          }}
                          label={intl.formatMessage({
                            id: 'label.lastName',
                            defaultMessage: 'Last name',
                          })}
                        />
                      </TooltippedUserControl>
                    </FormControl>
                    <FormControl className={classNames(classes.fieldRow)} required>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.username"
                            defaultMessage="Enter the name for logging in"
                          />
                        }
                      >
                        <FormikTextField
                          name="username"
                          autoComplete="off"
                          type="text"
                          required
                          className={classes.textField}
                          disabled={!!(userId && myId !== userId)}
                          InputProps={{
                            required: true,
                            readOnly: !!(userId && myId !== userId),
                            startAdornment: (
                              <InputAdornment position="start">
                                <img className={classes.textFieldIcon} src={UserIcon} alt="" />
                              </InputAdornment>
                            ),
                          }}
                          helperText={
                            <FormattedMessage
                              id="helptext.logout"
                              defaultMessage="After you change username - you will be logout."
                            />
                          }
                          label={intl.formatMessage({
                            id: 'label.username',
                            defaultMessage: 'Username',
                          })}
                        />
                      </TooltippedUserControl>
                    </FormControl>
                    <FormControl className={classes.fieldRow}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.email"
                            defaultMessage="Enter the email address of the user"
                          />
                        }
                      >
                        <FormikTextField
                          name="email"
                          id="email"
                          autoComplete="off"
                          className={classes.textField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  className={classes.textFieldIcon}
                                  src={EmailIcon}
                                  alt="EmailIcon"
                                />
                              </InputAdornment>
                            ),
                          }}
                          placeholder={intl.formatMessage({
                            id: 'Username',
                            defaultMessage: 'Username',
                          })}
                          label={intl.formatMessage({ id: 'Email', defaultMessage: 'Email' })}
                        />
                      </TooltippedUserControl>
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    align="left"
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      className={classNames(classes.imageContainer)}
                    >
                      <AddUserIcon
                        isCreateMode={false}
                        imageSelectionCallback={setUserImage}
                        imageSelectionDataCallback={setUserImageObj}
                        imageUrl={userImage}
                      />
                      <Grid item>{/*  ToDo: Add user level icon */}</Grid>
                    </Grid>
                    <FormControl className={classNames(classes.selector, classes.fieldRow)}>
                      <FormikPhoneField
                        name="phoneNumber"
                        preferredCountries={['dk', 'gr', 'gb']}
                        defaultCountry="dk"
                        placeholder={intl.formatMessage({
                          id: 'placeholder.mobile_phone',
                          defaultMessage: 'E.g. +4566666678',
                        })}
                        label={intl.formatMessage({
                          id: 'label.mobile_number',
                          defaultMessage: 'Mobile number',
                        })}
                      />
                    </FormControl>
                    <FormControl
                      className={classNames(classes.selector, classes.fieldRow)}
                      required
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.job_title"
                            defaultMessage="Choose the job title of the user"
                          />
                        }
                      >
                        <FormikSelect
                          required
                          filledStyle
                          name="jobTitle"
                          valuesList={JOB_TITLES}
                          label={
                            user?.jobtitle?.pendingTitle === null
                              ? intl.formatMessage({
                                  id: 'label.job_title',
                                  defaultMessage: 'Job title',
                                })
                              : `${intl.formatMessage({
                                  id: 'label.job_title.waitingForApproval',
                                  defaultMessage: 'Job title (waiting for approval of new title - ',
                                })} ${user?.jobtitle?.pendingTitle})`
                          }
                          placeholder={intl.formatMessage({
                            id: 'placeholder.job_title',
                            defaultMessage: 'Job title',
                          })}
                          className={classes.selector}
                          style={{ zIndex: 1 }}
                        />
                      </TooltippedUserControl>
                    </FormControl>
                    <FormControl
                      className={classNames(classes.selector, classes.fieldRow)}
                      required
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.language"
                            defaultMessage="Choose the language"
                          />
                        }
                      >
                        <FormikSelect
                          required
                          filledStyle
                          name="language"
                          valuesList={AVAILABLE_LOCALES}
                          label={intl.formatMessage({
                            id: 'label.language',
                            defaultMessage: 'Language',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.language',
                            defaultMessage: 'Language',
                          })}
                          className={classes.selector}
                        >
                          <FormattedMessage id="label.language" defaultMessage="Language" />
                        </FormikSelect>
                      </TooltippedUserControl>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="flex-start">
                  <Grid item xs={6}>
                    {(!inProjectId || me.isMaster || me.isAdmin) && (
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.button.reset_password"
                            defaultMessage="Click to reset/change the password of the user"
                          />
                        }
                        avoidIcon
                      >
                        <Button
                          color="secondary"
                          variant="contained"
                          className={classes.changePassBtn}
                          onClick={() => {
                            setOpenChangePassword(true);
                          }}
                        >
                          {inProjectId ? (
                            <FormattedMessage
                              id="label.button.reset_password"
                              defaultMessage="Reset password"
                            />
                          ) : (
                            <FormattedMessage
                              id="label.button.change_password"
                              defaultMessage="Change password"
                            />
                          )}
                        </Button>
                      </TooltippedUserControl>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.button.deactivate_my_account"
                          defaultMessage="Click to rdeactivate your user account"
                        />
                      }
                      avoidIcon
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        className={classes.changePassBtn}
                        onClick={() => {
                          setOpenDeactivateUser(true);
                        }}
                      >
                        <FormattedMessage
                          id="label.button.deactivate_my_account"
                          defaultMessage="Deactivate my account"
                        />
                      </Button>
                    </TooltippedUserControl>
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                  {isSubmitting ? (
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.submitBtn}
                      type="submit"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      <FormattedMessage id="save" defaultMessage="Save" />
                    </Button>
                  ) : (
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.button.save"
                          defaultMessage="Click to save the edited details of the user account"
                        />
                      }
                      tooltipStyle={classes.submitBtnContainer}
                      avoidIcon
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.submitBtn}
                        type="submit"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <FormattedMessage id="save" defaultMessage="Save" />
                      </Button>
                    </TooltippedUserControl>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <ChangePasswordDialogue
        open={openChangePassword}
        onConfirm={handleChangePassword}
        onClose={() => setOpenChangePassword(false)}
        masterEdit={Boolean(inProjectId && (me.isMaster || me.isAdmin))}
      />
      <ConfirmDialog
        open={openDeactivateUser}
        onConfirm={() => handleDeactivateUser()}
        onClose={() => setOpenDeactivateUser(false)}
        title={
          <>
            <FormattedMessage
              id="label.button.confirm_deactivate_my_account"
              defaultMessage="Are you sure you want to deactivate your user account?"
            />
          </>
        }
      />
    </Paper>
  );
};

UserEdit.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  handleSetUser: PropTypes.func.isRequired,
  me: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isMaster: PropTypes.bool.isRequired,
    isDemo: PropTypes.bool.isRequired,
    isReseller: PropTypes.bool.isRequired,
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUserAction(user, true));
  },
});

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserEdit));
