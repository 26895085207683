import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import LoadingLayout from '../../../../../shared/loading';
import { getActiveProjects } from '../../../../../shared/utils/settings';
import { allContainerQuery } from '../shared/utils';
import RegisterDeviceForm from './attachFormLayout';
import { attachDeviceHandler, checkDeviceHandler } from './apiCalls';
import Address from '../../../../../shared/google/location';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const attachDeviceMutation = loader(
  './../../../../../graphql/mutations/devices/attach_device.graphql',
);
const checkDeviceIdMutation = loader(
  './../../../../../graphql/mutations/devices/check_device_id.graphql',
);
const allMeasurementSettingsQuery = loader(
  './../../../../../graphql/queries/devices/all_measurement_settings.graphql',
);
const allCompanies = loader('./../../../../../graphql/queries/core/all_companies.graphql');

const containerQuery = loader('./../../../../../graphql/queries/devices/one_container.graphql');

const AttachDevice = ({ activeProjects, match }) => {
  const {
    params: { containerId = '' },
  } = match;

  const [selectedProject, setSelectedProject] = useState('');
  const [isRegularUser] = useRegularUser();
  const [attachDevice] = useMutation(attachDeviceMutation, {
    refetchQueries: [],
    awaitRefetchQueries: true,
  });
  const [checkDeviceId] = useMutation(checkDeviceIdMutation);

  const { loading: isContainersLoading, data: allContainersData } = useQuery(
    allContainerQuery.query,
    {
      variables: {
        ...allContainerQuery.variables,
        activeProjects: selectedProject,
        hasContainerTypeDetails: true,
      },
      displayName: 'allContainers',
      options: { fetchPolicy: 'cache-first' },
    },
  );

  const [getContainer, { data: containerData }] = useLazyQuery(containerQuery, {
    variables: { id: containerId },
  });

  useEffect(() => {
    if (containerId) {
      getContainer(containerId);
    }
  }, [containerId, getContainer]);

  const { loading: isCompaniesLoading, data: allCompaniesData } = useQuery(allCompanies, {
    displayName: 'allCompanies',
    options: { fetchPolicy: 'cache-first' },
  });

  const [
    loadAllMeasurementSettings,
    { loading: isMeasurementSettingsLoading, data: allMeasurementSettingsData },
  ] = useLazyQuery(allMeasurementSettingsQuery, {
    displayName: 'allMeasurements',
    options: { fetchPolicy: 'cache-first' },
  });

  useEffect(() => {
    if (selectedProject) {
      loadAllMeasurementSettings({
        variables: { projectId: selectedProject },
      });
    }
  }, [selectedProject, loadAllMeasurementSettings]);

  const allContainers = useMemo(
    () =>
      allContainersData
        ? allContainersData.allContainers.edges.map(({ node }) => ({
            value: node.id,
            label: (
              <>
                {node.containerId}
                {' - '}
                <Address
                  placeId={node.location.placeId}
                  lng={node.location.longitude}
                  lat={node.location.latitude}
                />
              </>
            ),
            containerType: node.containerType,
            project: node.project,
          }))
        : [],
    [allContainersData],
  );

  const allMeasurementSettings = allMeasurementSettingsData
    ? allMeasurementSettingsData.allMeasurementSettings.edges.map(({ node }) => ({
        value: node.id,
        label: node.name,
        project: node.project,
      }))
    : [];

  const allProjects = useMemo(
    () =>
      allCompaniesData
        ? allCompaniesData.allCompanies.edges.reduce((acc, { node: company }) => {
            acc.push(
              ...company.projectSet.edges.map(({ node: project }) => ({
                value: project.id,
                label: project.name,
              })),
            );
            return acc;
          }, [])
        : [],
    [allCompaniesData],
  );

  useEffect(() => {
    if (activeProjects.length === 1 && !selectedProject) {
      const activeProject = allProjects.find(({ value }) => value === activeProjects[0].node.id);
      if (activeProject) {
        setSelectedProject(activeProject.value);
      }
    }
  }, [activeProjects, allProjects, selectedProject]);

  const loading = isContainersLoading || isCompaniesLoading || isMeasurementSettingsLoading;

  const initialValues = useMemo(() => {
    const defaultValues = {
      deviceId: '',
      measurementId: '',
      customMeasurementId: '',
      lid: 'top',
      deviceAngle: 0,
      internalHeight: 0,
      offset: 0.045,
    };

    if (containerId) {
      if (containerData) {
        if (selectedProject !== containerData.container.project) {
          setSelectedProject(containerData.container.project.id);
        }

        return {
          ...defaultValues,
          containerId,
          projectId: containerData.container.project.id,
          internalHeight: containerData.container.containerType.height - defaultValues.offset,
        };
      }
    }

    return {
      ...defaultValues,
      projectId: selectedProject,
      containerId: '',
    };
  }, [containerId, selectedProject, containerData]);

  return (
    <Paper className="m-20 p-20">
      <LoadingLayout isLoading={loading} />
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          <FormattedMessage id="attach_to_container" defaultMessage="Attach sensor to container" />
        </Typography>
      </Grid>
      <Grid item xs={12} container alignItems="center" direction="column">
        {isRegularUser && (
          <FormattedMessage
            id="no_permission_attach_sensor"
            defaultMessage="You don’t have permission to attach sensor"
          >
            {(text) => <span className="no-permission-text">{text}</span>}
          </FormattedMessage>
        )}
        {!loading && !isRegularUser && (
          <RegisterDeviceForm
            allContainers={allContainers}
            allMeasurementSettings={allMeasurementSettings}
            onSubmit={attachDeviceHandler(attachDevice)}
            checkDeviceHandler={checkDeviceHandler(selectedProject, checkDeviceId)}
            setSelectedProject={setSelectedProject}
            disabledSteps={containerId || activeProjects.length === 1 ? [0] : []}
            initialValues={initialValues}
          />
        )}
      </Grid>
    </Paper>
  );
};

AttachDevice.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  activeProjects: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

const mapStateToProps = (state) => ({
  activeProjects: getActiveProjects(state),
});

export default connect(mapStateToProps)(AttachDevice);
