import userflow from 'userflow.js';

export function toggleSidebarOpen(isSidebarOpened) {
  return { type: 'TOGGLE_SIDEBAR_OPEN', isSidebarOpened };
}

export function setPageTitle(pageTitle) {
  return { type: 'SET_PAGE_TITLE', pageTitle };
}

export function setAllContainersQuery(allContainersQuery) {
  return { type: 'SET_All_CONTAINERS_QUERY', allContainersQuery };
}

export function setUser(user, ignore = false) {
  if (!ignore) {
    userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
    userflow.identify(user.userId, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      username: user.username,
      isAdmin: user.isAdmin,
      isDemo: user.isDemo,
      isSuperuser: user.isSuperuser,
      isReseller: user.isReseller,
      jobTitle: user.jobtitle.title,
    });
  }

  return { type: 'SET_USER', user };
}

export function setActiveProjects(newActiveProjects) {
  return { type: 'SET_ACTIVE_PROJECTS', newActiveProjects };
}

export function setSelectedContainerFromDashboard(container) {
  return { type: 'SET_SELECTED_CONTAINER_FROM_DASHBOARD', container };
}

export function setNewCompanySettings(newCompanySettings) {
  return { type: 'SET_NEW_COMPANY_SETTINGS', newCompanySettings };
}

export function setUserLayoutConfig(config) {
  return { type: 'SET_USER_LAYOUT_CONFIG', config };
}
