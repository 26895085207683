import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyledListLink } from './linkHoverStyle';

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
  },
}));

const ListItemLink = ({ href, primary }) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      component={StyledListLink}
      to={href}
      text={
        <ListItemText
          primary={<div className={classes.menuItem}>{primary}</div>}
          disableTypography
        />
      }
    />
  );
};

ListItemLink.propTypes = {
  href: PropTypes.string.isRequired,
  primary: PropTypes.node.isRequired,
};

export default ListItemLink;
