import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: '10px 0',
  },
  container: {
    margin: '0 10px',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '80px',
    width: '100%',
  },
  icon: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0px 4px',
  },
  status: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  disabledIcon: {
    backgroundColor: theme.variables.cSlate,
  },
  greenIcon: {
    backgroundColor: theme.variables.cGreen,
  },
  yellowIcon: {
    backgroundColor: theme.variables.cLemon,
  },
  orangeIcon: {
    backgroundColor: theme.variables.cOrange,
  },
  redIcon: {
    backgroundColor: theme.variables.cLightRed,
  },
  disabled: {
    color: theme.variables.cSlate,
  },
  green: {
    color: theme.variables.cGreen,
  },
  yellow: {
    color: theme.variables.cLemon,
  },
  orange: {
    color: theme.variables.cOrange,
  },
  red: {
    color: theme.variables.cLightRed,
  },
  value: {
    color: theme.variables.cIndependence,
  },
}));

const HelpBar = ({ inverted }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item className={classes.container}>
        <div className={classNames(classes.icon, inverted ? classes.redIcon : classes.greenIcon)} />
        <span className={classNames(classes.status, inverted ? classes.red : classes.green)}>
          0-25%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div
          className={classNames(classes.icon, inverted ? classes.orangeIcon : classes.yellowIcon)}
        />
        <span className={classNames(classes.status, inverted ? classes.orange : classes.yellow)}>
          25-50%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div
          className={classNames(classes.icon, inverted ? classes.yellowIcon : classes.orangeIcon)}
        />
        <span className={classNames(classes.status, inverted ? classes.yellow : classes.orange)}>
          50-75%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div className={classNames(classes.icon, inverted ? classes.greenIcon : classes.redIcon)} />
        <span className={classNames(classes.status, inverted ? classes.green : classes.red)}>
          75-100%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div className={classNames(classes.icon, classes.disabledIcon)} />
        <span className={classNames(classes.status, classes.disabled)}>
          <FormattedMessage id="disabled" defaultMessage="Disabled" />
        </span>
      </Grid>
    </Grid>
  );
};

HelpBar.propTypes = {
  inverted: PropTypes.bool,
};

HelpBar.defaultProps = {
  inverted: false,
};

export const StyledLabel = ({ color, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.labelContainer}>
      <div className={classNames(classes.icon, classes[`${color}Icon`])}> </div>
      {color !== 'disabled' ? (
        <span className={classNames(classes.status, classes.value)}>{value}</span>
      ) : null}
    </div>
  );
};

StyledLabel.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default HelpBar;
