import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const fileListQuery = loader(
  '../../../../../../graphql/queries/fleet_management/vehicles_media/file_list.graphql',
);

const addFilesMutation = loader(
  '../../../../../../graphql/queries/fleet_management/vehicles_media/upload_file.graphql',
);

function PhotosComponent({ vehicleId }) {
  return <Photo vehicleId={vehicleId} />;
}

const Photo = ({ vehicleId }) => {
  const [fileList, setFiles] = useState([]);
  const [mutate] = useMutation(addFilesMutation);
  const apolloClient = useApolloClient();

  function onFile({ target: { validity, files } }) {
    if (validity.valid) {
      mutate({
        variables: {
          objId: vehicleId,
          contentType: 'PHOTO',
          toDir: '',
          files,
        },
      }).then(({ data }) => {
        apolloClient.resetStore();
        toast.success('Files are uploaded');
        setFiles(data.addFiles.uploadFiles);
      });
    }
  }

  const { loading, data } = useQuery(fileListQuery, {
    variables: {
      objId: vehicleId,
      contentType: 'PHOTO',
      relativePath: '',
    },
  });
  useEffect(() => {
    if (loading === false && data) {
      setFiles(data.fileList.files);
    }
  }, [loading, data]);

  if (loading) {
    return <div>Images are loading...</div>;
  }
  const files = fileList.map((fileObj) => (
    <img width="180" height="180" key={fileObj.name} src={fileObj.url} alt="" />
  ));

  return (
    <div>
      {files}
      <input
        className="MuiButtonBase-root MuiButton-root MuiButton-contained button"
        type="file"
        name="file"
        multiple
        required
        onChange={onFile}
      />
    </div>
  );
};

PhotosComponent.propTypes = {
  vehicleId: PropTypes.string,
};

PhotosComponent.defaultProps = {
  vehicleId: '',
};

Photo.propTypes = {
  vehicleId: PropTypes.string,
};

Photo.defaultProps = {
  vehicleId: '',
};

export default PhotosComponent;
