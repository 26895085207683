import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { loader } from 'graphql.macro';

const allCompanies = loader('./../../graphql/queries/core/all_companies.graphql');

const allCompaniesQuery = {
  query: allCompanies,
  variables: { withUsers: true },
};

export default class Settings {
  constructor(projectSettings) {
    this.temperatureVariant = projectSettings.temperature;
    this.timezone = projectSettings.timezone;
    this.measuringDistanceVariant = projectSettings.measuringDistance || 'M';
    this.weightVariant = projectSettings.weight || 'KG';
    this.volumeVariant = projectSettings.volume || 'L';
    this.drivingDistance = projectSettings.drivingDistance || 'M';
    this.currency = projectSettings.currency || 'USD';
  }

  computeTemperature(value) {
    switch (this.temperatureVariant) {
      case '°C':
        return value;
      case '°F':
        return (value * 9) / 5 + 32;
      default:
        return value;
    }
  }

  computeMeasuringDistance(value) {
    switch (this.measuringDistanceVariant) {
      case 'M':
        return value;
      case 'MM':
        return value * 1000;
      case 'CM':
        return value * 100;
      case 'YD':
        return value * 1.093613;
      case 'IN':
        return value * 39.370079;
      case 'FT':
        return value * 3.28084;
      default:
        return value;
    }
  }

  computeDrivingDistance(value) {
    switch (this.drivingDistance) {
      case 'M':
        return value;
      case 'KM':
        return value * 0.001;
      case 'MI':
        return value * 0.00062137;
      case 'YD':
        return value * 1.093613;
      case 'FT':
        return value * 3.28084;
      default:
        return value;
    }
  }

  computeWeight(value) {
    switch (this.weightVariant) {
      case 'KG':
        return value;
      case 'T':
        return value * 0.001;
      case 'LBS':
        return value * 2.2046;
      default:
        return value;
    }
  }

  computeVolume(value) {
    switch (this.volumeVariant) {
      case 'M3':
        return value;
      case 'L':
        return value * 1000;
      case 'YD3':
        return value * 1.308;
      case 'FT3':
        return value * 28.316847;
      default:
        return value;
    }
  }

  computeWeightToVolume(value, reverse = false) {
    const weightCof = this.computeWeight(1);
    const volumeCof = this.computeVolume(1);

    return reverse ? (value * volumeCof) / weightCof : (value * weightCof) / volumeCof;
  }

  getDate(date) {
    if (!date) {
      return '';
    }

    let momentDate = moment(date);
    if (this.timezone) {
      momentDate = momentDate.tz(this.timezone);
    }
    return momentDate.format('DD/MM/YYYY');
  }

  getDateTime(date) {
    if (!date) {
      return '-';
    }

    let momentDate = moment(date);
    if (this.timezone) {
      momentDate = momentDate.tz(this.timezone);
    }
    return momentDate.format('DD/MM/YYYY HH:mm');
  }

  getTime(time) {
    if (!time) {
      return '-';
    }

    let momentTime = moment.utc(time, 'HH:mm:ss');
    if (this.timezone) {
      momentTime = momentTime.tz(this.timezone);
    }
    return momentTime.format('HH:mm');
  }

  getMeasuringDistance(value) {
    if (!value) {
      return '-';
    }

    const fillLevel = this.computeMeasuringDistance(value);
    return `${fillLevel.toFixed(2)} ${this.measuringDistanceVariant}`;
  }

  getDistance(value) {
    if (!value) {
      return '-';
    }

    const fillLevel = this.computeDrivingDistance(value);

    return `${fillLevel.toFixed(2)} ${this.drivingDistance}`;
  }

  getWeight(value) {
    if (!value) {
      throw new Error('$value is required');
    }

    const weightLevel = this.computeWeight(value);
    return `${weightLevel.toFixed(2)} ${this.weightVariant}`;
  }

  getVolume(value) {
    if (!value) {
      throw new Error('$value is required');
    }

    const volumeLevel = this.computeVolume(value);
    return `${volumeLevel.toFixed(2)} ${this.volumeVariant}`;
  }

  getWeightToVolume(value, reverse) {
    if (!value) {
      return null;
    }

    return this.computeWeightToVolume(value, reverse);
  }

  getTemperature(value) {
    if (!value) {
      return '-';
    }
    const temperature = this.computeTemperature(value);
    return `${temperature} ${this.temperatureVariant}`;
  }
}

export const getCost = async (fromCurrence, toCurrency, value) => {
  if (!value) {
    return '-';
  }
  try {
    const response = await fetch(
      `https://api.exchangerate.host/convert?from=${fromCurrence}&to=${toCurrency}&amount=${value}`,
    );
    const parsedResponse = await response.json();
    return parsedResponse.result.toFixed(2);
  } catch (err) {
    return value;
  }
};

const prepareVolume = (value, volumeVariant) => {
  switch (volumeVariant) {
    case 'M3':
      return value;
    case 'L':
      return value / 1000;
    case 'YD3':
      return value / 1.308;
    case 'FT3':
      return value / 28.316847;
    default:
      return value;
  }
};

const prepareWeight = (value, weightVariant) => {
  switch (weightVariant) {
    case 'KG':
      return value;
    case 'T':
      return value / 0.001;
    case 'LBS':
      return value / 2.2046;
    default:
      return value;
  }
};

/* eslint-disable no-param-reassign */
export const prepareVehicleValues = async (values, settings) => {
  const { currency, volumeVariant, weightVariant } = settings[values.project];

  if (currency !== 'USD') {
    values.fixedCost = await getCost(currency, 'USD', values.fixedCost);
    values.fuelCostPerKm = await getCost(currency, 'USD', values.fuelCostPerKm);
    values.fuelCostPerLtr = await getCost(currency, 'USD', values.fuelCostPerLtr);
  }

  if (values.fuelTankCapacity.length) {
    values.fuelTankCapacity = values.fuelTankCapacity.map((item) =>
      prepareVolume(item, volumeVariant),
    );
  }

  values.cargoVolume = prepareVolume(values.cargoVolume, volumeVariant);
  values.fuelConsumption = prepareVolume(values.fuelConsumption, volumeVariant);
  values.maxPayload = prepareWeight(values.maxPayload, weightVariant);
  values.vehicleWasteCapacity.forEach((item) => {
    item.maxAllowedVolume = prepareVolume(item.maxAllowedVolume, volumeVariant);
    item.maxAllowedWeight = prepareWeight(item.maxAllowedWeight, weightVariant);
  });
};
/* eslint-enable no-param-reassign */

export const getActiveProjects = (state) => {
  const {
    settings: { user: { activeProjects: { edges: activeProjects = [] } = {} } = {} } = {},
  } = state;
  return activeProjects;
};

export const getMapCenter = (state) => {
  const { activeProjects } = state.settings;
  const centerLocation = {};

  // No selected projects - get mapCenter of user Company
  if (!activeProjects.length) {
    const userCompany = state.settings.user.company;
    if (userCompany) {
      const { latitude, longitude } = userCompany.dashboardLocation;
      centerLocation.lat = latitude;
      centerLocation.lng = longitude;
    } else {
      centerLocation.lat = 56.13573;
      centerLocation.lng = 8.96548;
    }
  }

  // Selected only 1 project - get mapCenter of the Project or the Company
  else if (activeProjects.length === 1) {
    const projectLocation = activeProjects[0].settings.dashboardLocation;
    const { latitude, longitude } = projectLocation || activeProjects[0].company.dashboardLocation;
    centerLocation.lat = latitude;
    centerLocation.lng = longitude;
  } else {
    const companyProjectsCount = {};

    // Count how many projects from each company are selected
    activeProjects.forEach((project) => {
      if (companyProjectsCount[project.company.id]) {
        companyProjectsCount[project.company.id] += 1;
      } else {
        companyProjectsCount[project.company.id] = 1;
      }
    });

    const companyIds = Object.keys(companyProjectsCount);

    // All projects are from the same company - get mapCenter of the Company
    if (companyIds.length === 1) {
      const { latitude, longitude } = activeProjects.find(
        (project) => project.company.id === companyIds[0],
      ).company.dashboardLocation;
      centerLocation.lat = latitude;
      centerLocation.lng = longitude;
    }

    // There's company with only 1 selected project - get mapCenter of the Project
    /* eslint-disable-next-line no-restricted-syntax */
    for (const key of companyIds) {
      if (companyProjectsCount[key] === 1) {
        const selectedProject = activeProjects.find((project) => project.company.id === key);
        const { latitude, longitude } = selectedProject.settings.dashboardLocation
          ? selectedProject.settings.dashboardLocation
          : selectedProject.company.dashboardLocation;
        centerLocation.lat = latitude;
        centerLocation.lng = longitude;
        break;
      }
    }

    // Get mapCenter of the first company
    const { latitude, longitude } = activeProjects.find(
      (project) => project.company.id === companyIds[0],
    ).company.dashboardLocation;
    centerLocation.lat = latitude;
    centerLocation.lng = longitude;
  }

  return centerLocation;
};

export const getSettingsForProject = (activeProject) => {
  const { settings: settingsConfig = {} } = activeProject || {};
  return new Settings(settingsConfig);
};

export const getUserSettings = (state) => {
  const activeProjects = getActiveProjects(state);

  return activeProjects.reduce(
    (acc, { node: activeProject }) => ({
      ...acc,
      [activeProject.id]: getSettingsForProject(activeProject),
    }),
    {},
  );
};

export const WH_ADMIN = 'wh-admin';
export const RESELLER = 'reseller';
export const MASTER = 'master';
export const SUPER = 'super';
export const USER = 'user';
export const DEMO = 'demo';

export const ACCOUNT_TYPES = {
  CUSTOMER: 'CUSTOMER',
  WASTE_HERO: 'WASTE_HERO',
  PARTNER: 'PARTNER',
  DEMO: 'DEMO',
};

export const allUserPermissions = [
  { value: WH_ADMIN, label: <FormattedMessage id="wh_admin" defaultMessage="WH-Admin" /> },
  { value: RESELLER, label: <FormattedMessage id="partner" defaultMessage="Partner" /> },
  { value: MASTER, label: <FormattedMessage id="admin" defaultMessage="Admin" /> },
  { value: SUPER, label: <FormattedMessage id="project_owner" defaultMessage="Project owner" /> },
  { value: USER, label: <FormattedMessage id="user" defaultMessage="User" /> },
  { value: DEMO, label: <FormattedMessage id="demo_user" defaultMessage="Demo User" /> },
];

export const allProjectLevelUserPermissions = [
  { value: MASTER, label: <FormattedMessage id="admin" defaultMessage="Admin" /> },
  { value: SUPER, label: <FormattedMessage id="project_owner" defaultMessage="Project owner" /> },
  { value: USER, label: <FormattedMessage id="user" defaultMessage="User" /> },
];

export const getUserPermissionForProject = (user, project = {}) => {
  if (user.isAdmin) {
    return WH_ADMIN;
  }
  if (user.isReseller) {
    return RESELLER;
  }
  if (user.isMaster) {
    return MASTER;
  }
  return project.userAccessLevel || USER;
};

export const getAvailableUserPermissions = (permission) => {
  switch (permission) {
    case WH_ADMIN:
      return [MASTER, SUPER, USER, RESELLER, DEMO, WH_ADMIN];
    case RESELLER:
      return [MASTER, SUPER, USER, RESELLER];
    case MASTER:
      return [MASTER, SUPER, USER];
    case SUPER:
      return [SUPER, USER];
    case USER:
      return [USER];
    default:
      return [USER];
  }
};

export const updateUserCache = (project) => async (
  cache,
  {
    data: {
      assignUser: { userProject },
    },
  },
) => {
  const { allCompanies: cachedCompanies } = cache.readQuery(allCompaniesQuery);
  const filteredEdges = cachedCompanies.edges.map((comp) => {
    const deepCopyCompany = JSON.parse(JSON.stringify(comp));
    const {
      node: { projectSet },
    } = comp;
    if (projectSet.edges) {
      const newDeepCopyCompanyData = {
        ...deepCopyCompany,
        node: {
          ...deepCopyCompany.node,
          projectSet: {
            ...deepCopyCompany.node.projectSet,
            edges: projectSet.edges.map(({ node: proj, ...otherProjectData }) => {
              if (proj.id === project) {
                const projectCopy = { ...proj };
                const newProjectCopyData = {
                  ...projectCopy,
                  userprojectSet: {
                    ...projectCopy.userprojectSet,
                    edges: [
                      ...proj.userprojectSet.edges,
                      { __typename: 'UserProjectTypeEdge', node: { ...userProject } },
                    ],
                  },
                };
                return { node: newProjectCopyData, ...otherProjectData };
              }
              return { node: proj, ...otherProjectData };
            }),
          },
        },
      };
      return newDeepCopyCompanyData;
    }
    return deepCopyCompany;
  });
  cache.writeQuery({
    ...allCompaniesQuery,
    data: { allCompanies: { ...cachedCompanies, edges: filteredEdges } },
  });
};

export const checkIfRegularUser = (user) => {
  if (user.isAdmin || user.isMaster || user.isReseller || user.isSuperuser) {
    return false;
  }
  return true;
};
