import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PERIOD_VALUES = [
  {
    value: 'day',
    label: <FormattedMessage id="period_values.day" defaultMessage="Day" />,
  },
  {
    value: 'week',
    label: <FormattedMessage id="period_values.week" defaultMessage="Week" />,
  },
  {
    value: 'month',
    label: <FormattedMessage id="period_values.month" defaultMessage="Month" />,
  },
  {
    value: 'year',
    label: <FormattedMessage id="period_values.year" defaultMessage="Year" />,
  },
];

export const getWeightCoef = (unit) => {
  switch (unit) {
    case 'KG':
      return 1;
    case 'T':
      return 0.001;
    case 'LBS':
      return 2.2046;
    default:
      return 1;
  }
};

export const getVolumeCoef = (unit) => {
  switch (unit) {
    case 'M3':
      return 1;
    case 'L':
      return 1000;
    case 'YD3':
      return 1.308;
    case 'FT3':
      return 28.316847;
    default:
      return 1;
  }
};

export const getWeightToVolumeCoef = (weightUnit, volumeUnit) => {
  const weightCoef = getWeightCoef(weightUnit);
  const volumeCoef = getVolumeCoef(volumeUnit);
  return weightCoef / volumeCoef;
};

export const getCostSettingsDescription = (className, settingsTab, comparisonTab) => {
  switch (settingsTab) {
    case 'costSettings': {
      return (
        <>
          <p className={className}>
            To calculate the cost efficiency and operational optimization of implementing the
            WasteHero solution, you’ll first need to enter the costs associated with your existing
            combinations. Combinations refer to a specific container type and the attached waste
            fraction (e.g. Underground 770L – Paper).
          </p>
          <p className={className}>
            The available combinations are based on the existing individual containers within your
            project.
          </p>
          <p className={className}>
            You can enter settings for container combinations with and without sensors. If your
            container is fitted with a sensor, the system will dynamically update the collection
            information. If you’ve got containers without sensors, simply add in the static schedule
            details.
          </p>
          <p className={className}>
            Once you’ve entered the settings for all combinations, switch to the{' '}
            <strong>Comparison Period</strong>
            tab. Here you can decide the method to compare your savings; through entering your own
            historical pre-sensor data, a custom time period, or a previous period
            (week/month/year).
          </p>
        </>
      );
    }
    case 'comparisonPeriod': {
      switch (comparisonTab) {
        case 'historical_data': {
          return (
            <>
              <p className={className}>
                Add in your pre-sensor data here. You’ll need to enter the costs and collection
                schedules that were associated with each combination type prior to implementation of
                the WasteHero solution.
              </p>
              <p className={className}>
                This comparison method analyzes your all-time savings after implementing sensors and
                is the best way to understand the benefit of the WasteHero solution.
              </p>
              <p className={className}>
                With this option, it’s also possible to switch easily between time periods straight
                from the analytics dashboard.
              </p>
            </>
          );
        }
        case 'custom_period': {
          return (
            <p className={className}>
              Choose a custom time period to compare. This method is best when you want to compare
              current performance to a specific period of time since you’ve implemented the
              WasteHero solution.
            </p>
          );
        }
        case 'previous_period': {
          return (
            <p className={className}>
              Compare your savings to any previous week, month or year. This method is best when you
              want to compare average performance over the previous week, month or year since
              implementing the WasteHero solution. With this option, it’s possible to switch easily
              between time periods straight from the analytics dashboard.
            </p>
          );
        }
        default: {
          return <></>;
        }
      }
    }
    default: {
      return <></>;
    }
  }
};
