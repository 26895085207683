import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import useStyles from '../../shared/styles';
import FaqContainer from '../../shared/faqContainer';
import SupportTextPage from '../../shared/supportTextPage';

const PlatformFleetManagement = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <SupportTextPage
        title={<FormattedMessage id="fleet_management_header" defaultMessage="Fleet Management" />}
        texts={[
          <FormattedMessage
            id="fleet_management_content_1"
            defaultMessage="Fleet management is the place where you’ll add and manage all information concerning the drivers, vehicles and locations associated with your project."
          />,
          <FormattedMessage
            id="fleet_management_content_2"
            defaultMessage="Many of the details you enter in fleet management will provide our engine with the data it needs to create necessary routing constraints, so be sure to add as much information as possible!"
          />,
          <FormattedMessage
            id="fleet_management_content_3"
            defaultMessage="For ease of set-up, WasteHero recommends you begin with adding your locations, then vehicle types and vehicles, and lastly invite and add your drivers. The reason for this is that drivers cannot complete the configuration of their settings without having locations and vehicles being set-up first."
          />,
        ]}
      />
      <SupportTextPage
        subTitle={<FormattedMessage id="fleet_management_subtitle_1" defaultMessage="Vehicles" />}
        texts={[
          <FormattedMessage
            id="fleet_management_content_4"
            defaultMessage="The vehicle section of fleet management is where you’ll add the details of the trucks used to collect waste in your project."
          />,
          <FormattedMessage
            id="fleet_management_content_5"
            defaultMessage="In vehicle types, you can create new vehicle types for your project. Think of vehicle types as a standard template for vehicles with common body, loading and mechanization features. The vehicle type can be used as a template to create individual vehicles of the same variety without having to retype all the details."
          />,
          <FormattedMessage
            id="fleet_management_content_6"
            defaultMessage="In vehicle overview, you can see a list of all the vehicles associated with your project and their current working status. Clicking a vehicle name shows you further details. Here is where you can add a new individual vehicle and choose which project and waste fractions it will be used for."
          />,
        ]}
      />
      <FaqContainer
        faqs={
          <FormattedMessage id="platform_fleet_management_vehicles_faqs" defaultMessage="FAQs" />
        }
        objects={[
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_question_1"
                defaultMessage="How can I create a new vehicle type?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_question_1_content_1"
                defaultMessage="Think of vehicle types as a standard template for vehicles with common body, loading and mechanization features. The vehicle type can be used as a template to create individual vehicles of the same variety without having to retype all the details."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_1_content_2"
                defaultMessage="Navigate to <bold>Fleet Management</bold> in the main menu and then <bold>Vehicles</bold> in the sub-menu underneath."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_question_1_content_3"
                defaultMessage="Click the <bold>Vehicle Type</bold> tab in the top right corner. Click <bold>Add Vehicle Type +</bold>"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_question_1_content_4"
                defaultMessage="Give your vehicle type a name. Keep the name of the vehicle type unique so that you can differentiate from other vehicle types easily."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_1_content_5"
                defaultMessage="Select the body type, loading location, lift mechanization and body mechanization from the corresponding drop-down menus."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_1_content_6"
                defaultMessage="Click <bold>Save</bold> to confirm your vehicle type."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_question_2"
                defaultMessage="How can I add a new vehicle to my project?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_question_2_content_1"
                defaultMessage="Note that before you can add a vehicle to your project, you’ll need to have set-up a vehicle type already."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_2"
                defaultMessage="Navigate to <bold>Fleet Management</bold> in the main menu and then <bold>Vehicles</bold> in the sub-menu underneath."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_3"
                defaultMessage="Click the <bold>Vehicle Overview</bold> tab. Click <bold>Add Vehicles +</bold>"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_4"
                defaultMessage="Provide details on the vehicle, including ID, year, make and model, licence plate, and the vehicle identification number (VIN). Add a picture of the vehicle if you wish."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_5"
                defaultMessage="Select the appropriate vehicle type from the list of vehicle types. If the vehicle doesn’t fit any of the pre-existing vehicle types, you’ll need to go back and create a new vehicle type to suit this individual vehicle. This is important because containers must be coupled with a vehicle that has the appropriate lifting mechanism."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_6"
                defaultMessage="Add the volume and weight capacity for each waste fraction the truck can collect. Add multiple waste fractions by clicking the <bold>+</bold> icon."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_7"
                defaultMessage="Enter the max payload and cargo volume for the vehicle, along with the fuel type and fuel tank capacity. Enter the fuel costs associated with the vehicle."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_8"
                defaultMessage="Next you’ll add the constraints for route settings. Choose the working status of the vehicle and then select the depots and waste stations the vehicle is connected to."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_9"
                defaultMessage="You may wish to connect drivers to the vehicle in this step, or you can attach the driver to the vehicle when setting up individual drivers."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_10"
                defaultMessage="Choose the available work days and work hours for the vehicle."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_2_content_11"
                defaultMessage="Click <bold>Save</bold>"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_question_3"
                defaultMessage="What is the difference between a vehicle and a vehicle type?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_question_3_content_1"
                defaultMessage="A vehicle on the platform represents an individual vehicle within the fleet."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_3_content_2"
                defaultMessage="A vehicle type can be thought of as a template for vehicles with common body, loading and mechanization features. The purpose of vehicle types is to speed up the set-up process. You will be able to create individual vehicles of the same variety without having to retype the common features."
              />,
              <FormattedMessage
                id="platform_fleet_management_question_3_content_3"
                defaultMessage="You will need to include a new vehicle type for every group of vehicles with different body, loading and mechanization features. This is important because containers must be coupled with a vehicle that has the appropriate lifting mechanism."
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_question_4"
                defaultMessage="How do I view the details of a specific vehicle?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_question_4_content_1"
                defaultMessage="Navigate to <bold>Fleet Management</bold> in the main menu and then <bold>Vehicles</bold> in the sub-menu underneath. Make sure you have the <bold>Vehicle Overview</bold> tab selected."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_question_4_content_2"
                defaultMessage="Click the name of the desired vehicle to view all the details."
              />,
            ],
          },
        ]}
      />
      <SupportTextPage
        subTitle={<FormattedMessage id="fleet_management_subtitle_2" defaultMessage="Locations" />}
        texts={[
          <FormattedMessage
            id="fleet_management_locations_content_1"
            defaultMessage="Locations is where you’ll add details on the depots, workshops and waste stations associated with your project. The information you add here will inform the scheduling of smart routes."
          />,
        ]}
      />
      <FaqContainer
        faqs={
          <FormattedMessage id="platform_fleet_management_vehicles_faqs" defaultMessage="FAQs" />
        }
        objects={[
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_locations_question_1"
                defaultMessage="How do I add a new location to my project?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_1"
                defaultMessage="Navigate to <bold>Fleet Management</bold> in the main menu and then <bold>Locations</bold> in the sub-menu underneath."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_2"
                defaultMessage="Click <bold>Add Location</bold>"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_3"
                defaultMessage="Give the location a name. In the location type dropdown, select between Depot, Waste Station or Workshop."
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_4"
                defaultMessage="A <bold>Depot</bold> is where waste collection vehicles are stored and will act as the start and finish point for routes."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_5"
                defaultMessage="A <bold>Waste Station</bold> is where the waste will be dropped off after collection. Your project may contain multiple waste stations that cater for different waste fractions."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_6"
                defaultMessage="A <bold>Workshop</bold> is any type of store where vehicles may receive repairs or routine maintenance/inspections."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_7"
                defaultMessage="Add details about the physical location, contact person, geofence radius, and opening hours. You can add a description of the location and upload a photo of the location. Depending on the location type, you will need to add further details:"
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_8"
                defaultMessage="For depots, add the amount of trucks that the depot has capacity for."
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_9"
                defaultMessage="For waste stations, add the waste fractions of your project that the station has the ability to handle. Add the time it takes to unload the truck and leave the facility."
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_10"
                defaultMessage="For workshops, add the type of service it provides and a description."
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_1_content_11"
                defaultMessage="When you have entered all details, click <bold>Save</bold>."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_locations_question_2"
                defaultMessage="What are the different types of locations I can add to my project?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_locations_question_2_content_1"
                defaultMessage="You can add depots, waste stations and workshops to your project."
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_2_content_2"
                defaultMessage="A <bold>Depot</bold> is where waste collection vehicles are stored and will act as the start and finish point for routes. When adding a depot to your project, you’ll have the option to add in the amount of trucks the depot has capacity for."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_2_content_3"
                defaultMessage="A <bold>Waste Station</bold> is where the waste will be dropped off after collection. If you collect different waste fractions that need to be sorted at multiple locations, this is where you’ll add details of the separate locations, telling our routing engine what can be dropped off where. You’ll need to have your project’s waste fraction settings configured before you can add a waste station."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_2_content_4"
                defaultMessage="A <bold>Workshop</bold> is any type of store where vehicles may receive repairs or routine maintenance/inspections."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_locations_question_3"
                defaultMessage="How do I edit location details?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_locations_question_3_content_1"
                defaultMessage="Navigate to <bold>Fleet Management</bold> in the main menu and then <bold>Locations</bold> in the sub-menu underneath."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_3_content_2"
                defaultMessage="Click the name of the location you wish to edit. This will take you to the Location Details."
              />,
              <FormattedMessage
                id="platform_fleet_management_locations_question_3_content_3"
                defaultMessage="Click <bold>Edit Settings</bold> button and make your chosen changes. Click Save to confirm."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
        ]}
      />
      <SupportTextPage
        subTitle={
          <FormattedMessage id="fleet_management_subtitle_3" defaultMessage="Driver Management" />
        }
        texts={[
          <FormattedMessage
            id="fleet_management_driver_content_1"
            defaultMessage="For admins and project owners, <bold>Driver Management</bold> is where you can invite and set-up new drivers, as well as get an overview of the drivers within your project and their availability."
            values={{ bold: (text) => <b>{text}</b> }}
          />,
          <FormattedMessage
            id="fleet_management_driver_content_2"
            defaultMessage="For drivers, this is where you can add in all of your details, including contact information, work schedule, salary, and start/end locations for your workday. Keep track of every detail in one place by also adding your skills and permissions."
          />,
          <FormattedMessage
            id="fleet_management_driver_content_1"
            defaultMessage="In order for drivers to be added successfully, admins must send invites to drivers and have them create a user account. Once the account is created, drivers can add their own details in <bold>Driver Settings</bold> or an admin can do so on their behalf. Note that an admin can only set-up a driver’s details once the driver has accepted the invitation and sets up a user account."
            values={{ bold: (text) => <b>{text}</b> }}
          />,
        ]}
      />
      <FaqContainer
        faqs={<FormattedMessage id="platform_fleet_management_driver_faqs" defaultMessage="FAQs" />}
        objects={[
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_driver_question_1"
                defaultMessage="How do I invite a new driver to my project?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_driver_question_1_content_1"
                defaultMessage="Navigate to <bold>Fleet Management</bold> in the main menu and then <bold>Driver Management</bold> in the sub-menu below."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_1_content_2"
                defaultMessage="In the right-hand corner, hit <bold>Invite Driver</bold>"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_1_content_3"
                defaultMessage="If you manage multiple projects, choose the specific project you want to invite the new driver to."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_1_content_4"
                defaultMessage="If you only manage one project, the driver will automatically be assigned to your project and you can move to the next step."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_1_content_5"
                defaultMessage="The account type and job title sections are prefilled to suit driver requirements and permission levels. You are unable to edit these options."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_1_content_6"
                defaultMessage="Add the email address of the driver you want to invite and click save. The invitation has been sent when you see the message <bold>Driver assigned to project</bold>"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_driver_question_2"
                defaultMessage="How do I accept my invite as a driver?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_driver_question_2_content_1"
                defaultMessage="So you’ve been notified that you’ve been sent an invitation to set up a driver profile on WasteHero’s platform… what’s next?"
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_2_content_2"
                defaultMessage="Check your inbox for an email from WasteHero. Click the link to accept your invitation and follow the prompts to set up your user account on the WasteHero platform."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_2_content_3"
                defaultMessage="From here, you can either set up your own driver settings or check with your project manager if they will do this for you."
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_fleet_management_driver_question_3"
                defaultMessage="How do I add a driver and configure their settings for a project?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_1"
                defaultMessage="Driver details can be added by the drivers themselves, or by an admin or project owner."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_2"
                defaultMessage="Admins and project owners will only be able to configure individual driver settings once the driver has accepted their invitation and created a user account."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_3"
                defaultMessage="To configure driver settings, navigate to <bold>Fleet Management</bold> in the main menu, then select <bold>Driver Management</bold> from the sub-menu."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_4"
                defaultMessage="Click on <bold>Driver Settings</bold>."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_5"
                defaultMessage="Select the desired driver from the <bold>Invited Drivers</bold> dropdown list."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_6"
                defaultMessage="From here, you can fill out contact details and upload a photo of yourself."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_7"
                defaultMessage="Select the vehicle the driver uses from the existing list of vehicles. Add the driver’s working schedule, salary, and start/end locations for their workday from the existing list of locations."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_8"
                defaultMessage="You can also add in details of driver skills and licences so you can keep track of every detail in one place."
              />,
              <FormattedMessage
                id="platform_fleet_management_driver_question_3_content_9"
                defaultMessage="Click <bold>Save</bold> to finalise the driver details."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default PlatformFleetManagement;
