import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import CustomCheckbox from '../inputs/StyledCheckbox';

import DarkGreyFilterIcon from '../../../images/icons/dakGreyFilter.svg';

const useStyles = makeStyles((theme) => ({
  optionsSelector: {
    backgroundColor: theme.variables.cAntiFlashWhite,
    border: 'none',
    borderRadius: 4,
    width: 32,
    padding: 0,
    justifyContent: 'center',
    '&>.MuiSelect-select': {
      padding: 0,
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&>.MuiSelect-icon': {
      display: 'none',
    },
  },
  menuItemText: {
    '&> .MuiTypography-root:first-letter': {
      textTransform: 'capitalize',
    },
  },
}));

const ColumnFilter = ({ tableColumns, handleFilterColumns, activeTableColumns }) => {
  const classes = useStyles();

  return (
    <Select
      displayEmpty
      multiple
      onClick={(event) => event.stopPropagation()}
      value={[]}
      className={classes.optionsSelector}
      renderValue={() => <img src={DarkGreyFilterIcon} alt="Filter" />}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }}
    >
      {tableColumns.map((column) => (
        <MenuItem
          key={column.value}
          value={column.value}
          onClick={() => handleFilterColumns(column.value)}
        >
          <CustomCheckbox
            color="primary"
            checked={activeTableColumns.some((tableColumn) => tableColumn === column.value)}
          />
          <ListItemText primary={column.label} className={classes.menuItemText} />
        </MenuItem>
      ))}
    </Select>
  );
};

ColumnFilter.propTypes = {
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  handleFilterColumns: PropTypes.func.isRequired,
  activeTableColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ColumnFilter;
