import {
  DEMO,
  MASTER,
  RESELLER,
  SUPER,
  USER,
  WH_ADMIN,
} from '../../../../../../shared/utils/settings';
import DemoUser from '../../../../../../../images/user_management/DemoUser.svg';
import partner from '../../../../../../../images/user_management/partner.svg';
import ProjectOwner from '../../../../../../../images/user_management/ProjectOwner.svg';
import WHAdmin from '../../../../../../../images/user_management/WHAdmin.svg';
import CustomerAdmin from '../../../../../../../images/user_management/CustomerAdmin.svg';
import User from '../../../../../../../images/user_management/User.svg';

const rolesInfo = (translate) => ({
  [WH_ADMIN]: {
    picture: WHAdmin,
    description: translate({
      id: `${WH_ADMIN}.description`,
      defaultMessage: `This account is only meant for people working in WasteHero.
         This account type will be used by the sales department to create
         new accounts and technical support to help setup settings. `,
    }),
    permissions: translate({
      id: `${WH_ADMIN}.permissions`,
      defaultMessage: `Able to view, edit and create settings for all accounts
          and access all collected data`,
    }),
  },
  [RESELLER]: {
    picture: partner,
    description: translate({
      id: `${RESELLER}.description`,
      defaultMessage: `The account is meant for partners and resellers and therefor
       they should almost have same permissions and rights as the WH-Admin account.
       The only different between WH-admin and Partner is that WH-Admin can view
       all companies/projects from all accounts, while Partner only can view
       projects that they have created.`,
    }),
    permissions: translate({
      id: `${RESELLER}.permissions`,
      defaultMessage: `Able to view, edit and create settings for all accounts that
        have been created through this Admin account, also able to access
        all collected data from account created through it as well.`,
    }),
  },
  [MASTER]: {
    picture: CustomerAdmin,
    description: translate({
      id: `${MASTER}.description`,
      defaultMessage: `This account type will be the first a customer receive after
        payment have started. This account determine company settings and have
         permission to set up the entire platform. This is the highest level of
          permission a customer can receive. From this account they can invite
          more "Admin" accounts or "Project owner" as well as regular "User".`,
    }),
    permissions: translate({
      id: `${MASTER}.permissions`,
      defaultMessage: `Able to view, edit and create new projects and settings
             for the entire organization`,
    }),
  },
  [SUPER]: {
    picture: ProjectOwner,
    description: translate({
      id: `${SUPER}.description`,
      defaultMessage: `This account can be used to manage projects and define
    settings such as container types, waste fractions and measurement methods.
     Project owner can invite other "Project owner" and regular "User"`,
    }),
    permissions: translate({
      id: `${SUPER}.permissions`,
      defaultMessage: `Able to create, edit and view project settings to the
     given projects the account is assigned to`,
    }),
  },
  [USER]: {
    picture: User,
    description: translate({
      id: `${USER}.description`,
      defaultMessage: `The user account can register new container with the
    predefined settings; container types, waste fractions and measurement methods
     from either the admin or project owner account. This will be the most common
     account type. Regular user are not allow create or edit project settings,
      such as container types.`,
    }),
    permissions: translate({
      id: `${USER}.permissions`,
      defaultMessage: 'Able to view and use defined settings',
    }),
  },
  [DEMO]: {
    picture: DemoUser,
    description: translate({
      id: `${DEMO}.description`,
      defaultMessage: `The demo account shows primary features and benefits
    of utilizing WasteHero. The account is equipped with predefined data. `,
    }),
    permissions: translate({
      id: `${DEMO}.permissions`,
      defaultMessage: 'Able to view and edit current data input.',
    }),
  },
});

export default rolesInfo;
