import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import { Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { loader } from 'graphql.macro';
import UserLogo from '../../../../../layout/header/userLogo';
import AvatarIcon from '../../../../../../images/icons/myAccount.svg';
import PencilIcon from '../../../../../../images/icons/pencil.svg';
import DeleteIcon from '../../../../../../images/icons/delete.svg';
import EditIcon from '../../../../../../images/icons/edit.svg';
import { toastifyError } from '../../../../../shared/utils';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';

const resendInvitationMutation = loader(
  './../../../../../graphql/mutations/core/resend_invitation.graphql',
);

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  saveButton: {
    marginTop: '15px',
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(3, 0, 3),
  },
  centredLabel: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 5px 0 5px',
    height: '100%',
    '&>p': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&>span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  textToCenter: {
    '&>p': {
      textAlign: 'center',
    },
    '&>span': {
      textAlign: 'center',
    },
  },
  resendButton: {
    textTransform: 'none',
    marginLeft: 10,
    color: theme.variables.cRichOcean,
    fontSize: 14,
    fontWeight: 'bold',
    borderRadius: 4,
    backgroundColor: theme.variables.cAntiFlashWhite,
    padding: '3px 20px',
  },
  menuIcon: {
    marginRight: 10,
  },
  editButton: {
    width: 12,
    borderRadius: 4,
  },
  linkMenuItem: {
    textDecoration: 'none',
    color: theme.variables.cRichOcean,
  },
  linkListItem: {
    borderRadius: 4,
  },
  userPosition: {
    backgroundColor: theme.variables.cAntiFlashWhite,
    color: theme.variables.VHLightBlue,
    borderRadius: 16,
    verticalAlign: 'middle',
    lineHeight: '28px',
    fontWeight: 500,
    textAlign: 'center',
  },
  listItem: {
    height: 70,
    marginBottom: 15,
  },
  status: {
    fontWeight: 500,
    fontSize: 14,
  },
  avatar: {
    height: 50,
    width: 50,
    marginRight: 15,
    backgroundColor: 'inherit',
  },
  avatarBorder: {
    border: `1px solid ${theme.variables.VHOrange}`,
  },
  statusPending: {
    color: theme.variables.cLightRed,
  },
  statusAccepted: {
    color: theme.variables.cGreen,
  },
  listDivider: {
    width: '75%',
    margin: '5px auto 0',
  },
  expand: {
    padding: 0,
    color: theme.variables.cDarkBlue,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    padding: 0,
    transform: 'rotate(180deg)',
  },
  mainLitItem: {
    padding: '0 8px',
  },
  projectLogo: {
    display: 'flex',
    justifyContent: 'center',
    '&>img': {
      height: 40,
    },
  },
  tooltipedJobTitle: {
    paddingLeft: '15px',
  },
}));

const UserList = ({ usersList, project, removeFromProject, deleteHandle, me }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [activeUserProject, setActiveUserProject] = useState(null);
  const [usersInvited, setUsersInvited] = useState([]);
  const [resendInvitation] = useMutation(resendInvitationMutation);

  const changeAnchorEl = (event = {}, userProject) => {
    setMenuAnchorEl(event.currentTarget || null);
    setActiveUserProject(userProject || null);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleResendInvitation = (e, userProject) => {
    resendInvitation({
      variables: {
        userProjectId: userProject.id,
      },
    })
      .then(() => {
        toast.info(`Resend invitation email to ${userProject.user.email}`);
        setUsersInvited([...usersInvited, userProject.id]);
      })
      .catch(toastifyError);
  };

  const getUserLevel = ({ isSuper, user: { isMaster, isAdmin } = {} } = {}) => {
    if (isMaster) {
      return 'Admin';
    }
    if (isAdmin) {
      return 'Admin';
    }
    if (isSuper) {
      return 'Project Owner';
    }
    return 'Regular User';
  };

  const actionAllowed = (user, targetUserProject) => {
    const targetUserProjectExists = targetUserProject && targetUserProject.id;
    // Allow edit if target user is user
    const selfEdit =
      targetUserProjectExists &&
      user.id === targetUserProject.user.id &&
      getUserLevel(targetUserProject) !== 'Regular User';

    // Allow edit if user is reseller and target is not
    const companyReseller =
      targetUserProjectExists &&
      user.id &&
      (user.isReseller || user.isAdmin) &&
      !targetUserProject.user.isMaster &&
      !targetUserProject.user.isAdmin;

    // Allow edit if user is master and target is not
    const companyMaster =
      targetUserProjectExists &&
      user.id &&
      (user.isMaster || user.isAdmin) &&
      !targetUserProject.user.isAdmin;

    // Allow edit if user is projectOwner and target is not
    const projectOwner =
      targetUserProjectExists &&
      project.userAccessLevel === 'super' &&
      !targetUserProject.user.isMaster &&
      !targetUserProject.user.isAdmin &&
      !targetUserProject.isSuper;

    // Allow admin edit everyone except another admins
    const admin = targetUserProjectExists && user.isAdmin;

    return selfEdit || companyMaster || projectOwner || admin || companyReseller;
  };

  const invitationButton = (userProject) => {
    if (usersInvited.length && usersInvited.indexOf(userProject.id) !== -1) {
      return (
        <Button className={classNames(classes.resendButton)} disabled>
          <FormattedMessage id="invitation_sent" defaultMessage="Invitation sent" />
        </Button>
      );
    }

    return (
      <Button
        className={classNames(classes.resendButton)}
        onClick={(e) => handleResendInvitation(e, userProject)}
      >
        <FormattedMessage id="resend_invitation" defaultMessage="Resend Invitation" />
      </Button>
    );
  };

  return (
    <List style={{ padding: 0 }}>
      <ListItem button onClick={handleClick} className={classes.mainLitItem}>
        <Grid item xs={4} sm={2}>
          <div className={classes.projectLogo}>
            {project.logo ? (
              <img src={project.logo} alt={project.name} />
            ) : (
              <Avatar>
                {project.name
                  .split(' ')
                  .map((item) => item.slice(0, 1))
                  .join('')
                  .slice(0, 2)
                  .toUpperCase()}
              </Avatar>
            )}
          </div>
        </Grid>
        <Grid item xs={6} sm={8}>
          <Typography variant="h6" className={classes.title}>
            {project.name}
          </Typography>
        </Grid>
        <Grid container item xs={2} justify="flex-end" alignItems="center">
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: open,
            })}
            onClick={handleClick}
            aria-expanded={open}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </ListItem>
      <Grid container justify="center">
        <Grid item xs={10} className={classes.list}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              {!usersList.length && (
                <ListItem>
                  <ListItemText
                    classes={{ root: classes.centredLabel }}
                    primary={intl.formatMessage({ id: 'no_data', defaultMessage: 'No data' })}
                  />
                </ListItem>
              )}
              {usersList.map(({ node: userProject }) => {
                const titleStyle = userProject.user.jobtitle.pendingTitle
                  ? classes.tooltipedJobTitle
                  : null;
                const jobTitleInfo =
                  userProject.user.jobtitle.title && userProject.user.jobtitle.title !== '-' ? (
                    <div>
                      {userProject.user.jobtitle.pendingTitle && (
                        <TooltippedUserControl
                          tooltipStyle="paddingLeft: 25px;"
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.jobTitlePending"
                              defaultMessage="User is waiting for approval of new job title"
                            />
                          }
                        />
                      )}
                      <div className={titleStyle}>{userProject.user.jobtitle.title}</div>
                    </div>
                  ) : (
                    intl.formatMessage({ id: 'no_data', defaultMessage: 'No data' })
                  );
                return (
                  <ListItem key={userProject.user.id} classes={{ root: classes.listItem }}>
                    <ListItemAvatar>
                      <Avatar
                        classes={{
                          root: classNames(classes.avatar, {
                            [classes.avatarBorder]: !userProject.user.logo,
                          }),
                        }}
                      >
                        {userProject.user.logo ? (
                          <UserLogo logoUrl={userProject.user.logo} />
                        ) : (
                          <img
                            src={AvatarIcon}
                            className="h-100 w-100"
                            alt={userProject.user.firstName}
                          />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ root: classNames(classes.listText, 'w-20') }}
                      primary={
                        userProject.user.firstName || userProject.user.lastName
                          ? `${userProject.user.firstName} ${userProject.user.lastName}`
                          : intl.formatMessage({ id: 'no_data', defaultMessage: 'No data' })
                      }
                      secondary={jobTitleInfo}
                      secondaryTypographyProps={{
                        component: 'div',
                      }}
                    />
                    <ListItemText
                      classes={{
                        root: classNames(classes.listText, 'w-20'),
                      }}
                      primary={
                        `${userProject.user.email}` ||
                        intl.formatMessage({
                          id: 'no_data',
                          defaultMessage: 'No data',
                        })
                      }
                      secondary={
                        <>
                          <span
                            className={classNames(classes.status, {
                              [classes.statusAccepted]: userProject.user.isActive,
                              [classes.statusPending]: !userProject.user.isActive,
                            })}
                          >
                            {userProject.user.isActive
                              ? intl.formatMessage({ id: 'accepted', defaultMessage: 'Accepted' })
                              : intl.formatMessage({ id: 'pending', defaultMessage: 'Pending' })}
                          </span>
                          {!userProject.user.isActive ? invitationButton(userProject) : null}
                        </>
                      }
                    />
                    <ListItemText
                      classes={{ root: classNames(classes.listText, classes.textToCenter, 'w-10') }}
                      primary={`${userProject.user.phoneNumber}` || ''}
                    />
                    <ListItemText
                      classes={{
                        root: classNames(classes.listText, 'w-10'),
                        primary: classes.userPosition,
                      }}
                      primary={
                        `${getUserLevel(userProject)}` ||
                        intl.formatMessage({
                          id: 'no_data',
                          defaultMessage: 'No data',
                        })
                      }
                    />
                    <ListItemSecondaryAction>
                      {actionAllowed(me, userProject) && (
                        <IconButton
                          className={classNames('p-15', classes.editButton)}
                          edge="end"
                          aria-label="Edit"
                          aria-owns={menuAnchorEl ? 'simple-menu' : null}
                          aria-haspopup="true"
                          title={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                          onClick={(event) => changeAnchorEl(event, userProject)}
                        >
                          <img
                            src={EditIcon}
                            alt={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                          />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              <Divider className={classes.listDivider} />
              <Link
                to={`/app/settings/${project.id}/project-overview`}
                className={classNames(classes.linkMenuItem)}
              >
                <ListItem button className={classes.linkListItem}>
                  <ListItemText
                    classes={{ root: classes.centredLabel }}
                    primary={<AddIcon size="large" />}
                    secondary={intl.formatMessage({
                      id: 'add_new_user',
                      defaultMessage: 'Add new user',
                    })}
                  />
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => changeAnchorEl()}
          >
            {actionAllowed(me, activeUserProject) && (
              <Link
                to={`/app/user-management/account-settings/${project.id}/${
                  activeUserProject && activeUserProject.user.id
                }`}
                className={classNames(classes.linkMenuItem)}
              >
                <MenuItem>
                  <img
                    src={PencilIcon}
                    className={classes.menuIcon}
                    alt={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                  />
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </MenuItem>
              </Link>
            )}
            {actionAllowed(me, activeUserProject) && (
              <MenuItem
                onClick={() => {
                  changeAnchorEl();
                  removeFromProject(activeUserProject);
                }}
              >
                <img
                  src={DeleteIcon}
                  className={classes.menuIcon}
                  alt={intl.formatMessage({
                    id: 'remove_from_project',
                    defaultMessage: 'Remove from project',
                  })}
                />
                <FormattedMessage id="remove_from_project" defaultMessage="Remove from project" />
              </MenuItem>
            )}
            {actionAllowed(me, activeUserProject) && (
              <MenuItem
                onClick={() => {
                  changeAnchorEl();
                  deleteHandle(activeUserProject);
                }}
              >
                <img
                  src={DeleteIcon}
                  className={classes.menuIcon}
                  alt={intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
                />
                <FormattedMessage id="delete" defaultMessage="Delete" />
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
    </List>
  );
};

UserList.propTypes = {
  deleteHandle: PropTypes.func.isRequired,
  removeFromProject: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userAccessLevel: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }).isRequired,
  me: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isMaster: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }).isRequired,
  usersList: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isSuper: PropTypes.bool.isRequired,
        user: PropTypes.shape({
          id: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          isMaster: PropTypes.bool.isRequired,
          isAdmin: PropTypes.bool.isRequired,
          jobtitle: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            pendingTitle: PropTypes.string,
          }),
        }).isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(UserList);
