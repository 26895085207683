import React from 'react';
import { FormattedMessage } from 'react-intl';

export const wasteCategoryOptions = [
  {
    value: 'Biological_treatment',
    label: <FormattedMessage id="biologicalTreatment" defaultMessage="Biological treatment" />,
  },
  {
    value: 'Chemical_treatment',
    label: <FormattedMessage id="chemicalTreatment" defaultMessage="Chemical treatment" />,
  },
  {
    value: 'Physical_treatment',
    label: <FormattedMessage id="physicalTreatment" defaultMessage="Physical treatment" />,
  },
  { value: 'Combustion', label: <FormattedMessage id="combustion" defaultMessage="Combustion" /> },
  { value: 'Recycling', label: <FormattedMessage id="recycling" defaultMessage="Recycling" /> },
  { value: 'Reusable', label: <FormattedMessage id="reusable" defaultMessage="Reusable" /> },
  { value: 'Landfill', label: <FormattedMessage id="landfill" defaultMessage="Landfill" /> },
  { value: 'Special', label: <FormattedMessage id="special" defaultMessage="Special" /> },
  { value: 'Other', label: <FormattedMessage id="other" defaultMessage="Other" /> },
];

export const CUSTOM_EMPTYING_METHOD = 'Customize';

export const emptyingMethodChoices = [
  {
    value: 2,
    label: <FormattedMessage id="emptyingMethod.surface" defaultMessage="Surface 2 min" />,
  },
  {
    value: 15,
    label: <FormattedMessage id="emptyingMethod.underground" defaultMessage="Underground 15 min" />,
  },
  {
    value: CUSTOM_EMPTYING_METHOD,
    label: <FormattedMessage id="emptyingMethod.customize" defaultMessage="Customize" />,
  },
];

export const containerAccessChoices = [
  {
    value: 'Entrie container or compact',
    label: (
      <FormattedMessage id="containerAccess.entrie" defaultMessage="Entrie container or compact" />
    ),
  },
  {
    value: 'Open and elevating platforms',
    label: (
      <FormattedMessage id="containerAccess.open" defaultMessage="Open and elevating platforms" />
    ),
  },
  {
    value: 'Only opening platform/lid',
    label: (
      <FormattedMessage
        id="containerAccess.opening_platform"
        defaultMessage="Only opening platform/lid"
      />
    ),
  },
  {
    value: 'Only elevating platform',
    label: (
      <FormattedMessage
        id="containerAccess.elevating_platform"
        defaultMessage="Only elevating platform"
      />
    ),
  },
];

export const measuringDistanceChoices = [
  { value: 'MM', label: <FormattedMessage id="MM" defaultMessage="MM" /> },
  { value: 'CM', label: <FormattedMessage id="CM" defaultMessage="CM" /> },
  { value: 'M', label: <FormattedMessage id="M" defaultMessage="M" /> },
  { value: 'IN', label: <FormattedMessage id="IN" defaultMessage="IN" /> },
  { value: 'FT', label: <FormattedMessage id="FT" defaultMessage="FT" /> },
  { value: 'YD', label: <FormattedMessage id="YD" defaultMessage="YD" /> },
];

export const drivingDistanceChoices = [
  { value: 'M', label: <FormattedMessage id="M" defaultMessage="M" /> },
  { value: 'KM', label: <FormattedMessage id="KM" defaultMessage="KM" /> },
  { value: 'MI', label: <FormattedMessage id="MI" defaultMessage="MI" /> },
  { value: 'FT', label: <FormattedMessage id="FT" defaultMessage="FT" /> },
  { value: 'YD', label: <FormattedMessage id="YD" defaultMessage="YD" /> },
];

export const weightChoices = [
  { value: 'KG', label: <FormattedMessage id="KG" defaultMessage="KG" /> },
  { value: 'T', label: <FormattedMessage id="T" defaultMessage="T" /> },
  { value: 'LBS', label: <FormattedMessage id="LBS" defaultMessage="LBS" /> },
];

export const volumeChoices = [
  { value: 'M3', label: <FormattedMessage id="M3" defaultMessage="M3" /> },
  { value: 'L', label: <FormattedMessage id="L" defaultMessage="L" /> },
  { value: 'YD3', label: <FormattedMessage id="YD3" defaultMessage="YD3" /> },
  { value: 'FT3', label: <FormattedMessage id="FT3" defaultMessage="FT3" /> },
];

export const temperatureChoices = [
  { value: '°C', label: <FormattedMessage id="°C" defaultMessage="°C" /> },
  { value: '°F', label: <FormattedMessage id="°F" defaultMessage="°F" /> },
];

export const volumeSizeOptions = [
  {
    label: '120 L',
    value: '120 L',
    params: {
      volume: 0.12,
      length: 0.54,
      width: 0.48,
      height: 0.94,
    },
  },
  {
    label: '140 L',
    value: '140 L',
    params: {
      volume: 0.14,
      length: 0.54,
      width: 0.48,
      height: 1.08,
    },
  },
  {
    label: '240 L',
    value: '240 L',
    params: {
      volume: 0.24,
      length: 0.7,
      width: 0.58,
      height: 1.05,
    },
  },
  {
    label: '360 L',
    value: '360 L',
    params: {
      volume: 0.36,
      length: 0.86,
      width: 0.62,
      height: 1.12,
    },
  },
  {
    label: '400 L',
    value: '400 L',
    params: {
      volume: 0.4,
      length: 0.74,
      width: 0.98,
      height: 1.13,
    },
  },
  {
    label: '660 L',
    value: '660 L',
    params: {
      volume: 0.66,
      length: 0.78,
      width: 1.26,
      height: 1.19,
    },
  },
  {
    label: '770 L',
    value: '770 L',
    params: {
      volume: 0.77,
      length: 0.78,
      width: 1.26,
      height: 1.34,
    },
  },
  {
    label: '1000 L (cylindrical)',
    value: '1000 L (cylindrical)',
    params: {
      volume: 1.0,
      diameter: 1.0,
      height: 1.0,
      isCylindrical: true,
    },
  },
  {
    label: '1100 L',
    value: '1100 L',
    params: {
      volume: 1.1,
      length: 1.08,
      width: 1.25,
      height: 1.33,
    },
  },
  {
    label: '4 m³',
    value: '4 m3',
    params: {
      volume: 4.0,
      length: 2.0,
      width: 2.05,
      height: 1.65,
    },
  },
  {
    label: '6 m³',
    value: '6 m3',
    params: {
      volume: 6.0,
      length: 2.38,
      width: 2.05,
      height: 1.9,
    },
  },
  {
    label: '8 m³',
    value: '8 m3',
    params: {
      volume: 8.0,
      length: 2.53,
      width: 2.05,
      height: 2.25,
    },
  },
  {
    label: '10 m³',
    value: '10 m3',
    params: {
      volume: 10.0,
      length: 4.3,
      width: 2.0,
      height: 2.0,
    },
  },
  {
    label: '12 m³',
    value: '12 m3',
    params: {
      volume: 12.0,
      length: 5.0,
      width: 2.0,
      height: 2.0,
    },
  },
  {
    label: '15 m³',
    value: '15 m3',
    params: {
      volume: 15.0,
      length: 4.5,
      width: 2.25,
      height: 2.23,
    },
  },
  {
    label: '20 m³',
    value: '20 m3',
    params: {
      volume: 20.0,
      length: 6.0,
      width: 2.3,
      height: 2.35,
    },
  },
  {
    label: '28 m³',
    value: '28 m3',
    params: {
      volume: 28.0,
      length: 6.0,
      width: 2.5,
      height: 2.65,
    },
  },
  {
    label: '30 m³',
    value: '30 m3',
    params: {
      volume: 30.0,
      length: 6.8,
      width: 2.5,
      height: 2.23,
    },
  },
  {
    label: '36 m³',
    value: '36 m3',
    params: {
      volume: 36,
      length: 6.8,
      width: 2.52,
      height: 2.65,
    },
  },
  { label: <FormattedMessage id="customize" defaultMessage="Customize" />, value: 'Customize' },
];

export const emplacementChoices = [
  { value: 'Surface', label: <FormattedMessage id="surface" defaultMessage="Surface" /> },
  {
    value: 'Semi-underground',
    label: <FormattedMessage id="semi_underground" defaultMessage="Semi-underground" />,
  },
  {
    value: 'Underground',
    label: <FormattedMessage id="underground" defaultMessage="Underground" />,
  },
];

export const mobilityChoices = [
  {
    value: 'With wheels',
    label: <FormattedMessage id="with_wheels" defaultMessage="With wheels" />,
  },
  {
    value: 'Without wheels',
    label: <FormattedMessage id="without_wheels" defaultMessage="Without wheels" />,
  },
];

export const vehicleCouplingChoices = [
  { value: 'Without', label: <FormattedMessage id="Without" defaultMessage="Without" /> },
  {
    value: 'Crane one ring',
    label: <FormattedMessage id="crane_one_ring" defaultMessage="Crane one ring" />,
  },
  {
    value: 'Crane double ring',
    label: <FormattedMessage id="crane_double_ring" defaultMessage="Crane double ring" />,
  },
  {
    value: 'Crane mushroom',
    label: <FormattedMessage id="srane_mushroom" defaultMessage="Crane mushroom" />,
  },
  {
    value: 'Front side lift',
    label: <FormattedMessage id="front_side_lift" defaultMessage="Front side lift" />,
  },
  { value: 'Side lift', label: <FormattedMessage id="side_lift" defaultMessage="Side lift" /> },
];

export const timezones = [
  { value: 'Africa/Abidjan', label: '(GMT -12:00) Eniwetok, Kwajalein' },
  { value: 'Pacific/Samoa', label: '(GMT -11:00) Midway Island, Samoa' },
  { value: 'US/Hawaii', label: '(GMT -10:00) Hawaii' },
  { value: 'Pacific/Marquesas', label: '(GMT -09:30) Taiohae' },
  { value: 'US/Alaska', label: '(GMT -09:00) Alaska' },
  { value: 'Canada/Pacific', label: '(GMT -08:00) Pacific Time, USA, Canada' },
  { value: 'Canada/Mountain', label: '(GMT -07:00) Mountain Time, USA, Canada' },
  { value: 'America/Mexico_City', label: '(GMT -06:00) Central Time, USA, Canada, Mexico City' },
  { value: 'America/Bogota', label: '(GMT -05:00) Eastern Time, USA, Canada, Bogota, Lima' },
  { value: 'America/Caracas', label: '(GMT -04:00) Atlantic Time (Canada), Caracas, La Paz' },
  { value: 'Canada/Newfoundland', label: '(GMT -03:30) Newfoundland' },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: '(GMT -03:00) Brazil, Buenos Aires, Georgetown',
  },
  { value: 'America/Noronha', label: '(GMT -02:00) Mid-Atlantic' },
  { value: 'Atlantic/Azores', label: '(GMT -01:00) Azores, Cape Verde Islands' },
  { value: 'Europe/London', label: '(GMT +00:00) Western Europe Time, London, Lisbon' },
  { value: 'Europe/Paris', label: '(GMT +01:00) Copenhagen, Paris, Madrid, Brussels' },
  { value: 'Europe/Istanbul', label: '(GMT +02:00) Istanbul, Jerusalem' },
  { value: 'Europe/Moscow', label: '(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
  { value: 'Asia/Tehran', label: '(GMT +03:30) Tehran' },
  { value: 'Asia/Muscat', label: '(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
  { value: 'Asia/Kabul', label: '(GMT +04:30) Kabul' },
  { value: 'Asia/Karachi', label: '(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
  { value: 'Asia/Calcutta', label: '(GMT +05:30) Bombay, Calcutta, Madras, New Delhi' },
  { value: 'Asia/Almaty', label: '(GMT +06:00) Almaty, Dhaka, Colombo' },
  { value: 'Asia/Rangoon', label: '(GMT +06:30) Yangon, Mandalay' },
  { value: 'Asia/Bangkok', label: '(GMT +07:00) Bangkok, Hanoi, Jakarta' },
  { value: 'Asia/Singapore', label: '(GMT +08:00) Beijing, Perth, Singapore, Hong Kong' },
  { value: 'Asia/Yakutsk', label: '(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
  { value: 'Australia/Darwin', label: '(GMT +09:30) Adelaide, Darwin' },
  { value: 'Asia/Vladivostok', label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
  { value: 'Australia/Lord_Howe', label: '(GMT +10:30) Lord Howe Island' },
  { value: 'Asia/Magadan', label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
  { value: 'Pacific/Norfolk', label: '(GMT +11:30) Norfolk Island' },
  { value: 'Pacific/Fiji', label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
  { value: 'Pacific/Chatham', label: '(GMT +12:45) Chatham Islands' },
  { value: 'Pacific/Apia', label: '(GMT +13:00) Apia, Nukualofa' },
  { value: 'Pacific/Tongatapu', label: '(GMT +14:00) Line Islands, Tokelau' },
];

export const clockFormatList = [
  { value: '12', label: '12-hour clock (PM/AM)' },
  { value: '24', label: '24-hour clock' },
];

export const measurementsPerHourOptions = [
  { value: '1', label: '1 (XX:00)' },
  { value: '2', label: '2 (XX:00, XX:30)' },
];

export const daysOptions = [
  { value: 'Monday', label: <FormattedMessage key="monday" id="monday" defaultMessage="Monday" /> },
  {
    value: 'Tuesday',
    label: <FormattedMessage key="tuesday" id="tuesday" defaultMessage="Tuesday" />,
  },
  {
    value: 'Wednesday',
    label: <FormattedMessage key="wednesday" id="wednesday" defaultMessage="Wednesday" />,
  },
  {
    value: 'Thursday',
    label: <FormattedMessage key="thursday" id="thursday" defaultMessage="Thursday" />,
  },
  { value: 'Friday', label: <FormattedMessage key="friday" id="friday" defaultMessage="Friday" /> },
  {
    value: 'Saturday',
    label: <FormattedMessage key="saturday" id="saturday" defaultMessage="Saturday" />,
  },
  { value: 'Sunday', label: <FormattedMessage key="sunday" id="sunday" defaultMessage="Sunday" /> },
];

export const measurementDefaultValues = [
  {
    value: 'Two measurements – low traffic',
    label: (
      <FormattedMessage
        id="measurementDefault.two"
        defaultMessage="Two measurements – low traffic"
      />
    ),
    params: {
      measurementTypeName: 'Two measurements',
      hours: [6, 18],
      measureAt: '00',
      measurementsPerHour: '1',
      excludeDays: [],
      excludePeriodStart: null,
      excludePeriodEnd: null,
      excludePeriodEveryYear: null,
    },
  },
  {
    value: 'Four measurements – normal traffic',
    label: (
      <FormattedMessage
        id="measurementDefault.four"
        defaultMessage="Four measurements – normal traffic"
      />
    ),
    params: {
      measurementTypeName: 'Four measurements',
      hours: [6, 10, 14, 18],
      measureAt: '00',
      measurementsPerHour: '1',
      excludeDays: [],
      excludePeriodStart: null,
      excludePeriodEnd: null,
      excludePeriodEveryYear: null,
    },
  },
];

export const currencyList = [
  { value: 'DKK', label: 'Danish krone (DKK)' },
  { value: 'USD', symbol: '$', label: 'US dollar (USD)' },
  { value: 'EUR', symbol: '€', label: 'Euro (EUR)' },
  { value: 'AED', label: 'United Arab Emirates dirham (AED)' },
  { value: 'AFN', label: 'Afghan afghani (AFN)' },
  { value: 'ALL', label: 'Albanian lek (ALL)' },
  { value: 'AMD', label: 'Armenian dram (AMD)' },
  { value: 'ANG', label: 'Netherlands Antillean guilder (ANG)' },
  { value: 'AOA', label: 'Angolan kwanza (AOA)' },
  { value: 'ARS', label: 'Argentine peso (ARS)' },
  { value: 'AUD', label: 'Australian dollar (AUD)' },
  { value: 'AWG', label: 'Aruban florin (AWG)' },
  { value: 'AZN', label: 'Azerbaijani manat (AZN)' },
  { value: 'BAM', label: 'Bosnia and Herzegovina convertible mark (BAM)' },
  { value: 'BBD', label: 'Barbadian dollar (BBD)' },
  { value: 'BDT', label: 'Bangladeshi taka (BDT)' },
  { value: 'BGN', label: 'Bulgarian lev (BGN)' },
  { value: 'BHD', label: 'Bahraini dinar (BHD)' },
  { value: 'BIF', label: 'Burundian franc (BIF)' },
  { value: 'BMD', label: 'Bermudian dollar (BMD)' },
  { value: 'BND', label: 'Brunei dollar (BND)' },
  { value: 'BOB', label: 'Bolivian boliviano (BOB)' },
  { value: 'BRL', label: 'Brazilian real (BRL)' },
  { value: 'BSD', label: 'Bahamian dollar (BSD)' },
  { value: 'BTN', label: 'Bhutanese ngultrum (BTN)' },
  { value: 'BWP', label: 'Botswana pula (BWP)' },
  { value: 'BYN', label: 'Belarusian ruble (BYN)' },
  { value: 'BZD', label: 'Belize dollar (BZD)' },
  { value: 'CAD', label: 'Canadian dollar (CAD)' },
  { value: 'CDF', label: 'Congolese franc (CDF)' },
  { value: 'CHF', label: 'Swiss franc (CHF)' },
  { value: 'CLP', label: 'Chilean peso (CLP)' },
  { value: 'CNY', symbol: '¥', label: 'Chinese yuan (CNY)' },
  { value: 'COP', label: 'Colombian peso (COP)' },
  { value: 'CRC', label: 'Costa Rican colón (CRC)' },
  { value: 'CUC', label: 'Cuban convertible peso (CUC)' },
  { value: 'CUP', label: 'Cuban peso (CUP)' },
  { value: 'CVE', label: 'Cape Verdean escudo (CVE)' },
  { value: 'CZK', label: 'Czech koruna (CZK)' },
  { value: 'DJF', label: 'Djiboutian franc (DJF)' },
  { value: 'DOP', label: 'Dominican peso (DOP)' },
  { value: 'DZD', label: 'Algerian dinar (DZD)' },
  { value: 'EGP', label: 'Egyptian pound (EGP)' },
  { value: 'ERN', label: 'Eritrean nakfa (ERN)' },
  { value: 'ETB', label: 'Ethiopian birr (ETB)' },
  { value: 'FJD', label: 'Fijian dollar (FJD)' },
  { value: 'FKP', label: 'Falkland Islands pound (FKP)' },
  { value: 'GBP', symbol: '£', label: 'British pound (GBP)' },
  { value: 'GEL', label: 'Georgian lari (GEL)' },
  { value: 'GGP', label: 'Guernsey pound (GGP)' },
  { value: 'GHS', label: 'Ghanaian cedi (GHS)' },
  { value: 'GIP', label: 'Gibraltar pound (GIP)' },
  { value: 'GMD', label: 'Gambian dalasi (GMD)' },
  { value: 'GNF', label: 'Guinean franc (GNF)' },
  { value: 'GTQ', label: 'Guatemalan quetzal (GTQ)' },
  { value: 'GYD', label: 'Guyanese dollar (GYD)' },
  { value: 'HKD', label: 'Hong Kong dollar (HKD)' },
  { value: 'HNL', label: 'Honduran lempira (HNL)' },
  { value: 'HKD', label: 'Hong Kong dollar (HKD)' },
  { value: 'HRK', label: 'Croatian kuna (HRK)' },
  { value: 'HTG', label: 'Haitian gourde (HTG)' },
  { value: 'HUF', label: 'Hungarian forint (HUF)' },
  { value: 'IDR', label: 'Indonesian rupiah (IDR)' },
  { value: 'ILS', label: 'Israeli new shekel (ILS)' },
  { value: 'IMP', label: 'Manx pound (IMP)' },
  { value: 'INR', label: 'Indian rupee (INR)' },
  { value: 'IQD', label: 'Iraqi dinar (IQD)' },
  { value: 'IRR', label: 'Iranian rial (IRR)' },
  { value: 'ISK', label: 'Icelandic króna (ISK)' },
  { value: 'JEP', label: 'Jersey pound (JEP)' },
  { value: 'JMD', label: 'Jamaican dollar (JMD)' },
  { value: 'JOD', label: 'Jordanian dinar (JOD)' },
  { value: 'JPY', symbol: '¥', label: 'Japanese yen (JPY)' },
  { value: 'KES', label: 'Kenyan shilling (KES)' },
  { value: 'KGS', label: 'Kyrgyzstani som (KGS)' },
  { value: 'KHR', label: 'Cambodian riel (KHR)' },
  { value: 'KID', label: 'Kiribati dollar (KID)' },
  { value: 'KMF', label: 'Comorian franc (KMF)' },
  { value: 'KPW', label: 'North Korean won (KPW)' },
  { value: 'KRW', label: 'South Korean won (KRW)' },
  { value: 'KWD', label: 'Kuwaiti dinar (KWD)' },
  { value: 'KYD', label: 'Cayman Islands dollar (KYD)' },
  { value: 'KZT', label: 'Kazakhstani tenge (KZT)' },
  { value: 'LAK', label: 'Lao kip (LAK)' },
  { value: 'LBP', label: 'Lebanese pound (LBP)' },
  { value: 'LKR', label: 'Sri Lankan rupee (LKR)' },
  { value: 'LRD', label: 'Liberian dollar (LRD)' },
  { value: 'LSL', label: 'Lesotho loti (LSL)' },
  { value: 'LYD', label: 'Libyan dinar (LYD)' },
  { value: 'MAD', label: 'Moroccan dirham (MAD)' },
  { value: 'MDL', label: 'Moldovan leu (MDL)' },
  { value: 'MGA', label: 'Malagasy ariary (MGA)' },
  { value: 'MKD', label: 'Macedonian denar (MKD)' },
  { value: 'MMK', label: 'Burmese kyat (MMK)' },
  { value: 'MNT', label: 'Mongolian tögrög (MNT)' },
  { value: 'MOP', label: 'Macanese pataca (MOP)' },
  { value: 'MRU', label: 'Mauritanian ouguiya (MRU)' },
  { value: 'MUR', label: 'Mauritian rupee (MUR)' },
  { value: 'MVR', label: 'Maldivian rufiyaa (MVR)' },
  { value: 'MWK', label: 'Malawian kwacha (MWK)' },
  { value: 'MXN', label: 'Mexican peso (MXN)' },
  { value: 'MYR', label: 'Malaysian ringgit (MYR)' },
  { value: 'MZN', label: 'Mozambican metical (MZN)' },
  { value: 'NAD', label: 'Namibian dollar (NAD)' },
  { value: 'NGN', label: 'Nigerian naira (NGN)' },
  { value: 'NIO', label: 'Nicaraguan córdoba (NIO)' },
  { value: 'NOK', label: 'Norwegian krone (NOK)' },
  { value: 'NPR', label: 'Nepalese rupee (NPR)' },
  { value: 'NZD', label: 'New Zealand dollar (NZD)' },
  { value: 'OMR', label: 'Omani rial (OMR)' },
  { value: 'PAB', label: 'Panamanian balboa (PAB)' },
  { value: 'PEN', label: 'Peruvian sol (PEN)' },
  { value: 'PGK', label: 'Papua New Guinean kina (PGK)' },
  { value: 'PHP', label: 'Philippine peso (PHP)' },
  { value: 'PKR', label: 'Pakistani rupee (PKR)' },
  { value: 'PLN', label: 'Polish złoty (PLN)' },
  { value: 'PRB', symbol: '₽', label: 'Transnistrian ruble (PRB)' },
  { value: 'PYG', label: 'Paraguayan guaraní (PYG)' },
  { value: 'QAR', label: 'Qatari riyal (QAR)' },
  { value: 'RON', label: 'Romanian leu (RON)' },
  { value: 'RON', label: 'Romanian leu (RON)' },
  { value: 'RSD', label: 'Serbian dinar (RSD)' },
  { value: 'RUB', label: 'Russian ruble (RUB)' },
  { value: 'RWF', label: 'Rwandan franc (RWF)' },
  { value: 'SAR', label: 'Saudi riyal (SAR)' },
  { value: 'SEK', label: 'Swedish krona (SEK)' },
  { value: 'SGD', label: 'Singapore dollar (SGD)' },
  { value: 'SHP', label: 'Saint Helena pound (SHP)' },
  { value: 'SLL', label: 'Sierra Leonean leone (SLL)' },
  { value: 'SLS', label: 'Somaliland shilling (SLS)' },
  { value: 'SOS', label: 'Somali shilling (SOS)' },
  { value: 'SRD', label: 'Surinamese dollar (SRD)' },
  { value: 'SSP', label: 'South Sudanese pound (SSP)' },
  { value: 'STN', label: 'São Tomé and Príncipe dobra (STN)' },
  { value: 'SYP', label: 'Syrian pound (SYP)' },
  { value: 'SZL', label: 'Swazi lilangeni (SZL)' },
  { value: 'THB', label: 'Thai baht (THB)' },
  { value: 'TJS', label: 'Tajikistani somoni (TJS)' },
  { value: 'TMT', label: 'Turkmenistan manat (TMT)' },
  { value: 'TND', label: 'Tunisian dinar (TND)' },
  { value: 'TOP', label: 'Tongan paʻanga (TOP)' },
  { value: 'TRY', label: 'Turkish lira (TRY)' },
  { value: 'TTD', label: 'Trinidad and Tobago dollar (TTD)' },
  { value: 'TVD', label: 'Tuvaluan dollar (TVD)' },
  { value: 'TWD', label: 'New Taiwan dollar (TWD)' },
  { value: 'TZS', label: 'Tanzanian shilling (TZS)' },
  { value: 'UAH', symbol: '₴', label: 'Ukrainian hryvnia (UAH)' },
  { value: 'UGX', label: 'Ugandan shilling (UGX)' },
  { value: 'UYU', label: 'Uruguayan peso (UYU)' },
  { value: 'UZS', label: 'Uzbekistani soʻm (UZS)' },
  { value: 'VES', label: 'Venezuelan bolívar soberano (VES)' },
  { value: 'VND', label: 'Vietnamese đồng (VND)' },
  { value: 'VUV', label: 'Vanuatu vatu (VUV)' },
  { value: 'WST', label: 'Samoan tālā (WST)' },
  { value: 'XAF', label: 'Central African CFA franc (XAF)' },
  { value: 'XCD', label: 'Eastern Caribbean dollar (XCD)' },
  { value: 'XOF', label: 'West African CFA franc (XOF)' },
  { value: 'XPF', label: 'CFP franc (XPF)' },
  { value: 'ZAR', label: 'South African rand (ZAR)' },
  { value: 'ZMW', label: 'Zambian kwacha (ZMW)' },
  { value: 'ZWB', label: 'Zimbabwean bonds (ZWB)' },
];
