import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import BaseFormikInput from './BaseFormikInput';
import MuiMaskedInput from '../MuiMaskedInput';

const FormikMaskedInput = ({
  mask,
  name,
  required,
  label,
  placeholder,
  id,
  className,
  onChange,
}) => (
  <BaseFormikInput
    filledStyle
    name={name}
    id={id}
    onChange={onChange}
    required={required}
    className={className}
  >
    {({ isError, inputId, value, setFieldValue, setFieldTouched }) => (
      <>
        {label && (
          <InputLabel
            required={required}
            error={isError}
            shrink
            id={`label-${inputId}`}
            htmlFor={inputId}
          >
            {label}
          </InputLabel>
        )}
        <TextField
          mask={mask}
          placeholder={placeholder}
          id={inputId}
          type="text"
          required={required}
          name={name}
          value={value}
          onChange={(e) => setFieldValue(name, e.target.value)}
          onBlur={(e) => setFieldTouched(name, e.target.value)}
          inputProps={{
            mask,
            guide: true,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            inputComponent: MuiMaskedInput,
          }}
        />
      </>
    )}
  </BaseFormikInput>
);

FormikMaskedInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  mask: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])),
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

FormikMaskedInput.defaultProps = {
  required: false,
  label: '',
  mask: [],
  placeholder: '',
  className: '',
  id: '',
  onChange: () => {},
};

export default FormikMaskedInput;
