import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { wsUri } from '../graphql/apollo';

const toastContainer = (title, message, type) =>
  toast(
    <div>
      <h2>{title}</h2>
      <p>{message}</p>
    </div>,
    {
      type,

      autoClose: 8000,
      draggable: true,
      closeButton: true,
    },
  );

class WsController extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      socket: this.createNewSocket(),
    };
  }

  componentDidMount() {
    this.openNotificationsSocket();
  }

  createNewSocket() {
    const { user } = this.props;
    return new WebSocket(`${wsUri}/notifications/${user.userId}/`);
  }

  openNotificationsSocket() {
    const { socket } = this.state;

    socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const { status, title, message } = data.notification;
      toastContainer(
        title,
        message,
        status.toLowerCase() === 'debug' ? 'default' : status.toLowerCase(),
      );
    };

    socket.onopen = () => {
      window.console.debug('Notification Socket opened!');
    };

    socket.onclose = () => {
      window.console.error('Notification Socket closed unexpectedly');
      this.setState({ socket: null });
      setTimeout(() => {
        window.console.debug('Try reopen Notification Socket!');
        this.setState({ socket: this.createNewSocket() }, () => {
          this.openNotificationsSocket();
        });
      }, 2000);
    };
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  user: state.settings.user,
});

WsController.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(WsController);
