import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types/index';
import NotLoginDefaultPageLayout from '../../../../../layout/notLoginDefaultPageLayout';
import CompanySettings from '../../../settings/project/companySettings';
import { TutorialContext } from '../../../../../../tutorial';

const CreateCompany = ({ me, history }) => {
  const { startTutorial } = useContext(TutorialContext);

  useEffect(() => {
    if (!me) {
      return;
    }
    if (me.isMaster) {
      startTutorial('adminCreateCompany');
    }
  }, [me, startTutorial, history]);

  return (
    <NotLoginDefaultPageLayout loading="">
      <Grid container className="h-100" justify="center" alignItems="center">
        <CompanySettings createMode />
      </Grid>
    </NotLoginDefaultPageLayout>
  );
};

CreateCompany.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isReseller: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(CreateCompany);
