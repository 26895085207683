import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import Helmet from 'react-helmet';
import { toastifyError, saveCacheRead, getSavedActiveProjects } from '../../../../../shared/utils';
import {
  createContainerSerializer,
  initialValues as createContainerInitialValues,
  serializeAttachedDevice,
  serializePickupSettings,
} from '../shared/utils';
import RegisterContainerForm from './registerFormLayout';
import registerContainerSchema from './schema';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import FavIcon from '../../../../../../images/favicon-32x32.png';

const oneContainer = loader('./../../../../../graphql/queries/devices/one_container.graphql');

const allContainerQuery = loader(
  './../../../../../graphql/queries/devices/all_containers_dashboard.graphql',
);

const containersAnalytics = loader(
  './../../../../../graphql/queries/devices/containers_analytics.graphql',
);

const createContainer = loader(
  './../../../../../graphql/mutations/devices/create_container.graphql',
);
const updateContainer = loader(
  './../../../../../graphql/mutations/devices/update_container.graphql',
);
const allMeasurementSettingsQuery = loader(
  './../../../../../graphql/queries/devices/all_measurement_settings.graphql',
);

const serializeContainer = async ({ response, setSelectedProject, setSelectedContainer }) => {
  const {
    data: { container },
  } = response;
  const selectedContainerType = container?.containerType?.id || '';
  const selectedWasteFraction = container?.wasteFraction?.id || '';
  const containerType = container?.containerType || createContainerInitialValues.containerType;
  const deviceToContainerSet = container.deviceToContainerSet || {};

  const { edges: deviceToContainerEdges = [] } = deviceToContainerSet;
  const containerAttachedDevices = deviceToContainerEdges.map(serializeAttachedDevice);
  const { id, name, ...pickupSettings } = serializePickupSettings(container?.pickupSettings || {});

  setSelectedProject(container.project.id || null);
  setSelectedContainer({
    ...createContainerInitialValues,
    id: container.id,
    containerId: container.containerId,
    description: container.description,
    showOnRoute: container.showOnRoute,
    selectedProject: container.project.id,
    selectedContainerType,
    selectedWasteFraction,
    containerPhotoUrl: container.photoUrl,
    longitude: container.location.longitude,
    latitude: container.location.latitude,
    attachedDevices: containerAttachedDevices.filter(({ endDate }) => !endDate),
    placeId: container.location.placeId,
    overflowingSettings: container.overflowingSettings || { duration: '', percentage: '' },
    ...pickupSettings,
    containerType,
  });
};

const RegisterContainer = ({
  match,
  history,
  onSubmit,
  initialValues,
  noProjectSelection,
  savedActiveProjects,
}) => {
  const intl = useIntl();
  const [initialEditMeasurements, setInitialEditMeasurements] = useState(false);
  const [isSerializing, setIsSerializing] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedContainer, setSelectedContainer] = useState({
    ...createContainerInitialValues,
    ...initialValues,
  });

  const [containerPhoto, setContainerPhoto] = useState('');

  const selfId = match.params.id || null;
  const [loadContainer, { loading: isContainerLoading, data: containerResponse }] = useLazyQuery(
    oneContainer,
  );
  const isWizard = !!onSubmit;

  useEffect(() => {
    if (!selfId) {
      setSelectedContainer({
        ...createContainerInitialValues,
        ...initialValues,
      });
    }
  }, [selfId, initialValues]);

  useEffect(() => {
    if (containerResponse) {
      setIsSerializing(true);
      serializeContainer({
        response: {
          data: containerResponse,
        },
        setSelectedProject,
        setSelectedContainer,
        setInitialEditMeasurements,
      }).finally(() => setIsSerializing(false));
    }
  }, [containerResponse]);

  const [
    loadAllMeasurementSettings,
    { loading: isMeasurementSettingsLoading, data: allMeasurementSettingsData },
  ] = useLazyQuery(allMeasurementSettingsQuery, {
    displayName: 'allMeasurements',
    options: { fetchPolicy: 'cache-first' },
  });

  useEffect(() => {
    if (selectedProject) {
      loadAllMeasurementSettings({ variables: { projectId: selectedProject } });
    }
  }, [selectedProject, loadAllMeasurementSettings]);

  const [updateContainerMutation] = useMutation(updateContainer, {
    awaitRefetchQueries: true,
  });

  const [createContainerMutation] = useMutation(createContainer, {
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (selfId && selectedContainer?.id !== selfId) {
      loadContainer({
        variables: { id: selfId },
        onError: toastifyError,
      });
    }
  }, [selfId, loadContainer, selectedContainer.id, selectedContainer]);

  const query = selfId ? updateContainerMutation : createContainerMutation;
  const queryName = selfId ? 'updateContainer' : 'createContainer';

  const createContainerHandler = useCallback(
    (values, { setSubmitting, setFieldError, setFieldValue, resetForm }) => {
      const { redirectToAttachDevice = false } = values;
      const newContainer = createContainerSerializer(values, selfId, containerPhoto);

      return query({
        variables: newContainer,
        refetchQueries: [
          { query: containersAnalytics, variables: { activeProjects: savedActiveProjects } },
        ],
        update: async (
          cache,
          {
            data: {
              [queryName]: { container: newOneContainerData },
            },
          },
        ) => {
          /* Update cached containers on Dashboard */
          const allContainerFullQuery = {
            query: allContainerQuery,
            variables: { activeProjects: savedActiveProjects },
          };

          const {
            data: { allContainers: allContainersData },
          } = await saveCacheRead(allContainerFullQuery);

          const newContainerData = {
            ...allContainersData,
            edges: allContainersData.edges.filter(({ node }) => node.id !== newOneContainerData.id),
          };

          newContainerData.edges = [
            ...newContainerData.edges,
            {
              node: {
                containerId: newOneContainerData.containerId,
                id: newOneContainerData.id,
                measurement: newOneContainerData.measurement,
                location: newOneContainerData.location,
                __typename: 'ContainerType',
              },
              __typename: 'ContainerTypeEdge',
            },
          ];

          cache.writeQuery({
            ...allContainerFullQuery,
            data: {
              allContainers: newContainerData,
            },
          });
        },
      })
        .then((containerData) => {
          const {
            data: {
              [queryName]: { container },
            },
          } = containerData;

          if (!onSubmit) {
            if (!redirectToAttachDevice) {
              history.push(`/app/containers`);
            } else {
              history.push({
                pathname: `/app/containers/${container.id}/edit`,
                state: { forceOpenAttachSensor: true },
              });
            }
          } else {
            onSubmit(container);
            loadContainer({ variables: { id: selfId } });
            resetForm();
            setFieldValue('selectedProject', values.selectedProject);
          }
        })
        .catch((e) => {
          toastifyError(e);
          const error = e.message.replace('GraphQL error: containerIdError:', '');
          if (e.message.startsWith('GraphQL error: containerIdError:')) {
            setFieldError('containerId', error);
          }
        })
        .finally(() => setSubmitting(false));
    },
    [
      containerPhoto,
      savedActiveProjects,
      history,
      onSubmit,
      selfId,
      loadContainer,
      query,
      queryName,
    ],
  );

  const allMeasurementSettings = useMemo(
    () =>
      allMeasurementSettingsData
        ? allMeasurementSettingsData.allMeasurementSettings.edges.map(({ node }) => ({
            value: node.id,
            label: node.name,
            project: node.project,
          }))
        : [],
    [allMeasurementSettingsData],
  );

  const isLoading = isMeasurementSettingsLoading || isSerializing || isContainerLoading;
  const pageTitle = selfId
    ? intl.formatMessage(
        { id: 'edit_container.title', defaultMessage: 'Edit container {containerId}' },
        { containerId: selectedContainer?.containerId },
      )
    : intl.formatMessage({ id: 'adding_container', defaultMessage: 'Adding container' });

  return (
    <Grid container className="w-100 h-100">
      <FixedLoadingLayout open={isLoading} />
      {!isWizard && (
        <Helmet>
          <title>
            {pageTitle}
            &nbsp; | &nbsp;
            {intl.formatMessage({ id: 'wastehero_platform', defaultMessage: 'WasteHero Platform' })}
          </title>
          <meta name="description" content="WasteHero" />
          <link rel="icon" type="image/png" href={FavIcon} sizes="16x16" />
        </Helmet>
      )}
      <Formik
        enableReinitialize
        initialValues={selectedContainer}
        validationSchema={registerContainerSchema()}
        onSubmit={createContainerHandler}
        validateOnMount
        validateOnChange
        validateOnBlur
      >
        <RegisterContainerForm
          allMeasurementSettings={allMeasurementSettings}
          setSelectedProject={setSelectedProject}
          setContainerPhoto={setContainerPhoto}
          selfId={selfId}
          isWizard={isWizard}
          noProjectSelection={noProjectSelection}
          initialEditMeasurements={initialEditMeasurements}
        />
      </Formik>
    </Grid>
  );
};

RegisterContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func,
  match: ReactRouterPropTypes.match.isRequired,
  noProjectSelection: PropTypes.bool,
  savedActiveProjects: PropTypes.string.isRequired,
};

RegisterContainer.defaultProps = {
  onSubmit: null,
  initialValues: {},
  noProjectSelection: false,
};

const mapStateToProps = (state) => ({
  savedActiveProjects: getSavedActiveProjects(state),
});

export const RegisterForm = connect(mapStateToProps)(withRouter(React.memo(RegisterContainer)));

const RegisterContainerPage = ({
  match: {
    params: { id: selfId = null },
  },
  classes,
}) => (
  <Grid container className={classes.root}>
    <Grid className={classes.root} container spacing={0}>
      <Grid item xs={12} className={classes.root}>
        <Typography variant="h5" align="center" className={classes.title}>
          {selfId ? (
            <FormattedMessage id="edit_container" defaultMessage="Edit container" />
          ) : (
            <FormattedMessage id="adding_container" defaultMessage="Adding container" />
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RegisterForm />
      </Grid>
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.variables.cEmperor,
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
  },
});

RegisterContainerPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles, { withTheme: true })(RegisterContainerPage);
