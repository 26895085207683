const styles = (theme) => ({
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 101,
  },
  dialogHeaderContainer: {
    background: theme.variables.VHBlue,
    color: theme.variables.cWhite,
    padding: 20,
  },
  dialogTitle: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 20,
  },
  closeBtn: {
    minWidth: 20,
    padding: '5px 10px',
  },
  dialogBody: {
    padding: 20,
    minHeight: '16em',
  },
  cell: {
    padding: 10,
  },
  emptyInfo: {
    textAlign: 'center',
  },
});

export default styles;
