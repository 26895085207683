import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormikTimePicker from './FormikTimePicker';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 700,
    lineHeight: '20px',
    transform: 'translate(0, -2px)',
  },
  centredLabel: {
    marginTop: 14,
  },
  maskedInput: {
    width: 144,
    '& .MuiInput-input': {
      textAlign: 'center',
    },
  },
}));

const FormikTimeDiffFields = ({ fromName, toName, label, disabled, labelInline, required }) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <Grid item xs={labelInline ? 'auto' : 12}>
          <Typography variant="body2" className={classes.label}>
            {label} {required && '*'}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <FormikTimePicker
          name={fromName}
          className={classes.maskedInput}
          disabled={disabled}
          placeholder="00:00"
        />
      </Grid>
      {label && (
        <Grid item>
          <Typography variant="body2" className={classNames(classes.label, classes.centredLabel)}>
            <FormattedMessage id="to" defaultMessage="to" />
          </Typography>
        </Grid>
      )}
      <Grid item>
        <FormikTimePicker
          name={toName}
          className={classes.maskedInput}
          disabled={disabled}
          placeholder="00:00"
        />
      </Grid>
    </>
  );
};

FormikTimeDiffFields.propTypes = {
  fromName: PropTypes.string.isRequired,
  toName: PropTypes.string.isRequired,
  labelInline: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FormikTimeDiffFields.defaultProps = {
  label: '',
  disabled: false,
  labelInline: true,
  required: false,
};

export default FormikTimeDiffFields;
