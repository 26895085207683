import React, { useState, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useSelector, connect } from 'react-redux';
import StyledStatus from '../../components/styledStatus';
import FilledSelect from '../../../../../shared/inputs/FilledSelect';
import { STATUSES, STATUSES_VALUES } from '../../components/utils';
import AddButton from '../../components/addButton';
import ListPageTemplate from '../../components/listPageTemplate';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';
import HeaderTab from '../../components/headerTab';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import {
  vehiclesTableColumns,
  getVehiclesTableFilterableColumns,
} from '../../../../../shared/utils/constants';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';
import {
  getSavedActiveProjects,
  getUsersAvailableProjects,
  saveCacheRead,
} from '../../../../../shared/utils';
import { StyledListLink } from '../../components/linkHoverStyle';
import ButtonTooltip from '../../../../../shared/tooltip/ButtonTooltip';
import useAllowAddVehicle from '../../../../../shared/hooks/useAllowAddVehicle';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const allVehiclesQuery = loader(
  './../../../../../graphql/queries/fleet_management/all_vehicles.graphql',
);
const filterVehiclesQuery = loader(
  './../../../../../graphql/queries/fleet_management/filter_vehicles.graphql',
);
const deleteVehicleQuery = loader(
  './../../../../../graphql/mutations/fleet_management/delete_vehicle.graphql',
);

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
  select: {
    textAlign: 'center',
  },
  buttonTooltip: {
    height: '100%',
  },
}));

const VehicleList = ({ userLayoutConfig, handleConfigChange, me, type }) => {
  const [isRegularUser] = useRegularUser();
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const classes = useStyles();
  const intl = useIntl();
  const [vehicleFilter, setVehicleFilter] = useState('');
  const { forbidAddVehicle, errorMessage } = useAllowAddVehicle();

  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));

  const queryExtraVariables = useMemo(
    () => ({
      status: vehicleFilter === STATUSES_VALUES.all ? null : vehicleFilter,
      activeProjects: savedActiveProjects,
      Type: type,
    }),
    [vehicleFilter, savedActiveProjects, type],
  );

  const vehicleSerializer = ({ allVehicles: { edges: arr, totalCount = 0 } = {} }) => ({
    totalCount,
    items: arr
      ? arr.map(({ node }) => ({
          id: node.id,
          name: node.name,
          logo: node.logo,
          status: <StyledStatus status={node.status} />,
          type: node.vehicleType?.name,
          allowActions: me.ownProjectsIds.includes(node.project?.id),
          drivers: node.drivers?.edges?.length
            ? node.drivers?.edges.map((driver, index) => (
                <React.Fragment key={driver.node.id}>
                  <StyledListLink
                    to={`/app/fleet-management/driver-management/${driver.node.id}`}
                    text={`${driver?.node?.user?.firstName} ${driver?.node?.user?.lastName}`}
                    disableFlex
                  />
                  {index < node.drivers?.edges?.length - 1 && ', '}
                </React.Fragment>
              ))
            : '-',
        }))
      : [],
  });

  const getDriverName = (vehicleNode) => {
    if (vehicleNode.drivers?.edges?.length > 0) {
      const driverNames = vehicleNode.drivers.edges
        .map(({ node }) => `${node.user.firstName} ${node.user.lastName}`)
        .join(',');
      return driverNames;
    }
    return '';
  };

  const vehiclesAutocompleteSerializer = ({ allVehicles: { edges: arr = [] } = {} }) =>
    arr.map(({ node }) => ({
      primaryText: node.name,
      secondaryText: getDriverName(node),
      logo: node.logo,
      id: node.id,
    }));

  const [deleteVehicleMutation] = useMutation(deleteVehicleQuery, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: allVehiclesQuery, variables: { Type: type } }],
  });

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    vehiclesTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );
  const cellsConfig = [
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getVehiclesTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      label: <FormattedMessage id="vehicle.list.vehicleId" defaultMessage="Vehicle ID" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'status',
      label: <FormattedMessage id="vehicles.status" defaultMessage="Status" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'type',
      label: <FormattedMessage id="vehicles.type" defaultMessage="Type" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'drivers',
      label: <FormattedMessage id="vehicles.driver" defaultMessage="Driver" />,
      numeric: false,
      disablePadding: false,
    },
  ];

  const getAddVehicleTooltipMessage = () => {
    if (isRegularUser) {
      return (
        <FormattedMessage
          id="no_permission_add_vehicle"
          defaultMessage="You don’t have permission to add vehicle"
        />
      );
    }

    if (forbidAddVehicle) {
      return errorMessage;
    }

    return (
      <FormattedMessage
        id={usersAvailableProjects?.length ? 'tooltip.add_vehicle' : 'no_available_projects'}
        defaultMessage={
          usersAvailableProjects?.length
            ? 'Click to add a new vehicle for fleet management'
            : 'Please add at least one project'
        }
      />
    );
  };

  const extraFilter = (
    <>
      <Grid item xs={5}>
        <FilledSelect
          name="vehicle_type"
          placeholder={intl.formatMessage({
            id: 'vehicle.statuses',
            defaultMessage: 'Vehicle statuses',
          })}
          valuesList={STATUSES}
          required
          className={classes.select}
          value={vehicleFilter}
          onChange={(e) => setVehicleFilter(e.target.value)}
        />
      </Grid>
      <Grid item xs={5} tourid="addVehicles">
        <ButtonTooltip text={getAddVehicleTooltipMessage()}>
          <AddButton
            text={
              <Typography variant="body1" className={classes.buttonText}>
                <FormattedMessage id="vehicle.add_vehicle" defaultMessage="Add vehicles" />
              </Typography>
            }
            disabled={forbidAddVehicle || isRegularUser}
          />
        </ButtonTooltip>
      </Grid>
    </>
  );

  const allowDeleteItem = (item) =>
    item.drivers.length === 0 || (item.drivers.length === 1 && item.drivers[0] === '-');

  const updateCache = useCallback(
    (vehicle) => async (cache) => {
      const allVehiclesFullQuery = {
        query: allVehiclesQuery,
        variables: {
          status: vehicleFilter === STATUSES_VALUES.all ? null : vehicleFilter,
          activeProjects: savedActiveProjects,
          Type: type,
        },
      };

      const {
        data: { allVehicles: allVehiclesData },
      } = await saveCacheRead(allVehiclesFullQuery);

      const newVehiclesData = {
        ...allVehiclesData,
        edges: allVehiclesData.edges.filter(({ node }) => node.id !== vehicle.id),
      };

      cache.writeQuery({
        ...allVehiclesFullQuery,
        data: {
          allVehicles: newVehiclesData,
        },
      });
    },
    [savedActiveProjects, vehicleFilter, type],
  );

  return (
    <ListPageTemplate
      pageTitle={intl.formatMessage({ id: 'vehicle_overview', defaultMessage: 'Vehicle Overview' })}
      endAdornment={<HeaderTab />}
      userLayoutConfig={userLayoutConfig}
      updateUserConfig={handleConfigChange}
      cellsConfig={cellsConfig}
      autocompleteSerializer={vehiclesAutocompleteSerializer}
      autoCompleteQuery={filterVehiclesQuery}
      queryExtraVariables={queryExtraVariables}
      extraFilter={extraFilter}
      deleteItemMutation={deleteVehicleMutation}
      query={allVehiclesQuery}
      updateCache={updateCache}
      itemsSerializer={vehicleSerializer}
      activeTableColumns={activeTableColumns}
      allowDeleteItem={allowDeleteItem}
      deleteItemErrorTitle={
        <>
          <FormattedMessage
            id="vehicle.delete_error"
            defaultMessage="A driver should be unassigned, before deleting a vehicle."
          />
        </>
      }
      disableSelect
    />
  );
};

VehicleList.propTypes = {
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string.isRequired,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  me: PropTypes.shape({
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
    isMaster: PropTypes.bool,
    isReseller: PropTypes.bool,
    isSuperuser: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});
export default connect(mapStateToProps)(withUserLayoutConfig('vehicle')(VehicleList));
