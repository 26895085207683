import React from 'react';
import { FormattedMessage } from 'react-intl';

const divStyle = { fontSize: 'large' };
/**
 * Steps const object properites can be
 * highLightID - tourid of the component
 * tutorialContent - tutorial to be displayed.
 * isIntro - is introduction step?
 * enableInteraction - enable user interaction with highlighted component.
 * isBig - is big content?
 * isWecomeMessage - is the step is starting of the tutorial?
 * userNavigate - url of the page for which the step has to wait for
 * enterNowOption - is there a button for user to enter the details now
 *                  and the tutorial should wait
 *                  If enabled, next step should be
 *                    {
                        highlightID: 'wholePage',
                        enableInteraction: true,
                        userNavigate: 'url to check for resuming tutorial',
                      },
                    to allow user to enter details now
 */
const welcomeMessage = [
  {
    isWelcomeMessage: true,
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h1>
          <FormattedMessage id="tutotial.welcome.title" defaultMessage="Welcome" />
        </h1>
        <p>
          <FormattedMessage id="tutotial.welcome.p1" defaultMessage="Hi," />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.welcome.p2"
            defaultMessage="It looks like you’re new here! Let’s get started with a quick introduction to the WasteHero platform."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.welcome.p3"
            defaultMessage="You can always come back to this tutorial by heading to the Support Center."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.welcome.p4"
            defaultMessage="Ready to get started? Let us begin."
          />
        </p>
      </div>
    ),
  },
];

const sideBarStep = [
  {
    highlightID: 'sideBar',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.sidebar"
            defaultMessage="You can navigate to the features and functions that WasteHero provides, from the sidebar."
          />
        </p>
      </div>
    ),
  },
];

const dashboardSteps = [
  {
    highlightID: 'wholePage',
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.dashboard" defaultMessage="Dashboard" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.dashboard.p1"
            defaultMessage="This is the dashboard. You can think of it as your homepage."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'selectActiveProjects',
    enableInteraction: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.project_selection" defaultMessage="Project selection" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.project_selection.p1"
            defaultMessage="Use this dropdown menu to select the project's you want to view in the dashboard."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'statusOverview',
    enableInteraction: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.status_overview" defaultMessage="Status overview" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.status_overview.p1"
            defaultMessage="Here you can get a quick overview of the status of your containers."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'dashboardMap',
    enableInteraction: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.map" defaultMessage="Map" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.map.p1"
            defaultMessage="On the map you can view the location of your containers. Use the search function to find extra information on specific containers."
          />
        </p>
      </div>
    ),
  },
];

const settingsClickStep = [
  {
    highlightID: 'settingsNavigation',
    enableInteraction: true,
    userNavigate: 'settings',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage id="tutotial.go_to_settings" defaultMessage="Let us go to settings" />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_settings.p1"
            defaultMessage="Digitalization of your waste stream have to establish some ground settings."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.go_to_settings.secondpara"
            defaultMessage="To navigate, click on settings."
          />
        </p>
      </div>
    ),
  },
];

const settingsMenuSteps = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.settings" defaultMessage="Settings" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.settings.p1"
            defaultMessage="This is where you’ll establish some ground settings for your project. Remember you can go back at any time and change these."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'projectList',
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage
            id="tutotial.select_company_project"
            defaultMessage="Select company project"
          />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.select_company_project.firstpara"
            defaultMessage="You can view the list of company projects here."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.select_company_project.secondpara"
            defaultMessage="Select your project to get access to it’s settings."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'myAccount',
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.my_account" defaultMessage="My account" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.my_account.p1"
            defaultMessage="In Account Settings you can add and edit all of your personal details, as well as change your email and password."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'projectsOverview',
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.projects_overview" defaultMessage="Projects overview" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.projects_overview.p1"
            defaultMessage="In Projects Overview you can edit company details and add new projects."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'projectSettings',
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.project_settings" defaultMessage="Project settings" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.project_settings.p1"
            defaultMessage="Under Project Settings you can add your project name and add a description."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.project_settings.p2"
            defaultMessage="You can also edit the default setting for map location, time zone, currency and measurement units."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'containerSettings',
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.container_settings" defaultMessage="Container settings" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.container_settings.p1"
            defaultMessage="Here you can create and edit different types of container templates based on their dimensions, capacity, emplacement, vehicle coupling method, and emptying time."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'wasteFractionSettings',
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage
            id="tutotial.waste_fraction_settings"
            defaultMessage="Waste fraction settings"
          />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.waste_fraction_settings.p1"
            defaultMessage="Here you can create and edit the different types of waste that you collect, as well as their disposal methods."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'measurementSettings',
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage
            id="tutotial.measurement_settings"
            defaultMessage="Measurement settings"
          />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.measurement_settings.p1"
            defaultMessage="Here you will decide the hour and frequency of measurements for the fill-level sensors in your containers."
          />
        </p>
      </div>
    ),
  },
];

const projectSettingsSteps = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutorialContent.settings" defaultMessage="Settings" />
        </h2>
        <p>
          <FormattedMessage
            id="tutorialContent.project_settings.p1"
            defaultMessage="Initial setup can be done by the 4-step process which includes project, container, waste fraction, and measurements settings."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutorialContent.project_settings.p2"
            defaultMessage="In the project settings, you can set the preferences that will be used entirely on the project."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'projectDetails',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.project_details.p1"
            defaultMessage="Other than project name and description, you also choose a logo to be displayed for the project."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'projectPreferences1',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.project_preferences.p1"
            defaultMessage="These settings will affect how data is presented across the platform."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'projectPreferences2',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.project_preferences.thirdpara"
            defaultMessage="Select which measurement units you prefer for container dimensions, driving distance, contents weight, content volume, and temperature in this project."
          />
        </p>
      </div>
    ),
  },
];

const projectSettingsSaveAndNextWaitSteps = [
  {
    highlightID: 'saveButton',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.save_project_settings"
            defaultMessage="Save the project settings"
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.save_project_settings.firstpara"
            defaultMessage="Once you have made your selection, click save."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'nextButton',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.continue_other_settings"
            defaultMessage="Continue to other settings"
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.continue_other_settings.firstpara"
            defaultMessage="Eventually, click on the next button to continue to container settings."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.continue_other_settings.secondpara"
            defaultMessage="You have to set up at least one project to continue."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'wholePage',
    enableInteraction: true,
    userNavigate: 'container-settings',
  },
];

const containerToProjectOverviewSteps = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.container_settings" defaultMessage="Container settings" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.container_settings.p1"
            defaultMessage="Here you can create and edit different types of container templates based on their dimensions, capacity, emplacement, vehicle coupling method, and emptying time."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.container_settings.p2"
            defaultMessage="This shortens the process of creating new container registrations later on."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'containerTypeDetails',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.container_type.sixtpara"
            defaultMessage="These container settings will assist in optimising the route plan for haulers."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'containerTypeDimensions',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.container_type_dimensions.p1"
            defaultMessage="You can input the common measurement details of this container type here."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.container_type_dimensions.p2"
            defaultMessage="These measurements which will be used to calculate how much percentage of the container is filled in the future."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'containerTypeList',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.container_type_list.p1"
            defaultMessage="Your container types will be added to the list here."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'nextButton',
    userNavigate: 'waste-fractions-settings',
    enterNowOption: true,
    enableInteraction: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.containertype_fillnow.p1"
            defaultMessage="If you want to fill container type details now, click ‘Enter details now’ button."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.containertype_fillnow.p2"
            defaultMessage="If you want to do that later, click ‘Next’."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'wholePage',
    enableInteraction: true,
    userNavigate: 'waste-fractions-settings',
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage
            id="tutotial.waste_fraction_settings"
            defaultMessage="Waste fraction settings"
          />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.waste_fraction_settings.p1"
            defaultMessage="Here you can create and edit the different types of waste that you collect, as well as their disposal methods."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'wasteFractionSettings',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutorial.wasteFractionSettings.p1"
            defaultMessage="You can select multiple waste fractions and disposal methods  from the list if necessary. Weight to volume ratio will be used for analysis."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'nextButton',
    userNavigate: 'measurement-settings',
    enterNowOption: true,
    enableInteraction: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutorial.waste_fraction_fillnow.p1"
            defaultMessage="If you want to fill waste fraction details now, click ‘Enter details now’ button."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.waste_fraction_fillnow.p2"
            defaultMessage="If you want to do that later, click ‘Next’."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'wholePage',
    enableInteraction: true,
    userNavigate: 'measurement-settings',
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage
            id="tutotial.measurement_settings"
            defaultMessage="Measurement settings"
          />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.measurement_settings.p1"
            defaultMessage="Here you will decide the hour and frequency of measurements for the fill-level sensors in your containers."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'chooseHours',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.choose_hours.p1"
            defaultMessage="You can select the hours of the day in which measurements have to be taken."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.choose_hours.p2"
            defaultMessage="The measurement device will be sleeping the rest of the time to conserve battery."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'measurementConfig1',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutorial.measurementConfig1.p1"
            defaultMessage="In the measurements per hour drop-down, you can select the number of measurements to be taken each hour. We suggest 1 measurement per hour as a standard setting."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'measurementConfig2',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutorial.measurementConfig2.p1"
            defaultMessage="If there are few deposits to your containers on weekends, you can exclude Saturday and Sunday."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutorial.measurementConfig2.p2"
            defaultMessage="If your containers won’t be utilised over a certain date range (such as summer holidays), you can also choose to not take measurements during this time."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'addDefault',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.measurement_config.add_default.p1"
            defaultMessage="As an alternate option you can choose from our preset measurements."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.measurement_config.add_default.p2"
            defaultMessage="You can select from the four or eight daily measurement options depending on your traffic."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'nextButton',
    userNavigate: 'project-overview',
    enterNowOption: true,
    enableInteraction: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.measurement_settings_fillnow.firstpara"
            defaultMessage="If you want to fill measurement settings now, click ‘Enter details now’ button."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.measurement_settings_fillnow.secondpara"
            defaultMessage="If you want to do that later, click ‘Next’."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'wholePage',
    enableInteraction: true,
    userNavigate: 'project-overview',
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutorial.project_overview" defaultMessage="Project overview" />
        </h2>
        <p>
          <FormattedMessage
            id="tutorial.project_overview.p1"
            defaultMessage="The last screen of the setting process gives you an overview of the configured details."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'projectOverview',
    enableInteraction: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutorial.projectOverview.p1"
            defaultMessage="Mouse over the icons to view the details of the project settings you have just established."
          />
        </p>
      </div>
    ),
  },
];

const settingCompletedGreetingsStep = [
  {
    isIntro: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.settings_complete.p1"
            defaultMessage="That is it. Remember that you can come back to the settings at any time to make changes."
          />
        </p>
      </div>
    ),
  },
];

const inviteUserAndNewProjectSteps = [
  {
    highlightID: 'inviteUser',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.invite_user.firstpara"
            defaultMessage="You can invite a new user to the platform from here. Select the account type to be created: admin, project owner or user."
          />
        </p>
      </div>
    ),
  },
];

const containerClickStep = [
  {
    highlightID: 'containerNav',
    enableInteraction: true,
    userNavigate: 'app/containers',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_containers."
            defaultMessage="Let us go to containers."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_containers.firstpara"
            defaultMessage="On containers can view the details of our containers and add new containers to the platform."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.go_to_containers.secondpara"
            defaultMessage="Click on ‘Containers’ to get started."
          />
        </p>
      </div>
    ),
  },
];

const containerSteps = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.containers" defaultMessage="Containers" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.containers.firstpara"
            defaultMessage="Here you can get an overview of the containers within your project."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'containersTable',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h3>
          <FormattedMessage id="tutotial.containers_table" defaultMessage="Containers table" />
        </h3>
        <p>
          <FormattedMessage
            id="tutotial.containers_table.p1"
            defaultMessage="You can select a container from the list to view further details."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.containers_table.p2"
            defaultMessage="Also it is possible to make edits to specific containers from here."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'addContainer',
    enableInteraction: true,
    userNavigate: 'app/containers/add',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_adding_containers."
            defaultMessage="Let us go to adding containers."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_adding_containers.firstpara"
            defaultMessage="Let us have a look at adding container to the platform."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.go_to_adding_containers.secondpara"
            defaultMessage="Click on ‘Add container’."
          />
        </p>
      </div>
    ),
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.adding_containers" defaultMessage="Adding containers" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.adding_containers.p1"
            defaultMessage="Here you can add a new container to your project."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.adding_containers.p2"
            defaultMessage="You can choose from the existing container type and waste fraction templates created in your settings."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'attachSensor',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.attach_sensor.p1"
            defaultMessage="If you haven’t already allocated the sensor to a container via the Installation App, you can do so here."
          />
        </p>
      </div>
    ),
  },
];

const notificationsClickStep = [
  {
    highlightID: 'notificationsNav',
    enableInteraction: true,
    userNavigate: 'app/notifications',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutorial.go_to_notifications"
            defaultMessage="Let us go to notifications."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutorial.go_to_dashboard.firstpara"
            defaultMessage="Click on ‘Notifications’ to continue."
          />
        </p>
      </div>
    ),
  },
];

const notificationSteps = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.notifications" defaultMessage="Notifications" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.notifications.p1"
            defaultMessage="Here you can configure WasteHero sensors to send an email notification when they satisfy certain conditions."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'addNotification',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.add_notification.p1"
            defaultMessage="Create email notifications based on conditions such as fill-level or temperature within your containers."
          />
        </p>
      </div>
    ),
  },
];

const fleetManagementClickStep = [
  {
    highlightID: 'fleetManagementNav',
    enableInteraction: true,
    userNavigate: 'app/fleet-management',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_fleet_management"
            defaultMessage="Let us go to fleet management."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_fleet_management.p1"
            defaultMessage="Click on ‘Fleet management’ to continue."
          />
        </p>
      </div>
    ),
  },
];

const fleetManagementSteps = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.fleet_management" defaultMessage="Fleet management" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.fleet_management.p1"
            defaultMessage="Fleet management is where you will add details about your vehicles, drivers and locations associated with your project."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'vehicleOverview',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.vehicle_overview.p1"
            defaultMessage="Here you can get an overview of the vehicles in your project and their current status. Clicking a vehicle name shows you further details."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'vehicleType',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.vehicle_type.p1"
            defaultMessage="Here you can get an overview of and create new vehicle types. Vehicle types are a template for vehicles with common body, loading and mechanization features."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'addVehicles',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.add_vehicles.p1"
            defaultMessage="Here you can add a new individual vehicle and choose which project and waste fractions it will be used for. You’ll also need to include the vehicle’s availability and its associated depots and waste stations."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'driverNav',
    enableInteraction: true,
    userNavigate: 'app/fleet-management/driver-management',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_driver_management"
            defaultMessage="Let us go to driver management."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_driver_management.p1"
            defaultMessage="Click on ‘Driver management’ to continue."
          />
        </p>
      </div>
    ),
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.driver_management" defaultMessage="Driver management" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.driver_management.p1"
            defaultMessage="Here you can see an overview of the drivers within your project and their working status."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.driver_management.p2"
            defaultMessage="Clicking a driver name shows you further details."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'inviteDriver',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.invite_driver.p1"
            defaultMessage="Invite a driver to join your project here."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'driverSettings',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.driver_settings.p1"
            defaultMessage="Driver settings is where invited drivers add their details. Admins can also enter details for a driver they’ve already invited."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.driver_settings.p2"
            defaultMessage="Include contact information, licence details, vehicle driven and salary."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.driver_settings.p3"
            defaultMessage="Add driver working schedules and start/end locations to inform route scheduling."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'locationNav',
    enableInteraction: true,
    userNavigate: 'app/fleet-management/locations',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_locations"
            defaultMessage="Let us go to locations management."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_locations.p1"
            defaultMessage="Click on ‘Locations’ to continue."
          />
        </p>
      </div>
    ),
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.fm_locations" defaultMessage="Locations overview" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.fm_locations.p1"
            defaultMessage="Here you can get an overview of the depots, waste stations, and workshops associated with your project."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.fm_locations.p2"
            defaultMessage="Clicking the location name shows you further details."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'addLocation',
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.add_location.p1"
            defaultMessage="Here you can add a new location to your project. Specify the location type, geofence radius, address, and opening hours."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.add_location.p2"
            defaultMessage="For a depot, specify the amount of trucks it holds."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.add_location.p3"
            defaultMessage="For a waste station, specify accepted waste fractions and time needed for unloading and exiting the facility."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.add_location.p4"
            defaultMessage="For a workshop, specify opening hours and the services provided."
          />
        </p>
      </div>
    ),
  },
];

const operationManagementClickStep = [
  {
    highlightID: 'operationManagement',
    enableInteraction: true,
    userNavigate: 'app/routes',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_operation_management"
            defaultMessage="Let us go to operation management."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_operation_management.p1"
            defaultMessage="Click on ‘Operation management’ to continue."
          />
        </p>
      </div>
    ),
  },
];

const operationManagementSteps = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage
            id="tutotial.operation_management"
            defaultMessage="Operation management"
          />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.operation_management.p1"
            defaultMessage="Operation management is where you will setup waste schemes for collection, oversee upcoming and historic routes, and view inquiries."
          />
        </p>
      </div>
    ),
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.route_overview" defaultMessage="Route overview" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.route_overview.p1"
            defaultMessage="Here you can get an overview of upcoming scheduled routes in any given time period."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.route_overview.p2"
            defaultMessage="Selecting a route from the list gives you a detailed description of the route and shows collection points on the map."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'inquiriesNav',
    enableInteraction: true,
    userNavigate: 'app/routes/inquiries',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_inquiries"
            defaultMessage="Let us go to inquiries."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_inquiries.p1"
            defaultMessage="Click on ‘Inquiries’ to continue."
          />
        </p>
      </div>
    ),
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.om_inquiries" defaultMessage="Inquiries" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.om_inquiries.p1"
            defaultMessage="Here is where you can view and deal with inquiries that have been submitted by drivers from the navigation app."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.om_inquiries.p2"
            defaultMessage="Admins can also create new inquiries on the platform if receiving complaints from citizens."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'collectionPlanNav',
    enableInteraction: true,
    userNavigate: 'app/routes/collection-plan',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_collection_plan"
            defaultMessage="Let us go to collection plan."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_collection_plan.p1"
            defaultMessage="Click on ‘Collection plan’ to continue."
          />
        </p>
      </div>
    ),
  },
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.om_collection_plan" defaultMessage="Collection plan" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.om_collection_plan.p1"
            defaultMessage="In the collected tab, you can find a historical view of which individual containers were collected and which driver collected them."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.om_collection_plan.p2"
            defaultMessage="Enter a container ID in the search bar and specify dates to find out how often a container was collected in a given time period."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'planned',
    tutorialContent: () => (
      <div style={divStyle}>
        <p>
          <FormattedMessage
            id="tutotial.om_collection_planned.p1"
            defaultMessage="Under the planned tab, you can find an overview of the next time individual containers are scheduled to be collected."
          />
        </p>
      </div>
    ),
  },
];

const dashboardClickStep = [
  {
    highlightID: 'dashboardNav',
    enableInteraction: true,
    userNavigate: 'app/dashboard',
    tutorialContent: () => (
      <div style={divStyle}>
        <h4>
          <FormattedMessage
            id="tutotial.go_to_dashboard."
            defaultMessage="Let us go to dashboard."
          />
        </h4>
        <p>
          <FormattedMessage
            id="tutotial.go_to_dashboard.firstpara"
            defaultMessage="Click on ‘Dashboard’ to continue."
          />
        </p>
      </div>
    ),
  },
];

const tutorialCompleteStep = [
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage id="tutotial.congratulations" defaultMessage="Congratulations" />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.congratulations.p1"
            defaultMessage="Congratulations on taking the first step towards becoming a WasteHero!"
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.congratulations.p2"
            defaultMessage="Now you’re set to start using the WasteHero waste management platform and begin analysing and optimising your processes."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.congratulations.p3"
            defaultMessage="Remember, you can always restart this tutorial and find more help in our Support Center."
          />
        </p>
        <p>
          <FormattedMessage id="tutotial.congratulations.p4" defaultMessage="Have a nice day." />
        </p>
      </div>
    ),
  },
];

const supportCenterSteps = [
  ...dashboardClickStep,
  ...welcomeMessage,
  ...dashboardSteps,
  ...sideBarStep,
  ...settingsClickStep,
  ...settingsMenuSteps,
  ...containerClickStep,
  ...containerSteps,
  ...notificationsClickStep,
  ...notificationSteps,
  ...fleetManagementClickStep,
  ...fleetManagementSteps,
  ...operationManagementClickStep,
  ...operationManagementSteps,
  ...dashboardClickStep,
  ...tutorialCompleteStep,
];

const adminTutorialSteps = [
  {
    isWelcomeMessage: true,
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h1>
          <FormattedMessage id="tutotial.welcome" defaultMessage="Welcome" />
        </h1>
        <p>
          <FormattedMessage
            id="tutotial.admin_welcome.p1"
            defaultMessage="Welcome to the WasteHero waste management system. We are excited to help you on your journey towards streamlined and sustainable waste management."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.admin_welcome.p2"
            defaultMessage="This tutorial guides you through how to use the platform and how to configure the required settings so that you can begin optimising your waste collection."
          />
        </p>
        <p>
          <FormattedMessage
            id="tutotial.admin_welcome.p3"
            defaultMessage="Initially you have to give details about the company to proceed."
          />
        </p>
      </div>
    ),
  },
  {
    highlightID: 'wholePage',
    enableInteraction: true,
    userNavigate: 'settings',
  },
  ...projectSettingsSteps,
  ...projectSettingsSaveAndNextWaitSteps,
  ...containerToProjectOverviewSteps,
  ...inviteUserAndNewProjectSteps,
  ...settingCompletedGreetingsStep,
  ...dashboardClickStep,
  ...dashboardSteps,
  ...sideBarStep,
  ...containerClickStep,
  ...containerSteps,
  ...notificationsClickStep,
  ...notificationSteps,
  ...fleetManagementClickStep,
  ...fleetManagementSteps,
  ...operationManagementClickStep,
  ...operationManagementSteps,
  ...settingsClickStep,
  {
    isIntro: true,
    isBig: true,
    tutorialContent: () => (
      <div style={divStyle}>
        <h2>
          <FormattedMessage
            id="tutotial.complete_settings"
            defaultMessage="Complete the settings"
          />
        </h2>
        <p>
          <FormattedMessage
            id="tutotial.complete_settings.firstpara"
            defaultMessage="The tutorial for you is about to end. You are recommended to continue to configure your projects’ container type, waste fraction and measurement settings."
          />
        </p>
      </div>
    ),
  },
  ...tutorialCompleteStep,
];

const demoAccountSteps = [
  ...welcomeMessage,
  ...dashboardSteps,
  ...sideBarStep,
  ...settingsClickStep,
  ...settingsMenuSteps,
  ...containerClickStep,
  ...containerSteps,
  ...notificationsClickStep,
  ...notificationSteps,
  ...fleetManagementClickStep,
  ...fleetManagementSteps,
  ...operationManagementClickStep,
  ...operationManagementSteps,
  ...dashboardClickStep,
  ...tutorialCompleteStep,
];

export { supportCenterSteps, adminTutorialSteps, demoAccountSteps };
