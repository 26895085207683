import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const InputSkeleton = ({ classes, withLabel }) => (
  <>
    {withLabel && <Skeleton className={classNames(classes.skeleton)} height={20} width={100} />}
    <Skeleton className={classNames(classes.skeleton, classes.input)} height={47} />
  </>
);
const styles = () => ({
  skeleton: {
    transform: 'none',
  },
  input: {
    borderRadius: 24,
    marginTop: 12,
  },
});

InputSkeleton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  withLabel: PropTypes.bool,
};

InputSkeleton.defaultProps = {
  withLabel: true,
};

export default withStyles(styles)(InputSkeleton);
