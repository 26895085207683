import { useState, useEffect, useCallback } from 'react';

export default function useColumnFilter(tableColumns, userLayoutConfig, handleConfigChange) {
  const [activeTableColumns, setActiveTableColumns] = useState(tableColumns);
  useEffect(() => {
    setActiveTableColumns(
      tableColumns.filter(
        (activeTableColumn) =>
          !userLayoutConfig.disabledColumns?.some(
            (disabledColumn) => disabledColumn === activeTableColumn,
          ),
      ),
    );
  }, [tableColumns, userLayoutConfig.disabledColumns]);

  const handleFilterColumns = useCallback(
    (columnName) => {
      if (!userLayoutConfig.disabledColumns) {
        return;
      }
      const columnIndex = activeTableColumns.indexOf(columnName);
      if (columnIndex > -1) {
        handleConfigChange({
          ...userLayoutConfig,
          disabledColumns: userLayoutConfig.disabledColumns?.concat([columnName]),
        });
      } else {
        handleConfigChange({
          ...userLayoutConfig,
          disabledColumns: userLayoutConfig.disabledColumns?.filter(
            (column) => column !== columnName,
          ),
        });
      }
    },
    [activeTableColumns, handleConfigChange, userLayoutConfig],
  );
  return [activeTableColumns, handleFilterColumns];
}
