import React, { useState } from 'react';
import { Grid, AppBar, Tab, Tabs, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import useStyles from './styles';
import navData from './navData';
import SupportRouter from './supportRouter';

const SupportPage = ({ match, history }) => {
  const [currentTab, setCurrentTab] = useState('home');

  const classes = useStyles();
  const handleTabChange = (event, value) => {
    setCurrentTab(value);
    history.push(value === 'home' ? `${match.url}` : `${match.url}${value}`);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Grid container justify="flex-end" direction="row" spacing={2}>
          <Grid
            container
            item
            xs={3}
            justify="flex-start"
            alignItems="center"
            onClick={(e) => handleTabChange(e, 'home')}
          >
            <a rel="noreferrer" href="https://wastehero.freshdesk.com/support/home" target="_blank">
              <Button variant="contained" color="secondary" className={classes.button}>
                <FormattedMessage
                  id="submit_support_ticket"
                  defaultMessage="Submit Support Ticket"
                />
              </Button>
            </a>
          </Grid>
          <Grid container item xs={9} justify="flex-end">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="support category tab"
              textColor="secondary"
              indicatorColor="secondary"
              className={classes.tabs}
            >
              <Tab value="home" style={{ display: 'none' }} />
              {navData.map((nav) => (
                <Tab
                  label={nav.tabLabel}
                  className={classes.tab}
                  key={nav.name}
                  value={nav.routeLink}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>
      <div>
        <SupportRouter tabSelect={setCurrentTab} />
      </div>
    </div>
  );
};

SupportPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(SupportPage);
