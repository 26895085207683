import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useStyles from '../../shared/styles';
import ListStyled from '../../shared/listStyled';
import FaqContainer from '../../shared/faqContainer';

const NotificationPlatform = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <FaqContainer
        title={
          <FormattedMessage id="platform_notifications_header" defaultMessage="Notifications" />
        }
        text={[
          <FormattedMessage
            id="platform_notifications_abstract_first"
            defaultMessage="It is possible to configure WasteHero sensors to send an email notification based on changes to fill-level, temperature, location, container movements, frequency of data transmissions, and activity in the container. The <bold>Notifications</bold> section of the platform is where you’ll set up these alerts. These notifications will be visible on your dashboard."
            values={{ bold: (text) => <b>{text}</b> }}
          />,
        ]}
        faqs={<FormattedMessage id="platform_notification_faqs" defaultMessage="FAQs" />}
        objects={[
          {
            header: (
              <FormattedMessage
                id="platform_notifications_question_one"
                defaultMessage="How do I set up a new notification?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_notifications.item_q1_first"
                defaultMessage="Navigate to <bold>Notifications</bold> on the main menu. Ensure your desired project is selected in the top light blue ribbon (next to your user details)."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_second"
                defaultMessage="Click <bold>Add Notification</bold>. The notification settings will appear. Click the dropdown arrow next to <bold>Select Container</bold> and click the container you'd like to create the notification for. "
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_third"
                defaultMessage="Use the dropdown arrow to <bold>Select the Condition</bold> you want to use to trigger the alarm. You can select from:"
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <ListStyled
                items={[
                  <FormattedMessage
                    id="platform_notifications_item_q1_fourth"
                    defaultMessage="Fill-level <higher></higher> higher than X"
                    values={{ higher: () => <b>&gt;</b> }}
                  />,
                  <FormattedMessage
                    id="platform_notifications_item_q1_fifth"
                    defaultMessage="Fill-level <lower></lower> lower than X"
                    values={{ lower: () => <b>&lt;</b> }}
                  />,
                  <FormattedMessage
                    id="platform_notifications_item_q1_sixth"
                    defaultMessage="Temperature <higher></higher> higher than X "
                    values={{ higher: () => <b>&gt;</b> }}
                  />,
                  <FormattedMessage
                    id="platform_notifications_item_q1_seventh"
                    defaultMessage="Temperature <lower></lower> lower than X "
                    values={{ lower: () => <b>&lt;</b> }}
                  />,
                  <FormattedMessage
                    id="platform_notifications_item_q1_eigth"
                    defaultMessage="<cursor>(where X is the threshold you’ll set in the next step)</cursor>"
                    values={{ cursor: (text) => <em>{text}</em> }}
                  />,
                ]}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_nineth"
                defaultMessage="Select the <bold>Threshold</bold> that the condition has to satisfy for you to receive a notification. For example, you may want to create a notification for a specific container, where you want to be alerted if the container fill-level gets higher than 75%."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_twelth"
                defaultMessage="You may also choose to type a short <bold>description</bold> for your notification, but this is optional."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_thirteenth"
                defaultMessage="You will now select the users that should receive the notification for the condition you created."
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_tenth"
                defaultMessage="You can select the <bold>User</bold> from your existing list of platform users by using the drop-down menu. Simply click on the name in the list, then the <icon></icon>  icon to confirm. If you have multiple users that should receive the notification, search for another name in the drop-down list of users and again, click the <icon></icon> icon."
                values={{
                  bold: (text) => <b>{text}</b>,
                  icon: () => <AddCircleIcon className={classes.addIconStyle} />,
                }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_eleventh"
                defaultMessage="If you select someone by mistake, just click <bold>the trash can</bold> <deleteIcon></deleteIcon> icon to the right of their name to remove them."
                values={{
                  bold: (text) => <b>{text}</b>,
                  icon: () => <AddCircleIcon className={classes.addIconStyle} />,
                  deleteIcon: () => <DeleteIcon className={classes.iconStyle} />,
                }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_fourteenth"
                defaultMessage="If you want someone who isn't a platform user to receive notifications, simply enter their email address under the <bold> Email </bold> section, then click the <icon></icon> icon to confirm."
                values={{
                  bold: (text) => <b>{text}</b>,
                  icon: () => <AddCircleIcon className={classes.addIconStyle} />,
                  deleteIcon: () => <DeleteIcon className={classes.iconStyle} />,
                }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q1_fifteenth"
                defaultMessage="Once you are happy with the conditions and have selected the users that will receive notifications, click <bold>Save</bold>. You will see a blue ribbon with the message <bold>Notification setting successfully saved</bold>."
                values={{
                  bold: (text) => <b>{text}</b>,
                  icon: () => <AddCircleIcon className={classes.addIconStyle} />,
                  deleteIcon: () => <DeleteIcon className={classes.iconStyle} />,
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_notifications_question_two"
                defaultMessage="How do I edit a notification?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_notifications_item_q2_first"
                defaultMessage="To edit a notification, click the <bold>pencil icon</bold>  <icon></icon> to the right of the notification you wish to edit. The same options you used to set up the notification will appear. Make any necessary edits and click Save to confirm your changes."
                values={{
                  bold: (text) => <b>{text}</b>,
                  icon: () => <EditIcon className={classes.iconStyle} />,
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_notifications_question_third"
                defaultMessage="How do I delete a notification?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_notifications_item_q3_first"
                defaultMessage="To delete a notification, click the <bold>trash can icon</bold> <icon></icon> to the right of the notification you wish to delete. A message will appear asking if you are sure you want to delete this notification, click <bold>yes</bold>."
                values={{
                  bold: (text) => <b>{text}</b>,
                  icon: () => <DeleteIcon className={classes.iconStyle} />,
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_notifications_question_four"
                defaultMessage="Can I send the notifications to multiple users?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_notifications_item_q4_first"
                defaultMessage="Yes. When you set up the notification, select the user’s name from the drop-down list (or type in the email address of the person you want the notification to go to) and then click the <icon></icon> icon to add them to the list of users who will receive the notification. You can repeat this process for multiple users."
                values={{
                  icon: () => <AddCircleIcon className={classes.addIconStyle} />,
                }}
              />,
              <FormattedMessage
                id="platform_notifications_item_q4_second"
                defaultMessage="If you have an existing notification, the process is the same. Just click the <bold>pencil icon</bold> <icon></icon>  to the right of the notification you wish to add users to. The same options you used to set up the notification will appear. Add the users you would like to receive the notification and click <bold>Save</bold> to confirm changes."
                values={{
                  bold: (text) => <b>{text}</b>,
                  icon: () => <EditIcon className={classes.iconStyle} />,
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_notifications_question_fifth"
                defaultMessage="Which conditions can I create notifications for?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_notifications_item_q5_first"
                defaultMessage="Currently, you can create notifications based on fill-level and temperature of the containers. Keep your eyes peeled for upcoming updates to the platform as you’ll be able to create notifications for location (stolen bins), container movements, frequency of data transmissions, and activity in the container."
              />,
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default NotificationPlatform;
