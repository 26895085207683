import React, { useCallback, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, TableBody, TableContainer } from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import { toastifyError } from '../utils';
import LoadingLayout from '../loading';
import FilterRow from './filterRow';
import LogoContainer from '../logo/logo';
import { StyledTableCell, StyledTableRow } from './styledTableComponents';
import ConfirmDeletionDialog from '../dialog/ConfirmDeletionDialog';
import StyledTablePagination from './pagination';
import ActionButton from './actionButton';
import ActionMenu from './actionMenu';
import useLinkHoverStyle from '../link/link';
import EmptyResults from './emptyResults';
import StyledEnhancedTableHead from './tableHead';
import StyledCheckbox from '../inputs/StyledCheckbox';
import { SIZES } from '../../main/routes/fleet-management/components/utils';
import StyledImgSelect from '../inputs/StyledImgSelect';
import ConditionalScrollbar from '../conditionalScrollabar/ConditionalScrollbar';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    whiteSpace: 'normal',
    flexDirection: 'column',
  },
  tabsContainer: {
    display: 'flex',
    width: '100%',
    height: 44,
  },
  root: {
    backgroundColor: theme.variables.cWhite,
    minHeight: '100%',
    position: 'relative',
  },
  linkTableItem: {
    textDecoration: 'none',
    color: theme.variables.cRichOcean,
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  select: {
    textAlign: 'center',
  },
  actionMenuCellRoot: {
    padding: 0,
    paddingRight: 16,
  },
}));

const ReusableTable = React.forwardRef(
  (
    {
      extraFilter,
      queryExtraVariables,
      cellsConfig,
      deleteItemMutation,
      query,
      autocompleteSerializer,
      autoCompleteQuery,
      itemsSerializer,
      updateCache,
      chooseLogoSize,
      noLogoLink,
      userLayoutConfig,
      updateUserConfig,
      tableTabs,
      searchSize,
      noCheckboxes,
      tableContainerStyles,
      tableComponentWrapperStyles,
      multiSelectable,
      selectTableRowCallback,
      activeTableColumns,
      paginationClass,
      excludeEditOption,
      setSearchString,
      tableFontSize,
      isSearchAutocomplete,
      noActionMenu,
      queryName,
      selectCheckboxCallback,
      getListCallback,
      customActionMenu,
      additionalActionMenu,
      selectedRowDetails,
      leftSideHeaderElements,
      searchFieldClass,
      noRowSelection,
      displayScrollbar,
      tableContainerClass,
      allRowsEnabled,
      isRedirectOnSelect,
      objectNameKey,
      generateButton,
      freezeAutocompleteInputForASecond,
      excludeDeleteOption,
    },
    ref,
  ) => {
    const match = useRouteMatch();
    const classes = useStyles();
    const intl = useIntl();
    const setLinkHoverStyle = useLinkHoverStyle();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [deletingItem, setDeletingItem] = useState(null);
    const [selected, setSelected] = useState([]);
    const [selectedDetails, setSelectedDetails] = useState();
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(true);
    const { order, orderBy, iconsSize, pageSize } = userLayoutConfig;
    const location = useLocation();

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      updateUserConfig({
        ...userLayoutConfig,
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      });
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        // Abstract value for backed realization select all
        setSelected(['*']);
        return;
      }
      setSelected([]);
    };
    const isAllSelected = selected?.indexOf('*') !== -1;
    const isSelected = (id) => selected.indexOf(id) !== -1 || isAllSelected;
    const isDetailsSelected = (id) => selectedDetails === id;

    const queryFilter = useMemo(() => (queryString.parse(location.search) || {}).query, [
      location.search,
    ]);
    const realPageSize = pageSize > 0 ? pageSize : null;
    const offset = realPageSize > 0 ? realPageSize * page : null;

    const queryVariables = useMemo(
      () => ({
        name: queryFilter,
        ...queryExtraVariables,
        pageSize: realPageSize,
        offset,
        orderBy,
        order,
      }),
      [queryFilter, queryExtraVariables, realPageSize, offset, orderBy, order],
    );

    const variablesForAutocomplete = useMemo(() => {
      const { name, ...rest } = queryVariables;
      return rest;
    }, [queryVariables]);

    useEffect(() => {
      setPage(0);
    }, [queryExtraVariables]);

    useEffect(() => {
      selectCheckboxCallback(selected);
    }, [selectCheckboxCallback, selected]);

    const { loading: itemsLoading, data: itemsData = {}, refetch } = useQuery(query, {
      onError: toastifyError,
      suspend: false,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first',
      variables: queryVariables,
      onCompleted: (data) => {
        setSelectedDetails(data[queryName].edges[0]?.node?.id || '');
        selectTableRowCallback(data[queryName].edges[0]?.node?.id);
        getListCallback(data[queryName].edges);
        setMenuAnchorEl(null);
      },
    });

    useEffect(() => {
      if (selectedRowDetails) {
        setSelectedDetails(selectedRowDetails);
      }
    }, [selectedRowDetails]);

    const { totalCount, items } = useMemo(() => itemsSerializer(itemsData), [
      itemsData,
      itemsSerializer,
    ]);

    const handleClick = (event, id) => {
      event.stopPropagation();
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
      if (!multiSelectable) {
        setSelected([id]);
        return;
      }

      if (selected.indexOf('*') === 0) {
        const selectedIndexes = [];
        items.forEach((tableItem) => tableItem.id !== id && selectedIndexes.push(tableItem.id));
        newSelected = selectedIndexes;
      } else if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    };

    const changeAnchorEl = (event = {}, item = null) => {
      setMenuAnchorEl(event.currentTarget || null);
      setActiveItem(item);
    };

    const deleteHandle = () => {
      setDeletingItem(activeItem);
    };

    const refetchQuery = useCallback(() => {
      refetch(queryVariables);
    }, [refetch, queryVariables]);

    useEffect(() => {
      if (!first) {
        refetchQuery().catch(toastifyError);
        setFirst(false);
      }
    }, [refetchQuery, first]);

    const confirmDeleteDepot = () => {
      deleteItemMutation({
        variables: { selfId: deletingItem.id },
        update: () => updateCache().then(refetchQuery).catch(toastifyError),
      })
        .then(() =>
          toast.info(
            intl.formatMessage(
              {
                id: 'listPage.delete.success',
                defaultMessage: 'Item {name} successfully removed',
              },
              { name: objectNameKey ? deletingItem[objectNameKey] : deletingItem.name },
            ),
          ),
        )
        .catch(toastifyError)
        .finally(() => setDeletingItem(null));
    };

    return (
      <div className={classes.root} style={tableComponentWrapperStyles}>
        <Grid container>
          <LoadingLayout isLoading={itemsLoading} />
          <FilterRow
            freezeAutocompleteInputForASecond={freezeAutocompleteInputForASecond}
            query={autoCompleteQuery}
            serializer={autocompleteSerializer}
            queryExtraVariables={variablesForAutocomplete}
            searchSize={searchSize}
            paginationClass={paginationClass}
            setSearchString={setSearchString}
            isSearchAutocomplete={isSearchAutocomplete}
            isRedirectOnSelect={isRedirectOnSelect}
            iconSize={
              chooseLogoSize ? (
                <StyledImgSelect
                  valuesList={SIZES}
                  value={iconsSize}
                  onChange={(e) =>
                    updateUserConfig({ ...userLayoutConfig, iconsSize: e.target.value })
                  }
                  bkgdStyle="cAntiFlashWhite"
                />
              ) : null
            }
            pagination={
              <>
                <StyledTablePagination
                  page={page}
                  generateButton={generateButton}
                  totalCount={totalCount}
                  rowsPerPage={pageSize}
                  setPage={setPage}
                  allRowsEnabled={allRowsEnabled}
                  setRowsPerPage={(newPageSize) =>
                    updateUserConfig({
                      ...userLayoutConfig,
                      pageSize: newPageSize,
                    })
                  }
                />
              </>
            }
            leftSideElements={leftSideHeaderElements}
            searchFieldClass={searchFieldClass}
            haveGenerateButton={!!generateButton}
          >
            {extraFilter}
          </FilterRow>
          <TableContainer
            className={classNames([classes.container, tableContainerClass])}
            style={tableContainerStyles}
          >
            {tableTabs && <Grid className={classes.tabsContainer}>{tableTabs}</Grid>}
            <ConditionalScrollbar displayScrollbar={displayScrollbar} ref={ref}>
              <Table stickyHeader className="h-100" aria-label="location list">
                <StyledEnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  classes={classes}
                  onRequestSort={handleRequestSort}
                  numSelected={isAllSelected ? totalCount : selected.length}
                  rowCount={totalCount}
                  onSelectAllClick={handleSelectAllClick}
                  cellsConfig={cellsConfig}
                  noCheckboxes={noCheckboxes}
                  tableFontSize={tableFontSize}
                />
                <TableBody>
                  {items.map((item, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const isItemSelected = isSelected(item.id);
                    return (
                      <StyledTableRow
                        key={item.id}
                        name={item.id}
                        onClick={(e) => {
                          if (!noRowSelection) {
                            e.stopPropagation();
                            setSelectedDetails(item.id);
                            selectTableRowCallback(item.id);
                          }
                        }}
                        role="checkbox"
                        aria-checked={isDetailsSelected(item.id)}
                        tabIndex={-1}
                        selected={isDetailsSelected(item.id)}
                      >
                        {!noCheckboxes && (
                          <StyledTableCell padding="checkbox">
                            <StyledCheckbox
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, item.id)}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </StyledTableCell>
                        )}
                        {item.name && (
                          <StyledTableCell padding="none">
                            {!noLogoLink ? (
                              <Link
                                to={`${match.url}/${item?.id}`}
                                className={setLinkHoverStyle.linkTableItem}
                              >
                                <LogoContainer
                                  variant={iconsSize}
                                  src={item.logo}
                                  name={item.name}
                                />
                                <span className={setLinkHoverStyle.linkHover}>{item.name}</span>
                              </Link>
                            ) : (
                              <>{item.name}</>
                            )}
                          </StyledTableCell>
                        )}
                        {cellsConfig
                          .filter(({ id }) =>
                            activeTableColumns.some((activeColumn) => activeColumn === id),
                          )
                          .filter(({ id }) => !['name'].includes(id))
                          .map(({ id, className }) =>
                            id !== 'actionMenu' ? (
                              <StyledTableCell
                                key={id}
                                style={{ fontSize: tableFontSize }}
                                className={className}
                              >
                                {item[id]?.slice ? item[id]?.slice(0, 250) : item[id]}
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell
                                key={item.id}
                                classes={{
                                  root: classes.actionMenuCellRoot,
                                }}
                              >
                                {!noActionMenu && (
                                  <ActionButton
                                    aria={menuAnchorEl ? 'location-context-menu' : null}
                                    onClick={(event) => changeAnchorEl(event, item)}
                                  />
                                )}
                              </StyledTableCell>
                            ),
                          )}
                      </StyledTableRow>
                    );
                  })}
                  {!items.length && !itemsLoading && <EmptyResults />}
                </TableBody>
              </Table>
            </ConditionalScrollbar>
          </TableContainer>
          <ActionMenu
            anchorElement={menuAnchorEl}
            id={activeItem?.id}
            changeAnchor={changeAnchorEl}
            onDelete={() => {
              changeAnchorEl();
              deleteHandle(activeItem);
            }}
            excludeEditOption={excludeEditOption}
            customActionMenu={customActionMenu}
            excludeDeleteOption={excludeDeleteOption}
            additionalActionMenu={additionalActionMenu}
          />
          <ConfirmDeletionDialog
            open={!!deletingItem?.id}
            onConfirm={() => confirmDeleteDepot()}
            onClose={() => setDeletingItem(null)}
            title={deletingItem?.name || ''}
            helpText={intl.formatMessage({
              id: 'help_text.remove_item',
              defaultMessage: 'It will remove item from your project!',
            })}
          />
        </Grid>
      </div>
    );
  },
);

ReusableTable.propTypes = {
  extraFilter: PropTypes.element,
  queryExtraVariables: PropTypes.objectOf(PropTypes.any),
  deleteItemMutation: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.any).isRequired,
  autocompleteSerializer: PropTypes.func,
  autoCompleteQuery: PropTypes.objectOf(PropTypes.any).isRequired,
  itemsSerializer: PropTypes.func.isRequired,
  cellsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      noFilter: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ).isRequired,
  updateCache: PropTypes.func,
  chooseLogoSize: PropTypes.bool,
  noLogoLink: PropTypes.bool,
  updateUserConfig: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string,
  }),
  allRowsEnabled: PropTypes.bool,
  queryName: PropTypes.string.isRequired,
  selectTableRowCallback: PropTypes.func.isRequired,
  tableTabs: PropTypes.element,
  searchSize: PropTypes.number,
  noCheckboxes: PropTypes.bool,
  tableContainerStyles: PropTypes.objectOf(PropTypes.string),
  tableComponentWrapperStyles: PropTypes.objectOf(PropTypes.string),
  multiSelectable: PropTypes.bool,
  activeTableColumns: PropTypes.arrayOf(PropTypes.string),
  paginationClass: PropTypes.string,
  excludeEditOption: PropTypes.bool,
  setSearchString: PropTypes.func,
  tableFontSize: PropTypes.number,
  isSearchAutocomplete: PropTypes.bool,
  noActionMenu: PropTypes.bool,
  selectCheckboxCallback: PropTypes.func,
  getListCallback: PropTypes.func,
  customActionMenu: PropTypes.node,
  additionalActionMenu: PropTypes.arrayOf(PropTypes.node),
  selectedRowDetails: PropTypes.string,
  leftSideHeaderElements: PropTypes.node,
  searchFieldClass: PropTypes.string,
  noRowSelection: PropTypes.bool,
  displayScrollbar: PropTypes.bool,
  tableContainerClass: PropTypes.string,
  isRedirectOnSelect: PropTypes.bool,
  objectNameKey: PropTypes.string,
  generateButton: PropTypes.element,
  freezeAutocompleteInputForASecond: PropTypes.bool,
  excludeDeleteOption: PropTypes.bool,
};

ReusableTable.defaultProps = {
  extraFilter: null,
  queryExtraVariables: {},
  chooseLogoSize: true,
  noLogoLink: false,
  userLayoutConfig: {
    iconsSize: null,
  },
  updateCache: async () => {
    window.console.debug('Cache update function not provided');
  },
  tableTabs: null,
  searchSize: null,
  noCheckboxes: false,
  tableContainerStyles: null,
  tableComponentWrapperStyles: {},
  multiSelectable: true,
  activeTableColumns: [],
  excludeEditOption: false,
  setSearchString: () => {},
  tableFontSize: 18,
  paginationClass: '',
  isSearchAutocomplete: true,
  noActionMenu: false,
  selectCheckboxCallback: () => {},
  autocompleteSerializer: () => [],
  getListCallback: () => {},
  customActionMenu: null,
  additionalActionMenu: null,
  selectedRowDetails: null,
  leftSideHeaderElements: null,
  searchFieldClass: null,
  noRowSelection: false,
  displayScrollbar: false,
  tableContainerClass: null,
  allRowsEnabled: true,
  isRedirectOnSelect: true,
  objectNameKey: '',
  generateButton: null,
  freezeAutocompleteInputForASecond: false,
  excludeDeleteOption: false,
};

export default ReusableTable;
