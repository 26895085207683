import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import Settings from '../../../../../../shared/utils/settings';

import BlackCrossIcon from '../../../../../../../images/icons/blackCross.svg';
import TrashIcon from '../../../../../../../images/icons/trash.svg';
import GoToWateStationIcon from '../../../../../../../images/icons/goToWateStation.svg';
import Address from '../../../../../../shared/google/location';

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: 20,
    lineHeight: '30px',
    color: theme.variables.cEmperor,
    marginBottom: 32,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentWrapper: {
    marginBottom: 50,
    marginLeft: 30,
    marginRight: 30,
  },
  listHeaderText: {
    color: theme.variables.cSlate,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  listItemText: {
    color: theme.variables.cEmperor,
    fontSize: '16px',
    lineHeight: '20px',
    borderRadius: '15.5px',
    padding: '6px 16px',
    backgroundColor: theme.variables.cAntiFlashWhite,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const PickupDetailsModal = ({
  isPickupTimesModaOpen,
  setIsPickupTimesModaOpen,
  selectedRouteStops,
  projectId,
  settings,
}) => {
  const classes = useStyles();
  const closeModal = () => setIsPickupTimesModaOpen(false);
  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="simple-dialog-title"
      open={isPickupTimesModaOpen}
      maxWidth="sm"
      scroll="body"
    >
      <DialogContent className={classes.dialogBody}>
        <div className={classes.closeButtonContainer}>
          <Button onClick={closeModal}>
            <img src={BlackCrossIcon} alt="exit" />
          </Button>
        </div>
        <div className={classes.titleText}>
          <FormattedMessage id="pickup_times" defaultMessage="Pickup times" />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.infoRow}>
            <span className={classes.listHeaderText}>
              <FormattedMessage
                id="location"
                defaultMessage="Location"
                className={classes.listHeaderText}
              />
            </span>
            <span className={classes.listHeaderText}>
              <FormattedMessage id="pickup_time" defaultMessage="Pickup time" />
            </span>
          </div>
          {selectedRouteStops?.map((stop, stopIndex) => (
            <div className={classes.infoRow} key={stop.node.id}>
              <div className={classes.listItemText} style={{ width: '100%' }}>
                <div>
                  {stopIndex}
                  {(stop.node.depot || stop.node.container) && (
                    <img
                      src={stop.node.container ? TrashIcon : GoToWateStationIcon}
                      alt="trash"
                      style={{ marginLeft: 4, marginRight: 4 }}
                    />
                  )}
                  {stop.node.depot?.name || stop.node.container?.containerId || '-'}
                </div>
                <div style={{ marginLeft: 16 }}>
                  {stop.node.depot ? (
                    <Address
                      placeId={stop.node.depot?.location?.placeId}
                      lng={stop.node.depot?.location?.longitude}
                      lat={stop.node.depot?.location?.latitude}
                    />
                  ) : (
                    <Address
                      placeId={stop.node.container?.location?.placeId}
                      lng={stop.node.container?.location?.longitude}
                      lat={stop.node.container?.location?.latitude}
                    />
                  )}
                </div>
              </div>
              <div
                className={classes.listItemText}
                style={{ display: 'flex', alignItems: 'center', marginLeft: 48 }}
              >
                {settings[projectId]?.getTime(stop.node.scheduledDriverArrival) || (
                  <FormattedMessage id="not_set" defaultMessage="Not set" />
                )}
              </div>
            </div>
          ))}
          {!selectedRouteStops?.length && (
            <div>
              <FormattedMessage id="no_data" defaultMessage="No data" />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

PickupDetailsModal.defaultProps = {
  selectedRouteStops: [],
  projectId: '',
};

PickupDetailsModal.propTypes = {
  isPickupTimesModaOpen: PropTypes.bool.isRequired,
  setIsPickupTimesModaOpen: PropTypes.func.isRequired,
  settings: PropTypes.objectOf(PropTypes.instanceOf(Settings).isRequired).isRequired,
  selectedRouteStops: PropTypes.arrayOf(
    PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            scheduledDriverArrival: PropTypes.string,
            direction: PropTypes.shape({
              destination: PropTypes.shape({
                latitude: PropTypes.number,
                longitude: PropTypes.number,
              }),
            }),
            depot: PropTypes.objectOf(PropTypes.string),
            container: PropTypes.shape({
              containerId: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  ),
  projectId: PropTypes.string,
};

export default PickupDetailsModal;
