import PropTypes from 'prop-types';
import { DateRangeFilterPropTypes } from '../inputs/DateRangePicker';

export const PeriodFilterPropTypes = {
  period: PropTypes.string.isRequired,
  setPeriod: PropTypes.func.isRequired,
  ...DateRangeFilterPropTypes,
};

export const SelectorOption = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  groupBy: PropTypes.string,
});

export const FractionAnalyticsType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  wasteType: PropTypes.string.isRequired,
  collectedM3: PropTypes.number.isRequired,
  collectedKg: PropTypes.number.isRequired,
});
