import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import './scss/styles.global.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import './scss/styles.mapbox.scss';
import 'antd/dist/antd.css';
import 'typeface-roboto';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import client from './components/graphql/apollo';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

ReactDOM.render(
  // eslint-disable-next-line  react/jsx-filename-extension
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById('main'),
);
serviceWorker.unregister();
