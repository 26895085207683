import React from 'react';
import {
  Table,
  TableBody,
  Grid,
  TableContainer,
  TableCell,
  Paper,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useStyles from '../../shared/styles';

const TechnicalSpecifications = () => {
  const classes = useStyles();
  const intl = useIntl();
  function createData(title, text) {
    return {
      title,
      text,
    };
  }
  const rows = [
    createData(
      intl.formatMessage({ id: 'technical_specifications_text_first', defaultMessage: 'Material' }),
      intl.formatMessage({ id: 'technical_specifications_text_second', defaultMessage: 'ABS' }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_third',
        defaultMessage: 'Dimensions',
      }),
      intl.formatMessage({
        id: 'technical_specifications_text_fourth',
        defaultMessage: '112*41*51 mm',
      }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_fifth',
        defaultMessage: 'Battery life',
      }),
      intl.formatMessage({ id: 'technical_specifications_text_sixth', defaultMessage: '10 years' }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_seventh',
        defaultMessage: 'Temperature range',
      }),
      intl.formatMessage({
        id: 'technical_specifications_text_eigth',
        defaultMessage: '(-20 to +70°C)',
      }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_nineth',
        defaultMessage: 'Water resistance',
      }),
      intl.formatMessage({ id: 'technical_specifications_text_tenth', defaultMessage: 'IP67' }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_eleventh',
        defaultMessage: 'Detection range',
      }),
      intl.formatMessage({
        id: 'technical_specifications_text_twelveth',
        defaultMessage: '0–400cm',
      }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_thirteenth',
        defaultMessage: 'Measurement type',
      }),
      intl.formatMessage({
        id: 'technical_specifications_text_fourteenth',
        defaultMessage: 'Optical',
      }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_twenty',
        defaultMessage: 'Measurement Interval',
      }),
      intl.formatMessage({
        id: 'technical_specifications_text_fourtheenth',
        defaultMessage: 'Configurable (hourly/daily, etc.)',
      }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_fifteenth',
        defaultMessage: 'Connectivity',
      }),
      intl.formatMessage({
        id: 'technical_specifications_text_sixteenth',
        defaultMessage: 'GSM, LTE-M, NB-IoT, Sigfox, LoRaWAN',
      }),
    ),
    createData(
      intl.formatMessage({
        id: 'technical_specifications_text_seventeenth',
        defaultMessage: 'GPS',
      }),
      intl.formatMessage({ id: 'technical_specifications_text_eighteenth', defaultMessage: 'Yes' }),
    ),
  ];
  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          Technical specifications
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            style={{
              minWidth: 500,
            }}
            aria-label="simple table"
          >
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" className={classes.tableCells}>
                    {row.title}
                  </TableCell>
                  <TableCell align="left" scope="row">
                    {row.text}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TechnicalSpecifications;
