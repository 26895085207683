import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const useStyles = makeStyles({
  searchBox: {
    width: '100%',
    marginRight: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
});

const SearchPlaces = ({ onLocationSelect, clickedAddress, mapApi }) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const getDetailsCallback = (place, status) => {
    if (status === 'OK') {
      onLocationSelect(place);
    }
  };

  const retriveGoogleAPIResult = (placeId) => {
    if (mapApi && mapApi.map && mapApi.maps) {
      const { map, maps } = mapApi;
      const service = new maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId,
        },
        getDetailsCallback,
      );
    }
  };

  const handleOnChange = (val) => {
    if (val?.value?.place_id) {
      const {
        value: { place_id: placeId },
      } = val;
      retriveGoogleAPIResult(placeId);
    }
    setValue(val);
  };

  useEffect(() => {
    if (clickedAddress) {
      setValue({ label: clickedAddress, value: { place_id: '' } });
    }
  }, [clickedAddress]);

  return (
    <>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        selectProps={{
          placeholder: 'Search',
          className: classes.searchBox,
          isClearable: true,
          value,
          onChange: handleOnChange,
        }}
      />
    </>
  );
};

SearchPlaces.propTypes = {
  onLocationSelect: PropTypes.func.isRequired,
  clickedAddress: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  mapApi: PropTypes.object.isRequired,
};

SearchPlaces.defaultProps = {
  clickedAddress: '',
};

export default SearchPlaces;
