import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pin: {
    backgroundColor: 'red',
    borderRadius: '50%',
    padding: 16,
    zIndex: ({ showPopup }) => (showPopup ? 5 : 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.variables.cWhite,
    height: '18px',
  },
}));

const RouteStopMarker = ({ pointCount }) => {
  const classes = useStyles();

  return <div className={classes.pin}>{pointCount}</div>;
};

RouteStopMarker.propTypes = {
  pointCount: PropTypes.number.isRequired,
};

export default RouteStopMarker;
