import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '0 !important',
    backgroundColor: theme.variables.cAquaHaze,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.14)',
    height: '60px',
  },
  mainContainer: {
    padding: '16px 32px 16px 14px',
    backgroundColor: '#fafafa',
  },
  headerTitle: {
    fontSize: '16px',
    color: theme.variables.cCharcoal,
    fontWeight: 'bold',
    marginLeft: '32px',
    marginRight: '59px',
    lineHeight: '60px',
  },
  dateTabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  dateTab: {
    '&.Mui-selected': {
      backgroundColor: '#ccc',
    },
    opacity: 1,
    minWidth: 0,
    fontSize: '14px',
    fontWeight: 500,
    color: '#474e62',
    width: '100px',
    height: '36px',
    padding: 0,
    minHeight: 0,
    borderRadius: '4px',
    backgroundColor: theme.variables.cAntiFlashWhite,
    marginRight: '4px',
    textTransform: 'none',
  },
  costSettingsButton: {
    borderRadius: 32.5,
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.14)',
    margin: '0px 4px 10px 15px',
    height: '40px',
    width: '155px',
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  dataTypeTabs: {
    '& > div': {
      '& > .MuiTabs-indicator': {
        top: 'inherit',
        bottom: 0,
        backgroundColor: theme.variables.cOrange,
        height: '2',
      },
      textAlign: 'right',
      '& > .MuiTabs-flexContainer': {
        justifyContent: 'flex-end',
      },
    },
    marginRight: '19px',
  },
  dataTypeTab: {
    minWidth: 0,
    textTransform: 'none',
    fontSize: '12px',
    color: theme.variables.cSlate,
    fontWeight: 'bold',
  },
  topInfoTitle: {
    fontSize: '16px',
    color: theme.variables.cDarkBlue,
    borderBottom: '2px solid #ffa62d',
    paddingBottom: '5px',
    fontWeight: 'bold',
    display: 'inline-block',
    margin: '24px 0px 9px 24px',
  },
  toggleButton: {
    position: 'fixed',
    bottom: '46px',
    paddingRight: '24px',
    right: '16px',
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.variables.VHBlue,
    height: '44px',
    borderRadius: '22px',
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 1px rgba(2, 69, 89, 0.5)',
    opacity: 0.5,
    zIndex: 999999999,
  },
  arrowTop: {
    transform: 'rotate(-180deg)',
  },
  infoValue: {
    fontSize: '24px',
    color: theme.variables.cDarkBlue,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '5px',
    marginTop: '15px',
  },
  infoLabel: {
    fontSize: '13px',
    color: '#cIndependence',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
    maxWidth: '70%',
    margin: 'auto auto 20px auto',
  },
  bottomInfoBlock: {
    backgroundColor: '#fff',
    border: '5px solid #fafafa',
  },
  bottomInfoBlockPadding: {},
  infoSubBlock: {
    borderRight: '1px solid #fafafa',
    textAlign: 'center',
  },
  chartBlock: {
    marginRight: '32px',
    position: 'relative',
    height: '23vh',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  chartInfoBlock: {
    padding: '12px 24px 15px 24px',
    height: '100%',
    backgroundColor: '#fff',
    textAlign: 'center',
    border: '5px solid #fafafa',
  },
  chartBlockTitle: {
    fontSize: '14px',
    color: theme.variables.cDarkBlue,
    fontWeight: 'bold',
  },
  periodText: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: theme.variables.cSlate,
    marginTop: 0,
  },
  periodValue: {
    fontSize: '16px',
    color: theme.variables.cDarkBlue,
    fontWeight: 'bold',
    margin: 0,
  },
  analyticsTableContainer: {
    height: '90vh',
  },
  expandIcon: {
    marginLeft: 8,
  },
  costOverviewTableContainer: {
    paddingLeft: '24px',
    paddingRight: '19px',
  },
  tableContainer: {
    paddingLeft: '20px',
    paddingRight: '10px',
    marginBottom: '5px',
    minHeight: '41vh',
  },
  tableFilterContainer: {
    maxHeight: '65px',
  },
  wrapDescription: {
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  searchField: {
    padding: 0,
  },
  selectFilter: {
    padding: '0px 12px',
    minWidth: 0,
    width: 'auto',
    maxWidth: '155px',
    '& .MuiInput-input, & .MuiInputBase-input': {
      '&.MuiSelect-select': {
        padding: '6px 35px 6px 15px',
      },
    },
  },
  container: {
    display: 'flex',
    whiteSpace: 'normal',
    flexDirection: 'column',
    height: 'calc(100% - 65px)',
  },
  root: {
    backgroundColor: theme.variables.cWhite,
    flexWrap: 'unset',
  },
  pageTitle: {
    textAlign: 'center',
    marginTop: 40,
  },
  absoluteCentered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'none',
      width: '60% !important',
      height: '90%',
    },
  },
  dialogTitle: {
    padding: '0',
  },
  dialogContainer: {
    padding: '25px 12px 0px 59px',
  },
  settingsHeader: {
    backgroundColor: '#e58e3e',
    position: 'relative',
  },
  costSettingsTitle: {
    fontSize: '16px',
    color: '#ffffff',
    lineHeight: '24px',
  },
  closeDialogIcon: {
    height: '24px',
    width: '24px',
    color: theme.variables.cEmperor,
    fontWeight: 'bold',
  },
  closeDialogButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  costSettingsSubtitle: {
    fontSize: '12px',
    color: '#4e4e4e',
    fontWeight: 'bold',
  },
  inputLabel: {
    fontSize: '12px !important',
    fontWeight: 'bold !important',
    color: `#9196a5 !important`,
  },
  switchLabel: {
    fontSize: '12px',
    color: '#4e4e4e',
    fontWeight: 500,
  },
  fieldClass: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
    '& .MuiSelect-root': {
      padding: '7px 0 7px 23px',
      fontSize: '12px',
      color: '#4e4e4e',
      '& span': {
        fontSize: '12px',
        lineHeight: '20px',
        color: '#4e4e4e',
      },
    },
  },
  addCombinationButton: {
    borderRadius: '22px',
    border: 'solid 1px #024559',
    padding: '6px 32px',
  },
  addCombinationButtonBlock: {
    textAlign: 'right',
  },
  submitBtnText: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textTransform: 'none',
    color: theme.variables.VHBlue,
  },
  formField: {
    '& input': {
      fontSize: '12px',
      height: '24px',
      padding: '5px 16px 5px 16px !important',
    },
    '& .MuiInputBase-root': {
      marginTop: '0',
    },
  },
  formLabel: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#9196a5',
    lineHeight: '32px',
    display: 'inline-flex',
    width: 'max-content',
  },
  helpIcon: {
    marginBottom: '10px',
    marginRight: '10px',
  },
  adornment: {
    '& .MuiTypography-root': {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#4e4e4e',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  chartCenterElement: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.variables.cDarkBlue,
  },
  disabledBlock: {
    opacity: 0.5,
  },
  comparisonDescriptionText: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#4e4e4e',
  },
  settingsTabs: {
    '& > div': {
      '& > .MuiTabs-indicator': {
        top: 'inherit',
        bottom: 0,
        backgroundColor: theme.variables.cOrange,
        height: '2',
      },
      textAlign: 'right',
      '& > .MuiTabs-flexContainer': {},
    },
    width: '100%',
  },
  settingsTab: {
    textTransform: 'none',
    fontSize: '14px',
    color: theme.variables.cDarkBlue,
    fontWeight: 'bold',
    maxWidth: 'none',
    width: '50%',
  },
  divider: {
    border: '2px solid #eff5f7',
  },
  settingsTableButton: {
    minWidth: 0,
    padding: '0 5px',
    margin: '0 8px',
    width: 15,
  },
  comparisonPeriodTabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  comparisonPeriodTab: {
    '&.Mui-selected': {
      color: theme.variables.VHOrange,
    },
    opacity: 1,
    minWidth: 0,
    fontSize: '14px',
    fontWeight: 500,
    color: theme.variables.cIndependence,
    height: '36px',
    padding: 0,
    minHeight: 0,
    borderRadius: '4px',
    backgroundColor: theme.variables.cAntiFlashWhite,
    marginRight: '4px',
    textTransform: 'none',
  },
  previousDateTabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  previousDateTab: {
    '&.Mui-selected': {
      backgroundColor: '#ccc',
      color: theme.variables.VHOrange,
    },
    opacity: 1,
    minWidth: 0,
    fontSize: '14px',
    fontWeight: 500,
    color: theme.variables.cIndependence,
    height: '36px',
    minHeight: 0,
    borderRadius: '4px',
    backgroundColor: theme.variables.cAntiFlashWhite,
    marginRight: '4px',
    textTransform: 'none',
    padding: '10px 38px',
  },
  calendarBlock: {
    '& .SingleDatePicker_picker': {
      position: 'static ',
    },
    '& .DayPicker__withBorder': {
      boxShadow: 'none',
    },
    '& .SingleDatePickerInput__withBorder': {
      border: 'none',
    },
    '& .DateRangePickerInput__withBorder': {
      border: 'none',
    },
    '& .DateRangePicker_picker': {
      position: 'static ',
    },
  },
  calculateDataButton: {
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.15)',
    margin: '32px auto',
    height: '40px',
    width: '216px',
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  footer: {
    textAlign: 'center',
  },
  noYMargin: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  combinationError: {
    fontSize: '14px',
    color: 'red',
    marginBottom: '0',
    width: '100%',
  },
  chartButtonMargin: {
    margin: '24px 24px 9px 0px',
  },
  selectedComparisonTabs: {
    margin: '17px 0px 9px 24px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  selectedComparisonTab: {
    '&.Mui-selected': {
      backgroundColor: '#ccc',
    },
    opacity: 1,
    minWidth: 0,
    fontSize: '14px',
    fontWeight: 500,
    color: '#474e62',
    width: '100px',
    height: '36px',
    padding: 0,
    minHeight: 0,
    borderRadius: '4px',
    marginRight: '4px',
    backgroundColor: theme.variables.cAntiFlashWhite,
    textTransform: 'none',
  },
}));

export default useStyles;
