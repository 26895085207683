import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Link } from '@material-ui/core';
import SupportTextPage from '../../shared/supportTextPage';
import useStyles from '../../shared/styles';
import Logo from '../../../../../../images/support/app_support/logos/Logo_only_small.png';
import AppleStoreBadge from '../../../../../../images/support/app_support/logos/app_store_badge.png';
import GooglePlayBadge from '../../../../../../images/support/app_support/logos/google_playstore_badge.png';
import QrCode from '../../../../../../images/support/app_support/logos/QR_Code_App_Install.png';
import ContentHeaderContainer from './components/contentHeaderContainer';
import ImageContainer from './components/imageContainer';

const AppDownload = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container spacing={1} className={classes.root}>
      <SupportTextPage
        title={
          <FormattedMessage
            id="app_install_title"
            defaultMessage="Download WasteHero Install app"
          />
        }
        texts={[
          <FormattedMessage
            id="app_install_content_1"
            defaultMessage="Download the WasteHero Installation app on iOS or Android to remotely activate your sensors."
            values={{ bold: (text) => <b>{text}</b> }}
          />,
        ]}
      />

      {/* WasteHero logo */}
      <ImageContainer imageAlign="left">
        <img
          src={Logo}
          alt={intl.formatMessage({ id: 'wasteHero.logo', defaultMessage: 'WasteHero Logo' })}
        />
      </ImageContainer>
      <Grid item xs={6} align="left" />

      {/* Download Links */}
      <ContentHeaderContainer contentClass={classes.sectionTitle} size={6}>
        <Link href="https://apps.apple.com/au/app/wastehero-installed/id1498769501">
          <FormattedMessage
            id="app_install_content_applestore"
            defaultMessage="Download from the Apple Store."
          />
        </Link>
      </ContentHeaderContainer>
      <ContentHeaderContainer contentClass={classes.sectionTitle} size={6}>
        <Link href="https://play.google.com/store/apps/details?id=com.wastehero_mobileapp_installation">
          <FormattedMessage
            id="app_install_content_googleplay"
            defaultMessage="Download from Google Play."
          />
        </Link>
      </ContentHeaderContainer>
      <ImageContainer>
        <Link href="https://apps.apple.com/au/app/wastehero-installed/id1498769501">
          <img
            src={AppleStoreBadge}
            alt={intl.formatMessage({
              id: 'apple.store_badge',
              defaultMessage: 'Apple Store Badge',
            })}
            className={classes.storeBadgeImage}
          />
        </Link>
      </ImageContainer>
      <ImageContainer>
        <Link href="https://play.google.com/store/apps/details?id=com.wastehero_mobileapp_installation">
          <img
            src={GooglePlayBadge}
            alt={intl.formatMessage({
              id: 'google.play.badge',
              defaultMessage: 'Google Play Badge',
            })}
            className={classes.storeBadgeImage}
          />
        </Link>
      </ImageContainer>

      {/* QR Code */}
      <ContentHeaderContainer contentClass={classes.sectionTitle} size={6}>
        <FormattedMessage
          id="app_install_content_qrCode"
          defaultMessage="Or scan QR code from your smartphone."
        />
      </ContentHeaderContainer>
      <Grid item xs={6} />
      <ImageContainer>
        <img src={QrCode} alt={intl.formatMessage({ id: 'qr.code', defaultMessage: 'QR Code' })} />
      </ImageContainer>
    </Grid>
  );
};

export default AppDownload;
