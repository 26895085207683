import { getIn } from 'formik';

const memoizeFields = (fields) => (prevProps, nextProps) =>
  fields.every((getter) => {
    if (typeof getter === 'string') {
      return prevProps[getter] === nextProps[getter];
    }
    return getter(prevProps) === getter(nextProps);
  });

const formikFieldMemoizeCheck = () => [
  ({ formik, name }) => getIn(formik.errors, name),
  ({ formik, name }) => getIn(formik.values, name),
  ({ formik, name }) => getIn(formik.touched, name),
  ({ formik }) => formik.isSubmitting,
];

const formikComponentMemoizeCheck = (name) => [
  ({ formik }) => getIn(formik.errors, name),
  ({ formik }) => getIn(formik.values, name),
  ({ formik }) => getIn(formik.touched, name),
  ({ formik }) => formik.isSubmitting,
];

const formikComponentMemoizeValueCheck = (name) => ({ formik }) => getIn(formik.values, name);
const formikComponentMemoizeFieldCheck = () => ({ formik, name }) => getIn(formik.values, name);

export {
  memoizeFields,
  formikFieldMemoizeCheck,
  formikComponentMemoizeCheck,
  formikComponentMemoizeValueCheck,
  formikComponentMemoizeFieldCheck,
};
