import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useApolloClient } from '@apollo/client';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { loader } from 'graphql.macro';
import Sidebar from '../layout/sidebar/sidebar';
import LoadingLayout from '../shared/loading';
import { setUser } from '../../actions';
import WsController from '../shared/wsController';
import { CurrentUserContext } from '../shared/utils';
import MainRouter from './mainRouter';
import Content from '../layout/content';

const meType = loader('./../graphql/queries/core/me.graphql');

const useStyles = makeStyles({
  pageWrapper: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    position: 'relative',
    zIndex: 1,
  },
});

const Main = ({ user, location, handleSetUser }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [checkingLogIn, setCheckingLogIn] = useState(false);

  const getMe = async () => {
    let curUser = user;
    if (!curUser) {
      const response = await client.query({ query: meType });
      if (response.data && response.data.me) {
        handleSetUser(response.data.me);
        curUser = response.data.me;
      }
    }
    return curUser;
  };

  useEffect(() => {
    getMe().then(() => setCheckingLogIn(true));
  });

  if (checkingLogIn) {
    if (user && user.id) {
      return (
        <CurrentUserContext.Provider value={user}>
          <WsController />
          <div className={classes.pageWrapper} tourid="wholePage">
            <Sidebar />
            <Content>
              <MainRouter />
            </Content>
          </div>
        </CurrentUserContext.Provider>
      );
    }
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }
  return <LoadingLayout isLoading />;
};

Main.propTypes = {
  handleSetUser: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

Main.defaultProps = {
  user: {},
};

const mapStateToProps = (state) => ({
  user: state.settings.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(React.memo(Main)));
