import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const ConfirmEmptySettingsDialog = ({ open, onClose, onConfirm, settingsTitle }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onConfirm(event);
        }
      }}
    >
      <DialogTitle>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.root}>
        <Grid container spacing={2} justify="center">
          <Grid container item xs={12} spacing={2} justify="center">
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                <FormattedMessage
                  id="continue_question"
                  defaultMessage="Are you sure you to continue without any"
                />
                &nbsp;
                {settingsTitle}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="center" spacing={4} className="m-t-20">
            <Grid item xs={4}>
              <Button variant="contained" color="secondary" className="w-100" onClick={onConfirm}>
                <FormattedMessage id="yes" defaultMessage="Yes" />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" className="w-100" onClick={onClose}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

ConfirmEmptySettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  settingsTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ConfirmEmptySettingsDialog;
