import React from 'react';
import {
  Grid,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Avatar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useStyles from './styles';

const StepsContainer = ({ number, children, checkLast, subheader, image }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Grid container>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={8}>
          <List className={classes.steplistStyle}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar className={classes.avatarClass}>{number}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography gutterBottom variant="h6">
                    {subheader}
                  </Typography>
                }
                secondary={
                  <Typography component="div" variant="body1" className={classes.listItemText}>
                    {children}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <img
            alt={intl.formatMessage({
              id: 'wastehero.picture',
              defaultMessage: 'Wastehero picture',
            })}
            src={image}
            className={classes.img}
          />
        </Grid>
        <Grid item xs={11}>
          {!checkLast && <Divider className={classes.mainDivider} />}
        </Grid>
      </Grid>
    </Grid>
  );
};
StepsContainer.propTypes = {
  subheader: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  image: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  number: PropTypes.oneOfType([PropTypes.element, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  checkLast: PropTypes.bool,
};
StepsContainer.defaultProps = {
  subheader: '',
  image: null,
  number: null,
  children: null,
  checkLast: false,
};

export default StepsContainer;
