import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MapComponent from '../../../../shared/map';
import Address from '../../../../shared/google/location';

const useStyles = makeStyles(() => ({
  detailTitle: {
    color: '#0F4455',
    opacity: 0.4,
    marginTop: 12,
    marginBottom: 8,
    fontSize: '14px',
    lineHeight: '16px',
  },
  detailValue: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 12,
  },
  columnContainer: {
    flexDirection: 'column',
  },
  mapWrapper: {
    borderRadius: 2,
    overflow: 'hidden',
    marginTop: 16,
  },
  imageContainer: {
    height: 192,
    width: 235,
  },
  image: {
    height: 192,
    width: 235,
    borderRadius: 2,
  },
  contactInformation: {
    color: '#12465A',
    opacity: 0.6,
    marginTop: 12,
    marginBottom: 12,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
  },
  addressContainer: {
    flexDirection: 'column',
    marginTop: 107,
  },
  imageButton: {
    padding: 0,
  },
}));

const InquiryDetailsInformation = ({ selectedInquiry, openImagDetails }) => {
  const classes = useStyles();

  const centerCoords = useMemo(
    () =>
      selectedInquiry?.locationOfIncident
        ? [
            selectedInquiry?.locationOfIncident?.latitude,
            selectedInquiry?.locationOfIncident?.longitude,
          ]
        : [56.13573, 8.96548],
    [selectedInquiry],
  );

  const incidentCoords = useMemo(
    () =>
      selectedInquiry?.locationOfIncident
        ? [
            selectedInquiry?.locationOfIncident?.latitude,
            selectedInquiry?.locationOfIncident?.longitude,
          ]
        : null,
    [selectedInquiry],
  );

  return (
    <Grid container>
      <Grid container item lg={6} className={classes.columnContainer}>
        <Grid className={classes.columnContainer} style={{ height: 192 }}>
          <Typography className={classes.detailTitle} style={{ marginTop: 0 }}>
            <FormattedMessage id="description" defaultMessage="Description" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedInquiry?.description || '-'}
          </Typography>
        </Grid>

        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="case_type" defaultMessage="Case type" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedInquiry?.issueType || '-'}
          </Typography>
        </Grid>

        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="waste_fraction" defaultMessage="Waste fraction" />
          </Typography>
          {selectedInquiry?.wasteFraction?.length ? (
            <Typography className={classes.detailValue}>
              {`${selectedInquiry?.wasteFraction} - ${selectedInquiry?.wasteCategory}`}
            </Typography>
          ) : (
            <Typography className={classes.detailValue}>-</Typography>
          )}
        </Grid>

        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="waste_size_estimate" defaultMessage="Waste size estimate" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedInquiry?.wasteVolume || '-'}
          </Typography>
        </Grid>

        <Grid className={classes.columnContainer}>
          <Typography className={classes.contactInformation}>
            <FormattedMessage id="contact_information" defaultMessage="Contact Information" />
          </Typography>
        </Grid>

        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="name" defaultMessage="Name" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedInquiry?.contactName || '-'}
          </Typography>
        </Grid>

        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="telephone" defaultMessage="Telephone" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedInquiry?.contactPhone || '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item lg={6} className={classes.columnContainer}>
        <Grid className={classes.imageContainer}>
          {selectedInquiry?.images[0] && (
            <Button onClick={() => openImagDetails(true)} className={classes.imageButton}>
              <img src={selectedInquiry?.images[0]} alt="waste" className={classes.image} />
            </Button>
          )}
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="priority" defaultMessage="Priority" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedInquiry?.priority || '-'}
          </Typography>
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="deadline" defaultMessage="Deadline" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedInquiry?.deadline || '-'}
          </Typography>
        </Grid>
        <Grid className={classes.columnContainer} />

        <Grid className={classes.addressContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="address" defaultMessage="Address" />
          </Typography>
          <Typography className={classes.detailValue}>{selectedInquiry?.address || '-'}</Typography>
        </Grid>

        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="location_of_incident" defaultMessage="Location of incident" />
          </Typography>
          <Typography className={classes.detailValue}>
            <Address
              placeId={selectedInquiry?.locationOfIncident?.placeId}
              lng={selectedInquiry?.locationOfIncident?.longitude}
              lat={selectedInquiry?.locationOfIncident?.latitude}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} item className={classes.mapWrapper}>
        <MapComponent
          hideContainerInfoPopup
          containerStyle={{ minHeight: '192px' }}
          selectedContainerCoords={centerCoords}
          orangePinCoords={incidentCoords}
          zoom={[13]}
        />
      </Grid>
    </Grid>
  );
};

InquiryDetailsInformation.defaultProps = {
  selectedInquiry: null,
};

InquiryDetailsInformation.propTypes = {
  openImagDetails: PropTypes.func.isRequired,
  selectedInquiry: PropTypes.shape({
    id: PropTypes.string,
    issueType: PropTypes.string,
    wasteFraction: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    wasteCategory: PropTypes.string,
    wasteVolume: PropTypes.number,
    priority: PropTypes.node,
    deadline: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    locationOfIncident: PropTypes.shape({
      id: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      placeId: PropTypes.string,
    }),
    contactName: PropTypes.string,
    contactPhone: PropTypes.string,
    address: PropTypes.string,
  }),
};

export default InquiryDetailsInformation;
