import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100%',
    margin: 0,
    backgroundColor: 'white',
  },
  appbar: {
    backgroundColor: theme.variables.cLightBlue,
    paddingRight: 20,
    paddingLeft: 20,
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.15)',
  },
  supportTitle: {
    color: theme.variables.cTextDark,
    fontWeight: 'bold',
    fontSize: 23,
    marginLeft: 8,
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: theme.variables.VHOrange,
    },
  },
  tabs: {
    '& > div': {
      '& > .MuiTabs-indicator': {
        top: 'inherit',
        bottom: 0,
        backgroundColor: theme.variables.cOrange,
        height: '2',
      },
    },
  },
  button: {
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    margin: '10px 4px',
    padding: '8px 32px',
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  tab: {
    textTransform: 'none',
  },
}));

export default useStyles;
