import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/client';

import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import getMe from '../../main/routes/settings/project/helpers';
import { saveCacheRead } from '../utils';
import { setActiveProjects as reduxSetActiveProjectsAction, setUser } from '../../../actions';
import SelectActiveProjects from './SelectActiveProjects';

const allCompanies = loader('./../../graphql/queries/core/all_companies.graphql');
const updateActiveProjectsMutation = loader(
  './../../graphql/mutations/core/update_active_projects.graphql',
);

const ActiveProjectSelector = ({ savedActiveProjects, setActiveProjectsAction, handleSetUser }) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const client = useApolloClient();

  const getCompanies = () => {
    setLoading(true);
    saveCacheRead({ query: allCompanies })
      .then(
        ({
          data: {
            allCompanies: { edges },
          },
        }) => {
          setCompanies(edges);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const [updateActiveProjects] = useMutation(updateActiveProjectsMutation);

  const saveActiveProjects = useCallback(
    async (savingActiveProjects) => {
      setActiveProjectsAction(savingActiveProjects);
      await updateActiveProjects({
        variables: {
          activeProjects: savingActiveProjects.map(({ id }) => id),
        },
      });
      await getMe({ client, handleSetUser, projectList: savingActiveProjects });

      return true;
    },
    [setActiveProjectsAction, handleSetUser, updateActiveProjects, client],
  );

  return loading ? (
    <span>Loading ...</span>
  ) : (
    <SelectActiveProjects
      saveActiveProjects={saveActiveProjects}
      companies={companies}
      activeProjects={savedActiveProjects}
    />
  );
};

ActiveProjectSelector.propTypes = {
  setActiveProjectsAction: PropTypes.func.isRequired,
  handleSetUser: PropTypes.func.isRequired,
  savedActiveProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveProjectsAction: (newActiveProjects) => {
    dispatch(reduxSetActiveProjectsAction(newActiveProjects));
  },
  handleSetUser: (user) => {
    dispatch(setUser(user, true));
  },
});

const mapStateToProps = (state) => ({
  savedActiveProjects: state.settings.activeProjects,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveProjectSelector);
