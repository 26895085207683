import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useMutation } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactRouterPropTypes from 'react-router-prop-types';
import { loader } from 'graphql.macro';
import SupportMenuIcon from '../../../images/icons/supportMenuIcon.svg';
import ArrowLeft from '../../../images/icons/arrowLeft.svg';
import SettingsMenuIcon from '../../../images/icons/settingsMenuIcon.svg';
import LogoutIcon from '../../../images/icons/logout.svg';
import { toggleSidebarOpen } from '../../../actions';
import { checkUserIsDemo, getMainLogo } from '../../shared/utils';
import navData from './navData';
import useStyles from './styles';
import { getUserPermissionForProject } from '../../shared/utils/settings';

const logoutMutationGQL = loader('./../../graphql/mutations/core/logout.graphql');

const Sidebar = ({
  user,
  handleToggleSidebarOpen,
  match,
  history,
  location,
  isSidebarOpened,
  isDemoUser,
}) => {
  const [logoutMutation] = useMutation(logoutMutationGQL);
  const intl = useIntl();
  const logo = getMainLogo(user);
  const classes = useStyles({ closed: !isSidebarOpened });

  const updateLocationPathNames = useCallback(() => {
    const userAccessLevel = getUserPermissionForProject(user);
    return navData.reduce((acc, item) => {
      if (!item.except_level.includes(userAccessLevel)) {
        const { children = [] } = item;
        acc.push({
          ...item,
          children: children.reduce((vc, subItem) => {
            if (
              !subItem.except_level?.includes(userAccessLevel) &&
              ((subItem.forBeta && user.isBeta) || !subItem.forBeta)
            ) {
              vc.push(subItem);
            }
            return vc;
          }, []),
        });
      }
      return acc;
    }, []);
  }, [user]);

  const navDataWithLocation = updateLocationPathNames(navData);
  const initialData = navDataWithLocation.reduce(
    (res, nav, i) => ({ ...res, [i]: location.pathname.includes(nav.routeLink) }),
    {},
  );
  const [state, setState] = useState(initialData);

  const handleDrawerOpen = () => {
    handleToggleSidebarOpen();
  };

  const sideBarItemClick = (el, parent) => {
    const menu = Reflect.ownKeys(state).reduce((res, itemId) => ({ ...res, [itemId]: false }), {});
    setState((i) => ({
      ...menu,
      [el]: !i[el],
      [parent]: i[parent],
    }));
  };

  const logout = async () => {
    const {
      data: { refuseToken = {} },
    } = await logoutMutation({});
    sideBarItemClick();
    if (!refuseToken.token) {
      window.localStorage.removeItem('reactQLJWT');
      history.push('/login');
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.sidebar, {
            [classes.openedSidebar]: isSidebarOpened,
            [classes.closedSidebar]: !isSidebarOpened,
          }),
        }}
      >
        <div
          className={classNames(classes.imageContainer, {
            [classes.closedImageContainer]: !isSidebarOpened,
            [classes.openedImageContainer]: isSidebarOpened,
          })}
        >
          <Link to="/app/dashboard" className={classes.logoLink}>
            <div style={{ backgroundImage: `url(${logo})` }} className={classes.logo} />
            {isDemoUser ? (
              <div className={classes.demoContainer}>
                <div className={classes.demoLayout}>W H I T E L A B E L</div>
              </div>
            ) : null}
          </Link>
        </div>
        <IconButton
          color="default"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="end"
          className={classNames(classes.headerToggle, {
            [classes.headerToggleOpen]: !isSidebarOpened,
          })}
        >
          <img src={ArrowLeft} alt="Icon" />
        </IconButton>
        <List className={classNames(classes.menu, classes.mainMenu)} tourid="sideBar">
          {navDataWithLocation.map(
            (nav) =>
              nav.name && (
                <React.Fragment key={nav.name}>
                  <NavLink
                    to={`${match.url}${nav.routeLink}`}
                    activeStyle={{ opacity: 1 }}
                    className={classes.link}
                    onClick={() => sideBarItemClick(nav.name)}
                    tourid={nav.tourid}
                  >
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listIcon}>
                        <img src={nav.icon} alt="Icon" />
                      </ListItemIcon>
                      {isSidebarOpened && (
                        <ListItemText primary={nav.type} className={classes.text} />
                      )}

                      {isSidebarOpened && nav.children && nav.children.length > 0 && (
                        <IconButton
                          className={classNames(classes.expand, {
                            [classes.expandOpen]: state[`${nav.name}`],
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            sideBarItemClick(nav.name);
                          }}
                          aria-expanded={state[`${nav.name}`]}
                          aria-label="show more"
                          type="button"
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  </NavLink>
                  {nav.children && nav.children.length > 0 && (
                    <Collapse
                      in={state[`${nav.name}`]}
                      timeout="auto"
                      unmountOnExit
                      className={classes.collapseHolder}
                    >
                      <List>
                        {nav.children.map(
                          (child) =>
                            child.name && (
                              <NavLink
                                key={`${nav.name}-${child.name}`}
                                exact
                                to={`${match.url}${child.routeLink}`}
                                className={classNames(classes.link)}
                                onClick={() => sideBarItemClick(child.name, nav.name)}
                                tourid={child.tourid}
                              >
                                <ListItem
                                  className={classNames(classes.childListItem, {
                                    [classes.closedChildItem]: !isSidebarOpened,
                                  })}
                                  button
                                >
                                  <ListItemIcon className={classes.listIcon}>
                                    <img src={child.icon} alt={child.type} />
                                  </ListItemIcon>
                                  {isSidebarOpened && (
                                    <ListItemText primary={child.type} className={classes.text} />
                                  )}
                                </ListItem>
                              </NavLink>
                            ),
                        )}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              ),
          )}
        </List>
        <Divider classes={{ root: classes.divider }} />
        <List className={classes.menu}>
          <NavLink
            to="/app/settings"
            activeStyle={{ opacity: 1 }}
            className={classes.link}
            onClick={() => sideBarItemClick()}
          >
            <ListItem className={classes.listItem} button tourid="settingsNavigation">
              <ListItemIcon className={classes.listIcon}>
                <img src={SettingsMenuIcon} alt="Icon" />
              </ListItemIcon>
              {isSidebarOpened && (
                <ListItemText
                  primary={intl.formatMessage({ id: 'settings', defaultMessage: 'Settings' })}
                  className={classes.text}
                />
              )}
            </ListItem>
          </NavLink>
          {(user.isMaster || user.isReseller || user.isAdmin) && (
            <NavLink
              to="/app/support"
              activeStyle={{ opacity: 1 }}
              className={classes.link}
              onClick={() => sideBarItemClick()}
            >
              <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.listIcon}>
                  <img src={SupportMenuIcon} alt="Icon" />
                </ListItemIcon>
                {isSidebarOpened && (
                  <ListItemText
                    primary={intl.formatMessage({ id: 'support', defaultMessage: 'Support' })}
                    className={classes.text}
                  />
                )}
              </ListItem>
            </NavLink>
          )}
          {user.isAdmin && (
            <NavLink
              to="/app/send-mqtt"
              activeStyle={{ opacity: 1 }}
              className={classes.link}
              onClick={() => sideBarItemClick()}
            >
              <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.listIcon}>
                  <img src={SupportMenuIcon} alt="Icon" />
                </ListItemIcon>
                {isSidebarOpened && (
                  <ListItemText
                    primary={intl.formatMessage({
                      id: 'downlink',
                      defaultMessage: 'MQTT Downlink',
                    })}
                    className={classes.text}
                  />
                )}
              </ListItem>
            </NavLink>
          )}
          {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
            <NavLink
              to="/report-inquiry"
              activeStyle={{ opacity: 1 }}
              className={classes.link}
              onClick={() => sideBarItemClick()}
            >
              <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.listIcon}>
                  <img src={SupportMenuIcon} alt="Icon" />
                </ListItemIcon>
                {isSidebarOpened && (
                  <ListItemText
                    primary={intl.formatMessage({
                      id: 'report_inquiry',
                      defaultMessage: 'Report inquiry',
                    })}
                    className={classes.text}
                  />
                )}
              </ListItem>
            </NavLink>
          )}
          <ListItem className={classes.listItem} button onClick={() => logout()}>
            <ListItemIcon className={classes.listIcon}>
              <img src={LogoutIcon} alt="Icon" />
            </ListItemIcon>
            {isSidebarOpened && (
              <ListItemText
                primary={intl.formatMessage({ id: 'logout', defaultMessage: 'Logout' })}
                className={classes.text}
              />
            )}
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

Sidebar.propTypes = {
  isSidebarOpened: PropTypes.bool.isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  handleToggleSidebarOpen: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isMaster: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isReseller: PropTypes.bool.isRequired,
    isDemo: PropTypes.bool.isRequired,
    isBeta: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isSidebarOpened: state.settings.isSidebarOpened,
  user: state.settings.user,
  isDemoUser: checkUserIsDemo(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleToggleSidebarOpen: (isSidebarOpened) => {
    dispatch(toggleSidebarOpen(isSidebarOpened));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(React.memo(Sidebar)));
