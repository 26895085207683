import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Collapse from '@material-ui/core/Collapse';
import { connect, getIn } from 'formik';
import { connect as reduxConnect } from 'react-redux';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import RegisterContainerTooltipedField from '../../main/routes/containers/routes/register/components/registerContainerTooltipedField';
import {
  formikInjectedPropsTypes,
  formikComponentMemoizeValueCheck,
  FormikSelect,
  memoizeFields,
} from '../inputs/formik';
import NoItemsForSelect from './noItemsForSelect';
import { getWasteType } from '../analyticsComponents/utils';
import { checkIfRegularUser } from '../utils/settings';

const allWasteFractionsQuery = loader(
  './../../graphql/queries/wastacollector/all_waste_fractions.graphql',
);
const SelectWasteFraction = ({ formik, md, me }) => {
  const selectedProject = getIn(formik.values, 'selectedProject');
  const isRegularUser = checkIfRegularUser(me);

  const [
    loadWasteFraction,
    {
      loading: isWasteFractionsLoading,
      data: { allWasteFractions: { edges: wasteFractions = [] } = {} } = {},
    },
  ] = useLazyQuery(allWasteFractionsQuery, {
    variables: { projectId: selectedProject },
    suspend: false,
    notifyOnNetworkStatusChange: true,
  });

  const intl = useIntl();

  useEffect(() => {
    if (selectedProject) {
      loadWasteFraction();
    }
  }, [selectedProject, loadWasteFraction]);

  const allWasteFractions = useMemo(
    () =>
      wasteFractions.map(({ node }) =>
        Object({
          value: node.id,
          label: `${getWasteType(node)} - ${node?.wasteCategory || ''}`,
          projectId: node.project?.id,
        }),
      ),
    [wasteFractions],
  );

  const getNoItemsMessage = () => {
    if (isRegularUser) {
      return (
        <FormattedMessage id="no_waste_fractions" defaultMessage="No waste fractions registered.">
          {(text) => <Typography color="error">{text}</Typography>}
        </FormattedMessage>
      );
    }
    return (
      <NoItemsForSelect
        link={`/app/settings/${selectedProject}/waste-fractions-settings`}
        message={
          <FormattedMessage
            id="no_waste_fractions"
            defaultMessage="No waste fractions registered."
          />
        }
        linkText={
          <FormattedMessage
            id="add_waste_fractions"
            defaultMessage="Click to add new waste fraction."
          />
        }
        warning={
          <FormattedMessage
            id="data_will_not_be_save"
            defaultMessage="Data typed into this form will not be saved"
          />
        }
      />
    );
  };

  return (
    <Grid
      item
      xs={12}
      md={md}
      className={classNames({ 'p-0': isWasteFractionsLoading || !selectedProject })}
    >
      <Collapse
        className="w-100 position-relative"
        in={!isWasteFractionsLoading && !!selectedProject}
      >
        {allWasteFractions.length > 0 ? (
          <RegisterContainerTooltipedField
            md={12}
            tooltipText={
              <FormattedMessage
                id="tooltip.selectedWasteFraction"
                defaultMessage="Choose the type of waste this container is intended to"
              />
            }
          >
            <FormikSelect
              label={intl.formatMessage({
                id: 'label.selectedWasteFraction',
                defaultMessage: 'Waste fraction',
              })}
              placeholder={intl.formatMessage({
                id: 'placeholder.selectedWasteFraction',
                defaultMessage: 'Waste fraction',
              })}
              required
              filledStyle
              name="selectedWasteFraction"
              valuesList={allWasteFractions}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </RegisterContainerTooltipedField>
        ) : (
          getNoItemsMessage()
        )}
      </Collapse>
    </Grid>
  );
};

SelectWasteFraction.propTypes = {
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  md: PropTypes.number,
  me: PropTypes.shape({
    isAdmin: PropTypes.bool,
    isMaster: PropTypes.bool,
    isReseller: PropTypes.bool,
    isSuperuser: PropTypes.bool,
  }).isRequired,
};

SelectWasteFraction.defaultProps = {
  md: 9,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default reduxConnect(mapStateToProps)(
  connect(
    React.memo(
      SelectWasteFraction,
      memoizeFields([formikComponentMemoizeValueCheck('selectedProject')]),
    ),
  ),
);
