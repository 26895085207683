import React, { useCallback, useMemo } from 'react';
import { loader } from 'graphql.macro';
import FormikAsyncSelect from '@wastehero/storybook/lib/components/Form/Elements/Select/Async/Formik';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { getSavedActiveProjects } from '../../../../shared/utils';

const query = loader('./../../../../graphql/queries/fleet_management/all_drivers.graphql');

const DriverSelect = ({ multiple, name, label, required, requireVehicle }) => {
  const availableProjects = useSelector((state) => getSavedActiveProjects(state));
  const { values } = useFormikContext();

  const queryResultFormatter = (vs) => {
    let res = vs?.allDrivers?.edges || [];

    if (requireVehicle) {
      res = res?.filter((r) => r.node.vehicle.id === values?.vehicle?.value);
    }

    return res.map(({ node }) => ({
      key: node.id,
      label: `${node.user.firstName} ${node.user.lastName}`,
      value: node.id,
    }));
  };

  const queryVariablesFormatter = useCallback(
    (search, queryVariables) => ({
      ...queryVariables,
    }),
    [],
  );

  const queryVariables = useMemo(
    () => ({
      activeProjects: availableProjects,
    }),
    [availableProjects],
  );

  let disabled = false;

  if (requireVehicle) {
    disabled = true;

    if (values && values.vehicle) {
      disabled = false;
    }
  }

  // const onSelect = useCallback((value, option) => {
  //   console.log('onSelect', value, option);
  // }, []);

  const antd = {
    label,
    labelCol: { span: 24, offset: 0 },
    required,
  };

  return (
    <FormikAsyncSelect
      mode={multiple ? 'multiple' : undefined}
      name={name}
      query={query}
      queryResultFormatter={queryResultFormatter}
      queryVariablesFormatter={queryVariablesFormatter}
      queryVariables={queryVariables}
      // onSelect={onSelect}
      antd={antd}
      disabled={disabled}
    />
  );
};

DriverSelect.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  requireVehicle: PropTypes.bool,
};

DriverSelect.defaultProps = {
  multiple: true,
  label: 'Vehicle',
  name: 'vehicle',
  required: false,
  requireVehicle: false,
};

export default DriverSelect;
