import React, { useState, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';
import Button from '@material-ui/core/Button';
import {
  getSavedActiveProjects,
  getWasteFractionFromContainer,
  uniqueWasteFractions,
} from '../../../shared/utils';
import {
  generateContainerTypes,
  generateContainers,
} from '../../../shared/notificationComponents/utils';
import CreateNotificationSettingsDialog from './createPopups';
import TooltippedUserControl from '../../../shared/tooltip/TooltippedUserControl';
import FixedLoadingLayout from '../../../shared/loading/fixed';
import ReusableTable from '../../../shared/table/table';
import withUserLayoutConfig from '../../../shared/hoc/WithUserLayoutConfig';
import useColumnFilter from '../../../shared/hooks/useColumnFilter';
import {
  getNotificationsTableFilterableColumns,
  notificationsTableColumns,
} from '../../../shared/utils/constants';
import ColumnFilter from '../../../shared/table/columnFilter';
import HeaderRow from '../../../shared/table/headerRow';
import Address from '../../../shared/google/location';
import FilledSelect from '../../../shared/inputs/FilledSelect';
import useRegularUser from '../../../shared/hooks/useRegularUser';
import ButtonTooltip from '../../../shared/tooltip/ButtonTooltip';

const allNotificationSettingsQuery = loader(
  './../../../graphql/queries/devices/all_notification_settings.graphql',
);
const autocompleteNotificationSettingsQuery = loader(
  './../../../graphql/queries/devices/autocomplete_notification_settings.graphql',
);
const allContainerTypesQuery = loader(
  './../../../graphql/queries/devices/all_container_types.graphql',
);
const allWasteFractionsQuery = loader(
  './../../../graphql/queries/wastacollector/all_waste_fractions.graphql',
);
const allContainersQuery = loader('./../../../graphql/queries/devices/all_containers.graphql');
const allUsersQuery = loader('./../../../graphql/queries/core/all_users.graphql');
const deleteNotificationSettingMutation = loader(
  './../../../graphql/mutations/devices/delete_notification_setting.graphql',
);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    height: '100%',
  },
  button: {
    height: '100%',
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    border: '1px solid',
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'bold',
    },
    '& .MuiAvatar-img': {
      width: 26,
      height: 22,
    },
  },
  root: {
    backgroundColor: theme.variables.cWhite,
    marginBottom: '30px',
  },
  pageTitle: {
    textAlign: 'center',
    marginTop: 40,
  },
  pageContainer: {
    width: '96%',
  },
  tableContainer: {
    paddingLeft: 32,
    paddingRight: 32,
    marginBottom: 32,
    minHeight: '68vh',
  },
}));

const serializeNotificationSetting = ({ node }) => ({
  id: node.id,
  container_id: node.container?.containerId,
  location: (
    <Address
      placeId={node.container?.location?.placeId}
      lat={node.container?.location?.latitude}
      lng={node.container?.location?.longitude}
    />
  ),
  condition: node.condition,
  threshold: node.threshold,
  container_type: node.container?.containerType?.name,
  waste_fraction: getWasteFractionFromContainer(node.container),
  send_to: (
    <>
      {' '}
      {node.users.edges.map(({ node: user }) => (
        <p key={user.id}>{`${user.username} <${user.email || 'no email'}>`}</p>
      ))}
      {!!node.users.edges && !!node.users.edges.length && <br />}
      {node.recipients.map((email) => (
        <p key={email}>{email}</p>
      ))}
    </>
  ),
});

const NotificationSettingsTable = ({
  savedActiveProjects,
  userLayoutConfig,
  handleConfigChange,
}) => {
  const [deleteNotificationSetting, { loading: deleteNotificationLoading }] = useMutation(
    deleteNotificationSettingMutation,
  );
  const classes = useStyles();
  const intl = useIntl();

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    notificationsTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );
  const [isRegularUser] = useRegularUser();
  const [modalLoading, setModalLoading] = useState(false);
  const [fractionFilter, setFractionFilter] = useState([]);
  const [containerTypeFilter, setContainerTypeFilter] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [activeItemId, setActive] = useState(null);

  const queryExtraVariables = useMemo(() => {
    const variables = {
      activeProjects: savedActiveProjects,
      containerType: containerTypeFilter,
    };

    if (fractionFilter.length) {
      let ungroupedWasteFractions = [];
      fractionFilter.forEach((item) => {
        let wFraction = item;
        if (wFraction.includes(',')) {
          wFraction = wFraction.split(',');
        }
        ungroupedWasteFractions = ungroupedWasteFractions.concat(wFraction);
      });
      variables.wasteFraction = ungroupedWasteFractions;
    }

    return variables;
  }, [containerTypeFilter, fractionFilter, savedActiveProjects]);

  const {
    loading: wasteLoading,
    data: { allWasteFractions: { edges: rawWasteFractions = [] } = {} } = {},
  } = useQuery(allWasteFractionsQuery, {
    variables: {
      activeProjects: savedActiveProjects,
      withProjects: false,
    },
  });

  const {
    loading: usersLoading,
    data: { allUsers: { edges: rawUsers = [] } = {} } = {},
  } = useQuery(allUsersQuery, {
    variables: {
      activeProjects: savedActiveProjects,
      withProjects: false,
    },
  });

  const {
    loading: containerTypesLoading,
    data: { allContainerTypes: { edges: rawContainerTypes = [] } = {} } = {},
  } = useQuery(allContainerTypesQuery, {
    variables: {
      activeProjects: savedActiveProjects,
    },
  });

  const {
    loading: containersLoading,
    data: { allContainers: { edges: rawContainers = [] } = {} } = {},
  } = useQuery(allContainersQuery, {
    variables: {
      activeProjects: savedActiveProjects,
      withProjects: false,
    },
  });

  const containerTypes = useMemo(
    () => generateContainerTypes(rawContainerTypes.map(({ node }) => node)),
    [rawContainerTypes],
  );

  const containers = useMemo(() => generateContainers(rawContainers), [rawContainers]);

  const users = useMemo(
    () =>
      rawUsers.map(({ node }) => ({
        value: node.id,
        label: `${node.username} - ${node.email}`,
        email: node.email,
        username: node.username,
      })),
    [rawUsers],
  );

  const fractions = useMemo(
    () =>
      uniqueWasteFractions(rawWasteFractions).map((item) => ({
        value: item.id,
        label: getWasteFractionFromContainer({ wasteFraction: item }),
      })),
    [rawWasteFractions],
  );

  const loading =
    wasteLoading ||
    containerTypesLoading ||
    containersLoading ||
    usersLoading ||
    deleteNotificationLoading ||
    modalLoading;

  const handleOpenCreateDialog = useCallback(() => {
    setActive(null);
    setCreateDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setCreateDialogOpen(false);
    setActive(null);
  }, []);

  const handleAddNotificationSetting = useCallback(() => {}, []);

  const extraFilter = useMemo(
    () => (
      <Grid container item xs={12} justify="space-between">
        <Grid item xs={5}>
          <FilledSelect
            name="fractions"
            placeholder={intl.formatMessage({
              id: 'notificationSettings.fractionFilter',
              defaultMessage: 'Waste fractions',
            })}
            valuesList={fractions}
            required
            multiSelect
            value={fractionFilter}
            onChange={(e) => setFractionFilter(e.target.value)}
          />
        </Grid>

        <Grid item xs={5}>
          <FilledSelect
            name="containerTypes"
            placeholder={intl.formatMessage({
              id: 'notificationSettings.containerTypes',
              defaultMessage: 'Container types',
            })}
            valuesList={containerTypes}
            required
            multiSelect
            value={containerTypeFilter}
            onChange={(e) => setContainerTypeFilter(e.target.value)}
          />
        </Grid>
      </Grid>
    ),
    [containerTypeFilter, containerTypes, fractionFilter, fractions, intl],
  );

  const cellsConfig = [
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getNotificationsTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'container_id',
      label: <FormattedMessage id="table_head.container_id" defaultMessage="Container ID" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'location',
      label: <FormattedMessage id="table_head.location" defaultMessage="Location" />,
      numeric: false,
      disablePadding: false,
      disableOrder: true,
      noFilter: true,
    },
    {
      id: 'container_type',
      label: <FormattedMessage id="table_head.container_type" defaultMessage="Container type" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'waste_fraction',
      label: <FormattedMessage id="table_head.waste_fraction" defaultMessage="Waste fraction" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'condition',
      label: <FormattedMessage id="table_head.condition" defaultMessage="Condition" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'threshold',
      label: <FormattedMessage id="table_head.threshold" defaultMessage="Threshold" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'send_to',
      label: <FormattedMessage id="table_head.send_to" defaultMessage="Send to" />,
      numeric: false,
      disablePadding: false,
    },
  ];

  const headCellsToDisplay = cellsConfig.filter((cell) =>
    activeTableColumns.some((tableColumn) => tableColumn === cell.id),
  );

  const notificationSettingsSerializer = ({
    allNotificationSettings: { edges: rawNotificationSettings = [], totalCount = 0 } = {},
  } = {}) => ({
    totalCount,
    items: rawNotificationSettings.map(serializeNotificationSetting),
  });

  const notificationAutocompleteSerializer = ({
    allNotificationSettings: { edges: arr = [] } = {},
  }) =>
    arr.map(({ node }) => ({
      primaryText: node.container?.containerId,
      secondaryText: node?.condition,
      logo: node?.container?.photoUrl,
      id: node.id,
    }));

  const pageTitle = intl.formatMessage({
    id: 'notification_settings',
    defaultMessage: 'Notification settings',
  });

  return (
    <Grid container justify="center" className={classes.root}>
      <FixedLoadingLayout open={loading} />
      <HeaderRow pageTitle={pageTitle}>
        <Grid className="h-70" item lg={2} xs={5}>
          <ButtonTooltip
            text={
              isRegularUser
                ? intl.formatMessage({
                    id: 'tooltip.no_permission_add_notification',
                    defaultMessage: "You don't have permission to add notification",
                  })
                : ''
            }
          >
            <Button
              variant="outlined"
              disabled={isRegularUser}
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={handleOpenCreateDialog}
              tourid="addNotification"
            >
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="button.add_notification"
                    defaultMessage="Click to add a new notification"
                  />
                }
                avoidIcon
              >
                <Typography variant="body1">
                  <FormattedMessage id="add_notification" defaultMessage="Add notification" />
                </Typography>
              </TooltippedUserControl>
            </Button>
          </ButtonTooltip>
        </Grid>
      </HeaderRow>
      <Grid item xs={12} container justify="center">
        <Grid item className={classes.pageContainer}>
          <ReusableTable
            userLayoutConfig={userLayoutConfig}
            updateUserConfig={handleConfigChange}
            cellsConfig={headCellsToDisplay}
            autoCompleteQuery={autocompleteNotificationSettingsQuery}
            queryExtraVariables={queryExtraVariables}
            extraFilter={extraFilter}
            deleteItemMutation={deleteNotificationSetting}
            query={allNotificationSettingsQuery}
            itemsSerializer={notificationSettingsSerializer}
            autocompleteSerializer={notificationAutocompleteSerializer}
            updateCache={async () => {}}
            chooseLogoSize={false}
            noLogoLink
            searchSize={3}
            tableContainerClass={classes.tableContainer}
            tableComponentWrapperStyles={{
              paddingTop: '20px',
              paddingBottom: '0',
            }}
            activeTableColumns={activeTableColumns}
            tableFontSize={14}
            queryName="allNotificationSettings"
            noCheckboxes
            displayScrollbar={!loading}
            allRowsEnabled={false}
            isRedirectOnSelect={false}
            selectTableRowCallback={setActive}
            excludeEditOption
            noActionMenu={isRegularUser}
            freezeAutocompleteInputForASecond
          />
        </Grid>
      </Grid>

      {createDialogOpen && (
        <CreateNotificationSettingsDialog
          open={createDialogOpen}
          onClose={handleCloseDialog}
          containers={containers}
          allUsers={users}
          editingItem={activeItemId}
          handleAddNotificationSetting={handleAddNotificationSetting}
          activeProjects={savedActiveProjects}
          setLoading={setModalLoading}
          queryExtraVariables={queryExtraVariables}
          userLayoutConfig={userLayoutConfig}
        />
      )}
    </Grid>
  );
};

NotificationSettingsTable.propTypes = {
  savedActiveProjects: PropTypes.string.isRequired,
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  savedActiveProjects: getSavedActiveProjects(state),
});

export default connect(mapStateToProps)(
  withUserLayoutConfig('notifications')(NotificationSettingsTable),
);
