import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { connect, getIn } from 'formik';
import InputSkeleton from '../inputs/InputSkeleton';
import {
  formikComponentMemoizeFieldCheck,
  formikInjectedPropsTypes,
  FormikSelect,
  memoizeFields,
} from '../inputs/formik';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  blankItem: {
    minWidth: 60,
  },
}));

const MultiRowSelect = ({
  inputClassName,
  name,
  fieldName,
  valuesList,
  loading,
  defaultValue,
  getIdFromFormikValue,
  getItem,
  formik,
  noItems,
  label,
  placeholder,
  renderRow,
  required,
  maxInputsQuantity,
  onChange,
}) => {
  const classes = useStyles();

  const { setFieldValue } = formik;
  const selectedItems = getIn(formik.values, name);
  const selectedItemsIds = selectedItems.map(getIdFromFormikValue);

  useEffect(() => {
    if (!selectedItems.length) {
      setFieldValue(name, [defaultValue]);
    }
  }, [defaultValue, name, selectedItems.length, setFieldValue]);

  const handleRemoveFraction = useCallback(
    (id) => () => {
      const nextValue = [...selectedItems.slice(0, id), ...selectedItems.slice(id + 1)];
      setFieldValue(name, nextValue);
      onChange(nextValue);
    },
    [onChange, name, selectedItems, setFieldValue],
  );

  const handleAddFraction = useCallback(() => {
    const nextValue = [...selectedItems, defaultValue];
    if (maxInputsQuantity && selectedItems.length >= maxInputsQuantity) {
      return;
    }
    setFieldValue(name, nextValue);
    onChange(nextValue);
  }, [onChange, setFieldValue, name, selectedItems, defaultValue, maxInputsQuantity]);

  return (
    <Grid item xs={12}>
      {loading || selectedItems.length ? (
        <Grid container item xs={12} className={classes.root}>
          {selectedItems.map((value, index) => (
            <Grid key={getIdFromFormikValue(value) || index} container item xs={12} spacing={3}>
              <Grid item xs={12} md={4} lg={5} xl={6} className={classNames(inputClassName)}>
                {loading ? (
                  <InputSkeleton withLabel={index === 0} />
                ) : (
                  <FormikSelect
                    required={required}
                    valuesList={valuesList.filter(
                      ({ value: listValue }) =>
                        listValue === getIdFromFormikValue(value) ||
                        !selectedItemsIds.includes(listValue),
                    )}
                    name={`${name}[${index}]${fieldName ? `.${fieldName}` : ''}`}
                    label={index === 0 ? label : ''}
                    placeholder={placeholder}
                    filledStyle
                  />
                )}
              </Grid>
              {renderRow({
                value,
                item: getItem(getIdFromFormikValue(value)),
                index,
                loading,
                onChangeValue: () =>
                  onChange(selectedItems.map((prev, i) => (index === i ? value : prev))),
              })}
              <Grid
                item
                xs={4}
                md={2}
                lg={2}
                xl={1}
                container
                justify="flex-end"
                alignItems="center"
                className={classNames({ 'p-t-30': index === 0 })}
              >
                <Grid
                  item
                  className={classNames({
                    [classes.blankItem]: index + 1 !== selectedItems.length,
                  })}
                >
                  {index + 1 === selectedItems.length && (
                    <IconButton edge="end" aria-label="add" onClick={handleAddFraction}>
                      <AddIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  <IconButton edge="end" aria-label="delete" onClick={handleRemoveFraction(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        noItems
      )}
    </Grid>
  );
};

MultiRowSelect.propTypes = {
  inputClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  valuesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    }),
  ),
  getIdFromFormikValue: PropTypes.func,
  getItem: PropTypes.func,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  noItems: PropTypes.element,
  renderRow: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxInputsQuantity: PropTypes.number,
};

MultiRowSelect.defaultProps = {
  inputClassName: '',
  fieldName: '',
  defaultValue: '',
  getIdFromFormikValue: (val) => val,
  getItem: ({ id }) => id,
  onChange: () => {},
  valuesList: [],
  loading: false,
  required: true,
  label: '',
  placeholder: '',
  noItems: null,
  maxInputsQuantity: null,
};

export default connect(
  React.memo(
    MultiRowSelect,
    memoizeFields([
      'inputClassName',
      'name',
      'fieldName',
      'valuesList',
      'loading',
      'defaultValue',
      'getIdFromFormikValue',
      'noItems',
      'maxInputsQuantity',
      'placeholder',
      'label',
      'required',
      'getItem',
      'renderRow',
      formikComponentMemoizeFieldCheck(),
    ]),
  ),
);
