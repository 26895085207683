import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import StyledCheckbox from '../../../../../shared/inputs/StyledCheckbox';
import EditableLabel from './EditableLabel';
import { allContainerQuery as theme } from '../../../containers/routes/shared/utils';

const useStyles = makeStyles({
  inputFocused: {
    '& fieldset': {
      borderColor: `${theme.variables.cLightGray} !important`,
      borderWidth: '1px !important',
    },
  },
  optionLabel: {
    '& > .MuiTypography-body1': {
      display: 'inline-flex',
      justifyContent: 'space-between',
      width: '100%',
      '&:hover': {
        '& > div': {
          '& > button': {
            display: 'inline-flex',
          },
        },
      },
    },
  },
});

const SettingsDropdownCheckboxGroupWithCustom = ({
  valuesList,
  selected,
  setValue,
  setWasteTypeIdToDelete,
  getParamsForWasteTypeEdit,
}) => {
  const [localSelected, setLocalSelected] = React.useState(selected);
  const classes = useStyles();

  const handleChange = (event) => {
    const { target: { value = '', checked = false } = {} } = event;

    let oldSelected = [...localSelected];
    if (checked) {
      oldSelected.push(value);
    } else {
      oldSelected = oldSelected.filter((option) => option !== value);
    }

    setLocalSelected(oldSelected);
    setValue(oldSelected);
  };

  const handleChangeGroup = (subOptions) => (event) => {
    const { target: { checked = false, value } = {} } = event;

    let oldSelected = localSelected.filter(
      (option) => !(subOptions.some((subOption) => subOption.value === option) || option === value),
    );
    if (checked) {
      oldSelected = [...oldSelected, value];
    }
    setLocalSelected(oldSelected);
    setValue(oldSelected);
  };

  const renderValuesList = (options) =>
    options.map((option) => (
      <React.Fragment key={`checkbox_group_${option.value}`}>
        <FormControlLabel
          key={`checkbox_${option.value}`}
          className={classes.optionLabel}
          control={
            <StyledCheckbox
              checked={localSelected.indexOf(option.value) > -1}
              onChange={handleChangeGroup(option.options)}
              value={option.value}
            />
          }
          label={
            option?.isCustom ? (
              <EditableLabel
                label={option.label}
                value={option.value}
                setWasteTypeIdToDelete={setWasteTypeIdToDelete}
                getParamsForWasteTypeEdit={getParamsForWasteTypeEdit}
              />
            ) : (
              option.label
            )
          }
        />
        {option.options?.map((subOption) => (
          <FormControlLabel
            className={classNames(['p-l-20', classes.optionLabel])}
            key={`checkbox_${subOption.value}`}
            control={
              <StyledCheckbox
                checked={localSelected.indexOf(subOption.value) > -1}
                onChange={handleChange}
                value={subOption.value}
              />
            }
            label={
              subOption?.isCustom ? (
                <EditableLabel
                  label={subOption.label}
                  value={subOption.value}
                  setWasteTypeIdToDelete={setWasteTypeIdToDelete}
                  setParamsForWasteTypeEdit={getParamsForWasteTypeEdit}
                />
              ) : (
                subOption.label
              )
            }
          />
        ))}
      </React.Fragment>
    ));

  const sharedValues = [];

  valuesList.forEach((option) => {
    if (option.isCustom) {
      return;
    }
    sharedValues.push(option);
  });

  const [filterInputValue, setFilterInputValue] = useState('');
  const filteredList = filterInputValue ? [] : sharedValues;
  const filterByLabel = (option) =>
    option.label.toLowerCase().includes(filterInputValue.toLowerCase());
  if (filterInputValue) {
    sharedValues.forEach((sharedValue) => {
      const filteredChildOptions = sharedValue.options.filter(filterByLabel);
      if (filteredChildOptions.length) {
        filteredList.push({
          ...sharedValue,
          options: filteredChildOptions,
        });
      } else if (filterByLabel(sharedValue)) {
        filteredList.push(sharedValue);
      }
    });
  }

  return (
    <FormGroup className="p-10">
      <TextField
        value={filterInputValue}
        onChange={(e) => setFilterInputValue(e.target.value)}
        className={classes.inputBase}
        variant="outlined"
        size="small"
        autoFocus
        InputProps={{
          classes: { focused: classes.inputFocused },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography>General</Typography>
      <Divider light />
      {renderValuesList(filteredList)}
    </FormGroup>
  );
};

SettingsDropdownCheckboxGroupWithCustom.propTypes = {
  valuesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setWasteTypeIdToDelete: PropTypes.func.isRequired,
  getParamsForWasteTypeEdit: PropTypes.func.isRequired,
};

SettingsDropdownCheckboxGroupWithCustom.defaultProps = {
  selected: [],
};

export default SettingsDropdownCheckboxGroupWithCustom;
