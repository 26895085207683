import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';

const DefaultErrorUI = ({ reloadHandler }) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    spacing={4}
    direction="column"
    className="dashboard h-100 w-100 p-t-20"
  >
    <Grid item style={{ width: '50%' }}>
      <Grid container direction="column" spacing={5} alignItems="center">
        <Typography variant="h4" align="center" className="p-b-20">
          <FormattedMessage id="global_error_message" defaultMessage="This component has crashed" />
        </Typography>
        <Typography variant="h5" align="center" className="p-b-20">
          <FormattedMessage id="try_to_reload" defaultMessage="Try to reload" />
        </Typography>
        <Button
          variant="outlined"
          size="medium"
          fullWidth={false}
          style={{ width: 'fit-content' }}
          onClick={reloadHandler}
        >
          <FormattedMessage id="reload" defaultMessage="Reload" />
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

DefaultErrorUI.propTypes = {
  reloadHandler: PropTypes.func.isRequired,
};

class MainErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { location: currentLocation } = this.props;
    const { location: prevLocation } = prevProps;

    if (currentLocation !== prevLocation && prevState.hasError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  };

  componentDidCatch(error, errorInfo) {
    window.console.group('MainErrorBoundary catch error:');
    window.console.log(error.message);
    window.console.log(errorInfo.componentStack);
    window.console.groupEnd();
    this.setState({ hasError: true });
  }

  reloadHandler() {
    this.setState({ hasError: false });
  }

  render() {
    const { hasError } = this.state;
    const { children, errorUI } = this.props;

    if (hasError) {
      return errorUI({ reloadHandler: () => this.reloadHandler() });
    }

    return children;
  }
}

MainErrorBoundary.propTypes = {
  errorUI: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.node.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

MainErrorBoundary.defaultProps = {
  errorUI: DefaultErrorUI,
};

export default withRouter(MainErrorBoundary);
