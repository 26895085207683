import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import MenuItem from './menuItem';
import ProjectOverviewIcon from '../../../../../images/icons/projectOverview.svg';
import ProjectSettingsIcon from '../../../../../images/icons/projectSettings.svg';
import MeasurementsSettingsIcon from '../../../../../images/icons/measurementsSettings.svg';
import WasteFractionSettingsIcon from '../../../../../images/icons/wasteFractionSettings.svg';
import MyAccountIcon from '../../../../../images/icons/myAccount.svg';
import ContainerSettingsIcon from '../../../../../images/icons/containerSettings.svg';
import CompanySettingsIcon from '../../../../../images/icons/companySettings.svg';
import InviteColleagueIcon from '../../../../../images/icons/inviteColleague.svg';
import { saveCacheRead, toastifyError } from '../../../../shared/utils';
import LoadingLayout from '../../../../shared/loading';
import { getActiveProjects } from '../../../../shared/utils/settings';

const allProjectsQuery = loader('./../../../../graphql/queries/core/all_projects.graphql');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 700,
  },
  header: {
    marginTop: '60px',
    marginBottom: '35px',
  },
  projectLogo: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
    '&>img': {
      maxHeight: 40,
      maxWidth: '100%',
    },
  },
  avatar: {
    height: 50,
    width: 50,
    marginRight: 15,
    backgroundColor: 'inherit',
  },
  title: {
    textAlign: 'center',
    margin: theme.spacing(1),
  },
  selectedListItem: {
    backgroundColor: theme.variables.cAntiFlashWhite,
  },
  selectedText: {
    color: theme.variables.VHLightBlue,
  },
}));

const SettingsMenu = ({ history, match, user, activeProjects }) => {
  const classes = useStyles();
  const intl = useIntl();
  const projectContainers = useRef(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [loading, setLoading] = useState(false);

  const {
    params: { id: projectId },
  } = match;

  const handleProjectSelect = useCallback(
    (project) => {
      history.push(`/app/settings/${project.id}`);
    },
    [history],
  );

  useEffect(() => {
    if (activeProjects.length && !projectId) {
      const activeProject = projects.find(({ id }) => id === activeProjects[0].node.id);
      if (activeProject) {
        handleProjectSelect(activeProject);
      }
    }
  }, [activeProjects, projects, projectId, handleProjectSelect]);

  useEffect(() => {
    if (projectId) {
      const urlSelectedProject = projects.find(({ id }) => id === projectId);
      if (urlSelectedProject) {
        setSelectedProject(urlSelectedProject);
      }
    } else {
      setSelectedProject({});
    }
  }, [projects, projectId, selectedProject.id]);

  useEffect(() => {
    if (projectContainers.current) {
      const {
        current: { container },
      } = projectContainers;
      const selectedDiv = container.querySelector('div[class*="selectedListItem"]');
      if (selectedDiv) {
        projectContainers.current.scrollTop(selectedDiv.offsetTop);
      }
    }
  }, [selectedProject.id, projectContainers]);

  useEffect(() => {
    setLoading(true);
    saveCacheRead({ query: allProjectsQuery, variables: { isVisibleCompany: true } })
      .then(
        ({
          data: {
            allProjects: { edges },
          },
        }) => {
          const usersProjects = edges.map(({ node }) => node);
          setProjects(usersProjects);
        },
      )
      .catch((error) => toastifyError(error))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Grid container spacing={6} className={classes.root}>
      <LoadingLayout isLoading={loading} />
      <Grid item container justify="center" xs={4}>
        <Paper className="w-100 m-t-45 m-l-30">
          <Scrollbars
            ref={projectContainers}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            tourid="projectList"
          >
            <List style={{ padding: 0 }}>
              {projects.map((project) => (
                <ListItem
                  key={project.id}
                  button
                  onClick={() => handleProjectSelect(project)}
                  className={classNames({
                    [classes.selectedListItem]: selectedProject.id === project.id,
                  })}
                >
                  <Grid item xs={4} sm={2}>
                    <div className={classes.projectLogo}>
                      {project.logo ? (
                        <img src={project.logo} alt={project.name} />
                      ) : (
                        <Avatar>
                          {project.name
                            .split(' ')
                            .map((item) => item.slice(0, 1))
                            .join('')
                            .slice(0, 2)
                            .toUpperCase()}
                        </Avatar>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <Typography
                      variant="h6"
                      className={classNames(classes.title, {
                        [classes.selectedText]: selectedProject.id === project.id,
                      })}
                    >
                      {project.name}
                      &nbsp;
                      {<FormattedMessage id="project" defaultMessage="Project" />}
                    </Typography>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Scrollbars>
        </Paper>
      </Grid>
      <Grid item container justify="center" xs={8}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" className={classes.header}>
            <FormattedMessage id="settings" defaultMessage="Settings" />
          </Typography>
        </Grid>
        <Grid item>
          <List>
            <MenuItem
              primary={intl.formatMessage({
                id: 'my_account.label',
                defaultMessage: 'My account',
              })}
              secondary={intl.formatMessage({
                id: 'my_account.help_text',
                defaultMessage: 'Change account information, mail, password and more',
              })}
              icon={MyAccountIcon}
              href={`/app/user-management/account-settings/${projectId}/${user.id}`}
              tourid="myAccount"
            />
            <MenuItem
              primary={intl.formatMessage({
                id: 'projects_overview.label',
                defaultMessage: 'Projects overview',
              })}
              secondary={intl.formatMessage({
                id: 'projects_overview.help_text',
                defaultMessage: 'Provides an overview over project and assigned account',
              })}
              icon={ProjectOverviewIcon}
              href="/app/settings/projects-overview"
              tourid="projectsOverview"
            />
            <MenuItem
              primary={intl.formatMessage({
                id: 'company_settings.label',
                defaultMessage: 'Company settings',
              })}
              secondary={intl.formatMessage({
                id: 'company_settings.help_text',
                defaultMessage: 'Edit company settings',
              })}
              icon={CompanySettingsIcon}
              href={`/app/settings/company/${selectedProject.company?.id}`}
              disabled={!selectedProject.id}
            />
            <MenuItem
              primary={intl.formatMessage({
                id: 'projects_settings.label',
                defaultMessage: 'Project settings',
              })}
              secondary={intl.formatMessage({
                id: 'projects_settings.help_text',
                defaultMessage:
                  'Change unit settings, time zone, currency, upload logo and much more',
              })}
              icon={ProjectSettingsIcon}
              href={`/app/settings/${selectedProject.id}/project-settings`}
              disabled={!selectedProject.id}
              tourid="projectSettings"
            />
            <MenuItem
              primary={intl.formatMessage({
                id: 'container_settings.label',
                defaultMessage: 'Container settings',
              })}
              secondary={intl.formatMessage({
                id: 'container_settings.help_text',
                defaultMessage: 'Create new container types or edit existing container types',
              })}
              icon={ContainerSettingsIcon}
              href={`/app/settings/${selectedProject.id}/container-settings`}
              disabled={!selectedProject.id}
              tourid="containerSettings"
            />
            <MenuItem
              primary={intl.formatMessage({
                id: 'waste_fraction_settings.label',
                defaultMessage: 'Waste fraction settings',
              })}
              secondary={intl.formatMessage({
                id: 'waste_fraction_settings.help_text',
                defaultMessage:
                  'Display disposal, add new waste fractions and set weight to volume rate',
              })}
              icon={WasteFractionSettingsIcon}
              href={`/app/settings/${selectedProject.id}/waste-fractions-settings`}
              disabled={!selectedProject.id}
              tourid="wasteFractionSettings"
            />
            <MenuItem
              primary={intl.formatMessage({
                id: 'measurements_settings.label',
                defaultMessage: 'Measurements settings',
              })}
              secondary={intl.formatMessage({
                id: 'measurements_settings.help_text',
                defaultMessage: 'Create and change measurements methods',
              })}
              icon={MeasurementsSettingsIcon}
              href={`/app/settings/${selectedProject.id}/measurement-settings`}
              disabled={!selectedProject.id}
              tourid="measurementSettings"
            />
            <MenuItem
              primary={intl.formatMessage({
                id: 'invite_colleague.label',
                defaultMessage: 'Invite colleague',
              })}
              secondary={intl.formatMessage({
                id: 'invite_colleague.help_text',
                defaultMessage: 'Invite new user to the platform',
              })}
              icon={InviteColleagueIcon}
              href={`/app/settings/${selectedProject.id}/project-overview`}
              disabled={!selectedProject.id}
            />
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

SettingsMenu.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }).isRequired,
  activeProjects: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.settings.user,
  activeProjects: getActiveProjects(state),
});

export default connect(mapStateToProps)(withRouter(SettingsMenu));
