import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import parse from 'autosuggest-highlight/parse';
import autoSuggestMatch from 'autosuggest-highlight/match';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import throttle from 'lodash/throttle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Logo from './logo';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    height: '100%',
    '& .MuiInputBase-root': {
      borderRadius: 25,
      height: '100%',
    },
    '& input': {
      height: 10,
    },
  },
  primaryText: {
    fontSize: theme.spacing(2),
  },
  secondaryText: {
    fontSize: theme.spacing(1.75),
  },
}));

const AutocompleteFilter = ({
  query,
  serializer,
  queryExtraVariables,
  history,
  match,
  location,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [getAutocompleteOptions, { data: autocompleteOptions = {} }] = useLazyQuery(query, {
    displayName: 'autocomplete-filter',
    options: { fetchPolicy: 'cache-first' },
    variables: {
      ...queryExtraVariables,
    },
  });
  const loading = false;

  const parsedQueryString = useMemo(() => queryString.parse(location.search), [location.search]);

  const fetch = React.useMemo(
    () => throttle((variables, queryFunc) => queryFunc({ variables }), 200),
    [],
  );

  useEffect(() => {
    if (inputValue && query) {
      fetch({ name: inputValue, ...queryExtraVariables }, getAutocompleteOptions);
    }
  }, [inputValue, query, fetch, getAutocompleteOptions, queryExtraVariables]);

  const serializedOptions = serializer(autocompleteOptions);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : `${option?.secondaryText} ${option?.primaryText}` || ''
      }
      options={!inputValue || !serializedOptions.length ? [null] : serializedOptions}
      freeSolo
      loading={loading}
      className="h-100"
      includeInputInList
      filterSelectedOptions
      value={parsedQueryString.query || null}
      onChange={(event, newValue) => {
        if (!newValue) {
          delete parsedQueryString.query;
          history.push({
            pathname: match.url,
            search: `?${queryString.stringify({ ...parsedQueryString })}`,
          });
        } else if (typeof newValue === 'object') {
          history.push({
            pathname: `${match.url}/${newValue.id}`,
          });
        } else {
          history.push({
            search: `?${queryString.stringify({ ...parsedQueryString, query: newValue })}`,
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          className={classes.searchInput}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        if (!option) {
          return (
            <div className={classNames('MuiAutocomplete-noOptions', classes.primaryText)}>
              <FormattedMessage id="not_found" defaultMessage="Not found" />
            </div>
          );
        }

        const parts = parse(option.primaryText, autoSuggestMatch(option.primaryText, inputValue));

        return (
          <Grid container alignItems="center" className={classes.primaryText}>
            <Grid item>
              <Logo variant="small" src={option.logo} name={option.primaryText} />
            </Grid>
            <Grid item xs container direction="column">
              <Grid item>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </Grid>
              <Typography variant="body2" color="textSecondary" className={classes.secondaryText}>
                {option.secondaryText}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

AutocompleteFilter.propTypes = {
  query: PropTypes.objectOf(PropTypes.any),
  queryExtraVariables: PropTypes.objectOf(PropTypes.any),
  serializer: PropTypes.func,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

AutocompleteFilter.defaultProps = {
  query: null,
  serializer: () => [],
  queryExtraVariables: {},
};

export default withRouter(AutocompleteFilter);
