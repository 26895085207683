import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Grid, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { muiErrorClass } from '../../main/routes/containers/routes/shared/utils';

const styles = (theme) => ({
  navLink: {
    textDecoration: 'none',
    marginLeft: 5,
    color: theme.variables.VHBlue,
    cursor: 'pointer',
  },
  horizontalSpan: {
    display: 'inline-flex',
  },
  warning: {
    display: 'inline-flex',
    marginTop: 4,
    color: theme.variables.VHDarkGrey,
  },
});

const NoItemsForSelect = ({ classes, message, warning, linkText, link }) => (
  <Grid item xs={12}>
    <span className={classes.horizontalSpan}>
      <Typography className={classNames(muiErrorClass)}>{message}</Typography>
      <NavLink to={link} className={classes.navLink}>
        <Typography>{linkText}</Typography>
      </NavLink>
    </span>
    {warning && <span className={classes.warning}>{warning}</span>}
  </Grid>
);

NoItemsForSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  message: PropTypes.node.isRequired,
  linkText: PropTypes.node.isRequired,
  warning: PropTypes.string,
  link: PropTypes.string.isRequired,
};

NoItemsForSelect.defaultProps = {
  warning: '',
};

export default withStyles(styles, { withTheme: true })(NoItemsForSelect);
