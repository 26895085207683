import React from 'react';
import { Route, withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { Switch } from 'react-router-dom';
import HeaderRow from './headerRow';
import MainInfoHead from './mainInfoHead';
import InfoBox from './infoBox';
import WhenNotFound from '../../../../shared/whenNotFound';
import { detailSubMenuItems, detailUpperMenuItems } from './utils';
import PhotosComponent from '../routes/vehicles/components/Photos';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.variables.defaultFont,
    backgroundColor: theme.variables.cWhite,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appTitle: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuList: {
    paddingTop: theme.spacing(0.25),
  },
  menuItem: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'bold',
    padding: 0,
    marginTop: theme.spacing(3),
  },
  divider: {
    height: '1px',
    border: `1px solid ${theme.variables.cOrange}`,
    boxSizing: 'border-box',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  sectionText: {
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: theme.variables.cSlate,
    lineHeight: '16px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
  subList: {
    paddingLeft: theme.spacing(2),
  },
}));

const DetailsSkeleton = ({
  pageTitle,
  mainInfoHeadData,
  children,
  match,
  endAdornment,
  extraMenuItems,
}) => {
  const classes = useStyles();

  const mainMenuData = {
    upperMenuItems: detailUpperMenuItems(match.url, extraMenuItems),
    subMenuItems: detailSubMenuItems(match.url),
  };

  return (
    <Grid container className={classes.root}>
      <HeaderRow pageTitle={pageTitle} endAdornment={endAdornment} />
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <MainInfoHead
          imageSrc={mainInfoHeadData.imageSrc}
          imageAltText={mainInfoHeadData.imageAltText}
          name={mainInfoHeadData.name}
          description={mainInfoHeadData.description}
          settingsLink={mainInfoHeadData.settingsLink}
        >
          {mainInfoHeadData.children}
        </MainInfoHead>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.container}
        >
          <InfoBox title="Overview" variant="important" xs={12} lg={2}>
            <List className={classes.menuList}>
              {mainMenuData.upperMenuItems}
              {/* {mainMenuData.containerMenuItems} */}
              {/* TODO uncomment when to display second overview section */}
              {/* <div className={classes.divider} />
              {mainMenuData.subMenuItems} */}
            </List>
          </InfoBox>
          <Grid item container xs={12} lg={10} md={10} sm={10}>
            <Switch>
              <Route exact path={match.url}>
                {children}
              </Route>
              <Route exact path={`${match.url}/photos`}>
                <PhotosComponent vehicleId={match.params.id} />
              </Route>
              <Route exact path={`${match.url}/documents`}>
                Documents
              </Route>
              <Route exact path={`${match.url}/comments`}>
                Comments
              </Route>
              <Route component={WhenNotFound} />
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsSkeleton.propTypes = {
  pageTitle: PropTypes.string,
  mainInfoHeadData: PropTypes.shape({
    imageSrc: PropTypes.string,
    imageAltText: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    settingsLink: PropTypes.string,
    children: PropTypes.node,
  }),
  children: PropTypes.node,
  match: ReactRouterPropTypes.match.isRequired,
  endAdornment: PropTypes.element,
  extraMenuItems: PropTypes.element,
};

DetailsSkeleton.defaultProps = {
  pageTitle: '',
  mainInfoHeadData: {
    imageSrc: '',
    imageAltText: '',
    name: '',
    description: '',
    settingsLink: '',
    children: null,
  },
  children: null,
  endAdornment: null,
  extraMenuItems: null,
};

export default withRouter(DetailsSkeleton);
