import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import Map from '@wastehero/storybook/lib/components/Map';
import DashboardFilterForm from '@wastehero/storybook/lib/components/Form/Forms/Dashboard/Filter';
import ContainerDrawer from '@wastehero/storybook/lib/components/Drawer/Drawers/Containers';
import { Card, Col, Row, Spin } from 'antd';
import { getSavedActiveCompanies, getSavedActiveProjects } from '../../../shared/utils';
import { getMapCenter } from '../../../shared/utils/settings';
import ContainerTypeSelect from '../../../design-system/form/select/container-type';
import PickupSettingsSelect from '../../../design-system/form/select/pickup-settings';
import SensorSelect from '../../../design-system/form/select/sensor';
import WasteFractionSelect from '../../../design-system/form/select/waste-fraction';

const allContainerQuery = loader('./../../../graphql/queries/devices/all_containers.graphql');

const tmpMutation = loader('./../../../graphql/mutations/devices/attach_device.graphql');

const allDepotsQuery = loader('./../../../graphql/queries/fleet_management/all_depots.graphql');

// drawer queries
const containerDrawerQuery = loader('./../../../graphql/queries/devices/one_container.graphql');

const Dashboard = () => {
  const mapCenter = useSelector((state) => getMapCenter(state));
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const savedActiveCompanies = useSelector((state) => getSavedActiveCompanies(state));

  const [containerTypeFilter, setContainerTypeFilter] = useState([]);
  const [pickupSettingsFilter, setPickupSettingsFilter] = useState([]);
  const [sensorFilter, setSensorFilter] = useState(undefined);
  const [wasteFractionFilter, setWasteFractionFilter] = useState([]);

  const [showContainers, setShowContainers] = useState(true);
  const [showDepots, setShowDepots] = useState(true);
  // const [showRoutes, setShowRoutes] = useState(true);

  // drawer states
  const [containerDrawer, setContainerDrawer] = useState('');

  const { loading: allContainerDataLoading, data: allContainerData } = useQuery(allContainerQuery, {
    variables: {
      activeProjects: savedActiveProjects,
      containerTypes: containerTypeFilter.map((e) => e.value),
      pickupSetting: pickupSettingsFilter.map((e) => e.value),
      wasteTypes: wasteFractionFilter.map((e) => e.value),
      sensor_Isnull: sensorFilter ? sensorFilter === 'without_sensor' : undefined,
    },
  });

  const { loading: allDepotsLoading, data: allDepotsData } = useQuery(allDepotsQuery, {
    variables: {
      activeProjects: savedActiveProjects,
      activeCompanies: savedActiveCompanies,
    },
  });

  const containerDrawerQueryVariables = {
    id: containerDrawer,
  };

  const containerDrawerQueryVariablesFormatter = (containerQvs) => ({ ...containerQvs });

  const onContainerClick = (containerID) => {
    setContainerDrawer(containerID);
  };

  const containers =
    (showContainers &&
      allContainerData?.allContainers.edges.map(
        ({
          node: {
            id,
            location: { longitude, latitude },
          },
        }) => ({
          id,
          location: {
            longitude,
            latitude,
          },
        }),
      )) ||
    [];

  const depots =
    (showDepots &&
      allDepotsData?.allDepots.edges.map(
        ({
          node: {
            id,
            depotType,
            name,
            location: { longitude, latitude },
          },
        }) => ({
          id,
          name,
          type: depotType === 'depot' ? 'depot' : 'wasteStation',
          location: {
            longitude,
            latitude,
          },
        }),
      )) ||
    [];

  return (
    <>
      <Row style={{ height: '100%' }}>
        <Col span={3}>
          <Card title="Dashboard filters" bordered={false}>
            <DashboardFilterForm
              mutation={tmpMutation}
              containerTypeSelect={<ContainerTypeSelect />}
              pickupSettingsSelect={<PickupSettingsSelect />}
              sensorSelect={<SensorSelect multiple={false} />}
              wasteFractionSelect={<WasteFractionSelect />}
              onSuccess={(values) => {
                if (values?.containerType) {
                  setContainerTypeFilter(values?.containerType);
                } else {
                  setContainerTypeFilter([]);
                }

                if (values?.pickupSettings) {
                  setPickupSettingsFilter(values?.pickupSettings);
                } else {
                  setPickupSettingsFilter([]);
                }

                if (values?.sensor) {
                  setSensorFilter(values?.sensor);
                } else {
                  setSensorFilter(undefined);
                }

                if (values?.wasteFraction) {
                  setWasteFractionFilter(values?.wasteFraction);
                } else {
                  setWasteFractionFilter([]);
                }

                setShowContainers(values?.showContainers);
                setShowDepots(values?.showDepots);
                // setShowRoutes(values?.showRoutes);
              }}
            />
          </Card>
        </Col>
        <Col span={21}>
          {allContainerDataLoading || allDepotsLoading ? (
            <div style={{ height: '100%', textAlign: 'center', marginTop: '10%' }}>
              <Spin size="large" tip="Loading..." />
            </div>
          ) : (
            <Map
              containers={containers}
              inquiries={[]}
              depots={depots}
              routes={[]}
              vehicles={[]}
              onContainerClick={onContainerClick}
              mapBoxProps={{
                center: {
                  longitude: mapCenter.lng,
                  latitude: mapCenter.lat,
                },
                height: '100%',
              }}
            />
          )}
        </Col>
      </Row>
      <ContainerDrawer
        visible={!!containerDrawer}
        onClose={() => setContainerDrawer('')}
        query={containerDrawerQuery}
        queryVariablesFormatter={containerDrawerQueryVariablesFormatter}
        queryVariables={containerDrawerQueryVariables}
      />
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
