import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { IsJson } from '../../../../../shared/utils';
import { daysOptions } from '../../../settings/project/defaults/data';
import { DEFAULT_MAP_CENTER } from '../../../../../shared/utils/constants';

const allContainers = loader('./../../../../../graphql/queries/devices/all_containers.graphql');
const allMobileDevices = loader(
  './../../../../../graphql/queries/devices/all_mobile_devices.graphql',
);
const allSigFoxDevices = loader(
  './../../../../../graphql/queries/devices/all_sigfox_devices.graphql',
);
const allLorawanDevices = loader(
  './../../../../../graphql/queries/devices/all_lorawan_devices.graphql',
);

export const allContainerQuery = {
  query: allContainers,
  variables: {
    isLogs: false,
    isContainerLog: true,
    isFillleveldeviceSet: true,
    isFillleveldeviceSetNotificationSet: false,
    isCurrentEffective: false,
  },
};

export const MQTT_CONNECTION_TYPES = [
  {
    value: 'TTN',
    label: 'LoRaWAN',
  },
  {
    value: 'NB-IoT',
    label: 'NB-IoT',
  },
];

export const getQueryForDeviceType = (device) => {
  // NB-IoT type receive connection subtype in connection type field
  const connectionType = IsJson(device.connectionType.replace(/'/g, '"'))
    ? 'NB-IoT'
    : device.connectionType;

  switch (connectionType) {
    case 'NB-IoT':
      return [allMobileDevices, 'allMobileDevices', 'MobileDeviceTypeEdge'];
    case 'SigFox':
      return [allSigFoxDevices, 'allSigFoxDevices', 'SigFoxDeviceTypeEdge'];
    case 'TTN':
      return [allLorawanDevices, 'allLorawanDevices', 'LorawanDeviceTypeEdge'];
    default:
      return [allLorawanDevices, 'allLorawanDevices', 'LorawanDeviceTypeEdge'];
  }
};

export const handleRemoveAttachedDevice = (index, setAttachedDevices, attachedDevices) => () =>
  setAttachedDevices([...attachedDevices.slice(0, index), ...attachedDevices.slice(index + 1)]);
export const fixedPickupsPeriods = ['week', 'days', 'flexible'];

export const createPickupSettingsSerializer = ({
  pickupMethod,
  containerStatus,
  emptyingIntervalFrom,
  emptyingIntervalTo,
  excludeDays,
  fixedPickupsPeriod,
  amountDaysBetweenPickups,
  pickupInterval,
  allowedHoursFrom,
  allowedHoursTo,
  pickupRepeatPeriod,
  collectionPerWeek,
  minimumDaysBetweenPickup,
  pickupDay,
}) => ({
  pickupMethod,
  containerStatus,
  emptyingIntervalFrom: emptyingIntervalFrom?.format('HH:mm') || null,
  emptyingIntervalTo: emptyingIntervalTo?.format('HH:mm') || null,
  excludeDays: excludeDays || null,
  fixedPickupsPeriod: fixedPickupsPeriods[fixedPickupsPeriod],
  amountDaysBetweenPickups: amountDaysBetweenPickups || null,
  pickupInterval: pickupInterval || null,
  allowedHoursFrom: allowedHoursFrom?.format('HH:mm') || null,
  allowedHoursTo: allowedHoursTo?.format('HH:mm') || null,
  pickupRepeatPeriod: pickupRepeatPeriod || null,
  collectionPerWeek: collectionPerWeek || null,
  minimumDaysBetweenPickup: minimumDaysBetweenPickup || null,
  pickupDay: Object.keys(pickupDay).reduce((acc, key) => {
    const { timeFrom, timeTo, collect } = pickupDay[key];
    return collect
      ? [
          ...acc,
          {
            timeFrom: timeFrom?.format('HH:mm') || null,
            timeTo: timeTo?.format('HH:mm') || null,
            weekday: key,
          },
        ]
      : [...acc];
  }, []),
});

export const createContainerSerializer = (values, selfId, containerPhotoFile) => {
  const {
    containerId,
    selectedContainerType,
    selectedWasteFraction,
    description,
    selectedProject,
    containerPhoto,
    containerType,
    latitude,
    longitude,
    placeId,
    attachedDevices,
    showOnRoute,
    overflowingSettings,
  } = values;

  return {
    selfId,
    containerId,
    containerPhoto: containerPhotoFile || containerPhoto,
    containerTypeId: selectedContainerType,
    wasteFractionId: selectedWasteFraction,
    projectId: selectedProject,
    description,
    showOnRoute,
    longitude,
    latitude,
    placeId,
    height: containerType?.height || null,
    width: containerType?.width || null,
    length: containerType?.length || null,
    diameter: containerType?.diameter || null,
    overflowingSettings:
      overflowingSettings.duration && overflowingSettings.percentage
        ? { duration: overflowingSettings.duration, percentage: overflowingSettings.percentage }
        : null,

    deviceToContainers: attachedDevices
      .filter((item) => !!item.deviceId)
      .map((item) => ({
        id: item.id,
        deviceId: item.deviceId,
        lid: item.lid,
        internalHeight: item.internalHeight,
        offset: item.offset,
        deviceAngle: item.deviceAngle,
        endDate: item.endDate,
        measurementId: item.measurementId,
        customMeasurement: item.customMeasurementId === item.measurementId,
      })),
    ...createPickupSettingsSerializer(values),
  };
};

export const handleClosePopup = (
  setFieldValue,
  setFieldTouched,
  setIsMapDialogueOpen,
  setMultipleField,
) => (locationCoord) => {
  const [longitude, latitude, address] = locationCoord || [];

  if (setMultipleField) {
    let newLong = longitude;
    let newLat = latitude;
    let newAddress = address;

    if (!longitude && !latitude) {
      [newLong, newLat] = DEFAULT_MAP_CENTER;
      newAddress = 'Default address';
    }

    setMultipleField({
      longitude: newLong,
      latitude: newLat,
      address: newAddress,
    });
  } else if (!longitude && !latitude) {
    setFieldValue('longitude', DEFAULT_MAP_CENTER[0], true);
    setFieldValue('latitude', DEFAULT_MAP_CENTER[1], true);
  } else {
    setFieldValue('longitude', longitude, true);
    setFieldValue('latitude', latitude, true);
  }

  setTimeout(() => {
    setFieldTouched('longitude', true, true);
    setFieldTouched('latitude', true, true);
  }, 0);
  setIsMapDialogueOpen(false);
};

export const onChangeContainerType = (
  setFieldValue,
  setFieldTouched,
  editMeasurements,
  value,
  allContainerTypes,
) => {
  const selectedContainerType = allContainerTypes.find(
    (containerType) => containerType.value === value,
  );

  if (!editMeasurements && selectedContainerType) {
    setFieldValue('containerType', { ...selectedContainerType }, true);
    setTimeout(() => setFieldTouched('containerType', true, true), 0);
  }
};

export const muiErrorClass = 'error-label';

export const serializeAttachedDevice = ({ node: deviceToContainer }) => {
  const lid = deviceToContainer.sideLid ? 'side' : 'top';
  const { internalHeight, deviceAngle, offset, endDate } = deviceToContainer;

  const deviceId = deviceToContainer?.device?.id;
  const measurementSettings = deviceToContainer.measurementSettings || {};

  return {
    devId: deviceToContainer?.device?.devId,
    deviceId,
    lid,
    internalHeight,
    offset,
    deviceAngle,
    endDate,
    measurementId: measurementSettings.id || '',
    customMeasurementId: measurementSettings.custom ? measurementSettings.id : '',
    customMeasurement: measurementSettings.custom
      ? [
          {
            value: measurementSettings.id,
            label: measurementSettings.name,
          },
        ]
      : [],
  };
};

export const defaultPickupDay = () =>
  daysOptions.reduce(
    (acc, { value }) => ({
      ...acc,
      [value]: {
        collect: false,
        timeFrom: null,
        timeTo: null,
      },
    }),
    {},
  );

export const serializePickupDay = ({ edges = [] } = {}) => {
  const defaultValue = defaultPickupDay();
  edges.forEach(({ node: { timeFrom, timeTo, weekday } = {} }) => {
    defaultValue[weekday] = {
      collect: true,
      timeFrom: moment(timeFrom, 'HH:mm:ss'),
      timeTo: moment(timeTo, 'HH:mm:ss'),
    };
  });
  return defaultValue;
};

export const serializePickupSettings = (pickupSettings) =>
  Object.keys(pickupSettings).reduce((acc, key) => {
    let value = pickupSettings[key];

    if (IsJson(value)) {
      value = JSON.parse(value);
    }

    if (key === 'fixedPickupsPeriod') {
      value = value && fixedPickupsPeriods.indexOf(value);
    }

    if (key === 'pickupDay') {
      value = serializePickupDay(value);
    }

    if (
      value &&
      ['emptyingIntervalFrom', 'emptyingIntervalTo', 'allowedHoursFrom', 'allowedHoursTo'].indexOf(
        key,
      ) >= 0
    ) {
      value = moment(value, 'HH:mm');
    }

    return {
      ...acc,
      ...(value !== null && key !== '__typename' && { [key]: value }),
    };
  }, {});

export const pickupMethodsValues = {
  all: 'all',
  fixed: 'fixed',
  dynamic: 'dynamic',
};

export const containerStatusesValues = {
  available: 'available',
  onHold: 'on_hold',
  defect: 'defect',
};

export const pickupMethods = [
  {
    value: pickupMethodsValues.all,
    label: <FormattedMessage id="pickupMethod.label.all" defaultMessage="All" />,
  },
  {
    value: pickupMethodsValues.fixed,
    label: <FormattedMessage id="pickupMethod.label.static" defaultMessage="Static" />,
  },
  {
    value: pickupMethodsValues.dynamic,
    label: <FormattedMessage id="pickupMethod.label.dynamic" defaultMessage="Dynamic" />,
  },
];

export const containerStatuses = [
  {
    value: containerStatusesValues.available,
    label: <FormattedMessage id="pickupMethod.label.available" defaultMessage="Available" />,
  },
  {
    value: containerStatusesValues.onHold,
    label: <FormattedMessage id="pickupMethod.label.onHold" defaultMessage="On hold" />,
  },
  {
    value: containerStatusesValues.defect,
    label: <FormattedMessage id="pickupMethod.label.defect" defaultMessage="Defect" />,
  },
];

export const pickupIntervals = [
  {
    value: 'twice_a_week',
    label: <FormattedMessage id="pickupInterval.label.twiceAWeek" defaultMessage="Twice a week" />,
  },
  {
    value: 'three_times_a_week',
    label: (
      <FormattedMessage
        id="pickupInterval.label.threeTimesAWeek"
        defaultMessage="Three times a week"
      />
    ),
  },
  {
    value: 'four_times_a_week',
    label: (
      <FormattedMessage
        id="pickupInterval.label.fourTimesAWeek"
        defaultMessage="Four times a week"
      />
    ),
  },
  {
    value: 'five_times_a_week',
    label: (
      <FormattedMessage
        id="pickupInterval.label.fiveTimesAWeek"
        defaultMessage="Five times a week"
      />
    ),
  },
  {
    value: 'sixth_times_a_week',
    label: (
      <FormattedMessage
        id="pickupInterval.label.sixthTimesAWeek"
        defaultMessage="Sixth times a week"
      />
    ),
  },
  {
    value: 'first_working_day_in_a_month',
    label: (
      <FormattedMessage
        id="pickupInterval.label.firstWorkingDayInAMonth"
        defaultMessage="First working day in a month (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
  {
    value: 'fixed_date_every_month',
    label: (
      <FormattedMessage
        id="pickupInterval.label.fixedDateEveryMonth"
        defaultMessage="Fixed date every month (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
  {
    value: 'first_day_every_second_month',
    label: (
      <FormattedMessage
        id="pickupInterval.label.firstDayEverySecondMonth"
        defaultMessage="First day every second month (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
  {
    value: 'last_day_every_second_month',
    label: (
      <FormattedMessage
        id="pickupInterval.label.lastDayEverySecondMonth"
        defaultMessage="Last day every second month (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
  {
    value: 'fixed_date_every_second_month',
    label: (
      <FormattedMessage
        id="pickupInterval.label.fixedDateEverySecondMonth"
        defaultMessage="Fixed date every second month (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
  {
    value: 'first_working_day_every_quarter',
    label: (
      <FormattedMessage
        id="pickupInterval.label.firstWorkingDayEveryQuarter"
        defaultMessage="First working day every quarter (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
  {
    value: 'last_day_every_second_quarter',
    label: (
      <FormattedMessage
        id="pickupInterval.label.lastDayEverySecondQuarter"
        defaultMessage="Last day every second quarter (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
  {
    value: 'fixed_day_every_quarter_',
    label: (
      <FormattedMessage
        id="pickupInterval.label.fixedDayEveryQuarter"
        defaultMessage="Fixed day every quarter (change 'Minimum days..' to 'Select weekday')"
      />
    ),
  },
];

export const initialValues = {
  id: '',
  containerPhotoUrl: '',
  containerId: '',
  description: '',
  showOnRoute: false,
  selectedProject: '',
  selectedContainerType: '',
  selectedWasteFraction: '',
  containerType: {
    isCylindrical: false,
    length: 0,
    width: 0,
    diameter: 0,
    height: 0,
  },
  longitude: 0,
  latitude: 0,
  placeId: '',
  attachedDevices: [],
  redirectToAttachDevice: false,

  // Pickup settings fields
  pickupMethod: pickupMethodsValues.fixed,
  containerStatus: containerStatusesValues.available,
  emptyingIntervalFrom: null,
  emptyingIntervalTo: null,
  excludeDays: [],
  fixedPickupsPeriod: '',
  amountDaysBetweenPickups: '',
  pickupInterval: '',
  allowedHoursFrom: null,
  allowedHoursTo: null,
  pickupRepeatPeriod: '',
  collectionPerWeek: '',
  minimumDaysBetweenPickup: '',
  pickupDay: defaultPickupDay(),
  overflowingSettings: {
    duration: '',
    percentage: '',
  },
};

export const attachDeviceSerializer = (values) => {
  const {
    deviceId,
    containerId,
    projectId,
    lid,
    internalHeight,
    deviceAngle,
    offset,
    measurementId,
    customMeasurementId,
  } = values;

  return {
    deviceId,
    containerId,
    projectId,
    measurementId,
    lid,
    customMeasurement: customMeasurementId === measurementId,
    internalHeight,
    deviceAngle: (deviceAngle || 0).toFixed(4),
    offset: (offset || 0).toFixed(4),
  };
};

export const validateHeight = (containers, intl) => (values) => {
  const errors = {};
  const { internalHeight, offset } = values;

  if (values.containerId) {
    const currentContainer = containers.find(({ value }) => value === values.containerId);
    const height = currentContainer?.allContainers?.height;
    if (height) {
      if (internalHeight > height) {
        errors.internalHeight = `${intl.formatMessage({
          id: 'validateHeight.equalityError',
          defaultMessage: 'Should be less or equal to height',
        })}`;
      }
      if (offset > height) {
        errors.offset = `${intl.formatMessage({
          id: 'validateHeight.equalityError',
          defaultMessage: 'Should be less or equal to height',
        })}`;
      }
    }
  }
  return errors;
};
