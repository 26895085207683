import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { messageWrongCoords, maxLengthStringMessage } from '../../../../../shared/utils';
import { DEPOTS_TYPE_VALUES } from '../../components/utils';

const depotEditValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required().max(255, maxLengthStringMessage(255)),
    description: Yup.string().max(250, maxLengthStringMessage(250)),
    latitude: Yup.number()
      .required()
      .min(-90, messageWrongCoords(90))
      .max(90, messageWrongCoords(90))
      .notOneOf([0], 'This field is required'),
    longitude: Yup.number()
      .required()
      .min(-180, messageWrongCoords(180))
      .max(180, messageWrongCoords(180))
      .notOneOf([0], 'This field is required'),
    placeId: Yup.string(),
    address: Yup.string(),
    depotType: Yup.string().required(),
    geofenceRadius: Yup.number(),
    logo: Yup.string(),
    companyId: Yup.string(),
    contactPerson: Yup.object().shape({
      name: Yup.string().max(255, maxLengthStringMessage(255)),
      email: Yup.string().email(
        <FormattedMessage id="validation.invalid_email" defaultMessage="Invalid email" />,
      ),
      phone: Yup.string()
        .transform((phoneNumberString) => phoneNumberString.replace(/\s/g, ''))
        .matches(/^([+]{1})?[1-9]{1}[0-9]{3,14}$/, {
          message: (
            <FormattedMessage
              id="validation.number.invalid"
              defaultMessage="Please enter valid number."
            />
          ),
          excludeEmptyString: false,
        }),
    }),
    amountOfTrucks: Yup.number(),
    timeToUnloadTruck: Yup.mixed().time(),
    timeToLeaveTheFacility: Yup.mixed().time(),
    workshopType: Yup.string().when('depotType', {
      is: DEPOTS_TYPE_VALUES.workshop,
      then: Yup.string().required(),
    }),
    wasteFractions: Yup.array().when('depotType', {
      is: DEPOTS_TYPE_VALUES.wasteStation,
      then: Yup.array()
        .of(
          Yup.object().shape({
            wasteFraction: Yup.string().required(),
          }),
        )
        .required(),
    }),
  });

export default depotEditValidationSchema;
