/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { loader } from 'graphql.macro';
import React, { useEffect } from 'react';

const allDepotsQuery = loader('../../graphql/queries/fleet_management/all_depots.graphql');
const allVehicleTypesQuery = loader(
  '../../graphql/queries/fleet_management/all_vehicle_types.graphql',
);

const useAllowAddVehicle = () => {
  const activeCompanies = useSelector((state) =>
    Array.from(
      new Set(state.settings?.user.activeProjects.edges.map(({ node }) => node.company.id)),
    ),
  );
  const {
    loading: depotsLoading,
    data: { allDepots: { totalCount: depotsCount = 0 } = {} } = {},
    refetch: refetchDepots,
  } = useQuery(allDepotsQuery, {
    variables: { activeCompanies, pageSize: 0, type: 'depot' },
  });
  const {
    loading: wasteStationLoading,
    data: { allDepots: { totalCount: wasteStationCount = 0 } = {} } = {},
    refetch: refetchWasteStations,
  } = useQuery(allDepotsQuery, {
    variables: { activeCompanies, pageSize: 0, type: 'waste_station' },
  });
  const {
    loading: vehicleTypesLoading,
    data: { allVehicleTypes: { totalCount: vehicleTypesCount = 0 } = {} } = {},
    refetch: refetchVehicleTypes,
  } = useQuery(allVehicleTypesQuery, {
    variables: { pageSize: 0, activeCompanies },
  });

  useEffect(() => {
    if (!vehicleTypesLoading) {
      refetchVehicleTypes().catch((error) => window.console.error(error));
    }
    if (!depotsLoading) {
      refetchDepots().catch((error) => window.console.error(error));
    }
    if (!wasteStationLoading) {
      refetchWasteStations().catch((error) => window.console.error(error));
    }
  }, [refetchDepots, refetchVehicleTypes, refetchWasteStations]);

  const isLoading = depotsLoading || vehicleTypesLoading || wasteStationLoading;
  const forbidAddVehicle =
    isLoading || depotsCount === 0 || vehicleTypesCount === 0 || wasteStationCount === 0;

  const getErrorMessage = () => {
    const errorMessages = [];
    let generalMessage = '';

    if (vehicleTypesCount === 0) {
      errorMessages.push('vehicle type');
    }
    if (depotsCount === 0) {
      errorMessages.push('depot');
    }
    if (wasteStationCount === 0) {
      errorMessages.push('waste station');
    }

    switch (errorMessages.length) {
      case 1:
        [generalMessage] = errorMessages;
        break;
      case 2:
        generalMessage = errorMessages.join(' and ');
        break;
      case 3:
        generalMessage = `${errorMessages[0]}, ${errorMessages.slice(1).join(' and ')}`;
        break;
      default:
        break;
    }

    return (
      <FormattedMessage
        id="vehicle_adding_error"
        defaultMessage="Before adding a vehicle, please add at least one {requiredModels}."
        values={{ requiredModels: generalMessage }}
      />
    );
  };

  const handleShowError = () => {
    toast.error(getErrorMessage());
  };

  return {
    forbidAddVehicle,
    errorMessage: getErrorMessage(),
    isLoading,
    handleShowError,
  };
};

export default useAllowAddVehicle;
