import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { setUser } from '../../../../../actions';
import ProjectSettings from './projectSettings';
import ContainerSettings from './containerSettings';
import WasteFractionsSettings from './wasteFractionsSettings';
import MeasurementSettings from './measurementSettings';
import ProjectOverview from './projectOverview';

const TabPanel = ({ value, slug, children }) =>
  value === slug ? <div role="tabpanel">{children}</div> : null;

TabPanel.propTypes = {
  value: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

const TabLabel = (props) => {
  const { step, label } = props;
  return (
    <span>
      {step && (
        <Typography variant="caption" component="span" className="stepLabel">
          <b>
            {step}
            &nbsp;
            <FormattedMessage id="step" defaultMessage="step" />
          </b>
        </Typography>
      )}
      <br />
      <Typography variant="body1" component="span">
        {label}
      </Typography>
    </span>
  );
};
TabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  step: PropTypes.string,
};

TabLabel.defaultProps = {
  step: '',
};

const useStyles = makeStyles({
  root: {
    height: '80%',
  },
  header: {
    marginTop: '60px',
    marginBottom: '35px',
  },
  content: {
    margin: '35px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    '& > div': {
      '& > div': {
        marginTop: '20px',
        'justify-content': 'space-evenly',
        '& .stepLabel': {
          textTransform: 'none',
        },
      },
      '& > .MuiTabs-indicator': {
        top: 'inherit',
        bottom: 0,
        borderRadius: 0,
        backgroundColor: '#df9838',
        height: '2px',
      },
    },
  },
  tabLabel: {
    color: '#9aa8ad',
    'text-align': 'start !important',
    '&.Mui-selected': {
      color: '#df9838',
    },
    textTransform: 'none',
  },
});

const Settings = ({ match, history }) => {
  const { params: { id: projectId, step, forCompany } = {} } = match;
  const [activeTab, setActiveTab] = useState(step || 'project-settings');

  useEffect(() => {
    setActiveTab(step || 'project-settings');
  }, [step]);

  const handleTabChange = (event, value) => {
    if (projectId) {
      history.push(`/app/settings/${projectId}/${value}`);
    }
  };

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Settings tabs"
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.tabs}
        >
          <Tab
            label={
              <TabLabel
                step="1"
                label={intl.formatMessage({
                  id: 'tabs.project_settings',
                  defaultMessage: 'Project settings',
                })}
              />
            }
            className={classes.tabLabel}
            value="project-settings"
          />
          <Tab
            label={
              <TabLabel
                step="2"
                label={intl.formatMessage({
                  id: 'tabs.container_settings',
                  defaultMessage: 'Container settings',
                })}
              />
            }
            className={classes.tabLabel}
            value="container-settings"
          />
          <Tab
            label={
              <TabLabel
                step="3"
                label={intl.formatMessage({
                  id: 'tabs.waste_fraction_settings',
                  defaultMessage: 'Waste fractions settings',
                })}
              />
            }
            className={classes.tabLabel}
            value="waste-fractions-settings"
          />
          <Tab
            label={
              <TabLabel
                step="4"
                label={intl.formatMessage({
                  id: 'tabs.measurements_settings',
                  defaultMessage: 'Measurement settings',
                })}
              />
            }
            className={classes.tabLabel}
            value="measurement-settings"
          />
          <Tab
            label={
              <TabLabel
                label={intl.formatMessage({
                  id: 'tabs.project_overview',
                  defaultMessage: 'Project overview',
                })}
              />
            }
            className={classes.tabLabel}
            value="project-overview"
          />
        </Tabs>

        <TabPanel value={activeTab} slug="project-settings">
          <ProjectSettings forCompany={forCompany} projectId={projectId} />
        </TabPanel>
        <TabPanel value={activeTab} slug="container-settings">
          <ContainerSettings projectId={projectId} />
        </TabPanel>
        <TabPanel value={activeTab} slug="waste-fractions-settings">
          <WasteFractionsSettings projectId={projectId} />
        </TabPanel>
        <TabPanel value={activeTab} slug="measurement-settings">
          <MeasurementSettings projectId={projectId} />
        </TabPanel>
        <TabPanel value={activeTab} slug="project-overview">
          <ProjectOverview projectId={projectId} />
        </TabPanel>
      </Paper>
    </div>
  );
};

Settings.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user));
  },
});

export default connect(
  undefined,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(withRouter(Settings)));
