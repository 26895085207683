import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.variables.VHBlue,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
}));

const FixedLoadingLayout = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

FixedLoadingLayout.propTypes = {
  open: PropTypes.bool,
};

FixedLoadingLayout.defaultProps = {
  open: false,
};

export default FixedLoadingLayout;
