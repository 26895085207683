const styles = (theme) => ({
  dialogHeader: {
    background: theme.variables.VHBlue,
    color: theme.variables.cWhite,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 20,
  },
  closeBtn: {
    minWidth: 20,
    padding: '5px 10px',
  },
  dialogFooter: {
    padding: 20,
    textAlign: 'right',
  },
});

export default styles;
