import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const FullPageWrapperWithText = ({ text }) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    spacing={4}
    className="dashboard h-100"
    style={{ minHeight: '100vh' }}
  >
    <Typography variant="h4">{text}</Typography>
  </Grid>
);

FullPageWrapperWithText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default FullPageWrapperWithText;
