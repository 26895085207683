import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  largeAvatar: {
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
  smallAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  overview: {
    display: 'none',
  },
}));

const Logo = ({ src, name, variant }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar
        alt={name}
        src={src}
        variant="rounded"
        classes={{
          root: classNames({
            [classes.largeAvatar]: variant === 'large',
            [classes.smallAvatar]: variant === 'small',
            [classes.overview]: variant === 'none',
          }),
        }}
      >
        {name
          .split(' ')
          .map((item) => item.slice(0, 1))
          .join('')
          .slice(0, 2)
          .toUpperCase()}
      </Avatar>
    </div>
  );
};

Logo.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['large', 'small', 'none']),
};

Logo.defaultProps = {
  src: '',
  variant: 'big',
};

export default Logo;
