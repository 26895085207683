import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {
    display: (inlineItems) => (inlineItems ? 'inline-flex' : ''),
    '& .MuiInputLabel-root': {
      marginLeft: 15,
    },
    '& .MuiInputBase-root': {
      // paddingLeft: 15,
    },
    '& .MuiButton-root': {
      paddingTop: 2,
      paddingBottom: 1,
      paddingLeft: 15,
    },
  },
  tooltipIcon: {
    position: 'absolute',
    zIndex: 1,
    fill: 'lightgray',
    opacity: 0.7,
    width: 13,
    marginTop: -5,
  },
});

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const TooltippedUserControl = React.forwardRef(
  ({ children, tooltipText, tooltipStyle, avoidIcon, inlineItems, className }, ref) => {
    const classes = useStyles(inlineItems);

    return (
      <div className={classNames(classes.root, tooltipStyle, className)}>
        {!avoidIcon && (
          <>
            <StyledTooltip TransitionComponent={Zoom} arrow title={tooltipText} ref={ref}>
              <InfoIcon className={classes.tooltipIcon} />
            </StyledTooltip>
            {React.Children.toArray(children).map((child) => child)}
          </>
        )}
        {avoidIcon && (
          <StyledTooltip TransitionComponent={Zoom} arrow title={tooltipText} ref={ref}>
            {React.Children.only(children)}
          </StyledTooltip>
        )}
      </div>
    );
  },
);

TooltippedUserControl.propTypes = {
  children: PropTypes.node,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  avoidIcon: PropTypes.bool,
  inlineItems: PropTypes.bool,
  tooltipStyle: PropTypes.string,
  className: PropTypes.string,
};

TooltippedUserControl.defaultProps = {
  inlineItems: false,
  avoidIcon: false,
  tooltipStyle: '',
  tooltipText: '',
  children: null,
  className: '',
};

export default TooltippedUserControl;
