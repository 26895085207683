import React, { useState } from 'react';
import { Form, connect, getIn } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  formikComponentMemoizeValueCheck,
  formikInjectedPropsTypes,
  FormikSelect,
  FormikTextField,
  memoizeFields,
} from '../../../../../shared/inputs/formik';
import {
  BODY_MECHANIZATION_TYPE_VALUES,
  BODY_MECHANIZATION_TYPES,
  BODY_TYPES,
  CRANE_TYPES,
  LIFT_MECHANIZATION_TYPE_VALUES,
  LIFT_MECHANIZATION_TYPES,
  LOADING_LOC_TYPES,
  WITH_COMPACTOR_TYPES,
  TYPES_CHOICES_VALUES,
  TYPES_CHOICES_TYPES,
} from '../../components/utils';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import SubmitButtonsSet from '../../../../../shared/buttons/submitButtons';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: 0,
    paddingBottom: 20,
    flexWrap: 'unset',
  },
  paper: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 45,
  },
  paddedRows: {
    '&>div': {
      paddingBottom: 20,
      paddingRight: 45,
    },
  },
}));

const VehicleTypeForm = ({ formik }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { isSubmitting } = formik;
  const [isRegularUser] = useRegularUser();
  const liftingMechanization = getIn(formik.values, 'liftingMechanization');
  const bodyMechanization = getIn(formik.values, 'bodyMechanization');
  const [type, setType] = useState('');

  const handleChangeType = (value) => {
    setType(value);
  };

  return (
    <Form className={classes.root}>
      <FixedLoadingLayout isLoading={isSubmitting} />
      <Grid container className={classNames(classes.paper, classes.paddedRows)}>
        {isRegularUser && (
          <Grid item xs={12} className="text-align-center">
            <FormattedMessage
              id="no_permission_add_vehicle_type"
              defaultMessage="You don’t have permission to add vehicle type"
            >
              {(text) => <span className="no-permission-text">{text}</span>}
            </FormattedMessage>
          </Grid>
        )}
        <Grid item container xs={12} md={6}>
          <Grid item xs={6}>
            <FormikTextField
              required
              id="vehicle_type_name"
              name="name"
              type="text"
              placeholder={intl.formatMessage({
                id: 'vehicle_type.placeholder.name',
                defaultMessage: 'Type C',
              })}
              label={intl.formatMessage({
                id: 'vehicle_type.label.name',
                defaultMessage: 'Vehicle type name',
              })}
            />
          </Grid>
          <Grid item xs={6} className="p-l-10">
            <FormikSelect
              required
              name="Type"
              filledStyle
              placeholder={intl.formatMessage({
                id: 'vehicle_type.placeholder.type',
                defaultMessage: 'Choose the type',
              })}
              label={intl.formatMessage({
                id: 'vehicle_type.label.type',
                defaultMessage: 'Type',
              })}
              valuesList={TYPES_CHOICES_TYPES}
              onChange={handleChangeType}
            />
          </Grid>
        </Grid>
        {(!type || type === TYPES_CHOICES_VALUES.collector) && (
          <>
            <Grid item xs={12} md={6}>
              <FormikSelect
                required
                name="liftingMechanization"
                filledStyle
                placeholder={intl.formatMessage({
                  id: 'vehicle_type.placeholder.lift',
                  defaultMessage: 'Choose the lift machanization',
                })}
                label={intl.formatMessage({
                  id: 'vehicle_type.label.lift',
                  defaultMessage: 'Lift mechanization',
                })}
                valuesList={LIFT_MECHANIZATION_TYPES}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                label={intl.formatMessage({
                  id: 'vehicle_type.label.body',
                  defaultMessage: 'Body',
                })}
                placeholder={intl.formatMessage({
                  id: 'vehicle_type.placeholder.body',
                  defaultMessage: 'Choose the body type',
                })}
                required
                filledStyle
                name="body"
                valuesList={BODY_TYPES}
              />
            </Grid>
            <Collapse
              in={LIFT_MECHANIZATION_TYPE_VALUES.crane === liftingMechanization}
              timeout="auto"
              unmountOnExit
              component={Grid}
              item
              xs={12}
              md={6}
            >
              <FormikSelect
                name="crane"
                label={intl.formatMessage({
                  id: 'vehicle_type.label.crane',
                  defaultMessage: 'Crane',
                })}
                filledStyle
                placeholder={intl.formatMessage({
                  id: 'vehicle_type.placeholder.crane',
                  defaultMessage: 'Didn’t choosen in lifting mehcanization',
                })}
                valuesList={CRANE_TYPES}
                required
              />
            </Collapse>
            <Grid item xs={12} md={6}>
              <FormikSelect
                required
                filledStyle
                name="bodyMechanization"
                placeholder={intl.formatMessage({
                  id: 'vehicle_type.placeholder.body_mechanization',
                  defaultMessage: 'Choose the body mechanization',
                })}
                label={intl.formatMessage({
                  id: 'vehicle_type.label.body_mechanization',
                  defaultMessage: 'Body mechanization',
                })}
                valuesList={BODY_MECHANIZATION_TYPES}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="loadingLocation"
                filledStyle
                required
                placeholder={intl.formatMessage({
                  id: 'vehicle_type.placeholder.loading_loc',
                  defaultMessage: 'Choose the loading location',
                })}
                label={intl.formatMessage({
                  id: 'vehicle_type.label.loading_loc',
                  defaultMessage: 'Loading location',
                })}
                valuesList={LOADING_LOC_TYPES}
              />
            </Grid>
            <Collapse
              in={BODY_MECHANIZATION_TYPE_VALUES.withCompactor === bodyMechanization}
              timeout="auto"
              unmountOnExit
              component={Grid}
              item
              xs={12}
              md={6}
            >
              <FormikSelect
                name="compactor"
                filledStyle
                required
                placeholder={intl.formatMessage({
                  id: 'vehicle_type.placeholder.compactor',
                  defaultMessage: 'Didn’t choosen in body mechanization',
                })}
                label={intl.formatMessage({
                  id: 'vehicle_type.label.compactor',
                  defaultMessage: 'With compactor',
                })}
                valuesList={WITH_COMPACTOR_TYPES}
              />
            </Collapse>
          </>
        )}

        {!isRegularUser && <SubmitButtonsSet />}
      </Grid>
    </Form>
  );
};

VehicleTypeForm.propTypes = {
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

export default connect(
  React.memo(
    VehicleTypeForm,
    memoizeFields([
      ({ formik }) => formik.isSubmitting,
      formikComponentMemoizeValueCheck('liftingMechanization'),
      formikComponentMemoizeValueCheck('bodyMechanization'),
    ]),
  ),
);
