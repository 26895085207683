import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

const MapChart = (props) => {
  const { defaultCenter, defaultZoom, heatmap } = props;
  return (
    <GoogleMapReact
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
      visualization
      heatmap={heatmap}
    />
  );
};

MapChart.propTypes = {
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  defaultZoom: PropTypes.number,
  heatmap: PropTypes.shape({
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
        weight: PropTypes.number,
      }),
    ).isRequired,
    options: PropTypes.shape({
      radius: PropTypes.number,
      opacity: PropTypes.number,
    }).isRequired,
  }),
};

MapChart.defaultProps = {
  defaultCenter: {
    lat: 24.7576616,
    lng: 46.6672936,
  },
  defaultZoom: 11,
  heatmap: null,
};

export default React.memo(MapChart);
