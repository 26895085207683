import { ApolloClient, ApolloLink, split, from } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import history from '../../history';
import introspectionQueryResultData from './fragmentTypes.json';
import introspectionToPossibleTypes from './cache';

const errorMsg401 = 'You do not have permission to perform this action';

const { host, protocol } = window.location;
const secure = protocol.includes('s') ? 'wss' : 'ws';

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_SSL === 'true' ? 'https:' : protocol}//${
    process.env.REACT_APP_API_URL || host
  }/graph-api/`,
});

export const wsUri = `${process.env.REACT_APP_API_SSL === 'true' ? 'wss' : secure}://${
  process.env.REACT_APP_API_URL || host
}/ws`;
const wsLink = new WebSocketLink({
  uri: `${wsUri}/graphql/`,
  options: {
    reconnect: 1000,
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('reactQLJWT');
  operation.setContext({
    headers: {
      authorization: token ? `JWT ${token}` : '',
    },
  });

  return forward(operation);
});

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (
    (networkError && networkError.statusCode === 401 && history.location.pathname !== '/login') ||
    (graphQLErrors?.length && graphQLErrors.find((error) => error.message === errorMsg401))
  ) {
    history.push('/login');
  }
});

const cache = new InMemoryCache({
  possibleTypes: introspectionToPossibleTypes(introspectionQueryResultData),
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export default new ApolloClient({
  link: from([logoutLink, authMiddleware, link]),
  cache,
});
