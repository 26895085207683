import React from 'react';
import FormikSelect from '@wastehero/storybook/lib/components/Form/Elements/Select/Formik';
import PropTypes from 'prop-types';

const SensorSelect = ({ multiple, name, label, required }) => {
  const options = [
    {
      label: 'With sensors',
      value: 'with_sensor',
    },
    {
      label: 'Without sensors',
      value: 'without_sensor',
    },
  ];

  const antd = {
    label,
    labelCol: { span: 24, offset: 0 },
    required,
  };

  return (
    <FormikSelect
      mode={multiple ? 'multiple' : undefined}
      name={name}
      // onSelect={onSelect}
      antd={antd}
      options={options}
    />
  );
};

SensorSelect.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

SensorSelect.defaultProps = {
  multiple: true,
  label: 'Sensor',
  name: 'sensor',
  required: false,
};

export default SensorSelect;
