import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import MapChart from '../../../../shared/charts/map';
import withUserLayoutConfig from '../../../../shared/hoc/WithUserLayoutConfig';
import HeaderRow from '../../../../shared/table/headerRow';
import Drawer from '../../../../shared/drawer';
import PeriodFilter from '../../../../shared/analyticsComponents/periodFilter';
import RegisterContainerTooltipedField from '../../containers/routes/register/components/registerContainerTooltipedField';
import { getSavedActiveProjects } from '../../../../shared/utils';
import { getMapCenter } from '../../../../shared/utils/settings';
import TimeSeriesChart from '../../../../shared/charts/timeSeriesChart';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fafafa',
    width: '100%',
    height: '100%',
  },
  drawerContainerLeft: {
    backgroundColor: '#fafafa',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '600px',
    position: 'relative',
  },
  drawerContainerRight: {
    backgroundColor: '#fafafa',
    paddingRight: theme.spacing(2),
    position: 'relative',
  },
  drawerSpacer: {
    marginTop: theme.spacing(2),
  },
  drawerFormLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#626469',
  },
}));

const analyticsHeatMapsQuery = loader('../../../../graphql/queries/analytics/heat_maps.graphql');
const analyticsTimeSeriesQuery = loader(
  '../../../../graphql/queries/analytics/time_series_weight.graphql',
);

const HeatMaps = (props) => {
  const { savedActiveProjects, mapCenter } = props;
  const classes = useStyles();
  const intl = useIntl();

  // Period filter
  const [availableDays] = useState([]);
  const [period, setPeriod] = useState('last_month');
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().add(1, 'months').endOf('month'));

  const pageTitle = intl.formatMessage({
    id: 'analytics_heat_map',
    defaultMessage: 'Heat Maps',
  });

  // query
  const { data: mapData, loading: mapLoading } = useQuery(analyticsHeatMapsQuery, {
    variables: {
      activeProjects: savedActiveProjects,
      To: endDate,
      From: startDate,
    },
  });

  const { data: chartData } = useQuery(analyticsTimeSeriesQuery, {
    variables: {
      activeProjects: savedActiveProjects,
      To: endDate,
      From: startDate,
    },
  });

  const heatmapPositions = useMemo(() => {
    if (mapData) {
      return mapData.analytics.heatMapWeight.map((o) => ({
        lat: o.latitude,
        lng: o.longitude,
        weight: o.amount,
      }));
    }
    return [];
  }, [mapData]);

  const chartMapSeries = useMemo(() => {
    let data = [];
    if (chartData) {
      data = chartData.analytics.timeSeriesWeight.map((o) => [
        o.timestamp * 1000,
        parseInt(o.amount, 10),
      ]);
    }
    return [
      {
        type: 'column',
        name: 'Waste generated (kg)',
        data,
      },
    ];
  }, [chartData]);

  return (
    <Grid container spacing={0} className={`dashboard h-100 ${classes.container}`}>
      <HeaderRow pageTitle={pageTitle} />
      <Grid className="fullHeightCol" item xs={12} sm={12} md={12} lg={8} xl={8}>
        <div className={classes.drawerContainerLeft}>
          <Drawer title="Map" styleContentPadding={false}>
            <MapChart
              heatmap={{ positions: heatmapPositions, options: { radius: 20, opacity: 0.6 } }}
              defaultCenter={{
                lat: mapCenter.lat,
                lng: mapCenter.lng,
              }}
            />
          </Drawer>
        </div>
      </Grid>
      <Grid className="fullHeightCol h-100" item xs={12} sm={12} md={12} lg={4} xl={4}>
        <div className={classes.drawerContainerRight}>
          <Drawer title="Settings" styleHeight="auto">
            <RegisterContainerTooltipedField
              tooltipText={
                <FormattedMessage
                  id="tooltip.heat_map.Period"
                  defaultMessage="Choose the period from from which you wish to see heat map data from."
                />
              }
            >
              <Grid item xs={12} md={9} className="m-l-15">
                <span className={classes.drawerFormLabel}>Period</span>
              </Grid>
            </RegisterContainerTooltipedField>
            <PeriodFilter
              availableDays={availableDays}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              period={period}
              setPeriod={setPeriod}
              disabled={mapLoading}
            />
          </Drawer>
          <div className={classes.drawerSpacer}>
            <Drawer title="Waste generation" styleHeight="auto" styleContentPadding={false}>
              <TimeSeriesChart series={chartMapSeries} yAxisTitle="Waste generated (kg)" />
            </Drawer>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

HeatMaps.propTypes = {
  savedActiveProjects: PropTypes.string.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  mapCenter: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  mapCenter: getMapCenter(state),
  savedActiveProjects: getSavedActiveProjects(state),
});

export default connect(mapStateToProps)(withUserLayoutConfig('analytics')(HeatMaps));
