import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ListItem, ListItemText, ListItemIcon, List, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ListStyled = ({ items }) => {
  const classes = useStyles();

  return (
    <List>
      {items.map((item, index) => (
        <ListItem key={index} alignItems="flex-start">
          <ListItemIcon>
            <FiberManualRecordIcon className={classes.listCircle} />
          </ListItemIcon>
          <ListItemText
            secondary={<Typography className={classes.listItemText}>{item}</Typography>}
          />
        </ListItem>
      ))}
    </List>
  );
};
ListStyled.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      item: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
};
ListStyled.defaultProps = {
  items: [],
};

export default ListStyled;
