import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from 'recharts';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from '../../../../../../../../shared/inputs/StyledCheckbox';

const Information = ({ classes, container, activeDateTab, setActiveDateTab }) => {
  const intl = useIntl();

  const chartData = useMemo(() => {
    const dataArray = [];
    if (container?.deviceToContainerSet) {
      container.deviceToContainerSet.edges.forEach(({ node: device }) => {
        device.filllevelmeasurementSet?.edges?.forEach(({ node }) => {
          const item = {
            value: +node.fillPercentage.toFixed(2),
            masked: node.fillPercentageMasked ? +node.fillPercentageMasked.toFixed(2) : undefined,
            date: moment(node.createdAt),
          };
          if (activeDateTab === 'day') {
            item.date = item.date.format('HH:mm');
          } else if (['week', 'month', 'quarter'].includes(activeDateTab)) {
            item.date = item.date.format('DD/MM');
          } else {
            item.date = item.date.format('MM/DD/YY');
          }
          dataArray.push(item);
        });
      });
      dataArray.sort((a, b) => {
        if (activeDateTab === 'day') {
          return a.date - b.date;
        }
        return new Date(a.date) - new Date(b.date);
      });
    }
    return dataArray;
  }, [container, activeDateTab]);
  const hasMasked = useMemo(() => chartData.some((item) => item.masked), [chartData]);
  const [isMaskedDesired, setIsMaskedDesired] = useState(true);
  const shouldShowMasked = isMaskedDesired && hasMasked;
  return (
    <>
      <Grid item xs={12} container justify="space-between">
        <Grid item xs={9}>
          <Tabs
            value={activeDateTab}
            onChange={(e, value) => setActiveDateTab(value)}
            className={classes.tabs}
            aria-label="tabs.date_tabs"
          >
            <Tab
              label={intl.formatMessage({
                id: 'tabs.day',
                defaultMessage: 'Day',
              })}
              value="day"
              className={classes.dateTabs}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.week',
                defaultMessage: 'Week',
              })}
              value="week"
              className={classes.dateTabs}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.month',
                defaultMessage: 'Month',
              })}
              value="month"
              className={classes.dateTabs}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.quarter',
                defaultMessage: 'Quarter',
              })}
              value="quarter"
              className={classes.dateTabs}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.year',
                defaultMessage: 'Year',
              })}
              value="year"
              className={classes.dateTabs}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.all_time',
                defaultMessage: 'All time',
              })}
              value="allTime"
              className={classes.dateTabs}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.chartBlock}>
          <FormattedMessage id="no_date" defaultMessage="No data">
            {(txt) => (
              <span
                className={classes.absoluteCentered}
                style={{ display: chartData.length ? 'none' : 'inline' }}
              >
                {txt}
              </span>
            )}
          </FormattedMessage>
          <ResponsiveContainer width="99.9%" heigth="99.9%">
            <AreaChart
              data={chartData}
              margin={{
                top: 15,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="date" />
              <YAxis domain={[0, 100]} />
              <ChartTooltip />
              <Area type="monotone" stackId="1" dataKey="value" stroke="#df9838" fill="#e58e3e" />
              {shouldShowMasked && (
                <Area
                  type="monotone"
                  stackId="2"
                  dataKey="masked"
                  label="masked"
                  stroke="#82ca9d"
                  fill="#389e74"
                />
              )}
            </AreaChart>
          </ResponsiveContainer>
          {hasMasked && (
            <div className={classes.showMasked}>
              <FormattedMessage
                id="container_details.show-masked-checkbox"
                defaultMessage="Show masked"
              >
                {(txt) => (
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={isMaskedDesired}
                        onChange={() => setIsMaskedDesired((value) => !value)}
                      />
                    }
                    label={txt}
                  />
                )}
              </FormattedMessage>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

Information.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  container: PropTypes.shape({
    deviceToContainerSet: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            filllevelmeasurementSet: PropTypes.shape({
              edges: PropTypes.arrayOf(
                PropTypes.shape({
                  node: PropTypes.shape({
                    fillPercentage: PropTypes.number,
                    fillPercentageMasked: PropTypes.number,
                    createdAt: PropTypes.string,
                  }),
                }),
              ),
            }),
          }),
        }),
      ),
    }),
  }).isRequired,
  activeDateTab: PropTypes.string.isRequired,
  setActiveDateTab: PropTypes.func.isRequired,
};

export default Information;
