import React from 'react';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Galka from '../../../images/avatar_size/galka.png';

const useStyles = makeStyles((theme) => ({
  localeSelect: {
    '& .MuiInput-root': {
      borderBottom: 'none',
      minHeight: 48,
      '& .MuiSelect-root': {
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        backgroundColor: ({ bkgdStyle }) => `${theme.variables[bkgdStyle]} !important`,
        borderRadius: 10,
        '&[aria-expanded="true"]': {
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderRadius: 10,
        },
      },
    },
  },
  localeImage: {
    marginRight: theme.spacing(1),
  },
  localeChoiceContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paper: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 22,
    borderBottomLeftRadius: 22,
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.1)',
    backgroundColor: ({ bkgdStyle }) => `${theme.variables[bkgdStyle]} !important`,
    width: 'auto',
    '& > .MuiList-root': {
      paddingTop: 0,
    },
    '& .MuiListItem-button': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .Mui-selected': {
      position: 'relative',
      backgroundColor: 'inherit',
      '&::after': {
        position: 'absolute',
        content: '""',
        width: '12px',
        height: '10px',
        top: '50%',
        right: '2px',
        transform: 'translateY(-50%)',
        borderRadius: '50%',
        backgroundImage: `url(${Galka})`,
      },
    },
  },
}));

const StyledImgSelect = ({ valuesList, value, onChange, bkgdStyle, inputClass }) => {
  const classes = useStyles({ bkgdStyle });

  return (
    <Grid item>
      <TextField
        select
        className={classNames([classes.localeSelect, inputClass])}
        value={value}
        onChange={onChange}
        SelectProps={{
          renderValue: (renderValue) => {
            /* eslint-disable-next-line react/prop-types */
            const choice = valuesList.find((item) => renderValue === item.value);
            if (choice) {
              return (
                <>
                  <img src={choice.icon} alt={choice.value} className={classes.localeImage} />
                  {choice.label}
                </>
              );
            }
            return <span />;
          },
          MenuProps: {
            classes: {
              paper: classes.paper,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {valuesList.map((choice) => (
          <MenuItem
            key={choice.value}
            value={choice.value}
            className={classNames(classes.localeChoiceContainer)}
          >
            <img src={choice.icon} alt={choice.value} className={classes.localeImage} />
            {choice.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

StyledImgSelect.propTypes = {
  valuesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  bkgdStyle: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
};

StyledImgSelect.defaultProps = {
  inputClass: '',
};

export default StyledImgSelect;
