import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useStyles from '../../shared/styles';
import SupportTextPage from '../../shared/supportTextPage';
import ListStyled from '../../shared/listStyled';

const Maintenance = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <SupportTextPage
          title={<FormattedMessage id="maintenance" defaultMessage="Maintenance" />}
          subTitle={<FormattedMessage id="cleaning" defaultMessage="Cleaning the Sensors" />}
          texts={[
            <FormattedMessage
              id="cleaning_text_one"
              defaultMessage="It is important that the glass on the sensors remain clean to ensure they provide accurate readings. WasteHero recommends cleaning the sensors every 6-12 months."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
        />
        <Grid item xs={11}>
          <Divider className={classes.mainDivider} />
        </Grid>
        <SupportTextPage
          subTitle={
            <FormattedMessage
              id="surface_cleaning"
              defaultMessage="Surface Cleaning (prior to installation)"
            />
          }
          texts={[
            <FormattedMessage
              id="surface_cleaning_text_one"
              defaultMessage="If the container surface is contaminated with any foreign material you must clean it prior to installation of the sensor, otherwise it may interrupt the device installation process."
            />,
            <FormattedMessage
              id="cleaning_text_two"
              defaultMessage="Cleaning should be more regular if the sensors:."
            />,
            <ListStyled
              items={[
                <FormattedMessage
                  id="cleaning_text_list_item_first"
                  defaultMessage="are exposed to adverse weather conditions."
                />,
                <FormattedMessage
                  id="cleaning_text_list_item_second"
                  defaultMessage="are taking a high frequency of measurements."
                />,
                <FormattedMessage
                  id="cleaning_text_list_item_third"
                  defaultMessage="are exposed to waste fractions with oily residue."
                />,
              ]}
            />,
            <FormattedMessage
              id="surface_cleaning_text_second"
              defaultMessage="We recommend using an ultra-fine microfiber cloth (suitable for glass and lenses) and isopropyl alcohol as a cleaning agent. Read more about these items in the <linkText>Equipment for Installation section</linkText>."
              values={{
                linkText: (text) => (
                  <Link to="sensor-equipment-install" className={classes.links}>
                    {text}
                  </Link>
                ),
              }}
            />,
            <FormattedMessage
              id="cleaning_text_list_text_third"
              defaultMessage="Moisten a microfiber cloth with isopropyl alcohol. The cloth should be damp but not dripping, so be sure to squeeze any excess solution from the cloth. Wipe the moistened cloth over the glass surface in smooth motions. Do not press hard on the glass surface or rub repeatedly in one spot."
            />,
            <FormattedMessage
              id="cleaning_text_list_text_fifth"
              defaultMessage="If pooling or streaks occur, you may have too much solution on the cloth. Ensure there are no visible impurities left on the glass. You may wish to wipe the glass surface with a dry microfiber cloth if you have used too much solution and the alcohol hasn't evaporated or has left streaks"
            />,
          ]}
        />
        <Grid item xs={11}>
          <Divider className={classes.mainDivider} />
        </Grid>
        <SupportTextPage
          subTitle={<FormattedMessage id="data_errors" defaultMessage="Data Errors" />}
          texts={[
            <FormattedMessage
              id="data_errors_text_first"
              defaultMessage="The WasteHero Platform will notify you if a sensor is gathering irregular data. The notification will instruct you to clean and/or check the mounting of a specific sensor."
            />,
            <FormattedMessage
              id="data_errors_text_second"
              defaultMessage="In the unlikely circumstance that the sensor is reporting irregular data, this is how to analyze and solve the problem."
            />,
            <FormattedMessage
              id="data_errors_text_third"
              defaultMessage="<bold>Sensor does not report data.</bold>"
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="data_errors_text_fourth"
              defaultMessage="The most likely problem here is that the sensor has not been installed correctly or has become loose. Check if the sensor is still in place and has been mounted correctly. Check the installation instructions to ensure that the sensor has been correctly mounted."
            />,
            <FormattedMessage
              id="data_errors_text_fifth"
              defaultMessage="<bold>Sensor reports consistently full.</bold>"
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="data_errors_text_sixth"
              defaultMessage="If the sensor is reporting consistently full, it is likely that the sensor needs to be cleaned. As a first step, ensure that the sensor is still in place and is correctly mounted. Clean the glass on the sensor according to the cleaning instructions under <bold>Sensor > Maintenance > Cleaning</bold> in the support center."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Maintenance;
