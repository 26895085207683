import React, { useCallback, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Table, TableBody, TableContainer } from '@material-ui/core';
import { toastifyError } from '../../../../shared/utils';
import LoadingLayout from '../../../../shared/loading';
import HeaderRow from './headerRow';
import FilterRow from './filterRow';
import LogoContainer from './logo';
import { StyledTableCell, StyledTableRow } from './styledTableComponents';
import ConfirmDeletionDialog from '../../../../shared/dialog/ConfirmDeletionDialog';
import StyledTablePagination from '../../../../shared/table/pagination';
import ActionButton from './actionButton';
import ActionMenu from './actionMenu';
import useLinkHoverStyle from './linkHoverStyle';
import DummyText from './dummyText';
import StyledEnhancedTableHead from './tableHead';
import { SIZES } from './utils';
import StyledImgSelect from '../../../../shared/inputs/StyledImgSelect';
import ConfirmDialog from '../../../../shared/dialog/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    minHeight: '100%',
  },
  root: {
    backgroundColor: theme.variables.cWhite,
    paddingBottom: '150px',
    minHeight: '100%',
  },
  pageContainer: {
    width: '96%',
  },
  linkTableItem: {
    textDecoration: 'none',
    color: theme.variables.cRichOcean,
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  select: {
    textAlign: 'center',
  },
  actionMenuCellRoot: {
    padding: 0,
    paddingRight: 16,
  },
}));

const ListPageTemplate = ({
  match,
  location,
  extraFilter,
  queryExtraVariables,
  cellsConfig,
  pageTitle,
  deleteItemMutation,
  query,
  autocompleteSerializer,
  autoCompleteQuery,
  itemsSerializer,
  updateCache,
  chooseLogoSize,
  noLogoLink,
  endAdornment,
  userLayoutConfig,
  updateUserConfig,
  activeTableColumns,
  disableSelect,
  deleteItemErrorTitle,
  allowDeleteItem,
  me,
  excludeDeleteOption,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const setLinkHoverStyle = useLinkHoverStyle();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const [deletingItemError, setDeletingItemError] = useState(null);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(true);
  const { order, orderBy, iconsSize, pageSize } = userLayoutConfig;

  /* Don't allow regular user do actions  */
  const allowActions = me.isAdmin || me.isSuperuser || me.isMaster || me.isReseller;

  const allowedCellsConfig =
    allowActions || me.ownProjectsIds.length
      ? cellsConfig
      : cellsConfig.filter((item) => item.id !== 'actionMenu');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    updateUserConfig({
      ...userLayoutConfig,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const queryFilter = useMemo(() => (queryString.parse(location.search) || {}).query, [
    location.search,
  ]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // Abstract value for backed realization select all
      setSelected(['*']);
      return;
    }
    setSelected([]);
  };

  const isAllSelected = selected.indexOf('*') !== -1;
  const isSelected = (id) => selected.indexOf(id) !== -1 || isAllSelected;

  const realPageSize = pageSize > 0 ? pageSize : null;
  const offset = realPageSize > 0 ? realPageSize * page : null;

  const { loading: itemsLoading, data: itemsData = {}, refetch } = useQuery(query, {
    onError: toastifyError,
    suspend: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      name: queryFilter,
      ...queryExtraVariables,
      pageSize: realPageSize,
      offset,
      orderBy,
      order,
    },
  });

  const { totalCount, items } = useMemo(() => itemsSerializer(itemsData), [
    itemsData,
    itemsSerializer,
  ]);

  const handleClick = (event, id) => {
    if (disableSelect) {
      return;
    }

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selected.indexOf('*') === 0) {
      const selectedIndexes = [];
      items.forEach((tableItem) => tableItem.id !== id && selectedIndexes.push(tableItem.id));
      newSelected = selectedIndexes;
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const changeAnchorEl = (event = {}, item = null) => {
    setMenuAnchorEl(event.currentTarget || null);
    setActiveItem(item);
  };

  const deleteHandle = () => {
    if (allowDeleteItem !== null && !allowDeleteItem(activeItem)) {
      setDeletingItemError(activeItem);
      return;
    }
    setDeletingItem(activeItem);
  };
  const refetchQuery = useCallback(
    () =>
      refetch({
        name: queryFilter,
        ...queryExtraVariables,
        realPageSize,
        offset,
        orderBy,
        order,
      }),
    [refetch, queryFilter, queryExtraVariables, realPageSize, offset, orderBy, order],
  );

  const headCellsToDisplay = allowedCellsConfig.filter((cell) =>
    activeTableColumns.some((tableColumn) => tableColumn === cell.id),
  );

  useEffect(() => {
    if (!first) {
      refetchQuery().catch(toastifyError);
      setFirst(false);
    }
  }, [refetchQuery, first]);

  const confirmDeleteDepot = () => {
    deleteItemMutation({
      variables: { selfId: deletingItem.id },
      update: updateCache(deletingItem),
    })
      .then(refetchQuery)
      .then(() =>
        toast.info(
          intl.formatMessage(
            {
              id: 'listPage.delete.success',
              defaultMessage: 'Item {name} successfully removed',
            },
            { name: deletingItem.name },
          ),
        ),
      )
      .catch(toastifyError)
      .finally(() => setDeletingItem(null));
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <LoadingLayout isLoading={itemsLoading} />
        {pageTitle && <HeaderRow pageTitle={pageTitle}>{endAdornment}</HeaderRow>}
        <FilterRow
          query={autoCompleteQuery}
          serializer={autocompleteSerializer}
          queryExtraVariables={queryExtraVariables}
          iconSize={
            chooseLogoSize ? (
              <StyledImgSelect
                valuesList={SIZES}
                value={iconsSize}
                onChange={(e) =>
                  updateUserConfig({ ...userLayoutConfig, iconsSize: e.target.value })
                }
                bkgdStyle="cAntiFlashWhite"
              />
            ) : null
          }
          pagination={
            <StyledTablePagination
              page={page}
              totalCount={totalCount}
              rowsPerPage={pageSize}
              setPage={setPage}
              setRowsPerPage={(newPageSize) =>
                updateUserConfig({
                  ...userLayoutConfig,
                  pageSize: newPageSize,
                })
              }
            />
          }
        >
          <Grid container item xs={12} justify="space-between">
            {extraFilter}
          </Grid>
        </FilterRow>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item className={classes.pageContainer}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="location list">
                  <StyledEnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    classes={classes}
                    onRequestSort={handleRequestSort}
                    numSelected={isAllSelected ? totalCount : selected.length}
                    rowCount={totalCount}
                    onSelectAllClick={handleSelectAllClick}
                    cellsConfig={headCellsToDisplay}
                    noTableHeadCheckbox
                  />
                  <TableBody>
                    {items.map((item) => {
                      const isItemSelected = isSelected(item.id);
                      return (
                        <StyledTableRow
                          key={item.id}
                          onClick={(e) => handleClick(e, item.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          {allowedCellsConfig.some(({ id }) => id === 'actionMenu') && (
                            <StyledTableCell
                              key={item.id}
                              classes={{
                                root: classes.actionMenuCellRoot,
                              }}
                            >
                              {(item.allowActions || allowActions) && (
                                <ActionButton
                                  aria={menuAnchorEl ? 'location-context-menu' : null}
                                  onClick={(event) => changeAnchorEl(event, item)}
                                />
                              )}
                            </StyledTableCell>
                          )}
                          {activeTableColumns.some((tableColumn) => tableColumn === 'name') && (
                            <StyledTableCell padding="none">
                              {!noLogoLink ? (
                                <Link
                                  to={`${match.url}/${item?.id}`}
                                  className={setLinkHoverStyle.linkTableItem}
                                >
                                  <LogoContainer
                                    variant={iconsSize}
                                    src={item.logo}
                                    name={item.name}
                                  />
                                  <span className={setLinkHoverStyle.linkHover}>{item.name}</span>
                                </Link>
                              ) : (
                                <>{item.name}</>
                              )}
                            </StyledTableCell>
                          )}
                          {allowedCellsConfig
                            .filter(({ id }) =>
                              activeTableColumns.some((activeColumn) => activeColumn === id),
                            )
                            .filter(({ id }) => !['name'].includes(id))
                            .map(
                              ({ id }) =>
                                id !== 'actionMenu' && (
                                  <StyledTableCell key={id}>{item[id]}</StyledTableCell>
                                ),
                            )}
                        </StyledTableRow>
                      );
                    })}
                    {!items.length && <DummyText />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <ActionMenu
          anchorElement={menuAnchorEl}
          id={activeItem?.id}
          changeAnchor={changeAnchorEl}
          excludeDeleteOption={excludeDeleteOption && !allowActions}
          onDelete={() => {
            changeAnchorEl();
            deleteHandle();
          }}
        />
        <ConfirmDeletionDialog
          open={!!deletingItem?.id}
          onConfirm={() => confirmDeleteDepot()}
          onClose={() => setDeletingItem(null)}
          title={deletingItem?.name || ''}
          helpText={intl.formatMessage({
            id: 'help_text.remove_item',
            defaultMessage: 'It will remove item from your project!',
          })}
        />
        {deleteItemErrorTitle && (
          <ConfirmDialog
            open={!!deletingItemError}
            onClose={() => setDeletingItemError(null)}
            title={deleteItemErrorTitle}
            noDelete
          />
        )}
      </Grid>
    </div>
  );
};

ListPageTemplate.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  extraFilter: PropTypes.element,
  queryExtraVariables: PropTypes.objectOf(PropTypes.string),
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  deleteItemMutation: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.any).isRequired,
  autocompleteSerializer: PropTypes.func.isRequired,
  autoCompleteQuery: PropTypes.objectOf(PropTypes.any).isRequired,
  itemsSerializer: PropTypes.func.isRequired,
  cellsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ).isRequired,
  updateCache: PropTypes.func,
  chooseLogoSize: PropTypes.bool,
  noLogoLink: PropTypes.bool,
  endAdornment: PropTypes.element,
  updateUserConfig: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string,
  }),
  activeTableColumns: PropTypes.arrayOf(PropTypes.string),
  disableSelect: PropTypes.bool,
  allowDeleteItem: PropTypes.func,
  deleteItemErrorTitle: PropTypes.element,
  me: PropTypes.shape({
    isMaster: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isReseller: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  excludeDeleteOption: PropTypes.bool,
};

ListPageTemplate.defaultProps = {
  extraFilter: null,
  queryExtraVariables: {},
  pageTitle: null,
  chooseLogoSize: true,
  noLogoLink: false,
  endAdornment: null,
  userLayoutConfig: {
    iconsSize: null,
  },
  updateCache: async () => {
    window.console.debug('Cache update function not provided');
  },
  activeTableColumns: [],
  disableSelect: false,
  allowDeleteItem: null,
  deleteItemErrorTitle: null,
  excludeDeleteOption: false,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(withRouter(ListPageTemplate));
