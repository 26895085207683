import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, connect as connectToFormik, getIn } from 'formik';
import { Button, FormControlLabel, Grid, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import Collapse from '@material-ui/core/Collapse';
import { useHistory, withRouter } from 'react-router';
import Fade from '@material-ui/core/Fade';
import Switch from '@material-ui/core/Switch';
import { fixedPickupsPeriods, onChangeContainerType, pickupMethodsValues } from '../shared/utils';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import styles from './styles';
import {
  formikComponentMemoizeCheck,
  FormikTextField,
  memoizeFields,
  formikInjectedPropsTypes,
  FormikImageDropzone,
  FormikTimeDiffFields,
  FormikSelect,
} from '../../../../../shared/inputs/formik';
import RegisterContainerTooltipedField from './components/registerContainerTooltipedField';
import PickupsDetailsDialog from './components/pickupsDetailsDialog';

import InlineAttachDevice from '../attachDeviceInline';
import SelectProject from '../../../../../shared/apiPopulatedSelects/selectProjects';
import SelectContainerType from '../../../../../shared/apiPopulatedSelects/selectContainerType';
import SelectWasteFraction from '../../../../../shared/apiPopulatedSelects/selectWasteFraction';
import SelectPickupMethod from './components/selectPickupMethod';
import SelectContainerStatus from './components/selectContainerStatus';
import InlineAttachedDevice from './components/inlineAttachedDevice';
import ConfirmDialog from '../../../../../shared/dialog/ConfirmDialog';
import FormikCheckbox from '../../../../../shared/inputs/formik/FormikCheckbox';
import { daysOptions } from '../../../settings/project/defaults/data';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import FormikLocationInput from '../../../../../shared/inputs/formik/FormikLocationInput';
import OverflowingSettings from '../../../../../shared/containers/OverflowingSettings';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const RegisterContainerForm = ({
  classes,
  selfId,
  setSelectedProject,
  allMeasurementSettings,
  setContainerPhoto,
  isWizard,
  formik,
  location,
  noProjectSelection,
  initialEditMeasurements,
}) => {
  const history = useHistory();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [confirmOpened, setConfirmOpened] = useState(false);
  const [confirmParams, setConfirmParams] = useState({});
  const [isRegularUser] = useRegularUser();
  const [openAttachSensor, setOpenAttachSensor] = useState(
    location?.state?.forceOpenAttachSensor || false,
  );
  const intl = useIntl();
  const {
    initialValues,
    values,
    submitForm,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = formik;
  const pickupMethod = getIn(values, 'pickupMethod');
  const fixedPickupsPeriod = getIn(values, 'fixedPickupsPeriod');

  const pickupSettingsSelected = useMemo(() => {
    let pickupPeriodSelected;

    if (pickupMethod && pickupMethod === pickupMethodsValues.fixed) {
      pickupPeriodSelected = fixedPickupsPeriod !== '';
    }

    if (pickupMethod && pickupMethod === pickupMethodsValues.dynamic) {
      pickupPeriodSelected = true;
    }
    return pickupPeriodSelected;
  }, [pickupMethod, fixedPickupsPeriod]);

  const [enablePickupSettingsFrom, setEnablePickupSettingsFrom] = useState(
    !!pickupSettingsSelected,
  );

  useEffect(() => {
    if (location?.state?.forceOpenAttachSensor) {
      setOpenAttachSensor(location?.state?.forceOpenAttachSensor);
      setFieldValue('pickupMethod', pickupMethodsValues.dynamic);
    }
  }, [location, setFieldValue]);

  const onSubmitAttachSensor = useCallback(() => {
    setOpenAttachSensor(false);
    history.replace(location.pathname);
  }, [history, location]);

  const handleOpenModal = () => {
    setFieldValue('fixedPickupsPeriod', fixedPickupsPeriods.indexOf('week'));
    setOpenDetailsModal(true);
  };

  useEffect(() => {
    if (getIn(initialValues, 'projectId')) {
      setFieldValue('selectedProject', getIn(initialValues, 'projectId'));
    }
  }, [initialValues, setFieldValue]);

  const onProjectChange = useCallback(
    (value) => {
      if (value === getIn(initialValues, 'selectedProject')) {
        setFieldValue('selectedContainerType', getIn(initialValues, 'selectedContainerType'));
        setFieldValue('selectedWasteFraction', getIn(initialValues, 'selectedWasteFraction'));
      } else {
        setFieldValue('selectedContainerType', '');
        setFieldValue('selectedWasteFraction', '');
      }

      setSelectedProject(value);
      setOpenAttachSensor(false);
      setFieldValue('selectedProject', value);
      setFieldTouched('selectedContainerType', false);
      setFieldTouched('selectedWasteFraction', false);
    },
    [initialValues, setSelectedProject, setFieldValue, setFieldTouched],
  );

  const onContainerTypeChange = useCallback(
    (value, allContainerTypes) =>
      onChangeContainerType(
        setFieldValue,
        setFieldTouched,
        initialEditMeasurements,
        value,
        allContainerTypes,
      ),
    [initialEditMeasurements, setFieldValue, setFieldTouched],
  );

  const changeImage = useCallback(
    ({ file }) => {
      setContainerPhoto(file);
    },
    [setContainerPhoto],
  );

  const changePickupMethod = useCallback(() => {
    setFieldValue('fixedPickupsPeriod', '');
  }, [setFieldValue]);

  const onSubmit = useCallback(
    ({ attachDevice = false } = {}) => async () => {
      if (!enablePickupSettingsFrom) {
        setFieldValue('redirectToAttachDevice', attachDevice);
        await submitForm();
      } else if (!confirmOpened && !pickupSettingsSelected) {
        setConfirmOpened(true);
        setConfirmParams({ attachDevice });
      } else {
        setFieldValue('redirectToAttachDevice', attachDevice);
        await submitForm();
      }
    },
    [confirmOpened, setFieldValue, submitForm, pickupSettingsSelected, enablePickupSettingsFrom],
  );

  const onConfirm = useCallback(() => {
    onSubmit(confirmParams)();
    setConfirmOpened(false);
    setConfirmParams({});
  }, [onSubmit, confirmParams]);

  const handleChangeEnablePickupSettingsFrom = () => {
    setEnablePickupSettingsFrom((prev) => !prev);
  };

  const onCloseWithoutConfirm = useCallback(() => {
    setConfirmOpened(false);
    setConfirmParams({});
  }, []);

  return (
    <Grid container>
      <Form className={classes.root}>
        <FixedLoadingLayout open={isSubmitting} />
        <Grid container justify="center" className={classes.inputColumn}>
          {isRegularUser && (
            <FormattedMessage
              id="no_permission_edit_container"
              defaultMessage="You don’t have permission to add or edit container"
            >
              {(text) => <span className="no-permission-text">{text}</span>}
            </FormattedMessage>
          )}
          <Grid container item xs={12} spacing={3}>
            <Grid container item xs={12} md={8} justify="center" spacing={3}>
              {!selfId && !noProjectSelection && (
                <SelectProject md={9} onChange={onProjectChange} />
              )}

              <RegisterContainerTooltipedField
                tooltipText={
                  <FormattedMessage
                    id="container.tooltip.container_id"
                    defaultMessage="Enter a unique ID for the container"
                  />
                }
              >
                <FormikTextField
                  required
                  fast
                  id="container_id"
                  name="containerId"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'container.placeholder.container_id',
                    defaultMessage: 'E.g. CNY-26201',
                  })}
                  label={intl.formatMessage({
                    id: 'container.label.container_id',
                    defaultMessage: 'Container ID',
                  })}
                />
              </RegisterContainerTooltipedField>
              <RegisterContainerTooltipedField
                tooltipText={
                  <FormattedMessage
                    id="container.tooltip.description"
                    defaultMessage="Enter a description for the container"
                  />
                }
              >
                <FormikTextField
                  id="desc"
                  name="description"
                  fast
                  type="text"
                  multiline
                  placeholder={intl.formatMessage({
                    id: 'container.placeholder.description',
                    defaultMessage: "E.g. 'Container behind the mall'",
                  })}
                  label={intl.formatMessage({
                    id: 'container.label.description',
                    defaultMessage: 'Container description',
                  })}
                />
              </RegisterContainerTooltipedField>
              <Grid item xs={12} md={9} container justify="flex-start">
                <FormControlLabel
                  control={<FormikCheckbox name="showOnRoute" />}
                  classes={{
                    label: classes.label,
                  }}
                  label={intl.formatMessage({
                    id: 'label.showOnRoute',
                    defaultMessage: "Show container's description on route",
                  })}
                />
              </Grid>
              <RegisterContainerTooltipedField
                tooltipText={
                  <FormattedMessage
                    id="tooltip.location"
                    defaultMessage="Choose the location from the map, where the container is placed"
                  />
                }
              >
                <Grid item xs={12} md={9} className="m-l-15">
                  <Typography variant="body2" className={classes.label}>
                    <FormattedMessage id="location" defaultMessage="Location" />
                  </Typography>
                </Grid>
                <FormikLocationInput />
              </RegisterContainerTooltipedField>
              <SelectContainerType onChange={onContainerTypeChange} />
              <SelectWasteFraction />
              <Grid item xs={12} md={9} className="m-l-15">
                <OverflowingSettings />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} direction="column" alignItems="stretch">
              <FormikImageDropzone size="big" name="containerPhotoUrl" onChange={changeImage} />
              <Grid container justify="center" className="m-t-25">
                <FormControlLabel
                  control={
                    <Switch
                      checked={enablePickupSettingsFrom}
                      onChange={handleChangeEnablePickupSettingsFrom}
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({
                    id: 'label.enable_collection_information',
                    defaultMessage: 'Enable collection information',
                  })}
                />
              </Grid>
              <Fade in={enablePickupSettingsFrom}>
                <Grid container justify="space-between" className="m-t-25">
                  <SelectPickupMethod changePickupMethod={changePickupMethod} />
                  <SelectContainerStatus />

                  {values.pickupMethod === pickupMethodsValues.fixed ? (
                    <Grid container item spacing={3} justify="flex-start" className="m-t-15">
                      <Grid item xs={12} className={classes.uploadButtonWrapper}>
                        <Button
                          variant="outlined"
                          type="button"
                          className={classes.uploadButton}
                          onClick={handleOpenModal}
                        >
                          <TooltippedUserControl
                            tooltipText={
                              <FormattedMessage
                                id="tooltip.select_details_for_fixed_pickup"
                                defaultMessage="Click to open dialogue window with details for fixed pickup"
                              />
                            }
                            avoidIcon
                          >
                            <Typography variant="body1">
                              <FormattedMessage
                                id="label.button.select_static_collection"
                                defaultMessage="Select static collection"
                              />
                            </Typography>
                          </TooltippedUserControl>
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid container item spacing={3} className="m-t-15">
                        <Grid container item xs={12} alignItems="center" justify="space-between">
                          <FormikTimeDiffFields
                            label={
                              <FormattedMessage
                                id="emptying_interval"
                                defaultMessage="Emptying interval"
                              />
                            }
                            fromName="emptyingIntervalFrom"
                            toName="emptyingIntervalTo"
                          />
                        </Grid>
                      </Grid>
                      <Grid container item spacing={3} className="m-t-15">
                        <RegisterContainerTooltipedField
                          md={12}
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.exclude_days"
                              defaultMessage="Choose excluded days"
                            />
                          }
                        >
                          <FormikSelect
                            label={intl.formatMessage({
                              id: 'label.exclude_days',
                              defaultMessage: 'Exclude days',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'placeholder.exclude_days',
                              defaultMessage: 'Choose days',
                            })}
                            required
                            fast
                            filledStyle
                            multiSelect
                            name="excludeDays"
                            valuesList={daysOptions}
                          />
                        </RegisterContainerTooltipedField>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Fade>
            </Grid>
          </Grid>
          {!!values.attachedDevices?.length && (
            <Grid container item xs={12} spacing={3}>
              <Grid container item xs={12} md={8} justify="center" spacing={3}>
                <Grid item xs={12} md={9}>
                  <InlineAttachedDevice allMeasurementSettings={allMeasurementSettings} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Form>
      <Grid container justify="center" className={classes.inputColumn}>
        {!isWizard && !isRegularUser && (
          <Grid container item xs={12} spacing={3}>
            <Grid container item xs={12} md={8} justify="center">
              {openAttachSensor && (
                <InlineAttachDevice open={openAttachSensor} onSubmit={onSubmitAttachSensor} />
              )}
              <Grid container item xs={12} md={9} justify="flex-start">
                <Collapse className="w-100" in={!openAttachSensor}>
                  <Grid container item xs={6} md={3} justify="flex-start">
                    <Button
                      id="attach-device-button"
                      variant="contained"
                      color="primary"
                      className={classNames(
                        classes.submitBtn,
                        classes.roundedBtn,
                        'w-100',
                        'm-t-20',
                      )}
                      startIcon={<AddIcon />}
                      onClick={onSubmit({ attachDevice: true })}
                      type="button"
                      disabled={isSubmitting}
                      tourid="attachSensor"
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.attach_device_button"
                            defaultMessage="Save the container and move to attached measuring sensor to this container."
                          />
                        }
                        avoidIcon
                      >
                        <Typography variant="body1">
                          <FormattedMessage
                            id="register.container.attach_sensor"
                            defaultMessage="Attach sensor"
                          />
                        </Typography>
                      </TooltippedUserControl>
                    </Button>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isRegularUser && (
          <Grid container item xs={12} justify="center" alignItems="center" className="m-t-20">
            <Grid
              container
              item
              xs={12}
              md={6}
              justify="center"
              alignItems="center"
              spacing={3}
              tourid="saveContainer"
            >
              {!isWizard ? (
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classNames(classes.submitBtn, classes.roundedBtn, 'w-100')}
                    type="submit"
                    onClick={onSubmit()}
                    disabled={isSubmitting}
                  >
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.save_section"
                          defaultMessage="Save the container without attaching a measuring sensor to it. You can add it later."
                        />
                      }
                      avoidIcon
                    >
                      <Typography variant="body1">
                        {selfId ? (
                          <FormattedMessage id="save" defaultMessage="Save" />
                        ) : (
                          <FormattedMessage id="container.register" defaultMessage="Register" />
                        )}
                      </Typography>
                    </TooltippedUserControl>
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.submitBtn} w-100`}
                    type="submit"
                    onClick={onSubmit()}
                    disabled={isSubmitting}
                  >
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.save_container"
                          defaultMessage="Save the container."
                        />
                      }
                      avoidIcon
                    >
                      <Typography variant="body1">
                        <FormattedMessage id="save" defaultMessage="Save" />
                      </Typography>
                    </TooltippedUserControl>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <PickupsDetailsDialog
        open={openDetailsModal}
        onClose={useCallback(() => setOpenDetailsModal(false), [])}
      />
      <ConfirmDialog
        open={confirmOpened}
        onClose={onCloseWithoutConfirm}
        onConfirm={onConfirm}
        title={intl.formatMessage({
          id: 'confirm.save_container_without_pickup',
          defaultMessage:
            'Are you sure you want create the container without any collection settings?',
        })}
      />
    </Grid>
  );
};

RegisterContainerForm.propTypes = {
  selfId: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isWizard: PropTypes.bool.isRequired,
  setSelectedProject: PropTypes.func.isRequired,
  setContainerPhoto: PropTypes.func.isRequired,
  allMeasurementSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  noProjectSelection: PropTypes.bool,
  initialEditMeasurements: PropTypes.bool,
};

RegisterContainerForm.defaultProps = {
  selfId: '',
  noProjectSelection: false,
  initialEditMeasurements: false,
};

export default withRouter(
  withStyles(styles)(
    connectToFormik(
      React.memo(
        RegisterContainerForm,
        memoizeFields([
          'selfId',
          'classes',
          'isWizard',
          'setSelectedProject',
          'setContainerPhoto',
          'allMeasurementSettings',
          'location',
          'noProjectSelection',
          'initialEditMeasurements',
          ...formikComponentMemoizeCheck('pickupMethod'),
          ...formikComponentMemoizeCheck('fixedPickupsPeriod'),
        ]),
      ),
    ),
  ),
);
