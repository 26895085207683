import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Link, withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FormLabel from '@material-ui/core/FormLabel';

import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { loader } from 'graphql.macro';
import LoadingLayout from '../../../../shared/loading';
import {
  messageWrongCoords,
  saveCacheRead,
  maxLengthStringMessage,
} from '../../../../shared/utils';
import { MAX_FILE_SIZE } from '../../../../shared/utils/constants';
import { setNewCompanySettings, setUser } from '../../../../../actions';
import {
  FormikTextField,
  FormikSelect,
  FormikImageDropzone,
} from '../../../../shared/inputs/formik';
import TooltippedUserControl from '../../../../shared/tooltip/TooltippedUserControl';
import { handleClosePopup } from '../../containers/routes/shared/utils';
import MapDialog from '../../../../shared/mapDialog';
import FormikLocationInput from '../../../../shared/inputs/formik/FormikLocationInput';

const meType = loader('./../../../../graphql/queries/core/me.graphql');
const updateCompanyMutation = loader('./../../../../graphql/mutations/core/update_company.graphql');
const createCompanyMutation = loader('./../../../../graphql/mutations/core/create_company.graphql');
const companyQuery = loader('./../../../../graphql/queries/core/company.graphql');
const companySettingsQuery = loader('./../../../../graphql/queries/core/company_settings.graphql');

const defaultStyleValues = {
  noSidebarImage: false,
  sidebarBackgroundColor: '#024559',
  HeaderColor: '#5B90A6',
  buttonBlue: '#024559',
  clusterColor: '#e09837',
  chipBackgroundColor: '#F0F2F7',
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    marginTop: '60px',
    marginBottom: '35px',
  },
  content: {
    margin: '35px',
    height: '100%',
  },
  photoWrapper: {
    width: '100%',
  },
  tooltipContainer: {
    marginTop: 24,
  },
  projectPhotoContainer: {
    height: 64,
  },
  labelText: {
    color: '#626469',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '20px',
    transform: 'translate(0, -2px)',
  },
  loginImage: {
    width: '100%',
    height: '64px',
    marginTop: '10px',
    display: 'block',
  },
}));

const companySchema = Yup.object().shape({
  name: Yup.string().max(64, maxLengthStringMessage(64)).required().label('Name'),
  description: Yup.string().max(250, maxLengthStringMessage(250)).label('Description'),
  photo: Yup.string(),
  latitude: Yup.number()
    .required()
    .min(-90, messageWrongCoords(90))
    .max(90, messageWrongCoords(90))
    .notOneOf([0], 'This field is required')
    .label('Latitude'),
  longitude: Yup.number()
    .required()
    .min(-180, messageWrongCoords(180))
    .max(180, messageWrongCoords(180))
    .notOneOf([0], 'This field is required')
    .label('Longitude'),
  color1: Yup.string().label('Color 1').max(7, maxLengthStringMessage(7)),
  color2: Yup.string().label('Color 2').max(7, maxLengthStringMessage(7)),
  color3: Yup.string().label('Color 3').max(7, maxLengthStringMessage(7)),
  color4: Yup.string().label('Color 4').max(7, maxLengthStringMessage(7)),
  color5: Yup.string().label('Color 5').max(7, maxLengthStringMessage(7)),
  fontFamily: Yup.string().label('Font family'),
});

const FONT_VALUES = [
  {
    value: 'ROBOTO',
    label: <FormattedMessage id="font_family.roboto" defaultMessage="Roboto" />,
  },
  {
    value: 'HELVETICA',
    label: <FormattedMessage id="font_family.helvetica_neue" defaultMessage="Helvetica Neue" />,
  },
];

const CompanySettings = ({ history, match, me, createMode, handleSetNewCompanySettings }) => {
  const classes = useStyles();
  const intl = useIntl();

  const { params: { id: companyId } = {} } = match;
  useEffect(() => {
    if (!me) {
      return;
    }
    if (
      !me.isSuperuser &&
      !me.isReseller &&
      !companyId &&
      !me.isMaster &&
      !companyId &&
      me.isMaster &&
      me.company
    ) {
      history.push('/app/settings/');
      toast.warn('You don`t have permission for creating company');
    }
  }, [companyId, me, history]);

  const [isMapDialogueOpen, setIsMapDialogueOpen] = useState(false);

  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [newPhoto, setNewPhoto] = useState('');
  const [newPhotoUrl, setNewPhotoUrl] = useState('');
  const [loginImages, setLoginImages] = useState([]);
  const loginImageRef = useRef(null);

  const [updateCompany] = useMutation(updateCompanyMutation);
  const [createCompany] = useMutation(createCompanyMutation);

  const getCompany = useCallback(() => {
    setLoading(true);
    saveCacheRead({ query: companyQuery, variables: { companyId } })
      .then(({ data: { company: companyData } }) => {
        setCompany(companyData);
        setLoginImages(companyData.companySettings?.loginImages || []);
        setNewPhotoUrl(companyData.logo);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId]);

  useEffect(() => {
    if (!createMode) {
      getCompany();
    }
  }, [getCompany, createMode]);

  const handleChangePhotoUrl = (e) => {
    if (e.file) {
      const reader = new FileReader();
      reader.readAsDataURL(e.file);
      reader.onload = () => {
        setNewPhoto(reader.result);
        setNewPhotoUrl(e.url);
      };
    } else {
      setNewPhoto('');
      setNewPhotoUrl('');
    }
  };

  const handleChangeLoginImage = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > MAX_FILE_SIZE) {
        toast.error('This image is too large. Max size 5mb');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setLoginImages([...loginImages, reader.result]);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleRemoveImage = (image) => {
    setLoginImages(loginImages.filter((item) => item !== image));
  };

  const dispatch = useDispatch();
  const client = useApolloClient();

  const formSubmitHandler = (formVariables, { setSubmitting, setFieldError }) => {
    const variables = {
      ...formVariables,
      color1: formVariables.color1 ? formVariables.color1.substring(1) : '',
      color2: formVariables.color2 ? formVariables.color2.substring(1) : '',
      color3: formVariables.color3 ? formVariables.color3.substring(1) : '',
      color4: formVariables.color4 ? formVariables.color4.substring(1) : '',
      color5: formVariables.color5 ? formVariables.color5.substring(1) : '',
      companyId,
      loginImages,
      name: formVariables.name,
      logo: newPhoto || newPhotoUrl,
      latitude: formVariables.latitude,
      longitude: formVariables.longitude,
      description: formVariables.description,
    };

    const subDomain = window.location.host.split('.')[0];

    /* Change styles for custom DNS */
    if (
      !['dev', 'dev-platform', 'platform', 'stage', 'stage-platform', 'wastehero'].includes(
        subDomain,
      ) &&
      formVariables.dns === subDomain
    ) {
      const newCompanySettings = {};
      newCompanySettings.defaultFont = variables.fontFamily;

      if (variables.color1) {
        newCompanySettings.noSidebarImage = true;
        newCompanySettings.sidebarBackgroundColor = `#${variables.color1}`;
      } else {
        newCompanySettings.noSidebarImage = defaultStyleValues.noSidebarImage;
        newCompanySettings.sidebarBackgroundColor = defaultStyleValues.sidebarBackgroundColor;
      }

      if (variables.color2) {
        newCompanySettings.HeaderColor = `#${variables.color2}`;
      } else {
        newCompanySettings.HeaderColor = defaultStyleValues.HeaderColor;
      }

      if (variables.color3) {
        newCompanySettings.buttonBlue = `#${variables.color3}`;
      } else {
        newCompanySettings.buttonBlue = defaultStyleValues.buttonBlue;
      }

      if (variables.color4) {
        newCompanySettings.clusterColor = `#${variables.color4}`;
      } else {
        newCompanySettings.clusterColor = defaultStyleValues.clusterColor;
      }

      if (variables.color5) {
        newCompanySettings.chipBackgroundColor = `#${variables.color5}`;
      } else {
        newCompanySettings.chipBackgroundColor = defaultStyleValues.chipBackgroundColor;
      }

      handleSetNewCompanySettings(newCompanySettings);
    } else {
      handleSetNewCompanySettings(defaultStyleValues);
    }

    const refetchQueries = [
      {
        query: meType,
        fetchPolicy: 'network-only',
      },
    ];

    if (variables.dns) {
      refetchQueries.push({
        query: companySettingsQuery,
        variables: {
          dns: variables.dns,
        },
      });
    }

    return (createMode ? createCompany : updateCompany)({
      variables,
      refetchQueries,
      awaitRefetchQueries: true,
    })
      .then(
        ({
          data: {
            [createMode ? 'createCompany' : 'updateCompany']: { company: companyData },
          },
        }) => {
          client.query({ query: meType }).then((userResponse) => {
            dispatch(setUser(userResponse.data?.me));
          });
          if (createMode) {
            toast.info(
              intl.formatMessage({
                id: 'toast.company_created',
                defaultMessage: 'Company successfully created',
              }),
            );
            toast.info(
              intl.formatMessage({
                id: 'toast.setup_project',
                defaultMessage: 'Setup yours first project!',
              }),
            );
            return history.push({
              pathname: `/app/settings/create-project/${companyData.id}`,
            });
          }
          setCompany(companyData);
          return toast.info(
            intl.formatMessage({
              id: 'toast.company_saved',
              defaultMessage: 'Company settings successfully saved',
            }),
          );
        },
      )
      .catch((e) => {
        if (e.graphQLErrors && e.graphQLErrors.length) {
          e.graphQLErrors.forEach((graphQLError) => {
            toast.error(graphQLError.message);
            if (graphQLError?.context?.field) {
              setFieldError(graphQLError.context.field, graphQLError.message);
            }
          });
        }
        setSubmitting(false);
      });
  };

  const readOnly = me && !(me.isAdmin || me.isReseller || me.isMaster);

  const renderColorFields = (index) => (
    <Grid container item xs={12} md={10} spacing={2} justify="center">
      <Grid item xs={11} md={9}>
        <TooltippedUserControl
          tooltipText={
            <FormattedMessage
              id="tooltip.company_color_{index}"
              defaultMessage="Enter a company color {index}"
              values={{
                index,
              }}
            />
          }
          tooltipStyle={classes.tooltipContainer}
        >
          <FormControl className="m-b-1 w-100 b-rad-0">
            <FormikTextField
              id={`color${index}`}
              name={`color${index}`}
              label={intl.formatMessage({
                id: `label.company_color_{index}`,
                defaultMessage: `Color ${index}`,
                values: {
                  index,
                },
              })}
              className="w-100"
              InputProps={{
                readOnly,
              }}
            />
          </FormControl>
        </TooltippedUserControl>
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Typography variant="h5" align="center" className={classes.header}>
        {(company && company.name) || <FormattedMessage id="new" defaultMessage="New" />}
        &nbsp;
        {<FormattedMessage id="company_settings" defaultMessage="Company Settings" />}
      </Typography>
      <Paper className={classes.content}>
        {loading ? (
          <LoadingLayout isLoading={loading} />
        ) : (
          <Formik
            initialValues={{
              name: company.name || '',
              photo: company.logo || '',
              latitude: company.dashboardLocation ? company.dashboardLocation?.latitude : 0,
              longitude: company.dashboardLocation ? company.dashboardLocation?.longitude : 0,
              description: company.description || '',
              color1: company.companySettings?.color1 ? `#${company.companySettings.color1}` : '',
              color2: company.companySettings?.color2 ? `#${company.companySettings.color2}` : '',
              color3: company.companySettings?.color3 ? `#${company.companySettings.color3}` : '',
              color4: company.companySettings?.color4 ? `#${company.companySettings.color4}` : '',
              color5: company.companySettings?.color5 ? `#${company.companySettings.color5}` : '',
              dns: company.companySettings?.dns || '',
              fontFamily: company.companySettings?.fontFamily || 'ROBOTO',
              placeId: company.dashboardLocation ? company.dashboardLocation?.placeId : '',
            }}
            onSubmit={formSubmitHandler}
            validationSchema={companySchema}
          >
            {({ submitForm, isSubmitting, values, setFieldValue, setFieldTouched }) => (
              <Form className="w-100 m-t-40">
                <LoadingLayout isLoading={isSubmitting} />
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  className="m-b-10"
                  direction="column"
                  spacing={4}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    md={10}
                    spacing={2}
                    justify="center"
                    alignItems="flex-end"
                  >
                    <Grid container item xs={11} md={5} justify="center">
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.company_name"
                            defaultMessage="Enter a name for the company"
                          />
                        }
                        tooltipStyle={`${classes.tooltipContainer} w-100`}
                      >
                        <FormikTextField
                          name="name"
                          id="name"
                          label={intl.formatMessage({
                            id: 'label.company_name',
                            defaultMessage: 'Enter Company name',
                          })}
                          required
                          className="w-100"
                          InputProps={{
                            readOnly,
                          }}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <Grid container item xs={12} md={4} direction="column" alignItems="stretch">
                      <FormikImageDropzone
                        size="small"
                        name="photo"
                        id="photo"
                        onChange={handleChangePhotoUrl}
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} md={10} spacing={2} justify="center">
                    <Grid item xs={11} md={9}>
                      <FormikLocationInput disabled={readOnly} />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} md={10} spacing={2} justify="center">
                    <Grid item xs={11} md={9}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.company_description"
                            defaultMessage="Enter a description for the company"
                          />
                        }
                        tooltipStyle={classes.tooltipContainer}
                      >
                        <FormControl className="m-b-10 w-100 b-rad-0">
                          <FormikTextField
                            id="description"
                            name="description"
                            label={intl.formatMessage({
                              id: 'label.company_description',
                              defaultMessage: 'Company description',
                            })}
                            className="w-100"
                            multiline
                            rows={5}
                            InputProps={{
                              readOnly,
                            }}
                          />
                        </FormControl>
                      </TooltippedUserControl>
                    </Grid>
                  </Grid>
                  {me.isSuperuser && (
                    <>
                      <Grid container item xs={12} md={10} spacing={2} justify="center">
                        <Grid item xs={11} md={9}>
                          <TooltippedUserControl
                            tooltipText={
                              <FormattedMessage
                                id="tooltip.company_dns"
                                defaultMessage="Enter a company DNS"
                              />
                            }
                            tooltipStyle={classes.tooltipContainer}
                          >
                            <FormControl className="m-b-1 w-100 b-rad-0">
                              <FormikTextField
                                id="dns"
                                name="dns"
                                label={intl.formatMessage({
                                  id: 'label.company_dns',
                                  defaultMessage: 'DNS',
                                })}
                                className="w-100"
                                InputProps={{
                                  readOnly,
                                }}
                              />
                            </FormControl>
                          </TooltippedUserControl>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} md={10} spacing={2} justify="center">
                        <Grid item xs={11} md={9}>
                          <TooltippedUserControl
                            tooltipText={
                              <FormattedMessage
                                id="tooltip.company_font_family"
                                defaultMessage="Enter a company font family"
                              />
                            }
                            tooltipStyle={classes.tooltipContainer}
                          >
                            <FormControl className="m-b-10 w-100 b-rad-0">
                              <FormikSelect
                                name="fontFamily"
                                filledStyle
                                placeholder={intl.formatMessage({
                                  id: 'vehicle.placeholder.company_font_family',
                                  defaultMessage: 'Choose font family',
                                })}
                                label={intl.formatMessage({
                                  id: 'vehicle.label.company_font_family',
                                  defaultMessage: 'Company font family',
                                })}
                                valuesList={FONT_VALUES}
                                required
                                disabled={readOnly}
                              />
                            </FormControl>
                          </TooltippedUserControl>
                        </Grid>
                      </Grid>
                      {[1, 2, 3, 4, 5].map((index) => renderColorFields(index))}
                    </>
                  )}
                  <FormLabel>
                    <FormattedMessage id="label.login_images" defaultMessage="Login images">
                      {(txt) => (
                        <>
                          <span className={classes.labelText}>{txt}</span>
                        </>
                      )}
                    </FormattedMessage>
                  </FormLabel>
                  <Grid container item xs={12} md={10} spacing={2} justify="center">
                    <Grid item container xs={11} md={9} spacing={2} justify="flex-start">
                      {loginImages &&
                        loginImages.map((image) => (
                          <Grid item xs={12} md={2} className={`${classes.photoWrapper} m-t-2`}>
                            <img
                              className={classes.loginImage}
                              src={image}
                              alt="Login"
                              title={intl.formatMessage({
                                id: 'login_image',
                                defaultMessage: 'Login image',
                              })}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              className="w-100 text-center"
                              style={{
                                marginTop: '3px',
                              }}
                              type="button"
                              onClick={() => handleRemoveImage(image)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        ))}
                      <Grid container item md={1} xs={12} className={classes.projectPhotoContainer}>
                        <input
                          name="loginImage"
                          id="loginImage"
                          className="hidden"
                          readOnly={readOnly}
                          type="file"
                          accept="image/*"
                          ref={loginImageRef}
                          onChange={handleChangeLoginImage}
                        />
                        <Button
                          variant="outlined"
                          type="button"
                          disabled={readOnly}
                          className="h-100"
                          style={{
                            marginTop: '10px',
                          }}
                          onClick={() => loginImageRef.current.click()}
                        >
                          <PublishIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={10} spacing={2} justify="center">
                    {!createMode && (
                      <Grid item xs={3} container justify="flex-start">
                        <Link to="/app/settings" className="masked-link w-60">
                          <Button
                            variant="outlined"
                            className="w-100 settings-control-button"
                            startIcon={<ChevronLeftIcon />}
                            type="button"
                          >
                            <Typography variant="body1" className="p-r-20">
                              <FormattedMessage id="back" defaultMessage="Back" />
                            </Typography>
                          </Button>
                        </Link>
                      </Grid>
                    )}
                    <Grid item xs={3} container spacing={2} justify="center">
                      {!readOnly && (
                        <Button
                          variant="outlined"
                          className="w-60 settings-control-button"
                          type="button"
                          onClick={submitForm}
                        >
                          <Typography variant="body1">
                            {createMode ? (
                              <FormattedMessage id="create" defaultMessage="Create" />
                            ) : (
                              <FormattedMessage id="save" defaultMessage="Save" />
                            )}
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <MapDialog
                  locationCoord={[values.longitude || 0, values.latitude || 0]}
                  open={isMapDialogueOpen}
                  onClose={handleClosePopup(setFieldValue, setFieldTouched, setIsMapDialogueOpen)}
                  showFooter
                  showSearch
                  getLocation
                />
              </Form>
            )}
          </Formik>
        )}
      </Paper>
    </div>
  );
};

CompanySettings.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  createMode: PropTypes.bool,
  handleSetNewCompanySettings: PropTypes.func.isRequired,
  me: PropTypes.shape({
    isSuperuser: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isReseller: PropTypes.bool,
    isMaster: PropTypes.bool,
    company: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  }).isRequired,
};

CompanySettings.defaultProps = {
  createMode: false,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetNewCompanySettings: (newCompanySettings) => {
    dispatch(setNewCompanySettings(newCompanySettings));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(withRouter(CompanySettings)));
