import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  uploadButtonWrapper: {
    paddingTop: '0 !important',
  },
  uploadButton: {
    width: '100%',
    textTransform: 'none',
    backgroundColor: theme.variables.cWhite,
    height: 45,
    borderRadius: 22,
    color: theme.variables.VHBlue,
    border: `1px solid ${theme.variables.VHBlue}`,
  },
}));

const UploadImageButton = ({ imageInputRef }) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.uploadButtonWrapper}>
      <Button
        variant="outlined"
        type="button"
        className={classes.uploadButton}
        onClick={() => imageInputRef.current.click()}
      >
        <Typography variant="body1">
          <FormattedMessage id="label.upload_photo" defaultMessage="Upload photo" />
        </Typography>
      </Button>
    </Grid>
  );
};

UploadImageButton.propTypes = {
  imageInputRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

UploadImageButton.defaultProps = {
  imageInputRef: null,
};

export default UploadImageButton;
