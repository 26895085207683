import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles/index';
import { NavLink, withRouter } from 'react-router-dom';
import LoadingLayout from '../../../../shared/loading';
import CompanyProjectList from './ui/CompanyProjectList';
import ConfirmDeletionDialog from '../../../../shared/dialog/ConfirmDeletionDialog';

const allCompanies = loader('./../../../../graphql/queries/core/all_companies.graphql');
const deleteProject = loader('./../../../../graphql/mutations/core/delete_project.graphql');
const allProjectsQuery = loader('./../../../../graphql/queries/core/all_projects.graphql');

const useStyles = makeStyles(() => ({
  accordion: {
    marginBottom: '0 !important',
    backgroundColor: '#eff4f6',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.14)',
  },
  button: {
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    margin: '10px 4px',
    padding: '8px 32px',
    border: '1px solid',
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  headerContainersTitle: {
    fontSize: '16px',
    marginLeft: '20px',
    fontWeight: 'bold',
    lineHeight: '1.75',
    color: '#939393',
  },
  addButtonLink: {
    textDecoration: 'none',
  },
}));

const ProjectList = ({ me }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [deletingProject, setDeletingProject] = useState({});
  const [deleteProjectMutation, { loading: deleteProjectMutationLoading }] = useMutation(
    deleteProject,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: allCompanies },
        { query: allProjectsQuery, variables: { isVisibleCompany: false } },
      ],
    },
  );

  const {
    loading: companiesLoading,
    data: { allCompanies: { edges: companies = [] } = {} } = {},
  } = useQuery(allCompanies);

  const loading = companiesLoading || deleteProjectMutationLoading;

  const deleteHandle = (projectObj) => setDeletingProject(projectObj);

  const submitDeleteHandle = () =>
    deleteProjectMutation({
      variables: {
        projectId: deletingProject.id,
      },
      update(cache, { data: { deleteProject: deleteProjectStatus } }) {
        if (deleteProjectStatus.status === 'Success') {
          toast.info(
            intl.formatMessage({
              id: 'toast.project_removed',
              defaultMessage: 'Project successfully removed',
            }),
          );
          const { allCompanies: allCompaniesData } = cache.readQuery({ query: allCompanies });

          const filteredEdges = allCompaniesData.edges.map((company) => {
            const deepCopyCompany = JSON.parse(JSON.stringify(company));
            const {
              node: { projectSet },
            } = company;
            if (projectSet.edges) {
              deepCopyCompany.node.projectSet.edges = projectSet.edges.filter(
                ({ node }) => node.id !== deletingProject.id,
              );
            }
            return deepCopyCompany;
          });
          cache.writeQuery({
            query: allCompanies,
            data: { allCompanies: { ...allCompaniesData, edges: filteredEdges } },
          });
          setDeletingProject({});
        }
      },
    });

  return (
    <Grid container justify="center">
      <Grid item xs={12} className="fullHeightCol listCol">
        <Grid container className={classes.accordion} justify="space-between">
          <p>
            <span className={classes.headerContainersTitle}>Project overview</span>
          </p>
          {(me.isReseller || me.isSuperuser) && (
            <Grid>
              <NavLink to="/company/create/" className={classes.addButtonLink}>
                <span>
                  <span>
                    <span>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {}}
                        tourid="AdminCreateCompany"
                      >
                        <FormattedMessage id="add_company" defaultMessage="Add Company" />
                      </Button>
                    </span>
                  </span>
                </span>
              </NavLink>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={8}>
        {loading ? (
          <LoadingLayout isLoading={loading} />
        ) : (
          companies.map(({ node: company }) => (
            <CompanyProjectList
              projectList={company.projectSet.edges.map(({ node: project }) => project)}
              companyName={company.name}
              companyId={company.id}
              key={company.id}
              deleteHandle={deleteHandle}
            />
          ))
        )}
        <ConfirmDeletionDialog
          open={!!deletingProject.id}
          onConfirm={submitDeleteHandle}
          onClose={() => setDeletingProject({})}
          title={deletingProject.name || ''}
          helpText={intl.formatMessage({
            id: 'remove_all_data_related_to_project',
            defaultMessage: 'I will remove all data related to this project!',
          })}
        />
      </Grid>
    </Grid>
  );
};

ProjectList.propTypes = {
  me: PropTypes.shape({
    isReseller: PropTypes.bool,
    isSuperuser: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(withRouter(ProjectList));
