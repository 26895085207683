import moment from 'moment';
import { TableCellRenderer, getColor } from './styledComponents';

export const getWasteType = ({ wasteTypes: { edges: wasteTypes = [] } = {} }) =>
  wasteTypes.length
    ? wasteTypes
        ?.map(({ node }) => node?.name)
        .join(' + ')
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/^(.)|\s(.)/g, ($1) => $1.toUpperCase())
    : '';
export const getWasteCategory = ({ wasteCategory = '' }) =>
  wasteCategory ? `(${wasteCategory})` : '';

export const generateContainerTypes = (containersTypesArray) =>
  containersTypesArray.reduce((filterOptions, containerType) => {
    if (containerType.id && !filterOptions.find((el) => el.key === containerType.id)) {
      filterOptions.push({
        key: containerType.id,
        value: containerType.name,
      });
    }
    return filterOptions;
  }, []);

export const generateColumnsForPeriodSteps = (columns, periodSteps) => {
  const dynamicValues = periodSteps.reduce(
    (dynamicColumns, { id: field, end }) => {
      const year = end.year();
      const existedYear = dynamicColumns.year.find((el) => el.field === year);

      if (existedYear) {
        existedYear.colSpan += 1;
      } else {
        dynamicColumns.year.push({
          title: year,
          field: year,
          align: 'center',
          colSpan: 1,
          width: '80px',
          style: { border: '3px solid #fff', borderRight: 0 },
        });
      }

      const monthId = end.format('YYYY/MM');
      const existedMonth = dynamicColumns.month.find((el) => el.field === monthId);

      if (existedMonth) {
        existedMonth.colSpan += 1;
      } else {
        dynamicColumns.month.push({
          title: end.format('MMMM'),
          field: monthId,
          colSpan: 1,
          align: 'center',
          width: '80px',
          style: { border: '3px solid #fff', borderTop: 0, borderRight: 0 },
        });
      }

      dynamicColumns.step.push({
        title: field,
        field,
        render: TableCellRenderer(getColor(true)),
        align: 'center',
        width: '80px',
        style: { border: '3px solid #fff', borderTop: 0, borderRight: 0 },
      });

      return dynamicColumns;
    },
    { year: [], month: [], step: [] },
  );

  return {
    dynamicColumns: [...columns, ...dynamicValues.step.map((el) => ({ ...el, fixed: false }))],
    headerRows: [
      {
        id: 'first row',
        row: [...columns, ...dynamicValues.year],
      },
      {
        id: 'second row',
        row: [...dynamicValues.month],
      },
      {
        id: 'third row',
        row: [...dynamicValues.step],
      },
    ],
  };
};

export const avg = (list) => {
  if (!list || !list.length) {
    return 0;
  }
  return list.reduce((acc, val) => acc + val.oldFillPercentage, 0) / list.length;
};

export const getPeriodSteps = ({ end = moment(), start = moment(), period = 'week', step = 1 }) => {
  const endOfStep = start.endOf(period);
  const startOfStep = endOfStep.clone().subtract(step, period);
  const periodSteps = [];

  while (startOfStep > end) {
    periodSteps.push({
      id:
        period === 'week' ? `${endOfStep.year()}/${endOfStep.week()}` : endOfStep.format('dddd/D'),
      end: endOfStep.clone(),
      start: startOfStep.clone(),
    });
    endOfStep.subtract(step, period);
    startOfStep.subtract(step, period);
  }
  return periodSteps;
};

export const groupByPeriod = (periodSteps = [], { edges: containerLog = [] } = {}) =>
  periodSteps.reduce((acc, { id, start, end }) => {
    const grouped = containerLog.reduce((groupedLogs, { node: log }) => {
      if (moment(log.createdAt).isBetween(start, end)) {
        return [...groupedLogs, log];
      }
      return groupedLogs;
    }, []);
    return {
      ...acc,
      [id]: {
        value: avg(grouped),
        logs: grouped,
      },
    };
  }, {});

export const getRangeForPeriod = (period, localStartDate = null, localEndDate = null) => {
  let start;
  let end;
  switch (period) {
    case 'day':
      end = moment().endOf('day');
      start = end.clone().subtract(1, 'day');
      break;
    case 'week':
      end = moment().endOf('day');
      start = end.clone().subtract(1, 'week');
      break;
    case 'month':
      end = moment().endOf('day');
      start = end.clone().subtract(1, 'month');
      break;
    case 'quarter':
      end = moment().endOf('day');
      start = end.clone().subtract(3, 'months');
      break;
    case 'year':
      end = moment().endOf('day');
      start = end.clone().subtract(1, 'year');
      break;
    case 'last_quarter':
      end = moment().endOf('day');
      start = end.clone().subtract(3, 'months');
      break;
    case 'last_month':
      end = moment().endOf('day');
      start = end.clone().subtract(1, 'month');
      break;
    case 'last_week':
      end = moment().endOf('day');
      start = end.clone().subtract(1, 'week');
      break;
    case 'all_time':
      start = null;
      end = null;
      break;
    case 'custom':
      end = localEndDate;
      start = localStartDate;
      break;
    case 'next_month':
      start = moment().endOf('day');
      end = start.clone().add(1, 'month');
      break;
    case 'next_week':
      start = moment().endOf('day');
      end = start.clone().add(1, 'week');
      break;
    case 'next_year':
      start = moment().endOf('day');
      end = start.clone().add(1, 'year');
      break;
    case 'next_day':
      start = moment().endOf('day');
      end = start.clone().add(1, 'day');
      break;

    default:
      end = localEndDate;
      start = localStartDate;
      break;
  }
  return { start, end };
};

export const defaultUsedStatistics = {
  binsCount: 0,
  average: 0,
  chartData: [],
};

export const generatePeriod = (
  startDate,
  endDate,
  stepsGenerator = (el) => [{ start: el.end, end: el.start }],
) =>
  stepsGenerator({
    start: endDate.clone(),
    end: startDate.clone(),
    period: endDate.diff(startDate, 'weeks') > 1 ? 'week' : 'day',
    step: 1,
  });
