import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { loader } from 'graphql.macro';
import FormikSelect from '../inputs/formik/FormikSelect';
import NoItemsForSelect from './noItemsForSelect';
import InputSkeleton from '../inputs/InputSkeleton';

const allVehicleTypesQuery = loader(
  './../../graphql/queries/fleet_management/all_vehicle_types.graphql',
);

const SelectVehicleType = ({ md, setType }) => {
  const { loading, data: { allVehicleTypes: { edges: vehicleTypes = [] } = {} } = {} } = useQuery(
    allVehicleTypesQuery,
  );

  const intl = useIntl();

  const allVehicleTypes = useMemo(
    () =>
      vehicleTypes.map(({ node }) =>
        Object({
          value: node.id,
          label: node.name,
        }),
      ),
    [vehicleTypes],
  );

  const handleOnChange = (value) => {
    if (value) {
      const vehicleTypeNode = vehicleTypes.find(({ node }) => node.id === value);
      if (vehicleTypeNode) {
        setType(vehicleTypeNode.node.Type);
      }
    }
  };

  return (
    <Grid item xs={12} md={md}>
      {loading ? (
        <InputSkeleton />
      ) : (
        <>
          {allVehicleTypes.length > 0 ? (
            <FormikSelect
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.vehicle_type',
                defaultMessage: 'Choose the vehicleType',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.vehicle_type',
                defaultMessage: 'Vehicle Type',
              })}
              required
              filledStyle
              onChange={handleOnChange}
              name="vehicleType"
              valuesList={allVehicleTypes}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <NoItemsForSelect
              link="/app/fleet-management/vehicle-type/add"
              message={
                <FormattedMessage
                  id="no_vehicle_types"
                  defaultMessage="No vehicle types registered."
                />
              }
              linkText={
                <FormattedMessage
                  id="add_vehicle_types"
                  defaultMessage="Click to add new vehicle types."
                />
              }
            />
          )}
        </>
      )}
    </Grid>
  );
};

SelectVehicleType.propTypes = {
  md: PropTypes.number,
  setType: PropTypes.func,
};

SelectVehicleType.defaultProps = {
  md: 12,
  setType: () => {},
};

export default React.memo(SelectVehicleType);
