import React from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import { FormattedMessage } from 'react-intl';
import useGetActiveTab from '../../../../../../shared/hooks/useGetActiveTab';

const useStyles = makeStyles((theme) => ({
  tabs: {
    '& > div': {
      '& > .MuiTabs-indicator': {
        top: 'inherit',
        bottom: 0,
        backgroundColor: theme.variables.cOrange,
        height: '2',
      },
    },
  },
  tab: {
    maxWidth: 'none',
    textTransform: 'none',
    minHeight: 68,
    '& a': {
      color: 'rgba(0, 0, 0, 0.54)',
      textDecoration: 'none',
    },
    '&.Mui-selected': {
      '& a': {
        color: theme.variables.cOrange,
      },
    },
  },
}));

const HeaderTab = ({ match }) => {
  const classes = useStyles();
  const tab = useGetActiveTab(match, 'collector');

  const collectorRouteListString = (
    <Link to="/app/routes/vehicle/collector">
      <FormattedMessage id="collector_route" defaultMessage="Collector Route" />
    </Link>
  );

  return (
    <Grid item>
      <Grid>
        <Tabs
          value={tab}
          aria-label="route tab"
          textColor="secondary"
          indicatorColor="secondary"
          className={classes.tabs}
        >
          <Tab
            label={collectorRouteListString}
            className={classes.tab}
            value="collector"
            tourid="collectorRoute"
          />
          {/*
            <Tab
              label={streetSweeperRouteListString}
              className={classes.tab}
              value="street-sweeper"
              tourid="streetSweeperRoute"
            />
          */}
        </Tabs>
      </Grid>
    </Grid>
  );
};

HeaderTab.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  me: PropTypes.shape({
    isBeta: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(withRouter(HeaderTab));
