import React from 'react';
import classNames from 'classnames';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const simpleVariant = 'simple';
const importantVariant = 'important';
const variants = [simpleVariant, importantVariant];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper: {
    minHeight: theme.spacing(77),
    height: '100%',
    borderRadius: 12.5,
  },
  darkPaper: {
    backgroundColor: theme.variables.cAntiFlashWhite,
  },
  lightPaper: {
    backgroundColor: theme.variables.cSnow,
  },
  titleTextContainer: {
    lineHeight: '30px',
    color: theme.variables.cSlate,
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2.25),
  },
  content: {
    padding: theme.spacing(3),
  },
  titleText: {
    borderBottom: `2px solid ${theme.variables.VHOrange}`,
  },
}));

const InfoBox = ({ variant, title, children, xs, lg, md }) => {
  const classes = useStyles();

  return (
    <Grid item xs={xs} lg={lg} md={md} className={classes.root}>
      <Paper
        elevation={0}
        className={classNames(classes.paper, {
          [classes.darkPaper]: variant === importantVariant,
          [classes.lightPaper]: variant !== importantVariant,
        })}
      >
        <div className={classes.titleTextContainer}>
          <span className={classes.titleText}>{title}</span>
        </div>
        <div className={classes.content}>{children}</div>
      </Paper>
    </Grid>
  );
};

InfoBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.oneOf(variants),
  children: PropTypes.node,
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InfoBox.defaultProps = {
  title: '-',
  variant: simpleVariant,
  children: null,
  lg: 'auto',
  md: 'auto',
  xs: 'auto',
};

export default InfoBox;
