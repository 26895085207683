import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Route, Switch } from 'react-router-dom';
import OverviewPage from './routes/overview';
import HeatMaps from './routes/heat-maps';

const Analytics = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/`} component={OverviewPage} />
    <Route exact path={`${match.url}/heat-maps`} component={HeatMaps} />
  </Switch>
);

Analytics.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Analytics;
