import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import TooltippedUserControl from '../../../../../../shared/tooltip/TooltippedUserControl';

const styles = () => ({
  tooltipContainer: {
    width: '100%',
    '& > .MuiTextField-root': {
      width: '100%',
    },
  },
});

const RegisterContainerTooltipedField = ({ children, classes, tooltipText, md }) => (
  <Grid item xs={12} md={md}>
    <TooltippedUserControl tooltipText={tooltipText} tooltipStyle={classes.tooltipContainer}>
      {children}
    </TooltippedUserControl>
  </Grid>
);

RegisterContainerTooltipedField.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tooltipText: PropTypes.node.isRequired,
  md: PropTypes.number,
};

RegisterContainerTooltipedField.defaultProps = {
  md: 9,
};

export default withStyles(styles, { withTheme: true })(React.memo(RegisterContainerTooltipedField));
