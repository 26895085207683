import React, { useEffect, useState, useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid, Typography, FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { loader } from 'graphql.macro';
import SettingsMultiChipLabel from './ui/SettingsMultiChipLabel';
import SettingsCheckboxGroupWithCustom from './ui/SettingsCheckboxGroupWithCustom';
import SettingsDropdown from './ui/SettingsDropdown';
import LoadingLayout from '../../../../shared/loading';
import { messageNoNegativeValues, saveCacheRead, toastifyError } from '../../../../shared/utils';
import { wasteCategoryOptions } from './defaults/data';
import { setUser } from '../../../../../actions';
import ConfirmDeletionDialog from '../../../../shared/dialog/ConfirmDeletionDialog';
import ConfirmDialog from '../../../../shared/dialog/ConfirmDialog';
import ConfirmEmptySettingsDialog from './ui/ConfirmEmptySettingsDialog';
import TooltippedUserControl from '../../../../shared/tooltip/TooltippedUserControl';
import { FormikSelect } from '../../../../shared/inputs/formik';
import { TutorialContext } from '../../../../../tutorial';
import FormikTextField from '../../../../shared/inputs/formik/FormikTextField';
import Logo from '../../../../../images/icons/hand-pdf.svg';
import Settings, { checkIfRegularUser } from '../../../../shared/utils/settings';

const allWasteFractionsQuery = loader(
  './../../../../graphql/queries/wastacollector/all_waste_fractions.graphql',
);
const allWasteTypesQuery = loader(
  './../../../../graphql/queries/wastacollector/all_waste_types.graphql',
);
const projectSettings = loader('./../../../../graphql/queries/core/project_settings.graphql');
const createWasteFractionMutation = loader(
  './../../../../graphql/mutations/wastecollector/create_waste_fraction.graphql',
);
const updateWasteFractionMutation = loader(
  './../../../../graphql/mutations/wastecollector/update_waste_fraction.graphql',
);
const deleteWasteFractionMutation = loader(
  './../../../../graphql/mutations/wastecollector/delete_waste_fraction.graphql',
);

const deleteWasteTypeMutation = loader(
  './../../../../graphql/mutations/wastecollector/delete_waste_type.graphql',
);

const updateWasteTypeMutation = loader(
  './../../../../graphql/mutations/wastecollector/update_waste_type.graphql',
);

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.variables.chipBackgroundColor,
    marginRight: '8px',
    marginTop: '8px',
    '& svg.MuiSvgIcon-root': {
      height: '18px',
      width: '18px',
    },
    '&:hover': {
      backgroundColor: theme.variables.cLightGray,
      height: 'inherit',
      '& > .MuiChip-label': {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  chipText: {
    fontSize: '13px',
    overflowWrap: 'break-word',
    maxWidth: 400,
    overflow: 'hidden',
  },
  chipItems: {
    display: 'inline-flex',
  },
  addedWasteFractions: {
    border: '1px solid',
    borderColor: theme.variables.cOrange,
    borderRadius: '4px',
    padding: '16.5px',
    marginBottom: '74px',
  },
  errorInput: {
    '& .settings-popup-toggler': {
      borderColor: `${theme.variables.VHRedDark} !important`,
      '&>.MuiButton-label': {
        color: theme.variables.VHRedDark,
      },
    },
  },
  activeButton: {
    backgroundColor: theme.variables.buttonBlue,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.variables.VHBlueDark,
    },
  },
  logo: {
    display: 'inline-block',
    width: 42,
    height: 42,
    margin: '32px 0 0 20px',
    '& .MuiAvatar-root': {
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: 0,
    },
  },
  labelText: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '20px',
    transform: 'translate(0, -2px)',
    marginBottom: 12,
  },
  chipButton: {
    paddingBottom: 0,
    paddingTop: 0,
    height: 'maxContent',
    display: 'flex',
    alignSelf: 'center',
  },
  popoverClasses: {
    '& > .MuiPopover-paper': {
      borderBottomRightRadius: 24,
      borderBottomLeftRadius: 24,
      maxHeight: '60%',
      backgroundColor: theme.variables.cAntiFlashWhite,
      width: '32.6vw',
    },
  },
  disableEditText: {
    fontWeight: 400,
    lineHeight: '1.5rem',
    color: '#9aa8ad',
    fontSize: '1rem',
    padding: '0 16px 16px 16px',
  },
}));

const containerSettingsDefaultValues = {
  wasteType: [],
  wasteCategory: '',
  weightFactor: '',
  newWasteType: '',
};

const containerSettingsValidationSchema = () =>
  Yup.object().shape({
    wasteType: Yup.array().of(Yup.string()).required(),
    wasteCategory: Yup.string().required(),
    weightFactor: Yup.number().positive(messageNoNegativeValues),
  });

const WasteFractionsSettings = ({ projectId, history, match, me }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { isTutorialOpen } = useContext(TutorialContext);

  const [suggestedWeightFactor, setSuggestedWeightFactor] = useState('');

  const [editingId, setEditingId] = useState('');
  const [deletingFraction, setDeletingFraction] = useState('');

  const [wasteFractions, setWasteFractions] = useState([]);
  const [wasteTypeOptions, setWasteTypeOptions] = useState([]);

  const [activePopup, setActivePopup] = useState('');
  const [popupAnchorEl, setPopupAnchorEl] = useState(null);

  const [globalLoading, setGlobalLoading] = useState(false);
  const [emptySettingsConfirmationDialog, setEmptySettingsConfirmationDialog] = useState('');

  const [settingsConfig, setSettingsConfig] = useState();

  const [wasteTypeIdToDelete, setWasteTypeIdToDelete] = useState('');

  const [paramsForWasteTypeEdit, setParamsForWasteTypeEdit] = useState(null);

  const [wasteTypeDeletionError, setWasteTypeDeletionError] = useState(false);

  const [weightFactorVisible, setWeightFactorVisible] = useState(false);

  useQuery(projectSettings, {
    variables: {
      id: match.params.id,
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {
      setSettingsConfig(new Settings(project.settings));
    },
  });

  const isProjectOwner = me.ownProjectsIds.includes(projectId);
  const isRegularUser = checkIfRegularUser(me) && !isProjectOwner;

  const [deleteWasteType] = useMutation(deleteWasteTypeMutation, {
    variables: {
      selfId: wasteTypeIdToDelete,
    },
    onCompleted: () =>
      toast.success(
        intl.formatMessage({
          id: 'waste_type_deleted',
          defaultMessage: 'Waste type was successfully deleted',
        }),
      ),
    update: async (cache) => {
      const {
        data: { allWasteTypes: allWasteTypesData },
      } = await saveCacheRead({ query: allWasteTypesQuery, variables: { projectId } });
      const newWasteTypes = { ...allWasteTypesData };
      newWasteTypes.edges = allWasteTypesData.edges.filter(
        ({ node }) => node.id !== wasteTypeIdToDelete,
      );
      cache.writeQuery({
        query: allWasteTypesQuery,
        variables: { projectId },
        data: { allWasteTypes: newWasteTypes },
      });

      setWasteTypeOptions(newWasteTypes.edges);
      setWasteTypeIdToDelete('');
    },
  });

  const handleClickSettingsDropdown = (popupName) => (event) => {
    event.preventDefault();
    setPopupAnchorEl(event.currentTarget);
    setActivePopup(popupName);
  };
  const handleCloseSettingsDropdown = () => {
    setPopupAnchorEl(null);
    setActivePopup('');
  };

  const { data } = useQuery(allWasteFractionsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { projectId },
  });

  useEffect(() => {
    if (data) {
      setWasteFractions(data.allWasteFractions.edges);
    }
  }, [data]);

  const getWasteFractions = useCallback(() => {
    setGlobalLoading(true);
    saveCacheRead({ query: allWasteFractionsQuery, variables: { projectId } })
      .then(
        ({
          data: {
            allWasteFractions: { edges },
          },
        }) => {
          setWasteFractions(edges);
        },
      )
      .finally(() => {
        setGlobalLoading(false);
      });
  }, [projectId]);

  const getWasteTypes = useCallback(() => {
    setGlobalLoading(true);
    saveCacheRead({ query: allWasteTypesQuery, variables: { projectId } })
      .then(
        ({
          data: {
            allWasteTypes: { edges },
          },
        }) => {
          setWasteTypeOptions(edges);
        },
      )
      .finally(() => {
        setGlobalLoading(false);
      });
  }, [projectId]);

  const [updateWasteType] = useMutation(updateWasteTypeMutation, {
    onCompleted: () => getWasteTypes(),
  });

  const loading = globalLoading;

  useEffect(() => {
    getWasteTypes();
  }, [getWasteTypes]);

  useEffect(() => {
    getWasteFractions();
  }, [getWasteFractions]);

  const [createWasteFraction] = useMutation(createWasteFractionMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: allWasteTypesQuery, variables: { projectId } }],
  });
  const [updateWasteFraction] = useMutation(updateWasteFractionMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: allWasteTypesQuery, variables: { projectId } }],
  });
  const [deleteWasteFraction] = useMutation(deleteWasteFractionMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: allWasteTypesQuery, variables: { projectId } }],
  });

  const clearForm = (resetForm) => {
    resetForm();
  };

  const cancelEdit = (resetForm) => {
    setEditingId('');

    clearForm(resetForm);
  };

  const formSubmitHandler = (values, { setSubmitting, resetForm, setFieldError }) => {
    const variables = {
      projectId,
      ...values,
    };

    if (
      !editingId &&
      wasteFractions.some(
        (wasteFractionNode) =>
          wasteFractionNode.node.wasteTypes.edges
            .map((wasteTypeNode) => wasteTypeNode.node.id)
            .join() === variables.wasteType.join() &&
          wasteFractionNode.node.wasteCategory === variables.wasteCategory,
      )
    ) {
      setFieldError(
        'wasteType',
        <FormattedMessage
          id="waste_fraction_name_exists"
          defaultMessage="This waste fraction already exists in this company"
        />,
      );
      setSubmitting(false);
      return;
    }

    variables.weightFactor = settingsConfig.getWeightToVolume(variables.weightFactor);

    setGlobalLoading(true);
    let mutation = createWasteFraction;
    let returnQueryName = 'createWasteFraction';

    if (editingId) {
      mutation = updateWasteFraction;
      variables.selfId = editingId;
      returnQueryName = 'updateWasteFraction';
    }

    // eslint-disable-next-line consistent-return
    return mutation({
      variables,
      update: async (cache, { data: { [returnQueryName]: newWasteFraction } }) => {
        const {
          data: { allWasteFractions: allWasteFractionsData },
        } = await saveCacheRead({ query: allWasteFractionsQuery, variables: { projectId } });
        const newWasteFractions = { ...allWasteFractionsData };
        if (editingId) {
          newWasteFractions.edges = allWasteFractionsData.edges.filter(
            ({ node }) => node.id !== newWasteFraction.wasteFraction.id,
          );
        }

        newWasteFractions.edges = [
          ...newWasteFractions.edges,
          { node: newWasteFraction.wasteFraction, __typename: 'WasteFractionTypeEdge' },
        ];

        cache.writeQuery({
          query: allWasteFractionsQuery,
          variables: { projectId },
          data: { allWasteFractions: newWasteFractions },
        });

        setWasteFractions(newWasteFractions.edges);
        cancelEdit(resetForm);
      },
    })
      .then(() => {
        getWasteTypes();
        toast.info(
          intl.formatMessage({
            id: 'toast.waste_fraction_saved',
            defaultMessage: 'Waste fraction successfully saved',
          }),
        );
      })
      .catch((error) => {
        toastifyError(error);
        setSubmitting(false);
      })
      .finally(() => {
        setGlobalLoading(false);
      });
  };

  const handleDeleteWasteFraction = (e) => {
    e.preventDefault();
    const variables = { selfId: deletingFraction.id };
    setGlobalLoading(true);

    return deleteWasteFraction({
      variables,
      update: async (
        cache,
        {
          data: {
            deleteWasteFraction: { status },
          },
        },
      ) => {
        if (status !== 'Success') {
          // throw new Error(gettext(`Waste fraction could not be removed: ${status}`));
          throw new Error(`Waste fraction could not be removed: ${status}`);
        }

        const {
          data: { allWasteFractions: allWasteFractionsData },
        } = await saveCacheRead({ query: allWasteFractionsQuery, variables: { projectId } });

        const newWasteFractions = {
          ...allWasteFractionsData,
          edges: allWasteFractionsData.edges.filter(({ node }) => node.id !== deletingFraction.id),
        };

        cache.writeQuery({
          query: allWasteFractionsQuery,
          variables: { projectId },
          data: { allWasteFractions: newWasteFractions },
        });

        setWasteFractions(newWasteFractions.edges);
      },
    })
      .then(() => {
        getWasteTypes();
        toast.info(
          intl.formatMessage({
            id: 'toast.waste_fraction_removed',
            defaultMessage: 'Waste fraction successfully removed',
          }),
        );
      })
      .catch((error) => {
        toastifyError(error);
      })
      .finally(() => {
        setDeletingFraction('');
        setGlobalLoading(false);
      });
  };

  const editWasteFraction = (fraction, setFieldValue) => () => {
    setEditingId(fraction.id);

    setFieldValue(
      'wasteType',
      fraction.wasteTypes.edges.map(({ node }) => node.id),
    );
    setFieldValue('wasteCategory', fraction.wasteCategory);
    setFieldValue(
      'weightFactor',
      settingsConfig.getWeightToVolume(fraction.weightFactor, true).toFixed(2),
    );
  };
  const removeWasteFraction = (fraction) => () => {
    if (fraction.isUsed.length) {
      setWasteTypeDeletionError(fraction);
      return;
    }
    setDeletingFraction(fraction);
  };

  const getLabels = (values) => {
    const selectedWasteTypeLabels = [];
    if (values.length > 0) {
      wasteTypeOptions.forEach(({ node }) => {
        if (values.indexOf(node.id) > -1) {
          selectedWasteTypeLabels.push(node.name);
        }
      });
    }
    return selectedWasteTypeLabels;
  };

  const onWasteFractionChange = (setFieldValue, touched) => (values) => {
    if (!values.length && touched.wasteType) {
      setSuggestedWeightFactor(containerSettingsDefaultValues.weightFactor);
    }

    if (values.length) {
      const wasteTypeOption = wasteTypeOptions
        .filter(({ node }) => values.some((id) => id === node.id))
        .map(({ node }) => node.weightFactor)
        .filter((weightFactor) => weightFactor);

      const wasteTypesWeightFactor =
        wasteTypeOption.reduce((a, b) => a + b, 0) / wasteTypeOption.length || 0;

      if (wasteTypesWeightFactor) {
        setSuggestedWeightFactor(settingsConfig.getWeightToVolume(wasteTypesWeightFactor));
      } else {
        setSuggestedWeightFactor(containerSettingsDefaultValues.weightFactor);
      }
    }

    return setFieldValue('wasteType', values);
  };

  const handleNavigationButtonClick = (link) => {
    if (wasteFractions.length > 0 || isTutorialOpen()) {
      history.push(link);
    } else {
      setEmptySettingsConfirmationDialog(link);
    }
  };

  const handleDeleteRequest = () => {
    deleteWasteType();
  };

  const handleDeleteRequestAbord = () => {
    setWasteTypeDeletionError(true);
  };

  const wasteTypeInFractions = (wasteTypeToCheck) =>
    wasteTypeOptions.find(({ node }) => node.id === wasteTypeToCheck)?.node?.wastefractionSet?.edges
      ?.length;

  const Recommendation = (
    <Grid item xs={12} md={6}>
      <TooltippedUserControl
        tooltipText={
          <FormattedMessage
            id="tooltip.wasteRatioToVolume"
            defaultMessage="See the possible waste weight to volume ratio"
          />
        }
      >
        <a
          href={`${process.env.PUBLIC_URL}/Waste_ratio.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classes.logo}>
            <Avatar src={Logo} />
          </div>
        </a>
      </TooltippedUserControl>
    </Grid>
  );

  const getWasteFractionsChip = (node, setFieldValue) => {
    if (isRegularUser) {
      return (
        <Chip
          key={node.id}
          onClick={editWasteFraction(node, setFieldValue)}
          label={
            <span className={classes.chipItems}>
              <Typography className={classes.chipText}>
                {`${node?.wasteType.join(', ') || ''} - ${node?.wasteCategory || ''}`}
              </Typography>
            </span>
          }
          className={classes.chip}
        />
      );
    }

    return (
      <Chip
        key={node.id}
        label={
          <span className={classes.chipItems}>
            <Typography className={classes.chipText}>
              {`${node?.wasteType.join(', ') || ''} - ${node?.wasteCategory || ''}`}
            </Typography>
            <IconButton
              size="small"
              className={classes.chipButton}
              onClick={editWasteFraction(node, setFieldValue)}
            >
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.waste_fraction.edit"
                    defaultMessage="Click to edit this waste fraction’s details"
                  />
                }
                avoidIcon
              >
                <EditIcon />
              </TooltippedUserControl>
            </IconButton>
            <IconButton
              size="small"
              className={classes.chipButton}
              onClick={removeWasteFraction(node)}
            >
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.waste_fraction.delete"
                    defaultMessage="Click to delete this waste fraction"
                  />
                }
                avoidIcon
              >
                <DeleteIcon />
              </TooltippedUserControl>
            </IconButton>
          </span>
        }
        className={classes.chip}
      />
    );
  };

  const sortByLabel = (a, b) => {
    const lowerA = a.label.toLowerCase();
    const lowerB = b.label.toLowerCase();
    if (lowerA === lowerB) {
      return 0;
    }
    return lowerA > lowerB ? 1 : -1;
  };
  const wasteTypeValuesList = wasteTypeOptions
    .filter((val) => !val.node.parent)
    .map(({ node }) => ({
      label: node.name,
      value: node.id,
      isCustom: !!node.project,
      options: wasteTypeOptions
        .filter((item) => item.node.parent && item.node.parent.id === node.id)
        .map((item) => ({
          label: item.node.name,
          value: item.node.id,
          isCustom: !!item.node.project,
        }))
        .sort(sortByLabel),
    }))
    .sort(sortByLabel);

  return (
    <div>
      <Formik
        initialValues={containerSettingsDefaultValues}
        onSubmit={formSubmitHandler}
        validationSchema={containerSettingsValidationSchema()}
        enableReinitialize
      >
        {({ isSubmitting, values, errors, touched, setFieldValue, resetForm }) => (
          <Form className="w-100 m-t-40 p-b-50">
            <LoadingLayout isLoading={isSubmitting || loading} />
            <Grid container alignItems="center" justify="center" className="m-b-10" spacing={4}>
              <Grid
                container
                item
                xs={12}
                md={10}
                spacing={2}
                direction="column"
                tourid="wasteFractionSettings"
              >
                {isRegularUser && (
                  <FormattedMessage
                    id="no_permission_edit_waste_fraction_settings"
                    defaultMessage="You don’t have permission to add or edit waste fraction settings"
                  >
                    {(text) => <span className={classes.disableEditText}>{text}</span>}
                  </FormattedMessage>
                )}
                <Grid container item xs={12} spacing={4}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={classNames({
                      [classes.errorInput]: errors.wasteType && touched.wasteType,
                    })}
                    tourid="wastefraction"
                  >
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.wasteType"
                          defaultMessage="Choose the type of waste or add a specific type for this project"
                        />
                      }
                    >
                      <InputLabel
                        className={classes.labelText}
                        shrink
                        error={!!(errors.wasteType && touched.wasteType)}
                      >
                        <FormattedMessage id="waste_fraction" defaultMessage="Waste fraction" />
                        &nbsp;*
                      </InputLabel>
                      <SettingsDropdown
                        label={
                          <SettingsMultiChipLabel
                            values={getLabels(values.wasteType)}
                            defaultLabel={intl.formatMessage({
                              id: 'label.wasteType',
                              defaultMessage: 'Choose waste fraction',
                            })}
                            chipClassName={classes.chip}
                          />
                        }
                        handleOpenPopup={handleClickSettingsDropdown('wasteType')}
                        handleClosePopup={handleCloseSettingsDropdown}
                        anchorElement={popupAnchorEl}
                        isOpen={activePopup === 'wasteType'}
                        name="wasteType"
                        popoverClasses={classes.popoverClasses}
                        disabled={isRegularUser}
                      >
                        <SettingsCheckboxGroupWithCustom
                          setValue={onWasteFractionChange(setFieldValue, touched)}
                          selected={values.wasteType}
                          valuesList={wasteTypeValuesList}
                          setWasteTypeIdToDelete={setWasteTypeIdToDelete}
                          getParamsForWasteTypeEdit={setParamsForWasteTypeEdit}
                          disabled={isRegularUser}
                        />
                      </SettingsDropdown>
                    </TooltippedUserControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {weightFactorVisible && (
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.weightFactor"
                            defaultMessage="Enter the ratio of weight to volume"
                          />
                        }
                      >
                        <FormikTextField
                          name="weightFactor"
                          label={intl.formatMessage({
                            id: 'label.weightFactor',
                            defaultMessage: 'Weight to volume ratio',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.weightFactor',
                            defaultMessage: 'E.g. 0.7893',
                          })}
                          helperText={
                            suggestedWeightFactor &&
                            `${intl.formatMessage({
                              id: 'helperText.weightFactor',
                              defaultMessage: 'Suggested value',
                            })} - ${suggestedWeightFactor}`
                          }
                          type="number"
                          onChange={(value) => {
                            const [beforeSeparator, afterSeparator] = value.toString().split('.');
                            const digitsLength = afterSeparator?.length || 0;
                            const maxLength = beforeSeparator?.length + 3;
                            if (digitsLength > 2) {
                              setFieldValue(
                                'weightFactor',
                                value.toString().slice(0, maxLength),
                                true,
                              );
                            }
                          }}
                          InputProps={{
                            onInput: (e) => {
                              if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                const newValue = e.target.value.replaceAll('-', '');
                                e.target.value = newValue;
                              }
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                {`${settingsConfig?.weightVariant}/${settingsConfig?.volumeVariant}`}
                              </InputAdornment>
                            ),
                            inputProps: {
                              step: 0.01,
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    )}
                    <Grid style={{ position: 'fixed' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={weightFactorVisible}
                            onChange={() => setWeightFactorVisible(!weightFactorVisible)}
                            color="primary"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'label.enable_weight_factor',
                          defaultMessage: 'Use weight factor',
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.wasteCategory"
                          defaultMessage="Select the possible disposal methods of this waste fraction"
                        />
                      }
                    >
                      <FormikSelect
                        required
                        name="wasteCategory"
                        label={intl.formatMessage({
                          id: 'label.wasteCategory',
                          defaultMessage: 'Waste disposal method',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'placeholder.wasteCategory',
                          defaultMessage: 'Choose Waste disposal method',
                        })}
                        valuesList={wasteCategoryOptions}
                        filledStyle
                        disabled={isRegularUser}
                      />
                    </TooltippedUserControl>
                  </Grid>
                  {suggestedWeightFactor && Recommendation}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4} className="p-t-30" justify="center">
              <Grid container item xs={12} md={10} spacing={2} tourid="wastefractionList">
                {!isRegularUser && (
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      className={classNames(
                        'w-100 settings-control-button',
                        values.wasteType.length > 0 ? classes.activeButton : '',
                      )}
                      startIcon={editingId ? <EditIcon /> : <AddIcon />}
                      type="submit"
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.waste_fraction.save"
                            defaultMessage="Click to save the details of waste fraction"
                          />
                        }
                        avoidIcon
                      >
                        <Typography variant="body1" className="p-r-20">
                          {editingId ? (
                            <FormattedMessage
                              id="waste_fraction.save"
                              defaultMessage="Save waste fraction"
                            />
                          ) : (
                            <FormattedMessage
                              id="waste_fraction.add"
                              defaultMessage="Add waste fraction"
                            />
                          )}
                        </Typography>
                      </TooltippedUserControl>
                    </Button>
                    {editingId && (
                      <Button
                        variant="outlined"
                        className="w-100 settings-control-button m-t-20"
                        startIcon={<BlockIcon />}
                        type="button"
                        onClick={() => cancelEdit(resetForm)}
                      >
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.waste_fraction.cancel_edit"
                              defaultMessage="Click to cancel the changes without saving"
                            />
                          }
                          avoidIcon
                        >
                          <Typography variant="body1" className="p-r-20">
                            <FormattedMessage
                              id="label.waste_fraction.cancel_edit"
                              defaultMessage="Cancel edit"
                            />
                          </Typography>
                        </TooltippedUserControl>
                      </Button>
                    )}
                  </Grid>
                )}
                <Grid item xs={10}>
                  <Paper className={classes.addedWasteFractions}>
                    <InputLabel shrink>
                      <FormattedMessage
                        id="label.waste_fraction.added"
                        defaultMessage="Added waste fractions"
                      />
                    </InputLabel>
                    {wasteFractions &&
                      wasteFractions.map(({ node }) => getWasteFractionsChip(node, setFieldValue))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center">
              <Grid item xs={3} container justify="flex-end">
                <Button
                  variant="outlined"
                  className="w-60 p-r-40 settings-control-button"
                  startIcon={<ChevronLeftIcon />}
                  type="button"
                  onClick={() =>
                    handleNavigationButtonClick(`/app/settings/${projectId}/container-settings`)
                  }
                >
                  <TooltippedUserControl
                    tooltipText={
                      <FormattedMessage
                        id="tooltip.waste_fraction.back"
                        defaultMessage="Move to container settings"
                      />
                    }
                    avoidIcon
                  >
                    <Typography variant="body1" className="p-r-20">
                      <FormattedMessage id="back" defaultMessage="Back" />
                    </Typography>
                  </TooltippedUserControl>
                </Button>
              </Grid>
              <Grid item xs={3} container justify="flex-start">
                <Button
                  variant="outlined"
                  className="w-60 p-l-40 settings-control-button"
                  endIcon={<ChevronRightIcon />}
                  type="button"
                  onClick={() =>
                    handleNavigationButtonClick(`/app/settings/${projectId}/measurement-settings`)
                  }
                  tourid="nextButton"
                >
                  <TooltippedUserControl
                    tooltipText={
                      <FormattedMessage
                        id="tooltip.waste_fraction.next"
                        defaultMessage="Move to measurement settings"
                      />
                    }
                    avoidIcon
                  >
                    <Typography variant="body1" className="p-l-20">
                      <FormattedMessage id="Next" defaultMessage="Next" />
                    </Typography>
                  </TooltippedUserControl>
                </Button>
              </Grid>
            </Grid>
            <ConfirmDeletionDialog
              open={!!deletingFraction}
              onConfirm={(e) => {
                handleDeleteWasteFraction(e);
                cancelEdit(resetForm);
              }}
              onClose={() => setDeletingFraction('')}
              title={deletingFraction ? deletingFraction.wasteType.join(', ') : ''}
            />
            <ConfirmEmptySettingsDialog
              open={!!emptySettingsConfirmationDialog}
              onConfirm={() => history.push(emptySettingsConfirmationDialog)}
              onClose={() => setEmptySettingsConfirmationDialog('')}
              settingsTitle={
                <FormattedMessage id="waste_fractions" defaultMessage="waste fractions" />
              }
            />
            <ConfirmDeletionDialog
              open={!!wasteTypeIdToDelete}
              onConfirm={() => {
                setWasteTypeIdToDelete(false);
                const isWasteTypeUsedInFractions = wasteTypeOptions.find(
                  ({ node }) => node.id === wasteTypeIdToDelete,
                )?.node?.wastefractionSet?.edges?.length;
                if (isWasteTypeUsedInFractions) {
                  handleDeleteRequestAbord();
                } else {
                  handleDeleteRequest();
                }
              }}
              onClose={() => setWasteTypeIdToDelete('')}
              title={intl.formatMessage({
                id: 'this_waste_type',
                defaultMessage: 'this waste type',
              })}
            />
            <ConfirmDialog
              open={!!paramsForWasteTypeEdit}
              onConfirm={() => {
                updateWasteType(paramsForWasteTypeEdit).then(() => {
                  setParamsForWasteTypeEdit(null);
                  getWasteFractions();
                });
              }}
              onClose={() => setParamsForWasteTypeEdit(null)}
              title={
                wasteTypeInFractions(paramsForWasteTypeEdit?.variables?.selfId) ? (
                  <FormattedMessage
                    id="in_use_waste_type_edit_confirm"
                    defaultMessage="Are you sure you want to save changes? It will affect {fractionsNumber} waste fractions"
                    values={{
                      fractionsNumber: wasteTypeInFractions(
                        paramsForWasteTypeEdit?.variables?.selfId,
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="waste_type_edit_confirm"
                    defaultMessage="Are you sure you want to save changes?"
                  />
                )
              }
            />
            <ConfirmDialog
              open={!!wasteTypeDeletionError}
              onClose={() => setWasteTypeDeletionError(false)}
              title={
                <>
                  <FormattedMessage
                    id="waste_type_delete_error"
                    defaultMessage="Before deleting a waste type, please remove it from a {usedInList} that are using it."
                    values={{ usedInList: wasteTypeDeletionError?.isUsed?.join(', ') }}
                  />
                </>
              }
              noDelete
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

WasteFractionsSettings.propTypes = {
  projectId: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isReseller: PropTypes.bool,
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(withRouter(WasteFractionsSettings)));
