import React, { useMemo } from 'react';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import { loader } from 'graphql.macro';
import { toastifyError } from '../../../../../shared/utils';
import LoadingLayout from '../../../../../shared/loading';
import DetailsSkeleton from '../../components/detailsSkeleton';
import InfoLabel from '../../components/infoLabel';
import InfoBox from '../../components/infoBox';
import { StyledListLink } from '../../components/linkHoverStyle';
import StyledStatus from '../../components/styledStatus';
import OpeningHours from '../../components/openingHours';
import {
  DEPOTS_TYPE_VALUES,
  WORKSHOP_FORM_TYPES,
  TYPES_CHOICES_VALUES,
} from '../../components/utils';
import Address from '../../../../../shared/google/location';

const depotQuery = loader('./../../../../../graphql/queries/fleet_management/depot.graphql');

const useStyles = makeStyles((theme) => ({
  sectionText: {
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: theme.variables.cSlate,
    lineHeight: '16px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
  subList: {
    paddingLeft: theme.spacing(2),
  },
}));

const LocationDetails = ({ match }) => {
  const { params: { id: depotId } = {} } = match;

  const intl = useIntl();
  const classes = useStyles();

  const { loading: depotLoading, data: { depot = {} } = {} } = useQuery(depotQuery, {
    onError: toastifyError,
    variables: { id: depotId },
  });

  const vehicleLabelTitle = intl.formatMessage({
    id: 'location.details.vehicleLabel',
    defaultMessage: 'Vehicle',
  });

  const vehicleBox = useMemo(
    () =>
      depot && depot.depotType === DEPOTS_TYPE_VALUES.depot ? (
        <InfoBox
          lg={3}
          xs={12}
          md={4}
          title={
            <FormattedMessage
              id="location.details.attachedVehiclesBox"
              defaultMessage="Attached Vehicles"
            />
          }
        >
          {depot.vehicles
            ? depot.vehicles.edges.reduce((newVehicleLabels, { node: vehicle }) => {
                newVehicleLabels.push(
                  <InfoLabel
                    key={vehicle.id}
                    title={vehicleLabelTitle}
                    text={
                      <StyledListLink
                        to={`/app/fleet-management/${
                          vehicle.vehicleType?.Type === TYPES_CHOICES_VALUES.streetSweeper
                            ? 'street-sweeper-vehicle'
                            : 'collector-vehicle'
                        }/${vehicle.id}`}
                        text={vehicle.name}
                      />
                    }
                  />,
                );
                return newVehicleLabels;
              }, [])
            : []}
        </InfoBox>
      ) : null,
    [depot, vehicleLabelTitle],
  );

  const wasteFractionsInfoLabels = useMemo(
    () =>
      depot?.wasteFractions
        ? depot.wasteFractions.edges.reduce(
            (newWasteFractionsInfoLabels, { node: wasteFraction }) => {
              const wasteFractionsTitle = `${
                wasteFraction.wasteTypes
                  ? wasteFraction.wasteTypes.edges
                      .reduce((title, { node: wasteType }) => [...title, wasteType.name], [])
                      .join(', ')
                  : '-'
              }`;
              newWasteFractionsInfoLabels.push(
                <InfoLabel
                  title={wasteFractionsTitle || '-'}
                  text={
                    <div>
                      <div>{`Waste Substance: ${wasteFraction.wasteSubstance}`}</div>
                      <br />
                      <div>{`Weight Factor: ${wasteFraction.weightFactor} kg for 1L`}</div>
                    </div>
                  }
                  key={wasteFraction.id}
                />,
              );
              return newWasteFractionsInfoLabels;
            },
            [],
          )
        : [],
    [depot],
  );

  const pageTitle = intl.formatMessage({
    id: 'location.details.pageTitle',
    defaultMessage: 'Location details',
  });

  const mainInfoHeadImageAltText = intl.formatMessage({
    id: 'location.details.mainInfoHeadImageAltText',
    defaultMessage: 'Location Image',
  });

  return (
    <Grid container className="h-100">
      <LoadingLayout isLoading={depotLoading} />
      <DetailsSkeleton
        pageTitle={pageTitle}
        mainInfoHeadData={{
          imageSrc: depot.logo,
          imageAltText: mainInfoHeadImageAltText,
          name: depot.name,
          description: (
            <Address
              placeId={depot?.location?.placeId}
              lng={depot?.location?.longitude}
              lat={depot?.location?.latitude}
            />
          ),
          settingsLink: `${match.url}/edit`,
          children: (
            <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
              <InfoLabel
                title={
                  <FormattedMessage id="location.details.statusLabel" defaultMessage="Status" />
                }
                text={<StyledStatus status={depot.status || ''} />}
              />
            </Grid>
          ),
        }}
      >
        <>
          <InfoBox lg={3} xs={12} md={4} title="Identification">
            <InfoLabel
              title={
                <FormattedMessage
                  id="location.details.locationNameLabel"
                  defaultMessage="Location name"
                />
              }
              text={depot.name}
            />
            <InfoLabel
              title={<FormattedMessage id="location.details.typeLabel" defaultMessage="Type" />}
              text={depot.depotType}
            />
            <InfoLabel
              title={
                <FormattedMessage id="location.details.addressLabel" defaultMessage="Address" />
              }
              text={
                <Address
                  placeId={depot?.location?.placeId}
                  lng={depot?.location?.longitude}
                  lat={depot?.location?.latitude}
                />
              }
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="location.details.geofenceRadiusLabel"
                  defaultMessage="Geofence radius"
                />
              }
              text={depot.geofenceRadius}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="location.details.descriptionLabel"
                  defaultMessage="Description"
                />
              }
              text={depot.description}
            />
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={
              <FormattedMessage
                id="location.details.specificationBox"
                defaultMessage="Specification"
              />
            }
          >
            <InfoLabel
              title={
                <FormattedMessage
                  id="location.details.openingHoursLabel"
                  defaultMessage="Opening hours"
                />
              }
              text={
                <OpeningHours openingTime={depot.openingTime} closingTime={depot.closingTime} />
              }
            />
            {depot.depotType === DEPOTS_TYPE_VALUES.wasteStation && (
              <div>
                <div className={classes.sectionText}>
                  <FormattedMessage
                    id="location.details.wasteFractionsSubtitle"
                    defaultMessage="Waste fractions"
                  />
                </div>
                <div className={classes.subList}>{wasteFractionsInfoLabels}</div>
              </div>
            )}
            {depot.depotType === DEPOTS_TYPE_VALUES.depot && (
              <InfoLabel
                title={
                  <FormattedMessage
                    id="location.details.amountOfTrucks"
                    defaultMessage="Amount of trucks"
                  />
                }
                text={depot.amountOfTrucks}
              />
            )}
            {depot.depotType === DEPOTS_TYPE_VALUES.workshop && (
              <>
                <InfoLabel
                  title={
                    <FormattedMessage
                      id="location.details.workshopType"
                      defaultMessage="Type os service"
                    />
                  }
                  text={
                    WORKSHOP_FORM_TYPES.find((workshop) => workshop.value === depot.workshopType)
                      ?.label
                  }
                />
                <InfoLabel
                  title={
                    <FormattedMessage
                      id="location.details.workshopServiceDescription"
                      defaultMessage="Service description"
                    />
                  }
                  text={depot.workshopServiceDescription}
                />
              </>
            )}
          </InfoBox>
          {vehicleBox}
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={
              <FormattedMessage
                id="location.details.contactPersonBox"
                defaultMessage="Contact Person"
              />
            }
          >
            <InfoLabel
              title={
                <FormattedMessage id="location.details.personNameLabel" defaultMessage="Name" />
              }
              text={depot.contactPerson ? depot.contactPerson.name : ''}
            />
            <InfoLabel
              title={
                <FormattedMessage id="location.details.personEmailLabel" defaultMessage="Email" />
              }
              text={depot.contactPerson ? depot.contactPerson.email : ''}
            />
            <InfoLabel
              title={
                <FormattedMessage id="location.details.personPhoneLabel" defaultMessage="Phone" />
              }
              text={depot.contactPerson ? depot.contactPerson.phone : ''}
            />
          </InfoBox>
        </>
      </DetailsSkeleton>
    </Grid>
  );
};

LocationDetails.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(LocationDetails);
