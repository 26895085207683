import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import RightIcon from '@material-ui/icons/ChevronRight';
import SupportCategoryRouter from './supportCategoryRouter';
import navData from './navData';
import SupportSearch from './shared/supportSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100%',
    overflow: 'unset',
    padding: 20,
    backgroundColor: 'white',
  },
  paper: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: theme.variables.cSnow,
    borderRadius: 15,
  },
  list: {
    padding: 0,
  },
  catLabelArea: {
    pointerEvents: 'none',
  },
  navLink: {
    color: theme.variables.VH,
    display: 'block',
    textDecoration: 'none',
  },
  navText: {
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.variables.cTextDark,
  },
  navChildText: {
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    color: theme.variables.VHBlue,
    textDecoration: 'none',
    display: 'block',
    '&:hover': {
      color: theme.variables.cOrange,
    },
    '&.active': {
      color: theme.variables.cOrange,
    },
  },
}));

const SupportCategoryPage = ({ supportCatName, match, history, tabSelect }) => {
  const categoryData = navData.filter((nav) => nav.name.includes(supportCatName));
  const classes = useStyles();

  useEffect(() => {
    if (categoryData[0].routeLink) {
      tabSelect(categoryData[0].routeLink);
    }
  }, [tabSelect, categoryData]);

  useEffect(() => {
    // to go to first sub category page
    if (
      categoryData[0].children[0] &&
      !categoryData[0].children.filter((catChild) =>
        history.location.pathname.includes(catChild.routeLink),
      ).length
    ) {
      history.replace({
        pathname: `${match.url}${categoryData[0].children[0].routeLink}`,
      });
    }
  });

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <SupportSearch categoryData={categoryData} />
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper} elevation={0}>
            <List className={classes.list}>
              {categoryData.map((nav) => (
                <React.Fragment key={nav.name}>
                  <ListItem button className={classes.catLabelArea}>
                    <ListItemText
                      primary={<Typography className={classes.navText}>{nav.label}</Typography>}
                    />
                  </ListItem>
                  {nav.children && nav.children.length > 0 && (
                    <List>
                      {nav.children.map((subCat) => (
                        <NavLink
                          to={`${match.url}${subCat.routeLink}`}
                          activeStyle={{ opacity: 1 }}
                          className={classes.navChildText}
                          key={subCat.name}
                        >
                          <ListItem button>
                            <ListItemText
                              primary={
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {subCat.label}
                                </Typography>
                              }
                            />
                            <ListItemIcon>
                              <RightIcon />
                            </ListItemIcon>
                          </ListItem>
                        </NavLink>
                      ))}
                    </List>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <SupportCategoryRouter />
        </Grid>
      </Grid>
    </>
  );
};
SupportCategoryPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  supportCatName: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  tabSelect: PropTypes.func.isRequired,
};

export default withRouter(SupportCategoryPage);
