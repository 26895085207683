import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Client } from '@googlemaps/google-maps-services-js';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { TranslationContext } from '../../../translations';

const useStyles = makeStyles(() => ({
  skeleton: {
    minWidth: 100,
    width: '100%',
  },
}));

export const useAddress = ({ placeId, lat, lng, address, allowAPICall }) => {
  const { locale } = useContext(TranslationContext);
  const [prevCoords, setPrevCoords] = useState({ lat: -200, lng: -200 });
  const [googleAddress, setGoogleAddress] = useState(address ?? '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!lat && !lng && !address) {
      setGoogleAddress('');
    }
  }, [lat, lng, address, setGoogleAddress]);

  useEffect(() => {
    if (
      (!lat && !lng) ||
      (prevCoords.lat && prevCoords.lng && prevCoords.lat === lat && prevCoords.lng === lng)
    ) {
      return;
    }
    if (process.env.REACT_APP_ENVIRONMENT !== 'production' && !allowAPICall) {
      setGoogleAddress(`[DEBUG] ${lat} // ${lng}`);
      setLoading(false);
      return;
    }

    if (address && address !== googleAddress) {
      setPrevCoords({ lat, lng });
      setGoogleAddress(address);

      setLoading(false);
      return;
    }

    setLoading(true);
    const client = new Client({});
    const requestParams = {
      language: locale,
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    };

    if (placeId) {
      requestParams.place_id = placeId;
    } else {
      requestParams.latlng = [lat, lng];
    }

    client
      .reverseGeocode({
        params: requestParams,
      })
      .then((response) => {
        const [first] = response?.data?.results || [];
        // Google SDK is using not a CamelCase parameter formatted_address
        // eslint-disable-next-line camelcase
        return first?.formatted_address || '';
      })
      .then((result) => {
        setPrevCoords({ lat, lng });
        setGoogleAddress(result);
      })
      .catch(window.console.dir)
      .finally(() => {
        setLoading(false);
      });
  }, [
    placeId,
    lat,
    lng,
    address,
    googleAddress,
    prevCoords.lat,
    prevCoords.lng,
    locale,
    allowAPICall,
  ]);

  return { loading, address: googleAddress };
};

const Address = ({ lat, lng, address, noneValue, placeId, me }) => {
  const { loading, address: localAddress } = useAddress({
    placeId,
    lat,
    lng,
    address,
    allowAPICall: me.isBeta,
  });
  const classes = useStyles();

  if (!localAddress && !loading) {
    return <span>{noneValue}</span>;
  }

  return loading ? <Skeleton className={classes.skeleton} /> : <span>{localAddress}</span>;
};

Address.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  address: PropTypes.string,
  noneValue: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeId: PropTypes.string,
  me: PropTypes.shape({
    isBeta: PropTypes.bool,
  }).isRequired,
};

Address.defaultProps = {
  lat: null,
  lng: null,
  address: '',
  noneValue: '-',
  placeId: '',
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(Address);
