import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 700,
    padding: '24px 10px',
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 16,
  },
  primary: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.variables.VHDarkBlue,
  },
  link: {
    textDecoration: 'none',
  },
  secondary: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.variables.VHDarkGrey,
    paddingTop: 8,
  },
  avatar: {
    height: 51,
    width: 51,
    backgroundColor: 'inherit',
  },
  avatarCircle: {
    border: `1px solid ${theme.variables.cOrange}`,
  },
  disabledLink: {
    pointerEvents: 'none',
  },
}));

const MenuItem = ({ primary, secondary, icon, href, disabled, tourid }) => {
  const classes = useStyles();

  return (
    <NavLink
      to={href}
      className={classNames(classes.link, { [classes.disabledLink]: disabled })}
      tourid={tourid}
    >
      <ListItem className={classes.root} button disabled={disabled}>
        <ListItemAvatar>
          <Avatar
            classes={{
              root: classes.avatar,
              circle: classes.avatarCircle,
            }}
          >
            <img src={icon} alt={primary} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          classes={{
            root: classes.text,
            primary: classes.primary,
            secondary: classes.secondary,
          }}
          primary={primary}
          secondary={secondary}
        />
      </ListItem>
    </NavLink>
  );
};

MenuItem.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tourid: PropTypes.string,
};

MenuItem.defaultProps = {
  disabled: false,
  tourid: '',
};

export default MenuItem;
