import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, getIn } from 'formik';
import FormGroup from '@material-ui/core/FormGroup';
import Tabs from '@material-ui/core/Tabs';
import { FormControlLabel, Grid } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { fixedPickupsPeriods, pickupIntervals } from '../../shared/utils';
import {
  FormikSelect,
  FormikTextField,
  FormikCheckbox,
  FormikTimeDiffFields,
  memoizeFields,
  formikComponentMemoizeValueCheck,
  formikInjectedPropsTypes,
} from '../../../../../../shared/inputs/formik';
import TabPanel, { a11yProps } from './pickupsDetailsTab';
import HoursForPickup from './selectHoursForPickup';
import { daysOptions } from '../../../../settings/project/defaults/data';
import FormikButtonSwitch from '../../../../../../shared/inputs/formik/FormikButtonSwitch';
import TooltippedUserControl from '../../../../../../shared/tooltip/TooltippedUserControl';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 'bold',
  },
  maskedInput: {
    width: 140,
    '& .MuiInput-root': {
      marginTop: 0,
    },
    '& .MuiInput-input': {
      textAlign: 'center',
    },
  },
  styledTab: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      width: 115,
      height: 44,
      minWidth: 'unset',
      lineHeight: '14px',
      borderRadius: 22,
      fontSize: '14px',
      textTransform: 'none',
      backgroundColor: theme.variables.cAntiFlashWhite,
      color: theme.variables.cIndependence,
      '&.Mui-selected': {
        color: theme.variables.VHOrange,
      },
    },
  },
  smallDropDown: {
    maxHeight: 180,
  },
}));

const PickupsDetailsForm = ({ formik }) => {
  const classes = useStyles();
  const { values, setFieldValue } = formik;
  const fixedPickupsPeriod = getIn(values, 'fixedPickupsPeriod');
  const pickupDay = getIn(values, 'pickupDay');
  const intl = useIntl();

  const handleChange = (event, newValue) => {
    setFieldValue('fixedPickupsPeriod', newValue);
  };

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.label}>
            <FormattedMessage id="select_static_pickups" defaultMessage="Select static pickups" />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Tabs
            value={fixedPickupsPeriod}
            onChange={handleChange}
            className={classes.styledTab}
            aria-label="pickups.tabs"
          >
            <Tab
              label={intl.formatMessage({ id: 'fixed_pickups.week', defaultMessage: 'Week' })}
              {...a11yProps(fixedPickupsPeriods.indexOf('week'))}
            />
            <Tab
              label={intl.formatMessage({ id: 'fixed_pickups.days', defaultMessage: 'Days' })}
              {...a11yProps(fixedPickupsPeriods.indexOf('days'))}
            />
            <Tab
              label={intl.formatMessage({
                id: 'fixed_pickups.customizable',
                defaultMessage: 'Customizable',
              })}
              {...a11yProps(fixedPickupsPeriods.indexOf('flexible'))}
            />
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={fixedPickupsPeriod} index={fixedPickupsPeriods.indexOf('week')}>
        {daysOptions.map(({ value, label }) => (
          <Grid key={value} item xs={12} container justify="flex-start" alignItems="center">
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  control={<FormikCheckbox name={`pickupDay.${value}.collect`} />}
                  classes={{
                    label: classes.label,
                  }}
                  label={label}
                />
              </FormGroup>
            </Grid>
            <FormikTimeDiffFields
              fromName={`pickupDay.${value}.timeFrom`}
              toName={`pickupDay.${value}.timeTo`}
              disabled={!pickupDay[value].collect}
            />
          </Grid>
        ))}
        <Grid item className={classes.fieldContainer}>
          <FormikButtonSwitch
            label={intl.formatMessage({
              id: 'collectionPerWeek',
              defaultMessage: 'Collection per week',
            })}
            name="collectionPerWeek"
            valuesList={daysOptions.map((item, index) => ({
              value: index + 1,
              label: index + 1,
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.collections_week_number"
                defaultMessage="If collecting every 3rd week, enter 2 as the number of weeks between collections."
              />
            }
          >
            <FormikTextField
              fast
              label={intl.formatMessage({
                id: 'label.collections_week_number',
                defaultMessage: 'Number of weeks between collections',
              })}
              placeholder={intl.formatMessage({
                id: 'placeholder.collections_week_number_enter',
                defaultMessage: 'Enter number of weeks',
              })}
              type="number"
              required
              name="pickupRepeatPeriod"
            />
          </TooltippedUserControl>
        </Grid>
      </TabPanel>
      <TabPanel value={fixedPickupsPeriod} index={fixedPickupsPeriods.indexOf('days')}>
        <Grid item xs={12}>
          <FormikTextField
            fast
            label={intl.formatMessage({
              id: 'label.amountDaysBetweenPickups',
              defaultMessage: 'Select amount of days between pickups',
            })}
            placeholder={intl.formatMessage({
              id: 'placeholder.amountDaysBetweenPickups',
              defaultMessage: 'Number of days between 1 and 50',
            })}
            name="amountDaysBetweenPickups"
          />
        </Grid>
        <HoursForPickup />
      </TabPanel>
      <TabPanel value={fixedPickupsPeriod} index={fixedPickupsPeriods.indexOf('flexible')}>
        <Grid item xs={12}>
          <FormikSelect
            label={intl.formatMessage({
              id: 'label.pickupInterval',
              defaultMessage: 'Select pickup interval',
            })}
            placeholder={intl.formatMessage({
              id: 'placeholder.pickupInterval',
              defaultMessage: 'Pickup interval',
            })}
            required
            fast
            filledStyle
            name="pickupInterval"
            valuesList={pickupIntervals}
          />
        </Grid>
        <HoursForPickup />
        <Grid item xs={12}>
          <FormikTextField
            fast
            name="minimumDaysBetweenPickup"
            type="text"
            label={intl.formatMessage({
              id: 'label.minimumDaysBetweenPickup',
              defaultMessage: 'Minimum days in between pickups',
            })}
            placeholder={intl.formatMessage({
              id: 'placeholder.minimumDaysBetweenPickup',
              defaultMessage: 'E.g. 10',
            })}
          />
        </Grid>
      </TabPanel>
    </Grid>
  );
};

PickupsDetailsForm.propTypes = {
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

export default connect(
  React.memo(
    PickupsDetailsForm,
    memoizeFields([
      formikComponentMemoizeValueCheck('fixedPickupsPeriod'),
      formikComponentMemoizeValueCheck('pickupDay'),
    ]),
  ),
);
