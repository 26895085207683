import React from 'react';

const StopMarker = () => (
  <div
    style={{
      width: 8,
      height: 8,
      backgroundColor: '#fff',
      borderRadius: 50,
      border: '2px solid #323232',
    }}
  />
);

export default StopMarker;
