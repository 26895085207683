import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { loader } from 'graphql.macro';
import FormikSelect from '../inputs/formik/FormikSelect';
import NoItemsForSelect from './noItemsForSelect';
import InputSkeleton from '../inputs/InputSkeleton';
import { DEPOTS_TYPE_VALUES } from '../../main/routes/fleet-management/components/utils';

const allDepotsQuery = loader('./../../graphql/queries/fleet_management/all_depots.graphql');

const SelectDepots = ({
  name,
  extraFilter,
  placeholder,
  label,
  multiSelect,
  required,
  md,
  activeCompanies,
}) => {
  const {
    loading,
    data: { allDepots: { edges: depots = [] } = {} } = {},
  } = useQuery(allDepotsQuery, { variables: { ...extraFilter, activeCompanies } });

  const allDepots = useMemo(
    () =>
      depots.map(({ node }) =>
        Object({
          value: node.id,
          label: node.name,
        }),
      ),
    [depots],
  );

  let noItemMessage = <FormattedMessage id="no_depot" defaultMessage="No depots registered." />;
  let noLinkText = <FormattedMessage id="add_depot" defaultMessage="Click to add new depot." />;
  if (extraFilter.type && extraFilter.type !== DEPOTS_TYPE_VALUES.depot) {
    noItemMessage = (
      <FormattedMessage id="no_waste_station" defaultMessage="No waste stations registered." />
    );
    noLinkText = (
      <FormattedMessage id="add_waste_station" defaultMessage="Click to add new waste station." />
    );
  }

  const locationType = extraFilter.type || DEPOTS_TYPE_VALUES.depot;

  return (
    <Grid item xs={12} md={md} container alignItems="center">
      {loading ? (
        <InputSkeleton />
      ) : (
        <>
          {allDepots.length > 0 ? (
            <FormikSelect
              placeholder={placeholder}
              label={label}
              required={required}
              filledStyle
              name={name}
              multiSelect={multiSelect}
              valuesList={allDepots}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <NoItemsForSelect
              link={`/app/fleet-management/locations/add?locationType=${locationType}`}
              message={noItemMessage}
              linkText={noLinkText}
            />
          )}
        </>
      )}
    </Grid>
  );
};

SelectDepots.propTypes = {
  extraFilter: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
  md: PropTypes.number,
  activeCompanies: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SelectDepots.defaultProps = {
  multiSelect: true,
  required: true,
  extraFilter: {},
  md: 12,
};

const mapStateToProps = (state) => ({
  activeCompanies: Array.from(
    new Set(state.settings.user.activeProjects.edges.map(({ node }) => node.company.id)),
  ),
});
export default connect(mapStateToProps)(React.memo(SelectDepots));
