import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const SmallTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <Paper className="recharts-default-tooltip">
        <div className="recharts-tooltip-item">
          <span>
            <FormattedMessage id="emptied" defaultMessage="Emptied" />:
          </span>
          <span>
            <FormattedMessage id="fill_level" defaultMessage="Fill level" />:
          </span>
        </div>
        <div className="recharts-tooltip-item">
          <span>{label}</span>
          <span>{payload && payload[0] ? payload[0].payload.fillLevel : 0}%</span>
        </div>
      </Paper>
    );
  }

  return null;
};

SmallTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        fillLevel: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  ),
};

SmallTooltip.defaultProps = {
  active: false,
  label: '',
  payload: [],
};

const SmallHistoryChart = ({ data }) => (
  <ResponsiveContainer width="99.9%" height="99%">
    <LineChart
      data={data}
      margin={{
        top: 10,
        right: 30,
        bottom: 0,
        left: -30,
      }}
    >
      <Line type="monotone" dataKey="fillLevel" stroke="#DF9838" dot={false} />
      <CartesianGrid stroke="#c6c6c6" strokeDasharray="5 5" />
      <XAxis dataKey="emptied" />
      <YAxis ticks={[0, 25, 50, 75, 100]} />
      <Tooltip content={<SmallTooltip />} />
    </LineChart>
  </ResponsiveContainer>
);

SmallHistoryChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      emptied: PropTypes.string.isRequired,
      fillLevel: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

export default React.memo(SmallHistoryChart);
