import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import { getUsersAvailableProjects } from '../../../../shared/utils';

const useStyles = makeStyles(() => ({
  roundedBtn: {
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    border: '1px solid',
  },
  submitBtn: {
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
}));

const AddButton = ({ text, match, disabled }) => {
  const classes = useStyles();
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));

  return (
    <Button
      component={Link}
      variant="outlined"
      color="primary"
      className={classNames(classes.submitBtn, classes.roundedBtn, 'w-100', 'h-100')}
      startIcon={<AddIcon />}
      type="button"
      to={`${match.url}/add`}
      disabled={!usersAvailableProjects?.length || disabled}
    >
      {text}
    </Button>
  );
};

AddButton.propTypes = {
  text: PropTypes.element.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  disabled: PropTypes.bool,
};

AddButton.defaultProps = {
  disabled: false,
};

export default withRouter(AddButton);
