import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { connect, getIn } from 'formik';
import BaseFormikInput from './BaseFormikInput';
import formikInjectedPropsTypes from './formikPropTypes';
import { formikFieldMemoizeCheck, memoizeFields } from './memoraize';

const useDatePickerStyles = makeStyles(() => ({
  customTimePicker: {
    '& .MuiInputAdornment-positionEnd': {
      paddingRight: 0,
    },
    '& .MuiInput-root': {
      minWidth: 125,
    },
  },
}));

const FormikDatePicker = ({
  name,
  required,
  label,
  id,
  className,
  onChange,
  disabled,
  placeholder,
  formik,
  autoOk,
  disablePast,
  initialFocusedDate,
}) => {
  const intl = useIntl();
  const classes = useDatePickerStyles();
  const isTouched = getIn(formik.touched, name);
  const error = isTouched && getIn(formik.errors, name);
  const value = getIn(formik.values, name);

  React.useEffect(() => {
    formik.registerField(name, {});
    return () => {
      formik.unregisterField(name);
    };
  }, [name, formik.registerField, formik.unregisterField, formik]);

  return (
    <BaseFormikInput
      filledStyle
      name={name}
      id={id}
      onChange={onChange}
      required={required}
      className={className}
    >
      {({ isError, inputId }) => (
        <>
          <InputLabel
            required={required}
            error={!!isError}
            shrink
            id={`label-${inputId}`}
            htmlFor={inputId}
            style={{ textTransform: 'capitalize' }}
          >
            {label}
          </InputLabel>
          <KeyboardDatePicker
            clearable
            id={inputId}
            name={name}
            className={classes.customTimePicker}
            initialFocusedDate={initialFocusedDate}
            autoOk={autoOk}
            disablePast={disablePast}
            cancelLabel={intl.formatMessage({
              id: 'label.cancel',
              defaultMessage: 'Cancel',
            })}
            clearLabel={intl.formatMessage({
              id: 'label.clear',
              defaultMessage: 'Clear',
            })}
            format="DD.MM.YYYY"
            okLabel={intl.formatMessage({
              id: 'label.ok',
              defaultMessage: 'Ok',
            })}
            value={value}
            onChange={(date) => {
              formik.setFieldValue(name, date, true);
            }}
            onBlur={() => {
              formik.setFieldTouched(name, true, true);
            }}
            helperText={error}
            error={!!error}
            disabled={disabled}
            placeholder={placeholder}
          />
        </>
      )}
    </BaseFormikInput>
  );
};

FormikDatePicker.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  autoOk: PropTypes.bool,
  disablePast: PropTypes.bool,
  initialFocusedDate: PropTypes.instanceOf(Date),
};

FormikDatePicker.defaultProps = {
  required: false,
  disabled: false,
  placeholder: '',
  label: '',
  className: '',
  id: '',
  onChange: () => {},
  autoOk: false,
  disablePast: false,
  initialFocusedDate: null,
};

export default connect(
  React.memo(
    FormikDatePicker,
    memoizeFields([
      'name',
      'required',
      'ampm',
      'disabled',
      'placeholder',
      'label',
      'className',
      'onChange',
      'children',
      'id',
      ...formikFieldMemoizeCheck(),
    ]),
  ),
);
