import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';

import styles from '../styles';
import NotLoginDefaultPageLayout from '../../layout/notLoginDefaultPageLayout';
import { toastifyError } from '../../shared/utils';
import { FormikTextField } from '../../shared/inputs/formik';

const resetPassword = loader('./../../graphql/mutations/core/reset_password.graphql');

const ResetPassword = ({ classes, history }) => {
  const [resetPasswordQuery, { loading, error }] = useMutation(resetPassword);
  const intl = useIntl();

  const tryReset = async (values) => {
    await resetPasswordQuery({
      variables: values,
    });
    history.push('/login/?checkEmail=true');
  };

  return (
    <NotLoginDefaultPageLayout loading={loading}>
      <Grid container justify="center" alignItems="center" className="h-100">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required()
              .email(
                <FormattedMessage
                  id="email.validation"
                  defaultMessage="This field should be a valid email."
                />,
              ),
          })}
          onSubmit={(values, { setSubmitting }) =>
            tryReset(values)
              .catch(toastifyError)
              .finally(() => setSubmitting(false))
          }
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form className={classes.wrap}>
              <Grid container justify="center">
                <Grid item xs={12} md={9} lg={7} className={classes.paddedRow}>
                  <Typography variant="h4" align="center">
                    <FormattedMessage id="pageName.resetPassword" defaultMessage="Reset password" />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} lg={7}>
                  <FormikTextField
                    name="email"
                    label={intl.formatMessage({
                      id: 'resetPassword.label.username',
                      defaultMessage: 'Username',
                    })}
                    InputProps={{
                      autoComplete: 'current-username',
                    }}
                  />
                </Grid>
                <Grid item container justify="center" xs={12} md={9} lg={7}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.submitBtn}
                      onClick={submitForm}
                      disabled={isSubmitting || !values.email}
                    >
                      <FormattedMessage
                        id="button.reset_password"
                        defaultMessage="RESET PASSWORD"
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={7}
                  className={classNames(classes.submitRow, classes.alignCenter)}
                >
                  <Link to="/login" className={classes.forgotPassword}>
                    <FormattedMessage id="link.back_to_login" defaultMessage="Back to Login" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={9} lg={7}>
                  <div className={classes.errorMessage}>{error?.message}</div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </NotLoginDefaultPageLayout>
  );
};

ResetPassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withStyles(styles)(ResetPassword);
