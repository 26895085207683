import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { RegisterForm } from '../register';
import Transition from '../../../../../shared/animations/transition';

const styles = () => ({
  dialogBody: {
    padding: 20,
    minHeight: '60vh',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const RegisterContainerDialog = ({ open, onClose, classes, initialValues }) => (
  <Dialog
    onClose={onClose}
    open={open}
    maxWidth="lg"
    fullWidth
    scroll="body"
    TransitionComponent={Transition}
    keepMounted
    aria-labelledby="register-container-dialog"
    aria-describedby="register-container-description"
    onKeyPress={(event) => {
      if (event.key === 'Enter') {
        onClose();
      }
    }}
  >
    <DialogTitle id="register-container-dialog" className={classes.dialogTitle}>
      <FormattedMessage id="adding_container" defaultMessage="Adding  Container" />
    </DialogTitle>
    <DialogContent className={classes.dialogBody}>
      <Grid container className="m-10">
        <RegisterForm onSubmit={onClose} initialValues={initialValues} noProjectSelection />
      </Grid>
    </DialogContent>
  </Dialog>
);

RegisterContainerDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  initialValues: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterContainerDialog);
