import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import StyledDateRangePicker from '../inputs/DateRangePicker';
import { getRangeForPeriod } from './utils';
import { PeriodFilterPropTypes } from './types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `0 ${theme.spacing(1)}px`,
    borderColor: theme.variables.cLightGray,
    height: 40,
    '&>div': {
      backgroundColor: 'inherit',
    },
  },
  periodSelector: {
    marginRight: 0,
    marginLeft: 0,

    '&>div': {
      width: 120,
      textAlign: 'center',
      backgroundColor: theme.variables.cAntiFlashWhite,
      border: `1px solid ${theme.variables.cAntiFlashWhite}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      fontWeight: 500,
    },
  },
  dateRangeSelector: {
    width: 260,
    '& .DateRangePickerInput': {
      backgroundColor: theme.variables.cAntiFlashWhite,
      borderRadius: 4,
      border: `1px solid ${theme.variables.cAntiFlashWhite}`,
    },
  },
}));

const previousPeriodOptions = [
  <MenuItem value="year" key="year">
    <FormattedMessage id="year" defaultMessage="Year" />
  </MenuItem>,
  <MenuItem value="last_month" key="last_month">
    <FormattedMessage id="last_month" defaultMessage="Last month" />
  </MenuItem>,
  <MenuItem value="last_week" key="last_week">
    <FormattedMessage id="last_week" defaultMessage="Last week" />
  </MenuItem>,
  <MenuItem value="day" key="day">
    <FormattedMessage id="day" defaultMessage="Day" />
  </MenuItem>,
];

const nextPeriodOptions = [
  <MenuItem value="next_year" key="next_year">
    <FormattedMessage id="next_year" defaultMessage="Next Year" />
  </MenuItem>,
  <MenuItem value="next_month" key="next_month">
    <FormattedMessage id="next_month" defaultMessage="Next month" />
  </MenuItem>,
  <MenuItem value="next_week" key="next_week">
    <FormattedMessage id="next_week" defaultMessage="Next week" />
  </MenuItem>,
  <MenuItem value="next_day" key="next_day">
    <FormattedMessage id="next_day" defaultMessage="Next Day" />
  </MenuItem>,
];

const PeriodFilter = ({
  startDate,
  endDate,
  period,
  setStartDate,
  setEndDate,
  setPeriod,
  availableDays,
  futurePeriod,
  noEmptyPeriod,
  hidePeriodSelector,
  disabled,
}) => {
  const classes = useStyles();

  const [localStartDate, setLocalStartDate] = useState();
  const [localEndDate, setLocalEndDate] = useState();
  useEffect(() => {
    setLocalStartDate(startDate);
  }, [startDate]);
  useEffect(() => {
    setLocalEndDate(endDate);
  }, [endDate]);

  const setStartDateLogic = useCallback((date) => {
    setLocalEndDate(null);
    setLocalStartDate(date?.startOf('day'));
  }, []);

  const setEndDateLogic = useCallback(
    (date) => {
      const endOfDay = date?.endOf('day');
      setLocalEndDate(endOfDay);

      setStartDate(localStartDate);
      setEndDate(endOfDay);
      setPeriod('custom');
    },
    [localStartDate, setEndDate, setStartDate, setPeriod],
  );

  const setPeriodHandler = (e) => {
    const newPeriod = e.target.value;
    if (newPeriod === 'all') {
      setPeriod(newPeriod);
      setStartDate(null);
      setEndDate(null);
      return;
    }
    const { start, end } = getRangeForPeriod(newPeriod, localStartDate, localEndDate);
    setPeriod(newPeriod);
    setStartDate(start);
    setEndDate(end);
  };

  const periodToDisplay = futurePeriod ? nextPeriodOptions : previousPeriodOptions;

  return (
    <Grid container className="w-100" direction="row" justify="flex-start">
      {!hidePeriodSelector && (
        <FormControl className={classNames(classes.formControl, classes.periodSelector)}>
          <Select
            value={period}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            disabled={disabled}
            onChange={setPeriodHandler}
          >
            {periodToDisplay.map((dynamicPeriod) => dynamicPeriod)}
            <MenuItem value="custom">
              <FormattedMessage id="custom" defaultMessage="Custom" />
            </MenuItem>
            {!noEmptyPeriod && (
              <MenuItem value="all">
                <FormattedMessage id="all" defaultMessage="all" />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      )}
      <FormControl className={classNames(classes.formControl, classes.dateRangeSelector)}>
        <StyledDateRangePicker
          availableDays={availableDays}
          endDate={localEndDate}
          startDate={localStartDate}
          setEndDate={setEndDateLogic}
          setStartDate={setStartDateLogic}
          futurePeriod={futurePeriod}
          disabled={disabled}
        />
      </FormControl>
    </Grid>
  );
};

PeriodFilter.propTypes = {
  ...PeriodFilterPropTypes,
  availableDays: PropTypes.arrayOf(
    PropTypes.oneOfType([MomentPropTypes.momentObj.isRequired, PropTypes.oneOf([null]).isRequired]),
  ),
  futurePeriod: PropTypes.bool,
  noEmptyPeriod: PropTypes.bool,
  hidePeriodSelector: PropTypes.bool,
  disabled: PropTypes.bool,
};

PeriodFilter.defaultProps = {
  availableDays: [],
  futurePeriod: false,
  noEmptyPeriod: false,
  hidePeriodSelector: false,
  disabled: false,
};

export default PeriodFilter;
