import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { VehicleTypesList, VehicleTypeEdit } from '.';
import { getUsersAvailableProjects } from '../../../../../shared/utils';

const VehicleTypeManagementRouter = ({ match }) => {
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={VehicleTypesList} />
      {!usersAvailableProjects?.length && <Redirect to={`${match.url}`} />}
      <Route exact path={`${match.url}/add`} component={VehicleTypeEdit} />
      <Route exact path={`${match.url}/:id/edit`} component={VehicleTypeEdit} />
    </Switch>
  );
};

VehicleTypeManagementRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default VehicleTypeManagementRouter;
