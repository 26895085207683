import React from 'react';
import { FormattedMessage } from 'react-intl';

export const filterValues = {
  all: 'all',
  withSensors: false,
  withoutSensors: true,
};

export const filterContainerDeviceOptions = [
  {
    value: filterValues.all,
    label: <FormattedMessage id="all_sensors" defaultMessage="All" />,
  },
  {
    value: filterValues.withSensors,
    label: <FormattedMessage id="with_sensors" defaultMessage="With sensors" />,
  },
  {
    value: filterValues.withoutSensors,
    label: <FormattedMessage id="without_sensors" defaultMessage="Without sensors" />,
  },
];

export const getFillIndicatorColor = (fillLevel) => {
  if (fillLevel === undefined) {
    return '#313C59';
  }
  if (fillLevel > 75) {
    return '#EC6B6B';
  }
  if (fillLevel > 50) {
    return '#E58E3E';
  }
  if (fillLevel > 25) {
    return '#D9CC5D';
  }
  return '#4FB42C';
};
