const styles = (theme) => ({
  searchWrap: {
    padding: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    flexShrink: 0,
  },
  searchIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  searchSettingsBtn: {
    minWidth: 34,
    padding: 0,
    marginLeft: 10,
    color: '#666',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  editBtn: {
    width: 12,
    borderRadius: 4,
  },
  orderIcon: {
    marginLeft: 5,
  },
  orderLabel: {
    whiteSpace: 'nowrap',
  },
  mapCol: {
    background: '#ccc',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
  },
  toggleSettings: {
    position: 'absolute',
    zIndex: 10,
    top: 'calc(50% - 75px)',
    minWidth: 20,
    padding: 0,
    height: 75,
  },
  toggleMap: {
    position: 'absolute',
    zIndex: 10,
    minWidth: 20,
    padding: 0,
    height: 75,
    right: 0,
  },
  clearAll: {
    color: theme.variables.cGray,
    fontSize: 14,
    marginLeft: 15,
    fontWeight: 'light',
    minHeight: 'inherit',
    minWidth: 'inherit',
    padding: '2px 5px',
  },
  moreBtn: {
    display: 'block',
    fontSize: 14,
    marginTop: 12,
    minHeight: 'inherit',
    minWidth: 'inherit',
    padding: '2px 5px',
  },
  checkbox: {
    height: 'inherit',
    width: 'inherit',
  },
  checkboxListItem: {
    padding: '7px 0',
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    paddingLeft: 10,
  },
  fillLevelPanel: {
    padding: '14px 0',
  },
  filterSliderValues: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  newRouteButton: {
    boxSizing: 'border-box',
    textAlign: 'center',
    position: 'absolute',
    top: '85px',
    left: 'calc(50% - 177px / 2)',
    zIndex: 9999999,
  },
  projectAccordion: {
    borderTop: '2px solid black',
  },
  addButtonLink: {
    display: 'block',
    width: 'fit-content',
    textDecoration: 'none',
  },
  selectContainer: {
    '& .MuiInputLabel-root': {},
  },
});

export default styles;
