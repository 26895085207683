import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import styles from './styles';
import { ContainerType } from '../shared/types';
import { getFieldsErrors, messageNoNegativeValues } from '../../../../../shared/utils';
import RegisterMeasurementDialog from '../../../settings/project/registerMeasurementDialog';
import { getSteps, getStepContent, getStepFields, checkStepFields } from './steps';

const InlineAttachDeviseForm = ({
  initialValues,
  onSubmit,
  allMeasurementSettings,
  classes,
  checkDeviceHandler,
  history,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openRegisterMS, setOpenRegisterMS] = useState(false);
  const [failedStep, setFailedStep] = useState(-1);
  const steps = getSteps();
  const intl = useIntl();

  const handleNext = () => {
    setFailedStep(-1); // If user can press next - step is valid
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (resetForm) => () => {
    resetForm();
    setActiveStep(0);

    if (initialValues.containerId) {
      history.push('/app/containers/attach');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        deviceId: Yup.string()
          .required()
          .test({
            name: 'is-available-device-id',
            exclusive: true,
            message: 'Sensor with id does not exist',
            async test(value) {
              const stepFields = getStepFields(activeStep);

              // eslint-disable-next-line react/no-this-in-sfc
              if (stepFields.required.includes(this.path)) {
                const status = await checkDeviceHandler(value);
                if (status !== 'success') {
                  // eslint-disable-next-line react/no-this-in-sfc
                  return this.createError({
                    message: status,
                  });
                }
              }
              return true;
            },
          }),
        projectId: Yup.string().required(),
        containerId: Yup.string().required(),
        measurementId: Yup.string().required(),
        lid: Yup.string().required(),
        internalHeight: Yup.number().required().positive(messageNoNegativeValues),
        offset: Yup.number()
          .positive(messageNoNegativeValues)
          .min(
            0.0405,
            <FormattedMessage
              id="validation.min_offset"
              defaultMessage="Sensor offset must be greater than or equal to 0.0405"
            />,
          )
          .required(),
        deviceAngle: Yup.number().nullable().when('lid', {
          is: 'side',
          then: Yup.number().required(),
        }),
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        onSubmit(values, setSubmitting, setFieldError)
          .then(() => {
            handleNext();
          })
          .catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors.length) {
              const fieldsErrors = getFieldsErrors(e);
              for (let i = 0; i < steps.length; i += 1) {
                const stepFields = getStepFields(i);
                if (fieldsErrors.some(({ field }) => stepFields.allFields.includes(field))) {
                  setActiveStep(i);
                  setFailedStep(i);
                  break;
                }
              }
            }
          });
      }}
    >
      {({ submitForm, values, isSubmitting, setFieldValue, errors, resetForm }) => (
        <Form className="w-100">
          <FixedLoadingLayout open={isSubmitting} />
          <Grid container alignItems="center" direction="column">
            <Grid item container xs={12} spacing={5} justify="center">
              <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
                  {steps.map(({ key, value }, index) => {
                    const labelProps = {};
                    if (failedStep === index) {
                      labelProps.error = true;
                    }

                    return (
                      <Step key={key}>
                        <StepLabel {...labelProps}>{value}</StepLabel>
                        <StepContent>
                          {getStepContent({
                            step: index,
                            allMeasurementSettings,
                            setFieldValue,
                            classes,
                            values,
                            setOpenRegisterMS,
                            intl,
                          })}
                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                variant="outlined"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                <FormattedMessage id="back" defaultMessage="Back" />
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={isSubmitting || !checkStepFields(errors, values, index)}
                                onClick={activeStep === steps.length - 1 ? submitForm : handleNext}
                                className={classes.button}
                              >
                                {activeStep === steps.length - 1 ? (
                                  <FormattedMessage id="save" defaultMessage="Save" />
                                ) : (
                                  <FormattedMessage id="next" defaultMessage="Next" />
                                )}
                              </Button>
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>
                      <FormattedMessage
                        id="sensor_successfully_attached"
                        defaultMessage="Sensor successfully attached"
                      />
                    </Typography>
                    <Button
                      color="primary"
                      onClick={handleReset(resetForm)}
                      className={classes.button}
                    >
                      <FormattedMessage
                        id="attach_next-device"
                        defaultMessage="Attach next device"
                      />
                    </Button>
                  </Paper>
                )}
              </div>
            </Grid>
          </Grid>
          {openRegisterMS && (
            <RegisterMeasurementDialog
              open={openRegisterMS}
              projectId={values.projectId}
              onClose={(measurement = {}) => {
                setFieldValue('measurementId', measurement.id || '', true);
                setFieldValue('customMeasurementId', measurement.id || '', true);
                setOpenRegisterMS(false);
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

InlineAttachDeviseForm.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  allMeasurementSettings: PropTypes.arrayOf(ContainerType).isRequired,
  checkDeviceHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    containerId: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired,
    measurementId: PropTypes.string.isRequired,
    lid: PropTypes.string.isRequired,
    internalHeight: PropTypes.number,
    deviceAngle: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
};

export default withRouter(withStyles(styles)(React.memo(InlineAttachDeviseForm)));
