import React from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const QrCodeScanner = React.forwardRef(({ onScan, ...restProps }, ref) => {
  const classes = useStyles();

  return (
    <QrReader
      ref={ref}
      className={classes.root}
      delay={1000}
      onScan={onScan}
      showViewFinder={false}
      {...restProps}
    />
  );
});

QrCodeScanner.propTypes = {
  onScan: PropTypes.func.isRequired,
};

export default QrCodeScanner;
