import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import ReactRouterPropTypes from 'react-router-prop-types';
import { loader } from 'graphql.macro';
import { toastifyError } from '../../../../../shared/utils';
import HeaderRow from '../../components/headerRow';
import DriverEditFrom from './form';
import driverEditValidationSchema from './schema';
import {
  serializeDate,
  serializeTime,
  setFieldErrors,
  TYPE_OF_COST_VALUES,
} from '../../components/utils';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import { actionHandler } from '../../../../../shared/buttons/submitButtons';
import { JOB_TITLE_VALUES } from '../../../../../shared/utils/constants';
import useCurrentUser from '../../../../../shared/hooks/useCurrentUser';

const driverQuery = loader('./../../../../../graphql/queries/fleet_management/driver.graphql');
const updateOrCreateDriverMutation = loader(
  './../../../../../graphql/mutations/fleet_management/update_or_create_driver.graphql',
);
const allUsersQuery = loader('./../../../../../graphql/queries/core/all_users.graphql');

const mutationSerializer = (
  {
    userId,
    email,
    employeeNumber,
    phone,
    vehicleId,
    workTimeStart,
    workTimeEnd,
    allowedOvertime,
    allowedMaximumWeeklyHours,
    allowedMaximumDailyHours,
    scheduleBreak,
    breakTimeStart,
    breakTimeEnd,
    breakDuration,
    daysAvailable,
    typeOfCost,
    costPerHour,
    costPerInterval,
    paymentInterval,
    costPerHourForOvertime,
    licenseClass,
    number,
    region,
    validFrom,
    validTo,
    skills,

    startLocationId,
    endLocationId,
  },
  photoFile,
  selfId,
) => ({
  selfId,

  // Identification
  userId,
  email,
  employeeNumber,
  phone: phone.replace(/\s|\(|\)/g, ''),
  vehicleId,
  logo: photoFile,

  // Work schedule
  workTimeStart: workTimeStart ? workTimeStart.format('HH:mm:ss') : null,
  workTimeEnd: workTimeEnd ? workTimeEnd.format('HH:mm:ss') : null,
  allowedOvertime: allowedOvertime || null,
  allowedMaximumWeeklyHours: allowedMaximumWeeklyHours || null,
  allowedMaximumDailyHours: allowedMaximumDailyHours || null,
  breakTimeStart: scheduleBreak && breakTimeStart ? breakTimeStart.format('HH:mm:ss') : null,
  breakTimeEnd: scheduleBreak && breakTimeEnd ? breakTimeEnd.format('HH:mm:ss') : null,
  breakDuration: scheduleBreak ? null : breakDuration || null,
  daysAvailable,

  // Driver cost
  isFixedCost: typeOfCost === TYPE_OF_COST_VALUES.fixed,
  costPerHour: costPerHour || null,
  costPerInterval: costPerInterval || null,
  paymentInterval: paymentInterval || null,
  costPerHourForOvertime: costPerHourForOvertime || null,

  // Driver permission
  licenseClass,
  number,
  region,
  validFrom: validFrom ? validFrom.format('YYYY-MM-DD') : null,
  validTo: validTo ? validTo.format('YYYY-MM-DD') : null,

  // Driver skills
  skills: skills
    .filter(({ skillId }) => skillId)
    .map(({ validFrom: vf, validTo: vt, ...other }) => ({
      ...other,
      validFrom: vf ? vf.format('YYYY-MM-DD') : null,
      validTo: vt ? vt.format('YYYY-MM-DD') : null,
    })),

  // Driver logistic
  startLocationId,
  endLocationId,
});

const initialDriverValues = {
  // Identification
  userId: '',
  email: '',
  employeeNumber: '',
  phone: '',
  vehicleId: '',
  logo: '',

  // Work schedule
  workTimeStart: null,
  workTimeEnd: null,
  allowedOvertime: '',
  allowedMaximumWeeklyHours: '',
  allowedMaximumDailyHours: '',
  scheduleBreak: false,
  breakTimeStart: null,
  breakTimeEnd: null,
  breakDuration: '',
  daysAvailable: [],

  // Driver cost
  typeOfCost: TYPE_OF_COST_VALUES.fixed,
  costPerHour: '',
  costPerInterval: '',
  paymentInterval: '',
  costPerHourForOvertime: '',

  // Driver permission
  licenseClass: '',
  number: '',
  region: '',
  validFrom: null,
  validTo: null,

  // Driver skills
  skills: [
    {
      skillId: '',
      validFrom: null,
      validTo: null,
    },
  ],

  // Driver logistic
  startLocationId: '',
  endLocationId: '',
};

const serializer = (driver) => ({
  ...initialDriverValues,

  // Identification
  userId: driver?.user?.id || initialDriverValues.userId,
  email: driver?.user?.email || initialDriverValues.email,
  employeeNumber: driver.employeeNumber || initialDriverValues.employeeNumber,
  phone: driver?.user?.phoneNumber || initialDriverValues.phone,
  vehicleId: driver?.vehicle?.id || initialDriverValues.vehicleId,
  logo: driver?.user?.logo || initialDriverValues.logo,

  // Work schedule
  workTimeStart: serializeTime(driver.workTimeStart, initialDriverValues.workTimeStart),
  workTimeEnd: serializeTime(driver.workTimeEnd, initialDriverValues.workTimeEnd),
  allowedOvertime: driver.allowedOvertime || initialDriverValues.allowedOvertime,
  allowedMaximumWeeklyHours:
    driver.allowedMaximumWeeklyHours || initialDriverValues.allowedMaximumWeeklyHours,
  allowedMaximumDailyHours:
    driver.allowedMaximumDailyHours || initialDriverValues.allowedMaximumDailyHours,
  scheduleBreak: !!(driver.breakTimeStart && driver.breakTimeEnd),
  breakTimeStart: serializeTime(driver.breakTimeStart, initialDriverValues.breakTimeStart),
  breakTimeEnd: serializeTime(driver.breakTimeEnd, initialDriverValues.breakTimeEnd),
  breakDuration: driver.breakDuration || initialDriverValues.breakDuration,
  daysAvailable: driver.daysAvailable || initialDriverValues.daysAvailable,

  // Driver cost
  typeOfCost:
    (!driver.cost?.isFixedCost && TYPE_OF_COST_VALUES.variable) || initialDriverValues.typeOfCost,
  costPerHour: driver.cost?.costPerHour || initialDriverValues.costPerHour,
  costPerInterval: driver.cost?.costPerInterval || initialDriverValues.costPerInterval,
  paymentInterval: driver.cost?.paymentInterval || initialDriverValues.paymentInterval,
  costPerHourForOvertime:
    driver.cost?.costPerHourForOvertime || initialDriverValues.costPerHourForOvertime,

  // Driver permission
  licenseClass: driver.license?.licenseClass || initialDriverValues.paymentInterval,
  number: driver.license?.number || initialDriverValues.number,
  region: driver.license?.region || initialDriverValues.region,
  validFrom: serializeDate(driver.license?.validInRange?.lower, initialDriverValues.validFrom),
  validTo: serializeDate(driver.license?.validInRange?.upper, initialDriverValues.validTo),

  // Driver skills
  skills:
    driver.driverSkills?.edges && driver.driverSkills?.edges.length
      ? driver.driverSkills.edges.map(({ node }) => ({
          skillId: node?.skill?.id,
          validFrom: serializeDate(node.validFrom, null),
          validTo: serializeDate(node.validTo, null),
        }))
      : initialDriverValues.skills,

  // Driver logistic
  startLocationId: driver?.startLocation?.id || initialDriverValues.startLocationId,
  endLocationId: driver?.endLocation?.id || initialDriverValues.endLocationId,
});

const DriverSettings = ({ match, history }) => {
  const { params: { id: driverId } = {} } = match;

  const intl = useIntl();

  const editPage = !!driverId;
  const [initialData, setInitialData] = useState(initialDriverValues);
  const [photoFile, setPhotoFile] = useState();

  const [updateOrCreateDriver, { loading: waitForMutation }] = useMutation(
    updateOrCreateDriverMutation,
    {
      refetchQueries: [
        {
          query: allUsersQuery,
          variables: {
            isDriver: false,
            jobTitle: 'Driver',
          },
        },
      ],
    },
  );

  const [getDriver, { loading: driverLoading, data: { driver = {} } = {} }] = useLazyQuery(
    driverQuery,
    {
      onError: toastifyError,
      variables: { id: driverId },
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (driverId && Reflect.ownKeys(driver).length) {
      setInitialData(serializer(driver));
    }
  }, [driver, driverId]);

  useEffect(() => {
    if (driverId) {
      getDriver({ variables: { id: driverId } });
    }
  }, [driverId, getDriver]);

  const editPageName = intl.formatMessage(
    {
      id: 'driver.edit_page_title',
      defaultMessage: 'Driver settings / {name}',
    },
    { name: `${driver?.user?.firstName} ${driver?.user?.lastName}` },
  );
  const addPageName = intl.formatMessage({
    id: 'driver.add_page_title',
    defaultMessage: 'Driver settings / Add driver',
  });

  const selectedUser = useMemo(
    () =>
      driver?.user
        ? {
            value: driver.user.id,
            label:
              driver.user.firstName && driver.user.lastName
                ? `${driver.user.firstName} ${driver.user.lastName}`
                : driver.user.username,
          }
        : null,
    [driver],
  );
  const userInfo = useCurrentUser();

  useEffect(() => {
    if (userInfo.jobtitle.title === JOB_TITLE_VALUES.driver) {
      setInitialData((currentInitialData) => ({
        ...currentInitialData,
        userId: userInfo.id,
        email: userInfo.email,
        phone: userInfo.phone,
        logo: userInfo.logo,
      }));
    }
  }, [userInfo.email, userInfo.id, userInfo.jobtitle.title, userInfo.logo, userInfo.phone]);

  const editDriverHandler = useCallback(
    (values, { setSubmitting, setFieldError, resetForm }) => {
      const variables = mutationSerializer(values, photoFile, driverId);
      return updateOrCreateDriver({ variables })
        .then(({ data: { updateOrCreateDriver: { driver: nextDriver } = {} } = {} }) => {
          toast.success(`Saved driver - ${nextDriver?.user?.firstName}`);
          return {
            action: values.action,
            obj: nextDriver,
          };
        })
        .then(
          actionHandler({
            editPage,
            reInitForm: (obj) => setInitialData(serializer(obj)),
            resetForm: () => {
              resetForm();
              setInitialData(initialDriverValues);
            },
            baseUrl: '/app/fleet-management/driver-management',
            history,
            onEditPage: editPage,
            toDetail: true,
          }),
        )
        .catch((e) => {
          toastifyError(e);
          setFieldErrors(e?.graphQLErrors, setFieldError);
        })
        .finally(() => setSubmitting(false));
    },
    [photoFile, driverId, updateOrCreateDriver, editPage, history],
  );

  return (
    <Grid container>
      <HeaderRow pageTitle={editPage ? editPageName : addPageName} />
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={driverEditValidationSchema()}
        onSubmit={editDriverHandler}
      >
        <Grid container className="p-15">
          <FixedLoadingLayout open={driverLoading || waitForMutation} />
          <DriverEditFrom
            setPhotoFile={setPhotoFile}
            selectedUser={selectedUser}
            endLocationVisibility={editPage}
          />
        </Grid>
      </Formik>
    </Grid>
  );
};

DriverSettings.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(DriverSettings);
