import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import DashboardIcon from '../../../images/icons/dashboard.svg';
import ContainerIcon from '../../../images/icons/containerMenuIcon.svg';
import AddContainerIcon from '../../../images/icons/addContainerIcon.svg';
import AnalyticsIcon from '../../../images/icons/analyticsMenuIcon.svg';
import ManagementIcon from '../../../images/icons/management.svg';
import AddUserIcon from '../../../images/icons/assingUserIcon.svg';
import NotificationIcon from '../../../images/icons/notificationIcon.svg';
import RouteIcon from '../../../images/icons/routeMenuIcon.svg';
import RouteOverviewIcon from '../../../images/icons/routeOverview.svg';
import AddDevice from '../../../images/icons/addDevice.svg';
import VehicleIcon from '../../../images/icons/vehicle.svg';
import FleetManagementIcon from '../../../images/icons/fleetManagement.svg';
import DriverManagementIcon from '../../../images/icons/driverManagement.svg';
import LocationsIcon from '../../../images/icons/locations.svg';
import AttachDevice from '../../../images/icons/ic-attach-device.svg';
import { DEMO, MASTER, SUPER, USER } from '../../shared/utils/settings';

const navData = [
  {
    name: 'dashboard',
    type: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
    routeLink: '/dashboard',
    icon: DashboardIcon,
    except_level: [],
    tourid: 'dashboardNav',
  },
  {
    name: 'containers',
    type: <FormattedMessage id="Containers" defaultMessage="Containers" />,
    routeLink: '/containers',
    icon: ContainerIcon,
    tourid: 'containerNav',
    except_level: [],
    children: [
      {
        name: 'add-container',
        type: <FormattedMessage id="add_container" defaultMessage="Add container" />,
        routeLink: '/containers/add',
        icon: AddContainerIcon,
        except_level: [],
      },
      {
        name: 'attach-sensor',
        type: <FormattedMessage id="attach_sensor" defaultMessage="Attach sensor" />,
        routeLink: '/containers/attach',
        icon: AttachDevice,
        except_level: [],
      },
      {
        name: 'add-sensor',
        type: <FormattedMessage id="add_new_sensor" defaultMessage="Add sensor" />,
        routeLink: '/containers/add-sensor',
        icon: AddDevice,
        except_level: [],
      },
      {
        name: 'pickup-settings',
        type: <FormattedMessage id="pickup-settings" defaultMessage="Pickup Settings" />,
        routeLink: '/containers/pickup-settings',
        icon: VehicleIcon,
        except_level: [],
      },
    ],
  },
  {
    name: 'fleet-management',
    type: <FormattedMessage id="fleet_management" defaultMessage="Fleet management" />,
    routeLink: '/fleet-management/collector-vehicle',
    icon: FleetManagementIcon,
    except_level: [],
    children: [
      {
        name: 'vehicles',
        type: <FormattedMessage id="vehicles" defaultMessage="Vehicles" />,
        routeLink: '/fleet-management/collector-vehicle',
        icon: VehicleIcon,
        except_level: [],
      },
      {
        name: 'driver-management',
        type: <FormattedMessage id="driver_management" defaultMessage="Driver management" />,
        routeLink: '/fleet-management/driver-management',
        icon: DriverManagementIcon,
        except_level: [],
        tourid: 'driverNav',
      },
      {
        name: 'locations',
        type: <FormattedMessage id="locations" defaultMessage="Locations" />,
        routeLink: '/fleet-management/locations',
        icon: LocationsIcon,
        except_level: [],
        tourid: 'locationNav',
      },
    ],
    tourid: 'fleetManagementNav',
  },
  {
    name: 'notifications',
    type: <FormattedMessage id="notifications" defaultMessage="Notifications" />,
    routeLink: '/notifications',
    icon: NotificationIcon,
    except_level: [],
    tourid: 'notificationsNav',
  },
  {
    name: 'user-management',
    type: <FormattedMessage id="user_management" defaultMessage="User management" />,
    routeLink: '/user-management',
    icon: ManagementIcon,
    except_level: [],
    children: [
      {
        name: 'assign-user',
        type: <FormattedMessage id="create_account" defaultMessage="Create account" />,
        routeLink: '/user-management/assign-user',
        icon: AddUserIcon,
        except_level: [MASTER, SUPER, USER, DEMO],
      },
    ],
  },
  {
    name: 'operation_management',
    type: <FormattedMessage id="operation-management" defaultMessage="Operation management" />,
    routeLink: '/operation-management/routes',
    icon: RouteIcon,
    except_level: [],
    children: [
      {
        name: 'routes-overview',
        type: <FormattedMessage id="route_overview_routes" defaultMessage="Routes" />,
        routeLink: '/operation-management/routes',
        icon: RouteOverviewIcon,
        except_level: [],
      },
      {
        name: 'report-inquiry-new',
        type: <FormattedMessage id="route_inquiries" defaultMessage="Inquiries" />,
        routeLink: '/operation-management/inquiries',
        icon: RouteOverviewIcon,
        except_level: [],
      },
    ],
    tourid: 'operationManagement',
  },
  {
    name: 'analytics',
    type: (
      <FormattedMessage id="analytics" defaultMessage="Overview">
        {(txt) => (
          <span>
            {txt}{' '}
            <Button
              variant="contained"
              color="secondary"
              style={{
                borderRadius: '22px',
                height: '26px',
                width: '64px',
                textTransform: 'none',
                fontSize: '0.88rem',
                marginLeft: '10px',
                fontWeight: 'bold',
              }}
            >
              Beta
            </Button>
          </span>
        )}
      </FormattedMessage>
    ),
    routeLink: '/analytics',
    icon: AnalyticsIcon,
    except_level: [],
    children: [
      {
        name: 'analytics-heat-maps',
        type: <FormattedMessage id="analytics-heat-maps" defaultMessage="Heat maps" />,
        routeLink: '/analytics/heat-maps',
        icon: RouteIcon,
        except_level: [],
      },
    ],
    tourid: 'analytics',
  },
];

export default navData;
