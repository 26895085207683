import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Route, Switch } from 'react-router-dom';
import RoutesMap from './routes/routes-map';
import Inquiries from './routes/inquiries';
import ReportInquiry from './routes/inquiries/report-inquiry';
import PickupOrders from './routes/pickup-orders';
import Plan from './routes/plan';
import RouteSettings from './routes/route-settings';
import { TYPES_CHOICES_VALUES } from '../fleet-management/components/utils';

const Routes = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/vehicle/collector`}
      render={(props) => <RoutesMap {...props} type={TYPES_CHOICES_VALUES.collector} />}
    />
    <Route
      exact
      path={`${match.url}/vehicle/street-sweeper`}
      render={(props) => <RoutesMap {...props} type={TYPES_CHOICES_VALUES.streetSweeper} />}
    />
    <Route exact path={`${match.url}/inquiries`} component={Inquiries} />
    <Route exact path={`${match.url}/collection-plan/collected`} component={PickupOrders} />
    <Route exact path={`${match.url}/collection-plan/planned`} component={PickupOrders} />
    <Route exact path={`${match.url}/live-tracking`} component={Plan} />
    <Route exact path={`${match.url}/plan`} component={Plan} />
    <Route exact path={`${match.url}/plan/:id`} component={Plan} />
    <Route exact path={`${match.url}/set-route-settings`} component={RouteSettings} />
    <Route exact path={`${match.url}/set-route-settings/:id/edit`} component={RouteSettings} />
    <Route exact path={`${match.url}/report-inquiry`} component={ReportInquiry} />
    <Route exact path={`${match.url}/:tabId`} component={RoutesMap} />
  </Switch>
);

Routes.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Routes;
