import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect, getIn } from 'formik';
import formikInjectedPropsTypes from './formikPropTypes';
import { formikFieldMemoizeCheck, memoizeFields } from './memoraize';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 'bold',
    lineHeight: '20px',
    transform: 'translate(0, -2px)',
  },
  root: {
    marginTop: ({ label }) => (label ? 14 : 0),
    boxShadow: 'none',
    height: 47,
    color: theme.variables.cGrayInpBorder,
  },
  button: {
    color: theme.variables.cEmperor,
    backgroundColor: theme.variables.cAntiFlashWhite,
    textTransform: 'none',
    padding: theme.spacing(1.75, 3),
    fontSize: 16,
    fontWeight: 500,
  },
  first: {
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20,
  },
  last: {
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
  },
  selected: {
    color: theme.variables.cWhite,
    backgroundColor: theme.variables.VHOrange,
    '&:hover': {
      backgroundColor: theme.variables.VHOrange,
    },
  },
}));

const FormikButtonSwitch = ({ label, name, formik, valuesList, buttonClass, buttonGroupClass }) => {
  const classes = useStyles({ label });

  const value = getIn(formik.values, name);
  const { setFieldValue } = formik;

  const handleClick = useCallback(
    (newValue) => () => {
      setFieldValue(name, newValue);
    },
    [name, setFieldValue],
  );

  return (
    <>
      {label && (
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      )}
      <ButtonGroup
        className={classNames([classes.root, buttonGroupClass])}
        variant="contained"
        color="inherit"
      >
        {valuesList.map(({ label: optionLabel, value: optionValue }, index) => (
          <Button
            key={index}
            className={classNames(
              classes.button,
              {
                [classes.first]: index === 0,
                [classes.last]: index === valuesList.length - 1,
                [classes.selected]: optionValue === value,
              },
              buttonClass,
            )}
            onClick={handleClick(optionValue)}
          >
            {optionLabel}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

FormikButtonSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  valuesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  buttonClass: PropTypes.string,
  buttonGroupClass: PropTypes.string,
};

FormikButtonSwitch.defaultProps = {
  buttonClass: '',
  buttonGroupClass: '',
};

export default connect(
  React.memo(FormikButtonSwitch, memoizeFields(['name', 'label', ...formikFieldMemoizeCheck()])),
);
