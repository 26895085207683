import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Typography, Link } from '@material-ui/core';
import useStyles from '../../shared/styles';
import StepsContainer from '../../shared/stepsContainer';
import SupportTextPage from '../../shared/supportTextPage';
import StepOneImage from '../../../../../../images/support/app_support/unpair_sensor/step_1.png';
import StepTwoImage from '../../../../../../images/support/app_support/unpair_sensor/step_2.png';
import StepThreeImage from '../../../../../../images/support/app_support/unpair_sensor/step_3.png';
import StepFourImage from '../../../../../../images/support/app_support/unpair_sensor/step_4.png';
import StepFiveImage from '../../../../../../images/support/app_support/unpair_sensor/step_5.png';
import StepSixImage from '../../../../../../images/support/app_support/unpair_sensor/step_6.png';
import ImageContainer from './components/imageContainer';
import QrCode from '../../../../../../images/support/app_support/logos/QR_Code_App_Install.png';

const AppUnpairSensor = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container spacing={1} className={classes.root}>
      {/* pageTitle and top content */}

      <SupportTextPage
        title={<FormattedMessage id="app_unpair_device_title" defaultMessage="Unpair a sensor" />}
        texts={[
          <FormattedMessage
            id="app_unpair_device_content_1"
            defaultMessage="Unpair a sensor from a container using the WasteHero Installation App."
          />,
        ]}
      />

      {/* Step 1 */}
      <StepsContainer
        number={1}
        subheader={
          <FormattedMessage
            id=" app_unpair_device_step_1_subTitle"
            defaultMessage="Open the WasteHero Installation App."
          />
        }
        image={StepOneImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_1_content_1"
            defaultMessage="Haven’t downloaded the app? Download the smartphone app from the <appstoreLink>Apple Store</appstoreLink> or <playstoreLink>Google Play</playstoreLink>."
            values={{
              appstoreLink: (text) => (
                <Link
                  className={classes.links}
                  href="https://apps.apple.com/au/app/wastehero-installed/id1498769501"
                  target="_blank"
                >
                  {text}
                </Link>
              ),
              playstoreLink: (text) => (
                <Link
                  className={classes.links}
                  href="https://play.google.com/store/apps/details?id=com.wastehero_mobileapp_installation"
                  target="_blank"
                >
                  {text}
                </Link>
              ),
            }}
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_content_step_1_content_2"
            defaultMessage="Alternatively, scan this QR code from your smartphone:"
          />
        </Typography>
        <ImageContainer>
          <img
            src={QrCode}
            alt={intl.formatMessage({ id: 'qr.code', defaultMessage: 'QR Code' })}
          />
        </ImageContainer>
      </StepsContainer>

      {/* Step 2 */}
      <StepsContainer
        number={2}
        subheader={
          <FormattedMessage
            id="app_unpair_device_step_2_subTitle"
            defaultMessage="Login with your credentials"
          />
        }
        image={StepTwoImage}
      >
        <Typography className={classes.textBold} variant="body1" gutterBottom>
          <FormattedMessage id="app_unpair_device_step_2_content_1" defaultMessage="Username" />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_2_content_2"
            defaultMessage="The email address you used to create your account on the platform, e.g. yourname@company.com"
          />
        </Typography>
        <Typography className={classes.textBold} variant="body1" gutterBottom>
          <FormattedMessage id="app_unpair_device_step_2_content_3" defaultMessage="Password" />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_2_content_4"
            defaultMessage="Type your password"
          />
        </Typography>
      </StepsContainer>

      {/* Step 3 */}
      <StepsContainer
        number={3}
        subheader={
          <FormattedMessage
            id="app_unpair_device_step_3_subTitle"
            defaultMessage="Select Project"
          />
        }
        image={StepThreeImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_3_content_1"
            defaultMessage="Select the <bold>Project</bold> that the sensor is associated with and hit <bold>Apply</bold>."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
      </StepsContainer>
      {/* Step 4 */}
      <StepsContainer
        number={4}
        subheader={
          <FormattedMessage id="app_unpair_device_step_4_subTitle" defaultMessage="Unpair sensor" />
        }
        image={StepFourImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_4_content_1"
            defaultMessage="Select <bold>Unpair sensor</bold>."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
      </StepsContainer>

      {/* Step 5 */}
      <StepsContainer
        number={5}
        subheader={
          <FormattedMessage
            id="app_unpair_device_step_5_subTitle"
            defaultMessage="Scan the sensor"
          />
        }
        image={StepFiveImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_5_content_1"
            defaultMessage="Scan the sensor using the NFC tag, the QR code, or manually type in the Sensor ID."
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_5_content_2"
            defaultMessage="<bold>For scanning via NFC</bold>, hold your NFC enabled smartphone on the QR code (the NFC tag is located behind the label). Press the orange icon on the app to scan the NFC tag."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_5_content_3"
            defaultMessage="<bold>For scanning via QR code</bold>, switch to the QR tab in the app and position the camera over the QR code."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_5_content_4"
            defaultMessage="<bold>For scanning via Sensor ID</bold>, simply type the Sensor ID located on the label of the sensor into the Sensor ID field on the app. Select Enter to confirm."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
      </StepsContainer>

      {/* Step 6 */}
      <StepsContainer
        number={6}
        subheader={
          <FormattedMessage id="app_unpair_device_step_6_subTitle" defaultMessage="Unpair sensor" />
        }
        image={StepSixImage}
        checkLast
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_unpair_device_step_6_content_1"
            defaultMessage="Click <bold>Unpair Sensor</bold>."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
      </StepsContainer>
    </Grid>
  );
};

export default AppUnpairSensor;
