import useCurrentUser from '../../../../shared/hooks/useCurrentUser';

export default function useIsRegularUser(isDriver) {
  const me = useCurrentUser();
  return !(
    me.isAdmin ||
    isDriver ||
    me.isMaster ||
    me.isSuperuser ||
    me.isReseller ||
    me.ownProjectsIds.length
  );
}
