import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

const ConditionalScrollbar = React.forwardRef(({ children, displayScrollbar }, ref) =>
  displayScrollbar ? (
    <Scrollbars hideTracksWhenNotNeeded className="scrollbar" ref={ref}>
      {children}
    </Scrollbars>
  ) : (
    children
  ),
);

ConditionalScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  displayScrollbar: PropTypes.bool.isRequired,
};

export default ConditionalScrollbar;
