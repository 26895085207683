import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { connect } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Grid, Tabs, Tab } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import CloseIcon from '../../../../images/icons/whiteCrossIcon.svg';
import { getSavedActiveProjects } from '../../../shared/utils';
import CostSettings from './components/containerSettings';
import ComparisonSettings from './components/comparisonSettings';
import LoadingLayout from '../../../shared/loading';
import { getCostSettingsDescription } from './components/utils';

const getAllCostSettingsQuery = loader('../../../graphql/queries/analytics/cost_settings.graphql');

const SettingsPopup = ({
  savedActiveProjects,
  open,
  onClose,
  classes,
  fractions,
  isLoading,
  containerTypes,
  currencyCoef,
  currencySymbol,
  weightToVolumeCoef,
  weightSymbol,
  volumeSymbol,
  handleCalculateAnalytics,
  businessAnalyticsRefetch,
  selectedCombinations,
  setSelectedCombinations,
  customPeriodComparisonFrom,
  customPeriodComparisonTo,
  customPeriodTo,
  customPeriodFrom,
  period,
}) => {
  const intl = useIntl();

  const [settingsTab, setSettingsTab] = useState('costSettings');
  const [comparisonPeriodTab, setComparisonPeriodTab] = useState('');

  const { data: allCostSettings } = useQuery(getAllCostSettingsQuery, {
    variables: {
      activeProjects: savedActiveProjects,
    },
    fetchPolicy: 'cache-first',
  });

  const descriptionText = useMemo(
    () =>
      getCostSettingsDescription(
        classes.comparisonDescriptionText,
        settingsTab,
        comparisonPeriodTab,
      ),
    [settingsTab, classes.comparisonDescriptionText, comparisonPeriodTab],
  );

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justify="center" className={classes.settingsHeader}>
          <Grid item>
            <FormattedMessage id="settings" defaultMessage="Settings">
              {(txt) => <p className={classes.costSettingsTitle}>{txt}</p>}
            </FormattedMessage>
          </Grid>
          <IconButton onClick={onClose} className={classes.closeDialogButton}>
            <img src={CloseIcon} className={classes.closeDialogIcon} alt="close" />
          </IconButton>
        </Grid>

        <Grid className={classes.dialogContainer}>{descriptionText}</Grid>
      </DialogTitle>
      <Grid container className={classes.dialogContainer}>
        <Tabs
          onChange={(e, value) => setSettingsTab(value)}
          value={settingsTab}
          className={classes.settingsTabs}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="tabs.data_type_tabs"
        >
          <Tab
            label={intl.formatMessage({
              id: 'tabs.containerSettings',
              defaultMessage: 'Combination settings',
            })}
            value="costSettings"
            className={classes.settingsTab}
          />
          <Tab
            label={intl.formatMessage({
              id: 'tabs.comparison_options',
              defaultMessage: 'Comparison options',
            })}
            value="comparisonPeriod"
            className={classes.settingsTab}
          />
        </Tabs>
        <Grid container>
          <LoadingLayout isLoading={isLoading} />
          {settingsTab === 'costSettings' ? (
            <CostSettings
              classes={classes}
              fractions={fractions}
              containerTypes={containerTypes}
              savedActiveProjects={savedActiveProjects}
              allCostSettings={allCostSettings}
              currencyCoef={currencyCoef}
              currencySymbol={currencySymbol}
              weightToVolumeCoef={weightToVolumeCoef}
              weightSymbol={weightSymbol}
              volumeSymbol={volumeSymbol}
              businessAnalyticsRefetch={businessAnalyticsRefetch}
              selectedCombinations={selectedCombinations}
              setSelectedCombinations={setSelectedCombinations}
            />
          ) : (
            <ComparisonSettings
              classes={classes}
              savedActiveProjects={savedActiveProjects}
              allCostSettings={allCostSettings}
              onClose={onClose}
              currencyCoef={currencyCoef}
              currencySymbol={currencySymbol}
              weightToVolumeCoef={weightToVolumeCoef}
              weightSymbol={weightSymbol}
              volumeSymbol={volumeSymbol}
              handleCalculateAnalytics={handleCalculateAnalytics}
              comparisonPeriodTab={comparisonPeriodTab}
              setComparisonPeriodTab={setComparisonPeriodTab}
              selectedCombinations={selectedCombinations}
              customPeriodComparisonFrom={customPeriodComparisonFrom}
              customPeriodComparisonTo={customPeriodComparisonTo}
              customPeriodTo={customPeriodTo}
              customPeriodFrom={customPeriodFrom}
              period={period}
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

SettingsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customPeriodComparisonFrom: PropTypes.string,
  customPeriodComparisonTo: PropTypes.string,
  customPeriodTo: PropTypes.string,
  customPeriodFrom: PropTypes.string,
  currencyCoef: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  weightToVolumeCoef: PropTypes.number.isRequired,
  weightSymbol: PropTypes.string.isRequired,
  volumeSymbol: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fractions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  containerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  savedActiveProjects: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  handleCalculateAnalytics: PropTypes.func.isRequired,
  businessAnalyticsRefetch: PropTypes.func.isRequired,
  selectedCombinations: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedCombinations: PropTypes.func.isRequired,
  period: PropTypes.string,
};

SettingsPopup.defaultProps = {
  fractions: [],
  containerTypes: [],
  isLoading: false,
  customPeriodComparisonFrom: null,
  customPeriodComparisonTo: null,
  customPeriodTo: null,
  customPeriodFrom: null,
  period: null,
};

const mapStateToProps = (state) => ({
  savedActiveProjects: getSavedActiveProjects(state),
});

export default connect(mapStateToProps)(SettingsPopup);
