import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedTime } from 'react-intl';

const OpeningHours = ({ openingTime, closingTime }) => {
  const ot = openingTime && moment(openingTime, 'hh:mm:ss');
  const ct = closingTime && moment(closingTime, 'hh:mm:ss');

  return ot && ct ? (
    <>
      <FormattedTime value={ot} />
      &nbsp; - &nbsp;
      <FormattedTime value={ct} />
    </>
  ) : (
    '-'
  );
};

OpeningHours.propTypes = {
  openingTime: PropTypes.string,
  closingTime: PropTypes.string,
};

OpeningHours.defaultProps = {
  openingTime: null,
  closingTime: null,
};

export default OpeningHours;
