import { useEffect, useState } from 'react';

export default function useGetActiveTab(match, defaultTab) {
  const [activeTab, setActiveTab] = useState(defaultTab);
  useEffect(() => {
    const { path = '' } = match;

    const pathItems = path.split('/');
    if (pathItems[pathItems.length - 1] === ':id') {
      setActiveTab(pathItems[pathItems.length - 2]);
    } else {
      setActiveTab(pathItems[pathItems.length - 1]);
    }
  }, [match]);
  return activeTab;
}
