import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useIntl } from 'react-intl';

import useGetActiveTab from '../../../../../../shared/hooks/useGetActiveTab';

const useStyles = makeStyles((theme) => ({
  tabs: {
    '& > div': {
      '& > .MuiTabs-indicator': {
        top: 'inherit',
        bottom: 0,
        backgroundColor: theme.variables.cOrange,
        height: '2',
      },
    },
  },
  tab: {
    textTransform: 'none',
    minHeight: 68,
  },
}));

const PickupOrdersHeadTabs = ({ match }) => {
  const classes = useStyles();
  const intl = useIntl();

  const tab = useGetActiveTab(match, 'collected');

  const collectedRouteString = intl.formatMessage({
    id: 'collected',
    defaultMessage: 'Collected',
  });
  const plannedRouteString = intl.formatMessage({
    id: 'planned',
    defaultMessage: 'Planned',
  });
  return (
    <div>
      <Tabs
        value={tab}
        aria-label="pickup orders tab"
        textColor="secondary"
        indicatorColor="secondary"
        className={classes.tabs}
      >
        <Tab
          label={collectedRouteString}
          className={classes.tab}
          value="collected"
          component={Link}
          to="/app/routes/collection-plan/collected"
        />
        <Tab
          label={plannedRouteString}
          className={classes.tab}
          value="planned"
          component={Link}
          to="/app/routes/collection-plan/planned"
          tourid="planned"
        />
      </Tabs>
    </div>
  );
};

PickupOrdersHeadTabs.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(PickupOrdersHeadTabs);
