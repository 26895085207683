import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { connect, getIn } from 'formik';
import { loader } from 'graphql.macro';
import LoadingLayout from '../../../../../shared/loading';
import InlineAttachDeviseForm from './attachFormLayout';
import { attachDeviceHandler, checkDeviceHandler } from '../attachDevice/apiCalls';
import {
  formikInjectedPropsTypes,
  formikComponentMemoizeValueCheck,
  memoizeFields,
} from '../../../../../shared/inputs/formik';

const attachDeviceMutation = loader(
  './../../../../../graphql/mutations/devices/attach_device.graphql',
);
const checkDeviceIdMutation = loader(
  './../../../../../graphql/mutations/devices/check_device_id.graphql',
);
const allMeasurementSettingsQuery = loader(
  './../../../../../graphql/queries/devices/all_measurement_settings.graphql',
);
const oneContainer = loader('./../../../../../graphql/queries/devices/one_container.graphql');

const InlineAttachDevice = ({ open, onSubmit, formik }) => {
  const containerId = getIn(formik.values, 'id');
  const selectedProject = getIn(formik.values, 'selectedProject');
  const containerHeight = getIn(formik.values, 'containerType.height');

  const [attachDevice] = useMutation(attachDeviceMutation, {
    refetchQueries: [{ query: oneContainer, variables: { id: containerId } }],
    awaitRefetchQueries: true,
  });
  const [checkDeviceId] = useMutation(checkDeviceIdMutation);

  const [
    loadMeasurement,
    {
      loading: isMeasurementSettingsLoading,
      data: allMeasurementSettingsData,
      called: isMeasurementSettingsCalled,
    },
  ] = useLazyQuery(allMeasurementSettingsQuery, {
    displayName: 'allMeasurements',
    options: { fetchPolicy: 'cache-first' },
  });

  useEffect(() => {
    if (selectedProject && !isMeasurementSettingsCalled) {
      loadMeasurement({ variables: { projectId: selectedProject } });
    }
  }, [isMeasurementSettingsCalled, loadMeasurement, selectedProject]);

  const allMeasurementSettings = allMeasurementSettingsData
    ? allMeasurementSettingsData.allMeasurementSettings.edges.map(({ node }) => ({
        value: node.id,
        label: node.name,
        project: node.project,
      }))
    : [];

  const loading = isMeasurementSettingsLoading;

  const initialValues = useMemo(
    () => ({
      deviceId: '',
      measurementId: '',
      customMeasurementId: '',
      lid: 'top',
      deviceAngle: 0,
      offset: 0.045,
      containerId,
      internalHeight: containerHeight - 0.045,
      projectId: selectedProject,
    }),
    [containerId, containerHeight, selectedProject],
  );

  const onAttachDeviceHandler = useMemo(() => attachDeviceHandler(attachDevice, onSubmit), [
    attachDevice,
    onSubmit,
  ]);
  const onCheckDeviceHandler = useMemo(() => checkDeviceHandler(selectedProject, checkDeviceId), [
    selectedProject,
    checkDeviceId,
  ]);

  return (
    <Grid container item xs={12} md={9} justify="flex-start">
      <Collapse className="w-100" in={!!(containerId && selectedProject && open)}>
        <Grid item xs={12} container alignItems="center" direction="column">
          <LoadingLayout isLoading={loading} />
          <InlineAttachDeviseForm
            allMeasurementSettings={allMeasurementSettings}
            onSubmit={onAttachDeviceHandler}
            checkDeviceHandler={onCheckDeviceHandler}
            initialValues={initialValues}
          />
        </Grid>
      </Collapse>
    </Grid>
  );
};

InlineAttachDevice.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

export default connect(
  React.memo(
    InlineAttachDevice,
    memoizeFields([
      'open',
      'onSubmit',
      formikComponentMemoizeValueCheck('id'),
      formikComponentMemoizeValueCheck('selectedProject'),
      formikComponentMemoizeValueCheck('containerType.height'),
    ]),
  ),
);
