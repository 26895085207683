import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import useStyles from './formik/styles';

const StyledTextField = ({
  value,
  error,
  name,
  required,
  label,
  placeholder,
  id,
  readOnly,
  className,
  InputProps,
  onChange,
  onClick,
  ...otherProps
}) => {
  const isError = !!error;
  const inputId = id || `texField${name}`;
  const classes = useStyles({ backgroundColor: 'cAntiFlashWhite' });
  return (
    <FormControl
      required={required}
      className={classNames(classes.formControl, className, classes.input)}
    >
      {label && (
        <InputLabel
          required={required}
          error={isError}
          shrink
          id={`label-${inputId}`}
          htmlFor={inputId}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        id={inputId}
        type="text"
        name={name}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target?.value)}
        onClick={onClick}
        helperText={error}
        FormHelperTextProps={{
          error,
        }}
        InputProps={{
          readOnly,
          ...InputProps,
        }}
        {...otherProps}
      />
    </FormControl>
  );
};

StyledTextField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  InputProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

StyledTextField.defaultProps = {
  required: false,
  label: '',
  placeholder: '',
  error: '',
  name: '',
  readOnly: false,
  className: '',
  id: '',
  InputProps: {},
  onChange: () => {},
  onClick: () => {},
};

export default StyledTextField;
