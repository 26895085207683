import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect, getIn } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddPhoto from '../../../../images/icons/addPhoto.svg';
import { MAX_FILE_SIZE } from '../../utils/constants';
import formikInjectedPropsTypes from './formikPropTypes';
import { formikComponentMemoizeFieldCheck, memoizeFields } from './memoraize';

const useStyles = makeStyles((theme) => ({
  noImage: {
    fontSize: 14,
    color: theme.variables.cSlate,
    fontWeight: 500,
    paddingTop: 8,
    lineHeight: '24px',
  },
  photoPlaceholder: {
    maxHeight: '100%',
    minHeight: 1,
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  photoContainer: {
    backgroundColor: theme.variables.cWhite,
    border: `2px dashed ${theme.variables.cLightGray}`,
    width: '100%',
    height: 200,
    borderRadius: 26,
    boxSizing: 'border-box',
    cursor: 'pointer',
    outline: 'none',
    padding: 20,
  },
  container: {
    padding: 20,
    outline: 'none',
    '&>div': {
      height: 175,
    },
  },
}));

const FormikMultiImageDropzone = ({ name, formik, imageContainerClass, containerClass }) => {
  const value = getIn(formik.values, name);
  const intl = useIntl();
  const onDrop = useCallback(
    (acceptedFiles) => {
      let newFiles = [];
      acceptedFiles.forEach((file) => {
        const url = URL.createObjectURL(file);

        if (file.size > MAX_FILE_SIZE) {
          toast.error(
            intl.formatMessage({
              id: 'image.too_large',
              defaultMessage: 'This image is too large. Max size 5mb',
            }),
          );
          return;
        }
        newFiles = [...newFiles, { url, file }];
      });
      formik.setFieldValue(name, [...value, ...newFiles]);
    },
    [formik, value, name, intl],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const classes = useStyles();

  const { ref, ...rootProps } = getRootProps();

  return (
    <RootRef rootRef={ref}>
      <>
        <Grid
          container
          item
          justify="flex-start"
          className={classNames([classes.container, containerClass])}
        >
          {value &&
            value.map(({ url }, index) => (
              <Grid
                key={url}
                item
                xs={3}
                md={6}
                lg={4}
                className={classNames('photo-upload-container')}
              >
                <img
                  src={url}
                  style={{ width: '100%', height: '100%', borderRadius: 26 }}
                  alt={intl.formatMessage(
                    {
                      id: 'container_photo.next_photo',
                      defaultMessage: 'Container Photo №{index}',
                    },
                    { index },
                  )}
                />
              </Grid>
            ))}
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            item
            xs={3}
            md={6}
            lg={4}
            className={classNames([
              classes.photoContainer,
              'photo-upload-container',
              imageContainerClass,
            ])}
            {...rootProps}
          >
            <img
              className={classes.photoPlaceholder}
              src={AddPhoto}
              alt={intl.formatMessage({
                id: 'container_photo.name',
                defaultMessage: 'Container Photo',
              })}
            />
            <span className={classes.noImage}>
              <FormattedMessage id="image.add_photo" defaultMessage="Add Photo" />
            </span>
            <input {...getInputProps()} name={name} />
          </Grid>
        </Grid>
      </>
    </RootRef>
  );
};

FormikMultiImageDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  id: PropTypes.string,
  imageContainerClass: PropTypes.string,
  containerClass: PropTypes.string,
};

FormikMultiImageDropzone.defaultProps = {
  id: '',
  imageContainerClass: null,
  containerClass: null,
};

export default connect(
  React.memo(
    FormikMultiImageDropzone,
    memoizeFields(['name', 'id', 'onChange', 'size', formikComponentMemoizeFieldCheck()]),
  ),
);
