import React, { useState } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import { FormikTextField } from '../inputs/formik';
import TooltippedUserControl from '../tooltip/TooltippedUserControl';
import FormikMaskedInput from '../inputs/formik/FormikMaskedInput';

const useStyles = makeStyles(() => ({
  overflowingSettingsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    '& > *:first-child': {
      marginRight: 16,
    },
  },
  overflowingSettingsLabel: {
    color: '#626469',
    fontWeight: 700,
    marginLeft: 16,
  },
  overflowingTooltip: {
    '& > *': {
      marginTop: 0,
    },
  },
  expandIcon: {
    transition: '0.2s all',
  },
  rotateArrowIcon: {
    transform: 'rotate(180deg)',
  },
}));

const OverflowingSettings = () => {
  const [showOverflowingSettings, setShowOverflowingSettings] = useState(false);
  const classes = useStyles();
  const intl = useIntl();

  const toggleShowOverflowingSettings = () => setShowOverflowingSettings((prev) => !prev);

  return (
    <Grid item xs={12} md={12}>
      <div className={classes.overflowingSettingsContainer}>
        <TooltippedUserControl
          className={classes.overflowingTooltip}
          tooltipText={
            <FormattedMessage
              id="tooltip.specify_overflowing"
              defaultMessage="Specify the overflowing trigger for the specified container"
            />
          }
        >
          <Typography className={classes.overflowingSettingsLabel}>
            {intl.formatMessage({
              id: 'overflowing_settings',
              defaultMessage: 'Overflowing Settings',
            })}
          </Typography>
        </TooltippedUserControl>

        <IconButton size="small" onClick={toggleShowOverflowingSettings}>
          <ExpandMoreIcon
            className={
              showOverflowingSettings
                ? `${classes.expandIcon} ${classes.rotateArrowIcon}`
                : classes.expandIcon
            }
          />
        </IconButton>
      </div>

      <Collapse in={showOverflowingSettings}>
        <Grid container xs={12} md={12} spacing={4}>
          <Grid item xs={3} md={6}>
            <FormikMaskedInput
              name="overflowingSettings.duration"
              mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
              type="text"
              label={intl.formatMessage({
                id: 'label.duration',
                defaultMessage: 'Duration',
              })}
              placeholder={intl.formatMessage({
                id: 'placeholder.duration',
                defaultMessage: 'e.g. 00:00:00',
              })}
            />
          </Grid>
          <Grid item xs={3} md={6}>
            <FormikTextField
              name="overflowingSettings.percentage"
              type="number"
              min={1}
              max={100}
              label={intl.formatMessage({
                id: 'label.fill_level',
                defaultMessage: 'Fill level, %',
              })}
              placeholder={intl.formatMessage({
                id: 'placeholder.fill_level',
                defaultMessage: 'eg 95%',
              })}
              className="m-b-10 w-100"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default OverflowingSettings;
