/* eslint-disable no-template-curly-in-string */
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import client from '../../graphql/apollo';
import Logo from '../../../images/logo.svg';
import { getWasteCategory, getWasteType } from '../analyticsComponents/utils';

export const getDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : '');

export const getLocalDate = (date) => (date ? moment(date).local().format('DD/MM/YYYY') : '');

export const checkUserIsDemo = (state) => !!state.settings.user.isDemo;

export const checkIfMomentDateIsValid = (date) => (moment(date).isValid() ? moment(date) : null);

export const getSavedActiveProjects = (state) =>
  state.settings.activeProjects.map((project) => project.id).join(',');

export const getSavedActiveCompanies = (state) =>
  state.settings.user.activeProjects.edges.map(({ node }) => node.company.id);

export const getProjectSettings = (state) => {
  if (state.settings.user.activeProjects.edges.length) {
    const { node: project } = state.settings.user.activeProjects.edges[0];
    return project.settings;
  }
  return {};
};

export const calcWeight = (projectSettings, value) => {
  switch (projectSettings.weight) {
    case 'KG':
      return value;
    case 'T':
      return value * 0.001;
    case 'LBS':
      return value * 2.2046;
    default:
      return value;
  }
};

export const calcVolume = (projectSettings, value) => {
  switch (projectSettings.volume) {
    case 'M3':
      return value;
    case 'L':
      return value * 1000;
    case 'YD3':
      return value * 1.308;
    case 'FT3':
      return value * 28.316847;
    default:
      return value;
  }
};

export const calcDrivingDistance = (projectSettings, value) => {
  switch (projectSettings.drivingDistance) {
    case 'M':
      return value;
    case 'KM':
      return value * 0.001;
    case 'MI':
      return value * 0.00062137;
    case 'YD':
      return value * 1.093613;
    case 'FT':
      return value * 3.28084;
    default:
      return value;
  }
};

export const getUsersAvailableProjects = (state) => state.settings.user.activeProjects.edges;

export const getWasteFractionFromContainer = (container) => {
  const { wasteFraction = {} } = container || {};

  return (
    wasteFraction && `${getWasteType(wasteFraction)} ${getWasteCategory(wasteFraction)}`.trim()
  );
};

export const getDeviceFromContainer = (container = {}) =>
  // Return first active sensor from the container
  // deviceToContainerSet has a history of all sensor attached to it
  // container can has more than one active sensor, we are returning only first active
  container.deviceToContainerSet?.edges?.find((i) => !i.node.endDate)?.node;

export const getMeasurementsFromContainer = (container) => {
  const { deviceToContainerSet = {} } = container || {};
  const { edges: device = [] } = deviceToContainerSet || {};
  const fillLevelMeasurementSets = [];
  let result = [];

  device.forEach(({ node }) => {
    // Join all measurement from different devices
    if (node.filllevelmeasurementSet && node.filllevelmeasurementSet.edges.length) {
      fillLevelMeasurementSets.push(node.filllevelmeasurementSet.edges || []);
    }
  });

  result = result.concat(...fillLevelMeasurementSets);

  // Sort by createdAt
  result = result.sort(
    ({ node: { createdAt: a } }, { node: { createdAt: b } }) =>
      moment(a).format('X') - moment(b).format('X'),
  );

  return [result, device];
};

export const logger = (debug) => (...messages) => {
  if (debug) {
    window.console.debug('[DEBUG]', ...messages);
  }
};

export const saveCacheRead = async (query, debug = false) => {
  const queryLogger = logger(debug);
  try {
    const cachedQuery = await client.readQuery(query);
    queryLogger('cachedQuery - ', cachedQuery);
    return cachedQuery ? { data: cachedQuery } : await client.query(query);
  } catch (e) {
    queryLogger('get cache error - ', e);
    const apiQuery = await client.query(query);
    queryLogger('api query - ', e);
    return apiQuery;
  }
};

export const IsJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const toastifyError = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.length) {
    error.graphQLErrors.forEach((graphQLError) => {
      const message = graphQLError?.message?.replace(/'/gi, '"');
      if (IsJson(message)) {
        const jsonMessage = JSON.parse(message);
        Reflect.ownKeys(jsonMessage).forEach((field) => {
          toast.error(jsonMessage[field].join(', '));
        });
      } else {
        toast.error(message);
      }
    });
  } else if (error?.networkError?.result?.errors) {
    error.networkError.result.errors.forEach((networkError) => toast.error(networkError.message));
  } else {
    toast.error(error.message);
  }
};

export const getMainLogo = (user) => {
  if (user && user.isAdmin) {
    return Logo;
  }

  if (user && user.activeProjects && user.activeProjects.edges[0]) {
    if (user.activeProjects.edges.length > 1) {
      return user?.company?.logo ? user.company.logo : Logo;
    }
    return user.activeProjects.edges[0]?.node?.logo || user?.company?.logo || Logo;
  }

  return user?.company?.logo || Logo;
};

export const getJobTitle = (state) => {
  const { settings: { user: { jobtitle: { title: jobTitle = '' } } = {} } = {} } = state;

  return jobTitle;
};

export const CurrentUserContext = React.createContext(null);

export const getFieldsErrors = (e) =>
  (e?.graphQLErrors || []).reduce((acc, graphQLError) => {
    if (graphQLError?.context?.field) {
      acc.push({
        field: graphQLError.context.field,
        message: graphQLError.message,
      });
    }
    return acc;
  }, []);

export const sleep = (ms, ...args) =>
  new Promise((resolve) => setTimeout(() => resolve(...args), ms));

export const validationMessages = {
  mixed: {
    default: (
      <FormattedMessage id="defaultValidation.default" defaultMessage="${path} is invalid" />
    ),
    required: (
      <FormattedMessage id="defaultValidation.required" defaultMessage="This field is required" />
    ),
    oneOf: (
      <FormattedMessage
        id="defaultValidation.oneOf"
        defaultMessage="${path} must be one of the following values: ${values}"
      />
    ),
    notOneOf: (
      <FormattedMessage
        id="defaultValidation.notOneOf"
        defaultMessage="${path} must not be one of the following values: ${values}"
      />
    ),
    defined: (
      <FormattedMessage id="defaultValidation.defined" defaultMessage="${path} must be defined" />
    ),
  },
  string: {
    length: (
      <FormattedMessage
        id="defaultValidation.length"
        defaultMessage="${path} must be exactly ${length} characters"
      />
    ),
    min: (
      <FormattedMessage
        id="defaultValidation.string.min"
        defaultMessage="${path} must be at least ${min} characters"
      />
    ),
    max: (
      <FormattedMessage
        id="defaultValidation.string.max"
        defaultMessage="${path} must be at most ${max} characters"
      />
    ),
    matches: (
      <FormattedMessage
        id="defaultValidation.matches"
        defaultMessage="${path} must match the following: '${regex}'"
      />
    ),
    email: (
      <FormattedMessage
        id="defaultValidation.email"
        defaultMessage="${path} must be a valid email"
      />
    ),
    url: (
      <FormattedMessage id="defaultValidation.url" defaultMessage="${path} must be a valid URL" />
    ),
    trim: (
      <FormattedMessage
        id="defaultValidation.trim"
        defaultMessage="${path} must be a trimmed string"
      />
    ),
    lowercase: (
      <FormattedMessage
        id="defaultValidation.lowercase"
        defaultMessage="${path} must be a lowercase string"
      />
    ),
    uppercase: (
      <FormattedMessage
        id="defaultValidation.uppercase"
        defaultMessage="${path} must be a upper case string"
      />
    ),
  },
  number: {
    min: (
      <FormattedMessage
        id="defaultValidation.number.min"
        defaultMessage="${path} must be greater than or equal to ${min}"
      />
    ),
    max: (
      <FormattedMessage
        id="defaultValidation.number.max"
        defaultMessage="${path} must be less than or equal to ${max}"
      />
    ),
    lessThan: (
      <FormattedMessage
        id="defaultValidation.lessThan"
        defaultMessage="${path} must be less than ${less}"
      />
    ),
    moreThan: (
      <FormattedMessage
        id="defaultValidation.moreThan"
        defaultMessage="${path} must be greater than ${more}"
      />
    ),
    notEqual: (
      <FormattedMessage
        id="defaultValidation.notEqual"
        defaultMessage="${path} must be not equal to ${notEqual}"
      />
    ),
    positive: (
      <FormattedMessage
        id="defaultValidation.positive"
        defaultMessage="${path} must be a positive number"
      />
    ),
    negative: (
      <FormattedMessage
        id="defaultValidation.negative"
        defaultMessage="${path} must be a negative number"
      />
    ),
    integer: (
      <FormattedMessage
        id="defaultValidation.integer"
        defaultMessage="${path} must be an integer"
      />
    ),
  },
  date: {
    min: (
      <FormattedMessage
        id="defaultValidation.date.min"
        defaultMessage="${path} field must be later than ${min}"
      />
    ),
    max: (
      <FormattedMessage
        id="defaultValidation.date.max"
        defaultMessage="${path} field must be at earlier than ${max}"
      />
    ),
  },
  boolean: {},
  object: {
    noUnknown: (
      <FormattedMessage
        id="defaultValidation.noUnknown"
        defaultMessage="${path} field has unspecified keys: ${unknown}"
      />
    ),
  },
  array: {
    min: (
      <FormattedMessage
        id="defaultValidation.array.min"
        defaultMessage="${path} field must have at least ${min} items"
      />
    ),
    max: (
      <FormattedMessage
        id="defaultValidation.array.max"
        defaultMessage="${path} field must have less than or equal to ${max} items"
      />
    ),
  },
};

export const addValidationMethods = () => {
  Yup.addMethod(Yup.mixed, 'time', function testValueIsTime() {
    return this.test({
      name: 'time',
      message: (
        <FormattedMessage
          id="validation.validTimeString"
          defaultMessage="Not valid time string"
          description="Validate min value"
        />
      ),
      test(value) {
        if (!value) {
          return true;
        }
        return value.isValid();
      },
    });
  });

  Yup.addMethod(
    Yup.mixed,
    'timeGreaterThen',
    function testValueIsGreaterThen(compareWith, message = '') {
      return this.test({
        name: 'is-time-from-greater',
        message: message || (
          <FormattedMessage
            id="validation.timeGreaterThen.errorMessage"
            defaultMessage="Should be more then value in previous input"
            description="Validate min value"
          />
        ),
        test(currentValue) {
          const compareWithValue = this.parent[compareWith];
          if (compareWithValue && currentValue) {
            return currentValue.isAfter(compareWithValue);
          }
          return true;
        },
      });
    },
  );

  Yup.addMethod(
    Yup.mixed,
    'timeLesserThen',
    function testValueIsGreaterThen(compareWith, message = '') {
      return this.test({
        name: 'is-time-from-greater',
        message: message || (
          <FormattedMessage
            id="validation.timeLesserThen.errorMessage"
            defaultMessage="Should be less then value in previous input"
            description="Validate min value"
          />
        ),
        test(currentValue) {
          const compareWithValue = this.parent[compareWith];
          if (compareWithValue && currentValue) {
            return compareWithValue.isAfter(currentValue);
          }
          return true;
        },
      });
    },
  );
};

export const maxLengthStringMessage = (maxLength) => (
  <FormattedMessage
    id="maxLengthStringMessage"
    defaultMessage="Exceeded maximum length of {maxLength}"
    values={{ maxLength }}
  />
);

export const maxNumberMessage = (maxNumber) => (
  <FormattedMessage
    id="less_or_equal_number"
    defaultMessage="Should be less than or equal to {maxNumber}"
    values={{ maxNumber }}
  />
);

export const messageNoNegativeValues = (
  <FormattedMessage
    id="vehicle.validation.negative_numbers_not_allowed"
    defaultMessage="Only positive numbers are allowed!"
  />
);

export const messageWrongCoords = (coordsValue) => (
  <FormattedMessage
    id="wrong_coords"
    defaultMessage="Should be in range from -{value} to {value}"
    values={{ value: coordsValue }}
  />
);

export const uniqueWasteFractions = (arr) => {
  let wFractions = [];
  const fractions = arr.map((item) => item.node || item);

  fractions.forEach((item) => {
    const foundWFraction = wFractions.find(
      (wFraction) =>
        `${wFraction.wasteCategory} ${wFraction.wasteType.join(',')}` ===
        `${item.wasteCategory} ${item.wasteType.join(',')}`,
    );

    if (foundWFraction) {
      wFractions = wFractions.filter(
        (wFraction) =>
          `${wFraction.wasteCategory} ${wFraction.wasteType.join(',')}` !==
          `${item.wasteCategory} ${item.wasteType.join(',')}`,
      );

      wFractions.push({
        ...foundWFraction,
        id: foundWFraction.id.concat(`,${item.id}`),
      });
    } else {
      wFractions.push(item);
    }
  });
  return wFractions;
};
