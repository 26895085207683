import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/el';
import 'moment/locale/en-gb';
import 'moment/locale/da';
import Tutorial from './components/shared/tutorial/tutorial';

export const TutorialContext = React.createContext(null);

const TutorialProvider = ({ children }) => {
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [tutorialType, setTutorialType] = useState('');

  const startTutorial = useCallback((newTutorialName) => {
    setTutorialType(newTutorialName);
    setTutorialOpen(true);
  }, []);

  const isTutorialOpen = useCallback(() => tutorialOpen, [tutorialOpen]);

  return (
    <TutorialContext.Provider value={{ startTutorial, isTutorialOpen }}>
      {children}
      {isTutorialOpen && (
        <Tutorial
          isTutorialOpen={isTutorialOpen()}
          onCloseTutorial={setTutorialOpen}
          tutorialType={tutorialType}
        />
      )}
    </TutorialContext.Provider>
  );
};

TutorialProvider.propTypes = {
  children: PropTypes.element,
};

TutorialProvider.defaultProps = {
  children: null,
};

export default TutorialProvider;
