import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Divider } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useStyles from '../../shared/styles';
import FaqContainer from '../../shared/faqContainer';
import ListStyled from '../../shared/listStyled';
import SupportTextPage from '../../shared/supportTextPage';

const PlatformSettings = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={11}>
        <SupportTextPage
          title={<FormattedMessage id="platform_settings_header" defaultMessage="Settings" />}
          texts={[
            <FormattedMessage
              id="platform_settings_text_one"
              defaultMessage="In <bold>Settings</bold> you can configure all the standard settings for your account and projects. You can create pre-sets for container types, waste fractions, and measurement settings, which speeds up the process of adding a new container to your project."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
        />
        <FaqContainer
          heading={<FormattedMessage id="account_overview" defaultMessage="My Account Settings" />}
          text={[
            <FormattedMessage
              id="my_account_settings_text_one"
              defaultMessage="In <bold> My Account </bold> settings you can add and edit your name, username, email address, profile picture, mobile number and your job title. This is where you can change your password."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="account_settings_question_one"
                  defaultMessage="How do I change my password?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="account_settings_q1_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu then <bold>My Account</bold>. Click <bold>Reset Password </bold>. Type in your new password in the first field, then type it again in the confirm password field. Click <bold>Change Password</bold> to save your new password."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="account_settings_question_two"
                  defaultMessage="How do I change my email address for my account?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="paccount_settings_q2_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu then <bold>My Account</bold>. Under <bold>Email</bold>, type in the email address you would like to be associated with your account and click save. Remember that this will not change your username, so if you’d like to login with your new email address, you will also need to change your username. You will be logged out immediately after changing your Username. Login again with your updated email address."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="account_settings_question_third"
                  defaultMessage="I've forgotten my password. How do I reset it?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="account_settings_q3_first"
                  defaultMessage="In the login screen for the platform, click <bold>Forgot Password</bold>? Type in the email address associated with your WasteHero account and click <bold>Reset Password</bold>. Check your emails for an email from WasteHero. If it doesn't come through immediately, check your spam folder. Click the link in the email and type in your new password, then type it again to confirm your new password. Click <bold>Change Password</bold> to save your new password. You will see the message <bold>You can now use your new password.</bold>"
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
                <FormattedMessage
                  id="account_settings_q3_second"
                  defaultMessage="Return to login and login as normal with your new password."
                />,
              ],
            },
          ]}
        >
          <Grid item xs={11}>
            <Divider className={classes.mainDivider} />
          </Grid>
        </FaqContainer>
        <FaqContainer
          heading={<FormattedMessage id="platform_overview" defaultMessage="Project Overview" />}
          text={[
            <FormattedMessage
              id="platform_overview_text"
              defaultMessage="In <bold>Project Overview</bold> you can view the primary company and the projects within the company. Here you can edit company details, add new projects, and manage the settings of existing projects."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="platform_overview_question_first"
                  defaultMessage="How do I change the default map view on my dashboard?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_overview_q1_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. Click on the location and drag the map to your desired position. Click confirm. Hit <bold>Save</bold> at the bottom of the page to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_po_second"
                  defaultMessage="How do I add a new project?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_overview_q1_second"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, then <bold>Project Overview</bold>. Add a new project under your company by clicking the <icon></icon> icon to the right of the name. You will then be guided through the steps of setting up your new project."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    icon: () => <AddCircleIcon className={classes.addIconStyle} />,
                  }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_overview_question_third"
                  defaultMessage="How do I edit a project?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_overview_q3_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, then <bold>Project Overview</bold>. Click the  <bold>settings icon</bold> <icon></icon> to the right of the name to edit a particular project. You can then navigate through the different settings to make any necessary changes."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    icon: () => <SettingsIcon className={classes.iconStyle} />,
                  }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_overview_question_fourth"
                  defaultMessage="How do I delete a project?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_overview_q4_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, then <bold>Project Overview</bold>. Click the  <bold>trash can icon</bold>  <icon></icon> icon to the right of the project name you want to delete."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    icon: () => <DeleteIcon className={classes.iconStyle} />,
                  }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_overview_question_fifth"
                  defaultMessage="How do I edit my company details?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_overview_q5_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, then <bold>Project Overview</bold>. Click the  <bold>pencil icon</bold> <icon></icon> icon to the right of your company name to edit the company name, location, image, and description."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    icon: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_overview_question_sixth"
                  defaultMessage="How do I add a logo for my company?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_overview_q6_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, then Project Overview. Click the <bold>pencil icon</bold> <icon></icon>  to the right of your company name. Click the upload icon and select your logo from your own files and click Open to upload your chosen image."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    icon: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
              ],
            },
          ]}
        >
          <Grid item xs={11}>
            <Divider className={classes.mainDivider} />
          </Grid>
        </FaqContainer>
        <FaqContainer
          heading={<FormattedMessage id="platform_settings" defaultMessage="Project Settings" />}
          text={[
            <FormattedMessage
              id="platform_settings_text"
              defaultMessage="In <bold>Project Settings</bold> you can add a name, description, and image for your project. You’ll also choose your preferences for the time zone, currency, clock format, units of measurement and the default country to appear on dashboard map."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_first"
                  defaultMessage="How do I change the time zone for my project?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_q1_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. In the Time Zone drop-down, type in your desired time zone or scroll to search through the list. Select your time zone and then hit <bold>Save</bold> to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_second"
                  defaultMessage="How do I change the currency?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_q2_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. In the <bold>Currency</bold> drop-down, type in your desired currency or scroll to search through the list. Select your currency and then hit <bold>Save</bold> to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_third"
                  defaultMessage="How do I change to the 12-hour or 24-hour time format?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_q3_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. In the <bold>Hour format</bold> drop-down, choose from either 12-hour clock or 24-hour clock, then hit <bold>Save</bold> to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_fourth"
                  defaultMessage="How do I change to my preferred measurement system? How do I change to the metric or imperial system?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_q4_fourth"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. Choose your preferred units of measurement for <bold>Measuring Length, Driving Distance, Weight, Volume</bold>. Hit <bold>Save</bold> to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_fifth"
                  defaultMessage="How do I change to degrees Celsius or Fahrenheit?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_q5_fifth"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. Under <bold>Temperature</bold>, click to select your desired temperature measurement, °C for Celsius or °F for Fahrenheit. Hit <bold>Save</bold> to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_sixth"
                  defaultMessage="How do I add an image for my project?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_q6_sixth"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. Click the upload icon next to your project name. Select an image from your own files and click <bold>Open</bold> to upload your chosen image. Hit <bold>Save</bold> to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_seventh"
                  defaultMessage="How do I change the name for my project?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_item_q7_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. Change the text under <bold>Project Name</bold>. Hit <bold>Save</bold> to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_eigth"
                  defaultMessage="How do I change the default map view on my dashboard?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_settings_q8_eigth"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. Click on the location and drag the map to your desired position. Click confirm. Hit <bold>Save</bold> at the bottom of the page to confirm your changes."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
          ]}
        >
          <Grid item xs={11}>
            <Divider className={classes.mainDivider} />
          </Grid>
        </FaqContainer>
        <FaqContainer
          heading={
            <FormattedMessage
              id="platform_settings_container"
              defaultMessage="Container Settings"
            />
          }
          text={[
            <FormattedMessage
              id="platform_settings_container_text"
              defaultMessage="In <bold>Container Settings</bold> you can create new container types for your project, and also edit existing container types. Containers with different placements, vehicle coupling methods, capacity or even different emptying time can be considered as separate container types."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="platform_settings_container_text_second"
              defaultMessage="Later on, when you are activating a device and registering a new container, options from these container types will appear to select. Having these container types pre-set will speed up the process of adding new containers later on, so it’s essential you set them up prior to the device activation process."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="platform_settings_question_container_settings_first"
                  defaultMessage="How do I add a new container type?"
                />
              ),
              texts: [
                <ListStyled
                  items={[
                    <FormattedMessage
                      id="container_settings_item_q1_first"
                      defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Container Settings</bold>. "
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="container_settings_q1_second"
                      defaultMessage="Enter a <bold>name</bold> for the container type, then select its emplacement; surface, semi-underground or underground. Depending on the container’s emplacement, you will need to add further details on mobility or container access."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="container_settings_q1_third"
                      defaultMessage="Choose the <bold>coupling</bold> of the container with the type of pick-up vehicle. Select the approximate amount of time it takes to empty the container based on a surface or underground bin. Otherwise, enter a custom amount for the number of minutes it takes to empty the container."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="container_settings_q1_fourth"
                      defaultMessage="Enter the <bold>volume</bold> of the container by selecting a predefined option from the list, or enter a custom volume in m3 or L. You can also <bold>upload an image</bold> from your own files for the container type by clicking on the container outline to the right."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="container_settings_q1_fifth"
                      defaultMessage="The internal dimensions will appear automatically based on your chosen volume. If your container is cylindrical, tick the box cylinder shaped. Tick the edit measurements box if you want to manually edit the dimensions of height, length and width."
                    />,
                    <FormattedMessage
                      id="container_settings_q1_sixth"
                      defaultMessage="These measurements which will be used to calculate how much percentage of the container is filled in the future."
                    />,
                    <FormattedMessage
                      id="container_settings_q1_seventh"
                      defaultMessage="When satisfied with the details of your container type, click the <iconAdd></iconAdd> Add Container button. You will see the message <bold>Container type successfully saved</bold>. This means that the container type has been added to your added containers list which makes for faster set-up in the future."
                      values={{
                        bold: (text) => <b>{text}</b>,
                        iconAdd: () => <AddCircleIcon className={classes.addIconStyle} />,
                      }}
                    />,
                  ]}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="container_settings_question_second"
                  defaultMessage="How do I add an image for a container type?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="container_settings_q2_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Container Settings</bold>. In the <bold>Added containers list</bold>, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the container type you wish to add an image for. Click on the picture of the container outline to the right. Select an image from your files and click <bold>Open</bold> to upload your chosen image. Hit <bold>Save Container</bold> to confirm your changes."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="container_settings_question_third"
                  defaultMessage="How do I delete a container type?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="container_settings_q3_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Container Settings</bold>. In the Added containers list, click the <bold>trash can icon</bold> <iconDelete></iconDelete> next to the container type you want to delete, then click <bold>Confirm</bold> to delete. This can’t be undone, so be sure you want to delete this container type."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconDelete: () => <DeleteIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="container_settings_item_q3_second"
                  defaultMessage="Deletion is successful when you see the message <bold>Container type successfully removed</bold>"
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="container_settings_question_fourth"
                  defaultMessage="How do I edit the settings for a container type?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="container_settings_q4_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Container Settings</bold>. In the <bold>Added containers</bold> list, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the container type you want to edit. Make your desired changes to the container type, then click <bold>Save Container</bold> to confirm your changes."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="container_settings_q4_second"
                  defaultMessage="You will see the message <bold>Container type successfully saved</bold>."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
          ]}
        >
          <Grid item xs={11}>
            <Divider className={classes.mainDivider} />
          </Grid>
        </FaqContainer>
        <FaqContainer
          heading={
            <FormattedMessage
              id="platform_waste_fraction_settings_section"
              defaultMessage="Waste Fractions Settings"
            />
          }
          text={[
            <FormattedMessage
              id="platform_waste_fraction_text"
              defaultMessage="In <bold>Waste Fractions Settings</bold> you can set up different types of waste the containers can collect; known as waste fractions. They may be plastics, paper, electronics, organics etc. Later when you are adding a new container, options from these waste fraction types will appear for you to select. Having these waste fraction types pre-set will speed up the process of adding new containers later on."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="platform_waste_fraction_question_first"
                  defaultMessage=" How do I add a new waste fraction type?"
                />
              ),
              texts: [
                <ListStyled
                  items={[
                    <FormattedMessage
                      id="platform_waste_fraction_q1_first"
                      defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Waste Fraction Settings</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="platform_waste_fraction_q1_second"
                      defaultMessage="Choose the type of waste fraction from the drop-down list. You can select multiple waste fractions from the list if necessary. If your container’s waste type doesn’t appear in the list, scroll to the bottom of the list to create a custom project-specific waste fraction type. Type the name of the waste fraction then hit the <iconAdd></iconAdd> button to add your option to the list."
                      values={{
                        bold: (text) => <b>{text}</b>,
                        iconAdd: () => <AddCircleIcon className={classes.addIconStyle} />,
                      }}
                    />,
                    <FormattedMessage
                      id="platform_waste_fraction_q1_third"
                      defaultMessage="Enter the weight to volume ratio for your waste fraction. If you’re not sure what this is, just enter 1. Select the disposal method for the selected waste fraction from the dropdown menu. You can select multiple disposal methods if necessary."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="platform_waste_fraction_q1_fourth"
                      defaultMessage="Once satisfied with the details for your waste fraction, click <iconAdd></iconAdd> <bold>Add waste fraction</bold> to save your settings. You will see the message <bold>Waste fraction successfully saved</bold>. This means that the waste fraction has been added to your <bold>Added waste fractions</bold> list, which makes for faster container set-up in the future."
                      values={{
                        bold: (text) => <b>{text}</b>,
                        iconAdd: () => <AddCircleIcon className={classes.addIconStyle} />,
                      }}
                    />,
                  ]}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_waste_fraction_question_second"
                  defaultMessage="How do I delete a waste fraction?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_waste_fraction_q2_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Waste Fraction Settings</bold>. In the <bold>Added waste fractions</bold> list, click the <bold>trash can icon</bold> <iconDelete></iconDelete> next to the waste fraction you want to delete, then click <bold>Confirm</bold> to delete. This can’t be undone, so be sure you want to delete this container type."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconDelete: () => <DeleteIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_waste_fraction_q2_second"
                  defaultMessage="Deletion is successful when you see the message <bold>Waste fraction successfully removed</bold>"
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_waste_fraction_question_third"
                  defaultMessage="How do I edit the settings for a waste fraction?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_waste_fraction_q3_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Waste Fraction Settings</bold>. In the <bold>Added waste fractions</bold> list, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the waste fraction you want to edit. Make your desired changes to the waste fraction, then click Save waste fraction to confirm your changes."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_waste_fraction_q3_second"
                  defaultMessage="You will see the message <bold>Waste fraction successfully saved</bold>"
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_waste_fraction_question_fourth"
                  defaultMessage="Can I add a custom waste fraction type?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_waste_fraction_q4_first"
                  defaultMessage="Yes, you can add a custom waste fraction. Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Waste Fraction Settings</bold>. Under the <bold>Waste Fraction</bold> drop-down, scroll to the bottom of the list and you’ll find you can create a custom project-specific waste fraction type. Type the name of the waste fraction then hit the <iconAdd></iconAdd> button to add your option to the list."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconAdd: () => <AddCircleIcon className={classes.addIconStyle} />,
                  }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_waste_fraction_question_fifth"
                  defaultMessage="Can I choose multiple types of waste for one container?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_waste_fraction_q5_first"
                  defaultMessage="Yes, you can select multiple waste fractions for one container. Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Waste Fraction Settings</bold>. Under the <bold>Waste Fraction</bold> drop-down, you can select multiple waste types to make up a waste fraction setting."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
          ]}
        >
          <Grid item xs={11}>
            <Divider className={classes.mainDivider} />
          </Grid>
        </FaqContainer>
        <FaqContainer
          heading={
            <FormattedMessage
              id="platform_measurement_settings_section"
              defaultMessage="Measurement Settings"
            />
          }
          text={[
            <FormattedMessage
              id="platform_measurement_text"
              defaultMessage="In <bold>Measurement Settings</bold> you can set up custom settings for when and how often the sensor will take measurements in the container. It is possible to create multiple measurement settings with custom timings, where you can exclude particular days or periods."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_first"
                  defaultMessage="How can I add custom measurement settings?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q1_item_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. Enter your desired measurement settings then click <bold> + Add customized</bold> to save it to your <bold>Added measurement settings</bold> list."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconAdd: () => <AddCircleIcon className={classes.addIconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_measurement_q1_item_second"
                  defaultMessage="Here are the details for creating a custom measurement setting:"
                />,
                <ListStyled
                  items={[
                    <FormattedMessage
                      id="platform_measurement_q1_item_third"
                      defaultMessage="Enter a suitable <bold>Measurement type name</bold> for the setting. You may wish to name the setting after the number of measurements the sensor will take in a day and any exclusions, e.g. 4 measurements (excl. weekends). Pick a name that will be distinguishable from other measurement setting names."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="platform_measurement_q1_item_fourth"
                      defaultMessage="Under <bold>choose hours</bold>, you can select the hours of the day in which measurements have to be taken. The measurement device will be sleeping the rest of the time to conserve battery. If you’d like the device to measure at four periods throughout the day, you might select 6AM, 10AM, 2PM and 6PM."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="platform_measurement_q1_item_fifth"
                      defaultMessage="In the <bold>measure at</bold> drop-down, you can select at which quarter of the hour the measurement will be taken. This will apply for every hour you have chosen in the previous step. To measure on the hour, choose the first option of XX:00."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="platform_measurement_q1_item_sixth"
                      defaultMessage="In the <bold>measurements per hour</bold> drop-down, you can select the number of measurements to be taken each hour. You can select 1-4 measurements to be taken for every hour you have chosen. We suggest 1 measurement per hour as a standard setting."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="platform_measurement_q1_item_seventh"
                      defaultMessage="In the <bold>exclude days</bold> and <bold>exclude period</bold> options, you can choose time periods in which the sensors will not take measurements. For example, if there are few deposits to your containers on weekends, you can exclude Saturday and Sunday. Click on the drop-down menu and tick the day you wish to exclude."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                    <FormattedMessage
                      id="platform_measurement_q1_item_eigth"
                      defaultMessage="If your containers won’t be utilised over a certain date range (such as summer holidays), you can also choose to not take measurements during this time. You also have the option to exclude this period every year. To exclude a whole period, click on start date to bring up the calendar, then select your chosen start date and finish date."
                    />,
                    <FormattedMessage
                      id="platform_measurement_q1_item_nineth"
                      defaultMessage="When satisfied with your measurement settings, click <bold> + Add customized</bold> to save it to the <bold>Added measurement settings</bold> list. You will see the message <bold>Measurement setting successfully saved</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />,
                  ]}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_second"
                  defaultMessage="How can I use the default measurement settings?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q2_item_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. Click <bold>+ Add default</bold>, then select either four measurements per day (low traffic) or eight measurements per day (high traffic). By selecting a default measurement, this will save the measurement settings to your <bold>Added measurement settings</bold> list."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_third"
                  defaultMessage="How can I edit my measurement settings?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q3_item_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. In the <bold>Added measurement settings</bold> list, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the measurement setting you want to edit. Make your desired changes to the measurement setting, then click <bold>Save customized</bold> to confirm your changes."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_measurement_q3_item_second"
                  defaultMessage="You will see the message <bold>Measurement setting successfully saved</bold>"
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_fourth"
                  defaultMessage="How can I delete a measurement setting?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q4_item_first"
                  defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. In the <bold>Added measurement settings</bold> list, click the <bold>Trash can icon</bold>  <iconDelete></iconDelete> next to the measurement setting you want to delete, then click Confirm to delete. This can’t be undone, so be sure you want to delete this measurement setting."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconDelete: () => <DeleteIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_measurement_q4_item_second"
                  defaultMessage="Deletion is successful when you see the message <bold>Measurement setting successfully removed</bold>."
                  values={{
                    bold: (text) => <b>{text}</b>,
                  }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_fifth"
                  defaultMessage="Can I exclude days where the sensor won’t take measurements?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q5_item_first"
                  defaultMessage="Yes, you can. Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. In the <bold>Added measurement settings</bold> list, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the measurement setting you want to exclude days for measuring. Under the <bold>Exclude Days</bold> drop-down, tick the days that you don’t want the device to take measurements on."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_measurement_q5_item_second"
                  defaultMessage="Click <bold>Save customized</bold> to confirm your changes. You will see the message <bold>Measurement setting successfully saved</bold>."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_sixth"
                  defaultMessage="Can I choose the hours that the sensor will take measurements?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q6_item_first"
                  defaultMessage="Yes, you can. Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. In the <bold>Added measurement settings</bold> list, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the measurement setting you want to exclude days for measuring. Under <bold>Choose hours</bold> on the number grid, you may choose the hours of the day that you would like the device to take measurements."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_measurement_q6_item_second"
                  defaultMessage="Click <bold>Save customized</bold> to confirm your changes. You will see the message <bold>Measurement setting successfully saved</bold>."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_seventh"
                  defaultMessage="Can the sensor take measurements more than once per hour?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q7_item_first"
                  defaultMessage="Yes, you can. Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. In the <bold>Added measurement settings</bold> list, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the measurement setting you want to edit. Under the drop-down of <bold>Measurements per hour</bold>, select either 1, 2, 3 or 4."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_measurement_q7_item_second"
                  defaultMessage="Click <bold>Save customized</bold> to confirm your changes. You will see the message <bold>Measurement setting successfully saved</bold>."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="platform_measurement_question_eighth"
                  defaultMessage="Can I exclude a period where the sensor won't take any measurements?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="platform_measurement_q8_item_first"
                  defaultMessage="Yes, you can. Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Measurement Settings</bold>. In the <bold>Added measurement settings</bold> list, click the <bold>Pencil icon</bold> <iconPencil></iconPencil> next to the measurement setting you want to edit. Under <bold>Exclude period</bold>, click on start date to bring up the calendar, then select your chosen start date and finish date for the period you want to exclude."
                  values={{
                    bold: (text) => <b>{text}</b>,
                    iconPencil: () => <EditIcon className={classes.iconStyle} />,
                  }}
                />,
                <FormattedMessage
                  id="platform_measurement_q8_item_second"
                  defaultMessage="Click <bold>Save customized</bold> to confirm your changes. You will see the message <bold>Measurement setting successfully saved</bold>."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
          ]}
        >
          <Grid item xs={11}>
            <Divider className={classes.mainDivider} />
          </Grid>
        </FaqContainer>
        <FaqContainer
          heading={
            <FormattedMessage
              id="project_overview_within_settings"
              defaultMessage="Project Overview within Settings"
            />
          }
          text={[
            <FormattedMessage
              id="project_overview_within_settings_text"
              defaultMessage="The last screen of the setting establishment process, <bold>Project Overview</bold>, gives you an overview of the configured details for the project. Here you can view the container types, waste fractions, and measurement settings you have just set up. You can also invite other users to collaborate on the project."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="project_overview_within_settings_question_first"
                  defaultMessage="How do I add a new user?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="project_overview_within_settings_q1_first"
                  defaultMessage="Go to <bold>User Management</bold> in the main menu, select your project, then click <bold>Add New User</bold>. This will redirect you to an overview of your project in the last step of settings. Select the account type of the user you want to invite, select their job title and enter their email address, then click Invite. You will see the message <bold>User assigned to project</bold>."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
                <FormattedMessage
                  id="project_overview_within_settings_q1_second"
                  defaultMessage="This will send the invitee an email with a link to access the platform. You may need to ask the invitee to check their spam folder if the email doesn’t come through immediately."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
                <FormattedMessage
                  id="project_overview_within_settings_q1_third"
                  defaultMessage="The invitee will then have to register a new account."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
            {
              header: (
                <FormattedMessage
                  id="project_overview_within_settings_question_second"
                  defaultMessage="How do I remove a user?"
                />
              ),
              texts: [
                <FormattedMessage
                  id="project_overview_within_settings_q2_first"
                  defaultMessage="Go to <bold>User Management</bold> in the main menu, then select your project. Any users assigned to this project will be shown in the list. Click the three vertical dots to the right of the user you want to delete and click <bold>Delete</bold>. A dialogue will appear and ask if you're sure you want to delete, click <bold>Confirm</bold>."
                  values={{ bold: (text) => <b>{text}</b> }}
                />,
              ],
            },
          ]}
        >
          <Grid item xs={11}>
            <Divider className={classes.mainDivider} />
          </Grid>
        </FaqContainer>
      </Grid>
    </Grid>
  );
};

export default PlatformSettings;
