import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core';

const muiErrorClass = 'error-label';

const useStyle = makeStyles({
  startIconContainer: {
    marginRight: -10,
  },
  text: {
    padding: 3.5,
    marginRight: 10,
  },
});

const SettingsDropdown = React.forwardRef(
  (
    {
      label,
      handleOpenPopup,
      handleClosePopup,
      isOpen,
      anchorElement,
      popoverClasses,
      buttonClasses,
      children,
      buttonStyle,
      variantType,
      colour,
      disabled,
      ...props
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField(props);
    const classes = useStyle();
    return (
      <>
        <Button
          color={colour === 'orange' ? 'secondary' : 'default'}
          ref={ref}
          variant={variantType === 'contained' ? 'contained' : 'outlined'}
          className={classNames({
            'w-100 settings-control-button': buttonStyle === 'button',
            'settings-popup-toggler': buttonStyle === 'dropdown',
            [muiErrorClass]: meta.touched && meta.error,
            buttonClasses,
          })}
          startIcon={
            buttonStyle === 'button' ? (
              <AddIcon />
            ) : (
              <Grid className={classes.startIconContainer} alignItems="center" container>
                <ArrowDropDownIcon />
              </Grid>
            )
          }
          onClick={(e) => {
            if (!disabled) {
              helpers.setTouched(true);
              handleOpenPopup(e);
            }
          }}
          type="button"
        >
          {buttonStyle === 'button' ? (
            <Typography className={classes.text} variant="body1">
              {label}
            </Typography>
          ) : (
            label
          )}
        </Button>
        <input {...field} {...props} type="hidden" />
        {meta.touched && meta.error ? (
          <FormHelperText style={{ paddingLeft: '14px' }} error>
            {meta.error}
          </FormHelperText>
        ) : null}
        <Popover
          className={`settings-popup ${popoverClasses}`}
          open={isOpen}
          disablePortal
          anchorEl={anchorElement}
          onClose={handleClosePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grid container className="p-l-10" direction="column">
            {children}
          </Grid>
        </Popover>
      </>
    );
  },
);

SettingsDropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired])
    .isRequired,
  handleOpenPopup: PropTypes.func.isRequired,
  handleClosePopup: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorElement: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.instanceOf(Element).isRequired,
  ]),
  popoverClasses: PropTypes.string,
  buttonClasses: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node).isRequired,
    PropTypes.node.isRequired,
  ]),
  buttonStyle: PropTypes.string,
  variantType: PropTypes.string,
  colour: PropTypes.string,
  disabled: PropTypes.bool,
};

SettingsDropdown.defaultProps = {
  popoverClasses: '',
  buttonClasses: '',
  children: null,
  buttonStyle: 'dropdown',
  variantType: 'outlined',
  colour: 'default',
  anchorElement: null,
  disabled: false,
};

export default SettingsDropdown;
