const styles = (theme) => ({
  form: {
    margin: 50,
    width: '80%',
  },
  fieldRow: {
    marginTop: 20,
    minWidth: 380,
    display: 'flex',
  },
  textField: {
    width: '100%',
  },
  textFieldIcon: {
    marginLeft: 10,
    opacity: 0.2,
  },
  submitBtn: {
    width: 300,
    height: 40,
    marginTop: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  submitBtnContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  changePassBtn: {
    minWidth: 200,
    height: 40,
    marginTop: 30,
  },
  errorMessage: {
    marginTop: 20,
    fontSize: 14,
    minHeight: 30,
    color: theme.variables.VHRed,
  },
  selector: {
    width: '100%',
    textAlign: 'left',
  },
  imageContainer: {
    height: 96,
  },
});

export default styles;
