import React from 'react';
import { FormattedMessage } from 'react-intl';
import PlatformSupport from '../../../../images/support/platform_support.png';
import AppSupport from '../../../../images/support/app_support.png';
import SensorSupport from '../../../../images/support/sensor_support.png';
import NavigationSupport from '../../../../images/support/nav_support.png';
import PlatformOverview from './content/platformSupport/platformOverview';
import PlatformDashbord from './content/platformSupport/dashboard';
import PlatformContainer from './content/platformSupport/containerPage';
import NotificationPlatform from './content/platformSupport/notificationPage';
import UserManagementPlatform from './content/platformSupport/userManagement';
import PlatformFleetManagement from './content/platformSupport/fleetManagement';
import PlatformSettings from './content/platformSupport/platformSettings';
import AppOverview from './content/appSupport/appOverview';
import AppDownload from './content/appSupport/appDownload';
import AppScanActivateSensor from './content/appSupport/appScanActivateSensor';
import AppUnpairSensor from './content/appSupport/appUnpairSensor';
import AppFaqs from './content/appSupport/appFaqs';
import AppContainerSettings from './content/appSupport/appContainerSettings';
import NavOverview from './content/navigationSupport/navOverview';
import EquipmentInstallation from './content/sensorSupport/equipmentInstallation';
import PhysicalInstallation from './content/sensorSupport/physicalInstallation';
import Maintenance from './content/sensorSupport/maintenance';
import TechnicalSpecifications from './content/sensorSupport/technicalSpecifications';
import FaqsSensor from './content/sensorSupport/faqsSensor';

const navData = [
  {
    name: 'platform',
    label: <FormattedMessage id="platform" defaultMessage="Platform" />,
    tabLabel: <FormattedMessage id="platform_support" defaultMessage="Platform support" />,
    routeLink: '/platform',
    image: PlatformSupport,
    children: [
      {
        name: 'platform.overview',
        label: <FormattedMessage id="overview" defaultMessage="Overview" />,
        routeLink: '/platform-overview',
        component: PlatformOverview,
        keyWords: ['platform overview'],
      },
      {
        name: 'platform.dashboard',
        label: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
        routeLink: '/platform-dashboard',
        component: PlatformDashbord,
        keyWords: ['change the default map', 'meaning of colorured dots'],
      },
      {
        name: 'platform.containers',
        label: <FormattedMessage id="containers" defaultMessage="Containers" />,
        routeLink: '/platform-containers',
        component: PlatformContainer,
        keyWords: [
          'difference between a container and a container type',
          'add a new container type',
          'add a new waste fraction type',
          'view the details of a specific container',
          'filter the list of containers',
        ],
      },
      {
        name: 'platform.fleet_management',
        label: <FormattedMessage id="fleet_management" defaultMessage="Fleet management" />,
        routeLink: '/platform-fleet-management',
        component: PlatformFleetManagement,
        keyWords: ['fleet management'],
      },
      {
        name: 'platform.notifications',
        label: <FormattedMessage id="notifications" defaultMessage="Notifications" />,
        routeLink: '/platform-notifications',
        component: NotificationPlatform,
        keyWords: [
          'set up a new notification',
          'edit a notification',
          'delete notification',
          'send the notifications to multiple users',
          'create notifications',
        ],
      },
      {
        name: 'platform.analytics',
        label: <FormattedMessage id="analytics" defaultMessage="Analytics" />,
        routeLink: '/platform-analytics',
        keyWords: [],
      },
      {
        name: 'platform.user_management',
        label: <FormattedMessage id="user_management" defaultMessage="User management" />,
        routeLink: '/platform-user-management',
        component: UserManagementPlatform,
        keyWords: [
          'add a new user',
          'remove user',
          'edit the permissions or details of a user',
          'account types ',
        ],
      },
      {
        name: 'platform.routes',
        label: <FormattedMessage id="routes" defaultMessage="Routes" />,
        routeLink: '/platform-routes',
        keyWords: [],
      },
      {
        name: 'platform.settings',
        label: <FormattedMessage id="settings" defaultMessage="Settings" />,
        routeLink: '/platform-settings',
        component: PlatformSettings,
        keyWords: [
          'add a new project',
          'change the default map view on my dashboard',
          'edit a project',
          'delete a project',
          'edit company details',
          'add a logo for my company',
          'change the time zone',
          'change the time format',
          'change the metric ',
          'change to degrees Celsius or Fahrenheit',
          'add a new waste fraction type',
          'delete a waste fraction',
          'edit the settings for a waste fraction',
          'edit measurement settings',
          'delete a measurement setting',
          'choose the hours that the sensor will take measurements',
          'add a new user',
          'remove a user',
          'edit user permissions',
          'remove user',
          'forgot password',
        ],
      },
      {
        name: 'platform.faq',
        label: <FormattedMessage id="faqs" defaultMessage="FAQs" />,
        routeLink: '/platform-faq',
        keyWords: [],
      },
    ],
  },
  {
    name: 'app',
    label: <FormattedMessage id="app" defaultMessage="Mobile App" />,
    tabLabel: <FormattedMessage id="app_support" defaultMessage="App support" />,
    routeLink: '/app',
    image: AppSupport,
    children: [
      {
        name: 'app.overview',
        label: <FormattedMessage id="overview" defaultMessage="Overview" />,
        routeLink: '/app-overview',
        component: AppOverview,
        keyWords: ['app overview'],
      },
      {
        name: 'app.download_app',
        label: <FormattedMessage id="download_app" defaultMessage="Download WasteHero app" />,
        routeLink: '/app-download-app',
        component: AppDownload,
        keyWords: ['download app'],
      },
      {
        name: 'app.scan_activation',
        label: (
          <FormattedMessage
            id="scan_activation"
            defaultMessage="Scanning and activation of sensors"
          />
        ),
        routeLink: '/app-scan-activate',
        component: AppScanActivateSensor,
        keyWords: [
          'activate the sensor',
          'set up device with existing container',
          'register a device without a container',
        ],
      },
      {
        name: 'app.unpair',
        label: <FormattedMessage id="unpair_device" defaultMessage="Unpair sensor" />,
        routeLink: '/app-unpair',
        component: AppUnpairSensor,
        keyWords: ['unpair sensor'],
      },
      {
        name: 'app.contain_settings',
        label: (
          <FormattedMessage
            id="change_container_settings"
            defaultMessage="Change container settings"
          />
        ),
        routeLink: '/app-change-settings',
        component: AppContainerSettings,
        keyWords: ['change container settings'],
      },
      {
        name: 'app.faq',
        label: <FormattedMessage id="faqs" defaultMessage="FAQs" />,
        routeLink: '/app-faq',
        component: AppFaqs,
        keyWords: [
          'NFC enabled',
          'activate sensor',
          'dowload the Watehero Install app',
          'assign a sensor to container',
        ],
      },
    ],
  },
  {
    name: 'sensor',
    label: <FormattedMessage id="sensor" defaultMessage="Sensor" />,
    tabLabel: <FormattedMessage id="sensor_support" defaultMessage="Sensor support" />,
    routeLink: '/sensor',
    image: SensorSupport,
    children: [
      {
        name: 'sensor.overview',
        label: <FormattedMessage id="overview" defaultMessage="Overview" />,
        routeLink: '/sensor-overview',
        keyWords: [],
      },
      {
        name: 'sensor.equipment_install',
        label: (
          <FormattedMessage id="equipment_install" defaultMessage="Equipment for installation" />
        ),
        routeLink: '/sensor-equipment-install',
        component: EquipmentInstallation,
        keyWords: ['equipment for installation'],
      },
      {
        name: 'sensor.physical_install',
        label: (
          <FormattedMessage id="physical_install" defaultMessage="Physical installation manual" />
        ),
        routeLink: '/sensor-physical-install',
        component: PhysicalInstallation,
        keyWords: ['physical installation'],
      },
      {
        name: 'sensor.maintenance',
        label: <FormattedMessage id="maintenance" defaultMessage="Maintenance" />,
        routeLink: '/sensor-maintenance',
        component: Maintenance,
        keyWords: ['cleaning the sensors', 'data errors', 'surface cleaning'],
      },
      {
        name: 'sensor.tech_spec',
        label: <FormattedMessage id="tech_spec" defaultMessage="Technical specifications" />,
        routeLink: '/sensor-tech-spec',
        component: TechnicalSpecifications,
        keyWords: ['technical specifications'],
      },
      {
        name: 'sensor.faq',
        label: <FormattedMessage id="faqs" defaultMessage="FAQs" />,
        routeLink: '/sensor-faq',
        component: FaqsSensor,
        keyWords: ['clean sensor', 'install sensor'],
      },
    ],
  },
  {
    name: 'navigation',
    label: <FormattedMessage id="navigation" defaultMessage="Navigation" />,
    tabLabel: <FormattedMessage id="navigation_support" defaultMessage="Navigation support" />,
    routeLink: '/navigation',
    image: NavigationSupport,
    children: [
      {
        name: 'navigation.overview',
        label: <FormattedMessage id="overview" defaultMessage="Overview" />,
        routeLink: '/navigation-overview',
        component: NavOverview,
        keyWords: ['navigation overview'],
      },
      {
        name: 'navigation.settings',
        label: <FormattedMessage id="settings" defaultMessage="Settings" />,
        routeLink: '/navigation-settings',
        keyWords: [],
      },
      {
        name: 'navigation.how_to',
        label: <FormattedMessage id="how_to" defaultMessage="How to use" />,
        routeLink: '/navigation-how-to',
        keyWords: [],
      },
      {
        name: 'navigation.faq',
        label: <FormattedMessage id="faqs" defaultMessage="FAQs" />,
        routeLink: '/navigation-faq',
        keyWords: [],
      },
    ],
  },
];

export default navData;
