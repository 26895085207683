import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from './styles';
import reporInquirySchema from './schema';
import HeaderRow from '../../../../../shared/table/headerRow';
import {
  FormikSelect,
  FormikTextField,
  FormikDatePicker,
  FormikLocationInput,
} from '../../../../../shared/inputs/formik';
import FormikMultiImageDropzone from '../../../../../shared/inputs/formik/FormikMultiImageDropzone';
import FormikButtonSwitch from '../../../../../shared/inputs/formik/FormikButtonSwitch';
import SelectProject from '../../../../../shared/apiPopulatedSelects/selectProjects';
import { getSettingsForProject } from '../../../../../shared/utils/settings';

const reportInquiryQuery = loader(
  './../../../../../graphql/mutations/operation_management/report_inquiry.graphql',
);

const CASE_TYPE_VALUES = [
  {
    value: 'missorted',
    label: 'Misssorted waste',
  },
  {
    value: 'overflowing',
    label: 'Overflowing',
  },
  {
    value: 'damaged',
    label: 'Damaged',
  },
  {
    value: 'bulky waste',
    label: 'Bulky waste',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const ReportInquiry = ({ me }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [projectSettings, setProjectSettings] = useState('');

  const [createInquiry] = useMutation(reportInquiryQuery);

  const pageTitle = intl.formatMessage({
    id: 'report.inquiries',
    defaultMessage: 'Report Inquiry',
  });

  const handleSubmit = (values, setSubmitting) => {
    createInquiry({ variables: values }).then(() => setSubmitting(false));
  };

  const onProjectChange = useCallback((value) => {
    setProjectSettings(getSettingsForProject(value));
  }, []);

  const endAdornmentVolume = projectSettings.volumeVariant;
  const endAdornmentWeight = projectSettings.weightVariant;

  return (
    <Grid justify="center" className={classes.pageContainer}>
      <HeaderRow pageTitle={pageTitle} />
      <Formik
        initialValues={{
          priority: 'low',
          wasteVolume: '',
          wasteFraction: '',
          deadline: null,
          reporter: `${me.firstName} ${me.lastName}`,
          images: [],
        }}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        validationSchema={reporInquirySchema}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className={classNames(['w-100', classes.form])}>
            <Grid justify="center" className={classes.leftSide}>
              <Grid container direction="column" justify="space-between">
                <Grid item className={classes.fieldContainer}>
                  <FormikTextField
                    name="reporter"
                    type="text"
                    label={intl.formatMessage({
                      id: 'inquiries.reporter',
                      defaultMessage: 'Reporter',
                    })}
                  />
                </Grid>
                <Grid item className={classes.fieldContainer}>
                  <FormikSelect
                    name="caseType"
                    valuesList={CASE_TYPE_VALUES}
                    withoutNoneChoice="true"
                    required="true"
                    label={intl.formatMessage({
                      id: 'case_type',
                      defaultMessage: 'Case type',
                    })}
                    filledStyle
                    className={classes.fieldBackground}
                  />
                </Grid>
                <Grid item className={classes.fieldContainer}>
                  <FormikButtonSwitch
                    label={intl.formatMessage({
                      id: 'priority',
                      defaultMessage: 'Priority',
                    })}
                    name="priority"
                    buttonClass={classes.switchButtonClass}
                    buttonGroupClass={classes.switchbuttonGroupClass}
                    valuesList={[
                      {
                        value: 'critical',
                        label: intl.formatMessage({ id: 'critical', defaultMessage: 'Critical' }),
                      },
                      {
                        value: 'high',
                        label: intl.formatMessage({ id: 'high', defaultMessage: 'High' }),
                      },
                      {
                        value: 'medium',
                        label: intl.formatMessage({ id: 'medium', defaultMessage: 'Medium' }),
                      },
                      {
                        value: 'low',
                        label: intl.formatMessage({ id: 'low', defaultMessage: 'Low' }),
                      },
                    ]}
                  />
                </Grid>
                <Grid item className={classes.fieldContainer}>
                  <FormikDatePicker
                    name="deadline"
                    required="true"
                    autoOk="true"
                    disablePast="true"
                    label={intl.formatMessage({
                      id: 'deadline',
                      defaultMessage: 'deadline',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'report_inquiry.datepicker.placeholder',
                      defaultMessage: 'Select a deadline.',
                    })}
                  />
                </Grid>
                <Grid>
                  <FormikLocationInput />
                </Grid>
              </Grid>
            </Grid>
            <Grid justify="center" className={classes.rightSide}>
              <Grid>
                <Grid item className={classes.fieldContainer}>
                  <SelectProject
                    classes={classNames(classes.selectBackground)}
                    onChange={onProjectChange}
                  />
                </Grid>
                <div className={classes.uploadPhotoContainer}>
                  <FormikTextField
                    name="comment"
                    type="text"
                    multiline
                    rows={5}
                    label={intl.formatMessage({
                      id: 'comment',
                      defaultMessage: 'Comment',
                    })}
                    className={classes.fieldBackground}
                  />
                  <FormikMultiImageDropzone
                    name="images"
                    imageContainerClass={classes.imageContainerClass}
                    containerClass={classes.containerClass}
                  />
                </div>
                <Divider classes={{ root: classes.divider }} />
                <Grid item className={classes.fieldContainer}>
                  <FormikTextField
                    name="wasteVolume"
                    type="text"
                    label={intl.formatMessage({
                      id: 'waste_volume_estimate',
                      defaultMessage: 'Waste volume estimate',
                    })}
                    className={classes.fieldBackground}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{endAdornmentVolume}</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item className={classes.fieldContainer}>
                  <FormikTextField
                    name="wasteWeight"
                    type="text"
                    label={intl.formatMessage({
                      id: 'waste_weight_estimate',
                      defaultMessage: 'Waste weight estimate',
                    })}
                    className={classes.fieldBackground}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{endAdornmentWeight}</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid justify="flex-end" container>
                <Button
                  data-testid="submit-login-button"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.submitBtn}
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  <FormattedMessage id="create" defaultMessage="Create" />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

ReportInquiry.propTypes = {
  me: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(ReportInquiry);
