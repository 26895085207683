import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { fixedPickupsPeriods } from '../shared/utils';
import { daysOptions } from '../../../settings/project/defaults/data';
import { messageWrongCoords } from '../../../../../shared/utils';

const registerContainerSchema = () =>
  Yup.object().shape({
    containerId: Yup.string()
      .required()
      .max(
        32,
        <FormattedMessage
          id="container.register.validation.container_id"
          defaultMessage="This field cannot exceed 32 characters in length"
        />,
      ),
    description: Yup.string(),
    showOnRoute: Yup.bool(),
    latitude: Yup.number()
      .required()
      .min(-90, messageWrongCoords(90))
      .max(90, messageWrongCoords(90))
      .notOneOf([0], 'This field is required'),
    longitude: Yup.number()
      .required()
      .min(-180, messageWrongCoords(180))
      .max(180, messageWrongCoords(180))
      .notOneOf([0], 'This field is required'),
    selectedProject: Yup.string().required(),
    selectedContainerType: Yup.string().required(),
    selectedWasteFraction: Yup.string().required(),
    containerType: Yup.object().shape({
      height: Yup.number().required(),
      isCylindrical: Yup.bool(),
      width: Yup.number().nullable().when('isCylindrical', {
        is: false,
        then: Yup.number().required(),
      }),
      length: Yup.number().nullable().when('isCylindrical', {
        is: false,
        then: Yup.number().required(),
      }),
      diameter: Yup.number().nullable().when('isCylindrical', {
        is: true,
        then: Yup.number().required(),
      }),
    }),
    attachedDevices: Yup.array().when('containerType.height', (height, schema) =>
      schema.of(
        Yup.object().shape({
          deviceId: Yup.string().required(),
          lid: Yup.string().required(),
          internalHeight: Yup.number()
            .required()
            .when('offset', (offset, heightSchema) =>
              heightSchema.max(
                height,
                <FormattedMessage
                  id="validation.internalHeight"
                  defaultMessage="Should be less or equal to height"
                  description="Validation of internal height"
                />,
              ),
            ),
          offset: Yup.number()
            .positive()
            .min(
              0.0405,
              <FormattedMessage
                id="validation.offset_min_value"
                defaultMessage="Sensor offset must be greater than or equal to 0.0405"
              />,
            )
            .max(
              height,
              <FormattedMessage
                id="validation.internal_offset"
                defaultMessage="Should be less then or equal to height"
                description="Validation of internal height"
              />,
            )
            .required(),
          deviceAngle: Yup.number().nullable().when('lid', {
            is: 'side',
            then: Yup.number().required(),
          }),
        }),
      ),
    ),

    pickupMethod: Yup.string().required(),
    containerStatus: Yup.string().required(),
    emptyingIntervalFrom: Yup.mixed().time(),
    emptyingIntervalTo: Yup.mixed().time().timeGreaterThen('emptyingIntervalFrom'),
    excludeDays: Yup.array().of(Yup.string()),
    fixedPickupsPeriod: Yup.number(),
    amountDaysBetweenPickups: Yup.mixed().when('fixedPickupsPeriod', {
      is: fixedPickupsPeriods.indexOf('days'),
      then: Yup.number()
        .required()
        .max(
          50,
          <FormattedMessage
            id="validation.maxValue"
            defaultMessage="Should be less then {max}"
            description="Validate max value"
            values={{
              max: 50,
            }}
          />,
        )
        .min(
          1,
          <FormattedMessage
            id="validation.minValue"
            defaultMessage="Should be more then {min}"
            description="Validate min value"
            values={{
              min: 1,
            }}
          />,
        ),
    }),
    pickupInterval: Yup.string().when('fixedPickupsPeriod', {
      is: fixedPickupsPeriods.indexOf('flexible'),
      then: Yup.string().required(),
    }),
    allowedHoursFrom: Yup.mixed()
      .time()
      .when('fixedPickupsPeriod', {
        is: (fixedPickup) =>
          fixedPickup === fixedPickupsPeriods.indexOf('days') ||
          fixedPickup === fixedPickupsPeriods.indexOf('flexible'),
        then: Yup.mixed().time().required(),
      }),
    allowedHoursTo: Yup.mixed()
      .time()
      .when('fixedPickupsPeriod', {
        is: (fixedPickups) =>
          fixedPickups === fixedPickupsPeriods.indexOf('days') ||
          fixedPickups === fixedPickupsPeriods.indexOf('flexible'),
        then: Yup.mixed().time().timeGreaterThen('allowedHoursFrom').required(),
      }),
    pickupRepeatPeriod: Yup.number().when('fixedPickupsPeriod', {
      is: fixedPickupsPeriods.indexOf('week'),
      then: Yup.number()
        .required()
        .max(
          52,
          <FormattedMessage
            id="validation.maxValue"
            defaultMessage="Should be less then {max}"
            description="Validate max value"
            values={{
              max: 52,
            }}
          />,
        )
        .min(
          1,
          <FormattedMessage
            id="validation.minValue"
            defaultMessage="Should be more then {min}"
            description="Validate min value"
            values={{
              min: 1,
            }}
          />,
        ),
    }),
    minimumDaysBetweenPickup: Yup.number().when('fixedPickupsPeriod', {
      is: fixedPickupsPeriods.indexOf('flexible'),
      then: Yup.number().required(),
    }),
    placeId: Yup.string(),
    pickupDay: Yup.object().shape(
      daysOptions.reduce(
        (acc, { value }) => ({
          ...acc,
          [value]: Yup.object().shape({
            collect: Yup.bool().required(),
            timeFrom: Yup.mixed().time().when('collect', {
              is: true,
              then: Yup.mixed().time().required(),
            }),
            timeTo: Yup.mixed().when('collect', {
              is: true,
              then: Yup.mixed().time().timeGreaterThen('timeFrom').required(),
            }),
          }),
        }),
        {},
      ),
    ),
  });

export default registerContainerSchema;
