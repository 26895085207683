import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { StyledLabel } from './helpBar';

export const StyledTableRow = withStyles(() => ({
  head: {
    height: '24px !important',
  },
  root: {
    height: 24,
  },
}))(TableRow);

export const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 'none',
    fontSize: 11,
    color: '#939393',
  },
  body: {
    color: theme.variables.cTextDark,
    fontSize: 12,
  },
  root: {
    height: 24,
  },
}))(TableCell);

export const StyledTableHead = withStyles(() => ({
  root: {
    background: '#f0f2f7',
  },
}))(TableHead);

export const getColor = (inverse = false) => (value) => {
  if (value > 0 && value < 25) {
    return inverse ? 'red' : 'green';
  }
  if (value >= 25 && value < 50) {
    return inverse ? 'orange' : 'yellow';
  }
  if (value >= 50 && value < 75) {
    return inverse ? 'yellow' : 'orange';
  }
  if (value >= 75) {
    return inverse ? 'green' : 'red';
  }
  return 'disabled';
};

export const TableCellRenderer = (getColorRepr) => (cellData) => {
  let value = cellData || 0;
  if (typeof value === 'object') {
    value = value.value;
  }

  return (
    <StyledLabel value={`${value !== '-' ? value.toFixed(0) : '-'}%`} color={getColorRepr(value)} />
  );
};
