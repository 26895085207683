import React, { useCallback, useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import NotLoginDefaultPageLayout from '../layout/notLoginDefaultPageLayout';
import FormikMultiImageDropzone from '../shared/inputs/formik/FormikMultiImageDropzone';
import { toastifyError } from '../shared/utils';

const isDriverQuery = loader('./../graphql/queries/fleet_management/is_driver.graphql');
const uploadImagesForInquiry = loader(
  './../graphql/mutations/operation_management/upload_images_for_inquiriy.graphql',
);

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: 22,
    color: theme.variables.cCharcoal,
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const UploadImagesForInquiries = ({ match, history }) => {
  const classes = useStyles();
  const { params: { driverId, inquiryId } = {} } = match;

  const redirect = useCallback(() => {
    toast.error('Broken upload url');
    history.push('/login');
  }, [history]);

  const { loading, data: { isDriver = {} } = {} } = useQuery(isDriverQuery, {
    variables: { driverId },
    onError: redirect,
  });

  useEffect(() => {
    if (!isDriver) {
      redirect();
    }
  }, [isDriver, redirect]);

  const [upload] = useMutation(uploadImagesForInquiry);

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      upload({
        variables: {
          inquiryId,
          driverId,
          images: values.images.map(({ file }) => file),
        },
      })
        .catch((error) => {
          toastifyError(error);
        })
        .finally(() => setSubmitting(false));
    },
    [driverId, inquiryId, upload],
  );

  return (
    <NotLoginDefaultPageLayout loading={loading}>
      <Grid container justify="center" alignItems="center" className="h-100">
        <Formik
          initialValues={{
            images: [],
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, submitForm }) => (
            <Form className={classNames([classes.form, 'h-100 w-100'])}>
              <div>
                <Grid className={classNames(['upload-photo-text'])}>
                  <Typography variant="h5" className={classes.titleText}>
                    {isDriver && isDriver.firstName && (
                      <FormattedMessage
                        id="Here you can upload images for your inqueries!"
                        defaultMessage="Here you can upload images for your inqueries!"
                        values={isDriver}
                      />
                    )}
                  </Typography>
                </Grid>
                <FormikMultiImageDropzone name="images" />
              </div>
              <Grid container justify="center" className="upload-photo-button">
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => submitForm()}
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="button.upload" defaultMessage="Upload" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </NotLoginDefaultPageLayout>
  );
};

UploadImagesForInquiries.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default UploadImagesForInquiries;
