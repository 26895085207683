import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ContainerIcon } from '../../../../../images/icons/containers.svg';
import { ReactComponent as PinIcon } from '../../../../../images/icons/pin.svg';
import { ReactComponent as ColoredContainerIcon } from '../../../../../images/icons/container.svg';
import { ReactComponent as WasteStation } from '../../../../../images/icons/baseline-home-24px.svg';
import { ReactComponent as IconArrowUp } from '../../../../../images/icons/arrowup.svg';
import SmallHistoryChart from '../../../charts/lineChart';
import {
  getDate,
  getMeasurementsFromContainer,
  getWasteFractionFromContainer,
} from '../../../utils';
import { getColorName } from '../../utils';
import { getFillIndicatorColor } from '../../../../main/routes/containers/routes/containers-map/utils';
import history from '../../../../../history';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: '0.8rem',
    width: '0.8rem',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  open: {
    transform: 'rotate(-90deg)',
  },
  closed: {
    transform: 'rotate(90deg)',
  },
}));

const MarkerWithModal = ({
  container,
  onSelectContainer,
  isActive,
  noModal,
  handleSetSelectedContainerFromDashboard,
}) => {
  const classes = useStyles();
  const { id: containerId } = container;

  const [showPopup, setShowPopup] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showHistoryButton, setShowHistoryButton] = useState(false);
  const [logData, setLogData] = useState(null);
  const [lastLog, setLastLog] = useState(null);

  useEffect(() => {
    const [measurements] = getMeasurementsFromContainer(container);
    if (measurements.length) {
      setLogData(
        measurements.map(({ node: { fillPercentage: fillLevel, createdAt } = {} } = {}) => ({
          fillLevel,
          emptied: getDate(createdAt),
        })),
      );
      setShowHistoryButton(true);
      setLastLog(getDate(measurements[measurements.length - 1].node.createdAt));
    }
  }, [container]);

  const toggleHistorySection = () => {
    setShowHistory((oldShowHistory) => !oldShowHistory);
  };

  const changeShowPopup = () => {
    if (!noModal) {
      setShowPopup((oldShowPopup) => !oldShowPopup);
    }
  };

  if (containerId) {
    const fillPercentage = container?.measurement
      ? Math.round((container?.measurement?.fillPercentage || 0) * 100) / 100
      : undefined;

    const colorCode = getFillIndicatorColor(fillPercentage);
    const color = getColorName(fillPercentage);

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={classNames('container-icon-inside', `status-${color}`, isActive ? 'active' : '')}
        onClick={() => onSelectContainer(container)}
        onMouseEnter={changeShowPopup}
        onMouseLeave={changeShowPopup}
      >
        <div className="icon-holder clickable">
          <ContainerIcon className="icon" />
        </div>

        {showPopup && (
          <Paper
            className="containerHover"
            style={{
              left: '30px',
              top: '-40px',
            }}
          >
            <div className={classNames('containerHeading', !showHistory && 'short')}>
              <PinIcon className="p-r-10 pinIcon" />
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="containerSectionWrapper"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className="containerSectionArrow" onClick={() => onSelectContainer(container)} />
              <div className="containerSection">
                <div className={classNames('containerBodyHeader', `status-${color}`)}>
                  <SvgIcon component={ColoredContainerIcon} style={{ color: colorCode }} />
                  <CircularProgress
                    className="dashProgress"
                    variant="determinate"
                    value={fillPercentage}
                  />
                  <span>
                    <FormattedMessage id="fill_level" defaultMessage="Fill level" />
                    <br />
                    {fillPercentage ? `${fillPercentage}%` : '-'}
                  </span>
                </div>
                <div className="containerBodyInfo">
                  <div>
                    <span>
                      <FormattedMessage id="fraction" defaultMessage="Fraction" />:
                    </span>
                    <span>
                      <FormattedMessage id="bin_size" defaultMessage="Bin size" />:
                    </span>
                    <span>
                      <FormattedMessage id="bin_id" defaultMessage="Bin ID" />:
                    </span>
                    <span>
                      <FormattedMessage id="emptied" defaultMessage="Emptied" />:
                    </span>
                  </div>
                  <div>
                    <span>
                      {getWasteFractionFromContainer(container) || (
                        <FormattedMessage id="no_data" defaultMessage="no data" />
                      )}
                    </span>
                    <span>
                      {container?.containerType?.volume}
                      &nbsp; m³
                    </span>
                    <span>{container?.containerId || '-'}</span>
                    <span>{lastLog || '-'}</span>
                  </div>
                </div>
                {showHistoryButton ? (
                  <Button
                    className="button"
                    onClick={toggleHistorySection}
                    endIcon={
                      <IconArrowUp
                        className={classNames(classes.icon, {
                          [classes.open]: showHistory,
                          [classes.closed]: !showHistory,
                        })}
                      />
                    }
                  >
                    <FormattedMessage id="show_more" defaultMessage="Show more" />
                  </Button>
                ) : (
                  <Button
                    className="button button-orange"
                    style={{ borderRadius: 32.5 }}
                    onClick={() => {
                      handleSetSelectedContainerFromDashboard(container);
                      history.push('/app/containers');
                    }}
                  >
                    <FormattedMessage id="detailed" defaultMessage="Detailed" />
                  </Button>
                )}
              </div>
              {showHistoryButton && showHistory && (
                <div className="containerSection containerSectionChart">
                  <div className="containerChart">
                    <SmallHistoryChart data={logData} responsive />
                  </div>
                  <Link
                    to={`/app/containers/${container.id ? `${container.id}` : ''}`}
                    className="button button-orange"
                    style={{ borderRadius: 32.5 }}
                  >
                    <FormattedMessage id="detailed" defaultMessage="Detailed" />
                  </Link>
                </div>
              )}
            </div>
          </Paper>
        )}
      </div>
    );
  }
  return (
    <div
      className={classNames('container-icon-inside blue', isActive ? 'active' : '')}
      onMouseEnter={changeShowPopup}
      onMouseLeave={changeShowPopup}
    >
      <div className="icon-holder clickable">
        <SvgIcon component={WasteStation} className="icon" />
      </div>
    </div>
  );
};

MarkerWithModal.propTypes = {
  onSelectContainer: PropTypes.func,
  noModal: PropTypes.bool,
  isActive: PropTypes.bool,
  container: PropTypes.shape({
    id: PropTypes.string,
    containerId: PropTypes.string,
    location: PropTypes.shape({
      address: PropTypes.string,
    }),
    containerType: PropTypes.shape({
      volume: PropTypes.number,
    }),
    measurement: PropTypes.shape({
      fillPercentage: PropTypes.number,
    }),
  }),
  handleSetSelectedContainerFromDashboard: PropTypes.func.isRequired,
};

MarkerWithModal.defaultProps = {
  onSelectContainer: () => {},
  container: {},
  noModal: false,
  isActive: false,
};

export default MarkerWithModal;
