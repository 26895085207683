import PropTypes from 'prop-types';

export const ContainerTypesType = PropTypes.shape({
  diameter: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  length: PropTypes.number,
  volume: PropTypes.number,
  value: PropTypes.string,
  projectId: PropTypes.string,
  isCylindrical: PropTypes.bool,
  label: PropTypes.string,
});

export const FillLevelDevicesType = PropTypes.shape({
  devId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
});

export const WasteFractionsType = PropTypes.shape({
  projectId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
});

export const FormInitialValues = PropTypes.shape({
  containerId: PropTypes.string,
  containerPhotoUrl: PropTypes.string,
  description: PropTypes.string,
  isCylindrical: PropTypes.bool,
  diameter: PropTypes.number,
  height: PropTypes.number,
  length: PropTypes.number,
  width: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  selectedWasteFraction: PropTypes.string,
  selectedProject: PropTypes.string,
  selectedContainerType: PropTypes.string,
});

export const ProjectTypes = PropTypes.shape({
  companyId: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  containerTypes: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: ContainerTypesType,
      }),
    ),
  }),
  wasteFractions: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: WasteFractionsType,
      }),
    ),
  }),
});

export const SelectType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const ContainerType = PropTypes.shape({
  companyId: PropTypes.string,
  label: PropTypes.element,
  value: PropTypes.string,
});

export const DeviceType = PropTypes.shape({
  devId: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
});
