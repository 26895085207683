const styles = (theme) => ({
  inputColumn: {
    padding: theme.spacing(0, 4),
  },
  roundedBtn: {
    borderRadius: 32.5,
  },
  submitBtn: {
    '& .MuiTypography-root': {
      textTransform: 'none',
    },
  },
  tooltipContainer: {
    width: '100%',
    '& > .MuiTextField-root': {
      width: '100%',
    },
    '& > .MuiFormControlLabel-root': {
      alignItems: 'flex-end',
    },
  },
  horizontalSpan: {
    display: 'inline-flex',
  },
  label: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 'bold',
  },
  uploadButtonWrapper: {
    paddingTop: '0 !important',
  },
  uploadButton: {
    width: '100%',
    textTransform: 'none',
    backgroundColor: theme.variables.cWhite,
    height: 45,
    borderRadius: 22,
    color: theme.variables.buttonBlue,
    border: `1px solid ${theme.variables.buttonBlue}`,
  },
  maskedInput: {
    width: 140,
    '& .MuiInput-root': {
      marginTop: 0,
    },
    '& .MuiInput-input': {
      textAlign: 'center',
    },
  },
  disabledText: {
    fontWeight: 400,
    lineHeight: '1.5rem',
    color: '#9aa8ad',
    fontSize: '1rem',
    padding: '0 16px 16px 16px',
  },
});

export default styles;
