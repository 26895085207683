import React from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { setUserLayoutConfig } from '../../../actions';
import { toastifyError } from '../utils';

const updateUserLayoutConfig = loader(
  './../../graphql/mutations/fleet_management/update_user_layout_config.graphql',
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const withUserLayoutConfig = (pageName) => (Wrapped) => {
  const mapStateToProps = (state) => ({
    userLayoutConfig: ((name) => ({
      userId: state.settings.user.id,
      order: state.settings.userLayoutConfig[`${name}Order`],
      orderBy: state.settings.userLayoutConfig[`${name}OrderBy`],
      iconsSize: state.settings.userLayoutConfig[`${name}IconsSize`],
      pageSize: state.settings.userLayoutConfig[`${name}PageSize`],
      disabledColumns: state.settings.userLayoutConfig[`${name}DisabledColumns`],
    }))(pageName),
  });

  const mapDispatchToProps = (dispatch) => ({
    dispatchConfigChange: (userLayoutConfig) => {
      dispatch(setUserLayoutConfig(userLayoutConfig));
    },
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(({ userLayoutConfig, dispatchConfigChange, ...props }) => {
    const [updateLayout] = useMutation(updateUserLayoutConfig);

    const { userId, ...otherLayoutConfig } = userLayoutConfig;

    const handleConfigChange = (newUserConfig) => {
      updateLayout({
        variables: {
          userId,
          [`${pageName}Order`]: newUserConfig.order,
          [`${pageName}OrderBy`]: newUserConfig.orderBy,
          [`${pageName}PageSize`]: newUserConfig.pageSize,
          [`${pageName}IconsSize`]: newUserConfig.iconsSize,
          [`${pageName}DisabledColumns`]: newUserConfig.disabledColumns,
        },
      })
        .then(() => {
          dispatchConfigChange(
            Reflect.ownKeys(newUserConfig).reduce(
              (acc, fieldName) => ({
                ...acc,
                [`${pageName}${capitalizeFirstLetter(fieldName)}`]: newUserConfig[fieldName],
              }),
              {},
            ),
          );
        })
        .catch(toastifyError);
    };

    return (
      <Wrapped
        {...props}
        handleConfigChange={handleConfigChange}
        userLayoutConfig={otherLayoutConfig}
      />
    );
  });
};

export default withUserLayoutConfig;
