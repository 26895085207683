import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: '100%',
  },
  leftSide: {
    backgroundColor: theme.variables.cWhite,
    padding: '20px 70px 40px 70px',
    width: '50%',
  },
  rightSide: {
    backgroundColor: theme.variables.VHGreyBackground,
    padding: '20px 70px 40px 70px',
    width: '50%',
  },
  form: {
    display: 'flex',
    height: '100%',
  },
  fieldContainer: {
    marginBottom: 16,
    textTransform: 'capitalize',
  },
  divider: {
    backgroundColor: theme.variables.VHGreyBackground,
    marginBottom: 24,
    marginTop: 16,
  },
  uploadPhotoContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainerClass: {
    maxWidth: '136px !important',
    flexBasis: '100% !important',
    height: '136px !important',
    width: '136px !important',
    marginRight: '0 !important',
  },
  containerClass: {
    paddingRight: 0,
    width: 'inherit',
  },
  switchButtonClass: {
    width: '25%',
  },
  switchbuttonGroupClass: {
    width: '100%',
  },
  submitBtn: {
    borderRadius: 32.5,
    textTransform: 'capitalize',
    fontSize: 18,
    lineHeight: '22px',
    padding: '12px 24px',
  },
  fieldBackground: {
    '& > .MuiFormControl-root': {
      '& > .MuiInput-formControl': {
        backgroundColor: 'transparent',
      },
    },
  },
  selectBackground: {
    '& .MuiFormControl-root': {
      '& > .MuiInput-root': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

export default useStyles;
