import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Typography, Link } from '@material-ui/core';
import useStyles from '../../shared/styles';
import StepsContainer from '../../shared/stepsContainer';
import SupportTextPage from '../../shared/supportTextPage';
import StepOneImage from '../../../../../../images/support/app_support/container_settings/step_1.png';
import StepTwoImage from '../../../../../../images/support/app_support/container_settings/step_2.png';
import StepThreeImage from '../../../../../../images/support/app_support/container_settings/step_3.png';
import StepFourImage from '../../../../../../images/support/app_support/container_settings/step_4.png';
import StepFiveImage from '../../../../../../images/support/app_support/container_settings/step_5.png';
import StepSixImage from '../../../../../../images/support/app_support/container_settings/step_6.png';
import ImageContainer from './components/imageContainer';
import QrCode from '../../../../../../images/support/app_support/logos/QR_Code_App_Install.png';

const AppContainerSettings = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container spacing={1} className={classes.root}>
      {/* pageTitle and top content */}

      <SupportTextPage
        title={
          <FormattedMessage
            id="app_container_settings_title"
            defaultMessage="Change Container Settings"
          />
        }
        texts={[
          <FormattedMessage
            id="app_container_settings_content_1"
            defaultMessage="You can change settings of a container, including the location, waste fraction, container type and container dimensions, using the WasteHero Installation app. Here’s how:"
          />,
        ]}
      />

      {/* Step 1 */}
      <StepsContainer
        number={1}
        subheader={
          <FormattedMessage
            id="app_container_settings_step_1_subTitle"
            defaultMessage="Open the WasteHero Installation App."
          />
        }
        image={StepOneImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_1_body1_subTitle"
            defaultMessage="Haven’t downloaded the app? Download the smartphone app from the <appstoreLink>Apple Store</appstoreLink> or <playstoreLink>Google Play</playstoreLink>."
            values={{
              appstoreLink: (text) => (
                <Link
                  className={classes.links}
                  href="https://apps.apple.com/au/app/wastehero-installed/id1498769501"
                  target="_blank"
                >
                  {text}
                </Link>
              ),
              playstoreLink: (text) => (
                <Link
                  className={classes.links}
                  href="https://play.google.com/store/apps/details?id=com.wastehero_mobileapp_installation"
                  target="_blank"
                >
                  {text}
                </Link>
              ),
            }}
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_scanactivate_content_step_1_content_2"
            defaultMessage="Alternatively, scan this QR code from your smartphone:"
          />
        </Typography>
        <ImageContainer>
          <img
            src={QrCode}
            alt={intl.formatMessage({ id: 'qr.code', defaultMessage: 'QR Code' })}
          />
        </ImageContainer>
      </StepsContainer>

      {/* Step 2 */}
      <StepsContainer
        number={2}
        subheader={
          <FormattedMessage
            id="app_container_settings_step_2_subTitle"
            defaultMessage="Login with your platform credentials"
          />
        }
        image={StepTwoImage}
      >
        <Typography className={classes.textBold} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_2_content_1"
            defaultMessage="Username"
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_2_content_2"
            defaultMessage="The email address you used to create your account on the platform, e.g. yourname@company.com"
          />
        </Typography>
        <Typography className={classes.textBold} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_2_content_3"
            defaultMessage="Password"
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_2_content_4"
            defaultMessage="Type your password"
          />
        </Typography>
      </StepsContainer>

      {/* Step 3 */}

      <StepsContainer
        number={3}
        subheader={
          <FormattedMessage
            id="app_container_settings_step_3_subTitle"
            defaultMessage="Select Project"
          />
        }
        image={StepThreeImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_3_content_1"
            defaultMessage="Select your <bold>Project</bold> and hit <bold>Apply</bold>."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
      </StepsContainer>

      {/* Step 4 */}
      <StepsContainer
        number={4}
        subheader={
          <FormattedMessage
            id="app_container_settings_step_4_subTitle"
            defaultMessage="Container Settings"
          />
        }
        image={StepFourImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_4_content_1"
            defaultMessage="Select <bold>Change container settings</bold>."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
      </StepsContainer>

      {/* Step 5 */}
      <StepsContainer
        number={5}
        subheader={
          <FormattedMessage
            id="app_container_settings_step_5_subTitle"
            defaultMessage="Select the container"
          />
        }
        image={StepFiveImage}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_5_content_1"
            defaultMessage="Choose the container you wish to edit from the list or search by container ID."
          />
        </Typography>
      </StepsContainer>

      {/* Step 6 */}
      <StepsContainer
        number={6}
        subheader={
          <FormattedMessage
            id="app_container_settings_step_6_subTitle"
            defaultMessage="Edit container settings"
          />
        }
        image={StepSixImage}
        checkLast
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_6_content_1"
            defaultMessage="Here you can change the container’s settings. You can edit the container ID, description, location, waste fraction, container type, dimensions and photo."
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="app_container_settings_step_6_content_2"
            defaultMessage="Make your desired changes and click <bold>Update</bold> to save your changes."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
      </StepsContainer>
    </Grid>
  );
};

export default AppContainerSettings;
