import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loader } from 'graphql.macro';
import { Space } from 'antd';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import Drawer from '@wastehero/storybook/lib/components/Drawer/Drawers/Routes';
import TableView from '@wastehero/storybook/lib/components/Views/Table';
import Button from '@wastehero/storybook/lib/components/Form/Elements/Button';
import { useMutation } from '@apollo/client';
import { getSavedActiveProjects } from '../../../../../shared/utils';
import { getMapCenter } from '../../../../../shared/utils/settings';

// eslint-disable-next-line no-unused-vars
const allRoutesQuery = loader(
  './../../../../../graphql/queries/operation_management/all_routes.graphql',
);

const drawerQuery = loader('./../../../../../graphql/queries/operation_management/route.graphql');
const drawerRouteGeoJsonQuery = loader(
  './../../../../../graphql/queries/operation_management/route_geo_json.graphql',
);
const generateRoutesMutation = loader(
  './../../../../../graphql/mutations/operation_management/generate_routes.graphql',
);

const Routes = ({ history }) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const mapCenter = useSelector((state) => getMapCenter(state));
  const [drawer, setDrawer] = useState('');
  const [generateRoutes] = useMutation(generateRoutesMutation);

  const columns = [
    {
      title: 'ID',
      dataIndex: ['node', 'id'],
      key: 'id',
    },
    {
      title: 'Project',
      dataIndex: ['node', 'project', 'id'],
      key: 'project',
      sorter: true,
    },
    {
      title: 'Vehicle',
      dataIndex: ['node', 'vehicle', 'id'],
      key: 'vehicle',
      sorter: true,
      render: (text, record) => (
        <Link to={`/app/fleet-management/collector-vehicle/${record.node.vehicle.id}`}>{text}</Link>
      ),
    },
    {
      title: 'Driver',
      dataIndex: ['node', 'driver', 'id'],
      key: 'driver',
      sorter: true,
      render: (text, record) => (
        <Link to={`/app/fleet-management/driver-management/${record.node.driver.id}`}>
          {record.node.driver.user.firstName} {record.node.driver.user.lastName}
        </Link>
      ),
    },
    {
      title: 'Date',
      dataIndex: ['node', 'scheduledDay'],
      key: 'date',
      sorter: true,
    },
    {
      title: 'Stops',
      dataIndex: ['node', 'stopsCount'],
      key: 'stops',
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button title="View" size="small" onClick={() => setDrawer(record.node.id)} />
        </Space>
      ),
    },
  ];

  const breadcrumbBar = {
    title: 'Routes',
    createButtonTitle: 'Create Route',
    createButtonAction: () => history.push('/app/operation-management/routes/create'),
    createButtonActions: [
      {
        label: 'Generate routes',
        action: () => generateRoutes({ variables: { projectIds: savedActiveProjects.split(',') } }),
      },
    ],
    breadcrumbsLinks: [
      {
        label: 'Operation Management',
        path: '/route1',
      },
      {
        label: 'Routes',
        path: '/route2',
      },
    ],
  };

  const queryVariablesFormatter = (queryVariables, filterValues, pagination, filters, sorter) => {
    const sortBy = {};
    const dataPagination = { pageSize: 10 };
    const dateRange = filterValues.find((filter) => filter.key === 'dateRange');
    const dateFilter = dateRange
      ? { From: dateRange.value.end.toDateString(), To: dateRange.value.start.toDateString() }
      : {};

    if (sorter) {
      sortBy.orderBy = sorter.columnKey;

      if (sorter.order) {
        sortBy.order = sorter.order === 'descend' ? 'desc' : 'asc';
      }
    }

    if (pagination) {
      dataPagination.pageSize = pagination.pageSize;
      dataPagination.offset = pagination.pageSize * (pagination.current - 1);
    }

    return {
      ...queryVariables,
      ...sortBy,
      ...dataPagination,
      ...dateFilter,
    };
  };

  const queryResultFormatter = (values) => ({
    data: values?.allRoutes.edges || [],
    pagination: {
      total: values?.allRoutes.totalCount,
    },
  });

  const queryVariables = {
    activeProjects: savedActiveProjects,
  };

  const tableProps = {
    antdTableProps: {
      columns,
      rowKey: (record) => record.node.id,
    },
  };

  const drawerQueryVariablesFormatter = (qvs) => ({ ...qvs });

  const drawerQueryVariables = {
    id: drawer,
  };

  const queryTableProps = {
    query: allRoutesQuery,
    queryResultFormatter,
    queryVariablesFormatter,
    queryVariables,
    tableProps,
  };
  return (
    <>
      <TableView queryTableProps={queryTableProps} breadcrumbBar={breadcrumbBar} hasFilterBar />
      ;
      <Drawer
        visible={!!drawer}
        onClose={() => setDrawer('')}
        query={drawerQuery}
        queryVariablesFormatter={drawerQueryVariablesFormatter}
        queryVariables={drawerQueryVariables}
        queryGeoJson={drawerRouteGeoJsonQuery}
        mapBoxProps={{
          center: {
            longitude: mapCenter.lng,
            latitude: mapCenter.lat,
          },
        }}
      />
    </>
  );
};

Routes.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(Routes);
