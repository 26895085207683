import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import ReactRouterPropTypes from 'react-router-prop-types';
import { toast } from 'react-toastify';
import { loader } from 'graphql.macro';
import HeaderRow from '../../components/headerRow';
import VehicleTypeForm from './form';
import { toastifyError } from '../../../../../shared/utils';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import vehicleTypeEditValidationSchema from './schema';
import { actionHandler } from '../../../../../shared/buttons/submitButtons';
import { TYPES_CHOICES_VALUES } from '../../components/utils';

const vehicleTypeQuery = loader(
  './../../../../../graphql/queries/fleet_management/vehicle_type.graphql',
);
const updateOrCreateVehicleTypeMutation = loader(
  './../../../../../graphql/mutations/fleet_management/update_or_create_vehicle_type.graphql',
);
const allVehicleTypesQuery = loader(
  './../../../../../graphql/queries/fleet_management/all_vehicle_types.graphql',
);

const defaultInitialValues = {
  name: '',
  crane: '',
  body: '',
  liftingMechanization: '',
  bodyMechanization: '',
  loadingLocation: '',
  compactor: '',
  action: '',
  Type: '',
};

const vehicleSerializer = ({
  body,
  bodyMechanization,
  liftingMechanization,
  loadingLocation,
  name,
  compactor,
  crane,
  Type,
}) => ({
  ...defaultInitialValues,
  body,
  bodyMechanization,
  liftingMechanization,
  loadingLocation,
  name,
  compactor: compactor || defaultInitialValues.compactor,
  crane: crane || defaultInitialValues.crane,
  Type,
});

const VehicleTypeEdit = ({ match, history }) => {
  const { params: { id: vehicleTypeId } = {} } = match;

  const intl = useIntl();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [mutation] = useMutation(updateOrCreateVehicleTypeMutation, {
    refetchQueries: [{ query: allVehicleTypesQuery, variables: { isVisibleCompany: false } }],
  });

  const editPage = !!vehicleTypeId;

  const [
    getVehicleType,
    { loading: vehicleTypeLoading, data: { vehicleType = {} } = {} },
  ] = useLazyQuery(vehicleTypeQuery, { onError: toastifyError, variables: { id: vehicleTypeId } });

  useEffect(() => {
    if (vehicleTypeId) {
      getVehicleType({ variables: { id: vehicleTypeId } });
    }
  }, [vehicleTypeId, getVehicleType]);

  useEffect(() => {
    if (vehicleTypeId && Reflect.ownKeys(vehicleType).length) {
      setInitialValues(vehicleSerializer(vehicleType));
    }
  }, [vehicleType, vehicleTypeId]);

  const editPageName = intl.formatMessage(
    {
      id: 'vehicleType.edit_title',
      defaultMessage: 'Vehicle Type / {name}',
    },
    { name: vehicleType.name },
  );
  const addPageName = intl.formatMessage({
    id: 'vehicleType.add_title',
    defaultMessage: 'Vehicle Type / Add',
  });

  const onSubmit = useCallback(
    (values, { setSubmitting, resetForm }) => {
      const variables =
        values.Type !== TYPES_CHOICES_VALUES.collector
          ? {
              name: values.name,
              Type: values.Type,
            }
          : values;

      mutation({ variables: { ...variables, selfId: vehicleTypeId } })
        .then(
          ({
            data: {
              updateOrCreateVehicleType: { vehicleType: savedVehicleType },
            },
          }) => {
            toast.success(`Saved vehicle type - ${savedVehicleType.name}`);
            return {
              obj: savedVehicleType,
              action: values.action,
            };
          },
        )
        .then(
          actionHandler({
            editPage,
            reInitForm: (obj) => setInitialValues(vehicleSerializer(obj)),
            resetForm: () => {
              resetForm();
              setInitialValues(defaultInitialValues);
            },
            baseUrl: '/app/fleet-management/vehicle-type',
            history,
            onEditPage: editPage,
          }),
        )
        .catch(toastifyError)
        .finally(() => setSubmitting(false));
    },
    [editPage, history, mutation, vehicleTypeId],
  );

  return (
    <Grid container>
      <FixedLoadingLayout isLoading={vehicleTypeLoading} />
      <HeaderRow pageTitle={editPage ? editPageName : addPageName} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={vehicleTypeEditValidationSchema()}
        onSubmit={onSubmit}
      >
        <Grid container className="p-15">
          <VehicleTypeForm />
        </Grid>
      </Formik>
    </Grid>
  );
};

VehicleTypeEdit.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(VehicleTypeEdit);
