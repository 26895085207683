import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`pickups-tabpanel-${index}`}
      aria-labelledby={`pickups-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container spacing={2} className="p-15">
          {children}
        </Grid>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `pickups-tabpanel-${index}`,
  };
}

export default TabPanel;
