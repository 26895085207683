import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useLinkHoverStyle = makeStyles((theme) => ({
  linkTableItem: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16,
    color: theme.variables.cRichOcean,
    '&:hover': {
      color: theme.variables.cOrange,
      transition: '.3s',
    },
    '&:hover span:before': {
      backgroundColor: theme.variables.cOrange,
      visibility: 'visible',
      transform: 'scaleX(1)',
    },
  },
  linkHover: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: '-5px',
      left: 0,
      backgroundColor: 'black',
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.3s ease-in-out 0s',
    },
  },
  noFlexLinkTableItem: {
    textDecoration: 'none',
    marginTop: 16,
    marginBottom: 16,
    color: theme.variables.cRichOcean,
    '&:hover': {
      color: theme.variables.cOrange,
      transition: '.3s',
    },
    '&:hover span:before': {
      backgroundColor: theme.variables.cOrange,
      visibility: 'visible',
      transform: 'scaleX(1)',
    },
  },
}));

export const StyledListLink = React.forwardRef(({ to, text, disableFlex, children }, ref) => {
  const setLinkHoverStyle = useLinkHoverStyle();
  return (
    <Link
      ref={ref}
      className={
        disableFlex ? setLinkHoverStyle.noFlexLinkTableItem : setLinkHoverStyle.linkTableItem
      }
      to={to}
    >
      <span className={setLinkHoverStyle.linkHover}>{text}</span>
      {React.Children.map(children, (child, index) => (
        <React.Fragment key={index}>{child}</React.Fragment>
      ))}
    </Link>
  );
});

StyledListLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disableFlex: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

StyledListLink.defaultProps = {
  disableFlex: false,
  children: null,
};

export default useLinkHoverStyle;
