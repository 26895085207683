import React, { useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Logo from './logo';
import ImagesModal from '../../../../shared/imagesModal/imagesModal';
import { useHasDriverEditPageAccess } from '../hooks/useDriverManagmentPageAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.variables.cAntiFlashWhite,
    padding: theme.spacing(3),
    borderRadius: 12.5,
  },
  highContainer: {
    height: '100%',
  },
  titleText: {
    lineHeight: '30px',
    color: theme.variables.cSlate,
    textAlign: 'left',
    font: '18px',
  },
  roundedBtn: {
    borderRadius: 32.5,
    minHeight: 40,
    marginTop: 7,

    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: 0,
  },
  disabledButton: {
    '& > span': {
      '& > a': {
        color: theme.variables.cLightGray,
      },
    },
  },
  nameText: {
    color: theme.variables.VHGrey,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '30px',
    marginBottom: theme.spacing(1),
  },
  descriptionText: {
    color: theme.variables.cEmperor,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
    textAlign: 'left',
  },
  link: {
    textDecoration: 'none',
    color: theme.variables.cEmperor,
  },
  mainInfoContainer: {
    maxWidth: '34.84vw',
  },
}));

const MainInfoHead = ({ imageSrc, imageAltText, name, description, settingsLink, children }) => {
  const classes = useStyles();
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const toggleImagesModalClose = () => setIsImagesModalVisible(!isImagesModalVisible);
  const userMayEdit = useHasDriverEditPageAccess();

  return (
    <Grid item xs className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.highContainer}
        >
          <Logo
            name={imageAltText}
            src={imageSrc}
            variant="large"
            onClick={toggleImagesModalClose}
          />
          <Grid item xs className={classes.highContainer}>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item xs>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <Grid item className={classes.mainInfoContainer} style={{ alignSelf: 'center' }}>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                      <div className={classes.nameText}>{name}</div>
                      <div className={classes.descriptionText}>{description}</div>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    {children}
                  </Grid>
                </Grid>
              </Grid>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                className={classes.roundedBtn}
                disabled={!userMayEdit}
                classes={{
                  disabled: classes.disabledButton,
                }}
              >
                <Link to={settingsLink} className={classes.link}>
                  <FormattedMessage id="detailPage.editSettings" defaultMessage="Edit Settings" />
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <ImagesModal
        isOpen={isImagesModalVisible}
        onClose={toggleImagesModalClose}
        images={imageSrc}
      />
    </Grid>
  );
};

MainInfoHead.propTypes = {
  imageSrc: PropTypes.string,
  imageAltText: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  settingsLink: PropTypes.string,
  children: PropTypes.node,
};

MainInfoHead.defaultProps = {
  imageSrc: '-',
  imageAltText: 'Image',
  name: '-',
  description: '-',
  settingsLink: '/',
  children: null,
};

export default MainInfoHead;
