import { useSelector } from 'react-redux';
import { checkIfRegularUser } from '../utils/settings';

const useRegularUser = () => {
  const me = useSelector((state) => state.settings.user);
  const isRegularUser = checkIfRegularUser(me);

  return [isRegularUser];
};

export default useRegularUser;
