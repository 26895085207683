import PropTypes from 'prop-types';
import React from 'react';
import { ErrorMessage } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';

const StyledErrorMessage = ({ name }) => (
  <FormHelperText error>
    <ErrorMessage name={name} />
  </FormHelperText>
);

StyledErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default StyledErrorMessage;
