import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

const PaperComponent = ({ className, children }) => (
  <Paper className={className}>
    <Scrollbars
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      autoHeight
      autoHeightMin={50}
      autoHeightMax={410}
    >
      {children}
    </Scrollbars>
  </Paper>
);

PaperComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PaperComponent.defaultProps = {
  className: '',
};

export default PaperComponent;
