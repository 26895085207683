import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from '../../shared/styles';
import StyledList from '../../shared/listStyled';
import FaqContainer from '../../shared/faqContainer';

const UserManagementPlatform = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <FaqContainer
        title={
          <FormattedMessage id="platform_user_management_header" defaultMessage="User Management" />
        }
        text={[
          <FormattedMessage
            id="platform_user_management_abstract_first"
            defaultMessage="User Management is where you can invite colleagues and partners to work with you on a project in the WasteHero Waste Management System."
          />,
        ]}
        faqs={<FormattedMessage id="platform_user_maanagment_faqs" defaultMessage="FAQs" />}
        objects={[
          {
            header: (
              <FormattedMessage
                id="platform_user_management_question_one"
                defaultMessage="How do I add a new user?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_user_management.item_q1_first"
                defaultMessage="Navigate to <bold>User Management</bold> in the main menu. Select your project and click <bold>Add New User</bold>."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_user_management_item_q1_second"
                defaultMessage="This action will redirect you to the final step of the settings for the project. An overview of the container, measurement, and fraction settings will also show on this screen."
              />,
              <FormattedMessage
                id="platform_user_management_item_q1_third"
                defaultMessage="Select the <bold>account type</bold> to be created: <bold> admin, project owner</bold> or <bold>user</bold>."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <StyledList
                items={[
                  <FormattedMessage
                    id="platform_user_management_item_q1_fourth"
                    defaultMessage="A <bold>User</bold> has basic access to the platform features"
                    values={{ bold: (text) => <b>{text}</b> }}
                  />,
                  <FormattedMessage
                    id="platform_user_management_item_q1_fifth"
                    defaultMessage="A <bold>Project Owner</bold> can create containers and change project settings."
                    values={{ bold: (text) => <b>{text}</b> }}
                  />,
                  <FormattedMessage
                    id="platform_user_management_item_q1_sixth"
                    defaultMessage="<bold>Admin</bold> will have the project owner's accessibility and can also create new projects."
                    values={{ bold: (text) => <b>{text}</b> }}
                  />,
                ]}
              />,
              <FormattedMessage
                id="platform_user_management_item_q1_eigth"
                defaultMessage="Choose a Job Title from the drop-down to match the role of the person you're inviting"
              />,
              <FormattedMessage
                id="platform_user_management_item_q1_nineth"
                defaultMessage="Enter their email address and click the <bold>Invite button </bold> . This person will receive an email with a link to register as a new user on the platform."
                values={{ bold: (text) => <b style={{ color: '#DF9838' }}>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_user_management_item_q1_tenth"
                defaultMessage="The invitation was successful when you see a blue ribbon with the message <bold>User Assigned to Project</bold>."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_user_management_item_q1_eleventh"
                defaultMessage="You may wish to tell the invitee to check their spam folder for the invitation if the email doesn't come through immediately."
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_user_management_question_two"
                defaultMessage="How do I remove a user?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_user_management_item_q2_first"
                defaultMessage="Navigate to <bold>User Management</bold> to view the people who you have invited to collaborate on your project. From here, you can delete users."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_user_management_item_q2_second"
                defaultMessage="To delete, click the three dots to the right of the user and click the delete button <icon></icon>."
                values={{ icon: () => <DeleteIcon className={classes.iconStyle} /> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_user_management_question_third"
                defaultMessage="How do I edit the permissions or details of a user?"
              />
            ),
            texts: [
              <StyledList
                items={[
                  <FormattedMessage
                    id="platform_user_management_item_q3_second"
                    defaultMessage="To edit, click the three dots to the right of the user and click edit."
                  />,
                  <FormattedMessage
                    id="platform_user_management_item_q3_fourth"
                    defaultMessage="This will take you to the Account Settings where you can edit the name, username, email address, phone number, photo, job title, and password for the user."
                  />,
                  <FormattedMessage
                    id="platform_user_management_item_q3_fifth"
                    defaultMessage="Click <bold>Save</bold> to confirm any changes."
                    values={{ bold: (text) => <b>{text}</b> }}
                  />,
                  <FormattedMessage
                    id="platform_user_management_item_q4_sixth"
                    defaultMessage="Your can edit your personal Account Settings by clicking the dropdown arrow next to your name on the top right-hand corner of the platform."
                  />,
                ]}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_user_management_question_four"
                defaultMessage="What permissions does each account type have?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_user_management_item_q4_first"
                defaultMessage="<bold>Admin: </bold>This account type will be the first a customer receive after payment have started. This account determine company settings and have permission to set up the entire platform. This is the highest level of permission a customer can receive. From this account they can invite more <bold>Admin</bold> accounts or <bold>Project owner</bold> as well as regular <bold>User</bold>. Admins are able to view, edit and create new projects and settings for the entire organization."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_user_management_item_q4_second"
                defaultMessage="<bold>Project owner:</bold> This account can be used to manage projects and define settings such as container types, waste fractions and measurement methods. Project owner can invite other <bold>Project owner</bold> and regular <bold>User</bold>. They are able create, edit and view project settings to the given projects the account is assigned to."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_user_management_item_q4_third"
                defaultMessage="<bold>User:</bold> The user account can register new container with the predefined settings; container types, waste fractions and measurement methods from either the admin or project owner account. This will be the most common account type. Regular user are not allow create or edit project settings, such as container types. They are able to view and use defined settings."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default UserManagementPlatform;
