import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { StyledListLink } from '../../fleet-management/components/linkHoverStyle';
import { TYPES_CHOICES_VALUES } from '../../fleet-management/components/utils';

const useStyles = makeStyles(() => ({
  detailTitle: {
    color: '#0F4455',
    opacity: 0.6,
    marginTop: 12,
    marginBottom: 8,
    fontSize: '14px',
    lineHeight: '16px',
  },
  detailValue: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: 12,
  },
  columnContainer: {
    flexDirection: 'column',
  },
  relative: {
    position: 'relative',
  },
}));

const RouteDetailInformation = ({ selectedRoute, routeDistance, routeDuration }) => {
  const classes = useStyles();

  const uniqueObjectsFilter = (arr) =>
    Array.from(new Set(arr?.map((a) => a.id))).map((id) => arr?.find((a) => a.id === id)) || [];

  const depotsList = uniqueObjectsFilter(selectedRoute?.depot);

  const driverInfo = selectedRoute?.driver?.fullName || selectedRoute?.driver?.email || '';

  return (
    <Grid container className={classes.relative}>
      <Grid container item lg={6} className={classes.columnContainer}>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="route_id" defaultMessage="Route ID" />
          </Typography>
          <Typography className={classes.detailValue}>{selectedRoute?.id || '-'}</Typography>
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="stops" defaultMessage="Stops" />
          </Typography>
          <Typography className={classes.detailValue}>
            {selectedRoute?.stopsCount || '-'}
          </Typography>
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="distance" defaultMessage="Distance" />
          </Typography>
          <Typography className={classes.detailValue}>{routeDistance}</Typography>
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="start_time" defaultMessage="Starts at" />
          </Typography>
          <Typography className={classes.detailValue}>{selectedRoute?.startTime || '-'}</Typography>
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="pickupMethod.wasteStation" defaultMessage="Waste Station" />
          </Typography>
          {selectedRoute?.wasteStation?.length ? (
            selectedRoute?.wasteStation?.map((station) => (
              <StyledListLink
                to={`/app/fleet-management/locations/${station.id}`}
                key={station.id}
                style={{ marginTop: '0', marginBottom: '0' }}
                text={<Typography className={classes.detailValue}>{station.name}</Typography>}
              />
            ))
          ) : (
            <Typography className={classes.detailValue}>-</Typography>
          )}
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="table_head.waste_fraction" defaultMessage="Waste fraction" />
          </Typography>
          {selectedRoute?.wasteFractions?.length ? (
            selectedRoute?.wasteFractions?.map((wasteFraction) =>
              wasteFraction?.wasteTypes?.map((wasteType) => (
                <Typography className={classes.detailValue} key={wasteType.id}>
                  {wasteType.name}
                </Typography>
              )),
            )
          ) : (
            <Typography className={classes.detailValue}>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container item lg={6} className={classes.columnContainer}>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="vehicle" defaultMessage="Vehicle" />
          </Typography>
          {selectedRoute?.vehicle?.name ? (
            <StyledListLink
              to={`/app/fleet-management/${
                selectedRoute?.vehicle?.vehicleType?.Type === TYPES_CHOICES_VALUES.streetSweeper
                  ? 'street-sweeper-vehicle'
                  : 'collector-vehicle'
              }/${selectedRoute?.vehicle.id}`}
              style={{ marginTop: '0', marginBottom: '0' }}
              text={
                <Typography className={classes.detailValue}>
                  {selectedRoute?.vehicle?.name}
                </Typography>
              }
            />
          ) : (
            <Typography className={classes.detailValue}>-</Typography>
          )}
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="vehicles.driver" defaultMessage="Driver" />
          </Typography>
          {driverInfo && (
            <StyledListLink
              to={`/app/fleet-management/driver-management/${selectedRoute?.driver.id}`}
              style={{ marginTop: '0', marginBottom: '0' }}
              text={<Typography className={classes.detailValue}>{driverInfo}</Typography>}
            />
          )}
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="duration" defaultMessage="Duration" />
          </Typography>
          <Typography className={classes.detailValue}>{routeDuration}</Typography>
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="end_time" defaultMessage="Ends at" />
          </Typography>
          <Typography className={classes.detailValue}>{selectedRoute?.endTime || '-'}</Typography>
        </Grid>
        <Grid className={classes.columnContainer}>
          <Typography className={classes.detailTitle}>
            <FormattedMessage id="depots_type.depot" defaultMessage="Depot" />
          </Typography>
          {depotsList.length ? (
            depotsList.map((routeDepot) => (
              <StyledListLink
                to={`/app/fleet-management/locations/${routeDepot.id}`}
                key={routeDepot.id}
                style={{ marginTop: '0', marginBottom: '0' }}
                text={<Typography className={classes.detailValue}>{routeDepot.name}</Typography>}
              />
            ))
          ) : (
            <Typography className={classes.detailValue}>-</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

RouteDetailInformation.defaultProps = {
  selectedRoute: null,
  routeDistance: null,
  routeDuration: null,
};

RouteDetailInformation.propTypes = {
  selectedRoute: PropTypes.shape({
    endTime: PropTypes.string,
    startTime: PropTypes.string,
    stopsCount: PropTypes.number,
    id: PropTypes.string,
    driver: PropTypes.shape({
      email: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.string,
    }),
    depot: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
    project: PropTypes.shape({
      id: PropTypes.string,
    }),
    stops: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            scheduledDriverArrival: PropTypes.string,
            direction: PropTypes.shape({
              destination: PropTypes.shape({
                latitude: PropTypes.number,
                longitude: PropTypes.number,
              }),
            }),
            depot: PropTypes.shape({
              depotType: PropTypes.string,
              id: PropTypes.string,
              name: PropTypes.string,
              location: PropTypes.shape({
                id: PropTypes.string,
              }),
            }),
            container: PropTypes.shape({
              containerId: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
    vehicle: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      vehicleType: PropTypes.shape({
        Type: PropTypes.string,
      }),
    }),
    wasteFractions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    wasteStation: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
  }),
  routeDistance: PropTypes.string,
  routeDuration: PropTypes.string,
};

export default RouteDetailInformation;
