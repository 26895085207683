import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import useStyles from '../../shared/styles';
import FaqContainer from '../../shared/faqContainer';

const PlatformDashbord = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <FaqContainer
        title={<FormattedMessage id="platform_dashboard_header" defaultMessage="Dashboard" />}
        text={[
          <FormattedMessage
            id="platform_dashboard_abstract_first"
            defaultMessage="You can think of the Dashboard as your homepage. Here you can view your projects, get an overview on the status of your containers, view your collection efficiency, view any issue reports and navigate to all areas of the WasteHero platform.The WasteHero platform is the central hub where you’ll manage all aspects of the waste management process. You can add new containers and assign their sensors, get a real-time overview on the fill-level of containers, plan dynamic collection routes, and analyse emission and cost savings. "
          />,
        ]}
        faqs={<FormattedMessage id="platform_dashboard_faqs" defaultMessage="FAQs" />}
        checkLast
        objects={[
          {
            header: (
              <FormattedMessage
                id="platform_dashboard_question_one"
                defaultMessage="How do I search for a specific container on my dashboard?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_dashboard_item_q1_first"
                defaultMessage="Use the search box in the top left-hand corner of the map area and type in the name or location of your chosen container. Select the container from the dropdown list to view it on the map. You can then hover over the marker on the map to view basic details of the container."
              />,
              <FormattedMessage
                id="platform_dashboard_item_q1_second"
                defaultMessage="Click the show more button to view a graph on historical daily fill levels and dates the container was emptied.Hover over the graph for daily statistics. Click <bold>Detailed</bold> to view all data on this specific container. "
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_dashboard_item_q1_third"
                defaultMessage="To return to the normal view, highlight and delete the text in the search box or simply refresh your page."
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_dashboard_question_two"
                defaultMessage="How do I change the default map view on my dashboard?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_dashboard_item_q2_first"
                defaultMessage="Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Project Settings</bold>. Click on the location and drag the map to your desired position. Click confirm. Hit  <bold>Save</bold> at the bottom of the page to confirm your changes."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="platform_dashboard_question_third"
                defaultMessage="What do the coloured dots signify?"
              />
            ),
            texts: [
              <FormattedMessage
                id="platform_dashboard_item_q3_first"
                defaultMessage="You'll notice that the coloured dots that signify the fill-level of a container. Here’s what they mean:"
              />,
              <FormattedMessage
                id="platform_dashboard_item_q3_second"
                defaultMessage="<green>Empty 0-25%</green>"
                values={{ green: (text) => <b className={classes.greenColor}>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_dashboard_item_q3_third"
                defaultMessage=" <yellow> Normal 25-50% </yellow>"
                values={{ yellow: (text) => <b className={classes.yellowColor}>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_dashboard_item_q3__fourth"
                defaultMessage="<orange>Full 50-75% </orange>"
                values={{ orange: (text) => <b className={classes.orangeColor}>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_dashboard_item_q3_fifth"
                defaultMessage=" <pink>Overflowing 75-100% </pink> "
                values={{ pink: (text) => <b className={classes.pinkColor}>{text}</b> }}
              />,
              <FormattedMessage
                id="platform_dashboard_item_q3_sixth"
                defaultMessage="<grey> Disabled </grey>"
                values={{ grey: (text) => <b className={classes.greyColor}>{text}</b> }}
              />,
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default PlatformDashbord;
