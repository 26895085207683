import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { DriverList, DriverDetails, DriverInvite, DriverSettings } from '.';
import { getUsersAvailableProjects } from '../../../../../shared/utils';
import { JOB_TITLE_VALUES } from '../../../../../shared/utils/constants';

const DriverManagementRouter = ({ match }) => {
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));
  const isDriver = useSelector(
    (state) => state.settings.user.jobtitle.title === JOB_TITLE_VALUES.driver,
  );

  const driverRoutes = useMemo(
    () => [
      <Route exact path={`${match.url}`} component={DriverList} />,
      !usersAvailableProjects?.length && <Redirect to={`${match.url}/`} />,
      <Route exact path={`${match.url}/invite`} component={DriverInvite} />,
      <Route exact path={`${match.url}/add`} component={DriverSettings} />,
      <Route exact path={`${match.url}/:id/edit`} component={DriverSettings} />,
      <Route path={`${match.url}/:id`} component={DriverDetails} />,
    ],
    [match.url, usersAvailableProjects],
  );

  useEffect(() => {
    if (isDriver && driverRoutes.length === 6) {
      driverRoutes.splice(4, 1);
    }
  }, [driverRoutes, isDriver]);
  return <Switch>{driverRoutes}</Switch>;
};

DriverManagementRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default DriverManagementRouter;
