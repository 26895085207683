import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Collapse from '@material-ui/core/Collapse';
import { Grid, Typography } from '@material-ui/core';
import { connect, getIn } from 'formik';
import { connect as reduxConnect } from 'react-redux';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import RegisterContainerTooltipedField from '../../main/routes/containers/routes/register/components/registerContainerTooltipedField';
import {
  formikInjectedPropsTypes,
  formikComponentMemoizeValueCheck,
  FormikSelect,
  memoizeFields,
} from '../inputs/formik';
import NoItemsForSelect from './noItemsForSelect';
import { checkIfRegularUser } from '../utils/settings';

const allContainerTypesQuery = loader(
  './../../graphql/queries/devices/all_container_types.graphql',
);

const SelectContainerType = ({ formik, onChange, md, me }) => {
  const selectedProject = getIn(formik.values, 'selectedProject');
  const isRegularUser = checkIfRegularUser(me);

  const [
    loadContainerType,
    {
      loading: isContainerTypesLoading,
      data: { allContainerTypes: { edges: containerTypes = [] } = {} } = {},
    },
  ] = useLazyQuery(allContainerTypesQuery, { variables: { projectId: selectedProject } });

  useEffect(() => {
    if (selectedProject) {
      loadContainerType();
    }
  }, [selectedProject, loadContainerType]);

  const intl = useIntl();

  const getNoItemsMessage = () => {
    if (isRegularUser) {
      return (
        <FormattedMessage id="no_container_type" defaultMessage="No container type registered.">
          {(text) => <Typography color="error">{text}</Typography>}
        </FormattedMessage>
      );
    }

    return (
      <NoItemsForSelect
        link={`/app/settings/${selectedProject}/container-settings`}
        message={
          <FormattedMessage id="no_container_type" defaultMessage="No container type registered." />
        }
        linkText={
          <FormattedMessage
            id="add_container_type"
            defaultMessage="Click to add new container type."
          />
        }
        warning={
          <FormattedMessage
            id="data_will_not_be_save"
            defaultMessage="Data typed into this form will not be saved"
          />
        }
      />
    );
  };
  const allContainerTypes = useMemo(
    () =>
      containerTypes.map(({ node }) =>
        Object({
          value: node.id,
          label: node.name,
          volume: node.volume,
          length: node.length,
          width: node.width,
          height: node.height,
          diameter: node.diameter,
          projectId: node.project ? node.project.id : null,
          isCylindrical: node.isCylindrical,
        }),
      ),
    [containerTypes],
  );

  return (
    <Grid
      item
      xs={12}
      md={md}
      className={classNames({ 'p-0': isContainerTypesLoading || !selectedProject })}
    >
      <Collapse className="w-100" in={!isContainerTypesLoading && !!selectedProject}>
        {allContainerTypes.length > 0 ? (
          <RegisterContainerTooltipedField
            md={12}
            tooltipText={
              <FormattedMessage
                id="tooltip.selectedContainerType"
                defaultMessage="Choose the type of container"
              />
            }
          >
            <FormikSelect
              label={intl.formatMessage({
                id: 'label.selectedContainerType',
                defaultMessage: 'Container type',
              })}
              placeholder={intl.formatMessage({
                id: 'placeholder.selectedContainerType',
                defaultMessage: 'Container type',
              })}
              required
              filledStyle
              name="selectedContainerType"
              onChange={(e) => onChange(e, allContainerTypes)}
              valuesList={allContainerTypes}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </RegisterContainerTooltipedField>
        ) : (
          getNoItemsMessage()
        )}
      </Collapse>
    </Grid>
  );
};

SelectContainerType.propTypes = {
  onChange: PropTypes.func,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  md: PropTypes.number,
  me: PropTypes.shape({
    isAdmin: PropTypes.bool,
    isMaster: PropTypes.bool,
    isReseller: PropTypes.bool,
    isSuperuser: PropTypes.bool,
  }).isRequired,
};

SelectContainerType.defaultProps = {
  onChange: async () => {},
  md: 9,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default reduxConnect(mapStateToProps)(
  connect(
    React.memo(
      SelectContainerType,
      memoizeFields(['onChange', formikComponentMemoizeValueCheck('selectedProject')]),
    ),
  ),
);
