import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { StyledTableCell, StyledTableHead, StyledTableRow } from './styledTableComponents';
import StyledCheckbox from '../inputs/StyledCheckbox';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  styledTableRow: {
    '& .MuiTypography-root:first-letter': {
      textTransform: 'capitalize !important',
    },
  },
}));

const StyledEnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  cellsConfig,
  noCheckboxes,
  tableFontSize,
}) => {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <StyledTableRow className={classes.styledTableRow}>
        {!noCheckboxes && (
          <StyledTableCell padding="checkbox">
            <StyledCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </StyledTableCell>
        )}
        {cellsConfig.map((headCell) =>
          !headCell.noFilter ? (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ fontSize: tableFontSize }}
            >
              {headCell.label && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography variant="body2">{headCell.label}</Typography>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </StyledTableCell>
          ) : (
            <StyledTableCell
              padding={headCell.disableOrder ? 'default' : 'checkbox'}
              key={headCell.id}
              style={headCell.disableOrder ? { fontSize: tableFontSize } : {}}
              align={headCell.disableOrder ? 'left' : 'right'}
            >
              <Typography variant="body2">{headCell.label}</Typography>
            </StyledTableCell>
          ),
        )}
      </StyledTableRow>
    </StyledTableHead>
  );
};

StyledEnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  cellsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      noFilter: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ).isRequired,
  noCheckboxes: PropTypes.bool,
  tableFontSize: PropTypes.number,
};

StyledEnhancedTableHead.defaultProps = {
  noCheckboxes: false,
  tableFontSize: 15,
};

export default StyledEnhancedTableHead;
