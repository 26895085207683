import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AddUserBlack from '../../../../../images/icons/addUserBlack.svg';
import AddUserGrey from '../../../../../images/icons/addUserGrey.svg';
import { getUsersAvailableProjects } from '../../../../shared/utils';
import ButtonTooltip from '../../../../shared/tooltip/ButtonTooltip';
import useRegularUser from '../../../../shared/hooks/useRegularUser';

const useStyles = makeStyles(() => ({
  roundedBtn: {
    borderRadius: 32.5,
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    border: '1px solid',
  },
  submitBtn: {
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'bold',
    },
    '& .MuiAvatar-img': {
      width: 26,
      height: 22,
    },
  },
}));

const InviteButton = ({ text, match }) => {
  const classes = useStyles();
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));
  const [isRegularUser] = useRegularUser();

  return (
    <ButtonTooltip
      text={
        <FormattedMessage
          id={
            // eslint-disable-next-line no-nested-ternary
            isRegularUser
              ? 'no_permission_invite_driver'
              : usersAvailableProjects?.length
              ? 'tooltip.invite_driver'
              : 'no_available_projects'
          }
          defaultMessage={
            // eslint-disable-next-line no-nested-ternary
            isRegularUser
              ? "You don't have permission to invite driver"
              : usersAvailableProjects?.length
              ? 'Click to invite new driver'
              : 'Please add at least one project'
          }
        />
      }
    >
      <Button
        component={Link}
        variant="outlined"
        color="primary"
        className={classNames(classes.submitBtn, classes.roundedBtn, 'w-100', 'h-100')}
        startIcon={<Avatar src={usersAvailableProjects?.length ? AddUserBlack : AddUserGrey} />}
        type="button"
        to={`${match.url}/invite`}
        disabled={!usersAvailableProjects?.length || isRegularUser}
      >
        {text}
      </Button>
    </ButtonTooltip>
  );
};

InviteButton.propTypes = {
  text: PropTypes.element.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(InviteButton);
