import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { SelectorOption } from '../../analyticsComponents/types';
import CustomCheckbox from '../StyledCheckbox';

const removeDuplicates = (array) => array.filter((a, b) => array.indexOf(a) === b);

const useStyles = makeStyles((theme) => ({
  optionContainer: {
    display: 'flex',
    width: '90%',
    height: 32,
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: 4,
    margin: '4px 12px',
    borderRadius: 4,
  },
  text: {
    fontSize: 14,
    fontFamily: theme.variables.defaultFont,
    color: theme.variables.cCharcoal,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  selectedText: {
    fontWeight: 500,
  },
  selectedContainer: {
    backgroundColor: theme.variables.cAntiFlashWhite,
  },
  groupedOption: {
    marginLeft: 15,
  },
}));

const RenderGroup = ({ groupName, children, options, selectedOptions, selectGroup }) => {
  const classes = useStyles();
  const childrenOptions = options.filter((option) => option.groupBy === groupName);
  const selected = childrenOptions.every((childOption) =>
    selectedOptions.some((selectedOption) => childOption.key === selectedOption.key),
  );

  const groupCheck = (groupSelected, groupOptions) => (e) => {
    if (!groupSelected) {
      selectGroup(e, removeDuplicates([...selectedOptions, ...groupOptions]));
    } else {
      selectGroup(
        e,
        selectedOptions.filter(
          (option) => !groupOptions.some((groupOption) => groupOption.key === option.key),
        ),
      );
    }
  };

  if (!groupName) {
    return <React.Fragment key="ungroupedOptions">{children}</React.Fragment>;
  }

  return (
    <React.Fragment key={groupName}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={groupCheck(selected, childrenOptions)}
        className={classNames(classes.optionContainer, classes.selectedContainer)}
      >
        <CustomCheckbox color="primary" checked={selected} />
        <div className={classNames(classes.text, classes.selectedText)}>{groupName}</div>
      </div>
      <div className={classes.groupedOption}>{children}</div>
    </React.Fragment>
  );
};

RenderGroup.propTypes = {
  selectedOptions: PropTypes.arrayOf(SelectorOption).isRequired,
  groupName: PropTypes.string,
  selectGroup: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(SelectorOption).isRequired,
};

RenderGroup.defaultProps = {
  groupName: '',
};

export default RenderGroup;
