import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Popper from '@material-ui/core/Popper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableHead } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { ReactComponent as PinIcon } from '../../../../../images/icons/pin.svg';
import Address from '../../../google/location';
import { getFillIndicatorColor } from '../../../../main/routes/containers/routes/containers-map/utils';

const useStyles = makeStyles((theme) => ({
  pin: {
    backgroundColor: theme.variables.clusterColor,
    borderRadius: '50%',
    padding: 16,
    zIndex: ({ showPopup }) => (showPopup ? 5 : 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.variables.cWhite,
  },
  popper: {
    zIndex: 300,
    '& $arrow': {
      height: '3em',
      width: '1em',
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.2em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.4em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  heading: {
    background: theme.variables.cSnow,
    width: '100%',
    fontSize: '0.88rem',
    padding: '0 16px',
    borderRadius: 4,
    '& > p': {
      lineHeight: '29px',
      maxWidth: 237,
    },
    '& > svg': {
      width: 22,
      paddingRight: 10,
    },
  },
  containerSectionWrapper: {
    overflowY: 'scroll',
    padding: '0 15px',
    minWidth: 340,
    minHeight: 140,
    maxHeight: 334,
  },
  stateIndicator: {
    margin: 'auto',
    display: 'block',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  },
}));

const Cell = withStyles(() => ({
  root: {
    padding: 2,
    textAlign: 'center',
    maxWidth: 180,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: 25,
  },
  head: {
    fontSize: 14,
  },
}))(TableCell);

const ClusterMarker = ({
  onSelectItem,
  pointCount,
  leaves: containers,
  isStation,
  fullScreen,
  setOpenContainersTable,
}) => {
  const [arrowRef, setArrowRef] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles({ showPopup });
  const title = isStation ? (
    <FormattedMessage id="waste_stations" defaultMessage="Waste Stations" />
  ) : (
    <FormattedMessage id="containers" defaultMessage="Containers" />
  );

  return (
    <div
      className={classNames('container-pin', classes.pin)}
      onMouseEnter={(e) => {
        setAnchorEl(e.currentTarget);
        setShowPopup(true);
        setOpenContainersTable(true);
      }}
      onMouseLeave={() => {
        setAnchorEl(null);
        setShowPopup(false);
        setOpenContainersTable(false);
      }}
    >
      {pointCount}
      <Popper
        id="clusterOnMap"
        open={showPopup}
        disablePortal={fullScreen}
        className={classes.popper}
        anchorEl={anchorEl}
        placement="right"
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="m-5">
              <Grid container className={classes.heading} justify="center">
                <PinIcon />
                <Typography noWrap>{title}</Typography>
              </Grid>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className={classes.containerSectionWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopPropagation();
                }}
              >
                <span className={classes.arrow} ref={setArrowRef} />
                <Table>
                  <TableHead>
                    <TableRow>
                      <Cell padding="none" variant="head">
                        <FormattedMessage id="state" defaultMessage="State" />
                      </Cell>
                      <Cell padding="none" variant="head">
                        <FormattedMessage id="container_id" defaultMessage="Container ID" />
                      </Cell>
                      <Cell padding="none" variant="head">
                        <FormattedMessage id="address" defaultMessage="Address" />
                      </Cell>
                      <Cell padding="none" variant="head">
                        <FormattedMessage id="fill_level" defaultMessage="Fill level" />
                      </Cell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {containers.map(({ properties: { container: containerInfo } }) => (
                      <TableRow
                        key={containerInfo.id}
                        onClick={() => onSelectItem(containerInfo)}
                        hover
                      >
                        <Cell padding="none">
                          <span
                            className={classes.stateIndicator}
                            style={{
                              background: getFillIndicatorColor(
                                containerInfo?.measurement?.fillPercentage,
                              ),
                            }}
                          />
                        </Cell>
                        <Cell padding="none">{containerInfo?.containerId}</Cell>
                        <Cell padding="none">
                          <Address
                            placeId={containerInfo?.location?.placeId}
                            lat={containerInfo?.location?.latitude}
                            lng={containerInfo?.location?.longitude}
                          />
                        </Cell>
                        <Cell padding="none">
                          {containerInfo.measurement
                            ? `${
                                Math.round((containerInfo.measurement.fillPercentage || 0) * 100) /
                                100
                              }%`
                            : ' - '}
                        </Cell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

ClusterMarker.propTypes = {
  isStation: PropTypes.bool,
  onSelectItem: PropTypes.func.isRequired,
  leaves: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  pointCount: PropTypes.number.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  setOpenContainersTable: PropTypes.func.isRequired,
};

ClusterMarker.defaultProps = {
  isStation: false,
};

export default ClusterMarker;
