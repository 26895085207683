import React, { useCallback } from 'react';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { loader } from 'graphql.macro';
import Transition from '../animations/transition';
import { FormikTextField } from '../inputs/formik';
import FixedLoadingLayout from '../loading/fixed';
import { setFieldErrors } from '../../main/routes/fleet-management/components/utils';
import { toastifyError } from '../utils';

const updateOrCreateSkill = loader(
  './../../graphql/mutations/fleet_management/update_or_create_skill.graphql',
);

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    padding: 20,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  paddedRows: {
    '&>div': {
      paddingBottom: theme.spacing(1.5),
    },
  },
  btn: {
    width: '100%',
    fontSize: 17,
    textTransform: 'none',
    boxShadow: theme.variables.defaultBoxShadow,
    borderRadius: 32.5,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const CreateSkillsDialog = ({ open, onClose, initialValues }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [mutation] = useMutation(updateOrCreateSkill);

  const handleOnCLose = useCallback(() => {
    onClose('');
  }, [onClose]);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setFieldError, resetForm }) => {
      // ToDo update cache
      mutation({ variables: values })
        .then(
          ({
            data: {
              updateOrCreateSkill: {
                skill: { id },
              },
            },
          }) => {
            resetForm();
            onClose(id);
          },
        )
        .catch((error) => {
          toastifyError(error);
          setFieldErrors(error?.graphQLErrors, setFieldError);
        })
        .finally(() => setSubmitting(false));
    },
    [mutation, onClose],
  );

  return (
    <Dialog
      onClose={handleOnCLose}
      open={open}
      maxWidth="sm"
      fullWidth
      scroll="body"
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="register-container-dialog"
      aria-describedby="register-container-description"
    >
      <DialogTitle id="register-container-dialog" className={classes.dialogTitle}>
        <FormattedMessage id="adding_skill" defaultMessage="Adding Skill" />
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleOnCLose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            code: Yup.string().required(),
            name: Yup.string().required(),
            description: Yup.string(),
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, submitForm }) => (
            <Form
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  submitForm();
                }
              }}
            >
              <Grid container justify="center" className={classNames('m-10', classes.paddedRows)}>
                <FixedLoadingLayout open={isSubmitting} />
                <Grid item xs={12}>
                  <FormikTextField
                    name="code"
                    autoFocus
                    label={intl.formatMessage({
                      id: 'createDriverSkill.form.label.code',
                      defaultMessage: 'Code',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'createDriverSkill.form.placeholder.code',
                      defaultMessage: 'Skill code',
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="name"
                    label={intl.formatMessage({
                      id: 'createDriverSkill.form.label.skillName',
                      defaultMessage: 'Skill name',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'createDriverSkill.form.placeholder.description',
                      defaultMessage: 'e.g.',
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormikTextField
                    name="description"
                    multiline
                    rows={6}
                    rowsMax={6}
                    label={intl.formatMessage({
                      id: 'createDriverSkill.form.label.description',
                      defaultMessage: 'Description',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'createDriverSkill.form.placeholder.description',
                      defaultMessage: 'e.g.',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    className={classes.btn}
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    <FormattedMessage id="button.save" defaultMessage="Save" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CreateSkillsDialog.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.string),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

CreateSkillsDialog.defaultProps = {
  initialValues: {
    selfId: '',
    code: '',
    name: '',
    description: '',
  },
};

export default CreateSkillsDialog;
