import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import PencilIcon from '../../../../../images/icons/pencil.svg';
import DeleteIcon from '../../../../../images/icons/delete.svg';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    marginRight: 10,
  },
  linkMenuItem: {
    textDecoration: 'none',
    color: theme.variables.cRichOcean,
  },
}));

const ActionMenu = ({ anchorElement, changeAnchor, onDelete, id, match, excludeDeleteOption }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => changeAnchor()}
    >
      <Link to={`${match.url}/${id}/edit`} className={classNames(classes.linkMenuItem)}>
        <MenuItem>
          <img
            src={PencilIcon}
            className={classes.menuIcon}
            alt={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
          />
          <FormattedMessage id="edit" defaultMessage="Edit" />
        </MenuItem>
      </Link>
      {!excludeDeleteOption && (
        <MenuItem onClick={onDelete}>
          <img
            src={DeleteIcon}
            className={classes.menuIcon}
            alt={intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
          />
          <FormattedMessage id="delete" defaultMessage="Delete" />
        </MenuItem>
      )}
    </Menu>
  );
};

ActionMenu.propTypes = {
  changeAnchor: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string,
  anchorElement: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.instanceOf(Element).isRequired,
  ]),
  match: ReactRouterPropTypes.match.isRequired,
  excludeDeleteOption: PropTypes.bool,
};

ActionMenu.defaultProps = {
  anchorElement: null,
  id: '',
  excludeDeleteOption: false,
};

export default withRouter(ActionMenu);
