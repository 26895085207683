const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 14,
    borderRadius: 32.5,
    textTransform: 'none',
    '&.MuiButton-root': {
      padding: theme.spacing(1.25, 5.75),
    },
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  containerFormWrapper: {
    paddingLeft: '18px !important',
    paddingRight: '18px !important',
    paddingBottom: '18px !important',
  },
  textFieldContainer: {
    width: '100%',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  horizontalSpan: {
    display: 'inline-flex',
  },
  navLink: {
    textDecoration: 'none',
    marginLeft: 5,
    color: theme.variables.VHBlue,
    cursor: 'pointer',
  },
});

export default styles;
