import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '&>.DateRangePicker': {
      height: '100%',
      '&>div': {
        height: '100%',
        '& .DateRangePickerInput': {
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& .DateInput_input': {
            fontFamily: theme.variables.defaultFont,
            fontSize: '16px',
            fontWeight: 500,
            '&::-webkit-input-placeholder': {
              color: 'black',
            },
            '&:-ms-input-placeholder': {
              color: 'black',
            },
            '&::placeholder': {
              color: 'black',
            },
          },
          '& .DateInput': {
            height: '100%',
            width: 'auto',
            backgroundColor: 'inherit',
          },
          '& button': {
            padding: 0,
            margin: '0 5px 0 10px',
            '&>svg': {
              fill: theme.variables.cRichOcean,
              height: 14,
            },
          },
          '& input': {
            height: '100%',
            fontWeight: 500,
            backgroundColor: 'inherit',
            color: theme.variables.cRichOcean,
            padding: 1,
            fontFamily: theme.variables.defaultFont,
            fontSize: 16,
            textAlign: 'center',
            lineHeight: '24px',
          },
          '& #end_custom_period': {
            marginRight: 15,
          },
          '& .DateInput_input__focused': {
            borderBottom: `2px solid ${theme.variables.cSilver}`,
          },
        },
      },
    },
  },
  icon: {
    display: 'flex',
  },
}));

const StyledDateRangePicker = ({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  showDefaultInputIcon,
  maxDate,
  availableDays,
  futurePeriod,
  disabled,
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const classes = useStyles();

  const outsideRange = (date) => {
    if (futurePeriod || !maxDate) {
      return false;
    }
    return date > maxDate;
  };

  const isDayBlocked = (date) => {
    if (futurePeriod) {
      return false;
    }
    return !availableDays.length
      ? true
      : availableDays.every(
          (availableDay) =>
            !(
              date.day() === availableDay.day() &&
              date.month() === availableDay.month() &&
              date.year() === availableDay.year()
            ),
        );
  };
  return (
    <div className={classes.root}>
      <DateRangePicker
        startDate={startDate}
        startDateId="start_custom_period"
        endDate={endDate}
        endDateId="end_custom_period"
        onDatesChange={({ startDate: newStartDate, endDate: newEndDate }) => {
          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }}
        customArrowIcon={
          <div className={classes.icon}>
            <RemoveIcon />
          </div>
        }
        focusedInput={focusedInput}
        daySize={50}
        disabled={disabled}
        block
        regular
        minimumNights={0}
        isDayBlocked={isDayBlocked}
        isOutsideRange={outsideRange}
        initialVisibleMonth={() =>
          ((availableDays.length && moment.max(availableDays)) || endDate || moment())
            .clone()
            .subtract(1, 'month')
        }
        hideKeyboardShortcutsPanel
        showDefaultInputIcon={showDefaultInputIcon}
        displayFormat="DD MMM YYYY"
        onFocusChange={(newFocusedInput) => {
          setFocusedInput(newFocusedInput);
        }}
      />
    </div>
  );
};

export const DateRangeFilterPropTypes = {
  startDate: PropTypes.oneOfType([
    MomentPropTypes.momentObj.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
  endDate: PropTypes.oneOfType([
    MomentPropTypes.momentObj.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
  setEndDate: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
};

StyledDateRangePicker.propTypes = {
  ...DateRangeFilterPropTypes,
  showDefaultInputIcon: PropTypes.bool,
  maxDate: MomentPropTypes.momentObj,
  availableDays: PropTypes.arrayOf(
    PropTypes.oneOfType([MomentPropTypes.momentObj.isRequired, PropTypes.oneOf([null]).isRequired]),
  ),
  futurePeriod: PropTypes.bool,
  disabled: PropTypes.bool,
};

StyledDateRangePicker.defaultProps = {
  showDefaultInputIcon: true,
  maxDate: moment(),
  availableDays: [],
  futurePeriod: false,
  disabled: false,
};

export default StyledDateRangePicker;
