import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';

import WhenNotFound from '../shared/whenNotFound';
import Dashboard from './routes/dashboard';
import Containers from './routes/containers/Containers';
import NotificationSettings from './routes/notificationSettings';
import UserManagement from './routes/user-management/UserManagement';
import Routes from './routes/routes/Routes';
import OperationManagement from './routes/operation-management/Routes';
import ReportsPage from './routes/reports';
import SupportPage from './routes/support';
import SettingsPage from './routes/settings';
import SendMqttMessage from './routes/settings/sendMQTTMessage';
import FleetManagement from './routes/fleet-management/FleetManagement';
import Analytics from './routes/analytics/Analytics';

const MainRouter = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/dashboard`} component={Dashboard} />
    <Route path={`${match.url}/containers`} component={Containers} />
    <Route path={`${match.url}/fleet-management`} component={FleetManagement} />
    <Route path={`${match.url}/user-management`} component={UserManagement} />
    <Route path={`${match.url}/routes`} component={Routes} />
    <Route path={`${match.url}/operation-management`} component={OperationManagement} />
    <Route path={`${match.url}/reports`} component={ReportsPage} exact />
    <Route path={`${match.url}/support`} component={SupportPage} />
    <Route path={`${match.url}/settings`} component={SettingsPage} />
    <Route path={`${match.url}/notifications`} component={NotificationSettings} />
    <Route exact path={`${match.url}/send-mqtt`} component={SendMqttMessage} />
    <Route path={`${match.url}/analytics`} component={Analytics} />
    <Route component={WhenNotFound} />
  </Switch>
);

MainRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(MainRouter);
