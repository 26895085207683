import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useStyles from '../../shared/styles';
import SupportTextPage from '../../shared/supportTextPage';
import StepsContainer from '../../shared/stepsContainer';
import DrillingHoles from '../../../../../../images/support/sensor_support/physicalInstallation/DrillingHoles.jpg';
import Placement2 from '../../../../../../images/support/sensor_support/physicalInstallation/Placement2.jpg';
import GuideSensor from '../../../../../../images/support/sensor_support/physicalInstallation/GuideSensor.jpg';
import InstalledSensor from '../../../../../../images/support/sensor_support/physicalInstallation/InstalledSensor.jpg';
import MarktheHolesFoDrilling from '../../../../../../images/support/sensor_support/physicalInstallation/MarktheHolesFoDrilling.jpg';
import Onepicture from '../../../../../../images/support/sensor_support/physicalInstallation/Onepicture.jpg';
import PlaceBlindrivets from '../../../../../../images/support/sensor_support/physicalInstallation/PlaceBlindrivets.jpg';
import TrashBin from '../../../../../../images/support/sensor_support/physicalInstallation/TrashBin.jpg';
import ScanSensor from '../../../../../../images/support/sensor_support/physicalInstallation/ScanSensor.jpg';

const PhysicalInstalation = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root} alignItems="center">
      <Grid item xs={12}>
        <SupportTextPage
          title={
            <FormattedMessage id="physical_installation" defaultMessage="Physical Installation" />
          }
          texts={[
            <FormattedMessage
              id="physical_installation_text_one"
              defaultMessage="<bold>Are you ready to install your WasteHero sensor?</bold> 2 people are required for sensor installation. Ensure you have all proper tools and safety equipment prior to beginning installation."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="physical_installation_text_two"
              defaultMessage="WasteHero takes no responsibility for the installation and maintenance of the WasteHero device. The below mentioned information is a recommendation only and does not represent any warranties from WasteHero."
            />,
          ]}
        />
        <StepsContainer
          number={1}
          subheader={
            <FormattedMessage id="step_one_header" defaultMessage="Scan and Activate Sensor" />
          }
          image={ScanSensor}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="physical_installation_item_first"
              defaultMessage="Before mounting your sensor, you must scan and activate it using the WasteHero Install App. Login to the app, select your project, select register device, scan the device, attach to new container, and set the location and container settings."
            />
          </Typography>
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="physical_installation_item_second"
              defaultMessage="For detailed instructions head to <bold>Support Center > Mobile App > Scanning and activating your WasteHero sensor with a new container</bold>."
              values={{ bold: (text) => <b>{text}</b> }}
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={2}
          subheader={
            <FormattedMessage
              id="step_two_prepare_container_placement"
              defaultMessage="Prepare Container and Choose Placement"
            />
          }
          image={Placement2}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_two_step_two_prepare_container_placement_item_first"
              defaultMessage="Ensure that your container is clean and free from any obstructions. Use a microfiber cloth and isopropyl alcohol to clean the area prior to installation if necessary."
            />
          </Typography>
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_two_step_two_prpare_container_placement_item_second"
              defaultMessage="The device should be installed centrally under the lid of the container. The device must have a clear view to the content of the bin."
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={3}
          subheader={
            <FormattedMessage
              id="step_three_mark_drilling_hole"
              defaultMessage="Mark the drilling holes"
            />
          }
          image={MarktheHolesFoDrilling}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_three_mark_drilling_hole_item_first"
              defaultMessage="Place the sensor on the top of the container lid, directly above where it will sit inside the container. Hold the sensor steady on the bin. Using the four holes in the sensor’s casing as a template, mark the four holes on the container lid with a pen. This is where you will drill the holes."
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={4}
          subheader={
            <FormattedMessage id="step_four_drilling_holes" defaultMessage="Drill Holes" />
          }
          image={DrillingHoles}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_four_drilling_hole_item_first"
              defaultMessage="Drill the four holes through the container using a drill bit suited to the material of your container. Clean up the drill dust. Remember to always wear safety glasses when drilling."
            />
          </Typography>
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_four_drilling_hole_item_second"
              defaultMessage="Not sure which drill bit you need? Check out our equipment for installation section."
            />
          </Typography>
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_four_drilling_hole_item_third"
              defaultMessage="If drilling into a metal container, we recommend that you use cutting fluid."
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={5}
          subheader={
            <FormattedMessage id="step_five_place_the_rivet" defaultMessage="Place the rivets" />
          }
          image={PlaceBlindrivets}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="laser_distance_measure_tape_measure_text"
              defaultMessage="Take the blind rivets provided in the mounting kit and place them head side down into the container so that the long stem protrudes from the top of the bin."
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={6}
          subheader={
            <FormattedMessage
              id="step_six_align_blind_rivets"
              defaultMessage="Align blind rivets and sensor"
            />
          }
          image={GuideSensor}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_six_align_blind_rivets_item_first"
              defaultMessage="From the inside of the container, align the sensor with the four rivets. Guide the rivets through the four holes in the casing of the sensor while holding the stems from the other side of the container (you will likely need two people for this step)."
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={7}
          subheader={
            <FormattedMessage id="step_seven_set_the_rivets" defaultMessage="Set the rivets" />
          }
          image={Onepicture}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_seven_set_the_rivets_item_first"
              defaultMessage="One person should hold the sensor in place inside the bin, while another person uses a rivet gun to set the rivets on the lid. First ensure the sensor and rivets are aligned. Then, place the stem of the rivet into the rivet gun and squeeze the handle of the gun until you hear a snapping noise. The rivet gun will snap off the stem of the rivet (which can be discarded) and leave the top of the container without obstruction."
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={8}
          subheader={
            <FormattedMessage
              id="step_eight_check_istallation"
              defaultMessage="Check Installation"
            />
          }
          image={InstalledSensor}
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_eight_check_istallation_item_first"
              defaultMessage="Apply a little force to check the tightness of the installation. If installation of the device was successful, you will be able to view data on the platform within 48 hours."
            />
          </Typography>
        </StepsContainer>
        <StepsContainer
          number={9}
          subheader={<FormattedMessage id="step_nine_take_a_photo" defaultMessage="Take photo" />}
          image={TrashBin}
          checkLast
        >
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_nine_take_a_photo_item_first"
              defaultMessage="Take a photo of the container with the installed device. Add the photo of the container on the platform:."
            />
          </Typography>
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_nine_take_a_photo_item_second"
              defaultMessage="<bold>ON THE APP:</bold> Login, select project, change container settings, search for your container, select container, click <bold>+ Take a Photo</bold>, use photo, <bold>Update</bold>."
              values={{ bold: (text) => <b>{text}</b> }}
            />
          </Typography>
          <Typography className={classes.textContent} variant="body1" gutterBottom>
            <FormattedMessage
              id="step_nine_take_a_photo_item_third"
              defaultMessage="<bold>ON THE WEB PLATFORM:</bold> Go to <bold>Settings</bold> in the main menu, select your project, then navigate to <bold>Container Settings</bold>. In the Added containers list, click the Pencil icon next to the container type you wish to add an image for. Click on the picture of the container outline to the right. Select an image from your files and click Open to upload your chosen image. Hit <bold>Save Container</bold> to confirm your changes."
              values={{ bold: (text) => <b>{text}</b> }}
            />
          </Typography>
        </StepsContainer>
      </Grid>
    </Grid>
  );
};

export default PhysicalInstalation;
