import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import * as Yup from 'yup';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { loader } from 'graphql.macro';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tab, Tabs, Grid, Button, Tooltip } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';

import {
  getWasteFractionFromContainer,
  toastifyError,
  saveCacheRead,
} from '../../../../shared/utils';
import { FormikSelect, FormikTextField } from '../../../../shared/inputs/formik';
import IconInfo from '../../../../../images/icons/iconInfo.svg';
import TrashIcon from '../../../../../images/icons/trashIcon.svg';
import PenIcon from '../../../../../images/icons/penIcon.svg';
import { PERIOD_VALUES } from './utils';

const getAllComparisonSettingsQuery = loader(
  '../../../../graphql/queries/analytics/comparison_settings.graphql',
);

const createComparisonSettingsMutation = loader(
  '../../../../graphql/mutations/analytics/create_comparison_settings.graphql',
);

const deleteComparisonSettingsMutation = loader(
  '../../../../graphql/mutations/analytics/delete_comparison_settings.graphql',
);

const updateComparisonSettingsMutation = loader(
  '../../../../graphql/mutations/analytics/update_comparison_settings.graphql',
);

const comparisonSettingsValidationSchema = () =>
  Yup.object().shape({
    collectionCost: Yup.number().required(),
    overflowingCost: Yup.number(),
    costPerTonne: Yup.number(),
    incomePerTonne: Yup.number(),
    weightFactor: Yup.number(),
    amountOfCollection: Yup.number().required(),
    chanceOfOverflowing: Yup.number().nullable(),
    amountOfCollectionPeriod: Yup.string().required(),
    costSettings: Yup.string().required(),
    averageFillLevel: Yup.number().nullable(),
  });

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.variables.cAntiFlashWhite,
    color: theme.variables.VHDarkGrey,
    fontSize: '11px',
    paddingRight: '10px',
  },
  body: {
    fontSize: '12px',
    paddingRight: '10px',
  },
  root: {
    height: '25px',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    height: '25px',
    backgroundColor: '#f9fafd',
  },
}))(TableRow);

const ComparisonSettings = ({
  classes,
  savedActiveProjects,
  allCostSettings,
  onClose,
  currencyCoef,
  currencySymbol,
  weightToVolumeCoef,
  weightSymbol,
  volumeSymbol,
  handleCalculateAnalytics,
  comparisonPeriodTab,
  setComparisonPeriodTab,
  customPeriodComparisonFrom,
  customPeriodComparisonTo,
  customPeriodTo,
  customPeriodFrom,
  period,
}) => {
  const intl = useIntl();

  const [selectedFirstStartDate, setSelectedFirstStartDate] = useState(
    customPeriodFrom ? moment(customPeriodFrom) : null,
  );
  const [selectedFirstEndDate, setSelectedFirstEndDate] = useState(
    customPeriodTo ? moment(customPeriodTo) : null,
  );
  const [selectedSecondStartDate, setSelectedSecondStartDate] = useState(
    customPeriodComparisonFrom ? moment(customPeriodComparisonFrom) : null,
  );
  const [selectedSecondEndDate, setSelectedSecondEndDate] = useState(
    customPeriodComparisonTo ? moment(customPeriodComparisonTo) : null,
  );
  const [selectedFirstFocusedInput, setSelectedFirstFocusedInput] = useState('startDate');
  const [selectedSecondFocusedInput, setSelectedSecondFocusedInput] = useState('startDate');

  const [editingId, setEditingId] = useState('');
  const [previousDateTab, setPreviousDateTab] = useState(period || 'month');
  const [selectedCombination, setSelectedCombination] = useState('');

  const [createComparisonSettings] = useMutation(createComparisonSettingsMutation);
  const [deleteComparisonSettings] = useMutation(deleteComparisonSettingsMutation);
  const [updateComparisonSettings] = useMutation(updateComparisonSettingsMutation);

  const calculateData = () => {
    if (comparisonPeriodTab === 'custom_period') {
      const customDates = {
        customPeriodFrom: selectedFirstStartDate,
        customPeriodTo: selectedFirstEndDate,
        customPeriodComparisonFrom: selectedSecondStartDate,
        customPeriodComparisonTo: selectedSecondEndDate,
      };
      handleCalculateAnalytics(comparisonPeriodTab, null, customDates);
    }

    if (comparisonPeriodTab === 'previous_period') {
      handleCalculateAnalytics(comparisonPeriodTab, previousDateTab);
    }

    if (comparisonPeriodTab === 'historical_data') {
      handleCalculateAnalytics(comparisonPeriodTab);
    }

    onClose();
  };

  const handleDeleteComparisonSettings = async (e, selfId) => {
    e.preventDefault();
    const variables = { selfId };

    return deleteComparisonSettings({
      variables,
      update: async (
        cache,
        {
          data: {
            deleteComparisonSettings: { status },
          },
        },
      ) => {
        if (status !== 'Success') {
          throw new Error(`Comparison setting could not be removed: ${status}`);
        }

        const {
          data: { allComparisonSettings: allComparisonSettingsData },
        } = await saveCacheRead({
          query: getAllComparisonSettingsQuery,
          variables: { activeProjects: savedActiveProjects },
        });

        const newComparisonSettings = {
          ...allComparisonSettingsData,
          edges: allComparisonSettingsData.edges.filter(({ node }) => node.id !== selfId),
        };

        cache.writeQuery({
          query: getAllComparisonSettingsQuery,
          variables: { activeProjects: savedActiveProjects },
          data: { allComparisonSettings: newComparisonSettings },
        });
      },
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'toast.comparison_settings_removed',
            defaultMessage: 'Comparison setting was successfully removed',
          }),
        );
      })
      .catch((error) => {
        toastifyError(error);
      });
  };

  const formSubmitHandler = async (values, { setSubmitting, setFieldError, resetForm }) => {
    setSubmitting(true);

    const variables = values;

    if (!variables.amountOfCollectionPeriod) {
      setFieldError('amountOfCollectionPeriod', 'This field is required');
      setSubmitting(false);
      return;
    }
    if (!variables.amountOfCollection) {
      setFieldError('amountOfCollection', 'This field is required');
      setSubmitting(false);
      return;
    }

    variables.collectionCost /= currencyCoef;
    variables.overflowingCost /= currencyCoef;
    variables.incomePerTonne /= currencyCoef;
    variables.costPerTonne /= currencyCoef;
    variables.weightFactor /= weightToVolumeCoef;

    if (editingId) {
      updateComparisonSettings({
        variables: { input: { ...values, id: editingId } },
        update: async (
          cache,
          {
            data: {
              updateComparisonSettings: { comparisonSettings },
            },
          },
        ) => {
          const comparisonSettingsFullQuery = {
            query: getAllComparisonSettingsQuery,
            variables: {
              activeProjects: savedActiveProjects,
            },
          };

          const {
            data: { allComparisonSettings: allComparisonSettingsData },
          } = await saveCacheRead(comparisonSettingsFullQuery);

          const newAllComparisonSettingsData = {
            ...allComparisonSettingsData,
            edges: [
              ...allComparisonSettingsData.edges.filter(({ node }) => node.id !== editingId),
              {
                node: comparisonSettings,
                __typename: 'ComparisonSettingsTypeEdge',
              },
            ],
          };

          cache.writeQuery({
            ...comparisonSettingsFullQuery,
            data: {
              allComparisonSettings: newAllComparisonSettingsData,
            },
          });
        },
      })
        .then(() => {
          toast.info(
            intl.formatMessage({
              id: 'toast.comparison_settings_updated',
              defaultMessage: 'Comparison settings were successfully updated',
            }),
          );
          resetForm();
        })
        .catch((error) => {
          toastifyError(error);
        })
        .finally(() => {
          setSubmitting(false);
          setEditingId('');
        });
    } else {
      createComparisonSettings({
        variables: { input: values },
        update: async (
          cache,
          {
            data: {
              createComparisonSettings: { comparisonSettings },
            },
          },
        ) => {
          const comparisonSettingsFullQuery = {
            query: getAllComparisonSettingsQuery,
            variables: {
              activeProjects: savedActiveProjects,
            },
          };

          const {
            data: { allComparisonSettings: allComparisonSettingsData },
          } = await saveCacheRead(comparisonSettingsFullQuery);

          const newAllComparisonSettingsData = {
            ...allComparisonSettingsData,
            edges: [
              ...allComparisonSettingsData.edges,
              {
                node: comparisonSettings,
                __typename: 'ComparisonSettingsTypeEdge',
              },
            ],
          };

          cache.writeQuery({
            ...comparisonSettingsFullQuery,
            data: {
              allComparisonSettings: newAllComparisonSettingsData,
            },
          });
        },
      })
        .then(() => {
          toast.info(
            intl.formatMessage({
              id: 'toast.comparison_settings_created',
              defaultMessage: 'Comparison settings were successfully created',
            }),
          );
          resetForm();
        })
        .catch((error) => {
          toastifyError(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const { data: allComparisonSettings } = useQuery(getAllComparisonSettingsQuery, {
    variables: {
      activeProjects: savedActiveProjects,
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (allComparisonSettings?.allComparisonSettings?.edges?.length) {
      setComparisonPeriodTab('historical_data');
    } else {
      setComparisonPeriodTab('custom_period');
    }
  }, [allComparisonSettings, setComparisonPeriodTab]);

  const rows = useMemo(() => {
    if (!allComparisonSettings?.allComparisonSettings?.edges) {
      return [];
    }

    return allComparisonSettings?.allComparisonSettings?.edges?.map(({ node }) => ({
      id: node.id,
      combination: `${node.costSettings.containerType.name} + ${getWasteFractionFromContainer(
        node.costSettings,
      )}`,
      collection: node.collectionCost * currencyCoef,
      overflowing: node.overflowingCost * currencyCoef,
      costPerTonne: node.costPerTonne * currencyCoef,
      incomePerTonne: node.incomePerTonne * currencyCoef,
    }));
  }, [allComparisonSettings, currencyCoef]);

  const combinationsList = useMemo(() => {
    const allCombinations =
      allCostSettings?.allCostSettings?.edges?.map(({ node }) => ({
        value: node.id,
        label: `${node.containerType.name} + ${getWasteFractionFromContainer(node)}`,
      })) || [];

    const allExistCombinationIds = [];

    allComparisonSettings?.allComparisonSettings?.edges?.forEach(({ node }) => {
      allExistCombinationIds.push(node.costSettings.id);
    });

    const allComparisonSettingsList = allCombinations.filter(
      ({ value }) => !allExistCombinationIds.includes(value),
    );

    if (editingId) {
      const costSettingsId = allComparisonSettings?.allComparisonSettings?.edges?.find(
        ({ node }) => editingId === node.id,
      ).node.costSettings.id;
      allComparisonSettingsList.push(allCombinations.find(({ value }) => costSettingsId === value));
    }

    return allComparisonSettingsList;
  }, [allCostSettings, allComparisonSettings?.allComparisonSettings, editingId]);

  const editComparisonSettings = (selfId, setFieldValue) => {
    setEditingId(selfId);

    const comparisonSettings = allComparisonSettings.allComparisonSettings.edges.find(
      ({ node }) => node.id === selfId,
    ).node;

    setFieldValue('costSettings', comparisonSettings.costSettings.id);
    setFieldValue('collectionCost', comparisonSettings.collectionCost * currencyCoef);
    setFieldValue('overflowingCost', comparisonSettings.overflowingCost * currencyCoef);
    setFieldValue('costPerTonne', comparisonSettings.costPerTonne * currencyCoef);
    setFieldValue('incomePerTonne', comparisonSettings.incomePerTonne * currencyCoef);
    setFieldValue('weightFactor', comparisonSettings.weightFactor * weightToVolumeCoef);
    setFieldValue('amountOfCollection', comparisonSettings.amountOfCollection);
    setFieldValue(
      'amountOfCollectionPeriod',
      comparisonSettings.amountOfCollectionPeriod.toLowerCase(),
    );
    setFieldValue('chanceOfOverflowing', comparisonSettings.chanceOfOverflowing);
    setFieldValue('averageFillLevel', comparisonSettings.averageFillLevel);
  };

  const cancelEditing = (resetForm) => {
    setEditingId('');
    resetForm();
  };

  return (
    <>
      <Grid xs={12} className="m-t-20">
        <Tabs
          onChange={(e, value) => setComparisonPeriodTab(value)}
          value={comparisonPeriodTab}
          className={classes.comparisonPeriodTabs}
          aria-label="tabs.comparisonPeriod"
          variant="fullWidth"
        >
          <Tab
            label={intl.formatMessage({
              id: 'tabs.own_values',
              defaultMessage: 'Historical data',
            })}
            value="historical_data"
            className={classes.comparisonPeriodTab}
          />
          <Tab
            label={intl.formatMessage({
              id: 'tabs.custom_period',
              defaultMessage: 'Custom period',
            })}
            value="custom_period"
            className={classes.comparisonPeriodTab}
          />
          <Tab
            label={intl.formatMessage({
              id: 'tabs.previous_period',
              defaultMessage: 'Previous period',
            })}
            value="previous_period"
            className={classes.comparisonPeriodTab}
          />
        </Tabs>
      </Grid>
      <Grid className="w-100">
        {comparisonPeriodTab === 'historical_data' && (
          <Grid xs={12}>
            <Grid item xs={12} className="m-t-20">
              <FormattedMessage id="select_combination" defaultMessage="Select combination">
                {(txt) => <span className={classes.costSettingsSubtitle}>{txt}</span>}
              </FormattedMessage>
            </Grid>
            <Formik
              initialValues={{
                collectionCost: '',
                overflowingCost: '',
                costPerTonne: '',
                incomePerTonne: '',
                weightFactor: '',
                costSettings: '',
                amountOfCollection: '',
                amountOfCollectionPeriod: '',
                chanceOfOverflowing: null,
                averageFillLevel: null,
              }}
              onSubmit={formSubmitHandler}
              validationSchema={comparisonSettingsValidationSchema}
            >
              {({ submitForm, setFieldValue, resetForm }) => (
                <Form className="w-100 m-t-20">
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FormikSelect
                        label={intl.formatMessage({
                          id: 'label.combination',
                          defaultMessage: 'Combination',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'placeholder.combination',
                          defaultMessage: 'Combination',
                        })}
                        filledStyle
                        name="costSettings"
                        onChange={(value) => setSelectedCombination(value)}
                        valuesList={combinationsList}
                        labelClass={classes.inputLabel}
                        fieldClass={classes.fieldClass}
                        withoutNoneChoice
                        disabled={editingId}
                      />
                    </Grid>
                    {selectedCombination && (
                      <>
                        <Grid xs={12} className="m-t-10">
                          <FormattedMessage id="enter_values" defaultMessage="Enter values">
                            {(txt) => <p className={classes.costSettingsSubtitle}>{txt}</p>}
                          </FormattedMessage>
                          <Grid container xs={12} justify="space-around" spacing={2}>
                            <Grid item xs={4} className={classes.formLabelItem}>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <FormattedMessage
                                    id="label.collection"
                                    defaultMessage="Collection"
                                  >
                                    {(txt) => (
                                      <span className={classes.formLabel}>
                                        {txt}{' '}
                                        <Tooltip
                                          title={intl.formatMessage({
                                            id: 'tooltip.collection',
                                            defaultMessage:
                                              'The cost for a waste hauler to collect a combination of this container type and waste fraction.',
                                          })}
                                        >
                                          <img
                                            className={classes.helpIcon}
                                            src={IconInfo}
                                            alt="tooltip"
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </FormattedMessage>
                                </Grid>
                                <Grid item className="w-100">
                                  <FormikTextField
                                    name="collectionCost"
                                    type="number"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          className={classes.adornment}
                                          position="end"
                                        >
                                          {currencySymbol}
                                        </InputAdornment>
                                      ),
                                      onInput: (e) => {
                                        if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                          const newValue = e.target.value.replaceAll('-', '');
                                          e.target.value = newValue;
                                        }
                                      },
                                    }}
                                    className={classes.formField}
                                    onChange={(value) => {
                                      const [
                                        beforeSeparator,
                                        afterSeparator,
                                      ] = value.toString().split('.');
                                      const digitsLength = afterSeparator?.length || 0;
                                      const maxLength = beforeSeparator?.length + 3;
                                      if (digitsLength > 2) {
                                        setFieldValue(
                                          'collectionCost',
                                          value.toString().slice(0, maxLength),
                                          true,
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={4} item className={classes.formLabelItem}>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <FormattedMessage
                                    id="label.overflowing"
                                    defaultMessage="Overflowing"
                                  >
                                    {(txt) => (
                                      <span className={classes.formLabel}>
                                        {txt}{' '}
                                        <Tooltip
                                          title={intl.formatMessage({
                                            id: 'label.overflowing',
                                            defaultMessage:
                                              'The cost of collecting overflowing waste from a combination of this container type and waste fraction.',
                                          })}
                                        >
                                          <img
                                            className={classes.helpIcon}
                                            src={IconInfo}
                                            alt="tooltip"
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </FormattedMessage>
                                </Grid>
                                <Grid item className="w-100">
                                  <FormikTextField
                                    name="overflowingCost"
                                    type="number"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          className={classes.adornment}
                                          position="end"
                                        >
                                          {currencySymbol}
                                        </InputAdornment>
                                      ),
                                      onInput: (e) => {
                                        if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                          const newValue = e.target.value.replaceAll('-', '');
                                          e.target.value = newValue;
                                        }
                                      },
                                    }}
                                    className={classes.formField}
                                    onChange={(value) => {
                                      const [
                                        beforeSeparator,
                                        afterSeparator,
                                      ] = value.toString().split('.');
                                      const digitsLength = afterSeparator?.length || 0;
                                      const maxLength = beforeSeparator?.length + 3;
                                      if (digitsLength > 2) {
                                        setFieldValue(
                                          'overflowingCost',
                                          value.toString().slice(0, maxLength),
                                          true,
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={4} item className={classes.formLabelItem}>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <FormattedMessage
                                    id="label.disposal_cost"
                                    defaultMessage="Disposal cost"
                                  >
                                    {(txt) => (
                                      <span className={classes.formLabel}>
                                        {txt}{' '}
                                        <Tooltip
                                          title={intl.formatMessage({
                                            id: 'label.cost_per_tonne',
                                            defaultMessage:
                                              'The cost of disposing of 1 tonne of this waste fraction.',
                                          })}
                                        >
                                          <img
                                            className={classes.helpIcon}
                                            src={IconInfo}
                                            alt="tooltip"
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </FormattedMessage>
                                </Grid>
                                <Grid item className="w-100">
                                  <FormikTextField
                                    name="costPerTonne"
                                    type="number"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          className={classes.adornment}
                                          position="end"
                                        >
                                          {currencySymbol}
                                        </InputAdornment>
                                      ),
                                      onInput: (e) => {
                                        if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                          const newValue = e.target.value.replaceAll('-', '');
                                          e.target.value = newValue;
                                        }
                                      },
                                    }}
                                    onChange={(value) => {
                                      const [
                                        beforeSeparator,
                                        afterSeparator,
                                      ] = value.toString().split('.');
                                      const digitsLength = afterSeparator?.length || 0;
                                      const maxLength = beforeSeparator?.length + 3;
                                      if (digitsLength > 2) {
                                        setFieldValue(
                                          'costPerTonne',
                                          value.toString().slice(0, maxLength),
                                          true,
                                        );
                                      }
                                    }}
                                    className={classes.formField}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={12} container spacing={2}>
                              <Grid item xs={5} className={classes.formLabelItem}>
                                <Grid container wrap="nowrap">
                                  <Grid item>
                                    <FormattedMessage
                                      id="label.income_per_tonne"
                                      defaultMessage="Income"
                                    >
                                      {(txt) => (
                                        <span className={classes.formLabel}>
                                          {txt}{' '}
                                          <Tooltip
                                            title={intl.formatMessage({
                                              id: 'label.income_per_tonne',
                                              defaultMessage:
                                                'The income received from recycling 1 tonne of this waste fraction.',
                                            })}
                                          >
                                            <img
                                              className={classes.helpIcon}
                                              src={IconInfo}
                                              alt="tooltip"
                                            />
                                          </Tooltip>
                                        </span>
                                      )}
                                    </FormattedMessage>
                                  </Grid>
                                  <Grid item className="w-100">
                                    <FormikTextField
                                      name="incomePerTonne"
                                      type="number"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            className={classes.adornment}
                                            position="end"
                                          >
                                            {currencySymbol}
                                          </InputAdornment>
                                        ),
                                        onInput: (e) => {
                                          if (
                                            e.target.value.indexOf('-') !== -1 ||
                                            !e.target.value
                                          ) {
                                            const newValue = e.target.value.replaceAll('-', '');
                                            e.target.value = newValue;
                                          }
                                        },
                                      }}
                                      onChange={(value) => {
                                        const [
                                          beforeSeparator,
                                          afterSeparator,
                                        ] = value.toString().split('.');
                                        const digitsLength = afterSeparator?.length || 0;
                                        const maxLength = beforeSeparator?.length + 3;
                                        if (digitsLength > 2) {
                                          setFieldValue(
                                            'weightFactor',
                                            value.toString().slice(0, maxLength),
                                            true,
                                          );
                                        }
                                      }}
                                      className={classes.formField}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={7} className={classes.formLabelItem}>
                                <Grid container wrap="nowrap">
                                  <Grid item>
                                    <FormattedMessage
                                      id="label.weight_to_volume_ratio"
                                      defaultMessage="Weight to volume ratio"
                                    >
                                      {(txt) => (
                                        <span className={classes.formLabel}>
                                          {txt}{' '}
                                          <Tooltip
                                            title={intl.formatMessage({
                                              id: 'label.weight_to_volume_ratio',
                                              defaultMessage:
                                                'Add the weight to volume ratio of your waste. The default is 1.',
                                            })}
                                          >
                                            <img
                                              className={classes.helpIcon}
                                              src={IconInfo}
                                              alt="tooltip"
                                            />
                                          </Tooltip>
                                        </span>
                                      )}
                                    </FormattedMessage>
                                  </Grid>
                                  <Grid item className="w-100">
                                    <FormikTextField
                                      name="weightFactor"
                                      type="number"
                                      className={classes.formField}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            className={classes.adornment}
                                            position="end"
                                          >
                                            {weightSymbol}/{volumeSymbol}
                                          </InputAdornment>
                                        ),
                                        onInput: (e) => {
                                          if (
                                            e.target.value.indexOf('-') !== -1 ||
                                            !e.target.value
                                          ) {
                                            const newValue = e.target.value.replaceAll('-', '');
                                            e.target.value = newValue;
                                          }
                                        },
                                      }}
                                      onChange={(value) => {
                                        const [
                                          beforeSeparator,
                                          afterSeparator,
                                        ] = value.toString().split('.');
                                        const digitsLength = afterSeparator?.length || 0;
                                        const maxLength = beforeSeparator?.length + 3;
                                        if (digitsLength > 2) {
                                          setFieldValue(
                                            'weightFactor',
                                            value.toString().slice(0, maxLength),
                                            true,
                                          );
                                        }
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} className={classNames(classes.divider, 'm-t-20')} />
                        <FormattedMessage
                          id="select_static_collection"
                          defaultMessage="Select static collection"
                        >
                          {(txt) => <p className={classes.costSettingsSubtitle}>{txt}</p>}
                        </FormattedMessage>

                        <Grid container xs={12} spacing={2}>
                          <Grid item xs={3}>
                            <FormikSelect
                              label={intl.formatMessage({
                                id: 'label.choose_period',
                                defaultMessage: 'Choose period',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'placeholder.choose_period',
                                defaultMessage: 'Choose period',
                              })}
                              filledStyle
                              name="amountOfCollectionPeriod"
                              onChange={() => {}}
                              valuesList={PERIOD_VALUES}
                              labelClass={classNames(classes.inputLabel, 'm-t-10')}
                              fieldClass={classes.fieldClass}
                              withoutNoneChoice
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormattedMessage
                              id="label.amount_of_collections"
                              defaultMessage="Amount of collections"
                            >
                              {(txt) => <span className={classes.formLabel}>{txt}</span>}
                            </FormattedMessage>
                            <FormikTextField
                              name="amountOfCollection"
                              type="number"
                              className={classes.formField}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <FormattedMessage
                              id="label.chance_of_overflowing"
                              defaultMessage="Chance of overflowing"
                            >
                              {(txt) => <span className={classes.formLabel}>{txt}</span>}
                            </FormattedMessage>
                            <FormikTextField
                              name="chanceOfOverflowing"
                              type="number"
                              className={classes.formField}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment className={classes.adornment} position="end">
                                    %
                                  </InputAdornment>
                                ),
                                onInput: (e) => {
                                  if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                    const newValue = e.target.value.replaceAll('-', '');
                                    e.target.value = newValue;
                                  }
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormattedMessage
                              id="label.average_fill_percentage"
                              defaultMessage="Average fill percentage"
                            >
                              {(txt) => <span className={classes.formLabel}>{txt}</span>}
                            </FormattedMessage>
                            <FormikTextField
                              name="averageFillLevel"
                              type="number"
                              className={classes.formField}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment className={classes.adornment} position="end">
                                    %
                                  </InputAdornment>
                                ),
                                onInput: (e) => {
                                  if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                    const newValue = e.target.value.replaceAll('-', '');
                                    e.target.value = newValue;
                                  }
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          justify="flex-end"
                          spacing={2}
                          className="m-t-10 m-b-10"
                        >
                          <Grid xs={3}>
                            {editingId && (
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.addCombinationButton}
                                type="button"
                                onClick={() => cancelEditing(resetForm)}
                              >
                                <FormattedMessage
                                  id="cost_settings.cancel_edit"
                                  defaultMessage="Cancel editing"
                                >
                                  {(txt) => <span className={classes.submitBtnText}>{txt}</span>}
                                </FormattedMessage>
                              </Button>
                            )}
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.addCombinationButton}
                              type="button"
                              onClick={submitForm}
                            >
                              {editingId ? (
                                <FormattedMessage
                                  id="cost_settings.edit_comparison_settings"
                                  defaultMessage="Edit"
                                >
                                  {(txt) => <span className={classes.submitBtnText}>{txt}</span>}
                                </FormattedMessage>
                              ) : (
                                <FormattedMessage
                                  id="cost_settings.add_combination"
                                  defaultMessage="Add combination"
                                >
                                  {(txt) => <span className={classes.submitBtnText}>{txt}</span>}
                                </FormattedMessage>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid xs={12} className={classNames(classes.divider, 'm-t-20')} />
                  <Grid xs={12} className="m-t-10">
                    <FormattedMessage id="overview_over_added" defaultMessage="Overview over added">
                      {(txt) => <p className={classes.costSettingsSubtitle}>{txt}</p>}
                    </FormattedMessage>
                  </Grid>
                  <Grid xs={12} className="m-t-20">
                    <TableContainer>
                      <Scrollbars autoHeight>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Combination</StyledTableCell>
                              <StyledTableCell align="right">Collection</StyledTableCell>
                              <StyledTableCell align="right">Overflowing</StyledTableCell>
                              <StyledTableCell align="right">Disposal cost</StyledTableCell>
                              <StyledTableCell align="right">Income</StyledTableCell>
                              <StyledTableCell align="right" />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows?.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                  {row.combination}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.collection.toFixed(0)} {currencySymbol}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.overflowing.toFixed(0)} {currencySymbol}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.costPerTonne.toFixed(0)} {currencySymbol}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.incomePerTonne.toFixed(0)} {currencySymbol}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Button
                                    className={classes.settingsTableButton}
                                    type="button"
                                    onClick={() => editComparisonSettings(row.id, setFieldValue)}
                                  >
                                    <img src={PenIcon} alt="pen" />
                                  </Button>
                                  <Button
                                    className={classes.settingsTableButton}
                                    type="button"
                                    onClick={(e) => handleDeleteComparisonSettings(e, row.id)}
                                  >
                                    <img src={TrashIcon} alt="trash" />
                                  </Button>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Scrollbars>
                    </TableContainer>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
        {comparisonPeriodTab === 'custom_period' && (
          <>
            <FormattedMessage id="choose_first_period" defaultMessage="Choose first period">
              {(txt) => <p className={classNames(classes.formLabel, classes.noYMargin)}>{txt}</p>}
            </FormattedMessage>
            <div className={classes.calendarBlock}>
              <DateRangePicker
                startDateId="selected_first_start_date"
                onDatesChange={({ startDate, endDate }) => {
                  setSelectedFirstStartDate(startDate);
                  setSelectedFirstEndDate(endDate);
                }}
                endDateId="selected_first_end_date"
                startDate={selectedFirstStartDate}
                endDate={selectedFirstEndDate}
                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                showDefaultInputIcon
                hideKeyboardShortcutsPanel
                verticalSpacing={0}
                displayFormat="DD MMM YYYY"
                focusedInput={selectedFirstFocusedInput}
                onFocusChange={(value) => {
                  if (value) {
                    setSelectedFirstFocusedInput(value);
                  }
                }}
                keepOpenOnDateSelect
              />
            </div>
            <Grid xs={12} className={classNames(classes.divider, 'm-t-20')} />
            <FormattedMessage id="choose_second_period" defaultMessage="Choose second period">
              {(txt) => <p className={classNames(classes.formLabel, classes.noYMargin)}>{txt}</p>}
            </FormattedMessage>
            <div className={classes.calendarBlock}>
              <DateRangePicker
                startDateId="selected_second_start_date"
                onDatesChange={({ startDate, endDate }) => {
                  setSelectedSecondStartDate(startDate);
                  setSelectedSecondEndDate(endDate);
                }}
                endDateId="selected_second_end_date"
                startDate={selectedSecondStartDate}
                endDate={selectedSecondEndDate}
                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                showDefaultInputIcon
                hideKeyboardShortcutsPanel
                verticalSpacing={0}
                displayFormat="DD MMM YYYY"
                focusedInput={selectedSecondFocusedInput}
                onFocusChange={(value) => {
                  if (value) {
                    setSelectedSecondFocusedInput(value);
                  }
                }}
                keepOpenOnDateSelect
              />
            </div>
          </>
        )}
        {comparisonPeriodTab === 'previous_period' && (
          <>
            <Tabs
              onChange={(e, value) => setPreviousDateTab(value)}
              value={previousDateTab}
              className={classes.previousDateTabs}
              aria-label="tabs.previousDateTabs"
              centered
            >
              <Tab
                label={intl.formatMessage({
                  id: 'tabs.day',
                  defaultMessage: 'Day',
                })}
                value="day"
                className={classes.previousDateTab}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'tabs.week',
                  defaultMessage: 'Week',
                })}
                value="week"
                className={classes.previousDateTab}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'tabs.month',
                  defaultMessage: 'Month',
                })}
                value="month"
                className={classes.previousDateTab}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'tabs.quarter',
                  defaultMessage: 'Quarter',
                })}
                value="quarter"
                className={classes.previousDateTab}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'tabs.year',
                  defaultMessage: 'Year',
                })}
                value="year"
                className={classes.previousDateTab}
              />
            </Tabs>
          </>
        )}
      </Grid>
      <Grid xs={12} className={classNames(classes.divider, 'm-t-20')} />
      <Grid xs={12} className={classes.footer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.calculateDataButton}
          tourid="calculateData"
          onClick={calculateData}
          disabled={
            (comparisonPeriodTab === 'custom_period' &&
              (!selectedFirstEndDate ||
                !selectedFirstStartDate ||
                !selectedSecondEndDate ||
                !selectedSecondStartDate)) ||
            (comparisonPeriodTab === 'historical_data' &&
              !allComparisonSettings?.allComparisonSettings?.edges?.length)
          }
        >
          <FormattedMessage id="calculate_the_data" defaultMessage="Calculate the data" />
        </Button>
      </Grid>
    </>
  );
};

ComparisonSettings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  savedActiveProjects: PropTypes.string.isRequired,
  currencyCoef: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  weightToVolumeCoef: PropTypes.number.isRequired,
  weightSymbol: PropTypes.string.isRequired,
  volumeSymbol: PropTypes.string.isRequired,
  allCostSettings: PropTypes.shape({
    allCostSettings: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  }),
  onClose: PropTypes.func.isRequired,
  handleCalculateAnalytics: PropTypes.func.isRequired,
  comparisonPeriodTab: PropTypes.string.isRequired,
  setComparisonPeriodTab: PropTypes.func.isRequired,
  customPeriodComparisonFrom: PropTypes.string,
  customPeriodComparisonTo: PropTypes.string,
  customPeriodTo: PropTypes.string,
  customPeriodFrom: PropTypes.string,
  period: PropTypes.string,
};

ComparisonSettings.defaultProps = {
  allCostSettings: {},
  customPeriodComparisonFrom: null,
  customPeriodComparisonTo: null,
  customPeriodTo: null,
  customPeriodFrom: null,
  period: null,
};

export default ComparisonSettings;
