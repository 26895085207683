import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import PersonIcon from '@material-ui/icons/Person';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { fade, makeStyles } from '@material-ui/core/styles';
import { MAX_FILE_SIZE } from '../../../../shared/utils/constants';
import AvatarBG from '../../../../../images/icons/avatar.svg';
import AvatarBG2 from '../../../../../images/icons/avatar2.svg';

const AddUserIcon = ({
  isCreateMode,
  imageSelectionCallback,
  imageSelectionDataCallback,
  imageUrl,
}) => {
  const useStyles = makeStyles((theme) => ({
    addUserImageTop: {
      height: 94,
      width: 94,
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.015),
      },
    },
    userBG: {
      height: 94,
      width: 94,
      position: 'relative',
    },
    userIcon: {
      height: 48,
      width: 48,
      position: 'absolute',
      top: 23,
      left: 23,
    },
    addCircle: {
      height: 28,
      width: 28,
      position: 'absolute',
      bottom: 5,
      right: 0,
      marginRight: -4,
    },
    container: {
      height: '100%',
      width: '100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: '50%',
      border: `1px solid ${theme.variables.cOrange}`,
      color: theme.variables.VHBlue,
    },
  }));

  const classes = useStyles();
  const inputFile = useRef(null);

  const browseImage = () => {
    inputFile.current.click();
  };

  const fileSelectHandler = (event) => {
    if (event.target.files[0] !== null) {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        toast.error('This image is too large. Max size 5mb');
        return;
      }

      const url = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        imageSelectionDataCallback(reader.result);
        imageSelectionCallback(url);
      };
      reader.readAsDataURL(file);
    } else {
      imageSelectionDataCallback('');
      imageSelectionCallback('');
    }
  };

  if (imageUrl == null) {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <div className={classes.addUserImageTop} onClick={browseImage}>
        {isCreateMode && <img src={AvatarBG} className={classes.userBG} alt="Add user" />}
        {!isCreateMode && <img src={AvatarBG2} className={classes.userBG} alt="Add user" />}
        <PersonIcon className={classes.userIcon} color="primary" />
        <AddCircleIcon
          className={classes.addCircle}
          color={isCreateMode ? 'primary' : 'secondary'}
        />
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={fileSelectHandler}
          accept="image/*"
        />
      </div>
    );
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div className={classes.addUserImageTop} onClick={browseImage}>
      <div className={classes.container} style={{ backgroundImage: `url(${imageUrl})` }} />
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={fileSelectHandler}
        accept="image/*"
      />
    </div>
  );
};

AddUserIcon.propTypes = {
  imageSelectionCallback: PropTypes.func.isRequired,
  imageSelectionDataCallback: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  isCreateMode: PropTypes.bool,
};

AddUserIcon.defaultProps = {
  imageUrl: '',
  isCreateMode: false,
};

export default AddUserIcon;
