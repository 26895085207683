import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect, Form, getIn } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect as reduxConnect, useSelector } from 'react-redux';
import {
  formikComponentMemoizeValueCheck,
  FormikImageDropzone,
  FormikSelect,
  FormikTextField,
  memoizeFields,
} from '../../../../../shared/inputs/formik';
import formikInjectedPropsTypes from '../../../../../shared/inputs/formik/formikPropTypes';
import LoadingLayout from '../../../../../shared/loading';
import AddMoreInputs from '../../components/addMoreInputs';
import ToggledHourPicker from '../../../../../shared/inputs/ToggledHourPicker';
import { daysOptions } from '../../../settings/project/defaults/data';
import {
  DEPOTS_TYPE_VALUES,
  FUEL_TYPE,
  FUEL_TYPE_VALUES,
  TYPES_CHOICES_VALUES,
  VEHICLE_STATUSES,
} from '../../components/utils';
import SelectVehicleType from '../../../../../shared/apiPopulatedSelects/selectVehicleType';
import SelectProject from '../../../../../shared/apiPopulatedSelects/selectProjects';
import SelectDepots from '../../../../../shared/apiPopulatedSelects/selectDepots';
import SubmitButtonsSet from '../../../../../shared/buttons/submitButtons';
import MultiSelectWasteFraction from '../../../../../shared/apiPopulatedSelects/multiSelectWasteFraction';
import InputSkeleton from '../../../../../shared/inputs/InputSkeleton';
import { getUserSettings } from '../../../../../shared/utils/settings';
import StyledCollapse from '../../../../../shared/animations/collapse';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  positionCorrector: {
    padding: theme.spacing(0, 7),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
  },
  gridContainer: {
    width: '90%',
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 50,
  },
  btn: {
    width: '100%',
    fontSize: 17,
    textTransform: 'none',
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    borderRadius: 32.5,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(5.5),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sectionLabel: {
    fontWeight: 'bold',
    fontSize: 22,
    color: theme.variables.cEmperor,
    marginTop: 30,
    marginBottom: 10,
  },
  secondaryLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.variables.VHDarkGrey,
    marginBottom: 15,
  },
  tertiaryLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.variables.cLightGray,
    marginTop: 15,
    marginBottom: 15,
  },
  orLabel: {
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.variables.VHDarkGrey,
    marginTop: 40,
    textAlign: 'center',
    right: 0,
    marginRight: -55,
  },
  hoursPicker: {
    width: '70%',
  },
  projectContainer: {
    marginTop: 0,
  },
  extraAdornment: {
    color: theme.variables.VHInputAdornment,
  },
}));

const VehicleEditFrom = ({ setPhotoFile, formik, me }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isRegularUser] = useRegularUser();

  const { isSubmitting, values, setFieldValue } = formik;
  const project = getIn(values, 'project');
  const isProjectOwner = !!me.ownProjects?.edges?.length;

  /* Collector/Street Sweeper/Mechanic type */
  const [type, setType] = useState('');

  const handleTypeChange = (value) => {
    setFieldValue('Type', value);
    setType(value);
  };

  const onVehicleWasteCapacityChange = useCallback(
    (vehicleWasteCapacity) => {
      setFieldValue(
        'cargoVolume',
        vehicleWasteCapacity.reduce(
          (acc, capacity) => acc + (capacity.maxAllowedVolume ? +capacity.maxAllowedVolume : 0),
          0,
        ),
      );
      setFieldValue(
        'maxPayload',
        vehicleWasteCapacity.reduce(
          (acc, capacity) => acc + (capacity.maxAllowedWeight ? +capacity.maxAllowedWeight : 0),
          0,
        ),
      );
    },
    [setFieldValue],
  );

  const settings = useSelector((state) => getUserSettings(state));

  const changeImage = useCallback(
    ({ file }) => {
      setPhotoFile(file);
    },
    [setPhotoFile],
  );

  const wasteFractionSerializer = useCallback(({ wasteFraction }) => wasteFraction, []);

  const wasteFractionDefaultValue = useMemo(
    () => ({
      maxAllowedVolume: '',
      maxAllowedWeight: '',
      wasteFraction: '',
    }),
    [],
  );

  const wasteFractionExtraFields = useCallback(
    ({ index, loading, onChangeValue }) => (
      <>
        <Grid item xs={4} md={3} lg={2} style={{ marginLeft: 28 }}>
          {loading ? (
            <InputSkeleton withLabel={index === 0} />
          ) : (
            <FormikTextField
              name={`vehicleWasteCapacity[${index}].maxAllowedVolume`}
              required
              type="number"
              label={
                index === 0
                  ? intl.formatMessage({
                      id: 'vehicle.label.maxAllowedVolume',
                      defaultMessage: 'Volume capacity',
                    })
                  : ''
              }
              onChange={onChangeValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {settings[values.project]?.volumeVariant || '-'}
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          {loading ? (
            <InputSkeleton withLabel={index === 0} />
          ) : (
            <FormikTextField
              name={`vehicleWasteCapacity[${index}].maxAllowedWeight`}
              required
              type="number"
              label={
                index === 0
                  ? intl.formatMessage({
                      id: 'vehicle.label.maxAllowedWeight',
                      defaultMessage: 'Weight capacity',
                    })
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {settings[values.project]?.weightVariant || '-'}
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
      </>
    ),
    [intl, settings, values.project],
  );

  const currentYear = new Date().getFullYear();

  const vehicleCostLabel = () => {
    const fuelType = getIn(values, 'fuelType');
    const fuelCostLabels = {
      label: {
        fuelCostPerKm: intl.formatMessage({
          id: 'vehicle.label.fuel_per_kilometer',
          defaultMessage: 'Fuel cost per kilometer',
        }),
        fuelConsumption: intl.formatMessage({
          id: 'vehicle.label.fuel_consumption',
          defaultMessage: 'Fuel consumption',
        }),
        fuelCostPerLtr: intl.formatMessage({
          id: 'vehicle.label.fuel_cost_per_ltr',
          defaultMessage: 'Fuel cost per litre',
        }),
      },
      placeholder: {
        fuelCostPerKm: intl.formatMessage({
          id: 'vehicle.placeholder.fuel_per_kilometer',
          defaultMessage: 'Enter fuel cost per kilometer',
        }),
        fuelConsumption: intl.formatMessage({
          id: 'vehicle.placeholder.fuel_consumption',
          defaultMessage: 'Enter fuel consumption',
        }),
        fuelCostPerLtr: intl.formatMessage({
          id: 'vehicle.placeholder.fuel_cost_per_ltr',
          defaultMessage: 'Enter fuel cost per litre',
        }),
      },
    };
    const gasCostLabels = {
      label: {
        fuelCostPerKm: intl.formatMessage({
          id: 'vehicle.label.gas_per_kilometer',
          defaultMessage: 'Gas cost per kilometer',
        }),
        fuelConsumption: intl.formatMessage({
          id: 'vehicle.label.gas_consumption',
          defaultMessage: 'Gas consumption',
        }),
        fuelCostPerLtr: intl.formatMessage({
          id: 'vehicle.label.gas_cost_per_ltr',
          defaultMessage: 'Gas cost per litre',
        }),
      },
      placeholder: {
        fuelCostPerKm: intl.formatMessage({
          id: 'vehicle.placeholder.gas_per_kilometer',
          defaultMessage: 'Enter gas cost per kilometer',
        }),
        fuelConsumption: intl.formatMessage({
          id: 'vehicle.placeholder.gas_consumption',
          defaultMessage: 'Enter gas consumption',
        }),
        fuelCostPerLtr: intl.formatMessage({
          id: 'vehicle.placeholder.gas_cost_per_ltr',
          defaultMessage: 'Enter gas cost per litre',
        }),
      },
    };
    const electricCostLabels = {
      label: {
        fuelCostPerKm: intl.formatMessage({
          id: 'vehicle.label.energy_per_kilometer',
          defaultMessage: 'Energy cost per kilometer',
        }),
        fuelConsumption: intl.formatMessage({
          id: 'vehicle.label.energy_consumption',
          defaultMessage: 'Energy consumption',
        }),
        fuelCostPerLtr: intl.formatMessage({
          id: 'vehicle.label.energy_cost_per_kilowatt_hour',
          defaultMessage: 'Energy cost per kilowatt hour',
        }),
      },
      placeholder: {
        fuelCostPerKm: intl.formatMessage({
          id: 'vehicle.placeholder.energy_per_kilometer',
          defaultMessage: 'Enter energy cost per kilometer',
        }),
        fuelConsumption: intl.formatMessage({
          id: 'vehicle.placeholder.energy_consumption',
          defaultMessage: 'Enter energy consumption',
        }),
        fuelCostPerLtr: intl.formatMessage({
          id: 'vehicle.placeholder.energy_cost_per_kilowatt_hour',
          defaultMessage: 'Enter energy cost per kilowatt hour',
        }),
      },
    };
    switch (fuelType) {
      case FUEL_TYPE_VALUES.gas:
      case FUEL_TYPE_VALUES.diesel:
        return fuelCostLabels;
      case FUEL_TYPE_VALUES.propane:
      case FUEL_TYPE_VALUES.cng:
        return gasCostLabels;
      case FUEL_TYPE_VALUES.electricity:
        return electricCostLabels;
      default:
        return fuelCostLabels;
    }
  };

  return (
    <Form className={classes.root}>
      <Grid container className={classes.positionCorrector} spacing={3}>
        {isRegularUser && (
          <Grid item xs={12} className="text-align-center">
            <FormattedMessage
              id="no_permission_add_vehicle"
              defaultMessage="You don’t have permission to add or edit vehicle"
            >
              {(text) => <span className="no-permission-text">{text}</span>}
            </FormattedMessage>
          </Grid>
        )}
        <LoadingLayout isLoading={isSubmitting} />
        <Grid container item xs={12} spacing={7} className={classes.projectContainer}>
          <Grid item xs={12} md={6}>
            <SelectProject
              isProjectOwner={
                isProjectOwner && !(me.isAdmin || me.isSuperuser || me.isReseller || me.isMaster)
              }
              availableProjects={me.ownProjects?.edges}
              name="project"
              md={12}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.sectionLabel}>
            <FormattedMessage id="vehicle.identification" defaultMessage="Identification" />
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={7}>
          <Grid container item xs={12} md={6} direction="column" justify="space-between">
            <Grid item>
              <FormikTextField
                id="name"
                name="name"
                required
                type="text"
                placeholder={intl.formatMessage({
                  id: 'vehicle.placeholder.name',
                  defaultMessage: 'e.g. Truck B2',
                })}
                label={intl.formatMessage({
                  id: 'vehicle.label.name',
                  defaultMessage: 'Vehicle ID',
                })}
              />
            </Grid>
            <Grid item>
              <FormikTextField
                id="licensePlateNumber"
                name="licensePlateNumber"
                required
                type="text"
                placeholder={intl.formatMessage({
                  id: 'vehicle.placeholder.license_plate_number',
                  defaultMessage: 'e.g. LKJ585DF',
                })}
                label={intl.formatMessage({
                  id: 'vehicle.label.license_plate_number',
                  defaultMessage: 'License plate',
                })}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={4} direction="column" spacing={7}>
            <Grid item>
              <FormikTextField
                id="vin"
                name="vin"
                required
                type="text"
                placeholder={intl.formatMessage({
                  id: 'vehicle.placeholder.vin',
                  defaultMessage: 'Enter VIN',
                })}
                label={intl.formatMessage({
                  id: 'vehicle.label.vin',
                  defaultMessage: 'VIN',
                })}
              />
            </Grid>
            <Grid item>
              <FormikTextField
                id="manufacturerYear"
                name="manufacturerYear"
                required
                inputProps={{ min: '1970', max: currentYear }}
                type="number"
                placeholder={intl.formatMessage({
                  id: 'vehicle.placeholder.manufacturer_year',
                  defaultMessage: 'e.g. 2010',
                })}
                label={intl.formatMessage({
                  id: 'vehicle.label.manufacturing_year',
                  defaultMessage: 'Manufacturing year',
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormikImageDropzone size="small" onChange={changeImage} required name="logo" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikTextField
              id="trim"
              name="trim"
              required
              type="text"
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.trim',
                defaultMessage: 'Enter vehicle trim',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.trim',
                defaultMessage: 'Vehicle trim',
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikTextField
              id="model"
              name="model"
              required
              type="text"
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.model',
                defaultMessage: 'Enter vehicle model',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.model',
                defaultMessage: 'Model',
              })}
            />
          </Grid>

          <Grid container item xs={12} md={6}>
            <Grid item xs={6}>
              <SelectVehicleType setType={handleTypeChange} />
            </Grid>
            <Grid item xs={6} className="p-l-10">
              <FormikTextField
                id="type"
                name="Type"
                required
                type="text"
                label={intl.formatMessage({
                  id: 'vehicle_type.label.type',
                  defaultMessage: 'Type',
                })}
                inputProps={{
                  value: type,
                }}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikTextField
              id="manufacturer"
              name="manufacturer"
              required
              type="text"
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.manufacturer',
                defaultMessage: 'Enter manufacturer',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.manufacturer',
                defaultMessage: 'Manufacturer',
              })}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.sectionLabel}>
            <FormattedMessage id="vehicle.capacity" defaultMessage="Vehicle capacity" />
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={7}>
          {(!type || type === TYPES_CHOICES_VALUES.collector) && (
            <>
              <StyledCollapse in={!!values.project}>
                <MultiSelectWasteFraction
                  name="vehicleWasteCapacity"
                  wasteFractionFieldName="wasteFraction"
                  serializer={wasteFractionSerializer}
                  defaultValue={wasteFractionDefaultValue}
                  queryVariables={{
                    projectId: project || null,
                  }}
                  onChange={onVehicleWasteCapacityChange}
                  extraInputs={wasteFractionExtraFields}
                />
              </StyledCollapse>

              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={4} lg={5} xl={6} style={{ marginRight: 5 }} />
                <Grid item xs={4} md={3} lg={2} style={{ marginLeft: 28 }}>
                  <FormikTextField
                    name="cargoVolume"
                    type="number"
                    required
                    readOnly
                    placeholder={intl.formatMessage({
                      id: 'vehicle.placeholder.cargo_volume',
                      defaultMessage: 'Enter considering volume capacity for each waste fraction',
                    })}
                    label={intl.formatMessage({
                      id: 'vehicle.label.cargo_volume',
                      defaultMessage: 'Cargo volume',
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {settings[values.project]?.volumeVariant || '-'}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={3} lg={2}>
                  <FormikTextField
                    name="maxPayload"
                    type="number"
                    required
                    readOnly
                    placeholder={intl.formatMessage({
                      id: 'vehicle.placeholder.max_payload',
                      defaultMessage: 'Enter considering weight capacity for each waste fraction',
                    })}
                    label={intl.formatMessage({
                      id: 'vehicle.label.max_payload',
                      defaultMessage: 'Max payload',
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {settings[values.project]?.weightVariant || '-'}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={2} lg={2} xl={1} />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <FormikSelect
              name="fuelType"
              filledStyle
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.fuel_type',
                defaultMessage: 'Choose fuel type',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.fuel_type',
                defaultMessage: 'Fuel type',
              })}
              valuesList={FUEL_TYPE}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* ToDo Electricity variant */}
            {values.fuelType !== FUEL_TYPE_VALUES.electricity && (
              <AddMoreInputs
                name="fuelTankCapacity"
                inputType="number"
                addText={intl.formatMessage({
                  id: 'vehicle.add_extra_tank',
                  defaultMessage: '+ Add extra tank',
                })}
                placeholder={intl.formatMessage({
                  id: 'vehicle.placeholder.fuel_tank_capacity',
                  defaultMessage: 'Choose fuel type',
                })}
                label={intl.formatMessage({
                  id: 'vehicle.label.fuel_tank_capacity',
                  defaultMessage: 'Fuel tank capacity',
                })}
                extraAdornment={
                  <Typography className={classes.extraAdornment}>
                    {settings[values.project]?.volumeVariant}
                  </Typography>
                }
                hideCountForFirst
                required={false}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.sectionLabel}>
            <FormattedMessage id="vehicle.coast" defaultMessage="Vehicle cost" />
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={7}>
          <Grid container item xs={12} md={3}>
            <Grid item xs={11}>
              <FormikTextField
                name="fuelCostPerKm"
                type="number"
                placeholder={vehicleCostLabel().placeholder.fuelCostPerKm}
                label={vehicleCostLabel().label.fuelCostPerKm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {settings[values.project]?.currency || '-'}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography className={classes.orLabel}>
                <FormattedMessage id="or" defaultMessage="or" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormikTextField
              name="fuelConsumption"
              type="number"
              placeholder={vehicleCostLabel().placeholder.fuelConsumption}
              label={vehicleCostLabel().label.fuelConsumption}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {settings[values.project]?.volumeVariant || '-'}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormikTextField
              name="fuelCostPerLtr"
              type="number"
              placeholder={vehicleCostLabel().placeholder.fuelCostPerLtr}
              label={vehicleCostLabel().label.fuelCostPerLtr}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {settings[values.project]?.currency || '-'}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormikTextField
              id="fixedCost"
              name="fixedCost"
              type="number"
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.fixed_cost',
                defaultMessage: 'Enter fixed cost per day',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.fixed_cost',
                defaultMessage: 'Fixed cost per day',
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {settings[values.project]?.currency || '-'}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.sectionLabel}>
            <FormattedMessage id="vehicle.route_settings" defaultMessage="Route settings" />
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={7}>
          <Grid item xs={12} md={6}>
            <FormikSelect
              required
              name="status"
              filledStyle
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.status',
                defaultMessage: 'Choose status',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.status',
                defaultMessage: 'Status',
              })}
              valuesList={VEHICLE_STATUSES}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectDepots
              name="depots"
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.depots',
                defaultMessage: 'Choose depots',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.depots',
                defaultMessage: 'Depots',
              })}
              extraFilter={{
                type: DEPOTS_TYPE_VALUES.depot,
              }}
              multiSelect={false}
            />
          </Grid>
          {(!type || type === TYPES_CHOICES_VALUES.collector) && (
            <Grid item xs={12} md={6}>
              <SelectDepots
                name="wasteStations"
                placeholder={intl.formatMessage({
                  id: 'vehicle.placeholder.waste_stations',
                  defaultMessage: 'Choose waste station',
                })}
                label={intl.formatMessage({
                  id: 'vehicle.label.waste_stations',
                  defaultMessage: 'Waste stations',
                })}
                extraFilter={{
                  type: DEPOTS_TYPE_VALUES.wasteStation,
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography className={classes.secondaryLabel}>
              <FormattedMessage
                id="vehicle.label.work_hours"
                defaultMessage="Available workhours"
              />
            </Typography>
            <Typography className={classes.tertiaryLabel}>
              <FormattedMessage
                id="vehicle.placeholder.work_hours"
                defaultMessage="Choose available workhours"
              />
            </Typography>
            <div className={classes.hoursPicker}>
              <ToggledHourPicker name="hours" format12 />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikSelect
              name="daysAvailable"
              required
              filledStyle
              multiSelect
              placeholder={intl.formatMessage({
                id: 'vehicle.placeholder.available_workdays',
                defaultMessage: 'Choose available workdays',
              })}
              label={intl.formatMessage({
                id: 'vehicle.label.available_workdays',
                defaultMessage: 'Available workdays',
              })}
              valuesList={daysOptions}
            />
          </Grid>
        </Grid>
        {!isRegularUser && <SubmitButtonsSet />}
      </Grid>
    </Form>
  );
};

VehicleEditFrom.propTypes = {
  setPhotoFile: PropTypes.func.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  me: PropTypes.shape({
    isAdmin: PropTypes.bool,
    isMaster: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isReseller: PropTypes.bool,
    ownProjects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ).isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default reduxConnect(mapStateToProps)(
  connect(
    React.memo(
      VehicleEditFrom,
      memoizeFields([
        'setPhotoFile',
        'editPage',
        ({ formik }) => formik.isSubmitting,
        formikComponentMemoizeValueCheck('project'),
        formikComponentMemoizeValueCheck('fuelType'),
      ]),
    ),
  ),
);
