import React from 'react';
import { Collapse, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const StyledCollapse = (props) => <Collapse component={Grid} item xs={12} {...props} />;

export default withStyles(() => ({
  hidden: {
    display: 'none',
  },
}))(StyledCollapse);
