import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import BlockIcon from '@material-ui/icons/Block';

import { loader } from 'graphql.macro';
import LoadingLayout from '../../../../shared/loading';
import { maxLengthStringMessage, saveCacheRead, toastifyError } from '../../../../shared/utils';
import { checkIfRegularUser } from '../../../../shared/utils/settings';
import { measurementsPerHourOptions, daysOptions, measurementDefaultValues } from './defaults/data';
import { setUser } from '../../../../../actions';
import StyledDateRangePicker from '../../../../shared/inputs/DateRangePicker';
import SettingsDropdown from './ui/SettingsDropdown';
import ToggledHourPicker from '../../../../shared/inputs/ToggledHourPicker';
import ConfirmDeletionDialog from '../../../../shared/dialog/ConfirmDeletionDialog';
import ConfirmEmptySettingsDialog from './ui/ConfirmEmptySettingsDialog';
import { FormikCheckbox, FormikSelect } from '../../../../shared/inputs/formik';
import TooltippedUserControl from '../../../../shared/tooltip/TooltippedUserControl';
import { TutorialContext } from '../../../../../tutorial';
import FormikTextField from '../../../../shared/inputs/formik/FormikTextField';

const allMeasurementSettingsQuery = loader(
  './../../../../graphql/queries/devices/all_measurement_settings.graphql',
);
const projectQuery = loader('./../../../../graphql/queries/core/project.graphql');
const measurementSettingsQuery = loader(
  './../../../../graphql/queries/devices/measurement_settings.graphql',
);
const createMeasurementSettingMutation = loader(
  './../../../../graphql/mutations/devices/create_measurement_settings.graphql',
);
const updateMeasurementSettingMutation = loader(
  './../../../../graphql/mutations/devices/update_measurement_settings.graphql',
);
const deleteMeasurementSettingMutation = loader(
  './../../../../graphql/mutations/devices/delete_measurement_settings.graphql',
);

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.variables.chipBackgroundColor,
    marginRight: '8px',
    marginTop: '8px',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    '& svg.MuiSvgIcon-root': {
      height: '18px',
      width: '18px',
    },
    '&:hover': {
      height: 'inherit',
      backgroundColor: theme.variables.cLightGray,
      '& > .MuiChip-label': {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  chipHighlight: {
    backgroundColor: theme.variables.chipBackgroundColor,
    marginRight: '8px',
    marginTop: '8px',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    '& svg.MuiSvgIcon-root': {
      height: '18px',
      width: '18px',
    },
    '&:hover': {
      height: 'inherit',
      backgroundColor: theme.variables.cLightGray,
      '& > .MuiChip-label': {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  chipText: {
    fontSize: '13px',
    overflowWrap: 'break-word',
    maxWidth: 400,
    overflow: 'hidden',
  },
  chipItems: {
    display: 'inline-flex',
  },
  chipButton: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  addedMeasurementSettings: {
    border: '1px solid',
    borderColor: theme.variables.cOrange,
    borderRadius: '4px',
    padding: '16.5px',
  },
  hourPickerContainer: {
    height: 'fit-content',
  },
  switch: {
    paddingTop: '18px',
    '& > .MuiSwitch-track': {
      height: '2px',
    },
  },
  datePickerContainer: {
    '& .DateRangePickerInput__withBorder': {
      border: 0,
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
      paddingLeft: '30px',
      '& .DateInput_input': {
        fontFamily: theme.variables.defaultFont,
        fontSize: '16px',
        fontWeight: 500,
        '&::-webkit-input-placeholder': {
          color: 'black',
        },
        '&:-ms-input-placeholder': {
          color: 'black',
        },
        '&::placeholder': {
          color: 'black',
        },
      },
    },
  },
  defaultOptions: {
    padding: 20,
    marginLeft: -10,
    '&:hover': {
      backgroundColor: theme.variables.cOrange,
      cursor: 'pointer',
    },
  },
  activeButton: {
    backgroundColor: theme.variables.buttonBlue,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.variables.VHBlueDark,
    },
  },
  hoursTooltip: {
    marginLeft: -20,
  },
  excludePeriod: {
    color: theme.variables.VHLightBlack,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '20px',
    transform: 'translate(0, -2px)',
  },
  labelText: {
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '20px',
    transform: 'translate(0, -2px)',
  },
  disableEditText: {
    fontWeight: 400,
    lineHeight: '1.5rem',
    color: '#9aa8ad',
    fontSize: '1rem',
    padding: '0 16px 16px 16px',
  },
}));

const measurementSettingsDefaultValues = {
  measurementTypeName: '',
  measureAt: '00',
  measurementsPerHour: '1',
  excludeDays: [],
  excludePeriodStart: null,
  excludePeriodEnd: null,
  excludePeriodEveryYear: false,
  hours: [],
};

const measurementSettingsValidationSchema = (me) =>
  Yup.object().shape({
    measurementTypeName: Yup.string().max(100, maxLengthStringMessage(100)).required(),
    measureAt: Yup.string().required(),
    measurementsPerHour: Yup.string().required(),
    excludeDays: Yup.array().of(Yup.string()),
    excludePeriodStart: Yup.string().nullable(),
    excludePeriodEnd: Yup.string().nullable(),
    excludePeriodEveryYear: Yup.bool(),
    hours: Yup.array()
      .required()
      .when('measurementsPerHour', (measurementsPerHour, hoursScheme) => {
        if (me.isSuperuser) {
          return hoursScheme;
        }
        const userMeasurementsLimit = 4;
        const hoursLimit = userMeasurementsLimit / parseInt(measurementsPerHour, 10);
        return hoursScheme.max(
          hoursLimit,
          <FormattedMessage
            id="measurement_settings.chosen_hours.measurements_limit"
            defaultMessage="You can maximum chose {max} measurements"
            values={{
              max: userMeasurementsLimit,
            }}
          />,
        );
      }),
  });

const MeasurementSettings = ({ projectId, history, onSubmit, editId, me }) => {
  const partOfWizard = !onSubmit;
  const classes = useStyles();
  const intl = useIntl();
  const { isTutorialOpen } = useContext(TutorialContext);

  const [editingId, setEditingId] = useState('');
  const [initialValues, setInitialValues] = useState(measurementSettingsDefaultValues);
  const [deletingMeasurementSetting, setDeletingMeasurementSetting] = useState('');
  const [emptySettingsConfirmationDialog, setEmptySettingsConfirmationDialog] = useState('');

  const [clockFormat12, setClockFormat12] = useState(false);

  const [activePopup, setActivePopup] = useState('');
  const [popupAnchorEl, setPopupAnchorEl] = useState(null);

  const [globalLoading, setGlobalLoading] = useState(false);

  const [chipHighlight, setChipHighlight] = useState(null);

  const client = useApolloClient();

  const handleClickSettingsDropdown = (popupName) => (event) => {
    event.preventDefault();
    setPopupAnchorEl(event.currentTarget);
    setActivePopup(popupName);
  };
  const handleCloseSettingsDropdown = () => {
    setPopupAnchorEl(null);
    setActivePopup('');
  };

  const [
    loadAllMeasurementSettings,
    {
      loading: measurementLoading,
      data: { allMeasurementSettings: { edges: measurementSettings = [] } = {} } = {},
    },
  ] = useLazyQuery(allMeasurementSettingsQuery, {
    displayName: 'allMeasurements',
    options: { fetchPolicy: 'cache-first' },
  });

  useEffect(() => {
    if (projectId) {
      loadAllMeasurementSettings({ variables: { projectId } });
    }
  }, [projectId, loadAllMeasurementSettings]);

  const editMeasurementSetting = (measurementSetting, setFieldValue) => () => {
    setEditingId(measurementSetting.id);

    setFieldValue('hours', measurementSetting.hours);
    setFieldValue('excludeDays', measurementSetting.excludeDays);
    if (measurementSetting.excludePeriodStart) {
      setFieldValue('excludePeriodStart', moment(measurementSetting.excludePeriodStart));
    } else {
      setFieldValue('excludePeriodStart', null);
    }
    if (measurementSetting.excludePeriodStart) {
      setFieldValue('excludePeriodEnd', moment(measurementSetting.excludePeriodEnd));
    } else {
      setFieldValue('setExcludePeriodEnd', null);
    }

    setFieldValue('excludePeriodEveryYear', measurementSetting.excludePeriodEveryYear || false);
    setFieldValue('measureAt', measurementSetting.measureAt.toString().padStart(2, '0'));
    setFieldValue('measurementsPerHour', measurementSetting.measurementsPerHour.toString());
    setFieldValue('measurementTypeName', measurementSetting.name);
  };

  useEffect(() => {
    let cancel;
    if (editId && editingId !== editId) {
      setGlobalLoading(true);
      client
        .query({
          query: measurementSettingsQuery,
          variables: { id: editId },
        })
        .then(({ data: { measurementSettings: measurementSettingsData } }) => {
          if (cancel) {
            return;
          }

          setEditingId(editId);
          const newInitialValues = {};
          editMeasurementSetting(measurementSettingsData, (field, value) => {
            newInitialValues[field] = value;
          })();

          setInitialValues(newInitialValues);
        })
        .finally(() => setGlobalLoading(false));
    }
    return () => {
      cancel = true;
    };
  }, [editId, editingId, client]);

  const [
    getProject,
    {
      loading: projectLoading,
      data: { project: { settings = {} } = {} } = {},
      called: calledGetProject,
    },
  ] = useLazyQuery(projectQuery, {
    variables: { id: projectId, withSettings: true },
  });

  useEffect(() => {
    if (projectId && !calledGetProject) {
      getProject();
    }
  }, [projectId, calledGetProject, getProject]);

  const isProjectOwner = me.ownProjectsIds.includes(projectId);
  const isRegularUser = checkIfRegularUser(me) && !isProjectOwner;
  const loading = globalLoading || projectLoading || measurementLoading;

  useEffect(() => {
    if (settings) {
      setClockFormat12(settings.clockFormat12);
    }
  }, [settings]);

  const refetchQueries = [
    { query: allMeasurementSettingsQuery, variables: { projectId } },
    { query: allMeasurementSettingsQuery, variables: { projectId: '' } },
    'allMeasurementSettings',
    'container',
  ];

  const [createMeasurementSetting] = useMutation(createMeasurementSettingMutation, {
    refetchQueries,
  });
  const [updateMeasurementSetting] = useMutation(updateMeasurementSettingMutation, {
    refetchQueries,
  });
  const [deleteMeasurementSetting] = useMutation(deleteMeasurementSettingMutation, {
    refetchQueries,
  });

  const clearForm = (resetForm = () => {}) => {
    resetForm();
  };

  const cancelEdit = (resetForm) => {
    setEditingId('');
    clearForm(resetForm);
  };

  const saveMeasurement = (formVariables, resetForm) => {
    let mutation = createMeasurementSetting;
    let returnQueryName = 'createMeasurementSettings';
    const variables = { ...formVariables };

    if (editingId) {
      mutation = updateMeasurementSetting;
      variables.selfId = editingId;
      returnQueryName = 'updateMeasurementSettings';
    }

    return mutation({
      variables,
      update: async (
        cache,
        {
          data: {
            [returnQueryName]: { measurementSettings: newMeasurementSetting },
          },
        },
      ) => {
        const query = onSubmit
          ? { query: allMeasurementSettingsQuery }
          : { query: allMeasurementSettingsQuery, variables: { projectId } };

        const {
          data: { allMeasurementSettings: allMeasurementSettingsData },
        } = await saveCacheRead(query);

        const newMeasurementSettings = { ...allMeasurementSettingsData };

        newMeasurementSettings.edges = allMeasurementSettingsData.edges.filter(
          ({ node }) => node.id !== newMeasurementSetting.id,
        );

        newMeasurementSettings.edges = [
          ...newMeasurementSettings.edges,
          {
            node: newMeasurementSetting,
            __typename: 'MeasurementSettingTypeEdge',
          },
        ];

        cache.writeQuery({
          ...query,
          data: {
            allMeasurementSettings: {
              ...allMeasurementSettingsData,
              edges: newMeasurementSettings.edges,
            },
          },
        });

        cache.writeQuery({
          query: measurementSettingsQuery,
          variables: { id: newMeasurementSetting.id },
          data: {
            measurementSettings: newMeasurementSetting,
          },
        });

        cancelEdit(resetForm);
      },
    })
      .then(
        ({
          data: {
            [returnQueryName]: { measurementSettings: newMS },
          },
        }) => {
          toast.info(
            intl.formatMessage({
              id: 'measurement_setting.saved',
              defaultMessage: 'Measurement setting successfully saved',
            }),
          );
          if (onSubmit) {
            onSubmit(newMS);
          }
        },
      )
      .catch((error) => {
        toastifyError(error);
      });
  };

  const formSubmitHandler = (formVariables, { setSubmitting, resetForm, setFieldError }) => {
    const variables = {
      projectId,
      name: formVariables.measurementTypeName,
      ...formVariables,
    };
    if (
      !editingId &&
      measurementSettings.some(
        (containerNode) => containerNode.node.name === variables.measurementTypeName,
      )
    ) {
      setFieldError(
        'measurementTypeName',
        <FormattedMessage
          id="measurment_settings_name_exists"
          defaultMessage="Measurement settings with name {name} already exists in this company"
          values={{ name: variables.measurementTypeName }}
        />,
      );
      setSubmitting(false);
      return;
    }

    // eslint-disable-next-line consistent-return
    return saveMeasurement(variables, resetForm)
      .catch((e) => {
        toastifyError(e);
        setSubmitting(false);
      })
      .finally(() => {
        setInitialValues(initialValues);
        setEditingId('');
      });
  };

  const hourFormat = (measureAt) => (hour) =>
    clockFormat12
      ? `${hour % 12 || 12}:${measureAt} ${hour < 12 || hour === 24 ? 'AM' : 'PM'}`
      : `${hour}:${measureAt}`;

  const handleSelectDefault = (defaultOption, resetForm) => {
    const selectedDefaultParams = measurementDefaultValues.find(
      (option) => option.value === defaultOption,
    );
    if (selectedDefaultParams) {
      setChipHighlight(selectedDefaultParams.params.measurementTypeName);
      setTimeout(() => {
        setChipHighlight(null);
      }, 3000);

      const variables = {
        projectId,
        name: selectedDefaultParams.params.measurementTypeName,
        measureAt: selectedDefaultParams.params.measureAt,
        measurementsPerHour: selectedDefaultParams.params.measurementsPerHour,
        hours:
          selectedDefaultParams.params.hours.length > 0 ? selectedDefaultParams.params.hours : [],
        excludeDays:
          selectedDefaultParams.params.excludeDays.length > 0
            ? selectedDefaultParams.params.excludeDays
            : [],
        excludePeriodStart: selectedDefaultParams.params.excludePeriodStart || null,
        excludePeriodEnd: selectedDefaultParams.params.excludePeriodEnd || null,
        excludePeriodEveryYear: selectedDefaultParams.params.excludePeriodEveryYear,
      };
      handleCloseSettingsDropdown();
      return saveMeasurement(variables, resetForm);
    }
    return handleCloseSettingsDropdown();
  };

  const handleDeleteMeasurementSetting = (e) => {
    e.preventDefault();
    const variables = { selfId: deletingMeasurementSetting.id };
    setGlobalLoading(true);

    return deleteMeasurementSetting({
      variables,
      update: async (
        cache,
        {
          data: {
            deleteMeasurementSettings: { status },
          },
        },
      ) => {
        if (status !== 'Success') {
          throw new Error(`Measurement setting could not be removed: ${status}`);
        }

        const {
          data: { allMeasurementSettings: allMeasurementSettingsData },
        } = await saveCacheRead({ query: allMeasurementSettingsQuery, variables: { projectId } });

        const newMeasurementSettings = {
          ...allMeasurementSettingsData,
          edges: allMeasurementSettingsData.edges.filter(
            ({ node }) => node.id !== deletingMeasurementSetting.id,
          ),
        };

        cache.writeQuery({
          query: allMeasurementSettingsQuery,
          variables: { projectId },
          data: {
            allMeasurementSettings: newMeasurementSettings,
          },
        });
      },
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'measurement_setting.removed',
            defaultMessage: 'Measurement setting successfully removed',
          }),
        );
      })
      .catch((error) => {
        toastifyError(error);
      })
      .finally(() => {
        setDeletingMeasurementSetting('');
        cancelEdit();
        setGlobalLoading(false);
      });
  };

  const removeMeasurementSetting = (measurementSetting) => () => {
    setDeletingMeasurementSetting(measurementSetting);
  };

  const handleNavigationButtonClick = (link) => {
    if (measurementSettings.length > 0 || isTutorialOpen()) {
      history.push(link);
    } else {
      setEmptySettingsConfirmationDialog(link);
    }
  };

  const getMeasurementSettingsChip = (node, setFieldValue) => {
    if (isRegularUser) {
      return (
        <Chip
          key={`chip_${node.id}`}
          label={
            <span className={classes.chipItems}>
              <Typography className={classes.chipText}>{node.name}</Typography>
            </span>
          }
          onClick={editMeasurementSetting(node, setFieldValue)}
          className={
            chipHighlight && node.name === chipHighlight ? classes.chipHighlight : classes.chip
          }
        />
      );
    }
    return (
      <Chip
        key={`chip_${node.id}`}
        label={
          <>
            {node.name}
            <IconButton
              size="small"
              onClick={editMeasurementSetting(node, setFieldValue)}
              className={classes.chipButton}
            >
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.editMeasurementSetting"
                    defaultMessage="Click to edit this measurement settings"
                  />
                }
                avoidIcon
              >
                <EditIcon />
              </TooltippedUserControl>
            </IconButton>
            <IconButton
              size="small"
              onClick={removeMeasurementSetting(node)}
              className={classes.chipButton}
            >
              <TooltippedUserControl
                tooltipText={
                  <FormattedMessage
                    id="tooltip.removeMeasurementSetting"
                    defaultMessage="Click to delete this measurement settings"
                  />
                }
                avoidIcon
              >
                <DeleteIcon />
              </TooltippedUserControl>
            </IconButton>
          </>
        }
        className={
          chipHighlight && node.name === chipHighlight ? classes.chipHighlight : classes.chip
        }
      />
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingLayout isLoading={loading} />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={formSubmitHandler}
          validationSchema={measurementSettingsValidationSchema(me)}
        >
          {({ values, isSubmitting, errors, touched, setFieldValue, resetForm, submitForm }) => (
            <Form className="w-100 m-t-40 p-b-50">
              <LoadingLayout isLoading={isSubmitting} />
              <Grid
                container
                alignItems="center"
                justify="center"
                className="m-b-10"
                spacing={4}
                direction="column"
              >
                <Grid container item xs={12} md={10} spacing={2}>
                  <Grid container item xs={12} md={6} spacing={2}>
                    {isRegularUser && (
                      <FormattedMessage
                        id="no_permission_edit_measurement_settings"
                        defaultMessage="You don’t have permission to add or edit measurement settings"
                      >
                        {(text) => <span className={classes.disableEditText}>{text}</span>}
                      </FormattedMessage>
                    )}
                    <Grid container item xs={12} spacing={4} tourid="measurementConfig1">
                      <Grid item xs={12}>
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.measurement_type_name"
                              defaultMessage="Enter a suitable name for the measurement type"
                            />
                          }
                        >
                          <FormikTextField
                            required
                            label={intl.formatMessage({
                              id: 'label.measurement_type_name',
                              defaultMessage: 'Measurement type name',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'placeholder.measurement_type_name',
                              defaultMessage: 'Measurement type name',
                            })}
                            name="measurementTypeName"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.chosen_hours"
                              defaultMessage="The hours you have chosen in this measurement settings are shown here"
                            />
                          }
                        >
                          <FormikTextField
                            label={intl.formatMessage({
                              id: 'label.chosen_hours',
                              defaultMessage: 'Chosen hours',
                            })}
                            name="hours"
                            className="m-b-10 w-100"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                              value:
                                values.hours?.length > 0
                                  ? values.hours.map(hourFormat(values.measureAt)).join(' | ')
                                  : '',
                            }}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.measurementsPerHour"
                              defaultMessage="Choose the number of measurements to be taken per hour"
                            />
                          }
                        >
                          <FormikSelect
                            name="measurementsPerHour"
                            label={intl.formatMessage({
                              id: 'label.measurementsPerHour',
                              defaultMessage: 'Measurements per hour',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'placeholder.measurementsPerHour',
                              defaultMessage: 'Choose Measurements per hour',
                            })}
                            required
                            valuesList={measurementsPerHourOptions}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(value) => setFieldValue('measurementsPerHour', value)}
                            filledStyle
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={4} tourid="measurementConfig2">
                      <Grid item xs={12}>
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.excludeDays"
                              defaultMessage="Select the days to be excluded for measurement"
                            />
                          }
                        >
                          <FormikSelect
                            name="excludeDays"
                            label={intl.formatMessage({
                              id: 'label.excludeDays',
                              defaultMessage: 'Exclude days',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'placeholder.excludeDays',
                              defaultMessage: 'Choose exclude days',
                            })}
                            valuesList={daysOptions}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(value) => setFieldValue('excludeDays', value)}
                            filledStyle
                            multiSelect
                            withoutNoneChoice
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.excludePeriod"
                              defaultMessage="Select the date range to be excluded for taking the measurement"
                            />
                          }
                        >
                          <InputLabel shrink className={classes.excludePeriod}>
                            <FormattedMessage
                              id="label.excludePeriod"
                              defaultMessage="Exclude period"
                            />
                          </InputLabel>
                          <div className={classes.datePickerContainer}>
                            <StyledDateRangePicker
                              setStartDate={(value) => setFieldValue('excludePeriodStart', value)}
                              setEndDate={(value) => setFieldValue('excludePeriodEnd', value)}
                              startDate={values.excludePeriodStart}
                              endDate={values.excludePeriodEnd}
                              maxDate={null}
                              showDefaultInputIcon={false}
                              disabled={isRegularUser}
                            />
                          </div>
                        </TooltippedUserControl>
                      </Grid>
                      {values.excludePeriodStart && values.excludePeriodEnd && (
                        <Grid item xs={12}>
                          <FormGroup>
                            <TooltippedUserControl
                              tooltipText={
                                <FormattedMessage
                                  id="tooltip.excludePeriodEveryYear"
                                  defaultMessage="Select this to exclude the same date range every year"
                                />
                              }
                              avoidIcon
                            >
                              <FormControlLabel
                                control={<FormikCheckbox name="excludePeriodEveryYear" />}
                                label={intl.formatMessage({
                                  id: 'label.excludePeriodEveryYear',
                                  defaultMessage: 'Exclude period every year',
                                })}
                              />
                            </TooltippedUserControl>
                          </FormGroup>
                        </Grid>
                      )}
                    </Grid>
                    {partOfWizard && (
                      <>
                        <Grid item xs={12} tourid="measurementList">
                          <Paper className={classes.addedMeasurementSettings}>
                            <InputLabel shrink>
                              <FormattedMessage
                                id="label.addedMeasurementSettings"
                                defaultMessage="Added measurement settings"
                              />
                            </InputLabel>
                            {measurementSettings &&
                              measurementSettings.map(({ node }) =>
                                getMeasurementSettingsChip(node, setFieldValue),
                              )}
                          </Paper>
                        </Grid>
                        <Grid container item xs={12} spacing={4} className="p-t-30">
                          {!isRegularUser && (
                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                className={classNames(
                                  'w-100 settings-control-button',
                                  values.measurementTypeName ? classes.activeButton : '',
                                )}
                                disabled={isSubmitting}
                                startIcon={<AddIcon />}
                                type="submit"
                                onClick={submitForm}
                                tourid="addSettings"
                              >
                                <TooltippedUserControl
                                  tooltipText={
                                    <FormattedMessage
                                      id="tooltip.saveMeasurementSetting"
                                      defaultMessage="Click to save this measurement settings"
                                    />
                                  }
                                  avoidIcon
                                >
                                  <Typography variant="body1" className="p-r-50">
                                    {editingId ? (
                                      <FormattedMessage
                                        id="save_measurement"
                                        defaultMessage="Save measurement"
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id="add_measurement"
                                        defaultMessage="Add measurement"
                                      />
                                    )}
                                  </Typography>
                                </TooltippedUserControl>
                              </Button>
                            </Grid>
                          )}
                          {!editingId && !isRegularUser && (
                            <Grid item xs={6}>
                              <div className="w-100 settings-control-button" tourid="addDefault">
                                <TooltippedUserControl
                                  tooltipText={
                                    <FormattedMessage
                                      id="tooltip.add_default"
                                      defaultMessage="Click to choose from preset measurement settings options"
                                    />
                                  }
                                  avoidIcon
                                >
                                  <SettingsDropdown
                                    label={
                                      <FormattedMessage
                                        id="label.select_preset"
                                        defaultMessage="Select recommended setting"
                                      />
                                    }
                                    handleOpenPopup={handleClickSettingsDropdown('addDefault')}
                                    handleClosePopup={handleCloseSettingsDropdown}
                                    anchorElement={popupAnchorEl}
                                    isOpen={activePopup === 'addDefault'}
                                    buttonClasses="w-100 settings-control-button"
                                    buttonStyle="button"
                                    variantType="contained"
                                    colour="orange"
                                    name="default"
                                  >
                                    {measurementDefaultValues.map((option) => (
                                      <Typography
                                        key={option.value}
                                        onClick={() => handleSelectDefault(option.value, resetForm)}
                                        className={classes.defaultOptions}
                                      >
                                        {option.label}
                                      </Typography>
                                    ))}
                                  </SettingsDropdown>
                                </TooltippedUserControl>
                              </div>
                            </Grid>
                          )}
                          <Grid item xs={6}>
                            {editingId && (
                              <Button
                                variant="outlined"
                                className="w-100 settings-control-button"
                                startIcon={<BlockIcon />}
                                type="button"
                                onClick={() => cancelEdit(resetForm)}
                              >
                                <TooltippedUserControl
                                  tooltipText={
                                    <FormattedMessage
                                      id="tooltip.cancel_edit"
                                      defaultMessage="Click to cancel the changes without saving"
                                    />
                                  }
                                  avoidIcon
                                >
                                  <Typography variant="body1" className="p-r-50">
                                    <FormattedMessage
                                      id="cancel_edit"
                                      defaultMessage="Cancel edit"
                                    />
                                  </Typography>
                                </TooltippedUserControl>
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={2}
                    className={classes.hourPickerContainer}
                  >
                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={1} />
                      <Grid item xs={11} className="m-b-10 w-100">
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.select_hours"
                              defaultMessage="Selects the hours in which the measurements are taken"
                            />
                          }
                          tooltipStyle={classes.hoursTooltip}
                        />
                        <FormLabel>
                          <FormattedMessage id="label.select_hours" defaultMessage="Choose hours">
                            {(txt) => (
                              <span
                                className={classes.labelText}
                                style={errors.hours && touched.hours ? { color: '#E25656' } : {}}
                              >
                                {txt}&nbsp;*
                              </span>
                            )}
                          </FormattedMessage>
                        </FormLabel>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} tourid="chooseHours">
                      <ToggledHourPicker
                        name="hours"
                        disabled={isRegularUser}
                        format12={clockFormat12}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {partOfWizard && (
                <Grid container spacing={2} justify="center">
                  <Grid item xs={3} container justify="flex-end">
                    <Button
                      variant="outlined"
                      className="w-60 p-r-40 settings-control-button"
                      startIcon={<ChevronLeftIcon />}
                      type="button"
                      onClick={() =>
                        handleNavigationButtonClick(
                          `/app/settings/${projectId}/waste-fractions-settings`,
                        )
                      }
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.waste_fraction_settings.back"
                            defaultMessage="Move to waste fraction settings"
                          />
                        }
                        avoidIcon
                      >
                        <Typography variant="body1" className="p-r-20">
                          <FormattedMessage id="back" defaultMessage="Back" />
                        </Typography>
                      </TooltippedUserControl>
                    </Button>
                  </Grid>
                  <Grid item xs={3} container justify="flex-start">
                    <Button
                      variant="outlined"
                      className="w-60 p-l-40 settings-control-button"
                      endIcon={<ChevronRightIcon />}
                      type="button"
                      onClick={() =>
                        handleNavigationButtonClick(`/app/settings/${projectId}/project-overview`)
                      }
                      tourid="nextButton"
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.waste_fraction_settings.next"
                            defaultMessage="Move to project overview"
                          />
                        }
                        avoidIcon
                      >
                        <Typography variant="body1" className="p-l-20">
                          <FormattedMessage id="Next" defaultMessage="Next" />
                        </Typography>
                      </TooltippedUserControl>
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!partOfWizard && (
                <Grid container spacing={2} justify="center">
                  <Grid item xs={3} container justify="center">
                    <Button
                      variant="outlined"
                      className="w-60 m-t-40 settings-control-button"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      <FormattedMessage id="save" defaultMessage="Save" />
                    </Button>
                  </Grid>
                </Grid>
              )}

              <ConfirmDeletionDialog
                open={!!deletingMeasurementSetting}
                onConfirm={(e) => {
                  handleDeleteMeasurementSetting(e);
                  cancelEdit(resetForm);
                }}
                onClose={() => setDeletingMeasurementSetting('')}
                title={deletingMeasurementSetting ? deletingMeasurementSetting.name : ''}
              />
              <ConfirmEmptySettingsDialog
                open={!!emptySettingsConfirmationDialog}
                onConfirm={() => history.push(emptySettingsConfirmationDialog)}
                onClose={() => setEmptySettingsConfirmationDialog('')}
                settingsTitle={
                  <FormattedMessage
                    id="measurement_settings"
                    defaultMessage="measurement settings"
                  />
                }
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

MeasurementSettings.propTypes = {
  projectId: PropTypes.string.isRequired,
  editId: PropTypes.string,
  onSubmit: PropTypes.func,
  history: ReactRouterPropTypes.history.isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isReseller: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

MeasurementSettings.defaultProps = {
  onSubmit: null,
  editId: '',
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(withRouter(MeasurementSettings)));
