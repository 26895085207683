import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect as connectToFormik, getIn } from 'formik';
import { connect as reduxConnect } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import MapDialog from '../../mapDialog';
import { handleClosePopup } from '../../../main/routes/containers/routes/shared/utils';
import formikInjectedPropsTypes from './formikPropTypes';
import { useAddress } from '../../google/location';
import StyledTextField from '../StyledTextField';
import FormikTextField from './FormikTextField';

const useStyles = makeStyles(() => ({
  locationInput: {
    '& .MuiInputBase-input[readonly]': {
      fontWeight: 700,
      color: 'currentColor',
    },
  },
}));

const FormikLocationInput = ({
  formik,
  label,
  placeholder,
  name,
  multiValuesSetFieldValue,
  customClasses,
  disabled,
  required,
  me,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { values, setFieldValue, setFieldTouched, setValues, errors, touched } = formik;

  const [isMapDialogueOpen, setIsMapDialogueOpen] = useState(false);
  const [mapInput, setMapInput] = useState(true);
  const [placeId, setPlaceId] = useState('');

  const handleSetPlaceId = (id) => {
    setPlaceId(id);
    setFieldValue('placeId', id);
  };

  const longitude = getIn(values, 'longitude');
  const latitude = getIn(values, 'latitude');

  const formikAddress = getIn(values, 'address');

  const setMultipleValues = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const onLocationDialogOpen = useCallback(() => {
    setIsMapDialogueOpen(true);
  }, [setIsMapDialogueOpen]);

  const { address } = useAddress({
    placeId: placeId || values.placeId,
    lng: longitude,
    lat: latitude,
    address: formikAddress,
    allowAPICall: me.isBeta,
  });

  return (
    <>
      <Grid container justify="flex-start">
        <FormControlLabel
          control={
            <Switch
              checked={mapInput}
              onChange={() => setMapInput((prev) => !prev)}
              color="primary"
            />
          }
          label={intl.formatMessage({
            id: 'label.enable_geolocation',
            defaultMessage: 'Use map for geolocation',
          })}
        />
      </Grid>
      {mapInput && (
        <>
          <StyledTextField
            className={classNames([classes.locationInput, customClasses.textField])}
            required={required}
            readOnly
            name="address"
            error={(touched.latitude && (errors.latitude || errors.longitude)) || errors.address}
            onClick={onLocationDialogOpen}
            value={address}
            label={label || intl.formatMessage({ id: 'label.address', defaultMessage: 'Address' })}
            placeholder={
              placeholder ||
              intl.formatMessage({ id: 'placeholder.address', defaultMessage: 'Address' })
            }
            disabled={disabled}
          />
          <MapDialog
            locationCoord={[longitude, latitude, address]}
            setPlaceId={handleSetPlaceId}
            open={!disabled && isMapDialogueOpen}
            onClose={
              name
                ? handleClosePopup(multiValuesSetFieldValue, () => {}, setIsMapDialogueOpen)
                : handleClosePopup(
                    setFieldValue,
                    setFieldTouched,
                    setIsMapDialogueOpen,
                    setMultipleValues,
                  )
            }
            showFooter
            showSearch
            getLocation
          />
        </>
      )}
      {!mapInput && (
        <Grid item xs={12} container justify="space-between">
          <Grid item xs={5}>
            <FormikTextField
              required={required}
              fast
              id="latitude"
              name="latitude"
              type="number"
              placeholder={intl.formatMessage({
                id: 'lat',
                defaultMessage: 'E.g. 56.1429249',
              })}
              label={intl.formatMessage({
                id: 'latitude',
                defaultMessage: 'Latitude',
              })}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={5}>
            <FormikTextField
              required={required}
              fast
              id="longitude"
              name="longitude"
              type="number"
              placeholder={intl.formatMessage({
                id: 'long',
                defaultMessage: 'E.g. 10.2043458',
              })}
              label={intl.formatMessage({
                id: 'longitude',
                defaultMessage: 'Longitude',
              })}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

FormikLocationInput.propTypes = {
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  multiValuesSetFieldValue: PropTypes.func,
  customClasses: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  me: PropTypes.shape({
    isBeta: PropTypes.bool,
  }).isRequired,
};

FormikLocationInput.defaultProps = {
  label: '',
  placeholder: '',
  name: '',
  multiValuesSetFieldValue: () => {},
  customClasses: {},
  disabled: false,
  required: true,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default reduxConnect(mapStateToProps)(connectToFormik(React.memo(FormikLocationInput)));
