import * as Yup from 'yup';

const reporInquirySchema = Yup.object().shape({
  locations: Yup.array().of(
    Yup.object().shape({
      address: Yup.string(),
      latitude: Yup.string(),
      longitude: Yup.string(),
    }),
  ),
  name: Yup.string(),
  phone: Yup.string(),
  priority: Yup.string(),
  wasteVolume: Yup.string(),
  wasteFraction: Yup.string(),
  deadline: Yup.string(),
  reporter: Yup.string(),
  images: Yup.array().of(Yup.string()),
});

export default reporInquirySchema;
