import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useSelector } from 'react-redux';
import HeaderRow from '../../components/headerRow';
import VehicleEditFrom from './form';
import vehiclesEditValidationSchema from './schema';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import { toastifyError } from '../../../../../shared/utils';
import { DEPOTS_TYPE_VALUES, setFieldErrors, TYPES_CHOICES_VALUES } from '../../components/utils';
import { actionHandler } from '../../../../../shared/buttons/submitButtons';
import {
  getUserSettings,
  getCost,
  prepareVehicleValues,
} from '../../../../../shared/utils/settings';
import useAllowAddVehicle from '../../../../../shared/hooks/useAllowAddVehicle';
import LoadingLayout from '../../../../../shared/loading';

const vehicleQuery = loader('./../../../../../graphql/queries/fleet_management/vehicle.graphql');
const updateOrCreateVehicleQuery = loader(
  './../../../../../graphql/mutations/fleet_management/update_or_create_vehicle.graphql',
);

const defaultInitialValues = {
  logo: '',
  licensePlateNumber: '',
  name: '',
  manufacturer: '',
  model: '',
  manufacturerYear: '',
  trim: '',
  depots: [],
  vehicleType: '',
  fixedCost: '',
  vin: '',
  daysAvailable: [],
  maxPayload: '',
  cargoVolume: '',
  status: '',
  hours: [],
  wasteFractions: '',
  volume_capacity: '',
  wasteStations: [],
  fuelType: '',
  fuelConsumption: '',
  fuelCostPerKm: '',
  fuelCostPerLtr: '',
  fuelTankCapacity: [''],
  vehicleWasteCapacity: [],
  action: '',
  project: '',
};

const vehicleSerializer = async (
  {
    name,
    logo,
    licensePlateNumber,
    manufacturer,
    model,
    manufacturerYear,
    trim,
    fixedCost,
    externalIdentifier,
    daysAvailable,
    maxPayload,
    cargoVolume,
    status,
    hours,
    vehicleType,
    fuelUsagePerKm,
    fuelCostPerKm,
    fuelCostPerLtr,
    fuelType,
    fuelTankCapacity,
    depots,
    wasteCapacity,
    project,
  },
  settings,
) => ({
  ...defaultInitialValues,
  name,
  logo,
  licensePlateNumber,
  manufacturer,
  model,
  manufacturerYear,
  trim,
  fixedCost: await getCost('USD', settings[project.id].currency, fixedCost),
  vin: externalIdentifier,
  daysAvailable,
  maxPayload: maxPayload ? settings[project.id].computeWeight(maxPayload) : '',
  cargoVolume: cargoVolume ? settings[project.id].computeVolume(cargoVolume) : '',
  project: project?.id || defaultInitialValues.project,
  wasteStations: (depots.edges || defaultInitialValues.wasteStations)
    .filter(({ node }) => node.depotType === DEPOTS_TYPE_VALUES.wasteStation)
    .map(({ node }) => node.id),
  depots: (depots.edges || defaultInitialValues.depots)
    .filter(({ node }) => node.depotType === DEPOTS_TYPE_VALUES.depot)
    .map(({ node }) => node.id),
  vehicleWasteCapacity: (wasteCapacity.edges || defaultInitialValues.vehicleWasteCapacity).map(
    ({ node }) => ({
      maxAllowedVolume: settings[project.id].computeVolume(node.maxAllowedVolume),
      maxAllowedWeight: settings[project.id].computeWeight(node.maxAllowedWeight),
      wasteFraction: node.wasteFraction.id,
    }),
  ),
  status: status || defaultInitialValues.status,
  hours,
  vehicleType: vehicleType?.id || defaultInitialValues.vehicleType,
  fuelConsumption: settings[project.id].computeVolume(
    fuelUsagePerKm || defaultInitialValues.fuelConsumption,
  ),
  fuelType: fuelType || defaultInitialValues.fuelType,
  fuelCostPerKm: await getCost(
    'USD',
    settings[project.id].currency,
    fuelCostPerKm || defaultInitialValues.fuelCostPerKm,
  ),
  fuelCostPerLtr: await getCost(
    'USD',
    settings[project.id].currency,
    fuelCostPerLtr || defaultInitialValues.fuelCostPerLtr,
  ),
  fuelTankCapacity: fuelTankCapacity.length
    ? fuelTankCapacity.map((capacity) => settings[project.id].computeVolume(capacity))
    : defaultInitialValues.fuelTankCapacity,
});

const mutationSerializer = (
  {
    name,
    daysAvailable,
    depots,
    wasteStations,
    manufacturer,
    manufacturerYear,
    licensePlateNumber,
    trim,
    model,
    vehicleType,
    vin,
    maxPayload,
    cargoVolume,
    fuelType,
    fuelTankCapacity,
    fixedCost,
    fuelCostPerKm,
    fuelCostPerLtr,
    fuelConsumption,
    status,
    hours,
    vehicleWasteCapacity,
    project,
  },
  photoFile,
  selfId,
) => ({
  selfId,
  name,
  logo: photoFile,
  daysAvailable,
  depots: [depots, ...wasteStations],
  manufacturer,
  manufacturerYear,
  licensePlateNumber,
  trim,
  model,
  vehicleTypeId: vehicleType,
  externalIdentifier: vin,
  fuelType,
  fuelTankCapacity: fuelTankCapacity.filter((el) => el !== ''),
  fixedCost: fixedCost || null,
  fuelCostPerKm: fuelCostPerKm || null,
  fuelCostPerLtr: fuelCostPerLtr || null,
  fuelUsagePerKm: fuelConsumption || null,
  status,
  hours,
  cargoVolume,
  maxPayload,
  wasteFractionCapacity: vehicleWasteCapacity,
  project,
});

const VehicleEdit = ({ match }) => {
  const history = useHistory();
  const { params: { id: vehicleId } = {} } = match;
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [photoFile, setPhotoFile] = useState();
  const settings = useSelector((state) => getUserSettings(state));
  const { forbidAddVehicle, isLoading, handleShowError } = useAllowAddVehicle();

  const editPage = !!vehicleId;

  const setSerilizedData = useCallback(
    async (vehicleData) => {
      const serialized = await vehicleSerializer(vehicleData, settings);
      setInitialValues(serialized);
    },
    [settings],
  );

  const [getVehicle, { data: { vehicle = {} } = {}, loading }] = useLazyQuery(vehicleQuery, {
    displayName: 'vehicle',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: { vehicleId },
    onCompleted: ({ vehicle: vehicleData }) => setSerilizedData(vehicleData, settings),
    onError: toastifyError,
  });

  const [mutation] = useMutation(updateOrCreateVehicleQuery);

  const editPageName = intl.formatMessage(
    {
      id: 'vehicle.edit_title',
      defaultMessage: 'Vehicle / {name}',
    },
    { name: vehicle.name },
  );
  const addPageName = intl.formatMessage({
    id: 'vehicle.add_title',
    defaultMessage: 'Vehicle / Add',
  });

  useEffect(() => {
    if (vehicleId) {
      getVehicle({ variables: { vehicleId } });
    }
  }, [getVehicle, vehicleId]);

  const onSubmit = useCallback(
    async (values, { setSubmitting, setFieldError, resetForm }) => {
      await prepareVehicleValues(values, settings);

      const variables =
        values.Type !== TYPES_CHOICES_VALUES.collector
          ? {
              ...values,
              cargoVolume: null,
              maxPayload: null,
              vehicleWasteCapacity: [],
            }
          : values;

      mutation({ variables: mutationSerializer(variables, photoFile, vehicleId) })
        .then(
          ({
            data: {
              updateOrCreateVehicle: { vehicle: savedVehicle },
            },
          }) => {
            toast.success(`Saved changes for - ${savedVehicle.name}`);
            return { obj: savedVehicle, action: values.action };
          },
        )
        .then(
          actionHandler({
            editPage,
            reInitForm: (obj) => setSerilizedData(obj),
            resetForm: () => {
              resetForm();
              setInitialValues(defaultInitialValues);
            },
            baseUrl: '/app/fleet-management/collector-vehicle',
            history,
            onEditPage: editPage,
            toDetail: true,
          }),
        )
        .catch((e) => {
          toastifyError(e);
          setFieldErrors(e?.graphQLErrors, setFieldError);
        })
        .finally(() => setSubmitting(false));
    },
    [editPage, settings, history, mutation, photoFile, vehicleId, setSerilizedData],
  );

  const isLoadingLayout = !editPage && isLoading;
  useEffect(() => {
    if (isLoadingLayout) return;
    if (!editPage && forbidAddVehicle) {
      handleShowError();
      history.push('/app/fleet-management/collector-vehicle');
    }
  }, [editPage, forbidAddVehicle, handleShowError, history, isLoadingLayout]);

  if (isLoadingLayout) {
    return <LoadingLayout />;
  }

  return (
    <Grid container>
      <HeaderRow pageTitle={editPage ? editPageName : addPageName} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={vehiclesEditValidationSchema()}
        validate={({ fuelCostPerKm, fuelConsumption, fuelCostPerLtr, fixedCost }) => {
          const message = intl.formatMessage({
            id: 'oneOfTheFieldsRequired',
            defaultMessage: 'One of the fields required',
          });
          const errors = {};

          if (!fuelCostPerKm && !fuelConsumption && !fuelCostPerLtr && !fixedCost) {
            errors.fuelCostPerKm = message;
            errors.fuelConsumption = message;
            errors.fuelCostPerLtr = message;
            errors.fixedCost = message;
          }

          return errors;
        }}
        onSubmit={onSubmit}
      >
        <Grid container>
          <FixedLoadingLayout open={loading} />
          <VehicleEditFrom setPhotoFile={setPhotoFile} />
        </Grid>
      </Formik>
    </Grid>
  );
};

VehicleEdit.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

// TODO connect to redux and get activeProjects to set initial projectId

export default VehicleEdit;
