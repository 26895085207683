import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { connect, Form, getIn } from 'formik';
import {
  FormikSelect,
  FormikTextField,
  FormikImageDropzone,
  FormikLocationInput,
  FormikTimeDiffFields,
} from '../../../../../shared/inputs/formik';
import { DEPOT_FORM_TYPES, DEPOTS_TYPE_VALUES, WORKSHOP_FORM_TYPES } from '../../components/utils';
import UploadImageButton from '../../../../../shared/buttons/uploadImageButton';
import formikInjectedPropsTypes from '../../../../../shared/inputs/formik/formikPropTypes';
import StyledCollapse from '../../../../../shared/animations/collapse';
import FormikTimePicker from '../../../../../shared/inputs/formik/FormikTimePicker';
import MultiSelectWasteFraction from '../../../../../shared/apiPopulatedSelects/multiSelectWasteFraction';
import SubmitButtonsSet from '../../../../../shared/buttons/submitButtons';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const useStyles = makeStyles((theme) => ({
  zoneLabel: {
    color: theme.variables.cEmperor,
    fontWeight: 500,
    fontSize: 22,
  },
  submitButton: {
    borderRadius: 32,
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'none',
    padding: 13,
  },
  root: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 45,
  },
  paddedRows: {
    '&>div': {
      paddingBottom: 20,
    },
  },
  paddedCollapseRows: {
    '& .MuiCollapse-wrapperInner': {
      '&>div': {
        paddingBottom: 20,
      },
    },
  },
  baseRows: {
    flexBasis: '43.6%',
    flexGrow: 0,
    marginRight: 95,
  },
  processing: {
    color: theme.variables.cDarkBlue,
    fontSize: 16,
    fontWeight: 500,
    marginRight: 15,
  },
}));

const DepotEditFrom = ({ setPhotoFile, formik, isSuperuser, companiesToSelect }) => {
  const intl = useIntl();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const depotType = getIn(formik.values, 'depotType');
  const [isRegularUser] = useRegularUser();

  const changeImage = useCallback(
    ({ file }) => {
      setPhotoFile(file);
    },
    [setPhotoFile],
  );

  return (
    <Form className="w-100">
      <Grid container className={classNames(classes.root, classes.paddedRows)}>
        <Grid container item xs={12} justify="space-between" className={classes.paddedRows}>
          {isRegularUser && (
            <Grid item xs={12} className="text-align-center">
              <FormattedMessage
                id="no_permission_add_location"
                defaultMessage="You don’t have permission to add location"
              >
                {(text) => <span className="no-permission-text">{text}</span>}
              </FormattedMessage>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="location.form.identification" defaultMessage="Identification" />
            </Typography>
          </Grid>
          <Grid container item xs={12} md={9} justify="space-between">
            <Grid container item xs={12} md={7} className={classes.paddedRows}>
              {isSuperuser && (
                <Grid item xs={12}>
                  <FormikSelect
                    label={intl.formatMessage({
                      id: 'label.choose_company',
                      defaultMessage: 'Choose company',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'placeholder.choose_company',
                      defaultMessage: 'Choose company',
                    })}
                    filledStyle
                    name="companyId"
                    onChange={(value) => {
                      formik.setFieldValue('companyId', value);
                    }}
                    valuesList={companiesToSelect}
                    labelClass={classNames(classes.inputLabel, 'm-t-10')}
                    fieldClass={classes.fieldClass}
                    withoutNoneChoice
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormikTextField
                  name="name"
                  autoFocus
                  required
                  label={intl.formatMessage({
                    id: 'location.form.label.name',
                    defaultMessage: 'Name',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikSelect
                  name="depotType"
                  filledStyle
                  required
                  label={intl.formatMessage({
                    id: 'location_type',
                    defaultMessage: 'Location type',
                  })}
                  valuesList={DEPOT_FORM_TYPES}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikLocationInput
                  label={intl.formatMessage({
                    id: 'location.form.label.address',
                    defaultMessage: 'Address',
                  })}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} className={classes.paddedRows}>
              <Grid item xs={12}>
                <TooltippedUserControl
                  tooltipText={
                    <FormattedMessage
                      id="tooltip.geofenceRadius"
                      defaultMessage="Choose geofence radius to which this depot belongs to"
                    />
                  }
                >
                  <FormikTextField
                    name="geofenceRadius"
                    type="number"
                    label={intl.formatMessage({
                      id: 'location.form.label.geofenceRadius',
                      defaultMessage: 'Geofence radius',
                    })}
                  />
                </TooltippedUserControl>
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  name="description"
                  multiline
                  rows={6}
                  rowsMax={6}
                  label={intl.formatMessage({
                    id: 'location.form.label.description',
                    defaultMessage: 'Description',
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={2}>
            <FormikImageDropzone size="small" onChange={changeImage} name="logo">
              <UploadImageButton />
            </FormikImageDropzone>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="flex-start" className={classes.paddedRows}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="location.form.contact_person" defaultMessage="Contact Person" />
            </Typography>
          </Grid>
          <Grid item xs={12} container className={classes.paddedRows}>
            <Grid item xs={12} className={classNames({ [classes.baseRows]: matches })}>
              <FormikTextField
                name="contactPerson.name"
                label={intl.formatMessage({
                  id: 'location.form.contactPerson.label.name',
                  defaultMessage: 'Name',
                })}
              />
            </Grid>
            <Grid item xs={12} className={classNames({ [classes.baseRows]: matches })}>
              <FormikTextField
                name="contactPerson.phone"
                label={intl.formatMessage({
                  id: 'location.form.contactPerson.phone',
                  defaultMessage: 'Phone',
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.paddedRows}>
            {matches && (
              <Grid item xs={12} className={classNames({ [classes.baseRows]: matches })} />
            )}
            <Grid item xs={12} className={classNames({ [classes.baseRows]: matches })}>
              <FormikTextField
                name="contactPerson.email"
                label={intl.formatMessage({
                  id: 'location.form.contactPerson.email',
                  defaultMessage: 'Email',
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="flex-start" className={classes.paddedRows}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="location.form.specification" defaultMessage="Specification" />
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              sm={8}
              md={5}
              lg={3}
              alignItems="center"
              justify="space-between"
              className={classes.paddedRows}
            >
              <FormikTimeDiffFields
                fromName="openingTime"
                toName="closingTime"
                labelInline={false}
                label={intl.formatMessage({
                  id: 'location.form.openingHours',
                  defaultMessage: 'Opening hours',
                })}
              />
            </Grid>
          </Grid>
          <StyledCollapse
            xs={12}
            in={depotType === DEPOTS_TYPE_VALUES.depot}
            className={classNames({ [classes.baseRows]: matches })}
          >
            <FormikTextField
              name="amountOfTrucks"
              type="number"
              label={intl.formatMessage({
                id: 'location.form.amountOfTrucks',
                defaultMessage: 'Amount of trucks',
              })}
            />
          </StyledCollapse>
          <StyledCollapse xs={12} in={depotType === DEPOTS_TYPE_VALUES.wasteStation}>
            <MultiSelectWasteFraction
              name="wasteFractions"
              wasteFractionFieldName="wasteFraction"
              defaultValue={{
                wasteFraction: '',
              }}
              serializer={({ wasteFraction }) => wasteFraction}
              inputClassName={classNames({ [classes.baseRows]: matches })}
              extraInputs={({ item, index }) => (
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={3}
                  lg={1}
                  className={classNames({ 'm-t-30': index === 0 })}
                  container
                  alignItems="center"
                >
                  <Typography variant="h6" noWrap className={classes.processing}>
                    {item?.wasteCategory}
                  </Typography>
                </Grid>
              )}
            />
          </StyledCollapse>
          <StyledCollapse md={12} in={depotType === DEPOTS_TYPE_VALUES.workshop}>
            <Grid container className={classes.paddedRows}>
              <Grid item xs={12} className={classNames({ [classes.baseRows]: matches })}>
                <FormikSelect
                  filledStyle
                  name="workshopType"
                  required={depotType === DEPOTS_TYPE_VALUES.workshop}
                  valuesList={WORKSHOP_FORM_TYPES}
                  label={intl.formatMessage({
                    id: 'location.form.workShopType',
                    defaultMessage: 'Type of service',
                  })}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.paddedRows}>
              <Grid item xs={12} className={classNames({ [classes.baseRows]: matches })}>
                <FormikTextField
                  multiline
                  rows={6}
                  rowsMax={6}
                  name="workShopServiceDescription"
                  label={intl.formatMessage({
                    id: 'location.form.label.workShopServiceDescription',
                    defaultMessage: 'Description',
                  })}
                />
              </Grid>
            </Grid>
          </StyledCollapse>
        </Grid>
        <StyledCollapse
          className={classes.paddedCollapseRows}
          in={depotType === DEPOTS_TYPE_VALUES.wasteStation}
        >
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.zoneLabel}>
              <FormattedMessage id="location.form.route_settings" defaultMessage="Route settings" />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            justify="space-between"
            className={classes.paddedRows}
          >
            <Grid item xs={12} md={5}>
              <FormikTimePicker
                name="timeToUnloadTruck"
                className={classes.maskedInput}
                placeholder="00:00"
                label={intl.formatMessage({
                  id: 'label.timeToUnloadTruck',
                  defaultMessage: 'Time to unload truck',
                })}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FormikTimePicker
                name="timeToLeaveTheFacility"
                className={classes.maskedInput}
                placeholder="00:00"
                label={intl.formatMessage({
                  id: 'label.timeToLeaveTheFacility',
                  defaultMessage: 'Time to leave the facility',
                })}
              />
            </Grid>
          </Grid>
        </StyledCollapse>
        {!isRegularUser && <SubmitButtonsSet />}
      </Grid>
    </Form>
  );
};

DepotEditFrom.propTypes = {
  isSuperuser: PropTypes.bool.isRequired,
  setPhotoFile: PropTypes.func.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  companiesToSelect: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  setFieldValue: PropTypes.func.isRequired,
};

DepotEditFrom.defaultProps = {
  companiesToSelect: [],
};

export default connect(DepotEditFrom);
