import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '../../../images/icons/checkboxOn.svg';
import CheckboxIcon from '../../../images/icons/checkboxOff.svg';

/* eslint-disable-next-line react/prop-types */
const StyledCheckbox = ({ isLast, allOptionRef, ...otherProps }) => {
  useEffect(() => {
    /* eslint-disable react/prop-types */
    if (isLast && allOptionRef?.current) {
      allOptionRef.current.focus();
    }
    /* eslint-enable react/prop-types */
  });

  return (
    <Checkbox
      {...otherProps}
      checkedIcon={<img src={CheckIcon} alt="checked" />}
      icon={<img src={CheckboxIcon} alt="unchecked" />}
    />
  );
};

export default withStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
}))(StyledCheckbox);
