import { makeStyles } from '@material-ui/core/styles';
import SidebarBackgroundColor from '../../../images/sidebar-bg.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '&>div': {
      position: 'fixed',
      height: '100vh',
      zIndex: 1200,
    },
    width: ({ closed }) => (closed ? 66 : 245),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sidebar: {
    width: 245,
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    backgroundSize: 'cover',
    backgroundImage: !theme.variables.noSidebarImage ? `url(${SidebarBackgroundColor})` : '',
  },
  openedSidebar: {
    width: 245,
    backgroundColor: theme.variables.sidebarBackgroundColor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  demoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
  },
  demoLayout: {
    backgroundColor: theme.variables.cAntiFlashWhite,
    color: theme.variables.VHLightBlue,
    borderRadius: 16,
    lineHeight: '28px',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
    padding: '6px 1px',
    transform: 'rotate(15deg)',
  },
  closedSidebar: {
    width: 66,
    backgroundColor: theme.variables.VHBlue,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  link: {
    color: theme.variables.cWhite,
    display: 'block',
    textDecoration: 'none',
    '&.active': {
      color: theme.variables.VHOrange,
    },
  },
  nav: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    padding: 0,
  },
  mainMenu: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  collapseHolder: {
    marginBottom: 0,
  },
  listItem: {
    padding: '12px 16px 12px 16px',
  },
  childListItem: {
    padding: '4px 16px 4px 36px',
    marginTop: 4,
    marginBottom: 4,
    '& .MuiTouchRipple-root': {
      margin: 0,
    },
  },
  closedChildItem: {
    padding: '4px 16px 4px 16px',
  },
  listIcon: {
    marginRight: 8,
    maxWidth: 20,
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    padding: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 0,

    '& span': {
      fontSize: '0.88rem',
    },
  },
  divider: {
    backgroundColor: theme.variables.VHOrange,
    margin: '0 10%',
  },
  expand: {
    padding: 0,
    color: theme.variables.VHOrange,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    padding: 0,
    transform: 'rotate(180deg)',
  },
  headerToggle: {
    backgroundColor: theme.variables.VHOrange,
    padding: 6,
    borderRadius: '16px 0 0 16px',
    alignSelf: 'flex-end',
    margin: 0,
    '&:hover': {
      backgroundColor: theme.variables.VHOrange,
    },
  },
  headerToggleOpen: {
    '& img': {
      transform: 'rotate(180deg)',
    },
  },
  logo: {
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  logoLink: {
    width: '100%',
  },
  imageContainer: {
    margin: 15,
    height: 70,
    display: 'flex',
    position: 'relative',
    flexShrink: 0,
  },
  closedImageContainer: {
    marginLeft: 0,
    marginRight: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  openedImageContainer: {
    marginLeft: 16,
    marginRight: 16,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default useStyles;
