import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ContainersMap from './routes/containers-map';
import RegisterContainerPage from './routes/register';
import AttachDevice from './routes/attachDevice';
import WhenNotFound from '../../../shared/whenNotFound';
import CreateDevicePage from './routes/createDevice';

import { getUsersAvailableProjects } from '../../../shared/utils';
import PickupSettings from './routes/PickupSettings';

function Containers({ match }) {
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));
  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={ContainersMap} />
      {!usersAvailableProjects?.length && <Redirect to={`${match.url}/`} />}
      <Route exact path={`${match.url}/:id/edit`} component={RegisterContainerPage} />
      <Route exact path={`${match.url}/add`} component={RegisterContainerPage} />
      <Route exact path={`${match.url}/pickup-settings`} component={PickupSettings} />
      <Route exact path={`${match.url}/attach`} component={AttachDevice} />
      <Route exact path={`${match.url}/add-sensor`} component={CreateDevicePage} />
      <Route exact path={`${match.url}/attach-to/:containerId`} component={AttachDevice} />
      <Route component={WhenNotFound} />
    </Switch>
  );
}

Containers.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
};

export default Containers;
