// eslint-disable-next-line import/prefer-default-export
export const getColorName = (fillLevel) => {
  if (fillLevel === undefined) {
    return 'grey';
  }
  if (fillLevel > 75) {
    return 'red';
  }
  if (fillLevel > 50) {
    return 'orange';
  }
  if (fillLevel > 25) {
    return 'yellow';
  }
  return 'green';
};
