import React, { useEffect } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import TranslationsProvider from './translations';
import createTheme from './components/main/theme';
import Login from './components/auth/login';
import ResetPassword from './components/auth/reset';
import ChangePassword from './components/auth/changePassword';
import history from './history';
import Main from './components/main';
import RegistrationByInvite from './components/main/routes/user-management/routes/registration';
import CreateCompany from './components/main/routes/user-management/routes/registration/createCompany';
import FavIcon from './images/favicon-32x32.png';
import TutorialProvider from './tutorial';
import UploadImagesForInquiries from './components/inquiries';
import { setNewCompanySettings } from './actions';
import { isLocalhost } from './serviceWorker';

const companySettingsQuery = loader('./components/graphql/queries/core/company_settings.graphql');

const App = ({ variables, handleSetNewCompanySettings }) => {
  const subDomain = window.location.host.split('.')[0];
  const theme = responsiveFontSizes(createTheme(variables));

  const [loadCompanySettings] = useLazyQuery(companySettingsQuery, {
    variables: {
      dns: subDomain,
    },
    onCompleted: (data) => {
      const { color1, color2, color3, color4, color5, fontFamily } = data.companySettings;
      const newCompanySettings = {};
      newCompanySettings.defaultFont = fontFamily;
      if (color1) {
        newCompanySettings.noSidebarImage = true;
        newCompanySettings.sidebarBackgroundColor = `#${color1}`;
      }
      if (color2) {
        newCompanySettings.HeaderColor = `#${color2}`;
      }
      if (color3) {
        newCompanySettings.buttonBlue = `#${color3}`;
      }
      if (color4) {
        newCompanySettings.clusterColor = `#${color4}`;
      }
      if (color5) {
        newCompanySettings.chipBackgroundColor = `#${color5}`;
      }
      handleSetNewCompanySettings(newCompanySettings);
    },
  });

  useEffect(() => {
    if (
      !isLocalhost &&
      !['dev', 'dev-platform', 'platform', 'stage', 'stage-platform', 'wastehero'].includes(
        subDomain,
      )
    ) {
      loadCompanySettings();
    }
  }, [subDomain, loadCompanySettings]);

  return (
    <div className="mainHolder">
      <Helmet>
        <title>WasteHero Platform</title>
        <meta name="description" content="WasteHero" />
        <link rel="icon" type="image/png" href={FavIcon} sizes="16x16" />
      </Helmet>
      <Router history={history}>
        <TranslationsProvider>
          <>
            <MuiThemeProvider theme={theme}>
              <TutorialProvider>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/company/create/" component={CreateCompany} />
                  <Route exact path="/registration/:email/:id" component={RegistrationByInvite} />
                  <Route exact path="/reset" component={ChangePassword} />
                  <Route exact path="/forgot-password" component={ResetPassword} />
                  <Route
                    exact
                    path="/inquiries-upload/:driverId/:inquiryId"
                    component={UploadImagesForInquiries}
                  />
                  <Route path="/app" component={Main} />
                  <Redirect to="/login" />
                </Switch>
              </TutorialProvider>
            </MuiThemeProvider>
            <ToastContainer enableMultiContainer position={toast.POSITION.BOTTOM_RIGHT} />
          </>
        </TranslationsProvider>
      </Router>
    </div>
  );
};

App.propTypes = {
  variables: PropTypes.shape({
    VHRed: PropTypes.string.isRequired,
    clusterColor: PropTypes.string.isRequired,
    VHRedDark: PropTypes.string.isRequired,
    VHGreen: PropTypes.string.isRequired,
    VHBlueDark: PropTypes.string.isRequired,
    VHGrey: PropTypes.string.isRequired,
    VHGreyLight: PropTypes.string.isRequired,
    cLightBlue: PropTypes.string.isRequired,
    cLightgrayBr: PropTypes.string.isRequired,
    cGrayInpBorder: PropTypes.string.isRequired,
    cGray: PropTypes.string.isRequired,
    cDGray: PropTypes.string.isRequired,
    cTextDark: PropTypes.string.isRequired,
    cEmperor: PropTypes.string.isRequired,
    noSidebarImage: PropTypes.bool.isRequired,
    defaultBoxShadow: PropTypes.string.isRequired,
    defaultFont: PropTypes.string.isRequired,
    cCharcoal: PropTypes.string.isRequired,
    VHBlue: PropTypes.string.isRequired,
    buttonBlue: PropTypes.string.isRequired,
    sidebarBackgroundColor: PropTypes.string.isRequired,
    VHDarkBlue: PropTypes.string.isRequired,
    VHLightBlue: PropTypes.string.isRequired,
    VHDarkGrey: PropTypes.string.isRequired,
    HeaderColor: PropTypes.string.isRequired,
    cDarkBlue: PropTypes.string.isRequired,
    cSilver: PropTypes.string.isRequired,
    cSlate: PropTypes.string.isRequired,
    cLightGray: PropTypes.string.isRequired,
    cSnow: PropTypes.string.isRequired,
    cAntiFlashWhite: PropTypes.string.isRequired,
    chipBackgroundColor: PropTypes.string.isRequired,
    cWhite: PropTypes.string.isRequired,
    VHLightBlack: PropTypes.string.isRequired,
    cRichOcean: PropTypes.string.isRequired,
    VHOrange: PropTypes.string.isRequired,
    cOrange: PropTypes.string.isRequired,
    cLightPeach: PropTypes.string.isRequired,
    cLightRed: PropTypes.string.isRequired,
    cGreen: PropTypes.string.isRequired,
    cLemon: PropTypes.string.isRequired,
    cDarkOrange: PropTypes.string.isRequired,
    cIndependence: PropTypes.string.isRequired,
    VHGreyBackground: PropTypes.string.isRequired,
    VHInputAdornment: PropTypes.string.isRequired,
  }).isRequired,
  handleSetNewCompanySettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  variables: state.settings.variables,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetNewCompanySettings: (newCompanySettings) => {
    dispatch(setNewCompanySettings(newCompanySettings));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
