import React, { useEffect } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import supportCategoryData from './navData';
import SupportSearch from './shared/supportSearch';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    color: theme.variables.cTextDark,
    marginTop: 30,
    userSelect: 'none',
  },
  title2: {
    fontSize: 26,
    color: theme.variables.cTextDark,
    padding: 10,
    userSelect: 'none',
  },
  supportCatGrid: {
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 50,
    marginRight: 50,
  },
  supportCatPaper: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 50,
    paddingBottom: 50,
    overflow: 'hidden',
    backgroundColor: theme.variables.cSnow,
    borderRadius: 15,
  },
  catImage: {
    maxHeight: 150,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  navLink: {
    textDecoration: 'none',
  },
  supportCatTitle: {
    fontSize: 18,
    color: theme.variables.cRichOcean,
    fontWeight: 'bold',
    paddingTop: 30,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    userSelect: 'none',
  },
  supportItemsTitle: {
    fontSize: 14,
    color: theme.variables.VHBlue,
    fontWeight: 'bold',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    userSelect: 'none',
    '&:hover': {
      color: theme.variables.cOrange,
    },
  },
  supportSearch: {
    marginTop: 20,
  },
}));

const SupportWelcome = ({ match, tabSelect }) => {
  const classes = useStyles();

  useEffect(() => {
    tabSelect('home');
  }, [tabSelect]);

  return (
    <Grid container justify="center" direction="column" spacing={2}>
      <Grid container spacing={0} justify="center" alignItems="center" direction="column">
        <Grid item xs={12}>
          <Typography align="center" className={classes.title}>
            <FormattedMessage
              id="welcome_support_center"
              defaultMessage="Welcome to the WasteHero support center"
            />
          </Typography>
          <Typography align="center" className={classes.title2}>
            <FormattedMessage
              id="support_center_title_second"
              defaultMessage="How can we help you?"
            />
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.supportSearch}>
          <SupportSearch categoryData={supportCategoryData} />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="center" spacing={5} className={classes.supportCatGrid}>
        {supportCategoryData.map((supportCategory) => (
          <Grid item xs={12 / supportCategoryData.length} key={supportCategory.name}>
            <Paper className={classes.supportCatPaper} elevation={0}>
              <img
                className={classes.catImage}
                src={supportCategory.image}
                alt={supportCategory.label}
              />
              <NavLink
                to={`${match.url}${supportCategory.routeLink}`}
                activeStyle={{ opacity: 1 }}
                className={classes.navLink}
              >
                <Typography className={classes.supportCatTitle}>{supportCategory.label}</Typography>
              </NavLink>
              {supportCategory.children.map((supportItems) => (
                <NavLink
                  to={`${match.url}${supportCategory.routeLink}${supportItems.routeLink}`}
                  activeStyle={{ opacity: 1 }}
                  className={classes.navLink}
                  key={supportItems.name}
                >
                  <Typography className={classes.supportItemsTitle} key={supportItems.name}>
                    {supportItems.label}
                  </Typography>
                </NavLink>
              ))}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
SupportWelcome.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  tabSelect: PropTypes.func.isRequired,
};

export default withRouter(withStyles({}, { withTheme: true })(SupportWelcome));
