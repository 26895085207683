import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import { loader } from 'graphql.macro';
import FormikTextField from '../../../../shared/inputs/formik/FormikTextField';

import LoadingLayout from '../../../../shared/loading';
import {
  maxLengthStringMessage,
  messageNoNegativeValues,
  maxNumberMessage,
  saveCacheRead,
  sleep,
  toastifyError,
} from '../../../../shared/utils';
import ConfirmDialog from '../../../../shared/dialog/ConfirmDialog';

import {
  emplacementChoices,
  mobilityChoices,
  vehicleCouplingChoices,
  volumeSizeOptions,
  emptyingMethodChoices,
  containerAccessChoices,
  CUSTOM_EMPTYING_METHOD,
} from './defaults/data';
import { setUser } from '../../../../../actions';
import SettingsCustomizeVolumeDialog from './ui/SettingsCustomizeVolumeDialog';
import ConfirmDeletionDialog from '../../../../shared/dialog/ConfirmDeletionDialog';
import ConfirmEmptySettingsDialog from './ui/ConfirmEmptySettingsDialog';
import {
  FormikCheckbox,
  FormikSelect,
  FormikImageDropzone,
} from '../../../../shared/inputs/formik';
import TooltippedUserControl from '../../../../shared/tooltip/TooltippedUserControl';
import { getSettingsForProject, checkIfRegularUser } from '../../../../shared/utils/settings';

import { TutorialContext } from '../../../../../tutorial';
import ContainerTypeChip from './containerTypeChip';
import OverflowingSettings from '../../../../shared/containers/OverflowingSettings';

const allContainerTypesQuery = loader(
  './../../../../graphql/queries/devices/all_container_types.graphql',
);
const createContainerTypeMutation = loader(
  './../../../../graphql/mutations/devices/create_container_type.graphql',
);
const updateContainerTypeMutation = loader(
  './../../../../graphql/mutations/devices/update_container_type.graphql',
);
const deleteContainerTypeMutation = loader(
  './../../../../graphql/mutations/devices/delete_container_type.graphql',
);

const useStyles = makeStyles((theme) => ({
  addedContainers: {
    border: '1px solid',
    borderColor: theme.variables.cOrange,
    borderRadius: '4px',
    padding: '16.5px',
    marginBottom: '74px',
  },
  photoContainer: {
    alignSelf: 'stretch',
    '& > .MuiPaper-root': {
      display: 'flex !important',
      height: '100%',
      '& > img': {
        maxWidth: '100%',
      },
    },
  },
  errorInputWithPopup: {
    '& .settings-popup-toggler': {
      borderColor: `${theme.variables.VHRedDark} !important`,
      '&>.MuiButton-label': {
        color: theme.variables.VHRedDark,
      },
    },
  },
  errorInput: {
    '& .MuiInputBase-formControl': {
      borderColor: `${theme.variables.VHRedDark} !important`,
    },
    '&>label': {
      color: theme.variables.VHRedDark,
    },
  },
  activeButton: {
    backgroundColor: theme.variables.buttonBlue,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.variables.VHBlueDark,
    },
  },
  disableEditText: {
    fontWeight: 400,
    lineHeight: '1.5rem',
    color: '#9aa8ad',
    fontSize: '1rem',
    padding: '0 16px 16px 16px',
  },
  internalDimensions: {
    paddingLeft: '36px !important',
  },
  duplicateButton: {
    marginBottom: 12,
  },
}));

const containerSettingsDefaultValues = {
  name: '',
  editMeasurements: false,
  isCylindrical: false,
  length: '',
  diameter: '',
  height: '',
  width: '',
  volume: '',
  volumeValue: 0,
  vehicleCoupling: [],
  emptyingTime: '',
  customEmptyingTime: '',
  emplacement: '',
  mobility: '',
  containerAccess: '',
  totalContainersCount: 0,
  customVolumeUnit: '',
  overflowingSettings: {
    percentage: 95,
    duration: '48:00:00',
  },
};

const containerSettingsValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().max(50, maxLengthStringMessage(50)).required(),
    length: Yup.number().when('isCylindrical', {
      is: false,
      then: Yup.number().positive(messageNoNegativeValues).required(),
    }),
    diameter: Yup.number().when('isCylindrical', {
      is: true,
      then: Yup.number().positive(messageNoNegativeValues).required(),
    }),
    height: Yup.number().positive(messageNoNegativeValues).required(),
    width: Yup.number().when('isCylindrical', {
      is: false,
      then: Yup.number().positive(messageNoNegativeValues).required(),
    }),
    volume: Yup.string().required(),
    emplacement: Yup.string().required(),
    volumeValue: Yup.number().positive(messageNoNegativeValues).required(),
    emptyingTime: Yup.mixed().oneOf([CUSTOM_EMPTYING_METHOD, 2, 15]).required(),
    customEmptyingTime: Yup.number().when('emptyingTime', {
      is: CUSTOM_EMPTYING_METHOD,
      then: Yup.number().positive(messageNoNegativeValues).required(),
    }),
    containerAccess: Yup.string(),
    mobility: Yup.string().when('emplacement', {
      is: 'Surface',
      then: Yup.string().required(),
    }),
    vehicleCoupling: Yup.array().required(),
    totalContainersCount: Yup.number(),
    customVolumeUnit: Yup.string().when('volume', {
      is: 'Customize',
      then: Yup.string().required(),
    }),
    overflowingSettings: Yup.object().shape({
      duration: Yup.string(),
      percentage: Yup.number()
        .min(
          1,
          <FormattedMessage
            id="overflowingSettings.percentage_min"
            defaultMessage="This field should be more than 1."
          />,
        )
        .max(
          100,
          <FormattedMessage
            id="overflowingSettings.percentage_max"
            defaultMessage="This field should be less than 100."
          />,
        ),
    }),
    customVolume: Yup.number().when('volume', {
      is: 'Customize',
      then: Yup.number()
        .positive(messageNoNegativeValues)
        .max(100000, maxNumberMessage(100000))
        .required(),
    }),
  });

const ContainerSettings = ({ projectId, history, me }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { isTutorialOpen } = useContext(TutorialContext);
  const projectSettings = getSettingsForProject(projectId);
  const endAdornment = projectSettings.measuringDistanceVariant.toLowerCase();
  const [editingId, setEditingId] = useState('');
  const [deletingContainerType, setDeletingContainerType] = useState('');
  const [emptySettingsConfirmationDialog, setEmptySettingsConfirmationDialog] = useState('');

  const [newPhoto, setNewPhoto] = useState('');
  const [newPhotoUrl, setNewPhotoUrl] = useState('');
  const [newProjectContainerType, setNewProjectContainerType] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isEditContainerOpen, setIsEditContainerOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [preventVolumeSelActions, setPreventVolumeSelActions] = useState(false);
  const [containerTypeName, setContainerTypeName] = useState('');

  const containerTypesQueryOptions = { variables: { projectId }, fetchPolicy: 'cache-and-network' };

  const toggleEditConfirm = () => setIsConfirmOpen(!isConfirmOpen);
  const toggleMessageConfirm = () => setIsMessageOpen(!isMessageOpen);

  const {
    loading: isContainerTypesLoading,
    data: { allContainerTypes: { edges: containerTypes = [] } = {} } = {},
  } = useQuery(allContainerTypesQuery, containerTypesQueryOptions);

  const [createContainerType, { loading: createLoading }] = useMutation(
    createContainerTypeMutation,
  );

  const [updateContainerType, { loading: updateLoading }] = useMutation(
    updateContainerTypeMutation,
  );

  const [deleteContainerType, { loading: deleteLoading }] = useMutation(
    deleteContainerTypeMutation,
    {
      refetchQueries: [{ query: allContainerTypesQuery, ...containerTypesQueryOptions }],
    },
  );

  const isProjectOwner = me.ownProjectsIds.includes(projectId);
  const isRegularUser = checkIfRegularUser(me) && !isProjectOwner;

  const loading =
    globalLoading || isContainerTypesLoading || deleteLoading || createLoading || updateLoading;

  const cancelEdit = (resetForm) => {
    setEditingId('');
    setNewPhoto('');
    setNewPhotoUrl('');
    resetForm();
  };

  const onDuplicateContainerType = (name, setFieldValue) => {
    const newName = containerTypeName === name ? `${name} (copy)` : name;

    setEditingId('');
    setFieldValue('name', newName);

    toast.info(
      intl.formatMessage({
        id: 'toast.container_data_copied',
        defaultMessage: 'Container data was copied',
      }),
    );
  };

  const formSubmitHandler = async (values, { setSubmitting, resetForm, setFieldError }) => {
    const divider = values.volumeUnit === 'L' ? 1000 : 1;
    const isEditMode = Boolean(editingId);
    const variables = {
      projectId,
      ...values,
      length: values.length || 0,
      height: values.height || 0,
      width: values.width || 0,
      diameter: values.diameter || 0,
      overflowingSettings:
        values.overflowingSettings.duration && values.overflowingSettings.percentage
          ? values.overflowingSettings
          : null,
      containerTypePhoto: newPhoto || newPhotoUrl,
      volume: (values.volumeValue / divider).toFixed(4),
      emptyingTime: [2, 15].includes(values.emptyingTime)
        ? values.emptyingTime
        : values.customEmptyingTime,
    };
    if (
      containerTypes.some(
        (containerNode) =>
          containerNode.node.name === values.name && containerNode.node.id !== editingId,
      )
    ) {
      setFieldError(
        'name',
        <FormattedMessage
          id="container_settings_name_exists"
          defaultMessage="Container settings with name {name} already exists in this company"
          values={{ name: values.name }}
        />,
      );
      setSubmitting(false);
      if (isConfirmOpen) toggleEditConfirm();
      return;
    }
    setGlobalLoading(true);

    let mutation = createContainerType;
    let returnQueryName = 'createContainerType';

    if (editingId) {
      mutation = updateContainerType;
      variables.selfId = editingId;
      returnQueryName = 'updateContainerType';
      if (isConfirmOpen) toggleEditConfirm();
    }

    mutation({
      variables,
      update: async (cache, { data: { [returnQueryName]: newContainerType } }) => {
        const {
          data: { allContainerTypes: allContainerTypesData },
        } = await saveCacheRead({ query: allContainerTypesQuery, variables: { projectId } });

        const newContainerTypes = { ...allContainerTypesData };
        if (editingId) {
          newContainerTypes.edges = allContainerTypesData.edges.filter(
            ({ node }) => node.id !== newContainerType.containerType.id,
          );
        }

        newContainerTypes.edges = [
          ...newContainerTypes.edges,
          {
            node: newContainerType.containerType,
            __typename: 'ContainerTypeTypeEdge',
          },
        ];

        cache.writeQuery({
          query: allContainerTypesQuery,
          variables: { projectId },
          data: { allContainerTypes: newContainerTypes },
        });
      },
    })
      .then((response) => {
        cancelEdit(resetForm);
        if (!isEditMode) {
          const { id } = response.data.createContainerType.containerType;

          setNewProjectContainerType(id);
        }
        toast.info(
          intl.formatMessage({
            id: 'toast.container_saved',
            defaultMessage: 'Container type successfully saved',
          }),
        );
      })
      .catch((error) => {
        toastifyError(error);
        setSubmitting(false);
      })
      .finally(() => {
        setGlobalLoading(false);
      });
  };

  const selectVolume = (setValues, validateForm, oldValues) => (value) => {
    const values = { ...oldValues };

    if (value === 'Customize') {
      if (!preventVolumeSelActions) {
        setIsModalOpen(true);
      }
    } else {
      const selected = volumeSizeOptions.find((option) => option.value === value);

      if (selected) {
        if (!preventVolumeSelActions) {
          if (selected.params.isCylindrical) {
            values.length = 0;
            values.width = 0;
            values.diameter = selected.params.diameter;
          } else {
            values.length = selected.params.length;
            values.width = selected.params.width;
            values.diameter = 0;
          }
          values.isCylindrical = !!selected.params.isCylindrical;
          values.height = selected.params.height;
        }
        values.volumeValue = selected.params.volume;

        setIsModalOpen(false);
      }
    }
    setPreventVolumeSelActions(false);

    values.volume = value;
    setValues(values, true);

    // Force validation in next tick
    sleep(0).then(() => validateForm());
  };

  const handleOnCustomizeClick = (event) => {
    if (event.nativeEvent.target.dataset.value === 'Customize') {
      setIsModalOpen(true);
    }
  };

  const handleChangePhotoUrl = (e) => {
    if (isRegularUser) {
      if (e.file) {
        const reader = new FileReader();
        reader.readAsDataURL(e.file);
        reader.onload = () => {
          setNewPhoto(reader.result);
          setNewPhotoUrl(e.url);
        };
      } else {
        setNewPhoto('');
        setNewPhotoUrl('');
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteContainerType = (e) => {
    e.preventDefault();
    const variables = { selfId: deletingContainerType.id };

    return deleteContainerType({
      variables,
      update: async (
        cache,
        {
          data: {
            deleteContainerType: { status },
          },
        },
      ) => {
        if (status !== 'Success') {
          throw new Error(`Container type could not be removed: ${status}`);
        }

        const {
          data: { allContainerTypes: allContainerTypesData },
        } = await saveCacheRead({ query: allContainerTypesQuery, variables: { projectId } });

        const newContainerTypes = {
          ...allContainerTypesData,
          edges: allContainerTypesData.edges.filter(
            ({ node }) => node.id !== deletingContainerType.id,
          ),
        };

        cache.writeQuery({
          query: allContainerTypesQuery,
          variables: { projectId },
          data: { allContainerTypes: newContainerTypes },
        });
      },
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'toast.container_removed',
            defaultMessage: 'Container type successfully removed',
          }),
        );
      })
      .catch((error) => {
        toastifyError(error);
      })
      .finally(() => {
        setDeletingContainerType('');
      });
  };

  const editContainerType = (containerType, setValues) => () => {
    setPreventVolumeSelActions(true);

    const values = { ...containerSettingsDefaultValues };

    setEditingId(containerType.id);
    setContainerTypeName(containerType.name);

    values.name = containerType.name;
    values.totalContainersCount = containerType.containerSet?.totalCount || 0;
    values.vehicleCoupling = containerType.vehicleCoupling;

    values.emplacement = containerType.emplacement;
    if ([2, 15].includes(containerType.emptyingTime)) {
      values.emptyingTime = containerType.emptyingTime || '';
      values.customEmptyingTime = 0;
    } else {
      values.customEmptyingTime = containerType.emptyingTime;
      values.emptyingTime = CUSTOM_EMPTYING_METHOD;
    }

    values.mobility = containerType.mobility || '';
    values.containerAccess = containerType.containerAccess || '';

    values.isCylindrical = containerType.isCylindrical || false;

    const correspondingVolumeSize = volumeSizeOptions.find(
      (volumeSize) => volumeSize.params?.volume === containerType.volume,
    );

    if (correspondingVolumeSize) {
      values.volume = correspondingVolumeSize.value;
      values.volumeUnit = containerType.volumeUnit;
      values.volumeValue = containerType.volume;
    } else {
      values.volume = 'Customize';

      const volumeUnit = containerType.volume % 1 === 0 ? 'm³' : 'L';
      const divider = volumeUnit === 'L' ? 1000 : 1;

      values.volumeValue = containerType.volume * divider;
      values.volumeUnit = volumeUnit;
      values.customVolume = values.volumeValue;
      values.customVolumeUnit = values.volumeUnit;
    }

    values.overflowingSettings = (containerType.overflowingSettings && {
      percentage: containerType.overflowingSettings.percentage,
      duration: containerType.overflowingSettings.duration,
    }) || {
      percentage: 95,
      duration: '48:00:00',
    };
    values.length = containerType.length || 0;
    values.width = containerType.width || 0;
    values.height = containerType.height || 0;
    values.diameter = containerType.diameter || 0;

    values.photoUrl = containerType.photoUrl;

    setValues(values);

    setNewPhotoUrl(containerType.photoUrl);
  };

  const removeContainerType = (containerType) => () => {
    setDeletingContainerType(containerType);
  };

  const getCustomLabel = (values) => {
    let label = <FormattedMessage id="placeholder.volume" defaultMessage="Choose volume" />;
    if (values.volume === 'Customize') {
      const volumeValue =
        values.volumeValue ||
        intl.formatMessage({
          id: 'placeholder.volume',
          defaultMessage: 'Choose volume',
        });
      label = `Customize (${volumeValue}${values.volumeUnit ? ' ' : ''}${values.volumeUnit || ''})`;
    } else if (values.volume) {
      const foundLabel = volumeSizeOptions.find((option) => option.value === values.volume).label;
      if (foundLabel) label = foundLabel;
    }
    return {
      value: 'Customize',
      label,
    };
  };

  const handleNavigationButtonClick = (link) => {
    if (containerTypes.length > 0 || isTutorialOpen()) {
      history.push(link);
    } else {
      setEmptySettingsConfirmationDialog(link);
    }
  };

  const getContainerTypeChip = (node, newProjectContainerId, setValues) => {
    const isNewContainerType = newProjectContainerId === node.id;

    return (
      <ContainerTypeChip
        key={`chip_${node.id}`}
        node={node}
        isRegularUser={isRegularUser}
        isNewContainerType={isNewContainerType}
        setValues={setValues}
        editContainerType={editContainerType}
        removeContainerType={removeContainerType}
        toggleMessageConfirm={toggleMessageConfirm}
      />
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingLayout isLoading={loading} />
      ) : (
        <Formik
          initialValues={containerSettingsDefaultValues}
          onSubmit={formSubmitHandler}
          validationSchema={containerSettingsValidationSchema}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            validateForm,
            resetForm,
            submitForm,
            setValues,
            setFieldTouched,
          }) => (
            <Form className="w-100 m-t-40 p-b-50">
              <LoadingLayout isLoading={isSubmitting} />
              <Grid
                container
                alignItems="center"
                justify="center"
                className="m-b-10"
                spacing={4}
                tourid="containerTypeDetails"
              >
                <Grid container item xs={12} md={7} spacing={2} direction="column">
                  {isRegularUser && (
                    <FormattedMessage
                      id="no_permission_edit_container_settings"
                      defaultMessage="You don’t have permission to add or edit container settings"
                    >
                      {(text) => <span className={classes.disableEditText}>{text}</span>}
                    </FormattedMessage>
                  )}
                  <Grid container item xs={12} spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.enter_container_type"
                            defaultMessage="Enter a name for the container type"
                          />
                        }
                      >
                        <FormikTextField
                          name="name"
                          label={intl.formatMessage({
                            id: 'label.container_type',
                            defaultMessage: 'Container type name',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.container_type',
                            defaultMessage: "E.g. 'Frontloader 700 L'",
                          })}
                          required
                          className="m-b-10 w-100"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.container_emplacement"
                            defaultMessage="Choose where the container is placed"
                          />
                        }
                      >
                        <FormikSelect
                          name="emplacement"
                          label={intl.formatMessage({
                            id: 'emplacement',
                            defaultMessage: 'Emplacement',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'label.container_emplacement',
                            defaultMessage: 'Choose emplacement',
                          })}
                          required
                          valuesList={emplacementChoices}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(value) => setFieldValue('emplacement', value)}
                          filledStyle
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.vehicleCoupling"
                            defaultMessage="Choose the coupling of the container with the pick-up vehicle"
                          />
                        }
                      >
                        <FormikSelect
                          name="vehicleCoupling"
                          label={intl.formatMessage({
                            id: 'label.vehicleCoupling',
                            defaultMessage: 'Vehicle coupling',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.vehicleCoupling',
                            defaultMessage: 'Choose vehicle coupling',
                          })}
                          required
                          valuesList={vehicleCouplingChoices}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(value) => setFieldValue('vehicleCoupling', value)}
                          filledStyle
                          multiSelect
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.emptyingTime"
                            defaultMessage="Choose how much time it would take to empty the container"
                          />
                        }
                      >
                        <FormikSelect
                          name="emptyingTime"
                          label={intl.formatMessage({
                            id: 'label.emptyingTime',
                            defaultMessage: 'Emptying time (mins)',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.emptyingTime',
                            defaultMessage: 'Choose emptying time',
                          })}
                          required
                          valuesList={emptyingMethodChoices}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(value) => setFieldValue('emptyingTime', value)}
                          filledStyle
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={4}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classNames({
                        [classes.errorInputWithPopup]: errors.volume && touched.volume,
                      })}
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.volume"
                            defaultMessage="Choose the volume of the container"
                          />
                        }
                      >
                        <FormikSelect
                          name="volume"
                          label={intl.formatMessage({
                            id: 'label.volume',
                            defaultMessage: 'Volume',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'placeholder.volume',
                            defaultMessage: 'Choose volume',
                          })}
                          required
                          valuesList={
                            values.volume === 'Customize'
                              ? [
                                  ...volumeSizeOptions.filter((elem) => elem.value !== 'Customize'),
                                  getCustomLabel(values),
                                ]
                              : volumeSizeOptions
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={selectVolume(setValues, validateForm, values)}
                          filledStyle
                          onOptionClick={handleOnCustomizeClick}
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <OverflowingSettings />
                    <Grid item xs={12} md={6} spacing={4}>
                      {values.emplacement === 'Surface' && (
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.mobility"
                              defaultMessage="Choose the mobility option for the container"
                            />
                          }
                        >
                          <FormikSelect
                            name="mobility"
                            label={intl.formatMessage({
                              id: 'label.mobility',
                              defaultMessage: 'Mobility',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'placeholder.mobility',
                              defaultMessage: 'Choose mobility',
                            })}
                            required
                            valuesList={mobilityChoices}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(value) => setFieldValue('mobility', value)}
                            className="m-b-15"
                            filledStyle
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      )}
                      {values.emplacement === 'Semi-underground' && (
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.containerAccess"
                              defaultMessage="Choose how to access the container"
                            />
                          }
                        >
                          <FormikSelect
                            name="containerAccess"
                            label={intl.formatMessage({
                              id: 'label.containerAccess',
                              defaultMessage: 'Container access',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'placeholder.containerAccess',
                              defaultMessage: 'Choose Container access',
                            })}
                            valuesList={containerAccessChoices}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(value) => setFieldValue('containerAccess', value)}
                            className="m-b-15"
                            filledStyle
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      )}
                      {values.emptyingTime === CUSTOM_EMPTYING_METHOD && (
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage id="tooltip.customize" defaultMessage="Customize" />
                          }
                        >
                          <FormikTextField
                            name="customEmptyingTime"
                            label={intl.formatMessage({
                              id: 'label.customEmptyingTime',
                              defaultMessage: 'Customize emptying time',
                            })}
                            required
                            type="number"
                            InputProps={{
                              required: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FormattedMessage id="tooltip.min" defaultMessage="min" />
                                </InputAdornment>
                              ),
                            }}
                            className={classNames({
                              [classes.errorInput]: errors.length && touched.length,
                            })}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3} className={classes.photoContainer}>
                  <FormikImageDropzone
                    size="small"
                    name="containerTypePhoto"
                    id="containerTypePhoto"
                    onChange={handleChangePhotoUrl}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                direction="column"
                className="p-t-30"
                tourid="containerTypeDimensions"
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={4}
                  tourid="containerTypeDetails"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    md={7}
                    spacing={2}
                    direction="column"
                    className={classes.internalDimensions}
                  >
                    <Typography variant="body1" color="secondary">
                      <FormattedMessage
                        id="internal_dimensions"
                        defaultMessage="Internal dimensions"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} />
                </Grid>
                <Grid container item xs={12} justify="center" spacing={2} className="p-t-20">
                  <Grid container item xs={7} justify="flex-start" spacing={4}>
                    <Grid item xs={3}>
                      <FormGroup>
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.editMeasurements"
                              defaultMessage="Select this if you want to change the default internal dimensions"
                            />
                          }
                          avoidIcon
                        >
                          <FormControlLabel
                            control={<FormikCheckbox name="editMeasurements" />}
                            label={intl.formatMessage({
                              id: 'label.editMeasurements',
                              defaultMessage: 'Edit measurements',
                            })}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.isCylindrical"
                              defaultMessage="Select this if the container is cylindrically shaped. The internal dimensions required will change."
                            />
                          }
                          avoidIcon
                        >
                          <FormControlLabel
                            control={<FormikCheckbox name="isCylindrical" />}
                            label={intl.formatMessage({
                              id: 'label.isCylindrical',
                              defaultMessage: 'Cylinder shaped',
                            })}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={3}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.correctHeight"
                            defaultMessage="Enter the distance from the sensor glass to the bottom of the container"
                          />
                        }
                      >
                        <FormikTextField
                          name="height"
                          label={intl.formatMessage({
                            id: 'label.height',
                            defaultMessage: 'Height',
                          })}
                          required
                          type="number"
                          InputProps={{
                            required: true,
                            readOnly: !values.editMeasurements,
                            endAdornment: (
                              <InputAdornment position="end">{endAdornment}</InputAdornment>
                            ),
                          }}
                          className={classNames('m-b-10 w-100', {
                            [classes.errorInput]: errors.height && touched.height,
                          })}
                          disabled={isRegularUser}
                        />
                      </TooltippedUserControl>
                    </Grid>
                    <Grid item xs={3}>
                      {values.isCylindrical ? (
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.diameter"
                              defaultMessage="Enter the internal diameter of the container"
                            />
                          }
                        >
                          <FormikTextField
                            name="diameter"
                            label={intl.formatMessage({
                              id: 'label.diameter',
                              defaultMessage: 'Diameter',
                            })}
                            required
                            type="number"
                            InputProps={{
                              required: true,
                              readOnly: !values.editMeasurements,
                              endAdornment: (
                                <InputAdornment position="end">{endAdornment}</InputAdornment>
                              ),
                            }}
                            className={classNames('m-b-10 w-100', {
                              [classes.errorInput]: errors.diameter && touched.diameter,
                            })}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      ) : (
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.length"
                              defaultMessage="Enter the internal length of the container"
                            />
                          }
                        >
                          <FormikTextField
                            name="length"
                            label={intl.formatMessage({
                              id: 'label.length',
                              defaultMessage: 'Length',
                            })}
                            required
                            type="number"
                            InputProps={{
                              required: true,
                              readOnly: !values.editMeasurements,
                              endAdornment: (
                                <InputAdornment position="end">{endAdornment}</InputAdornment>
                              ),
                            }}
                            className={classNames('m-b-10 w-100', {
                              [classes.errorInput]: errors.length && touched.length,
                            })}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {!values.isCylindrical && (
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.width"
                              defaultMessage="Enter the internal width of the container"
                            />
                          }
                        >
                          <FormikTextField
                            name="width"
                            label={intl.formatMessage({
                              id: 'label.width',
                              defaultMessage: 'Width',
                            })}
                            type="number"
                            required
                            InputProps={{
                              required: true,
                              readOnly: !values.editMeasurements,
                              endAdornment: (
                                <InputAdornment position="end">{endAdornment}</InputAdornment>
                              ),
                            }}
                            className={classNames('m-b-10 w-100', {
                              [classes.errorInput]: errors.width && touched.width,
                            })}
                            disabled={isRegularUser}
                          />
                        </TooltippedUserControl>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                className="p-t-30"
                justify="center"
                tourid="containerTypeList"
              >
                {!isRegularUser && (
                  <Grid item xs={2}>
                    {editingId && (
                      <Button
                        variant="outlined"
                        size="large"
                        className={classNames(
                          'w-100 settings-control-button',
                          classes.activeButton,
                          classes.duplicateButton,
                        )}
                        startIcon={<EditIcon />}
                        type="button"
                        onClick={() => onDuplicateContainerType(values.name, setFieldValue)}
                      >
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.button.duplicate_container"
                              defaultMessage="Click to duplicate form data"
                            />
                          }
                          avoidIcon
                        >
                          <Typography variant="body1" className="p-r-20">
                            <FormattedMessage
                              id="duplicate_container_type"
                              defaultMessage="Duplicate and edit type"
                            />
                          </Typography>
                        </TooltippedUserControl>
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      size="large"
                      className={classNames('w-100 settings-control-button', classes.activeButton)}
                      startIcon={editingId ? <EditIcon /> : <AddIcon />}
                      type="button"
                      onClick={
                        editingId && values.totalContainersCount > 0
                          ? toggleEditConfirm
                          : submitForm
                      }
                    >
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.button.save_container"
                            defaultMessage="Click to save the details of the container type"
                          />
                        }
                        avoidIcon
                      >
                        <Typography variant="body1" className="p-r-20">
                          {editingId ? (
                            <FormattedMessage
                              id="save_container_type"
                              defaultMessage="Save container type"
                            />
                          ) : (
                            <FormattedMessage
                              id="add_container_type"
                              defaultMessage="Add container type"
                            />
                          )}
                        </Typography>
                      </TooltippedUserControl>
                    </Button>
                    {editingId && (
                      <Button
                        variant="outlined"
                        className="w-100 settings-control-button m-t-20"
                        startIcon={<BlockIcon />}
                        type="button"
                        onClick={() => cancelEdit(resetForm)}
                      >
                        <TooltippedUserControl
                          tooltipText={
                            <FormattedMessage
                              id="tooltip.button.cancel_edit"
                              defaultMessage="Click to cancel the edited details without saving"
                            />
                          }
                          avoidIcon
                        >
                          <Typography variant="body1" className="p-r-20">
                            <FormattedMessage id="cancel_edit" defaultMessage="Cancel edit" />
                          </Typography>
                        </TooltippedUserControl>
                      </Button>
                    )}
                  </Grid>
                )}

                <Grid item xs={isRegularUser ? 11 : 8}>
                  <Paper className={classes.addedContainers}>
                    <InputLabel shrink>
                      <FormattedMessage
                        id="added_container_types"
                        defaultMessage="Added container types"
                      />
                    </InputLabel>
                    {containerTypes &&
                      containerTypes.map(({ node }) =>
                        getContainerTypeChip(node, newProjectContainerType, setValues),
                      )}
                  </Paper>
                </Grid>
              </Grid>

              <Grid container spacing={2} justify="center">
                <Grid item xs={3} container justify="flex-end">
                  <Button
                    variant="outlined"
                    className="w-60 p-r-40 settings-control-button"
                    startIcon={<ChevronLeftIcon />}
                    type="button"
                    onClick={() =>
                      handleNavigationButtonClick(`/app/settings/${projectId}/project-settings`)
                    }
                  >
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.container_settings.back"
                          defaultMessage="Move to project settings"
                        />
                      }
                      avoidIcon
                    >
                      <Typography variant="body1" className="p-r-20">
                        <FormattedMessage id="back" defaultMessage="Back" />
                      </Typography>
                    </TooltippedUserControl>
                  </Button>
                </Grid>
                <Grid item xs={3} container justify="flex-start">
                  <Button
                    variant="outlined"
                    className="w-60 p-l-40 settings-control-button"
                    endIcon={<ChevronRightIcon />}
                    type="button"
                    onClick={() => {
                      if (
                        editingId !== '' ||
                        JSON.stringify(containerSettingsDefaultValues) !== JSON.stringify(values)
                      ) {
                        setIsEditContainerOpen(true);
                        toggleEditConfirm();
                      } else {
                        handleNavigationButtonClick(
                          `/app/settings/${projectId}/waste-fractions-settings`,
                        );
                      }
                    }}
                    tourid="nextButton"
                  >
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.container_settings.next"
                          defaultMessage="Move to waste fraction settings"
                        />
                      }
                      avoidIcon
                    >
                      <Typography variant="body1" className="p-l-20">
                        <FormattedMessage id="next" defaultMessage="Next" />
                      </Typography>
                    </TooltippedUserControl>
                  </Button>
                </Grid>
              </Grid>
              <SettingsCustomizeVolumeDialog
                open={isModalOpen}
                onClose={handleCloseModal}
                volumeUnit={values.volumeUnit}
                volumeValue={values.volumeValue}
                setVolumeValue={(value) => setFieldValue('volumeValue', value)}
                setVolumeUnit={(value) => setFieldValue('volumeUnit', value)}
                validateForm={validateForm}
                setFieldTouched={setFieldTouched}
              />
              <ConfirmDeletionDialog
                open={!!deletingContainerType}
                onConfirm={(e) => {
                  handleDeleteContainerType(e);
                  cancelEdit(resetForm);
                }}
                onClose={() => setDeletingContainerType('')}
                title={deletingContainerType ? deletingContainerType.name : ''}
              />
              <ConfirmEmptySettingsDialog
                open={!!emptySettingsConfirmationDialog}
                onConfirm={() => history.push(emptySettingsConfirmationDialog)}
                onClose={() => setEmptySettingsConfirmationDialog('')}
                settingsTitle={
                  <FormattedMessage id="container_types" defaultMessage="container types" />
                }
              />
              <ConfirmDialog
                open={isConfirmOpen}
                onClose={toggleEditConfirm}
                onConfirm={async () => {
                  if (isEditContainerOpen) {
                    await handleNavigationButtonClick(
                      `/app/settings/${projectId}/waste-fractions-settings`,
                    );
                    await setIsEditContainerOpen(false);
                  } else {
                    await submitForm();
                  }
                }}
                onExited={() => setIsEditContainerOpen(false)}
                title={
                  <FormattedMessage
                    id="container_types.edit_confirmation"
                    defaultMessage={
                      !isEditContainerOpen
                        ? `Are you sure, that you want change the container settings? This will affect and change ${
                            values.totalContainersCount
                          } ${
                            values.totalContainersCount > 1
                              ? 'containers which are'
                              : 'container which is'
                          } registered to this container type. The future measurement will be based on the new settings.`
                        : 'Are you sure, that you want to continue without saving the container type?'
                    }
                  />
                }
              />
              <ConfirmDialog
                open={isMessageOpen}
                onClose={toggleMessageConfirm}
                onConfirm={toggleMessageConfirm}
                title={
                  <FormattedMessage
                    id="container_type_delete_error"
                    defaultMessage="Before deleting a container type, please change the container type in containers that are using it"
                  />
                }
                noDelete
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

ContainerSettings.propTypes = {
  projectId: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isReseller: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(withRouter(ContainerSettings)));
