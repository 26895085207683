import * as Yup from 'yup';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const routeSettingsValidationSchema = () =>
  Yup.object().shape({
    wasteSchemeName: Yup.string().required(),
    selectedProject: Yup.string().required(),
    selectedWasteFraction: Yup.string().required(),
    selectedContainerType: Yup.string().required(),
    emptyContainersAt: Yup.number()
      .required()
      .positive(
        <FormattedMessage id="route_settings.validation.invalid" defaultMessage="Invalid value" />,
      )
      .max(
        100,
        <FormattedMessage
          id="route_settings.validation.empty_at"
          defaultMessage="Should be less than or equal to 100%"
        />,
      ),
    scheduleAhead: Yup.string().nullable(),
  });

export default routeSettingsValidationSchema;
