import React, { useCallback, useMemo } from 'react';
import { loader } from 'graphql.macro';
import FormikAsyncSelect from '@wastehero/storybook/lib/components/Form/Elements/Select/Async/Formik';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getSavedActiveProjects } from '../../../../shared/utils';
// eslint-disable-next-line no-unused-vars
import ContainerTypeSelect from '../container-type';

const allContainerQuery = loader(
  './../../../../graphql/queries/devices/all_containers_dashboard.graphql',
);

const ContainerSelect = ({ multiple }) => {
  const availableProjects = useSelector((state) => getSavedActiveProjects(state));
  const queryResultFormatter = useCallback(
    (values) =>
      (values?.allContainers?.edges || []).map(({ node: { containerId } }) => ({
        key: containerId,
        label: `Container #${containerId}`,
        value: containerId,
      })),
    [],
  );

  const queryVariablesFormatter = useCallback(
    (search, queryVariables) => ({
      ...queryVariables,
    }),
    [],
  );

  const queryVariables = useMemo(
    () => ({
      activeProjects: availableProjects,
    }),
    [availableProjects],
  );

  // const onSelect = useCallback((value, option) => {
  //   console.log('onSelect', value, option);
  // }, []);

  const antd = {
    label: 'Container ID',
    labelCol: { span: 24, offset: 0 },
  };

  return (
    <FormikAsyncSelect
      mode={multiple ? 'multiple' : undefined}
      name="container-select"
      query={allContainerQuery}
      queryResultFormatter={queryResultFormatter}
      queryVariablesFormatter={queryVariablesFormatter}
      queryVariables={queryVariables}
      // onSelect={onSelect}
      antd={antd}
    />
  );
};

ContainerSelect.propTypes = {
  multiple: PropTypes.bool,
};

ContainerSelect.defaultProps = {
  multiple: true,
};

export default ContainerSelect;
