import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ConfirmDialog from './ConfirmDialog';

const ConfirmDeletionDialog = ({ title, ...props }) => (
  <ConfirmDialog
    {...props}
    title={
      <>
        <FormattedMessage id="confirm_delete" defaultMessage="Are you sure you want to delete" />
        &nbsp;
        {title}?
      </>
    }
  />
);

ConfirmDeletionDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default ConfirmDeletionDialog;
