import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { FormikSelect } from '../../../../../../../../shared/inputs/formik';

const initialValues = {
  containerId: '',
  condition: '',
  threshold: 0,
  recipients: [],
  users: [],
  description: '',
};

const Notifications = ({ classes, allContainersList }) => {
  const intl = useIntl();

  return (
    <>
      <Typography variant="body1" className={`${classes.headerContainersTitle} m-t-20 m-b-50`}>
        <FormattedMessage id="add_notification" defaultMessage="Add notification" />
      </Typography>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {() => (
          <Form className="w-100">
            <Grid container xs={12} justify="flex-start">
              <Grid item xs={4}>
                <FormikSelect
                  required
                  name="containerId"
                  label={intl.formatMessage({
                    id: 'label.select_container',
                    defaultMessage: 'Select container',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'placeholder.container',
                    defaultMessage: 'Container',
                  })}
                  filledStyle
                  valuesList={allContainersList}
                  className={classes.notificationsSelectField}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikSelect
                  required
                  name="containerId"
                  label={intl.formatMessage({
                    id: 'label.select_container',
                    defaultMessage: 'Select container',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'placeholder.container',
                    defaultMessage: 'Container',
                  })}
                  filledStyle
                  valuesList={allContainersList}
                  className={classes.notificationsSelectField}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  required
                  name="containerId"
                  label={intl.formatMessage({
                    id: 'label.select_container',
                    defaultMessage: 'Select container',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'placeholder.container',
                    defaultMessage: 'Container',
                  })}
                  filledStyle
                  valuesList={allContainersList}
                  className={classes.notificationsSelectField}
                />
              </Grid>
            </Grid>
            <Typography
              variant="body1"
              className={`${classes.headerContainersTitle} m-t-20 m-b-50`}
            >
              <FormattedMessage id="add_notification" defaultMessage="Add notification" />
            </Typography>
            <Grid container xs={12}>
              <Grid item xs={4}>
                <FormikSelect
                  required
                  name="containerId"
                  label={intl.formatMessage({
                    id: 'label.select_container',
                    defaultMessage: 'Select container',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'placeholder.container',
                    defaultMessage: 'Container',
                  })}
                  filledStyle
                  valuesList={allContainersList}
                  className={classes.notificationsSelectField}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikSelect
                  required
                  name="containerId"
                  label={intl.formatMessage({
                    id: 'label.select_container',
                    defaultMessage: 'Select container',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'placeholder.container',
                    defaultMessage: 'Container',
                  })}
                  filledStyle
                  valuesList={allContainersList}
                  className={classes.notificationsSelectField}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

Notifications.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  allContainersList: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  ),
};

Notifications.defaultProps = {
  allContainersList: [],
};
export default Notifications;
