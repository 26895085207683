import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { connect as connectToFormik, getIn } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import Transition from '../../../../../../shared/animations/transition';
import PickupsDetailsForm from './pickupsDetailsForm';
import {
  formikInjectedPropsTypes,
  formikComponentMemoizeValueCheck,
  memoizeFields,
} from '../../../../../../shared/inputs/formik';
import { daysOptions } from '../../../../settings/project/defaults/data';
import { toastifyError } from '../../../../../../shared/utils';
import FixedLoadingLayout from '../../../../../../shared/loading/fixed';
import { ReactComponent as SaveIcon } from '../../../../../../../images/icons/saveIcon.svg';
import { ReactComponent as FolderIcon } from '../../../../../../../images/icons/folder.svg';
import PickupPresets from './pickupPresets';
import SavePickupPreset from './savePickupPreset';

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    padding: 20,
    minHeight: '60vh',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  roundedBtn: {
    borderRadius: 32.5,
  },
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

const modalFieldsPerTab = [
  [
    'fixedPickupsPeriod',
    'pickupRepeatPeriod',
    ...daysOptions.reduce((acc, { value }) => {
      acc.push(`pickupDay.${value}.timeFrom`);
      acc.push(`pickupDay.${value}.timeTo`);
      return acc;
    }, []),
  ],
  ['fixedPickupsPeriod', 'amountDaysBetweenPickups', 'allowedHoursFrom', 'allowedHoursTo'],
  [
    'fixedPickupsPeriod',
    'allowedHoursFrom',
    'allowedHoursTo',
    'minimumDaysBetweenPickup',
    'pickupInterval',
  ],
];

const modalFields = [
  'pickupRepeatPeriod',
  'amountDaysBetweenPickups',
  'pickupInterval',
  'minimumDaysBetweenPickup',
  'allowedHoursFrom',
  'allowedHoursTo',
  ...daysOptions.reduce((acc, { value }) => {
    acc.push(`pickupDay.${value}.timeFrom`);
    acc.push(`pickupDay.${value}.timeTo`);
    return acc;
  }, []),
];

const PickupsDetailsDialog = ({ open, onClose, formik }) => {
  const classes = useStyles();
  const intl = useIntl();

  const { setFieldValue, setFieldTouched, validateForm, values } = formik;
  const fixedPickupsPeriod = getIn(values, 'fixedPickupsPeriod');
  const selectedProject = getIn(values, 'selectedProject');

  const [initialValues, setInitialValues] = useState(null);
  const [validation, setValidation] = useState(false);
  const [openSelectPresetDialogue, setOpenSelectPresetDialogue] = useState(false);
  const [openSavePresetDialogue, setOpenSavePresetDialogue] = useState(false);

  useEffect(() => {
    if (!initialValues && open) {
      setInitialValues(
        modalFields.reduce(
          (acc, filedName) => ({
            ...acc,
            [filedName]: getIn(values, filedName),
          }),
          {},
        ),
      );
    }
  }, [initialValues, open, values]);

  const handleClose = useCallback(() => {
    modalFields.forEach((field) => setFieldValue(field, initialValues[field], false));
    modalFields.forEach((field) => setFieldTouched(field, false, false));
    onClose();
  }, [initialValues, onClose, setFieldTouched, setFieldValue]);

  const validateBeforeClose = useCallback(async () => {
    setValidation(true);
    try {
      const validationResult = await validateForm();
      const currentFields = modalFieldsPerTab[fixedPickupsPeriod];
      currentFields.map(async (field) => {
        await setFieldTouched(field, true, true);
      });
      const modalWindowErrors = currentFields.some((field) => getIn(validationResult, field));
      if (modalWindowErrors) {
        const error = new Error(
          intl.formatMessage({
            id: 'validation.errors',
            defaultMessage: 'Fix validation errors before submit',
          }),
        );
        error.formError = validationResult;
        throw error;
      }
      return validationResult;
    } finally {
      setTimeout(() => {
        setValidation(false);
      }, 500);
    }
  }, [fixedPickupsPeriod, intl, setFieldTouched, validateForm]);

  const onSubmit = useCallback(async () => {
    if (!validation) {
      validateBeforeClose()
        .then(() => {
          setFieldTouched('pickupMethod');
          setInitialValues(null);
          onClose();
        })
        .catch(toastifyError);
    }
  }, [validation, validateBeforeClose, setFieldTouched, onClose]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      fullWidth
      scroll="body"
      TransitionComponent={Transition}
      aria-labelledby="detailsForFixedPickups"
      aria-describedby="detailsForFixedPickups-description"
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onSubmit();
        }
      }}
    >
      <DialogTitle
        id="register-container-dialog"
        onClose={handleClose}
        className={classes.dialogTitle}
      >
        <FormattedMessage id="selectStaticCollection" defaultMessage="Select static collection" />
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <Grid container className="m-10">
          <PickupsDetailsForm />
          <FixedLoadingLayout open={validation} />
          <IconButton
            type="button"
            disabled={!selectedProject}
            title={intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
            onClick={() => {
              validateBeforeClose()
                .then(() => setOpenSavePresetDialogue(true))
                .catch(toastifyError);
            }}
          >
            <SvgIcon color="inherit" component={SaveIcon} />
          </IconButton>
          <IconButton
            type="button"
            disabled={!selectedProject}
            title={intl.formatMessage({ id: 'list', defaultMessage: 'List' })}
            onClick={() => setOpenSelectPresetDialogue(true)}
          >
            <SvgIcon color="inherit" component={FolderIcon} />
          </IconButton>
          {openSelectPresetDialogue && (
            <PickupPresets
              open={openSelectPresetDialogue}
              onClose={() => setOpenSelectPresetDialogue(false)}
            />
          )}
          {openSavePresetDialogue && (
            <SavePickupPreset
              open={openSavePresetDialogue}
              onClose={() => setOpenSavePresetDialogue(false)}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing className="m-b-15">
        <Grid container justify="center">
          <Grid item xs={5}>
            <Button
              onClick={onSubmit}
              color="secondary"
              fullWidth
              variant="contained"
              className={classes.roundedBtn}
            >
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

PickupsDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

export default connectToFormik(
  React.memo(
    PickupsDetailsDialog,
    memoizeFields([
      'open',
      'onClose',
      formikComponentMemoizeValueCheck('fixedPickupsPeriod'),
      formikComponentMemoizeValueCheck('selectedProject'),
    ]),
  ),
);
