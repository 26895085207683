import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { TutorialContext } from '../../../../../../tutorial';
import useStyles from '../../shared/styles';
import SupportTextPage from '../../shared/supportTextPage';

const PlatformOverview = () => {
  const classes = useStyles();
  const { startTutorial } = useContext(TutorialContext);
  return (
    <Grid container className={classes.root} spacing={2}>
      <SupportTextPage
        title={
          <FormattedMessage id="platform_overview_header" defaultMessage="Platform Overview" />
        }
        texts={[
          <FormattedMessage
            id="platform_overview_abstract_first"
            defaultMessage="The WasteHero platform is the central hub where you’ll manage all aspects of the waste management process. You can add new containers and assign their sensors, get a real-time overview on the fill-level of containers, plan dynamic collection routes, and analyse emission and cost savings. "
          />,
          <FormattedMessage
            id="platform_overview.abstract_second"
            defaultMessage="You can also invite people to collaborate on the platform and assign permissions depending on their role. Administration personnel, fleet managers, route planners and drivers can all use the platform to optimise the entire waste collection process."
          />,
          <FormattedMessage
            id="platform_overview_abstract_third"
            defaultMessage="The most important aspects to set up in the beginning are the user accounts, container types, waste fraction types, and the project settings."
          />,
          <FormattedMessage
            id="platform_overview_abstract_forth"
            defaultMessage="Do you need a refresher on how to use the platform?"
          />,
        ]}
      />
      <Typography
        gutterBottom
        className={`m-l-10 ${classes.links}`}
        onClick={() => startTutorial('supportCenter')}
      >
        <FormattedMessage
          id="platform_overview_view_tutorial"
          defaultMessage="View the platform tutorial here."
        />
      </Typography>
    </Grid>
  );
};

export default PlatformOverview;
