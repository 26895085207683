import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { STATUSES } from './utils';

const useStyles = makeStyles((theme) => ({
  working: {
    position: 'relative',
    paddingLeft: '20px',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: theme.variables.cGreen,
    },
  },
  unavailable: {
    position: 'relative',
    paddingLeft: '20px',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: theme.variables.cLightRed,
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      top: '50%',
      left: '2px',
      transform: 'translateY(-50%)',
      width: '8px',
      height: '2px',
      borderRadius: '50%',
      backgroundColor: theme.variables.cWhite,
    },
  },
  offWork: {
    position: 'relative',
    paddingLeft: '20px',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: theme.variables.cLightRed,
    },
  },
}));

const StyledStatus = ({ status }) => {
  const classes = useStyles();

  const getStatusClass = () => {
    switch (status) {
      case 'working':
        return classes.working;
      case 'unavailable':
        return classes.unavailable;
      case 'off-work':
        return classes.offWork;
      default:
        return 'working';
    }
  };
  return (
    <div className={getStatusClass(status)}>
      {STATUSES.find(({ value }) => value === status)?.label}
    </div>
  );
};

StyledStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StyledStatus;
