import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { LocationsList, LocationEdit, LocationDetails } from '.';
import { getUsersAvailableProjects } from '../../../../../shared/utils';

const LocationManagementRouter = ({ match }) => {
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={LocationsList} />
      {!usersAvailableProjects?.length && <Redirect to={`${match.url}/`} />}
      <Route exact path={`${match.url}/add`} component={LocationEdit} />
      <Route exact path={`${match.url}/:id/edit`} component={LocationEdit} />
      <Route path={`${match.url}/:id`} component={LocationDetails} />
    </Switch>
  );
};

LocationManagementRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default LocationManagementRouter;
