import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { loader } from 'graphql.macro';
import LoadingLayout from '../../../../../../../shared/loading';

import CloseIcon from '../../../../../../../../images/icons/closeWhite.svg';
import CheckIcon from '../../../../../../../../images/icons/check.svg';
import DangerIcon from '../../../../../../../../images/icons/danger.svg';
import WarnIcon from '../../../../../../../../images/icons/warn.svg';
import InfoIcon from '../../../../../../../../images/icons/information.svg';
import InfoSimpleIcon from '../../../../../../../../images/icons/infoSimple.svg';

import styles from './styles';
import { getMeasurementsFromContainer, saveCacheRead } from '../../../../../../../shared/utils';

const oneContainer = loader('./../../../../../../../graphql/queries/devices/one_container.graphql');

const getLogIcon = (status) => {
  let icon;
  switch (status) {
    case 'SUCCESS': {
      icon = CheckIcon;
      break;
    }
    case 'DEBUG': {
      icon = InfoSimpleIcon;
      break;
    }
    case 'INFO': {
      icon = InfoIcon;
      break;
    }
    case 'WARNING': {
      icon = WarnIcon;
      break;
    }
    case 'ERROR': {
      icon = DangerIcon;
      break;
    }
    default: {
      icon = InfoSimpleIcon;
      break;
    }
  }
  return (
    <img width={30} src={icon} alt={<FormattedMessage id="status" defaultMessage="Status" />} />
  );
};

class ShowLogModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { container } = this.props;
    this.getContainerLog(container);
  }

  getContainerLog(container) {
    this.setState({ isLoading: true, logs: [] });
    if (container) {
      saveCacheRead({
        query: oneContainer,
        variables: {
          id: container.id,
          isFillleveldeviceSetDeviceType: false,
        },
      })
        .then((response) => {
          const [, devices] = getMeasurementsFromContainer(response.data.container || {});
          const [{ node: { notificationSet: { edges: notification = [] } = {} } = {} } = {}] =
            devices || [];

          this.setState({
            logs: notification || [],
          });
        })
        .then(() => this.setState({ isLoading: false }));
    }
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  confirm = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { classes } = this.props;
    const { logs, isLoading } = this.state;
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open
          maxWidth="md"
          className="w-min-600"
          fullWidth
          scroll="body"
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              this.handleClose();
            }
          }}
        >
          <DialogTitle className={classes.dialogHeaderContainer}>
            <div className={classes.dialogHeader}>
              <div className={classes.dialogTitle}>
                <FormattedMessage id="log" defaultMessage="Log" />
              </div>
              <Button className={classes.closeBtn} onClick={this.handleClose}>
                <img src={CloseIcon} alt={<FormattedMessage id="close" defaultMessage="Close" />} />
              </Button>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogBody}>
            <table>
              <tbody>
                {logs &&
                  logs.map((logItem) => (
                    <tr className={classes.infoRow} key={logItem.node.id}>
                      <td className={classes.cell}>{getLogIcon(logItem.node.status)}</td>
                      <td className={classes.cell} style={{ whiteSpace: 'nowrap' }}>
                        {new Intl.DateTimeFormat('en-GB', {
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        }).format(new Date(logItem.node.createdAt))}
                      </td>
                      <td className={classes.cell}>{logItem.node.message}</td>
                    </tr>
                  ))}
                {!(logs && logs.length) && !isLoading && (
                  <tr>
                    <td colSpan="2" className={classes.emptyInfo}>
                      <FormattedMessage id="no_data" defaultMessage="No data" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </DialogContent>
          <LoadingLayout isLoading={isLoading} />
        </Dialog>
      </div>
    );
  }
}

ShowLogModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  container: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

ShowLogModal.defaultProps = {
  container: {},
};

export default withStyles(styles)(ShowLogModal);
