import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import WhenNotFound from '../../../shared/whenNotFound';
import DriverManagementRouter from './routes/driverManagement/router';
import VehicleManagementRouter from './routes/vehicles/router';
import LocationManagementRouter from './routes/locations/router';
import VehicleTypeManagementRouter from './routes/vehicleTypes/router';
import { TYPES_CHOICES_VALUES } from './components/utils';

const FleetManagement = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/`} component={WhenNotFound} />
    <Route
      path={`${match.url}/collector-vehicle`}
      render={(props) => (
        <VehicleManagementRouter {...props} type={TYPES_CHOICES_VALUES.collector} />
      )}
    />
    <Route
      path={`${match.url}/street-sweeper-vehicle`}
      render={(props) => (
        <VehicleManagementRouter {...props} type={TYPES_CHOICES_VALUES.streetSweeper} />
      )}
    />
    <Route path={`${match.url}/vehicle-type`} component={VehicleTypeManagementRouter} />
    <Route path={`${match.url}/driver-management`} component={DriverManagementRouter} />
    <Route path={`${match.url}/locations`} component={LocationManagementRouter} />
    <Route component={WhenNotFound} />
  </Switch>
);

FleetManagement.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default FleetManagement;
