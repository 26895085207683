import React from 'react';
import PropTypes from 'prop-types';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery90Icon from '@material-ui/icons/Battery90';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery20Icon from '@material-ui/icons/Battery20';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const BatteryIndicator = ({ batteryLevel }) => {
  const Icon = getBatteryIndicatorIcon(batteryLevel);
  const color = getFillIndicatorColor(batteryLevel);
  const classes = useStyles();
  return (
    <span className={classes.wrapper}>
      <Icon style={{ color }} />
      <span>{Math.round(batteryLevel)}%</span>
    </span>
  );
};

function getBatteryIndicatorIcon(batteryLevel) {
  if (batteryLevel <= 20) {
    return Battery20Icon;
  }
  if (batteryLevel <= 30) {
    return Battery30Icon;
  }
  if (batteryLevel <= 50) {
    return Battery50Icon;
  }
  if (batteryLevel <= 60) {
    return Battery60Icon;
  }
  if (batteryLevel <= 80) {
    return Battery80Icon;
  }
  if (batteryLevel <= 90) {
    return Battery90Icon;
  }
  return BatteryFullIcon;
}

function getFillIndicatorColor(batteryLevel) {
  if (batteryLevel <= 20) {
    return '#EC6B6B';
  }
  if (batteryLevel <= 50) {
    return '#E58E3E';
  }
  if (batteryLevel <= 80) {
    return '#D9CC5D';
  }
  return '#4FB42C';
}

BatteryIndicator.propTypes = {
  batteryLevel: PropTypes.number.isRequired,
};

export default BatteryIndicator;
