import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import BigAvatar from '../../../../../images/avatar_size/big_avatar.svg';
import SmallAvatar from '../../../../../images/avatar_size/small_avatar.svg';
import NoneAvatar from '../../../../../images/avatar_size/none_avatar.svg';
import ListItemLink from './listItemLink';
import { IsJson } from '../../../../shared/utils';

export const serializeTime = (value, defaultValue) =>
  value && moment(value, 'HH:mm:ss').isValid() ? moment(value, 'HH:mm:ss') : defaultValue;

export const serializeDate = (value, defaultValue) =>
  value && moment(value, 'YYYY:MM:DD').isValid() ? moment(value, 'YYYY:MM:DD') : defaultValue;

export const setFieldErrors = (graphQLErrors, setFieldError) => {
  if (graphQLErrors && graphQLErrors.length) {
    return graphQLErrors
      .map((graphQLError) => {
        if (graphQLError?.context?.field) {
          setFieldError(graphQLError.context.field, graphQLError.message);
          return true;
        }
        const message = graphQLError?.message?.replace(/'/gi, '"');
        if (IsJson(message)) {
          const jsonMessage = JSON.parse(message);
          Reflect.ownKeys(jsonMessage).forEach((field) => {
            setFieldError(field, jsonMessage[field].join(', '));
          });
          return true;
        }
        return false;
      })
      .some((belongsToField) => belongsToField);
  }
  return false;
};

export const DEPOTS_TYPE_VALUES = {
  depot: 'depot',
  wasteStation: 'waste_station',
  workshop: 'workshop',
  all: 'all',
};

export const DEPOT_TYPES = [
  {
    value: DEPOTS_TYPE_VALUES.all,
    label: <FormattedMessage id="depots_type.all" defaultMessage="All" />,
  },
  {
    value: DEPOTS_TYPE_VALUES.depot,
    label: <FormattedMessage id="depots_type.depot" defaultMessage="Depot" />,
  },
  {
    value: DEPOTS_TYPE_VALUES.wasteStation,
    label: <FormattedMessage id="pickupMethod.wasteStation" defaultMessage="Waste Station" />,
  },
  {
    value: DEPOTS_TYPE_VALUES.workshop,
    label: <FormattedMessage id="pickupMethod.workshop" defaultMessage="Workshop" />,
  },
];

export const DEPOT_FORM_TYPES = [
  {
    value: DEPOTS_TYPE_VALUES.depot,
    label: <FormattedMessage id="depots_type.depot" defaultMessage="Depot" />,
  },
  {
    value: DEPOTS_TYPE_VALUES.wasteStation,
    label: <FormattedMessage id="pickupMethod.wasteStation" defaultMessage="Waste Station" />,
  },
  {
    value: DEPOTS_TYPE_VALUES.workshop,
    label: <FormattedMessage id="pickupMethod.workshop" defaultMessage="Workshop" />,
  },
];

export const WORKSHOP_TYPE_VALUES = {
  inspection: 'inspection',
  equipmentShop: 'equipment_shop',
  repairShop: 'repair_shop',
};

export const WORKSHOP_FORM_TYPES = [
  {
    value: WORKSHOP_TYPE_VALUES.inspection,
    label: <FormattedMessage id="workshop_type.inspection" defaultMessage="Inspection" />,
  },
  {
    value: WORKSHOP_TYPE_VALUES.equipmentShop,
    label: <FormattedMessage id="workshop_type.equipmentShop" defaultMessage="Equipment shop" />,
  },
  {
    value: WORKSHOP_TYPE_VALUES.repairShop,
    label: <FormattedMessage id="workshop_type.repairShop" defaultMessage="Repair shop" />,
  },
];

export const STATUSES_VALUES = {
  working: 'working',
  unavailable: 'unavailable',
  off_work: 'off-work',
  all: 'all',
};

export const STATUSES = [
  {
    value: STATUSES_VALUES.all,
    label: <FormattedMessage id="statuses.all" defaultMessage="All" />,
  },
  {
    value: STATUSES_VALUES.working,
    label: <FormattedMessage id="statuses.working" defaultMessage="Working" />,
  },
  {
    value: STATUSES_VALUES.unavailable,
    label: <FormattedMessage id="statuses.unavailable" defaultMessage="Unavailable" />,
  },
  {
    value: STATUSES_VALUES.off_work,
    label: <FormattedMessage id="statuses.off_work" defaultMessage="Off work" />,
  },
];

export const SIZES_VALUES = {
  large: 'large',
  small: 'small',
  none: 'none',
};

export const SIZES = [
  {
    icon: BigAvatar,
    value: SIZES_VALUES.large,
    label: <FormattedMessage id="sizes.large" defaultMessage="Large" />,
  },
  {
    icon: SmallAvatar,
    value: SIZES_VALUES.small,
    label: <FormattedMessage id="sizes.small" defaultMessage="Small" />,
  },
  {
    icon: NoneAvatar,
    value: SIZES_VALUES.none,
    label: <FormattedMessage id="sizes.none" defaultMessage="None" />,
  },
];

export const TYPES_CHOICES_VALUES = {
  collector: 'Collector',
  streetSweeper: 'StreetSweeper',
  mechanic: 'Mechanic',
};

export const TYPES_CHOICES_TYPES = [
  {
    value: TYPES_CHOICES_VALUES.collector,
    label: <FormattedMessage id="vehicle_type.collector" defaultMessage="Collector" />,
  },
  {
    value: TYPES_CHOICES_VALUES.streetSweeper,
    label: <FormattedMessage id="vehicle_type.street_sweeper" defaultMessage="Street Sweeper" />,
  },
  {
    value: TYPES_CHOICES_VALUES.mechanic,
    label: <FormattedMessage id="vehicle_type.mechanic" defaultMessage="Mechanic" />,
  },
];

export const LIFT_MECHANIZATION_TYPE_VALUES = {
  without: 'WoL',
  liftAssisted: 'LiAs',
  liftAutomated: 'LiA',
  automatedArm: 'AA',
  hookLift: 'HL',
  crane: 'crane',
};

export const LIFT_MECHANIZATION_TYPES = [
  {
    value: LIFT_MECHANIZATION_TYPE_VALUES.without,
    label: <FormattedMessage id="lift_mechanization.without" defaultMessage="Without (WoL)" />,
  },
  {
    value: LIFT_MECHANIZATION_TYPE_VALUES.liftAssisted,
    label: (
      <FormattedMessage
        id="lift_mechanization.liftAssisted"
        defaultMessage="Lift assisted (LiAs)"
      />
    ),
  },
  {
    value: LIFT_MECHANIZATION_TYPE_VALUES.liftAutomated,
    label: (
      <FormattedMessage
        id="lift_mechanization.liftAutomated"
        defaultMessage="Lift automated (LiA)"
      />
    ),
  },
  {
    value: LIFT_MECHANIZATION_TYPE_VALUES.automatedArm,
    label: (
      <FormattedMessage id="lift_mechanization.automatedArm" defaultMessage="Automated arm (AA)" />
    ),
  },
  {
    value: LIFT_MECHANIZATION_TYPE_VALUES.hookLift,
    label: <FormattedMessage id="lift_mechanization.hookLift" defaultMessage="Hook lift (HL)" />,
  },
  {
    value: LIFT_MECHANIZATION_TYPE_VALUES.crane,
    label: <FormattedMessage id="lift_mechanization.crane" defaultMessage="Crane" />,
  },
];

export const BODY_TYPE_VALUES = {
  without: 'WoB',
  open: 'BO',
  closed: 'BC',
};
export const BODY_TYPES = [
  {
    value: BODY_TYPE_VALUES.without,
    label: <FormattedMessage id="body_type.without" defaultMessage="Without (WoB)" />,
  },
  {
    value: BODY_TYPE_VALUES.open,
    label: <FormattedMessage id="body_type.open" defaultMessage="Open (BO)" />,
  },
  {
    value: BODY_TYPE_VALUES.closed,
    label: <FormattedMessage id="body_type.closed" defaultMessage="Closed (BC)" />,
  },
];

export const CRANE_TYPE_VALUES = {
  craneC1H: 'C1H',
  craneC2H: 'C2H',
  craneCD: 'CD',
};

export const CRANE_TYPES = [
  {
    value: CRANE_TYPE_VALUES.craneC1H,
    label: <FormattedMessage id="crane_type.craneC1H" defaultMessage="Simple hook (C1H)" />,
  },
  {
    value: CRANE_TYPE_VALUES.craneC2H,
    label: <FormattedMessage id="crane_type.craneC2H" defaultMessage="Double hook (C2H)" />,
  },
  {
    value: CRANE_TYPE_VALUES.craneCD,
    label: <FormattedMessage id="crane_type.craneCD" defaultMessage="Double disc (CD)" />,
  },
];

export const BODY_MECHANIZATION_TYPE_VALUES = {
  p: 'p',
  withCompactor: 'with_compactor',
  without: 'WoM',
};

export const BODY_MECHANIZATION_TYPES = [
  {
    value: BODY_MECHANIZATION_TYPE_VALUES.p,
    label: (
      <FormattedMessage
        id="body_mechanization.p"
        defaultMessage="Mechanized hydraulic packer plat/grid (P)"
      />
    ),
  },
  {
    value: BODY_MECHANIZATION_TYPE_VALUES.withCompactor,
    label: (
      <FormattedMessage id="body_mechanization.with_compactor" defaultMessage="With compactor" />
    ),
  },
  {
    value: BODY_MECHANIZATION_TYPE_VALUES.without,
    label: (
      <FormattedMessage
        id="body_mechanization.without_mechanization"
        defaultMessage="Without mechanization (WoM)"
      />
    ),
  },
];

export const WITH_COMPACTOR_TYPE_VALUES = {
  withCompactorCI: 'CI',
  withCompactorCC: 'CC',
};

export const WITH_COMPACTOR_TYPES = [
  {
    value: WITH_COMPACTOR_TYPE_VALUES.withCompactorCI,
    label: <FormattedMessage id="with_compactor.ci" defaultMessage="Intermittent (CI)" />,
  },
  {
    value: WITH_COMPACTOR_TYPE_VALUES.withCompactorCC,
    label: <FormattedMessage id="with_compactor.cc" defaultMessage="Contunuous (CC)" />,
  },
];

export const LOADING_LOC_TYPE_VALUES = {
  rearEnd: 'LRe',
  side: 'LS',
  notSpecific: 'LNS',
};

export const LOADING_LOC_TYPES = [
  {
    value: LOADING_LOC_TYPE_VALUES.rearEnd,
    label: <FormattedMessage id="loading_loc.rear_end" defaultMessage="Rear-end (LRe)" />,
  },
  {
    value: LOADING_LOC_TYPE_VALUES.side,
    label: <FormattedMessage id="loading_loc.side" defaultMessage="Side (LS)" />,
  },
  {
    value: LOADING_LOC_TYPE_VALUES.notSpecific,
    label: <FormattedMessage id="loading_loc.not_specific" defaultMessage="Not specific (LNS)" />,
  },
];

export const VEHICLE_STATUS_VALUES = {
  working: 'working',
  unavailable: 'unavailable',
  offWork: 'off-work',
};

export const VEHICLE_STATUSES = [
  {
    value: VEHICLE_STATUS_VALUES.working,
    label: <FormattedMessage id="vehicle_status.working" defaultMessage="Working" />,
  },
  {
    value: VEHICLE_STATUS_VALUES.unavailable,
    label: <FormattedMessage id="vehicle_status.unavailable" defaultMessage="Unavailable" />,
  },
  {
    value: VEHICLE_STATUS_VALUES.offWork,
    label: <FormattedMessage id="vehicle_status.offWork" defaultMessage="Off work" />,
  },
];

export const FUEL_TYPE_VALUES = {
  gas: 'gasoline',
  diesel: 'diesel',
  propane: 'propane',
  cng: 'cng',
  electricity: 'electricity',
};

export const FUEL_TYPE = [
  {
    value: FUEL_TYPE_VALUES.gas,
    label: <FormattedMessage id="vehicle_fuel_type.gas" defaultMessage="Gasoline" />,
  },
  {
    value: FUEL_TYPE_VALUES.diesel,
    label: <FormattedMessage id="vehicle_fuel_type.diesel" defaultMessage="Diesel" />,
  },
  {
    value: FUEL_TYPE_VALUES.propane,
    label: <FormattedMessage id="vehicle_fuel_type.propane" defaultMessage="Propane" />,
  },
  {
    value: FUEL_TYPE_VALUES.cng,
    label: (
      <FormattedMessage id="vehicle_fuel_type.cng" defaultMessage="Compressed natural gas (CNG)" />
    ),
  },
  {
    value: FUEL_TYPE_VALUES.electricity,
    label: <FormattedMessage id="vehicle_fuel_type.electricity" defaultMessage="Electricity" />,
  },
];

export const TYPE_OF_COST_VALUES = {
  fixed: 'fixed',
  variable: 'variable',
};

export const TYPE_OF_COST = [
  {
    value: TYPE_OF_COST_VALUES.fixed,
    label: <FormattedMessage id="type_of_cost.fixed" defaultMessage="Fixed" />,
  },
  {
    value: TYPE_OF_COST_VALUES.variable,
    label: <FormattedMessage id="type_of_cost.variable" defaultMessage="Variable" />,
  },
];

export const LICENSE_CLASSES = {
  AM: 'AM',
  A1: 'A1',
  A2: 'A2',
  A: 'A',
  B: 'B',
  BE: 'BE',
  B1: 'B1',
  C1: 'C1',
  C1E: 'C1E',
  C: 'C',
  CE: 'CE',
  D1: 'D1',
  D1E: 'D1E',
  D: 'D',
  DE: 'DE',
};

export const LICENSE_OPTIONS = [
  { value: LICENSE_CLASSES.AM, label: 'AM' },
  { value: LICENSE_CLASSES.A1, label: 'A1' },
  { value: LICENSE_CLASSES.A2, label: 'A2' },
  { value: LICENSE_CLASSES.A, label: 'A' },
  { value: LICENSE_CLASSES.B, label: 'B' },
  { value: LICENSE_CLASSES.BE, label: 'BE' },
  { value: LICENSE_CLASSES.B1, label: 'B1' },
  { value: LICENSE_CLASSES.C1, label: 'C1' },
  { value: LICENSE_CLASSES.C1E, label: 'C1E' },
  { value: LICENSE_CLASSES.C, label: 'C' },
  { value: LICENSE_CLASSES.CE, label: 'CE' },
  { value: LICENSE_CLASSES.D1, label: 'D1' },
  { value: LICENSE_CLASSES.D1E, label: 'D1E' },
  { value: LICENSE_CLASSES.D, label: 'D' },
  { value: LICENSE_CLASSES.DE, label: 'DE' },
];

export const REGIONS = {
  A: 'A',
  B: 'B',
  BG: 'BG',
  HR: 'HR',
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  EST: 'EST',
  FIN: 'FIN',
  F: 'F',
  D: 'D',
  GR: 'GR',
  H: 'H',
  IS: 'IS',
  IRL: 'IRL',
  I: 'I',
  LV: 'LV',
  FL: 'FL',
  LT: 'LT',
  L: 'L',
  M: 'M',
  NL: 'NL',
  N: 'N',
  PL: 'PL',
  P: 'P',
  RO: 'RO',
  SK: 'SK',
  SLO: 'SLO',
  E: 'E',
  S: 'S',
};

export const REGION_OPTIONS = [
  { value: REGIONS.A, label: <FormattedMessage id="licenseRegion.A" defaultMessage="Austria" /> },
  { value: REGIONS.B, label: <FormattedMessage id="licenseRegion.B" defaultMessage="Belgium" /> },
  {
    value: REGIONS.BG,
    label: <FormattedMessage id="licenseRegion.BG" defaultMessage="Bulgaria" />,
  },
  { value: REGIONS.HR, label: <FormattedMessage id="licenseRegion.HR" defaultMessage="Croatia" /> },
  { value: REGIONS.CY, label: <FormattedMessage id="licenseRegion.CY" defaultMessage="Cyprus" /> },
  {
    value: REGIONS.CZ,
    label: <FormattedMessage id="licenseRegion.CZ" defaultMessage="Czech Republic" />,
  },
  { value: REGIONS.DK, label: <FormattedMessage id="licenseRegion.DK" defaultMessage="Denmark" /> },
  {
    value: REGIONS.EST,
    label: <FormattedMessage id="licenseRegion.EST" defaultMessage="Estonia" />,
  },
  {
    value: REGIONS.FIN,
    label: <FormattedMessage id="licenseRegion.FIN" defaultMessage="Finland" />,
  },
  { value: REGIONS.F, label: <FormattedMessage id="licenseRegion.F" defaultMessage="France" /> },
  { value: REGIONS.D, label: <FormattedMessage id="licenseRegion.D" defaultMessage="Germany" /> },
  { value: REGIONS.GR, label: <FormattedMessage id="licenseRegion.GR" defaultMessage="Greece" /> },
  { value: REGIONS.H, label: <FormattedMessage id="licenseRegion.H" defaultMessage="Hungary" /> },
  { value: REGIONS.IS, label: <FormattedMessage id="licenseRegion.IS" defaultMessage="Iceland" /> },
  {
    value: REGIONS.IRL,
    label: <FormattedMessage id="licenseRegion.IRL" defaultMessage="Ireland" />,
  },
  { value: REGIONS.I, label: <FormattedMessage id="licenseRegion.I" defaultMessage="Italy" /> },
  { value: REGIONS.LV, label: <FormattedMessage id="licenseRegion.LV" defaultMessage="Latvia" /> },
  {
    value: REGIONS.FL,
    label: <FormattedMessage id="licenseRegion.FL" defaultMessage="Liechtenstein" />,
  },
  {
    value: REGIONS.LT,
    label: <FormattedMessage id="licenseRegion.LT" defaultMessage="Lithuania" />,
  },
  {
    value: REGIONS.L,
    label: <FormattedMessage id="licenseRegion.L" defaultMessage="Luxembourg" />,
  },
  { value: REGIONS.M, label: <FormattedMessage id="licenseRegion.M" defaultMessage="Malta" /> },
  {
    value: REGIONS.NL,
    label: <FormattedMessage id="licenseRegion.NL" defaultMessage="Netherlands" />,
  },
  { value: REGIONS.N, label: <FormattedMessage id="licenseRegion.N" defaultMessage="Norway" /> },
  { value: REGIONS.PL, label: <FormattedMessage id="licenseRegion.PL" defaultMessage="Poland" /> },
  { value: REGIONS.P, label: <FormattedMessage id="licenseRegion.P" defaultMessage="Portugal" /> },
  { value: REGIONS.RO, label: <FormattedMessage id="licenseRegion.RO" defaultMessage="Romania" /> },
  {
    value: REGIONS.SK,
    label: <FormattedMessage id="licenseRegion.SK" defaultMessage="Slovakia" />,
  },
  {
    value: REGIONS.SLO,
    label: <FormattedMessage id="licenseRegion.SLO" defaultMessage="Slovenia" />,
  },
  { value: REGIONS.E, label: <FormattedMessage id="licenseRegion.E" defaultMessage="Spain" /> },
  { value: REGIONS.S, label: <FormattedMessage id="licenseRegion.S" defaultMessage="Sweden" /> },
];

export const PAYMENT_INTERVALS = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const PAYMENT_INTERVALS_OPTIONS = [
  {
    value: PAYMENT_INTERVALS.daily,
    label: <FormattedMessage id="paymentIntervals.daily" defaultMessage="Daily" />,
  },
  {
    value: PAYMENT_INTERVALS.weekly,
    label: <FormattedMessage id="paymentIntervals.weekly" defaultMessage="Weekly" />,
  },
  {
    value: PAYMENT_INTERVALS.monthly,
    label: <FormattedMessage id="paymentIntervals.monthly" defaultMessage="Monthly" />,
  },
];

export const joinElementsCallback = (separator) => (prev, curr, index) => {
  if (index === 0) {
    prev.push(curr);
  } else {
    prev.push(...[separator, curr]);
  }
  return prev;
};

export const detailUpperMenuItems = (baseUrl, extra = null) => (
  <div>
    <ListItemLink
      href={baseUrl}
      primary={<FormattedMessage id="location.details.information" defaultMessage="Information" />}
    />
    {extra}
    <ListItemLink
      href={`${baseUrl}/photos`}
      primary={<FormattedMessage id="location.details.photoMenu" defaultMessage="Photos" />}
    />
    <ListItemLink
      href={`${baseUrl}/documents`}
      primary={<FormattedMessage id="location.details.documentsMenu" defaultMessage="Documents" />}
    />
    <ListItemLink
      href={`${baseUrl}/comments`}
      primary={<FormattedMessage id="location.details.commentsMenu" defaultMessage="Comments" />}
    />
  </div>
);

export const detailSubMenuItems = (baseUrl) => (
  <div>
    <ListItemLink
      href={`${baseUrl}`}
      primary={<FormattedMessage id="vehicle.details.servicesMenu" defaultMessage="Services" />}
    />
    <ListItemLink
      href={`${baseUrl}`}
      primary={<FormattedMessage id="vehicle.details.remindersMenu" defaultMessage="Reminders" />}
    />
    <ListItemLink
      href={`${baseUrl}`}
      primary={<FormattedMessage id="vehicle.details.issuesMenu" defaultMessage="Issues" />}
    />
    <ListItemLink
      href={`${baseUrl}`}
      primary={<FormattedMessage id="vehicle.details.analyticsMenu" defaultMessage="Analytics" />}
    />
  </div>
);
