import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import NavigationBar from './header/navigationBar';
import MainErrorBoundary from '../shared/defaultPages/errorBoundry';

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1,
  },
  fullHeight: {
    flexGrow: 1,
    height: '100%',
    maxHeight: 'calc(100vh - 70px) !important',
    minHeight: 720,
    position: 'relative',
  },
});

const Content = ({ children }) => {
  const classes = useStyles();

  return (
    <main className={classes.contentWrapper}>
      <NavigationBar />
      <div className={classes.fullHeight}>
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
          <MainErrorBoundary>{children}</MainErrorBoundary>
        </Scrollbars>
      </div>
    </main>
  );
};

Content.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Content;
