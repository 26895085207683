import React from 'react';

import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';

const SettingsMultiChipLabel = ({ values, defaultLabel, chipClassName }) =>
  values.length > 0 ? (
    <span>
      {values.map((value) => (
        <Chip label={value} key={value} className={chipClassName} />
      ))}
    </span>
  ) : (
    defaultLabel
  );

SettingsMultiChipLabel.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultLabel: PropTypes.string,
  chipClassName: PropTypes.string,
};

SettingsMultiChipLabel.defaultProps = {
  defaultLabel: '',
  chipClassName: '',
};

export default SettingsMultiChipLabel;
