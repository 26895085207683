import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { connect as connectToFormik, getIn } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Transition from '../../../../../../shared/animations/transition';
import {
  formikComponentMemoizeValueCheck,
  formikInjectedPropsTypes,
  memoizeFields,
} from '../../../../../../shared/inputs/formik';
import FixedLoadingLayout from '../../../../../../shared/loading/fixed';
import { getSavedActiveProjects } from '../../../../../../shared/utils';
import { serializePickupSettings } from '../../shared/utils';

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    padding: 20,
    minHeight: '60vh',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  roundedBtn: {
    borderRadius: 22,
    minWidth: 200,
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: theme.variables.cAntiFlashWhite,
  },
}));

const staticCollectionOptions = [
  'pickupRepeatPeriod',
  'amountDaysBetweenPickups',
  'pickupInterval',
  'minimumDaysBetweenPickup',
  'allowedHoursFrom',
  'allowedHoursTo',
  'fixedPickupsPeriod',
  'collectionPerWeek',
  'pickupDay',
];

const allPickupSettingsQuery = loader(
  '../../../../../../graphql/queries/operation_management/all_pickup_settings.graphql',
);

const setFieldsFromPreset = (setFieldValue) => (preset) =>
  new Promise((resolve) => {
    const { id: presetId, name: presetName, ...serializedPreset } = serializePickupSettings(preset);
    staticCollectionOptions.forEach((filedName) => {
      setFieldValue(filedName, serializedPreset[filedName], false);
    });
    setTimeout(() => resolve(), 400);
  });

const PickupPresets = ({ open, onClose, formik }) => {
  const classes = useStyles();
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const [applyingPreset, setApplyingPreset] = useState(false);
  const { setFieldValue, setFieldTouched, values } = formik;

  const project = getIn(values, 'selectedProject');

  const {
    loading,
    data: { allPickupSettings: { edges: allPickupSettings = [] } = {} } = {},
  } = useQuery(allPickupSettingsQuery, {
    variables: { activeProjects: savedActiveProjects, project },
  });

  const setFieldToPresetValues = useMemo(
    () => setFieldsFromPreset(setFieldValue, setFieldTouched),
    [setFieldValue, setFieldTouched],
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSelect = useCallback(
    (selectedPreset) => {
      setApplyingPreset(true);
      setFieldToPresetValues(selectedPreset)
        .then(() => {
          setApplyingPreset(false);
          onClose();
        })
        .finally(() => setApplyingPreset(false));
    },
    [onClose, setFieldToPresetValues],
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      fullWidth
      scroll="body"
      TransitionComponent={Transition}
      aria-labelledby="selectPickupPreset"
      aria-describedby="selectPickupPreset-description"
    >
      <DialogTitle
        id="register-container-dialog"
        onClose={handleClose}
        className={classes.dialogTitle}
      >
        <FormattedMessage id="selectStaticCollection" defaultMessage="Saved settings" />
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <Grid container className="m-10">
          <FixedLoadingLayout open={applyingPreset || loading} />
          <Grid container direction="column" alignItems="flex-start">
            {allPickupSettings.length ? (
              allPickupSettings?.map(({ node }) => (
                <Grid item key={node.id} className="p-5">
                  <Button
                    onClick={() => onSelect(node)}
                    color="inherit"
                    fullWidth
                    variant="contained"
                    className={classes.roundedBtn}
                  >
                    {node.name}
                  </Button>
                </Grid>
              ))
            ) : (
              <Grid container item justify="center">
                <Typography variant="body2">
                  <FormattedMessage
                    id="project_settings.presets.doesNotExists"
                    defaultMessage="This projects dont have static presets"
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

PickupPresets.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

export default connectToFormik(
  React.memo(
    PickupPresets,
    memoizeFields([
      'open',
      'classes',
      'onClose',
      formikComponentMemoizeValueCheck('selectedProject'),
    ]),
  ),
);
