import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { FormikTimePicker } from '../../../../../../shared/inputs/formik';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 'bold',
  },
  maskedInput: {
    width: 140,
    '& .MuiInput-input': {
      textAlign: 'center',
    },
  },
}));

const HoursForPickup = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.label}>
          <FormattedMessage
            id="allowed_hours_for_pickup"
            defaultMessage="Allowed hours for pickup"
          />
        </Typography>
      </Grid>
      <Grid container item xs={7} justify="space-between">
        <Grid item>
          <FormikTimePicker
            name="allowedHoursFrom"
            className={classes.maskedInput}
            placeholder="00:00"
            label={intl.formatMessage({ id: 'label.allowedHoursFrom', defaultMessage: 'From' })}
          />
        </Grid>
        <Grid item>
          <FormikTimePicker
            name="allowedHoursTo"
            className={classes.maskedInput}
            placeholder="00:00"
            label={intl.formatMessage({ id: 'label.allowedHoursTo', defaultMessage: 'To' })}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default HoursForPickup;
