import React from 'react';
import PropTypes from 'prop-types';
import MuiPhoneInput from 'material-ui-phone-number';
import InputLabel from '@material-ui/core/InputLabel';
import BaseFormikInput from './BaseFormikInput';
import StyledErrorMessage from './StyledErrorMessage';

const FormikPhoneField = ({
  name,
  required,
  label,
  placeholder,
  id,
  readOnly,
  className,
  FieldProps,
  InputProps,
  onChange,
  onClick,
  ...otherProps
}) => (
  <BaseFormikInput
    filledStyle
    name={name}
    id={id}
    onChange={onChange}
    required={required}
    className={className}
  >
    {({ isError, inputId, value, setFieldValue, setFieldTouched, classes }) => (
      <>
        {label && (
          <InputLabel
            required={required}
            error={isError}
            shrink
            id={`label-${inputId}`}
            htmlFor={inputId}
          >
            {label}
          </InputLabel>
        )}
        <MuiPhoneInput
          id={inputId}
          name={name}
          value={value}
          onChange={(e) => setFieldValue(name, e)}
          onBlur={(e) => setFieldTouched(name, e.target.value)}
          dropdownClass={classes.paper}
          {...FieldProps}
          {...otherProps}
        />
        <StyledErrorMessage name={name} />
      </>
    )}
  </BaseFormikInput>
);

FormikPhoneField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  FieldProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  InputProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

FormikPhoneField.defaultProps = {
  required: false,
  label: '',
  placeholder: '',
  readOnly: false,
  children: null,
  className: '',
  id: '',
  FieldProps: {},
  InputProps: {},
  onChange: () => {},
  onClick: () => {},
};

export default FormikPhoneField;
