import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import Panel from './panel';

const FaqContainer = ({ heading, text, objects, title, faqs, children }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
          {heading}
        </Typography>
        {children}
        <Grid item xs={11}>
          {text.map((txt, index) => (
            <Typography key={index} gutterBottom className={classes.textContent} variant="body1">
              {txt}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            {faqs}
          </Typography>
          {objects.map((object, index) => (
            <Panel key={index} header={object.header} texts={object.texts} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
FaqContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  faqs: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text: PropTypes.arrayOf(
    PropTypes.shape({
      txt: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ),
  objects: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.element,
};
FaqContainer.defaultProps = {
  title: '',
  heading: '',
  faqs: '',
  text: [],
  children: null,
};

export default FaqContainer;
