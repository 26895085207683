import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import BaseFormikInput from './BaseFormikInput';
import FormikField from './FormikField';
import FormikFastField from './FormikFastField';

const FormikTextField = ({
  name,
  required,
  label,
  placeholder,
  id,
  readOnly,
  className,
  FieldProps,
  InputProps,
  onChange,
  onClick,
  fast,
  ...otherProps
}) => {
  const Field = fast ? FormikFastField : FormikField;
  return (
    <BaseFormikInput
      filledStyle
      name={name}
      id={id}
      onChange={onChange}
      required={required}
      className={className}
    >
      {({ isError, inputId }) => (
        <>
          {label && (
            <InputLabel
              required={required}
              error={isError}
              shrink
              id={`label-${inputId}`}
              htmlFor={inputId}
            >
              {label}
            </InputLabel>
          )}
          <Field
            id={inputId}
            component={TextField}
            type="text"
            placeholder={placeholder}
            name={name}
            onClick={onClick}
            style={{
              marginTop: 0,
            }}
            InputProps={{
              readOnly,
              ...InputProps,
            }}
            {...FieldProps}
            {...otherProps}
          />
        </>
      )}
    </BaseFormikInput>
  );
};

FormikTextField.propTypes = {
  required: PropTypes.bool,
  fast: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  FieldProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  InputProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

FormikTextField.defaultProps = {
  required: false,
  fast: false,
  label: '',
  placeholder: '',
  readOnly: false,
  children: null,
  className: '',
  id: '',
  FieldProps: {},
  InputProps: {},
  onChange: () => {},
  onClick: () => {},
};

export default FormikTextField;
