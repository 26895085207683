import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    minWidth: 120,
    '& .MuiFormHelperText-root.Mui-error': {
      paddingLeft: 14,
    },
  },
  selectEmpty: {
    height: '100%',
    marginTop: ({ inFormik = true }) => (inFormik ? theme.spacing(2) : 0),
  },
  placeholder: {
    color: theme.variables.VHDarkGrey,
    fontSize: 16,
    fontWeight: 500,
    opacity: 1,
    transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  paper: {
    maxHeight: 250,
    '& .MuiMenu-list': {
      height: '100%',
    },
    '& .MuiListItem-root': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'pre',
    },
  },
  filledPaper: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: ({ borderRadius = 24 }) => borderRadius,
    borderBottomLeftRadius: ({ borderRadius = 24 }) => borderRadius,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.1)',
    backgroundColor: ({ backgroundColor }) => `${theme.variables[backgroundColor]} !important`,
    width: 'auto',
    '& > .MuiList-root': {
      paddingTop: 0,
    },
  },
  input: {
    height: '100%',
    '& .MuiInputAdornment-positionEnd': {
      paddingRight: 14,
    },
    '& .MuiInputAdornment-positionStart': {
      paddingLeft: 6,
    },
    '& .MuiInputBase-input': {
      backgroundColor: ({ backgroundColor }) => theme.variables[backgroundColor],
      borderRadius: ({ borderRadius = 24 }) => borderRadius,
      '&[aria-expanded="true"]': {
        borderRadius: ({ borderRadius = 24 }) => borderRadius,
        borderBottomRightRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      },
    },
    '& .MuiInput-root, & .MuiInputBase-root': {
      fontFamily: theme.variables.defaultFont,
      minHeight: 32,
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.variables.cEmperor,
      marginTop: ({ inFormik = true }) => (inFormik ? theme.spacing(1.5) : 0),
      borderBottom: 'none',
      '&.MuiInputBase-adornedStart, &.MuiInputBase-adornedEnd': {
        backgroundColor: ({ backgroundColor }) => theme.variables[backgroundColor],
        borderRadius: ({ borderRadius = 24 }) => borderRadius,
      },
    },
    '& .MuiInput-input, & .MuiInputBase-input': {
      boxSizing: 'content-box',
      paddingRight: 16,
      paddingLeft: 16,
      paddingTop: 14,
      paddingBottom: 14,
      '&.MuiSelect-select': {
        paddingRight: 30,
      },
      '&.MuiInput-inputAdornedEnd': {
        paddingRight: 0,
      },
      '&.MuiInputBase-inputAdornedStart': {
        paddingLeft: 9,
      },
      '&[readonly]': {
        color: theme.variables.cSilver,
        fontSize: 16,
        fontWeight: 300,
      },
      '&::placeholder': {
        color: theme.variables.VHDarkGrey,
        fontSize: 16,
        fontWeight: 500,
        opacity: 1,
        transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
      // Set styling for autofill case
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        '-webkit-transition': 'color 9999s ease-out, background-color 9999s ease-out',
        '-webkit-transition-delay': '9999s',
      },
    },
    '& .MuiInput-underline': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    '& .MuiInput-formControl': {
      height: '100%',
      alignItems: 'center',
    },
    '& .MuiInputLabel-root': {
      color: theme.variables.cRichOcean,
      position: 'relative',
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      width: 'fit-content',
    },
    '& .MuiSvgIcon-root': {
      right: 10,
    },
    '& .MuiFormLabel-root': {
      fontSize: 16,
      color: theme.variables.VHLightBlack,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '20px',
      transform: 'translate(0, -2px)',
      '&.MuiInputLabel-root': {
        color: theme.variables.VHLightBlack,
      },
      '&.Mui-error': {
        color: theme.variables.VHRed,
      },
    },
    '& .MuiFormHelperText-root': {
      paddingLeft: 14,
    },
  },
}));

export default useStyles;
