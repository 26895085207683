import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = (variables) =>
  createMuiTheme({
    variables,
    typography: {
      fontFamily: variables.defaultFont,
    },
    palette: {
      primary: {
        main: variables.buttonBlue,
        dark: variables.cRichOcean,
        contrastText: variables.cWhite,
      },
      secondary: {
        main: variables.cOrange,
        dark: variables.cDarkOrange,
        contrastText: variables.cWhite,
      },
      error: {
        main: variables.VHRed,
      },
      default: {
        main: variables.cCharcoal,
      },
    },
    status: {
      danger: 'orange',
    },
    overrides: {
      MuiToolbar: {
        root: {
          background: variables.cWhite,
          color: variables.cTextDark,
          boxShadow: 'none',
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          color: variables.cWhite,
          borderRight: 'none',
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
      },
      MuiListItemText: {
        primary: {
          color: 'inherit',
          fontSize: '14px',
        },
      },
      MuiCheckbox: {
        root: {
          padding: '4px',
          '&$checked': {
            color: variables.VHBlue,
          },
        },
      },
      MuiMenuItem: {
        root: {
          paddingBottom: 0,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: 32,
        },
      },
      MuiModal: {
        hidden: {
          visibility: 'visible',
        },
      },
      MuiBadge: {
        badge: {
          width: 30,
          height: 30,
          top: 'auto',
          bottom: -6,
          right: -6,
          background: variables.VHGreen,
          color: variables.cWhite,
          border: `2px solid ${variables.cWhite}`,
          fontSize: 10,
          fontFamily: variables.defaultFont,
        },
        colorSecondary: {
          color: variables.cWhite,
        },
      },
      MuiTable: {
        root: {
          fontFamily: variables.defaultFont,
        },
      },
      MuiTableCell: {
        root: {
          padding: '4px 2px 4px 18px',
          fontSize: '0.88rem',
        },
        head: {
          fontSize: '1rem',
        },
        body: {
          color: variables.cDGray,
        },
      },
      MuiTableRow: {
        root: {
          height: 'auto',
        },
        head: {
          height: 41,
        },
      },
      MuiInput: {
        root: {
          borderBottom: `1px solid ${variables.VHGreyLight}`,
          background: variables.cWhite,
          fontFamily: variables.defaultFont,
          // padding: '0 7px 0 3px',
          minHeight: 32,
          fontSize: '14px',
          color: variables.cRichOcean,
        },
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        formControl: {
          height: '100%',
          alignItems: 'center',
        },
        multiline: {
          // padding: '0 7px 0 3px',
        },
      },
      MuiInputLabel: {
        root: {
          color: variables.cRichOcean,
        },
      },
      MuiSelect: {
        select: {
          // padding: '15px 10px',
        },
      },
      MuiAccordion: {
        root: {
          boxShadow: 'none',
          background: 'transparent',
          '&:before': {
            display: 'none',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiAccordionDetails: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiSlider: {
        root: {
          width: 'inherit',
        },
        track: {
          height: 5,
          borderRadius: 3,
        },
        trackBefore: {
          backgroundColor: `${variables.VHRed}!important`,
        },
        trackAfter: {
          height: 5,
          borderRadius: 3,
          backgroundColor: 'rgba(187, 187, 187, 0.25)',
        },
        thumb: {
          width: 14,
          height: 14,
          backgroundColor: variables.cDGray,
          border: `2px solid ${variables.cWhite}`,
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: '0 0 20px -4px rgba(0,0,0,0.1)',
        },
      },
      MuiTab: {
        root: {
          fontSize: 16,
          paddingTop: 15,
          paddingBottom: 15,
          fontFamily: variables.defaultFont,
        },
      },
      MuiTabs: {
        indicator: {
          bottom: 'inherit',
          top: 0,
          height: 4,
          borderRadius: '4px 4px 0 0',
          backgroundColor: variables.VHBlue,
        },
      },
      MuiButton: {
        root: {
          fontFamily: variables.defaultFont,
        },
        outlined: {
          borderColor: variables.cRichOcean,
          color: variables.cRichOcean,
        },
      },
      MuiChip: {
        root: {
          height: 24,
          background: variables.cAntiFlashWhite,
        },
        deleteIcon: {
          color: variables.VHOrange,
          marginRight: 0,
          height: 18,
        },
      },
      MuiTableHead: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
      MuiTypography: {
        body1: {
          fontFamily: variables.defaultFont,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      },
      MuiDialog: {
        paper: {
          minWidth: 600,
        },
      },
    },
  });

export default createTheme;
