import { loader } from 'graphql.macro';

const meType = loader('./../../../../graphql/queries/core/me.graphql');

const getMe = async ({ client, handleSetUser, projectList = [] }) => {
  const response = await client.query({
    query: meType,
    variables: {
      projectList: projectList.map((project) => project.id),
    },
    fetchPolicy: 'network-only',
  });

  if (response.data && response.data.me) {
    handleSetUser(response.data.me);
    return response.data.me;
  }
  return response.data;
};

export default getMe;
