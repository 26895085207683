import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { setLocale as setValidationLocale } from 'yup';
import translations from './translations.json';
import 'moment/locale/el';
import 'moment/locale/en-gb';
import 'moment/locale/da';
import { addValidationMethods, validationMessages } from '../components/shared/utils';
import EnglishIcon from '../images/language_icons/united-kingdom.svg';
import GreeceIcon from '../images/language_icons/greece.svg';
import DenmarkIcon from '../images/language_icons/denmark.svg';

const handleError = (error) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    window.console.log(error.message);
  }
};

export const AVAILABLE_LOCALES = [
  { value: 'en', label: 'English', icon: EnglishIcon },
  { value: 'el', label: 'Greek', icon: GreeceIcon },
  { value: 'da', label: 'Danish', icon: DenmarkIcon },
];

export const AVAILABLE_LOCALES_CODES = AVAILABLE_LOCALES.reduce(
  (acc, availableLocale) => [...acc, availableLocale.value],
  [],
);

export const DEFAULT_LOCALE = 'en';
export const TranslationContext = React.createContext(null);

const TranslationsProvider = ({ children, me }) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const sessionLocale = localStorage.getItem('locale');

  const switchLocale = useCallback((newLocale) => {
    moment.locale(newLocale);
    setLocale(newLocale);
    localStorage.setItem('locale', newLocale);
    setValidationLocale(validationMessages);
    addValidationMethods();
  }, []);

  useEffect(() => {
    if (AVAILABLE_LOCALES_CODES.includes(sessionLocale) && sessionLocale !== locale) {
      switchLocale(sessionLocale);
    }
  }, [sessionLocale, locale, switchLocale]);

  useEffect(() => {
    switchLocale(locale);
  }, [locale, switchLocale]);

  useEffect(() => {
    if (me) {
      switchLocale(me.language.toLowerCase());
    }
  }, [me, switchLocale]);

  return (
    <TranslationContext.Provider value={{ switchLocale, locale }}>
      <IntlProvider locale={locale} messages={translations[locale]} onError={handleError}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
          {children}
        </MuiPickersUtilsProvider>
      </IntlProvider>
    </TranslationContext.Provider>
  );
};

TranslationsProvider.propTypes = {
  children: PropTypes.element,
  me: PropTypes.shape({
    language: PropTypes.string,
  }),
};

TranslationsProvider.defaultProps = {
  children: null,
  me: {},
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(TranslationsProvider);
