import React, { useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useMutation } from '@apollo/client';
import qs from 'qs';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';
import styles from '../styles';
import NotLoginDefaultPageLayout from '../../layout/notLoginDefaultPageLayout';
import { toastifyError } from '../../shared/utils';
import { FormikTextField } from '../../shared/inputs/formik';

const changePassword = loader('./../../graphql/mutations/core/password_change.graphql');

const ChangePassword = ({ classes, location, history }) => {
  const intl = useIntl();
  const { token, uidb64 } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (!token || !uidb64) {
      toast.error(
        intl.formatMessage({
          id: 'toast.invalid_reset_password_link',
          defaultMessage: 'Invalid reset password link!',
        }),
      );
      history.push('/login/');
    }
  }, [token, uidb64, history, intl]);

  const [changePasswordQuery, { loading, error }] = useMutation(changePassword);

  const handleChangePassword = async (values) => {
    await changePasswordQuery({
      variables: {
        ...values,
        token,
        uidb64,
      },
    });
    history.push('/login/?newPass=true');
  };

  return (
    <NotLoginDefaultPageLayout loading={loading}>
      <Grid container justify="center" alignItems="center" className="h-100">
        <Formik
          initialValues={{
            newPassword: '',
            repeatPassword: '',
          }}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string()
              .required()
              .min(
                8,
                <FormattedMessage
                  id="password.validation.to_short"
                  defaultMessage="Password is too short - should be 8 chars minimum."
                />,
              ),
            repeatPassword: Yup.string().oneOf(
              [Yup.ref('newPassword'), null],
              <FormattedMessage
                id="password.validation.match"
                defaultMessage="Passwords must match"
              />,
            ),
          })}
          onSubmit={(values, { setSubmitting }) =>
            handleChangePassword(values)
              .catch(toastifyError)
              .finally(() => setSubmitting(false))
          }
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form className={classes.wrap}>
              <Grid container justify="center">
                <Grid item xs={12} md={9} lg={7} className={classes.paddedRow}>
                  <Typography variant="h4" align="center">
                    <FormattedMessage
                      id="pageName.changePassword"
                      defaultMessage="Change password"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} lg={7} className={classes.paddedRow}>
                  <FormikTextField
                    name="newPassword"
                    type="password"
                    label={intl.formatMessage({
                      id: 'password.placeholder.insertPassword',
                      defaultMessage: 'Password',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={7}>
                  <FormikTextField
                    name="repeatPassword"
                    type="password"
                    label={intl.formatMessage({
                      id: 'password.placeholder.repeat',
                      defaultMessage: 'Repeat password',
                    })}
                  />
                </Grid>
                <Grid item container justify="center" xs={12} md={9} lg={7}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.submitBtn}
                      onClick={submitForm}
                      disabled={isSubmitting || !values.newPassword || !values.repeatPassword}
                    >
                      <FormattedMessage
                        id="button.change_password"
                        defaultMessage="CHANGE PASSWORD"
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={7}
                  className={classNames(classes.submitRow, classes.alignCenter)}
                >
                  <Link to="/login" className={classes.forgotPassword}>
                    <FormattedMessage id="link.back_to_login" defaultMessage="Back to Login" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={9} lg={7}>
                  <div className={classes.errorMessage}>{error?.message}</div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </NotLoginDefaultPageLayout>
  );
};

ChangePassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChangePassword);
