import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { FormikDatePicker } from '../../../../shared/inputs/formik';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    color: theme.variables.VHLightBlack,
    fontWeight: 'bold',
    lineHeight: '20px',
    transform: 'translate(0, -2px)',
  },
  centredLabel: {
    marginTop: 14,
  },
  maskedInput: {
    '& .MuiInput-input': {
      textAlign: 'center',
    },
  },
}));

const DateRangeFields = ({ prefix, showLabel }) => {
  const classes = useStyles();

  return (
    <>
      {showLabel && (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.label}>
            <FormattedMessage id="valid_from" defaultMessage="Valid from" />
          </Typography>
        </Grid>
      )}
      <Grid container item xs={12} justify="space-between">
        <Grid item xs={12} md>
          <FormikDatePicker
            name={`${prefix}${prefix ? '.' : ''}validFrom`}
            className={classes.maskedInput}
            placeholder="2020.12.31"
          />
        </Grid>
        <Grid item xs={12} md={1} container justify="center" alignItems="center">
          <Typography variant="body2" className={classNames(classes.label, classes.centredLabel)}>
            <FormattedMessage id="to" defaultMessage="to" />
          </Typography>
        </Grid>
        <Grid item xs={12} md>
          <FormikDatePicker
            name={`${prefix}${prefix ? '.' : ''}validTo`}
            className={classes.maskedInput}
            placeholder="2020.12.31"
          />
        </Grid>
      </Grid>
    </>
  );
};

DateRangeFields.propTypes = {
  prefix: PropTypes.string,
  showLabel: PropTypes.bool,
};

DateRangeFields.defaultProps = {
  prefix: '',
  showLabel: false,
};

export default DateRangeFields;
