import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { Grid, Button } from '@material-ui/core';

const Photos = ({ classes }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Grid xs={12} className="h-100">
      <div className={classNames(classes.emptyPhotosBlock)}>
        <div {...getRootProps({ className: `${classes.dropzone} dropzone` })}>
          <input {...getInputProps()} />
          <div className={classNames(classes.absoluteCentered, classes.dragAndDropBlock)}>
            <FormattedMessage
              id="drug_and_drop_files"
              defaultMessage="Drug and drop your files here or"
            >
              {(txt) => <span>{txt}</span>}
            </FormattedMessage>
            <Button type="button" className={classes.browseButton} onClick={open}>
              Browse
            </Button>
          </div>
        </div>
      </div>
    </Grid>
  );
};

Photos.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Photos;
