import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { loader } from 'graphql.macro';
import FormikSelect from '../inputs/formik/FormikSelect';
import NoItemsForSelect from './noItemsForSelect';
import InputSkeleton from '../inputs/InputSkeleton';

const allUsersQuery = loader('./../../graphql/queries/core/all_users.graphql');

const SelectUser = ({
  name,
  placeholder,
  label,
  extraFilter,
  extraValues,
  onChange,
  isProjectOwner,
  ownProjectsIds,
  ...props
}) => {
  const { loading, data: { allUsers: { edges: allUsers = [] } = {} } = {} } = useQuery(
    allUsersQuery,
    {
      variables: {
        ...extraFilter,
        activeProjects: isProjectOwner ? ownProjectsIds.join(',') : null,
      },
    },
  );

  const users = useMemo(
    () =>
      allUsers.map(({ node }) =>
        Object({
          value: node.id,
          label:
            node.firstName && node.lastName ? `${node.firstName} ${node.lastName}` : node.username,
        }),
      ),
    [allUsers],
  );

  return (
    <Grid item xs={12}>
      {loading ? (
        <InputSkeleton />
      ) : (
        <>
          {users.length > 0 || extraValues.length > 0 ? (
            <FormikSelect
              placeholder={placeholder}
              label={label}
              required
              filledStyle
              name={name}
              valuesList={[...users, ...extraValues]}
              onChange={(value) => {
                onChange(allUsers.find(({ node }) => node.id === value)?.node);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...props}
            />
          ) : (
            <NoItemsForSelect
              link="/app/fleet-management/driver-management/invite"
              message={<FormattedMessage id="no_depot" defaultMessage="No user registered." />}
              linkText={<FormattedMessage id="add_depot" defaultMessage="Click to add new user." />}
            />
          )}
        </>
      )}
    </Grid>
  );
};

SelectUser.propTypes = {
  extraFilter: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  extraValues: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isProjectOwner: PropTypes.bool,
  ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
};

SelectUser.defaultProps = {
  name: 'name',
  extraFilter: {},
  extraValues: [],
  isProjectOwner: false,
  ownProjectsIds: [],
};

export default React.memo(SelectUser);
