import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart as RechartsPieChart, Pie, Sector, ResponsiveContainer } from 'recharts';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload } = props;
  const { fill } = payload;

  return (
    <g>
      <text x={cx} y={cy} dy={10} textAnchor="middle" fill={fill} />
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

renderActiveShape.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  innerRadius: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired,
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    fill: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default function PieChart({
  children,
  centeredElement,
  data,
  activeOnHover,
  height,
  innerRadius,
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (pieData, index) => {
    setActiveIndex(index);
  };

  return (
    <div style={{ height: `${height}vh` }} className="position-relative">
      {centeredElement && centeredElement}
      <ResponsiveContainer width="99.9%" height="99.9%">
        <RechartsPieChart>
          <Pie
            activeIndex={activeOnHover ? activeIndex : undefined}
            activeShape={renderActiveShape}
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius="80%"
            fill="#8884d8"
            onMouseEnter={activeOnHover ? onPieEnter : undefined}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
      {children}
    </div>
  );
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fill: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  children: PropTypes.element,
  activeOnHover: PropTypes.bool,
  height: PropTypes.number,
  innerRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  centeredElement: PropTypes.element,
};

PieChart.defaultProps = {
  children: null,
  activeOnHover: true,
  height: 15,
  innerRadius: '55%',
  centeredElement: null,
};
