import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import AutocompleteFilter from '../autocompleteFilter/autocompleteFilter';
import SearchIcon from '../../../images/icons/search.svg';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 10,
    paddingLeft: 20,
    paddingRight: 10,
  },
  searchConteiner: {
    maxWidth: '100%',
    width: '100%',
  },
}));

const SearchField = withStyles({
  root: {
    minWidth: 260,
    padding: 0,
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 24,
        height: 48,
        paddingRight: 16,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '11.5px 14px',
    },
  },
})(TextField);

const FilterRow = ({
  children,
  query,
  serializer,
  iconSize,
  pagination,
  queryExtraVariables,
  searchSize,
  paginationClass,
  setSearchString,
  isSearchAutocomplete,
  leftSideElements,
  searchFieldClass,
  isRedirectOnSelect,
  rootClassName,
  haveGenerateButton,
  freezeAutocompleteInputForASecond,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      justify="space-between"
      alignItems="center"
      className={classNames(classes.root, rootClassName)}
    >
      {leftSideElements}
      <Grid item xs={12} lg={searchSize || 4} className={classes.searchConteiner}>
        {isSearchAutocomplete ? (
          <AutocompleteFilter
            query={query}
            serializer={serializer}
            queryExtraVariables={queryExtraVariables}
            isRedirectOnSelect={isRedirectOnSelect}
            freezeAutocompleteInputForASecond={freezeAutocompleteInputForASecond}
          />
        ) : (
          <SearchField
            className={searchFieldClass}
            variant="outlined"
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setSearchString(value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search" />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>
      <Grid xs={haveGenerateButton ? 3 : 5}>{children}</Grid>
      {iconSize && (
        <Grid item xs={4} lg={1}>
          {iconSize}
        </Grid>
      )}
      <Grid item xs={haveGenerateButton ? 5 : 3} className={paginationClass}>
        {pagination}
      </Grid>
    </Grid>
  );
};

FilterRow.propTypes = {
  children: PropTypes.element,
  query: PropTypes.objectOf(PropTypes.any),
  queryExtraVariables: PropTypes.objectOf(PropTypes.any),
  serializer: PropTypes.func,
  pagination: PropTypes.element,
  iconSize: PropTypes.element,
  searchSize: PropTypes.number,
  paginationClass: PropTypes.string,
  setSearchString: PropTypes.func,
  isSearchAutocomplete: PropTypes.bool,
  leftSideElements: PropTypes.node,
  searchFieldClass: PropTypes.string,
  isRedirectOnSelect: PropTypes.bool,
  rootClassName: PropTypes.string,
  haveGenerateButton: PropTypes.bool,
  freezeAutocompleteInputForASecond: PropTypes.bool,
};

FilterRow.defaultProps = {
  query: null,
  children: null,
  serializer: () => [],
  pagination: null,
  queryExtraVariables: {},
  iconSize: null,
  searchSize: null,
  paginationClass: '',
  setSearchString: () => {},
  isSearchAutocomplete: true,
  leftSideElements: null,
  searchFieldClass: null,
  isRedirectOnSelect: true,
  rootClassName: '',
  haveGenerateButton: false,
  freezeAutocompleteInputForASecond: false,
};

export default FilterRow;
