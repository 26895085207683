import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import Table from '@material-ui/core/Table';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Switch, FormControlLabel, Checkbox } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Formik, Form } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
  toastifyError,
  saveCacheRead,
  getWasteFractionFromContainer,
} from '../../../../shared/utils';
import { FormikSelect, FormikTextField } from '../../../../shared/inputs/formik';
import { getActiveProjects } from '../../../../shared/utils/settings';
import IconInfo from '../../../../../images/icons/iconInfo.svg';
import TrashIcon from '../../../../../images/icons/trashIcon.svg';
import PenIcon from '../../../../../images/icons/penIcon.svg';
import { PERIOD_VALUES } from './utils';

const createCostSettingsMutation = loader(
  '../../../../graphql/mutations/analytics/create_cost_settings.graphql',
);

const getAllCostSettingsQuery = loader(
  '../../../../graphql/queries/analytics/cost_settings.graphql',
);

const deleteCostSettingsMutation = loader(
  '../../../../graphql/mutations/analytics/delete_cost_settings.graphql',
);

const updateCostSettingsMutation = loader(
  '../../../../graphql/mutations/analytics/update_cost_settings.graphql',
);

const getAllComparisonSettingsQuery = loader(
  '../../../../graphql/queries/analytics/comparison_settings.graphql',
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.variables.cAntiFlashWhite,
    color: theme.variables.VHDarkGrey,
    fontSize: '11px',
    paddingRight: '10px',
  },
  body: {
    fontSize: '12px',
    paddingRight: '10px',
  },
  root: {
    height: '25px',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    height: '25px',
    backgroundColor: '#f9fafd',
  },
}))(TableRow);

const costSettingsValidationSchema = () =>
  Yup.object().shape({
    collectionCost: Yup.number().required(),
    overflowingCost: Yup.number(),
    containerType: Yup.string().required(),
    wasteFraction: Yup.string().required(),
    costPerTonne: Yup.number(),
    incomePerTonne: Yup.number(),
    weightFactor: Yup.number(),
    amountOfCollection: Yup.number(),
    chanceOfOverflowing: Yup.number().nullable(),
    amountOfCollectionPeriod: Yup.string(),
    averageFillLevel: Yup.number().nullable(),
  });

const ContainerSettings = ({
  classes,
  fractions,
  containerTypes,
  savedActiveProjects,
  allCostSettings,
  currencyCoef,
  currencySymbol,
  weightToVolumeCoef,
  weightSymbol,
  volumeSymbol,
  businessAnalyticsRefetch,
  selectedCombinations,
  setSelectedCombinations,
}) => {
  const intl = useIntl();
  const activeProjects = useSelector((state) => getActiveProjects(state));

  const [allProjects, setAllProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(
    savedActiveProjects.includes(',') ? '' : savedActiveProjects,
  );
  const [selectedCostSettings, setSelectedCostSettings] = useState(null);
  const [selectedFraction, setSelectedFraction] = useState('');
  const [selectedContainerType, setSelectedContainerType] = useState('');
  const [editingId, setEditingId] = useState('');
  const [withSensor, setWithSensor] = useState(true);

  const [createCostSettings] = useMutation(createCostSettingsMutation);
  const [updateCostSettings] = useMutation(updateCostSettingsMutation);
  const [deleteCostSettings] = useMutation(deleteCostSettingsMutation);

  const isSelectedCombination = (id) => selectedCombinations.indexOf(id) !== -1;

  const fractionsToSelect = useMemo(() => {
    if (selectedProject) {
      if (selectedContainerType) {
        const fractionsWithSameContainerType = [];

        allCostSettings?.allCostSettings?.edges?.forEach(({ node }) => {
          if (node.containerType.id === selectedContainerType) {
            fractionsWithSameContainerType.push(node.wasteFraction.id);
          }
        });

        const fractionsToReturn = fractions.filter(
          ({ project, value }) =>
            selectedProject === project && !fractionsWithSameContainerType.includes(value),
        );

        if (selectedCostSettings) {
          if (
            !fractionsToReturn.includes(
              fractions.find(({ value }) => value === selectedCostSettings.wasteFraction.id),
            )
          ) {
            fractionsToReturn.push(
              fractions.find(({ value }) => value === selectedCostSettings.wasteFraction.id),
            );
          }
        }

        return fractionsToReturn;
      }
      return fractions.filter(({ project }) => selectedProject === project);
    }
    return fractions;
  }, [
    fractions,
    selectedProject,
    selectedContainerType,
    allCostSettings?.allCostSettings?.edges,
    selectedCostSettings,
  ]);

  const containerTypesToSelect = useMemo(() => {
    if (selectedProject) {
      if (selectedFraction) {
        const containerTypeWithSameFraction = [];

        allCostSettings?.allCostSettings?.edges?.forEach(({ node }) => {
          if (node.wasteFraction.id === selectedFraction) {
            containerTypeWithSameFraction.push(node.containerType.id);
          }
        });

        const containerTypesToReturn = containerTypes.filter(
          ({ project, value }) =>
            selectedProject === project && !containerTypeWithSameFraction.includes(value),
        );

        if (selectedCostSettings) {
          if (
            !containerTypesToReturn.includes(
              containerTypes.find(({ value }) => selectedCostSettings.containerType.id === value),
            )
          )
            containerTypesToReturn.push(
              containerTypes.find(({ value }) => selectedCostSettings.containerType.id === value),
            );
        }
        return containerTypesToReturn;
      }
      return containerTypes.filter(({ project }) => selectedProject === project);
    }
    return containerTypes;
  }, [
    containerTypes,
    selectedProject,
    selectedFraction,
    allCostSettings?.allCostSettings?.edges,
    selectedCostSettings,
  ]);

  const uniqueCombination =
    editingId ||
    !allCostSettings?.allCostSettings ||
    (allCostSettings?.allCostSettings &&
      !allCostSettings.allCostSettings.edges.find(
        ({ node }) =>
          selectedFraction === node.wasteFraction.id &&
          selectedContainerType === node.containerType.id,
      ));

  useEffect(() => {
    const projects = activeProjects?.map(({ node: activeProject = {} }) => ({
      value: activeProject.id,
      label: activeProject.name,
    }));
    setAllProjects(projects);
  }, [activeProjects]);

  const formSubmitHandler = async (values, { setSubmitting, resetForm, setFieldError }) => {
    setSubmitting(true);

    const variables = values;

    if (!withSensor) {
      if (!variables.amountOfCollectionPeriod) {
        setFieldError('amountOfCollectionPeriod', 'This field is required');
        setSubmitting(false);
        return;
      }
      if (!variables.amountOfCollection) {
        setFieldError('amountOfCollection', 'This field is required');
        setSubmitting(false);
        return;
      }
    }

    variables.collectionCost /= currencyCoef;
    variables.overflowingCost /= currencyCoef;
    variables.incomePerTonne /= currencyCoef;
    variables.costPerTonne /= currencyCoef;
    variables.weightFactor /= weightToVolumeCoef;
    variables.ownValues = !withSensor;

    if (withSensor) {
      delete variables.amountOfCollection;
      delete variables.chanceOfOverflowing;
      delete variables.amountOfCollectionPeriod;
      delete variables.averageFillLevel;
    }
    delete variables.project;

    if (editingId) {
      updateCostSettings({
        variables: { input: { ...values, id: editingId } },
        update: async (
          cache,
          {
            data: {
              updateCostSettings: { costSettings },
            },
          },
        ) => {
          const costSettingsFullQuery = {
            query: getAllCostSettingsQuery,
            variables: {
              activeProjects: savedActiveProjects,
            },
          };

          const {
            data: { allCostSettings: allCostSettingsData },
          } = await saveCacheRead(costSettingsFullQuery);

          const newAllCostSettingsData = {
            ...allCostSettingsData,
            edges: [
              ...allCostSettingsData.edges.filter(({ node }) => node.id !== editingId),
              {
                node: costSettings,
                __typename: 'CostSettingsTypeEdge',
              },
            ],
          };
          cache.writeQuery({
            ...costSettingsFullQuery,
            data: {
              allCostSettings: newAllCostSettingsData,
            },
          });
        },
      })
        .then(() => {
          toast.info(
            intl.formatMessage({
              id: 'toast.cost_settings_updated',

              defaultMessage: 'Cost settings were successfully updated',
            }),
          );
          setSelectedCostSettings(null);
          resetForm();
        })
        .catch((error) => {
          toastifyError(error);
        })
        .finally(() => {
          setEditingId('');
          setSubmitting(false);
        });
    } else {
      createCostSettings({
        variables: { input: variables },
        update: async (
          cache,
          {
            data: {
              createCostSettings: { costSettings },
            },
          },
        ) => {
          const costSettingsFullQuery = {
            query: getAllCostSettingsQuery,
            variables: {
              activeProjects: savedActiveProjects,
            },
          };

          const {
            data: { allCostSettings: allCostSettingsData },
          } = await saveCacheRead(costSettingsFullQuery);

          const newAllCostSettingsData = {
            ...allCostSettingsData,
            edges: [
              ...allCostSettingsData.edges,
              {
                node: costSettings,
                __typename: 'CostSettingsTypeEdge',
              },
            ],
          };

          cache.writeQuery({
            ...costSettingsFullQuery,
            data: {
              allCostSettings: newAllCostSettingsData,
            },
          });
        },
      })
        .then(() => {
          toast.info(
            intl.formatMessage({
              id: 'toast.cost_settings_created',
              defaultMessage: 'Cost settings were successfully created',
            }),
          );
          resetForm();
          businessAnalyticsRefetch();
          setSelectedCostSettings(null);
        })
        .catch((error) => {
          toastifyError(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const handleDeleteCostSettings = async (e, selfId, resetForm) => {
    e.preventDefault();
    const variables = { selfId };

    return deleteCostSettings({
      variables,
      update: async (
        cache,
        {
          data: {
            deleteCostSettings: { status },
          },
        },
      ) => {
        if (status !== 'Success') {
          throw new Error(`Cost setting could not be removed: ${status}`);
        }

        // Update Cost settings cache
        const {
          data: { allCostSettings: allCostSettingsData },
        } = await saveCacheRead({
          query: getAllCostSettingsQuery,
          variables: { activeProjects: savedActiveProjects },
        });

        const newCostSettingsData = {
          ...allCostSettingsData,
          edges: allCostSettingsData.edges.filter(({ node }) => node.id !== selfId),
        };

        cache.writeQuery({
          query: getAllCostSettingsQuery,
          variables: { activeProjects: savedActiveProjects },
          data: { allCostSettings: newCostSettingsData },
        });

        // Update Comparison settings cache
        const {
          data: { allComparisonSettings: allComparisonSettingsData },
        } = await saveCacheRead({
          query: getAllComparisonSettingsQuery,
          variables: { activeProjects: savedActiveProjects },
        });

        const newComparisonSettings = {
          ...allComparisonSettingsData,
          edges: allComparisonSettingsData.edges.filter(
            ({ node }) => node.costSettings.id !== selfId,
          ),
        };

        cache.writeQuery({
          query: getAllComparisonSettingsQuery,
          variables: { activeProjects: savedActiveProjects },
          data: { allComparisonSettings: newComparisonSettings },
        });
      },
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'toast.comparison_settings_removed',
            defaultMessage: 'Comparison setting was successfully removed',
          }),
        );
        businessAnalyticsRefetch();
        resetForm();
        setSelectedCostSettings(null);
      })
      .catch((error) => {
        toastifyError(error);
      });
  };

  const editCostSettings = (selfId, setFieldValue) => {
    setEditingId(selfId);

    const costSettings = allCostSettings.allCostSettings.edges.find(
      ({ node }) => node.id === selfId,
    ).node;

    setSelectedCostSettings(costSettings);
    setFieldValue('project', costSettings.containerType.project.id);
    setFieldValue('containerType', costSettings.containerType.id);
    setFieldValue('wasteFraction', costSettings.wasteFraction.id);
    setFieldValue('collectionCost', costSettings.collectionCost * currencyCoef);
    setFieldValue('overflowingCost', costSettings.overflowingCost * currencyCoef);
    setFieldValue('costPerTonne', costSettings.costPerTonne * currencyCoef);
    setFieldValue('incomePerTonne', costSettings.incomePerTonne * currencyCoef);
    setFieldValue('weightFactor', costSettings.weightFactor * weightToVolumeCoef);
    setWithSensor(!costSettings.ownValues);
    setFieldValue('amountOfCollection', costSettings.amountOfCollection);
    setFieldValue('amountOfCollectionPeriod', costSettings.amountOfCollectionPeriod.toLowerCase());
    setFieldValue('chanceOfOverflowing', costSettings.chanceOfOverflowing);
    setFieldValue('averageFillLevel', costSettings.averageFillLevel);
  };

  const cancelEditing = (resetForm) => {
    setEditingId('');
    setSelectedCostSettings(null);
    resetForm();
  };

  const rows = useMemo(() => {
    let projectCostSettings = allCostSettings?.allCostSettings?.edges;

    if (!projectCostSettings) {
      return [];
    }

    if (selectedProject) {
      projectCostSettings = projectCostSettings.filter(
        ({ node }) => node.containerType.project.id === selectedProject,
      );
    }

    return projectCostSettings.map(({ node }) => ({
      id: node.id,
      combination: `${node.containerType.name} + ${getWasteFractionFromContainer(node)}`,
      collection: node.collectionCost * currencyCoef,
      overflowing: node.overflowingCost * currencyCoef,
      costPerTonne: node.costPerTonne * currencyCoef,
      incomePerTonne: node.incomePerTonne * currencyCoef,
    }));
  }, [allCostSettings, selectedProject, currencyCoef]);

  const handleSelectCombination = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selectedCombinations.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCombinations, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCombinations.slice(1));
    } else if (selectedIndex === selectedCombinations.length - 1) {
      newSelected = newSelected.concat(selectedCombinations.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCombinations.slice(0, selectedIndex),
        selectedCombinations.slice(selectedIndex + 1),
      );
    }

    setSelectedCombinations(newSelected);
  };
  return (
    <>
      <Grid item xs={12} className="m-t-20">
        <FormattedMessage id="select_combination" defaultMessage="Select combination">
          {(txt) => <span className={classes.costSettingsSubtitle}>{txt}</span>}
        </FormattedMessage>
      </Grid>
      <Formik
        initialValues={{
          containerType: '',
          wasteFraction: '',
          collectionCost: '',
          overflowingCost: '',
          costPerTonne: '',
          incomePerTonne: '',
          weightFactor: '',
          project: '',
          amountOfCollection: '',
          chanceOfOverflowing: null,
          amountOfCollectionPeriod: '',
          averageFillLevel: null,
        }}
        onSubmit={formSubmitHandler}
        validationSchema={costSettingsValidationSchema}
      >
        {({ submitForm, resetForm, setFieldValue }) => (
          <Form className="w-100 m-t-20">
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <FormikSelect
                    label={intl.formatMessage({
                      id: 'label.project',
                      defaultMessage: 'Project',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'placeholder.project',
                      defaultMessage: 'Project',
                    })}
                    filledStyle
                    name="project"
                    onChange={(value) => setSelectedProject(value)}
                    valuesList={allProjects}
                    labelClass={classes.inputLabel}
                    fieldClass={classes.fieldClass}
                    withoutNoneChoice
                  />
                </Grid>
              </Grid>
              {selectedProject && (
                <>
                  <Grid item xs={12} md={6} className="m-t-20">
                    <FormikSelect
                      label={intl.formatMessage({
                        id: 'label.selectedContainerType',
                        defaultMessage: 'Container type',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'placeholder.selectedContainerType',
                        defaultMessage: 'Container type',
                      })}
                      filledStyle
                      name="containerType"
                      onChange={(value) => setSelectedContainerType(value)}
                      valuesList={containerTypesToSelect}
                      labelClass={classes.inputLabel}
                      fieldClass={classes.fieldClass}
                      withoutNoneChoice
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="p-l-10 p-r-20 m-t-20">
                    <FormikSelect
                      label={intl.formatMessage({
                        id: 'label.selectedWasteFraction',
                        defaultMessage: 'Waste fraction',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'placeholder.selectedWasteFraction',
                        defaultMessage: 'WasteFraction',
                      })}
                      filledStyle
                      name="wasteFraction"
                      onChange={(value) => setSelectedFraction(value)}
                      valuesList={fractionsToSelect}
                      labelClass={classes.inputLabel}
                      fieldClass={classes.fieldClass}
                      withoutNoneChoice
                      disabled={!selectedContainerType}
                    />
                  </Grid>
                  {!uniqueCombination && (
                    <FormattedMessage
                      id="combination_duplicate"
                      defaultMessage="This combination already exists"
                    >
                      {(txt) => <p className={classes.combinationError}>{txt}</p>}
                    </FormattedMessage>
                  )}
                  {selectedFraction && selectedContainerType && uniqueCombination && (
                    <>
                      <Grid xs={12} className="m-t-10">
                        <FormattedMessage id="enter_values" defaultMessage="Enter values">
                          {(txt) => <p className={classes.costSettingsSubtitle}>{txt}</p>}
                        </FormattedMessage>
                        <Grid container xs={12} justify="space-around" spacing={2}>
                          <Grid item xs={4} className={classes.formLabelItem}>
                            <Grid container wrap="nowrap">
                              <Grid item>
                                <FormattedMessage id="label.collection" defaultMessage="Collection">
                                  {(txt) => (
                                    <span className={classes.formLabel}>
                                      {txt}{' '}
                                      <Tooltip
                                        title={intl.formatMessage({
                                          id: 'label.collection',
                                          defaultMessage:
                                            'The cost for a waste hauler to collect a combination of this container type and waste fraction.',
                                        })}
                                      >
                                        <img
                                          className={classes.helpIcon}
                                          src={IconInfo}
                                          alt="tooltip"
                                        />
                                      </Tooltip>
                                    </span>
                                  )}
                                </FormattedMessage>
                              </Grid>
                              <Grid item className="w-100">
                                <FormikTextField
                                  name="collectionCost"
                                  type="number"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment className={classes.adornment} position="end">
                                        {currencySymbol}
                                      </InputAdornment>
                                    ),
                                    onInput: (e) => {
                                      if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                        const newValue = e.target.value.replaceAll('-', '');
                                        e.target.value = newValue;
                                      }
                                    },
                                  }}
                                  className={classes.formField}
                                  onChange={(value) => {
                                    const [
                                      beforeSeparator,
                                      afterSeparator,
                                    ] = value.toString().split('.');
                                    const digitsLength = afterSeparator?.length || 0;
                                    const maxLength = beforeSeparator?.length + 3;
                                    if (digitsLength > 2) {
                                      setFieldValue(
                                        'collectionCost',
                                        value.toString().slice(0, maxLength),
                                        true,
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={4} item className={classes.formLabelItem}>
                            <Grid container wrap="nowrap">
                              <Grid item>
                                <FormattedMessage
                                  id="label.overflowing"
                                  defaultMessage="Overflowing"
                                >
                                  {(txt) => (
                                    <span className={classes.formLabel}>
                                      {txt}{' '}
                                      <Tooltip
                                        title={intl.formatMessage({
                                          id: 'label.overflowing',
                                          defaultMessage:
                                            'The cost of collecting overflowing waste from a combination of this container type and waste fraction.',
                                        })}
                                      >
                                        <img
                                          className={classes.helpIcon}
                                          src={IconInfo}
                                          alt="tooltip"
                                        />
                                      </Tooltip>
                                    </span>
                                  )}
                                </FormattedMessage>
                              </Grid>
                              <Grid item className="w-100">
                                <FormikTextField
                                  name="overflowingCost"
                                  type="number"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment className={classes.adornment} position="end">
                                        {currencySymbol}
                                      </InputAdornment>
                                    ),
                                    onInput: (e) => {
                                      if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                        const newValue = e.target.value.replaceAll('-', '');
                                        e.target.value = newValue;
                                      }
                                    },
                                  }}
                                  className={classes.formField}
                                  onChange={(value) => {
                                    const [
                                      beforeSeparator,
                                      afterSeparator,
                                    ] = value.toString().split('.');
                                    const digitsLength = afterSeparator?.length || 0;
                                    const maxLength = beforeSeparator?.length + 3;
                                    if (digitsLength > 2) {
                                      setFieldValue(
                                        'overflowingCost',
                                        value.toString().slice(0, maxLength),
                                        true,
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={4} item className={classes.formLabelItem}>
                            <Grid container wrap="nowrap">
                              <Grid item>
                                <FormattedMessage
                                  id="label.disposalCost"
                                  defaultMessage="Disposal cost"
                                >
                                  {(txt) => (
                                    <span className={classes.formLabel}>
                                      {txt}{' '}
                                      <Tooltip
                                        title={intl.formatMessage(
                                          {
                                            id: 'label.cost_per_tonne',
                                            defaultMessage:
                                              'The cost of disposing of 1 {unit} of this waste fraction.',
                                          },
                                          {
                                            unit: weightSymbol,
                                          },
                                        )}
                                      >
                                        <img
                                          className={classes.helpIcon}
                                          src={IconInfo}
                                          alt="tooltip"
                                        />
                                      </Tooltip>
                                    </span>
                                  )}
                                </FormattedMessage>
                              </Grid>
                              <Grid item className="w-100">
                                <FormikTextField
                                  name="costPerTonne"
                                  type="number"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment className={classes.adornment} position="end">
                                        {currencySymbol}
                                      </InputAdornment>
                                    ),
                                    onInput: (e) => {
                                      if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                        const newValue = e.target.value.replaceAll('-', '');
                                        e.target.value = newValue;
                                      }
                                    },
                                  }}
                                  className={classes.formField}
                                  onChange={(value) => {
                                    const [
                                      beforeSeparator,
                                      afterSeparator,
                                    ] = value.toString().split('.');
                                    const digitsLength = afterSeparator?.length || 0;
                                    const maxLength = beforeSeparator?.length + 3;
                                    if (digitsLength > 2) {
                                      setFieldValue(
                                        'costPerTonne',
                                        value.toString().slice(0, maxLength),
                                        true,
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={12} container spacing={2}>
                            <Grid item xs={5} className={classes.formLabelItem}>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <FormattedMessage
                                    id="label.income_per_tonne"
                                    defaultMessage="Income"
                                  >
                                    {(txt) => (
                                      <span className={classes.formLabel}>
                                        {txt}{' '}
                                        <Tooltip
                                          title={intl.formatMessage(
                                            {
                                              id: 'label.income_per_tonne',
                                              defaultMessage:
                                                'The income received from recycling 1 {unit} of this waste fraction.',
                                            },
                                            {
                                              unit: weightSymbol,
                                            },
                                          )}
                                        >
                                          <img
                                            className={classes.helpIcon}
                                            src={IconInfo}
                                            alt="tooltip"
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </FormattedMessage>
                                </Grid>
                                <Grid item className="w-100">
                                  <FormikTextField
                                    name="incomePerTonne"
                                    type="number"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          className={classes.adornment}
                                          position="end"
                                        >
                                          {currencySymbol}
                                        </InputAdornment>
                                      ),
                                      onInput: (e) => {
                                        if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                          const newValue = e.target.value.replaceAll('-', '');
                                          e.target.value = newValue;
                                        }
                                      },
                                    }}
                                    className={classes.formField}
                                    onChange={(value) => {
                                      const [
                                        beforeSeparator,
                                        afterSeparator,
                                      ] = value.toString().split('.');
                                      const digitsLength = afterSeparator?.length || 0;
                                      const maxLength = beforeSeparator?.length + 3;
                                      if (digitsLength > 2) {
                                        setFieldValue(
                                          'incomePerTonne',
                                          value.toString().slice(0, maxLength),
                                          true,
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={7} className={classes.formLabelItem}>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <FormattedMessage
                                    id="label.weight_to_volume_ratio"
                                    defaultMessage="Weight to volume ratio"
                                  >
                                    {(txt) => (
                                      <span className={classes.formLabel}>
                                        {txt}{' '}
                                        <Tooltip
                                          title={intl.formatMessage({
                                            id: 'label.weight_to_volume_ratio',
                                            defaultMessage:
                                              'Add the weight to volume ratio of your waste. The default is 1.',
                                          })}
                                        >
                                          <img
                                            className={classes.helpIcon}
                                            src={IconInfo}
                                            alt="tooltip"
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </FormattedMessage>
                                </Grid>
                                <Grid item className="w-100">
                                  <FormikTextField
                                    name="weightFactor"
                                    type="number"
                                    className={classes.formField}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          className={classes.adornment}
                                          position="end"
                                        >
                                          {weightSymbol}/{volumeSymbol}
                                        </InputAdornment>
                                      ),
                                      onInput: (e) => {
                                        if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                          const newValue = e.target.value.replaceAll('-', '');
                                          e.target.value = newValue;
                                        }
                                      },
                                    }}
                                    onChange={(value) => {
                                      const [
                                        beforeSeparator,
                                        afterSeparator,
                                      ] = value.toString().split('.');
                                      const digitsLength = afterSeparator?.length || 0;
                                      const maxLength = beforeSeparator?.length + 3;
                                      if (digitsLength > 2) {
                                        setFieldValue(
                                          'weightFactor',
                                          value.toString().slice(0, maxLength),
                                          true,
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        className={classNames(classes.addCombinationButtonBlock, 'm-t-30')}
                        justify="space-between"
                      >
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={withSensor}
                                onChange={() => setWithSensor(!withSensor)}
                                color="primary"
                                size="small"
                              />
                            }
                            classes={{ label: classes.switchLabel }}
                            label={intl.formatMessage({
                              id: 'label.with_sensor',
                              defaultMessage: 'With Sensor',
                            })}
                          />
                        </Grid>
                        {!withSensor && (
                          <>
                            <Grid xs={12} className={classNames(classes.divider, 'm-t-20')} />
                            <FormattedMessage
                              id="select_static_collection"
                              defaultMessage="Select static collection"
                            >
                              {(txt) => <p className={classes.costSettingsSubtitle}>{txt}</p>}
                            </FormattedMessage>
                          </>
                        )}

                        <Grid container xs={12} spacing={2}>
                          {!withSensor && (
                            <>
                              <Grid item xs={3}>
                                <FormikSelect
                                  label={intl.formatMessage({
                                    id: 'label.choose_period',
                                    defaultMessage: 'Choose period',
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: 'placeholder.choose_period',
                                    defaultMessage: 'Choose period',
                                  })}
                                  filledStyle
                                  name="amountOfCollectionPeriod"
                                  onChange={() => {}}
                                  valuesList={PERIOD_VALUES}
                                  labelClass={classNames(classes.inputLabel, 'm-t-10')}
                                  fieldClass={classes.fieldClass}
                                  withoutNoneChoice
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FormattedMessage
                                  id="label.amount_of_collections"
                                  defaultMessage="Amount of collections"
                                >
                                  {(txt) => <span className={classes.formLabel}>{txt}</span>}
                                </FormattedMessage>
                                <FormikTextField
                                  name="amountOfCollection"
                                  type="number"
                                  className={classes.formField}
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <FormattedMessage
                                  id="label.chance_of_overflowing"
                                  defaultMessage="Chance of overflowing"
                                >
                                  {(txt) => <span className={classes.formLabel}>{txt}</span>}
                                </FormattedMessage>
                                <FormikTextField
                                  name="chanceOfOverflowing"
                                  type="number"
                                  className={classes.formField}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment className={classes.adornment} position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                    onInput: (e) => {
                                      if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                        const newValue = e.target.value.replaceAll('-', '');
                                        e.target.value = newValue;
                                      }
                                    },
                                  }}
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <FormattedMessage
                                  id="label.average_fill_percentage"
                                  defaultMessage="Average fill percentage"
                                >
                                  {(txt) => <span className={classes.formLabel}>{txt}</span>}
                                </FormattedMessage>
                                <FormikTextField
                                  name="averageFillLevel"
                                  type="number"
                                  className={classes.formField}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment className={classes.adornment} position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                    onInput: (e) => {
                                      if (e.target.value.indexOf('-') !== -1 || !e.target.value) {
                                        const newValue = e.target.value.replaceAll('-', '');
                                        e.target.value = newValue;
                                      }
                                    },
                                  }}
                                />
                              </Grid>
                            </>
                          )}

                          <Grid
                            container
                            xs={12}
                            justify="flex-end"
                            spacing={2}
                            className="m-t-10 m-b-10"
                          >
                            {editingId && (
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.addCombinationButton}
                                type="button"
                                onClick={() => cancelEditing(resetForm)}
                              >
                                <FormattedMessage
                                  id="cost_settings.cancel_edit"
                                  defaultMessage="Cancel editing"
                                >
                                  {(txt) => <span className={classes.submitBtnText}>{txt}</span>}
                                </FormattedMessage>
                              </Button>
                            )}
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.addCombinationButton}
                              type="button"
                              onClick={submitForm}
                              disabled={!uniqueCombination}
                            >
                              {editingId ? (
                                <FormattedMessage
                                  id="cost_settings.edit_combination"
                                  defaultMessage="Edit combination"
                                >
                                  {(txt) => <span className={classes.submitBtnText}>{txt}</span>}
                                </FormattedMessage>
                              ) : (
                                <FormattedMessage
                                  id="cost_settings.add_combination"
                                  defaultMessage="Add combination"
                                >
                                  {(txt) => <span className={classes.submitBtnText}>{txt}</span>}
                                </FormattedMessage>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid xs={12} className={classNames(classes.divider, 'm-t-20')} />
            <Grid xs={12} className="m-t-10">
              <FormattedMessage id="overview_over_added" defaultMessage="Overview over added">
                {(txt) => <p className={classes.costSettingsSubtitle}>{txt}</p>}
              </FormattedMessage>
            </Grid>
            <Grid xs={12} className="p-r-20">
              <TableContainer>
                <Scrollbars autoHeight>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell />
                        <StyledTableCell>Combination</StyledTableCell>
                        <StyledTableCell align="right">Collection</StyledTableCell>
                        <StyledTableCell align="right">Overflowing</StyledTableCell>
                        <StyledTableCell align="right">Disposal cost</StyledTableCell>
                        <StyledTableCell align="right">Income</StyledTableCell>
                        <StyledTableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map((row, index) => {
                        const isSelected = isSelectedCombination(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell padding="checkbox">
                              <Checkbox
                                checked={isSelected}
                                onClick={(event) => handleSelectCombination(event, row.id)}
                                inputProps={{ 'aria-labelledby': labelId }}
                                size="small"
                              />
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.combination}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.collection.toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.overflowing.toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.costPerTonne.toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.incomePerTonne.toFixed(0)} {currencySymbol}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Grid container wrap="nowrap">
                                <Grid item>
                                  <Button
                                    className={classes.settingsTableButton}
                                    type="button"
                                    onClick={() => editCostSettings(row.id, setFieldValue)}
                                  >
                                    <img src={PenIcon} alt="pen" />
                                  </Button>
                                </Grid>

                                <Grid item>
                                  <Button
                                    className={classes.settingsTableButton}
                                    type="button"
                                    onClick={(e) => handleDeleteCostSettings(e, row.id, resetForm)}
                                  >
                                    <img src={TrashIcon} alt="trash" />
                                  </Button>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Scrollbars>
              </TableContainer>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

ContainerSettings.propTypes = {
  savedActiveProjects: PropTypes.string.isRequired,
  currencyCoef: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  weightToVolumeCoef: PropTypes.number.isRequired,
  weightSymbol: PropTypes.string.isRequired,
  businessAnalyticsRefetch: PropTypes.func.isRequired,
  selectedCombinations: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedCombinations: PropTypes.func.isRequired,
  volumeSymbol: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fractions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  containerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  allCostSettings: PropTypes.shape({
    allCostSettings: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  }),
};

ContainerSettings.defaultProps = {
  fractions: [],
  containerTypes: [],
  allCostSettings: {},
};

export default ContainerSettings;
