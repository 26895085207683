import React, { useState, useCallback, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Slider from '@material-ui/core/Slider';
import {
  AppBar,
  Typography,
  Grid,
  Paper,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { loader } from 'graphql.macro';
import { FormikSelect, FormikTextField, FormikCheckbox } from '../../../../../shared/inputs/formik';
import SelectProject from '../../../../../shared/apiPopulatedSelects/selectProjects';
import SelectContainerType from '../../../../../shared/apiPopulatedSelects/selectContainerType';
import SelectWasteFraction from '../../../../../shared/apiPopulatedSelects/selectWasteFraction';
import { toastifyError } from '../../../../../shared/utils';
import { actionHandler, SAVE_BUTTONS_VARIANTS } from '../../../../../shared/buttons/submitButtons';
import routeSettingsValidationSchema from './schema';

const updateOrCreateRouteSettingsQuery = loader(
  './../../../../../graphql/mutations/operation_management/update_or_create_route_settings.graphql',
);
const routeSettingsQuery = loader(
  './../../../../../graphql/queries/operation_management/route_settings.graphql',
);

const defaultInitialValues = {
  wasteSchemeName: '',
  selectedProject: '',
  selectedWasteFraction: '',
  selectedContainerType: '',
  emptyContainersAt: '',
  scheduleAhead: null,
  extraAware: false,
};

const mutationSerializer = (
  {
    wasteSchemeName,
    selectedProject,
    selectedWasteFraction,
    selectedContainerType,
    emptyContainersAt,
    scheduleAhead,
    extraAware,
  },
  selfId,
) => ({
  selfId,
  name: wasteSchemeName,
  projectId: selectedProject,
  containerTypes: selectedContainerType,
  emptyAt: emptyContainersAt,
  extraAware,
  scheduleAhead,
  wasteFractionId: selectedWasteFraction,
});

const routeSettingsSerializer = ({ routeSettings }) => ({
  ...defaultInitialValues,
  wasteSchemeName: routeSettings.name,
  selectedProject: routeSettings.project?.id || defaultInitialValues.selectedProject,
  selectedWasteFraction: routeSettings.wasteFraction.id,
  selectedContainerType: (
    routeSettings.containerType.edges || defaultInitialValues.selectedContainerType
  ).map(({ node }) => node.id),
  emptyContainersAt: routeSettings.emptyAt,
  scheduleAhead: routeSettings.scheduleAhead,
  extraAware: routeSettings.extraAware,
});

const useStyles = makeStyles((theme) => ({
  appbar: {
    paddingRight: 20,
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.15)',
    paddingLeft: 40,
    fontWeight: 'bold',
    background: theme.variables.cAntiFlashWhite,
    marginBottom: 20,
    paddingTop: 6,
    minHeight: 74,
  },
  pageTitle: {
    color: theme.variables.cTextDark,
    fontWeight: 'bold',
    fontSize: 26,
    marginLeft: 8,
    userSelect: 'none',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  form: {
    width: '100%',
    margin: 0,
    paddingBottom: 20,
    flexWrap: 'unset',
  },
  paper: {
    marginLeft: 15,
    marginTop: 48,
    marginBottom: 30,
    marginRight: 15,
    paddingBottom: 10,
  },
  gridContainer: {
    marginLeft: 50,
    marginRight: 50,
    marginBottom: 50,
    width: '85%',
  },
  sectionLabel: {
    fontWeight: 'bold',
    fontSize: 24,
    color: theme.variables.cEmperor,
    marginTop: 30,
  },
  startInputAdornment: {
    fontWeight: 'bold',
    fontSize: 32,
    marginLeft: 18,
    marginRight: 10,
    paddingBottom: 5,
  },
  endInputAdornment: {
    fontWeight: 'bold',
    fontSize: 32,
    marginLeft: 18,
    marginRight: 10,
  },
  label: {
    fontSize: 16,
    color: theme.variables.VHDarkGrey,
    fontWeight: 500,
  },
  btn: {
    width: '100%',
    fontSize: 17,
    textTransform: 'none',
    boxShadow: theme.variables.defaultBoxShadow,
    borderRadius: 32.5,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const SCHEDULE_AHEAD_VALUES = {
  twoWeeks: 14,
  threeWeeks: 21,
  oneMonth: 30,
  twoMonths: 60,
};

export const SCHEDULE_AHEAD = [
  {
    value: SCHEDULE_AHEAD_VALUES.twoWeeks,
    label: <FormattedMessage id="schedule_ahead.two_weeks" defaultMessage="2 weeks ahead" />,
  },
  {
    value: SCHEDULE_AHEAD_VALUES.threeWeeks,
    label: <FormattedMessage id="schedule_ahead.three_weeks" defaultMessage="3 weeks ahead" />,
  },
  {
    value: SCHEDULE_AHEAD_VALUES.oneMonth,
    label: <FormattedMessage id="schedule_ahead.one_month" defaultMessage="1 month ahead" />,
  },
  {
    value: SCHEDULE_AHEAD_VALUES.twoMonths,
    label: <FormattedMessage id="schedule_ahead.two_months" defaultMessage="2 months ahead" />,
  },
];

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];

const SliderBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const SliderClass = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
    width: '100%',
    marginTop: '20px',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: SliderBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: SliderBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

const RouteSettings = ({ match, history }) => {
  const { params: { id: routeSettingsId } = {} } = match;
  const classes = useStyles();
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const editPage = !!routeSettingsId;

  const [getRouteSettings, { data: routeSettings }] = useLazyQuery(routeSettingsQuery, {
    variables: { id: routeSettingsId },
    onError: toastifyError,
  });
  useEffect(() => {
    if (routeSettingsId) {
      getRouteSettings({ variables: { routeSettingsId } });
    }
  }, [getRouteSettings, routeSettingsId]);
  useEffect(() => {
    if (routeSettingsId && routeSettings) {
      try {
        setInitialValues(routeSettingsSerializer(routeSettings));
      } catch (e) {
        toastifyError(e);
      }
    }
  }, [routeSettings, routeSettingsId]);

  const [mutation] = useMutation(updateOrCreateRouteSettingsQuery);
  const onSubmit = useCallback(
    (values, { setSubmitting, setFieldError }) => {
      mutation({ variables: mutationSerializer(values, routeSettingsId) })
        .then(
          ({
            data: {
              updateOrCreateRouteSettings: { routeSettings: savedRouteSettings },
            },
          }) => {
            toast.success(`Saved changes for - ${savedRouteSettings.name}`);
            return { obj: savedRouteSettings, action: SAVE_BUTTONS_VARIANTS.save };
          },
        )
        .then(
          actionHandler({
            baseUrl: `/app/routes`,
            history,
            onEditPage: editPage,
            toDetail: true,
          }),
        )
        .catch((e) => {
          if (e.graphQLErrors && e.graphQLErrors.length) {
            e.graphQLErrors.forEach((graphQLError) => {
              if (graphQLError?.context?.field) {
                if (graphQLError.context.field === 'selectedWasteFraction') {
                  setFieldError(graphQLError.context.field, graphQLError.message);
                  setFieldError('selectedContainerType', ' ');
                }
              }
            });
          }
        })
        .finally(() => setSubmitting(false));
    },
    [editPage, mutation, routeSettingsId, history],
  );

  return (
    <>
      <AppBar position="static" className={classes.appbar}>
        <Typography className={classes.pageTitle}>
          <FormattedMessage id="set_route_settings" defaultMessage="Set route settings" />
        </Typography>
      </AppBar>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={routeSettingsValidationSchema}
        className={classes.form}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Paper variant="elevation" spacing={3} className={classes.paper}>
            <Form>
              <Grid container spacing={4} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionLabel}>
                    <FormattedMessage
                      id="route_settings.waste_scheme_settings"
                      defaultMessage="Waste scheme settings"
                    />
                  </Typography>
                </Grid>
                <Grid container item xs={12} spacing={7}>
                  <Grid item xs={6}>
                    <FormikTextField
                      id="wasteSchemeName"
                      name="wasteSchemeName"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'route_settings.placeholder.waste_scheme_name',
                        defaultMessage: 'eg. Southeast Boston',
                      })}
                      label={intl.formatMessage({
                        id: 'route_settings.label.waste_scheme_name',
                        defaultMessage: 'Name waste scheme',
                      })}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.sectionLabel}>
                    <FormattedMessage id="route_settings.general" defaultMessage="General" />
                  </Typography>
                </Grid>
                <Grid container item xs={12} spacing={7}>
                  <Grid item xs={6}>
                    <SelectProject xs={12} md={12} name="selectedProject" />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={7}>
                  <SelectWasteFraction xs={6} md={6} />
                  <SelectContainerType xs={6} md={6} />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.sectionLabel}>
                    <FormattedMessage
                      id="route_settings.route_specifications"
                      defaultMessage="Route specifications"
                    />
                  </Typography>
                </Grid>
                <Grid container item xs={12} spacing={7}>
                  <Grid item xs={6}>
                    <FormikTextField
                      id="emptyContainersAt"
                      name="emptyContainersAt"
                      type="number"
                      placeholder={intl.formatMessage({
                        id: 'route_settings.placeholder.empty_containers_at',
                        defaultMessage: 'eg. 70',
                      })}
                      label={intl.formatMessage({
                        id: 'route_settings.label.empty_containers_at',
                        defaultMessage: 'Empty containers at',
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography className={classes.startInputAdornment}>{'>'}</Typography>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <Typography className={classes.endInputAdornment}>%</Typography>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikSelect
                      name="scheduleAhead"
                      filledStyle
                      placeholder={intl.formatMessage({
                        id: 'route_settings.placeholder.schedule_ahead',
                        defaultMessage: 'eg. 2 weeks',
                      })}
                      label={intl.formatMessage({
                        id: 'route_settings.label.schedule_ahead',
                        defaultMessage: 'Schedule ahead',
                      })}
                      valuesList={SCHEDULE_AHEAD}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={7}>
                  <Grid item xs={4}>
                    <FormLabel>
                      <FormattedMessage id="label.economy" defaultMessage="Economy">
                        {(txt) => (
                          <>
                            <span className="m-t-20 m-b-20">{txt}</span>
                          </>
                        )}
                      </FormattedMessage>
                    </FormLabel>
                    <SliderClass
                      aria-label="economy"
                      defaultValue={50}
                      marks={marks}
                      valueLabelDisplay="on"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>
                      <FormattedMessage id="label.environmental" defaultMessage="Environmental">
                        {(txt) => (
                          <>
                            <span className="m-t-20 m-b-20">{txt}</span>
                          </>
                        )}
                      </FormattedMessage>
                    </FormLabel>
                    <SliderClass
                      aria-label="environmental"
                      defaultValue={50}
                      marks={marks}
                      valueLabelDisplay="on"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>
                      <FormattedMessage id="label.social" defaultMessage="Social">
                        {(txt) => (
                          <>
                            <span className="m-t-20 m-b-20">{txt}</span>
                          </>
                        )}
                      </FormattedMessage>
                    </FormLabel>
                    <SliderClass
                      aria-label="social"
                      defaultValue={50}
                      marks={marks}
                      valueLabelDisplay="on"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={<FormikCheckbox name="extraAware" />}
                      label={intl.formatMessage({
                        id: 'route_settings.label.aware_overflowing',
                        defaultMessage: 'Be extra aware of overflowing bins',
                      })}
                    />
                  </FormGroup>
                </Grid>
                <Grid container item xs={12} justify="center">
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btn}
                      disabled={isSubmitting}
                      type="submit"
                    >
                      <FormattedMessage
                        id="route_settings.create_route"
                        defaultMessage="Create route plan"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        )}
      </Formik>
    </>
  );
};

RouteSettings.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(RouteSettings);
