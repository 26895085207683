import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import Carousel from 'react-material-ui-carousel';

import Logo from '../../images/logo.svg';
import LoadingLayout from '../shared/loading';
import Background from '../../images/main-banner.jpg';
import StyledImgSelect from '../shared/inputs/StyledImgSelect';
import { AVAILABLE_LOCALES, TranslationContext } from '../../translations';
import { isLocalhost } from '../../serviceWorker';

const companySettingsQuery = loader('./../graphql/queries/core/company_settings.graphql');

const useStyles = makeStyles((theme) => ({
  localeSelect: {
    flex: 1,
    paddingTop: 15,
    maxHeight: 70,
  },
  coloredHeader: {
    backgroundColor: theme.variables.VHBlue,
  },
  radioGroup: {
    flexDirection: 'row',
  },
  contentRoot: {
    height: '100vh',
    overflowY: 'visible',
  },
  content: {
    flex: 1,
    minHeight: 'calc(100vh - 100px)',
  },
  input: {
    marginRight: 45,
    backgroundColor: 'inherit',
    '& .MuiSelect-icon': {
      color: 'inherit',
    },
    '& .MuiInput-root': {
      color: 'inherit',
      backgroundColor: 'inherit',
    },
  },
  defaultInput: {
    color: theme.variables.cRichOcean,
  },
  whiteInput: {
    color: theme.variables.cWhite,
  },
  background: {
    backgroundPosition: 'right',
    backgroundSize: 'cover',
  },
  backgroundImage: {
    backgroundImage: `url('${Background}')`,
  },
  hide: {
    display: 'none',
  },
  logo: {
    position: 'absolute',
    zIndex: 2,
    top: '3%',
    left: '6%',
    maxHeight: 90,
  },
  smallLogo: {
    minHeight: 38,
    height: 38,
  },
  bigLogo: {
    minHeight: 60,
  },
  whiteBg: {
    backGround: 'white',
  },
  carousel: {
    height: '100%',
    width: '100%',
    '& image': {
      height: '100%',
      width: '100%',
    },
  },
  carouselImage: {
    height: '100vh',
  },
  carouselIndicators: {
    position: 'absolute',
    bottom: '30px',
  },
  carouselActiveIndicators: {},
}));

const NotLoginDefaultPageLayout = ({ loading, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { locale, switchLocale } = useContext(TranslationContext);

  const subDomain = window.location.host.split('.')[0];

  const [loadCompanySettings, { loading: settingsLoading, data: companySettings }] = useLazyQuery(
    companySettingsQuery,
    {
      variables: {
        dns: subDomain,
      },
      errorPolicy: 'ignore',
    },
  );

  useEffect(() => {
    if (
      !isLocalhost &&
      !['dev', 'dev-platform', 'platform', 'stage', 'stage-platform', 'wastehero'].includes(
        subDomain,
      )
    ) {
      loadCompanySettings();
    }
  }, [loadCompanySettings, companySettings, subDomain]);

  const isLoading = loading || settingsLoading;

  return (
    <Grid container spacing={0} className={classNames('h-100')} tourid="wholePage">
      <LoadingLayout isLoading={isLoading} />
      <Grid
        item
        xs={false}
        sm={6}
        className={classNames('h-100', 'position-relative', {
          [classes.background]: !isSmallScreen,
          [classes.backgroundImage]: !companySettings?.companySettings?.loginImages.length,
          [classes.hide]: isSmallScreen,
        })}
      >
        {companySettings?.companySettings?.loginImages.length ? (
          <>
            <Carousel
              indicators
              navButtonsAlwaysVisible
              timeout={200}
              className={classes.carousel}
              indicatorContainerProps={{
                className: classes.carouselIndicators,
              }}
            >
              {companySettings.companySettings.loginImages.map((url) => (
                <img src={url} alt="Login" className={classes.carouselImage} />
              ))}
            </Carousel>
            {companySettings.companySettings.companyLogo ? (
              <img
                src={companySettings.companySettings.companyLogo}
                className={classNames([classes.logo, classes.bigLogo])}
                alt="Logo"
              />
            ) : (
              <img src={Logo} className={classNames([classes.logo, classes.bigLogo])} alt="Logo" />
            )}
          </>
        ) : (
          <img src={Logo} className={classNames([classes.logo, classes.bigLogo])} alt="Logo" />
        )}
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        container
        direction="column"
        className={classNames([classes.contentRoot])}
      >
        <img
          src={Logo}
          className={classNames([
            classes.logo,
            classes.smallLogo,
            {
              [classes.hide]: !isSmallScreen,
            },
          ])}
          alt="Logo"
        />
        <Grid
          item
          xs={12}
          container
          justify="flex-end"
          className={classNames([
            classes.localeSelect,
            {
              [classes.coloredHeader]: isSmallScreen,
            },
          ])}
        >
          <StyledImgSelect
            value={locale}
            valuesList={AVAILABLE_LOCALES}
            onChange={(e) => switchLocale(e.target.value)}
            bkgdStyle={classes.whiteBg}
            inputClass={classNames(classes.input, {
              [classes.defaultInput]: !isSmallScreen,
              [classes.whiteInput]: isSmallScreen,
            })}
          />
        </Grid>
        <Grid item xs={12} container justify="center" className={classes.content}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

NotLoginDefaultPageLayout.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default NotLoginDefaultPageLayout;
