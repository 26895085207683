import React, { useCallback } from 'react';
import { loader } from 'graphql.macro';
import FormikAsyncSelect from '@wastehero/storybook/lib/components/Form/Elements/Select/Async/Formik';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { getSavedActiveProjects } from '../../../../shared/utils';

const query = loader('./../../../../graphql/queries/wastacollector/all_waste_fractions.graphql');

const WasteFractionSelect = ({ multiple, required, label, name, requireProject }) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const { values } = useFormikContext();

  const queryResultFormatter = (vs) =>
    (vs?.allWasteFractions?.edges || []).map(({ node }) => ({
      key: node.id,
      label: `${node.wasteType[0]} - ${node.wasteCategory}`,
      value: node.id,
    }));

  const queryVariablesFormatter = useCallback(
    (search, queryVariables) => ({
      ...queryVariables,
    }),
    [],
  );

  let queryVariables = {
    activeProjects: savedActiveProjects,
  };
  let disabled = false;

  if (requireProject) {
    disabled = true;

    if (values && values.project) {
      disabled = false;

      queryVariables = {
        ...queryVariables,
        projectId: values.project.value,
      };
    }
  }

  // const onSelect = useCallback((value, option) => {
  //   console.log('onSelect', value, option);
  // }, []);

  const antd = {
    label,
    labelCol: { span: 24, offset: 0 },
    required,
  };

  return (
    <FormikAsyncSelect
      mode={multiple ? 'multiple' : undefined}
      name={name}
      query={query}
      queryResultFormatter={queryResultFormatter}
      queryVariablesFormatter={queryVariablesFormatter}
      queryVariables={queryVariables}
      // onSelect={onSelect}
      antd={antd}
      disabled={disabled}
    />
  );
};

WasteFractionSelect.propTypes = {
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  requireProject: PropTypes.bool,
};

WasteFractionSelect.defaultProps = {
  multiple: true,
  required: false,
  label: 'Waste Fraction',
  name: 'wasteFraction',
  requireProject: false,
};

export default WasteFractionSelect;
