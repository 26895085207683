import React, { useCallback } from 'react';
import { loader } from 'graphql.macro';
import FormikAsyncSelect from '@wastehero/storybook/lib/components/Form/Elements/Select/Async/Formik';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { getSavedActiveCompanies, getSavedActiveProjects } from '../../../../shared/utils';

const query = loader('./../../../../graphql/queries/fleet_management/all_depots.graphql');

const DepotSelect = ({ required, multiple, depotType, label, name, requireWasteFraction }) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const savedActiveCompanies = useSelector((state) => getSavedActiveCompanies(state));
  const { values } = useFormikContext();

  const queryResultFormatter = (vs) => {
    let res = vs?.allDepots?.edges || [];

    if (requireWasteFraction) {
      res = res?.filter((r) =>
        r.node.wasteFractions.edges
          .map((wasteFraction) => wasteFraction.node.id)
          .includes(values?.wasteFraction?.value),
      );
    }

    return res.map(({ node }) => ({
      key: node.id,
      label: node.name,
      value: node.id,
    }));
  };

  const queryVariablesFormatter = useCallback(
    (search, queryVariables) => ({
      ...queryVariables,
    }),
    [],
  );

  let queryVariables = {
    activeProjects: savedActiveProjects,
    activeCompanies: savedActiveCompanies,
  };
  let disabled = false;

  if (requireWasteFraction) {
    disabled = true;

    if (values && values.wasteFraction) {
      disabled = false;
    }
  }

  if (depotType) {
    queryVariables = {
      ...queryVariables,
      type: depotType,
    };
  }

  // const onSelect = useCallback((value, option) => {
  //   console.log('onSelect', value, option);
  // }, []);

  const antd = {
    label,
    labelCol: { span: 24, offset: 0 },
    required,
  };

  return (
    <FormikAsyncSelect
      mode={multiple ? 'multiple' : undefined}
      name={name}
      query={query}
      queryResultFormatter={queryResultFormatter}
      queryVariablesFormatter={queryVariablesFormatter}
      queryVariables={queryVariables}
      // onSelect={onSelect}
      antd={antd}
      disabled={disabled}
    />
  );
};

DepotSelect.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string,
  depotType: PropTypes.string, // depot, waste_station
  name: PropTypes.string,
  required: PropTypes.bool,
  requireWasteFraction: PropTypes.bool,
};

DepotSelect.defaultProps = {
  multiple: true,
  required: false,
  label: 'Depot',
  name: 'depot',
  depotType: 'depot',
  requireWasteFraction: false,
};

export default DepotSelect;
