import React from 'react';
import { useSelector } from 'react-redux';
import { loader } from 'graphql.macro';

import CreateRouteView from '@wastehero/storybook/lib/components/Views/Route/Create';
import ProjectSelect from '../../../../../design-system/form/select/project';
import WasteFractionSelect from '../../../../../design-system/form/select/waste-fraction';
import ContainerTypeSelect from '../../../../../design-system/form/select/container-type';
import { getSavedActiveProjects } from '../../../../../shared/utils';
import DepotSelect from '../../../../../design-system/form/select/depot';
import DriverSelect from '../../../../../design-system/form/select/driver';
import VehicleSelect from '../../../../../design-system/form/select/vehicle';
import { getMapCenter } from '../../../../../shared/utils/settings';

// eslint-disable-next-line no-unused-vars
const containersQuery = loader(
  './../../../../../graphql/queries/containers/containers_autocomplete.graphql',
);

const inquiriesQuery = loader(
  './../../../../../graphql/queries/operation_management/all_inquiries.graphql',
);

const settingsMutation = loader(
  './../../../../../graphql/mutations/operation_management/report_inquiry.graphql',
);

const preRouteMutation = loader(
  './../../../../../graphql/mutations/operation_management/pre_route.graphql',
);

const inquiryDrawerQuery = loader(
  './../../../../../graphql/queries/operation_management/inquiry.graphql',
);

const containerDrawerQuery = loader(
  './../../../../../graphql/queries/devices/one_container.graphql',
);

const CreateRoute = () => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const mapCenter = useSelector((state) => getMapCenter(state));

  const breadcrumbBar = {
    title: 'Create Route',
    breadcrumbsLinks: [
      {
        label: 'Operation Management',
        path: '/route1',
      },
      {
        label: 'Routes',
        path: '/route2',
      },
    ],
  };

  const containerDrawerQueryVariablesFormatter = (containerQVS) => ({ ...containerQVS });

  const containerDrawerQueryVariables = {};

  const inquiryDrawerQueryVariablesFormatter = (inquiryQVS) => ({ ...inquiryQVS });

  const inquiryQueryVariables = {};

  return (
    <CreateRouteView
      mapBoxProps={{
        center: {
          longitude: mapCenter.lng,
          latitude: mapCenter.lat,
        },
      }}
      breadcrumbBar={breadcrumbBar}
      activeProjects={savedActiveProjects}
      containersQuery={containersQuery}
      inquiriesQuery={inquiriesQuery}
      settingsMutation={settingsMutation}
      preRouteMutation={preRouteMutation}
      containerDrawerQueryVariablesFormatter={containerDrawerQueryVariablesFormatter}
      containerDrawerQueryVariables={containerDrawerQueryVariables}
      containerDrawerQuery={containerDrawerQuery}
      inquiryDrawerQueryVariablesFormatter={inquiryDrawerQueryVariablesFormatter}
      inquiryDrawerQueryVariables={inquiryQueryVariables}
      inquiryDrawerQuery={inquiryDrawerQuery}
      projectSelect={<ProjectSelect multiple={false} required />}
      wasteFractionSelect={<WasteFractionSelect multiple={false} requireProject required />}
      containerTypeSelect={<ContainerTypeSelect multiple={false} requireProject required />}
      depotStartSelect={
        <DepotSelect
          multiple={false}
          name="startDepot"
          label="Start Depot"
          depotType="depot"
          required
        />
      }
      depotEndSelect={
        <DepotSelect
          multiple={false}
          name="endDepot"
          label="End depot"
          depotType="depot"
          required
        />
      }
      wasteStationSelect={
        <DepotSelect
          multiple={false}
          name="wasteStation"
          label="Waste Station"
          depotType="waste_station"
          required
          requireWasteFraction
        />
      }
      vehicleSelect={
        <VehicleSelect
          multiple={false}
          name="vehicle"
          label="Vehicle"
          required
          requireWasteFraction
        />
      }
      driverSelect={
        <DriverSelect multiple={false} name="driver" label="Driver" required requireVehicle />
      }
    />
  );
};

CreateRoute.propTypes = {};
export default CreateRoute;
