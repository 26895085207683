import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { toast } from 'react-toastify';
import { loader } from 'graphql.macro';
import { toastifyError, saveCacheRead } from '../../../../../shared/utils';
import HeaderRow from '../../components/headerRow';
import DepotEditFrom from './form';
import depotEditValidationSchema from './schema';
import { DEPOTS_TYPE_VALUES, serializeTime } from '../../components/utils';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import { actionHandler } from '../../../../../shared/buttons/submitButtons';

const depotQuery = loader('./../../../../../graphql/queries/fleet_management/depot.graphql');
const updateOrCreateDepotMutation = loader(
  './../../../../../graphql/mutations/fleet_management/update_or_create_depot.graphql',
);
const allDepotsQuery = loader(
  './../../../../../graphql/queries/fleet_management/all_depots.graphql',
);

const mutationSerializer = (
  {
    name,
    description,
    longitude,
    latitude,
    placeId,
    depotType,
    geofenceRadius,
    amountOfTrucks,
    workshopType,
    workShopServiceDescription,
    closingTime,
    openingTime,
    timeToUnloadTruck,
    timeToLeaveTheFacility,
    contactPerson,
    wasteFractions,
    companyId,
  },
  photoFile,
  selfId,
) => ({
  name,
  description,
  longitude,
  latitude,
  placeId,
  depotType,
  companyId,
  geofenceRadius: geofenceRadius !== '' ? geofenceRadius : null,
  logo: photoFile,
  selfId,
  amountOfTrucks: amountOfTrucks !== '' ? amountOfTrucks : null,
  workshopType,
  workShopServiceDescription,
  closingTime: closingTime ? closingTime.format('HH:mm:ss') : null,
  openingTime: openingTime ? openingTime.format('HH:mm:ss') : null,
  timeToUnloadTruck: timeToUnloadTruck ? timeToUnloadTruck.format('HH:mm:ss') : null,
  timeToLeaveTheFacility: timeToLeaveTheFacility ? timeToLeaveTheFacility.format('HH:mm:ss') : null,
  contactPerson,
  wasteFractions: wasteFractions.filter((a) => a).map((b) => b.wasteFraction),
});

const initialDepotValues = {
  name: '',
  depotType: DEPOTS_TYPE_VALUES.depot,
  geofenceRadius: '',
  description: '',
  logo: '',
  contactPerson: {
    name: '',
    email: '',
    phone: '',
  },
  openingTime: null,
  closingTime: null,
  amountOfTrucks: '',
  timeToUnloadTruck: null,
  timeToLeaveTheFacility: null,
  workshopType: '',
  workShopServiceDescription: '',
  wasteFractions: [],
  latitude: undefined,
  longitude: undefined,
  action: '',
  address: '',
  companyId: '',
  placeId: '',
};

const serializer = (depot) => ({
  ...initialDepotValues,
  name: depot.name || initialDepotValues.name,
  description: depot.description || initialDepotValues.description,
  depotType: depot.depotType || initialDepotValues.depotType,
  geofenceRadius: depot.geofenceRadius || initialDepotValues.geofenceRadius,
  logo: depot.logo || initialDepotValues.logo,
  longitude: depot?.location.longitude,
  latitude: depot?.location.latitude,
  contactPerson: {
    name: depot?.contactPerson?.name || initialDepotValues.contactPerson.name,
    email: depot?.contactPerson?.email || initialDepotValues.contactPerson.email,
    phone: depot?.contactPerson?.phone || initialDepotValues.contactPerson.phone,
  },
  openingTime: serializeTime(depot.openingTime, initialDepotValues.openingTime),
  closingTime: serializeTime(depot.closingTime, initialDepotValues.closingTime),
  timeToUnloadTruck: serializeTime(depot.timeToUnloadTruck, initialDepotValues.timeToUnloadTruck),
  timeToLeaveTheFacility: serializeTime(
    depot.timeToLeaveTheFacility,
    initialDepotValues.timeToLeaveTheFacility,
  ),
  workshopType: depot.workshopType || initialDepotValues.workshopType,
  workShopServiceDescription:
    depot.workshopServiceDescription || initialDepotValues.workShopServiceDescription,
  amountOfTrucks: depot.amountOfTrucks || initialDepotValues.amountOfTrucks,
  wasteFractions: (
    depot.wasteFractions?.edges || initialDepotValues.wasteFractions
  ).map(({ node }) => ({ wasteFraction: node.id })),
  companyId: depot.company?.id || initialDepotValues.companyId,
  placeId: depot.location.placeId,
});

const LocationEdit = ({ match, history, me, activeCompanies }) => {
  const { params: { id: depotId } = {} } = match;

  const location = useLocation();
  const parsedQueryString = useMemo(() => queryString.parse(location.search), [location.search]);

  const intl = useIntl();

  const editPage = !!depotId;
  const [initialDepotData, setInitialDepotData] = useState(initialDepotValues);
  const [photoFile, setPhotoFile] = useState();
  const [updateOrCreateDepot, { loading: waitForMutation }] = useMutation(
    updateOrCreateDepotMutation,
  );

  const [getDepot, { loading: depotsLoading, data: { depot = {} } = {} }] = useLazyQuery(
    depotQuery,
    {
      onError: toastifyError,
      variables: { id: depotId },
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (parsedQueryString) {
      setInitialDepotData({
        ...initialDepotValues,
        depotType: parsedQueryString.locationType || DEPOTS_TYPE_VALUES.depot,
      });
    }
  }, [parsedQueryString]);

  useEffect(() => {
    if (depotId && Reflect.ownKeys(depot).length) {
      setInitialDepotData(serializer(depot));
    }
  }, [depot, depotId]);

  useEffect(() => {
    if (depotId) {
      getDepot({ variables: { id: depotId } });
    }
  }, [depotId, getDepot]);

  const editPageName = intl.formatMessage(
    {
      id: 'locations.edit_page_title',
      defaultMessage: 'Edit location / {name}',
    },
    { name: depot.name },
  );
  const addPageName = intl.formatMessage({
    id: 'locations.add_page_title',
    defaultMessage: 'Add Location',
  });

  const companiesToSelect = useMemo(() => {
    if (!me.isSuperuser) {
      return [];
    }
    const companies = [];

    me.activeProjects.edges.forEach(({ node }) => {
      if (!companies.find(({ value }) => value === node.company.id)) {
        companies.push({
          value: node.company.id,
          label: node.company.name,
        });
      }
    });

    if (Object.keys(depot).length && !companies.find(({ value }) => value === depot.company.id)) {
      companies.push({
        value: depot.company.id,
        label: depot.company.name,
      });
    }
    return companies;
  }, [me, depot]);

  const editDepotHandler = useCallback(
    (values, { setSubmitting, setFieldError, resetForm }) => {
      const variables = mutationSerializer(values, photoFile, depotId);
      if (me.isSuperuser && !variables.company) {
        setFieldError('companyId', 'Field is required');
      }

      return updateOrCreateDepot({
        variables,
        update: async (cache, { data: { updateOrCreateDepot: newDepot } }) => {
          const {
            data: { allDepots: allDepotsData },
          } = await saveCacheRead({
            query: allDepotsQuery,
            variables: { type: variables.depotType, activeCompanies },
          });

          const newDepots = { ...allDepotsData };

          newDepots.edges = [
            ...newDepots.edges,
            {
              node: newDepot.depot,
              __typename: 'DepotTypeEdge',
            },
          ];

          cache.writeQuery({
            query: allDepotsQuery,
            variables: { type: variables.depotType, activeCompanies },
            data: { allDepots: newDepots },
          });
        },
      })
        .then(({ data: { updateOrCreateDepot: { depot: nextDepot } = {} } = {} }) => {
          toast.success(`Saved depot - ${nextDepot.name}`);
          return {
            action: values.action,
            obj: nextDepot,
          };
        })
        .then(
          actionHandler({
            editPage,
            reInitForm: (obj) => setInitialDepotData(serializer(obj)),
            resetForm: () => {
              resetForm();
              setInitialDepotData(initialDepotValues);
            },
            baseUrl: '/app/fleet-management/locations',
            history,
            onEditPage: editPage,
            toDetail: true,
          }),
        )
        .catch((e) => {
          if (e.graphQLErrors && e.graphQLErrors.length) {
            e.graphQLErrors.forEach((graphQLError) => {
              if (graphQLError?.context?.field) {
                if (graphQLError.context.field === 'address') {
                  setFieldError('address', graphQLError.message);
                  setFieldError('latitude', graphQLError.message);
                  setFieldError('longitude', graphQLError.message);
                } else {
                  setFieldError(graphQLError.context.field, graphQLError.message);
                }
              }
            });
          }
          setSubmitting(false);
        })
        .finally(() => setSubmitting(false));
    },
    [photoFile, depotId, updateOrCreateDepot, editPage, history, me.isSuperuser, activeCompanies],
  );

  return (
    <Grid container>
      <HeaderRow pageTitle={editPage ? editPageName : addPageName} />
      <Formik
        enableReinitialize
        initialValues={initialDepotData}
        validationSchema={depotEditValidationSchema()}
        onSubmit={editDepotHandler}
      >
        <Grid container className="p-15">
          <FixedLoadingLayout open={depotsLoading || waitForMutation} />
          <DepotEditFrom
            companiesToSelect={companiesToSelect}
            isSuperuser={me.isSuperuser}
            setPhotoFile={setPhotoFile}
          />
        </Grid>
      </Formik>
    </Grid>
  );
};

LocationEdit.propTypes = {
  me: PropTypes.shape({
    isSuperuser: PropTypes.bool,
    activeProjects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            company: PropTypes.shape({
              id: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }).isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  activeCompanies: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
  activeCompanies: Array.from(
    new Set(state.settings.user.activeProjects.edges.map(({ node }) => node.company.id)),
  ),
});

export default connect(mapStateToProps)(withRouter(LocationEdit));
