import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation } from '@apollo/client';
import ReactRouterPropTypes from 'react-router-prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { loader } from 'graphql.macro';
import { Scrollbars } from 'react-custom-scrollbars';
import { FormikTextField, FormikPhoneField } from '../../../../../shared/inputs/formik';
import UserIcon from '../../../../../../images/icons/user.svg';
import EmailIcon from '../../../../../../images/icons/email.svg';
import PasswordIcon from '../../../../../../images/icons/password.svg';
import LoadingLayout from '../../../../../shared/loading';
import AddUserIcon from '../../components/addUserIcon';
import { setUser as setUserAction } from '../../../../../../actions';
import { toastifyError } from '../../../../../shared/utils';
import getMe from '../../../settings/project/helpers';
import { TutorialContext } from '../../../../../../tutorial';
import NotLoginDefaultPageLayout from '../../../../../layout/notLoginDefaultPageLayout';

const getUserQuery = loader('./../../../../../graphql/queries/core/user.graphql');
const createUserQuery = loader('./../../../../../graphql/mutations/core/create_user.graphql');
const loginMutationQuery = loader('./../../../../../graphql/mutations/core/login.graphql');
const logoutMutationQuery = loader('./../../../../../graphql/mutations/core/logout.graphql');

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(4),
    '& > div': {
      marginTop: theme.spacing(1.5),
    },
  },
  textFieldIcon: {
    marginLeft: theme.spacing(1),
    opacity: 0.2,
  },
}));

const baseUserInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  username: '',
  password: '',
  repeatPassword: '',
};

const userSerializer = (user) => ({
  ...baseUserInfo,
  userId: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  phoneNumber: user.phoneNumber,
  username: user.username,
});

const validationSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string()
      .email(<FormattedMessage id="validation.email.invalid" defaultMessage="Invalid email" />)
      .required(),
    phoneNumber: Yup.string()
      .transform((phoneNumberString) => phoneNumberString.replace(/\s|\(|\)/g, ''))
      .test(
        'len',
        <FormattedMessage
          id="validation.number.invalid"
          defaultMessage="Please enter valid number."
        />,
        (val) => {
          if (!val || val === '+') {
            return true;
          }
          return /^\+[1-9]{1}[0-9]{3,14}$/.test(val);
        },
      ),
    username: Yup.string().required(),
    password: Yup.string()
      .required()
      .min(
        8,
        <FormattedMessage
          id="password.validation.to_short"
          defaultMessage="Password is too short - should be 8 chars minimum."
        />,
      ),
    repeatPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        <FormattedMessage id="password.validation.match" defaultMessage="Passwords must match" />,
      )
      .required(),
  });

const RegistrationByInvite = ({ history, match, handleSetUser, me }) => {
  const classes = useStyles();
  const intl = useIntl();
  const client = useApolloClient();
  const { startTutorial } = useContext(TutorialContext);

  const {
    params: { id, email },
  } = match;

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(baseUserInfo);
  const [userImage, setUserImage] = useState(null);
  const [userImageObj, setUserImageObj] = useState(null);

  const [createUserMutation] = useMutation(createUserQuery);
  const [loginMutation] = useMutation(loginMutationQuery);
  const [logoutMutation] = useMutation(logoutMutationQuery);

  const tryRegister = (values, { setSubmitting }) => {
    const variables = {
      ...values,
      logo: userImageObj,
      phoneNumber: values.phoneNumber.replace(/\s|\(|\)/g, ''),
    };

    createUserMutation({ variables })
      .then(
        async ({
          data: {
            createUser: { user: userData },
          },
        }) => {
          setUser(userSerializer(userData));
          const {
            data: { tokenAuth },
          } = await loginMutation({
            variables: {
              username: userData.username,
              password: variables.password,
            },
          });

          window.localStorage.setItem('reactQLJWT', tokenAuth.token);
          window.localStorage.setItem('rememberMe', '');

          await getMe({ client, handleSetUser });

          toast.info(
            intl.formatMessage({
              id: 'user.created',
              defaultMessage: 'User successfully created',
            }),
          );

          const pathname =
            userData.isMaster && !userData.company ? '/company/create/' : '/app/dashboard';

          if (userData.isDemo) {
            startTutorial('demoAccount');
          }

          history.push({
            pathname,
          });
        },
      )
      .catch((err) => {
        toastifyError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const errorToast = useCallback(() => {
    toast.error(
      intl.formatMessage({
        id: 'registration.broken_invitation_url',
        defaultMessage: 'Broken invitation url',
      }),
    );
    history.push('/login');
  }, [history, intl]);

  useEffect(() => {
    let isCancelled = false;

    if (user.userId) {
      return () => {};
    }
    setLoading(true);

    logoutMutation()
      .then(() => {
        if (isCancelled) {
          return Promise.resolve();
        }
        window.localStorage.removeItem('reactQLJWT');
        return client.resetStore();
      })
      .then(() => {
        if (isCancelled) {
          return Promise.resolve();
        }
        return client.query({ query: getUserQuery, variables: { id: id || me.id } });
      })
      .then(({ data: { user: userData } }) => {
        if (isCancelled) {
          return;
        }
        if (userData.email === email && !userData.isActive) {
          setUser(userSerializer(userData));
          return;
        }
        errorToast();
      })
      .catch((e) => {
        toastifyError(e);
        errorToast();
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setLoading(false);
      });

    return () => {
      setLoading(false);
      isCancelled = true;
    };
  }, [id, email, errorToast, me, logoutMutation, client, user.userId]);

  return (
    <NotLoginDefaultPageLayout loading={loading}>
      <Formik
        enableReinitialize
        initialValues={user}
        onSubmit={tryRegister}
        validationSchema={validationSchema()}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className="w-100">
            <Scrollbars>
              <Grid
                container
                direction="column"
                alignContent="center"
                className={classes.formContainer}
              >
                <LoadingLayout isLoading={isSubmitting} />
                <Grid item xs={12} sm={8} lg={6} container justify="center">
                  <AddUserIcon
                    isCreateMode
                    imageSelectionCallback={setUserImage}
                    imageSelectionDataCallback={setUserImageObj}
                    imageUrl={userImage}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6}>
                  <FormikTextField
                    name="firstName"
                    autoComplete="none"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img className={classes.textFieldIcon} src={UserIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    label={intl.formatMessage({
                      id: 'label.first_name',
                      defaultMessage: 'First name',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6}>
                  <FormikTextField
                    name="lastName"
                    autoComplete="none"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img className={classes.textFieldIcon} src={UserIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    label={intl.formatMessage({
                      id: 'label.lastName',
                      defaultMessage: 'Last name',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6}>
                  <FormikPhoneField
                    name="phoneNumber"
                    preferredCountries={['dk', 'gr', 'gb']}
                    defaultCountry="dk"
                    regions="europe"
                    label={intl.formatMessage({
                      id: 'label.mobile_number',
                      defaultMessage: 'Mobile number',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6}>
                  <FormikTextField
                    name="username"
                    autoComplete="email"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img className={classes.textFieldIcon} src={EmailIcon} alt="EmailIcon" />
                        </InputAdornment>
                      ),
                    }}
                    label={intl.formatMessage({ id: 'label.username', defaultMessage: 'Username' })}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6}>
                  <FormikTextField
                    name="email"
                    autoComplete="off"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img className={classes.textFieldIcon} src={EmailIcon} alt="EmailIcon" />
                        </InputAdornment>
                      ),
                    }}
                    label={intl.formatMessage({ id: 'Email', defaultMessage: 'Email' })}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6} required>
                  <FormikTextField
                    name="password"
                    type="password"
                    required
                    autoComplete="new-password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className={classes.textFieldIcon}
                            src={PasswordIcon}
                            alt="PasswordIcon"
                          />
                        </InputAdornment>
                      ),
                    }}
                    label={intl.formatMessage({
                      id: 'registration.label.password',
                      defaultMessage: 'Password',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6} required>
                  <FormikTextField
                    name="repeatPassword"
                    type="password"
                    required
                    autoComplete="new-password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className={classes.textFieldIcon}
                            src={PasswordIcon}
                            alt="PasswordIcon"
                          />
                        </InputAdornment>
                      ),
                    }}
                    label={intl.formatMessage({
                      id: 'label.confirm_password',
                      defaultMessage: 'Confirm password',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={6}>
                  <Button
                    fullWidth
                    className="m-t-20"
                    color="primary"
                    variant="contained"
                    onClick={submitForm}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </Button>
                </Grid>
              </Grid>
            </Scrollbars>
          </Form>
        )}
      </Formik>
    </NotLoginDefaultPageLayout>
  );
};

RegistrationByInvite.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  handleSetUser: PropTypes.func.isRequired,
  me: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

RegistrationByInvite.defaultProps = {
  me: {},
};

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUserAction(user));
  },
});

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationByInvite));
