import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import CustomSelect from '../inputs/CustomSelect';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '&>div': {
      background: 'inherit',
      color: 'inherit',
      border: 'none',
    },
    '& svg': {
      background: 'inherit',
      color: 'inherit',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '200px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const SelectActiveProjects = ({ companies, activeProjects, saveActiveProjects }) => {
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();
  const intl = useIntl();
  const options = useMemo(
    () =>
      companies.reduce((acc, { node: company }) => {
        acc.push(
          ...company.projectSet.edges.map(({ node: project }) => ({
            key: project.id,
            value: project.name,
            project: {
              ...project,
              company: {
                id: company.id,
                dashboardLocation: company.dashboardLocation,
              },
            },
          })),
        );
        return acc;
      }, []),
    [companies],
  );

  const handleChangeInputValue = (event, value) => {
    if (event && event.type !== 'click') {
      setInputValue(value);
    }
  };

  return (
    <div className={classes.container} tourid="selectActiveProjects">
      <CustomSelect
        pickUp
        value={activeProjects.map((el) => ({ key: el.id, value: el.name, project: el }))}
        placeholder={intl.formatMessage({
          id: 'select_projects',
          defaultMessage: 'Select active projects',
        })}
        noOptionsText={intl.formatMessage({ id: 'no_projects', defaultMessage: 'No Projects' })}
        setValue={(selectedProjects) => {
          saveActiveProjects(selectedProjects.map((el) => el.project));
        }}
        options={options}
        extraClasses={[classes.formControl]}
        render={(item) =>
          item.map((el) => <Chip key={el.key} className={classes.chip} label={el.value} />)
        }
        inputValue={inputValue}
        onInputChange={handleChangeInputValue}
        inputProps={{
          endAdornment: (
            <>
              {inputValue && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setInputValue('')} size="small">
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            </>
          ),
        }}
      />
    </div>
  );
};

SelectActiveProjects.propTypes = {
  activeProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  saveActiveProjects: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        projectSet: PropTypes.shape({
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
              }),
            }),
          ),
        }),
      }),
    }),
  ).isRequired,
};

export default SelectActiveProjects;
