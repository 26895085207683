import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Button } from '@material-ui/core';
import ArrowLeft from '../../../images/icons/arrowLeft.svg';
import ArrowLeftGrey from '../../../images/icons/arrowLeftGrey.svg';

const useStyles = makeStyles(() => ({
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  carouselRoot: {
    '& > .carousel-root': {
      maxHeight: 670,
      '& > .carousel-slider': {
        maxHeight: 500,
        '& > .slider-wrapper': {
          maxWidth: 600,
          '& > .animated': {
            '& > .slide': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      '& > .carousel': {
        '& > .thumbs-wrapper': {
          '& > .thumbs': {
            '& > .thumb': {
              width: '108px !important',
              height: 108,
              paddingTop: 8,
              paddingBottom: 8,
              border: '1px solid #F0F2F7',
              borderRadius: 8,
              padding: 0,
              marginRight: 8,
              '& img': {
                height: '100%',
              },
            },
            '& > .selected': {
              border: '1px solid #DF9838',
            },
          },
        },
      },
    },
  },
  prevArrow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
  },
  nextArrow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
  },
  arrowIcon: {
    width: 24,
    height: 24,
  },
  singleImage: {
    width: '100%',
    height: '88%',
    objectFit: 'contain',
  },
}));

const ImagesModal = ({ isOpen, onClose, images }) => {
  const classes = useStyles();

  const PrevArrow = (clickHandler, hasPrev) => (
    <Button className={classes.prevArrow} onClick={clickHandler}>
      <img src={hasPrev ? ArrowLeft : ArrowLeftGrey} className={classes.arrowIcon} alt="left" />
    </Button>
  );
  const NextArrow = (clickHandler, hasNext) => (
    <Button className={classes.nextArrow} onClick={clickHandler}>
      <img
        src={hasNext ? ArrowLeft : ArrowLeftGrey}
        className={classes.arrowIcon}
        alt="right"
        style={{ transform: 'rotate(180deg)' }}
      />
    </Button>
  );

  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="md" scroll="body">
      <DialogContent>
        <div className={classes.closeIconContainer}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {Array.isArray(images) ? (
          <div className={classes.carouselRoot}>
            <Carousel renderArrowPrev={PrevArrow} renderArrowNext={NextArrow} showStatus={false}>
              {images.map((image) => (
                <img src={image} alt="" key={image} />
              ))}
            </Carousel>
          </div>
        ) : (
          <img src={images} alt="" className={classes.singleImage} />
        )}
      </DialogContent>
    </Dialog>
  );
};

ImagesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

ImagesModal.defaultProps = {
  images: [],
};

export default ImagesModal;
