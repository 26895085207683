import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import styles from './styles';
import LoadingLayout from '../../../../../shared/loading';
import {
  getUserPermissionForProject,
  allUserPermissions,
  getAvailableUserPermissions,
  MASTER,
  RESELLER,
  SUPER,
  DEMO,
  WH_ADMIN,
  USER,
  ACCOUNT_TYPES,
} from '../../../../../shared/utils/settings';
import rolesInfo from './defaults';
import {
  FormikSelect,
  FormikTextField,
  FormikLocationInput,
} from '../../../../../shared/inputs/formik';
import HeaderRow from '../../../../../shared/table/headerRow';
import FilledSelect from '../../../../../shared/inputs/FilledSelect';
import SliderClass from './components/SliderClass';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';

const allProjectsQuery = loader('./../../../../../graphql/queries/core/all_projects.graphql');
const allCompanies = loader('./../../../../../graphql/queries/core/all_companies.graphql');
const assignUser = loader('./../../../../../graphql/mutations/core/assign_user.graphql');
const assignDemoUser = loader('./../../../../../graphql/mutations/core/assign_demo_user.graphql');

const useStyles = makeStyles(styles);

const sliderDefaultValues = {
  radiusAmount: 5,
  projectsAmount: 3,
  containersAmount: 100,
  vehiclesAmount: 2,
  driversAmount: 3,
  historicalDataAmount: 1,
  devicesAmount: 50,
  usersAmount: 10,
  wasteFractionsAmount: 4,
  demoPeriod: 14,
  depotsAmount: 1,
  wasteStationsAmount: 1,
  vehicleTypesAmount: 1,
  containerTypesAmount: 4,
  inquiriesAmount: 10,
};

const demoPeriodMarks = [
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 14,
    label: '14 days',
  },
  {
    value: 30,
    label: '30 days',
  },
  {
    value: 60,
    label: '60 days',
  },
];

const serializeDemoUserProjects = (projects) =>
  projects.map((item) => ({
    amountOfUsers: item.amountOfUsers,
    centerLocation: {
      lon: item.centerLocation.lng || 10.2043458,
      lat: item.centerLocation.lat || 56.1429249,
    },
    collectionInquiries: item.collectionInquiries,
    containerDevicePercentage: item.containerDevicePercentage,
    containerTypes: item.containerTypes,
    containers: item.containers,
    depots: item.depots,
    drivers: item.drivers,
    fillLevelHistoryDays: item.fillLevelHistoryDays * 7,
    projectName: item.projectName || null,
    vehicleTypes: item.vehicleTypes,
    vehicles: item.vehicles,
    wasteFractions: item.wasteFractions,
    wasteStations: item.wasteStations,
    radius: item.radius,
  }));

const AssignUser = ({ me }) => {
  const classes = useStyles();
  const intl = useIntl();
  const translatedRoleInfo = rolesInfo(intl.formatMessage);
  const theme = useTheme();
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [demoProjects, setDemoProjects] = useState([]);
  const [activeProjectSettings, setActiveProjectSettings] = useState(0);

  // Slider values
  const [projectsAmount, setProjectsAmount] = useState(sliderDefaultValues.projectsAmount);
  const [containersAmount, setContainersAmount] = useState(sliderDefaultValues.containersAmount);
  const [radiusAmount, setRadiusAmount] = useState(sliderDefaultValues.radiusAmount);
  const [vehiclesAmount, setVehiclesAmount] = useState(sliderDefaultValues.vehiclesAmount);
  const [driversAmount, setDriversAmount] = useState(sliderDefaultValues.driversAmount);
  const [historicalDataAmount, setHistoricalDataAmount] = useState(
    sliderDefaultValues.historicalDataAmount,
  );
  const [devicesAmount, setDevicesAmount] = useState(sliderDefaultValues.devicesAmount);
  const [usersAmount, setUsersAmount] = useState(sliderDefaultValues.usersAmount);
  const [wasteFractionsAmount, setWasteFractionsAmount] = useState(
    sliderDefaultValues.wasteFractionsAmount,
  );
  const [depotsAmount, setDepotsAmount] = useState(sliderDefaultValues.depotsAmount);
  const [wasteStationsAmount, setWasteStationsAmount] = useState(
    sliderDefaultValues.wasteStationsAmount,
  );
  const [vehicleTypesAmount, setVehicleTypesAmount] = useState(
    sliderDefaultValues.vehicleTypesAmount,
  );
  const [containerTypesAmount, setContainerTypesAmount] = useState(
    sliderDefaultValues.containerTypesAmount,
  );
  const [inquiriesAmount, setInquiriesAmount] = useState(sliderDefaultValues.inquiriesAmount);
  const [demoPeriod, setDemoPeriod] = useState(sliderDefaultValues.demoPeriod);

  const [open, setOpen] = useState(false);
  const handleClickNext = () => {
    setOpen(true);
  };
  const handleClickRefresh = (role) => {
    if (role !== MASTER && open) {
      setOpen(false);
    }
  };

  const [assignUserMutation] = useMutation(assignUser);
  const [assignDemoUserMutation] = useMutation(assignDemoUser);

  const onSubmit = async (formVariables, { setSubmitting, setFieldError, resetForm }) => {
    const { role } = formVariables;
    try {
      if (role === DEMO) {
        const projectsVariable = demoProjects;
        if (projectsAmount > demoProjects.length) {
          const templateProject = {
            amountOfUsers: usersAmount || sliderDefaultValues.usersAmount,
            centerLocation: {
              lng: formVariables.longitude,
              lat: formVariables.latitude,
            } || {
              lng: 10.2043458,
              lat: 56.1429249,
            },
            collectionInquiries: inquiriesAmount || sliderDefaultValues.inquiriesAmount,
            containerDevicePercentage: devicesAmount || sliderDefaultValues.devicesAmount,
            containerTypes: containerTypesAmount || sliderDefaultValues.containerTypesAmount,
            containers: containersAmount || sliderDefaultValues.containersAmount,
            depots: depotsAmount || sliderDefaultValues.depotsAmount,
            drivers: driversAmount || sliderDefaultValues.driversAmount,
            fillLevelHistoryDays:
              historicalDataAmount * 7 || sliderDefaultValues.historicalDataAmount * 7,
            vehicleTypes: vehicleTypesAmount || sliderDefaultValues.vehicleTypesAmount,
            vehicles: vehiclesAmount || sliderDefaultValues.vehiclesAmount,
            wasteFractions: wasteFractionsAmount || sliderDefaultValues.wasteFractionsAmount,
            wasteStations: wasteStationsAmount || sliderDefaultValues.wasteStationsAmount,
            projectName: formVariables.projectName,
            radius: radiusAmount || sliderDefaultValues.radiusAmount,
          };

          const demoProjectLength = demoProjects.length;
          for (let i = 0; i < projectsAmount - demoProjectLength; i += 1) {
            projectsVariable.push(templateProject);
          }
        }
        const variables = {
          demoPeriod,
          companyName: formVariables.companyName || null,
          email: formVariables.email,
          firstName: formVariables.FirstName || null,
          lastName: formVariables.LastName || null,
          projects: serializeDemoUserProjects(projectsVariable),
        };
        await assignDemoUserMutation({
          variables,
        }).then(() => {
          toast.info(
            intl.formatMessage({
              id: 'user_created.demo_created',
              defaultMessage: 'New demo user created',
            }),
          );
          resetForm();
        });
      } else {
        const { email, demoCollections, demoExpireDate, project, company } = formVariables;

        await assignUserMutation({
          variables: {
            projectIds: ((role === SUPER || role === USER) && project) || filteredProjects || '',
            demoCollections: (role === DEMO && demoCollections) || [],
            demoExpireDate: role === DEMO && demoExpireDate,
            isSuper: role === SUPER,
            isReseller: role === RESELLER,
            isMaster: role === MASTER,
            isDemo: role === DEMO,
            isAdmin: role === WH_ADMIN,
            email,
            company,
            firstName: formVariables.FirstName || null,
            lastName: formVariables.LastName || null,
          },
        }).then((data) => {
          if (data.data.assignUser.demoDeleted) {
            toast.info(
              intl.formatMessage({
                id: 'user_created.old_removed',
                defaultMessage: 'Old user has been removed',
              }),
            );
          }
          switch (role) {
            case WH_ADMIN:
              toast.info(
                intl.formatMessage({
                  id: 'user_created.wh_admin_created',
                  defaultMessage: 'New waste hero admin user created',
                }),
              );
              break;
            case RESELLER:
              toast.info(
                intl.formatMessage({
                  id: 'user_created.reseller_created',
                  defaultMessage: 'New reseller created',
                }),
              );
              break;
            case MASTER:
              toast.info(
                intl.formatMessage({
                  id: 'user_created.master_created',
                  defaultMessage: 'Create new User with permission create new company',
                }),
              );
              break;
            default:
              toast.info(
                intl.formatMessage({
                  id: 'user_created.regular_user_created',
                  defaultMessage: 'User assigned to project',
                }),
              );
              break;
          }
          resetForm();
        });
      }
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length) {
        e.graphQLErrors.forEach((graphQLError) => {
          if (graphQLError?.context?.field) {
            setFieldError(graphQLError.context.field, graphQLError.message);
          }
        });
      }
      setSubmitting(false);
    }
  };

  const {
    loading: loadingProjects,
    data: { allProjects: { edges: allProjects = [] } = {} } = {},
  } = useQuery(allProjectsQuery);

  const {
    loading: companiesLoading,
    data: { allCompanies: { edges: companies = [] } = {} } = {},
  } = useQuery(allCompanies);

  const loading = loadingProjects || companiesLoading;

  const getProjectObj = (projectId = null) => {
    const projectEdge = allProjects.find(({ node }) => node.id === projectId);
    return projectEdge ? projectEdge.node : {};
  };

  const permissionForProject = getUserPermissionForProject(me, getProjectObj());
  const availableUserPermissions = getAvailableUserPermissions(permissionForProject);
  const renderAllGroups = allUserPermissions.filter((group) =>
    availableUserPermissions.includes(group.value),
  );

  const userPermissionsByAccountType = (type) => {
    if (type === ACCOUNT_TYPES.PARTNER) {
      return renderAllGroups.filter((group) => group.value === RESELLER);
    }
    if (type === ACCOUNT_TYPES.WASTE_HERO) {
      return renderAllGroups.filter((group) => group.value === WH_ADMIN);
    }
    if (type === ACCOUNT_TYPES.DEMO) {
      return renderAllGroups.filter((group) => group.value === DEMO);
    }

    return renderAllGroups.filter((group) => ![WH_ADMIN, RESELLER, DEMO].includes(group.value));
  };

  const initialValues = {
    email: '',
    role: USER,
    demoCollections: '',
    demoExpireDate: 14,
    project: '',
    company: '',
    accountType: '',
    projectName: '',
    latitude: '',
    longitude: '',
    address: '',
  };
  const pageTitle = intl.formatMessage({
    id: 'create_user_header',
    defaultMessage: 'Create User',
  });

  const handleProjectsAmountChange = (value, setFieldValue) => {
    let newDemoProjects = demoProjects.sort((a, b) => {
      if (a.number > b.number) {
        return 1;
      }
      if (a.number < b.number) {
        return -1;
      }
      return 0;
    });
    if (value < demoProjects) {
      newDemoProjects = newDemoProjects.slice(0, value);
    }

    const currentProject = newDemoProjects.find(({ number }) => number === 0);

    if (currentProject) {
      setFieldValue('projectName', currentProject.projectName);
      setFieldValue('latitude', currentProject.centerLocation.lat);
      setFieldValue('longitude', currentProject.centerLocation.lng);
      setFieldValue('address', currentProject.centerLocation.address);
      setContainersAmount(currentProject.containers);
      setVehiclesAmount(currentProject.vehicles);
      setDriversAmount(currentProject.drivers);
      setHistoricalDataAmount(currentProject.fillLevelHistoryDays);
      setDevicesAmount(currentProject.containerDevicePercentage);
      setUsersAmount(currentProject.amountOfUsers);
      setWasteFractionsAmount(currentProject.wasteFractions);
      setDepotsAmount(currentProject.depots);
      setWasteStationsAmount(currentProject.wasteStations);
      setVehicleTypesAmount(currentProject.vehicleTypes);
      setContainerTypesAmount(currentProject.containerTypes);
      setInquiriesAmount(currentProject.collectionInquiries);
      setRadiusAmount(currentProject.radius);
    }

    setActiveProjectSettings(0);
    setProjectsAmount(value);
  };

  const handleNextClick = (values, setFieldValue) => {
    const project = {};
    project.number = activeProjectSettings;
    project.projectName = values.projectName;
    project.containers = containersAmount;
    project.vehicles = vehiclesAmount;
    project.drivers = driversAmount;
    project.fillLevelHistoryDays = historicalDataAmount;
    project.containerDevicePercentage = devicesAmount;
    project.amountOfUsers = usersAmount;
    project.wasteFractions = wasteFractionsAmount;
    project.depots = depotsAmount;
    project.wasteStations = wasteStationsAmount;
    project.vehicleTypes = vehicleTypesAmount;
    project.containerTypes = containerTypesAmount;
    project.collectionInquiries = inquiriesAmount;
    project.radius = radiusAmount;
    project.centerLocation = {
      lat: values.latitude,
      lng: values.longitude,
      address: values.address,
    };

    setDemoProjects([
      ...demoProjects.filter(({ number }) => number !== activeProjectSettings),
      project,
    ]);

    const nextDemoProject = demoProjects.find(({ number }) => number === activeProjectSettings + 1);

    if (nextDemoProject) {
      setFieldValue('projectName', nextDemoProject.projectName);
      setFieldValue('latitude', nextDemoProject.centerLocation.lat);
      setFieldValue('longitude', nextDemoProject.centerLocation.lng);
      setFieldValue('address', nextDemoProject.centerLocation.address);
      setContainersAmount(nextDemoProject.containers);
      setVehiclesAmount(nextDemoProject.vehicles);
      setDriversAmount(nextDemoProject.drivers);
      setHistoricalDataAmount(nextDemoProject.fillLevelHistoryDays);
      setDevicesAmount(nextDemoProject.containerDevicePercentage);
      setUsersAmount(nextDemoProject.amountOfUsers);
      setWasteFractionsAmount(nextDemoProject.wasteFractions);
      setDepotsAmount(nextDemoProject.depots);
      setWasteStationsAmount(nextDemoProject.wasteStations);
      setVehicleTypesAmount(nextDemoProject.vehicleTypes);
      setContainerTypesAmount(nextDemoProject.containerTypes);
      setInquiriesAmount(nextDemoProject.collectionInquiries);
      setRadiusAmount(nextDemoProject.radius);
    } else {
      setFieldValue('projectName', '');
      setFieldValue('latitude', '');
      setFieldValue('longitude', '');
      setFieldValue('address', '');
      setContainersAmount(sliderDefaultValues.containersAmount);
      setVehiclesAmount(sliderDefaultValues.vehiclesAmount);
      setDriversAmount(sliderDefaultValues.driversAmount);
      setHistoricalDataAmount(sliderDefaultValues.historicalDataAmount);
      setDevicesAmount(sliderDefaultValues.devicesAmount);
      setUsersAmount(sliderDefaultValues.usersAmount);
      setWasteFractionsAmount(sliderDefaultValues.wasteFractionsAmount);
      setDepotsAmount(sliderDefaultValues.depotsAmount);
      setWasteStationsAmount(sliderDefaultValues.wasteStationsAmount);
      setVehicleTypesAmount(sliderDefaultValues.vehicleTypesAmount);
      setContainerTypesAmount(sliderDefaultValues.containerTypesAmount);
      setInquiriesAmount(sliderDefaultValues.inquiriesAmount);
      setRadiusAmount(sliderDefaultValues.radiusAmount);
    }
    setActiveProjectSettings(activeProjectSettings + 1);
  };

  const handleBackClick = (values, setFieldValue) => {
    const project = {};
    project.number = activeProjectSettings;
    project.projectName = values.projectName;
    project.containers = containersAmount;
    project.vehicles = vehiclesAmount;
    project.drivers = driversAmount;
    project.fillLevelHistoryDays = historicalDataAmount;
    project.containerDevicePercentage = devicesAmount;
    project.amountOfUsers = usersAmount;
    project.wasteFractions = wasteFractionsAmount;
    project.depots = depotsAmount;
    project.wasteStations = wasteStationsAmount;
    project.vehicleTypes = vehicleTypesAmount;
    project.containerTypes = containerTypesAmount;
    project.collectionInquiries = inquiriesAmount;
    project.radius = radiusAmount;
    project.centerLocation = {
      lat: values.latitude,
      lng: values.longitude,
      address: values.address,
    };

    setDemoProjects([
      ...demoProjects.filter(({ number }) => number !== activeProjectSettings),
      project,
    ]);

    const previousDemoProject = demoProjects.find(
      ({ number }) => number === activeProjectSettings - 1,
    );

    if (previousDemoProject) {
      setFieldValue('projectName', previousDemoProject.projectName);
      setFieldValue('latitude', previousDemoProject.centerLocation.lat);
      setFieldValue('longitude', previousDemoProject.centerLocation.lng);
      setFieldValue('address', previousDemoProject.centerLocation.address);
      setContainersAmount(previousDemoProject.containers);
      setVehiclesAmount(previousDemoProject.vehicles);
      setDriversAmount(previousDemoProject.drivers);
      setHistoricalDataAmount(previousDemoProject.fillLevelHistoryDays);
      setDevicesAmount(previousDemoProject.containerDevicePercentage);
      setUsersAmount(previousDemoProject.amountOfUsers);
      setWasteFractionsAmount(previousDemoProject.wasteFractions);
      setDepotsAmount(previousDemoProject.depots);
      setWasteStationsAmount(previousDemoProject.wasteStations);
      setVehicleTypesAmount(previousDemoProject.vehicleTypes);
      setContainerTypesAmount(previousDemoProject.containerTypes);
      setInquiriesAmount(previousDemoProject.collectionInquiries);
      setRadiusAmount(previousDemoProject.radius);
    } else {
      setFieldValue('projectName', '');
      setFieldValue('latitude', '');
      setFieldValue('longitude', '');
      setFieldValue('address', '');
      setContainersAmount(sliderDefaultValues.containersAmount);
      setVehiclesAmount(sliderDefaultValues.vehiclesAmount);
      setDriversAmount(sliderDefaultValues.driversAmount);
      setHistoricalDataAmount(sliderDefaultValues.historicalDataAmount);
      setDevicesAmount(sliderDefaultValues.devicesAmount);
      setUsersAmount(sliderDefaultValues.usersAmount);
      setWasteFractionsAmount(sliderDefaultValues.wasteFractionsAmount);
      setDepotsAmount(sliderDefaultValues.depotsAmount);
      setWasteStationsAmount(sliderDefaultValues.wasteStationsAmount);
      setVehicleTypesAmount(sliderDefaultValues.vehicleTypesAmount);
      setContainerTypesAmount(sliderDefaultValues.containerTypesAmount);
      setInquiriesAmount(sliderDefaultValues.inquiriesAmount);
      setRadiusAmount(sliderDefaultValues.radiusAmount);
    }
    setActiveProjectSettings(activeProjectSettings - 1);
  };

  return (
    <Grid container className={classes.pageContainer} justify="center">
      <HeaderRow pageTitle={pageTitle} />
      {loading ? (
        <LoadingLayout isLoading={loading} />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(
                <FormattedMessage id="validation.invalid_email" defaultMessage="Invalid email" />,
              )
              .required(),
            role: Yup.string().required(),
            company: Yup.string().when('role', {
              is: (role) => role === USER || role === SUPER || (role === MASTER && open),
              then: Yup.string().required(),
            }),
            project: Yup.array().when('role', {
              is: (role) => role === USER || role === SUPER,
              then: Yup.array(),
            }),
            demoExpireDate: Yup.number().when('role', {
              is: DEMO,
              then: Yup.number().required(),
            }),
          })}
        >
          {({ isSubmitting, values, setValues, setFieldValue }) => (
            <Form className={classes.form}>
              <Grid container justify="center" className="w-100">
                <LoadingLayout isLoading={isSubmitting} />
                <Grid container item xs={12} md={9} className={classes.card}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1" align="center" className={classes.titleText}>
                        <FormattedMessage id="create_user" defaultMessage="Create user" />
                      </Typography>
                      <Typography className={classes.subtitleText}>
                        <FormattedMessage id="account_type" defaultMessage="Account type" />
                      </Typography>
                      <div className={classes.userTypesButtonsContainer}>
                        <Button
                          variant="contained"
                          type="button"
                          className={classes.userTypeButton}
                          disabled={isSubmitting}
                          style={{
                            color:
                              values.accountType === ACCOUNT_TYPES.CUSTOMER
                                ? theme.variables.VHOrange
                                : 'inherit',
                          }}
                          onClick={() =>
                            setValues({
                              ...values,
                              accountType: ACCOUNT_TYPES.CUSTOMER,
                              role: USER,
                            })
                          }
                        >
                          <FormattedMessage id="customer" defaultMessage="Customer" />
                        </Button>
                        <Button
                          variant="contained"
                          type="button"
                          className={classes.userTypeButton}
                          disabled={isSubmitting}
                          style={{
                            color:
                              values.accountType === ACCOUNT_TYPES.DEMO
                                ? theme.variables.VHOrange
                                : 'inherit',
                          }}
                          onClick={() =>
                            setValues({
                              ...values,
                              accountType: ACCOUNT_TYPES.DEMO,
                              role: DEMO,
                            })
                          }
                        >
                          <FormattedMessage id="demo" defaultMessage="Demo" />
                        </Button>
                        {!me.isReseller && (
                          <>
                            <Button
                              variant="contained"
                              type="button"
                              className={classes.userTypeButton}
                              disabled={isSubmitting}
                              style={{
                                color:
                                  values.accountType === ACCOUNT_TYPES.WASTE_HERO
                                    ? theme.variables.VHOrange
                                    : 'inherit',
                              }}
                              onClick={() =>
                                setValues({
                                  ...values,
                                  accountType: ACCOUNT_TYPES.WASTE_HERO,
                                  role: WH_ADMIN,
                                })
                              }
                            >
                              <FormattedMessage id="waste_hero" defaultMessage="WasteHero" />
                            </Button>
                            <Button
                              variant="contained"
                              type="button"
                              className={classes.userTypeButton}
                              disabled={isSubmitting}
                              style={{
                                color:
                                  values.accountType === ACCOUNT_TYPES.PARTNER
                                    ? theme.variables.VHOrange
                                    : 'inherit',
                              }}
                              onClick={() =>
                                setValues({
                                  ...values,
                                  accountType: ACCOUNT_TYPES.PARTNER,
                                  role: RESELLER,
                                })
                              }
                            >
                              <FormattedMessage id="partner" defaultMessage="Partner" />
                            </Button>
                          </>
                        )}
                      </div>
                      {values.accountType && (
                        <>
                          <div className={classes.fieldRow}>
                            <FormControl className="w-100" required>
                              <FormikTextField
                                name="email"
                                id="email"
                                required
                                className={classes.textField}
                                placeholder={intl.formatMessage({
                                  id: 'placeholder.email',
                                  defaultMessage: 'Email',
                                })}
                                label={intl.formatMessage({
                                  id: 'label.email',
                                  defaultMessage: 'Email',
                                })}
                              />
                            </FormControl>
                          </div>
                          <div className={classes.fieldRow}>
                            <Grid container spacing={2}>
                              <Grid item>
                                <TooltippedUserControl
                                  tooltipText={
                                    <FormattedMessage
                                      id="tooltip.will_be_generated"
                                      defaultMessage="If no value - it is generated automaticly"
                                    />
                                  }
                                >
                                  <FormControl className="w-100">
                                    <FormikTextField
                                      name="FirstName"
                                      id="FirstName"
                                      className={classes.textField}
                                      placeholder={intl.formatMessage({
                                        id: 'placeholder.FirstName',
                                        defaultMessage: 'First name',
                                      })}
                                      label={intl.formatMessage({
                                        id: 'label.First_name',
                                        defaultMessage: 'First name',
                                      })}
                                    />
                                  </FormControl>
                                </TooltippedUserControl>
                              </Grid>
                              <Grid item>
                                <TooltippedUserControl
                                  tooltipText={
                                    <FormattedMessage
                                      id="tooltip.will_be_generated"
                                      defaultMessage="If no value - it is generated automaticly"
                                    />
                                  }
                                >
                                  <FormControl className="w-100">
                                    <FormikTextField
                                      name="LastName"
                                      id="LastName"
                                      className={classes.textField}
                                      placeholder={intl.formatMessage({
                                        id: 'placeholder.LastName',
                                        defaultMessage: 'Last name',
                                      })}
                                      label={intl.formatMessage({
                                        id: 'label.Last_Name',
                                        defaultMessage: 'Last name',
                                      })}
                                    />
                                  </FormControl>
                                </TooltippedUserControl>
                              </Grid>
                            </Grid>
                            {values.role !== DEMO && (
                              <div className={classes.fieldRow}>
                                <FormikSelect
                                  required
                                  className="w-100"
                                  name="role"
                                  filledStyle
                                  classes={{ select: classes.paddedSelect }}
                                  placeholder={intl.formatMessage({
                                    id: 'placeholder.account_type',
                                    defaultMessage: 'Select account type',
                                  })}
                                  onOptionClick={handleClickRefresh(values.role)}
                                  valuesList={userPermissionsByAccountType(values.accountType)}
                                  label={intl.formatMessage({
                                    id: 'label.account_type',
                                    defaultMessage: 'Select account type',
                                  })}
                                />
                              </div>
                            )}
                          </div>
                          <div className={classes.fieldRow}>
                            {values.role === DEMO && (
                              <>
                                <div className={classes.fieldRow}>
                                  <TooltippedUserControl
                                    tooltipText={
                                      <FormattedMessage
                                        id="tooltip.will_be_generated"
                                        defaultMessage="If no value - it is generated automaticly"
                                      />
                                    }
                                  >
                                    <FormControl className="w-100" required>
                                      <FormikTextField
                                        name="companyName"
                                        id="companyName"
                                        className={classes.textField}
                                        placeholder={intl.formatMessage({
                                          id: 'placeholder.companyName',
                                          defaultMessage: 'Company name',
                                        })}
                                        label={intl.formatMessage({
                                          id: 'label.companyName',
                                          defaultMessage: 'Company name',
                                        })}
                                      />
                                    </FormControl>
                                  </TooltippedUserControl>
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfProjects"
                                      defaultMessage="Amount of projects"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of projects"
                                    step={1}
                                    value={projectsAmount}
                                    onChange={(e, value) =>
                                      handleProjectsAmountChange(value, setFieldValue)
                                    }
                                    min={1}
                                    max={5}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <FormattedMessage
                                  id="projectSettingsStep"
                                  defaultMessage="Settings for project {currentProject} of {projectsAmount}"
                                  values={{
                                    projectsAmount,
                                    currentProject: activeProjectSettings + 1,
                                  }}
                                >
                                  {(txt) => (
                                    <p className={classes.projectSettingsStepTitle}>{txt}</p>
                                  )}
                                </FormattedMessage>
                                <div className={classes.fieldRow}>
                                  <TooltippedUserControl
                                    tooltipText={
                                      <FormattedMessage
                                        id="tooltip.will_be_generated"
                                        defaultMessage="If no value - it is generated automaticly"
                                      />
                                    }
                                  >
                                    <FormControl className="w-100" required>
                                      <FormikTextField
                                        name="projectName"
                                        id="projectName"
                                        className={classes.textField}
                                        placeholder={intl.formatMessage({
                                          id: 'placeholder.projectName',
                                          defaultMessage: 'Project name',
                                        })}
                                        label={intl.formatMessage({
                                          id: 'label.projectName',
                                          defaultMessage: 'Project name',
                                        })}
                                      />
                                    </FormControl>
                                  </TooltippedUserControl>
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormikLocationInput
                                    label={intl.formatMessage({
                                      id: 'location.form.label.address',
                                      defaultMessage: 'Address',
                                    })}
                                    required
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfContainers"
                                      defaultMessage="Amount of containers"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of containers"
                                    step={10}
                                    value={containersAmount}
                                    onChange={(e, value) => setContainersAmount(value)}
                                    min={10}
                                    max={200}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfRadius"
                                      defaultMessage="Area radius, m"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="area radius"
                                    step={1}
                                    value={radiusAmount}
                                    onChange={(e, value) => setRadiusAmount(value)}
                                    min={1}
                                    max={100}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfWasteFractions"
                                      defaultMessage="Amount of waste fractions"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of waste fractions"
                                    step={1}
                                    value={wasteFractionsAmount}
                                    onChange={(e, value) => setWasteFractionsAmount(value)}
                                    min={1}
                                    max={10}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfWasteStations"
                                      defaultMessage="Amount of waste stations"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of waste stations"
                                    step={1}
                                    value={wasteStationsAmount}
                                    onChange={(e, value) => setWasteStationsAmount(value)}
                                    min={0}
                                    max={5}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfContainerTypes"
                                      defaultMessage="Amount of container types"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of container types"
                                    step={1}
                                    value={containerTypesAmount}
                                    onChange={(e, value) => setContainerTypesAmount(value)}
                                    min={1}
                                    max={10}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfInquiries"
                                      defaultMessage="Amount of collection inquiries"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of collection inquiries"
                                    step={1}
                                    value={inquiriesAmount}
                                    onChange={(e, value) => setInquiriesAmount(value)}
                                    min={0}
                                    max={30}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfVehicles"
                                      defaultMessage="Amount of vehicles"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of vehicles"
                                    value={vehiclesAmount}
                                    onChange={(e, value) => {
                                      if (!vehiclesAmount && value) {
                                        if (depotsAmount === 0) {
                                          setDepotsAmount(1);
                                        }
                                        if (vehicleTypesAmount === 0) {
                                          setVehicleTypesAmount(1);
                                        }
                                      }
                                      setVehiclesAmount(value);
                                    }}
                                    step={1}
                                    min={0}
                                    max={100}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfVehicleTypes"
                                      defaultMessage="Amount of vehicle types"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of vehicle types"
                                    step={1}
                                    value={vehicleTypesAmount}
                                    onChange={(e, value) => setVehicleTypesAmount(value)}
                                    min={vehiclesAmount ? 1 : 0}
                                    max={5}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfDepots"
                                      defaultMessage="Amount of depots"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of depots"
                                    step={1}
                                    value={depotsAmount}
                                    onChange={(e, value) => setDepotsAmount(value)}
                                    min={vehiclesAmount ? 1 : 0}
                                    max={5}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfDrivers"
                                      defaultMessage="Amount of drivers"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of drivers"
                                    value={driversAmount}
                                    onChange={(e, value) => setDriversAmount(value)}
                                    step={1}
                                    min={0}
                                    max={100}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfUsers"
                                      defaultMessage="Amount of users"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of users"
                                    value={usersAmount}
                                    onChange={(e, value) => setUsersAmount(value)}
                                    step={1}
                                    min={1}
                                    max={100}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfDeviceHistoricalData"
                                      defaultMessage="Amount of device historical data (weeks)"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of device historical data"
                                    value={historicalDataAmount}
                                    onChange={(e, value) => setHistoricalDataAmount(value)}
                                    step={1}
                                    min={0}
                                    max={3}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.amountOfDevices"
                                      defaultMessage="% with sensor"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="amount of devices"
                                    value={devicesAmount}
                                    onChange={(e, value) => setDevicesAmount(value)}
                                    step={10}
                                    min={0}
                                    valueLabelFormat={(value) => `${value}%`}
                                    max={100}
                                    marks
                                    valueLabelDisplay="on"
                                  />
                                </div>
                                <Grid container justify="space-between">
                                  <Button
                                    onClick={() => {
                                      handleBackClick(values, setFieldValue);
                                    }}
                                    disabled={activeProjectSettings === 0}
                                    className={classes.button}
                                  >
                                    <FormattedMessage id="back" defaultMessage="Back" />
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                      activeProjectSettings === projectsAmount - 1 ||
                                      !(values.latitude && values.longitude)
                                    }
                                    onClick={() => handleNextClick(values, setFieldValue)}
                                    className={classes.button}
                                  >
                                    <FormattedMessage id="next" defaultMessage="Next" />
                                  </Button>
                                </Grid>
                                <div className={classes.fieldRow}>
                                  <FormLabel className={classes.formLabel}>
                                    <FormattedMessage
                                      id="label.demo_period"
                                      defaultMessage="Demo period"
                                    >
                                      {(txt) => (
                                        <>
                                          <span className="m-t-20 m-b-20">{txt}</span>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </FormLabel>
                                  <SliderClass
                                    aria-label="demo_period"
                                    value={demoPeriod}
                                    onChange={(e, value) => setDemoPeriod(value)}
                                    step={null}
                                    min={7}
                                    max={60}
                                    marks={demoPeriodMarks}
                                  />
                                </div>
                              </>
                            )}

                            {(!values.role || values.role === SUPER || values.role === USER) && (
                              <FormikSelect
                                required
                                className="w-100"
                                name="company"
                                filledStyle
                                classes={{ select: classes.paddedSelect }}
                                valuesList={companies.map((item) => ({
                                  label: item.node.name,
                                  value: item.node.id,
                                }))}
                                placeholder={intl.formatMessage({
                                  id: 'placeholder.select_company',
                                  defaultMessage: 'Select Company',
                                })}
                                label={intl.formatMessage({
                                  id: 'label.select_company',
                                  defaultMessage: 'Select Company',
                                })}
                              />
                            )}
                            {(!values.role || values.role === MASTER) && !open && (
                              <div className={classes.btn_toolbar}>
                                <div className={classes.left_group}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submitBtnAdmin}
                                    type="submit"
                                    classes={{
                                      disabled: classes.disabledButton,
                                    }}
                                    disabled={isSubmitting || !values.accountType}
                                  >
                                    <FormattedMessage
                                      id="New_company"
                                      defaultMessage="New company"
                                    />
                                  </Button>
                                </div>
                                <div className={classes.right_group}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submitBtnAdmin}
                                    onClick={handleClickNext}
                                  >
                                    <FormattedMessage
                                      id="attach_to_company"
                                      defaultMessage="Attach to existing company"
                                    />
                                  </Button>
                                </div>
                              </div>
                            )}
                            {(!values.role || values.role === MASTER) && open && (
                              <FormikSelect
                                required
                                className="w-100"
                                name="company"
                                filledStyle
                                classes={{ select: classes.paddedSelect }}
                                valuesList={companies.map((item) => ({
                                  label: item.node.name,
                                  value: item.node.id,
                                }))}
                                placeholder={intl.formatMessage({
                                  id: 'placeholder.select_company',
                                  defaultMessage: 'Select Company',
                                })}
                                label={intl.formatMessage({
                                  id: 'label.select_company',
                                  defaultMessage: 'Select Company',
                                })}
                              />
                            )}
                          </div>
                          <div className={classes.fieldRow}>
                            {(!values.role ||
                              values.role === SUPER ||
                              values.role === USER ||
                              (values.role === MASTER &&
                                permissionForProject !== WH_ADMIN &&
                                permissionForProject !== RESELLER)) &&
                              values.company && (
                                <FilledSelect
                                  required
                                  name="project"
                                  className={classes.selectFilter}
                                  multiSelect
                                  filledStyle
                                  valuesList={allProjects
                                    .filter((project) => project.node.company.id === values.company)
                                    .map((item) => ({
                                      label: item.node.name,
                                      value: item.node.id,
                                    }))}
                                  value={filteredProjects}
                                  onChange={(e) => {
                                    setFilteredProjects(e.target.value);
                                  }}
                                  placeholder={intl.formatMessage({
                                    id: 'placeholder.select_project',
                                    defaultMessage: 'Select Project',
                                  })}
                                  label={intl.formatMessage({
                                    id: 'label.select_project',
                                    defaultMessage: 'Select Project',
                                  })}
                                />
                              )}
                          </div>
                        </>
                      )}
                      <div className={classes.submitBtnContainer}>
                        {(!values.role || values.role === MASTER) && open && (
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            className={classes.submitBtn}
                            classes={{
                              disabled: classes.disabledButton,
                            }}
                            disabled={isSubmitting || !values.accountType}
                          >
                            <FormattedMessage id="Create" defaultMessage="Create" />
                          </Button>
                        )}
                        {(!values.role || values.role !== MASTER) && (
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            className={classes.submitBtn}
                            classes={{
                              disabled: classes.disabledButton,
                            }}
                            disabled={isSubmitting || !values.accountType}
                          >
                            <FormattedMessage id="Create" defaultMessage="Create" />
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item container xs={12} md={6} style={{ flexDirection: 'column' }}>
                      <Typography
                        variant="body1"
                        align="center"
                        className={classNames(classes.titleText)}
                      >
                        {
                          allUserPermissions.find((permission) => permission.value === values.role)
                            .label
                        }
                        &nbsp;
                        <FormattedMessage id="account" defaultMessage="account" />
                      </Typography>
                      <img src={translatedRoleInfo[values.role].picture} alt="Account WasteHero" />
                      <Typography variant="body1" className={classes.subtitleText}>
                        <FormattedMessage id="description" defaultMessage="Description" />:
                      </Typography>
                      <Typography paragraph className={classes.text}>
                        {translatedRoleInfo[values.role].description}
                      </Typography>
                      <Typography variant="body1" className={classes.subtitleText}>
                        <FormattedMessage id="Permissions" defaultMessage="Permissions" />:
                      </Typography>
                      <Typography paragraph className={classes.text}>
                        {translatedRoleInfo[values.role].permissions}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Grid>
  );
};

AssignUser.propTypes = {
  me: PropTypes.shape({
    isAdmin: PropTypes.bool.isRequired,
    isReseller: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(AssignUser);
