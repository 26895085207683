import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';

import QrCodeScanner from '../../../../../shared/qrScanner';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  scanner: {
    display: 'none',
  },
}));

const DeviceIdScanner = ({ onShowScanner, setFieldValue }) => {
  const [hasPermission, setHasPermission] = useState(true);
  const scannerRef = useRef();
  const classes = useStyles();
  const intl = useIntl();

  const handleReceiveMediaPermission = () => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => {
          onShowScanner();
        })
        .catch(() => {
          toast.error(
            intl.formatMessage({
              id: 'toast.camera_forbidden',
              defaultMessage: 'Your browser does not let to use camera',
            }),
          );
          setHasPermission(false);
        });
    }
  };

  const handleUploadScan = () => {
    scannerRef.current.openImageDialog();
  };

  return (
    <>
      <Grid container item xs={12} spacing={2} className={classes.root}>
        <Grid item xs={6}>
          <Button
            disabled={!hasPermission}
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleReceiveMediaPermission}
          >
            {intl.formatMessage({
              id: 'sensor.scan_qr',
              defaultMessage: 'Scan QR',
            })}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth color="primary" variant="contained" onClick={handleUploadScan}>
            {intl.formatMessage({
              id: 'sensor.upload_qr',
              defaultMessage: 'Upload QR',
            })}
          </Button>
        </Grid>
      </Grid>
      <QrCodeScanner
        ref={scannerRef}
        className={classes.scanner}
        onScan={(value) => {
          if (value) {
            setFieldValue('devId', value);
          } else {
            toast.error(
              intl.formatMessage({
                id: 'toast.could_not_read_qr_code',
                defaultMessage: 'Could not read the QR code',
              }),
            );
          }
        }}
        legacyMode
      />
    </>
  );
};

DeviceIdScanner.propTypes = {
  onShowScanner: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default DeviceIdScanner;
