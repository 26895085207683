import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import { MASTER } from '../../../../../shared/utils/settings';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  saveButton: {
    marginTop: '15px',
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  projectLogo: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '40px',
    '&>img': {
      maxHeight: 40,
      maxWidth: '100%',
    },
  },
}));

const CompanyProjectList = ({ me, projectList, companyName, companyId, deleteHandle }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid item>
      <Typography variant="h6" className={classes.title}>
        {companyName}
        &nbsp;
        <FormattedMessage id="company_projects" defaultMessage="Company Projects" />
        {(me.isAdmin || me.isMaster || me.isReseller) && (
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.add_project_to_company"
                defaultMessage="Click to add new project under this company"
              />
            }
            avoidIcon
            inlineItems
          >
            <Link to={{ pathname: `/app/settings/create-project/${companyId}` }}>
              <IconButton
                edge="end"
                aria-label="Add Project"
                title={intl.formatMessage({
                  id: 'add_project_to_company',
                  defaultMessage: 'Add Project to Company',
                })}
              >
                <AddIcon />
              </IconButton>
            </Link>
          </TooltippedUserControl>
        )}
        {(me.isAdmin || me.isMaster || me.isReseller) && (
          <TooltippedUserControl
            tooltipText={
              <FormattedMessage
                id="tooltip.edit_company"
                defaultMessage="Click to edit the company details"
              />
            }
            avoidIcon
            inlineItems
          >
            <Link to={{ pathname: `/app/settings/company/${companyId}` }}>
              <IconButton
                className="p-15"
                edge="end"
                title={intl.formatMessage({ id: 'edit_company', defaultMessage: 'Edit Company' })}
              >
                <EditIcon />
              </IconButton>
            </Link>
          </TooltippedUserControl>
        )}
      </Typography>
      {projectList.length ? (
        <div className={classes.list}>
          <List>
            {projectList.map((project) => (
              <ListItem key={project.id}>
                <ListItemAvatar>
                  {project.logo ? (
                    <div className={classes.projectLogo}>
                      <img src={project.logo} alt={project.name} />
                    </div>
                  ) : (
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={project.name} />
                <ListItemSecondaryAction>
                  {project.userAccessLevel === MASTER && (
                    <TooltippedUserControl
                      tooltipText={
                        <FormattedMessage
                          id="tooltip.delete_project"
                          defaultMessage="Click to delete this project"
                        />
                      }
                      avoidIcon
                      inlineItems
                    >
                      <IconButton className="p-15" edge="end" onClick={() => deleteHandle(project)}>
                        <DeleteIcon />
                      </IconButton>
                    </TooltippedUserControl>
                  )}
                  {!!project.userAccessLevel && (
                    <Link to={`/app/settings/${project.id}/project-settings`}>
                      <TooltippedUserControl
                        tooltipText={
                          <FormattedMessage
                            id="tooltip.settings"
                            defaultMessage="Click to see the settings of this project"
                          />
                        }
                        avoidIcon
                        inlineItems
                      >
                        <IconButton className="p-15" edge="end">
                          <SettingsIcon />
                        </IconButton>
                      </TooltippedUserControl>
                    </Link>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      ) : null}
    </Grid>
  );
};

CompanyProjectList.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  deleteHandle: PropTypes.func.isRequired,
  projectList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isReseller: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(withRouter(CompanyProjectList));
