import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { loader } from 'graphql.macro';
import LoadingLayout from '../../../../shared/loading';
import { toastifyError } from '../../../../shared/utils';
import { FormikField, FormikSelect } from '../../../../shared/inputs/formik';
import { MQTT_CONNECTION_TYPES } from '../../containers/routes/shared/utils';

const sendMqttMessageMutation = loader(
  './../../../../graphql/mutations/core/send_mqtt_message.graphql',
);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    marginTop: '60px',
    marginBottom: '35px',
  },
  content: {
    margin: '35px',
    height: '100%',
  },
}));

const SendMqttMessageForm = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [sendMqttMessage] = useMutation(sendMqttMessageMutation);

  const formSubmitHandler = (variables, { setSubmitting, resetForm }) =>
    sendMqttMessage({ variables })
      .then(
        ({
          data: {
            sendMqttMessage: { status },
          },
        }) => {
          if (status === 'Success') {
            resetForm();
            toast.info(
              intl.formatMessage({
                id: 'message_sent',
                defaultMessage: 'Message sent',
              }),
            );
          }
        },
      )
      .catch((error) => {
        toastifyError(error);
      })
      .finally(() => {
        setSubmitting(false);
      });

  return (
    <div className={classes.root}>
      <Typography variant="h5" align="center" className={classes.header}>
        <FormattedMessage id="send_mqtt_message" defaultMessage="Send MQTT message" />
      </Typography>
      <Paper className={classes.content}>
        <Formik
          initialValues={{
            topic: '',
            message: '',
            strMessage: '',
            connectionType: MQTT_CONNECTION_TYPES[0].value,
          }}
          onSubmit={formSubmitHandler}
          validationSchema={Yup.object().shape({
            topic: Yup.string().required(),
            connectionType: Yup.string().required(),
            message: Yup.mixed().when('strMessage', {
              is: (strMessage) => !strMessage,
              then: Yup.string().required(
                <FormattedMessage
                  id="validation.one_is_required"
                  defaultMessage="One of the messages is required"
                />,
              ),
              otherwise: Yup.string(),
            }),
            strMessage: Yup.string(),
          })}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className="w-100 m-t-40">
              <LoadingLayout isLoading={isSubmitting} />
              <Grid
                container
                alignItems="center"
                justify="center"
                className="m-b-10"
                direction="column"
                spacing={4}
              >
                <Grid container item xs={12} md={10} spacing={2} justify="center">
                  <FormikField
                    component={TextField}
                    label={intl.formatMessage({ id: 'topic', defaultMessage: 'Topic' })}
                    required
                    className="w-100"
                    name="topic"
                  />
                </Grid>
                <Grid container item xs={12} md={10} spacing={2} justify="center">
                  <FormikSelect
                    required
                    name="connectionType"
                    valuesList={MQTT_CONNECTION_TYPES}
                    label={intl.formatMessage({
                      id: 'connection_type',
                      defaultMessage: 'Connection type',
                    })}
                  />
                </Grid>
                <Grid container item xs={12} md={10} spacing={2} justify="center">
                  <FormikField
                    component={TextField}
                    label={intl.formatMessage({
                      id: 'unit32_message',
                      defaultMessage: 'uint32 Message',
                    })}
                    className="w-100"
                    name="message"
                  />
                </Grid>
                <Grid container item xs={12} md={10} spacing={2} justify="center">
                  <FormikField
                    component={TextField}
                    label={intl.formatMessage({
                      id: 'unformatted_message',
                      defaultMessage: 'Unformatted Message',
                    })}
                    className="w-100"
                    name="strMessage"
                  />
                </Grid>
                <Grid container item xs={10} spacing={2} justify="center">
                  <Grid item xs={3} container justify="center">
                    <Button
                      variant="outlined"
                      className="w-60 settings-control-button"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      <Typography variant="body1">
                        <FormattedMessage id="send" defaultMessage="Send" />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default SendMqttMessageForm;
