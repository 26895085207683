import React from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  AppBar,
  IconButton,
  DialogContent,
  makeStyles,
  Typography,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';
import CreateDeviceForm from './CreateDeviceForm';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * > *': {
      minWidth: 'auto',
    },
  },
  content: {
    padding: 24,
    [theme.breakpoints.down(600)]: {
      padding: 16,
    },
  },
  appBar: {
    position: 'relative',
    '& > *': {
      color: '#ffffff',
      backgroundColor: theme.variables.HeaderColor,
    },
  },
  dialogActions: {
    padding: 24,
  },
  submitButton: {
    minWidth: '50%',
  },
  title: {
    color: '#ffffff',
  },
}));

const CreateDeviceDialog = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const handleCloseDialog = () => {
    history.push('/app/containers/');
  };

  return (
    <Dialog fullScreen open className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {intl.formatMessage({
              id: 'create_device',
              defaultMessage: 'Create Device',
            })}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <CreateDeviceForm />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(CreateDeviceDialog);
