import React from 'react';
import { FormattedMessage } from 'react-intl';
import FullPageWrapperWithText from './onlyTextPage';

const PageNotFound = () => (
  <FullPageWrapperWithText
    text={<FormattedMessage id="404_error_message" defaultMessage="404 - Page not found!" />}
  />
);

export default PageNotFound;
