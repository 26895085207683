import React, { useMemo } from 'react';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { toastifyError } from '../../../../../shared/utils';
import InfoLabel from '../../components/infoLabel';
import InfoBox from '../../components/infoBox';
import StyledStatus from '../../components/styledStatus';
import DetailsSkeleton from '../../components/detailsSkeleton';
import LoadingLayout from '../../../../../shared/loading';
import { StyledListLink } from '../../components/linkHoverStyle';
import { joinElementsCallback, FUEL_TYPE } from '../../components/utils';
import HeaderTab from '../../components/headerTab';
import { daysOptions } from '../../../settings/project/defaults/data';

const vehicleDetailsQuery = loader(
  './../../../../../graphql/queries/fleet_management/vehicle.graphql',
);

const useStyles = makeStyles((theme) => ({
  sectionText: {
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: theme.variables.cSlate,
    lineHeight: '16px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
  subList: {
    paddingLeft: theme.spacing(2),
  },
  descriptionContainer: {
    wordBreak: 'break-word',
  },
}));

const VehicleDetails = ({ match }) => {
  const { params: { id: vehicleId } = {} } = match;

  const intl = useIntl();
  const classes = useStyles();

  const {
    loading: vehicleDetailsLoading,
    data: { vehicle: vehicleDetails = {} } = {},
  } = useQuery(vehicleDetailsQuery, { onError: toastifyError, variables: { vehicleId } });

  const wasteFractionsInfoLabels = useMemo(
    () =>
      vehicleDetails?.wasteCapacity
        ? vehicleDetails?.wasteCapacity?.edges.reduce(
            (newWasteFractionsInfoLabels, { node: vehicleFraction }) => {
              const wasteFractionsTitle =
                vehicleFraction.wasteFraction.wasteTypes?.edges
                  .reduce((title, { node: wasteType }) => [...title, wasteType?.name], [])
                  .join(', ') || '';
              const wasteFractionCategory = vehicleFraction?.wasteFraction?.wasteCategory || '';
              newWasteFractionsInfoLabels.push(
                <InfoLabel
                  title={
                    wasteFractionsTitle?.length
                      ? `${wasteFractionsTitle} - ${wasteFractionCategory}`
                      : '-'
                  }
                  text={
                    <>
                      Max weight: {vehicleFraction.maxAllowedVolume} kg
                      <br />
                      Max volume: {vehicleFraction.maxAllowedWeight} m3
                    </>
                  }
                  key={vehicleFraction.id}
                />,
              );
              return newWasteFractionsInfoLabels;
            },
            [],
          )
        : [],
    [vehicleDetails],
  );

  const projectNamesLinks = useMemo(
    () => (
      <StyledListLink
        key={vehicleDetails?.project}
        to={`/app/settings/${vehicleDetails?.project?.id || `#`}`}
        text={`${vehicleDetails?.project?.name || '-'}`}
        disableFlex
      />
    ),
    [vehicleDetails],
  );

  const driversNamesLinks = useMemo(
    () =>
      vehicleDetails?.drivers
        ? vehicleDetails.drivers.edges.reduce((newDriversNames, { node: driver }) => {
            if (driver && driver.user) {
              newDriversNames.push(
                <StyledListLink
                  key={driver.id}
                  to={`/app/fleet-management/driver-management/${driver.id}`}
                  text={`${driver.user.firstName} ${driver.user.lastName}`}
                  disableFlex
                />,
              );
            }
            return newDriversNames;
          }, [])
        : [],
    [vehicleDetails],
  );

  const depotsNamesLinks = useMemo(
    () =>
      vehicleDetails?.depots
        ? vehicleDetails.depots.edges.reduce((newDepotsNames, { node: depot }) => {
            if (depot) {
              newDepotsNames.push(
                <StyledListLink
                  key={depot.id}
                  to={`/app/fleet-management/locations/${depot.id}`}
                  text={depot.name}
                  disableFlex
                />,
              );
            }
            return newDepotsNames;
          }, [])
        : [],
    [vehicleDetails],
  );

  const wasteStationsNames = useMemo(
    () =>
      vehicleDetails?.depots
        ? vehicleDetails.depots.edges.reduce((newDepotsNames, { node: depot }) => {
            if (depot && depot.depotType === 'waste_station') {
              newDepotsNames.push(
                <StyledListLink
                  key={depot.id}
                  to={`/app/fleet-management/locations/${depot.id}`}
                  text={depot.name}
                  disableFlex
                />,
              );
            }
            return newDepotsNames;
          }, [])
        : [],
    [vehicleDetails],
  );

  const pageTitle = intl.formatMessage({
    id: 'vehicles.details.pageTitle',
    defaultMessage: 'Vehicle details',
  });
  const mainInfoHeadImageAltText = intl.formatMessage({
    id: 'vehicles.details.mainInfoHeadImageAltText',
    defaultMessage: 'Vehicle Image',
  });

  const availableDays = (vehicleDetails.daysAvailable || []).map(
    (day) => daysOptions.find((translatedOption) => translatedOption.value === day)?.label,
  );

  return (
    <Grid container className="h-100">
      <LoadingLayout isLoading={vehicleDetailsLoading} />
      <DetailsSkeleton
        pageTitle={pageTitle}
        endAdornment={<HeaderTab />}
        mainInfoHeadData={{
          imageSrc: vehicleDetails.logo,
          imageAltText: mainInfoHeadImageAltText,
          name: `${vehicleDetails.manufacturer} ${vehicleDetails.model}`,
          description: <></>,
          settingsLink: `${match.url}/edit`,
          children: (
            <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
              <InfoLabel
                title={
                  <FormattedMessage id="vehicle.details.projectLabel" defaultMessage="Project" />
                }
                text={projectNamesLinks}
              />
              <InfoLabel
                title={
                  <FormattedMessage id="vehicle.details.driversLabel" defaultMessage="Drivers" />
                }
                text={driversNamesLinks.reduce(joinElementsCallback(', '), [])}
              />
              <InfoLabel
                title={
                  <FormattedMessage id="vehicle.details.statusLabel" defaultMessage="Status" />
                }
                text={<StyledStatus status={vehicleDetails.status || ''} />}
              />
            </Grid>
          ),
        }}
      >
        <>
          <InfoBox lg={3} xs={12} md={4} title="Identification">
            <InfoLabel
              title={
                <FormattedMessage id="vehicle.details.vehicleIDLabel" defaultMessage="Vehicle ID" />
              }
              text={vehicleDetails.name}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.licensePlateLabel"
                  defaultMessage="License plate"
                />
              }
              text={vehicleDetails.licensePlateNumber}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.manufacturerLabel"
                  defaultMessage="Manufacturer"
                />
              }
              text={vehicleDetails.manufacturer}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.manufacturingYearLabel"
                  defaultMessage="Manufacturing year"
                />
              }
              text={vehicleDetails.manufacturerYear}
            />
            <InfoLabel
              title={<FormattedMessage id="vehicle.details.modelLabel" defaultMessage="Model" />}
              text={vehicleDetails.model}
            />
            <InfoLabel
              title={
                <FormattedMessage id="vehicle.details.trimLabel" defaultMessage="Vehicle trim" />
              }
              text={vehicleDetails.trim}
            />
            <InfoLabel
              title={<FormattedMessage id="vehicle.details.vinLabel" defaultMessage="VIN" />}
              text={vehicleDetails.externalIdentifier}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.vehicleTypeLabel"
                  defaultMessage="Vehicle type"
                />
              }
              text={vehicleDetails.vehicleType?.name || '-'}
            />
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={<FormattedMessage id="vehicle.details.capacityBox" defaultMessage="Capacity" />}
          >
            <div className={classes.sectionText}>
              <FormattedMessage
                id="vehicle.details.wasteFractionsSubtitle"
                defaultMessage="Waste fractions"
              />
            </div>
            <div className={classes.subList}>{wasteFractionsInfoLabels}</div>
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.maxPayloadLabel"
                  defaultMessage="Max payload"
                />
              }
              text={`${vehicleDetails.maxPayload} kg`}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.cargoVolumeLabel"
                  defaultMessage="Cargo volume"
                />
              }
              text={`${vehicleDetails.cargoVolume} m³`}
            />
            <InfoLabel
              title={
                <FormattedMessage id="vehicle.details.fuelTypeLabel" defaultMessage="Fuel type" />
              }
              text={FUEL_TYPE.find((fuelType) => fuelType.value === vehicleDetails.fuelType)?.label}
            />
            {vehicleDetails?.fuelTankCapacity?.map((tank, index) => (
              <InfoLabel
                key={index}
                title={
                  <FormattedMessage
                    id="vehicle.details.fuelTankCapacity"
                    defaultMessage="Fuel tank capacity {numberOfTank}"
                    values={{
                      numberOfTank: index === 0 ? '' : index + 1,
                    }}
                  />
                }
                text={tank}
              />
            ))}
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={<FormattedMessage id="vehicle.details.costBox" defaultMessage="Cost" />}
          >
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.consumptionKmLabel"
                  defaultMessage="Fuel consumption per kilometer"
                />
              }
              text={`${vehicleDetails.fuelUsagePerKm} litres per km`}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.kmCostLabel"
                  defaultMessage="Fuel cost per kilometer"
                />
              }
              text={vehicleDetails.fuelCostPerKm}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.dayCostLabel"
                  defaultMessage="Fixed cost per day"
                />
              }
              text={`${vehicleDetails.fixedCost}`}
            />
          </InfoBox>
          <InfoBox
            lg={3}
            xs={12}
            md={4}
            title={
              <FormattedMessage
                id="vehicle.details.routeInfoBox"
                defaultMessage="Route Information"
              />
            }
          >
            <InfoLabel
              title={<FormattedMessage id="vehicle.details.depotsLabel" defaultMessage="Depots" />}
              text={depotsNamesLinks.reduce(joinElementsCallback(', '), [])}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.wasteStationsLabel"
                  defaultMessage="Waste stations"
                />
              }
              text={wasteStationsNames.reduce(joinElementsCallback(', '), [])}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.workHoursLabel"
                  defaultMessage="Available work hours"
                />
              }
              text={(vehicleDetails.hours || []).join(', ')}
            />
            <InfoLabel
              title={
                <FormattedMessage
                  id="vehicle.details.workDaysLabel"
                  defaultMessage="Available work days"
                />
              }
              text={
                availableDays.length ? availableDays.reduce(joinElementsCallback(', '), []) : '-'
              }
            />
          </InfoBox>
        </>
      </DetailsSkeleton>
    </Grid>
  );
};

VehicleDetails.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(VehicleDetails);
