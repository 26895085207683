import React, { useCallback, useMemo } from 'react';
import { loader } from 'graphql.macro';
import FormikAsyncSelect from '@wastehero/storybook/lib/components/Form/Elements/Select/Async/Formik';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSavedActiveProjects } from '../../../../shared/utils';

const allProjectsQuery = loader('./../../../../graphql/queries/core/all_projects.graphql');

const ProjectSelect = ({ multiple, required, label, name }) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));

  const queryResultFormatter = useCallback(
    (values) =>
      (
        values?.allProjects?.edges.filter((project) =>
          savedActiveProjects.split(',').includes(project.node.id),
        ) || []
      ).map(({ node }) => ({
        key: node.id,
        label: node.name,
        value: node.id,
      })),
    [savedActiveProjects],
  );

  const queryVariablesFormatter = useCallback(
    (search, queryVariables) => ({
      ...queryVariables,
    }),
    [],
  );

  const queryVariables = useMemo(() => ({}), []);

  // const onSelect = useCallback((value, option) => {
  //   console.log('onSelect', value, option);
  // }, []);

  const antd = {
    label,
    labelCol: { span: 24, offset: 0 },
    required,
  };

  return (
    <FormikAsyncSelect
      mode={multiple ? 'multiple' : undefined}
      name={name}
      query={allProjectsQuery}
      queryResultFormatter={queryResultFormatter}
      queryVariablesFormatter={queryVariablesFormatter}
      queryVariables={queryVariables}
      // onSelect={onSelect}
      antd={antd}
    />
  );
};

ProjectSelect.propTypes = {
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

ProjectSelect.defaultProps = {
  multiple: true,
  required: false,
  label: 'Project',
  name: 'project',
};

export default ProjectSelect;
