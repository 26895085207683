import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { NavLink, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useMutation } from '@apollo/client';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { loader } from 'graphql.macro';
import { toggleSidebarOpen } from '../../../actions';
import ActiveProjectSelector from '../../shared/selectActiveProject';
import UserLogo from './userLogo';
import { checkUserIsDemo, getJobTitle } from '../../shared/utils';
import ContactInfoDialog from './contactInfoDialog';

const logoutMutationGQL = loader('./../../graphql/mutations/core/logout.graphql');

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  usernameContainer: {
    maxWidth: 140,
    marginLeft: 10,
  },
  arrowContainer: {
    display: 'flex',
    align: 'center',
    marginRight: 32,
    marginLeft: 10,
  },
  text: {
    fontFamily: theme.variables.defaultFont,
    lineHeight: 'unset',
    display: 'none',
    color: 'inherit',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  userName: {
    fontSize: '14px',
    fontWeight: 500,
  },
  jobTitle: {
    fontSize: '12px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root: {
    border: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    height: '100%',
    flexShrink: 0,
    flexBasis: 70,
    backgroundColor: theme.variables.HeaderColor,
    color: theme.variables.cWhite,
    boxShadow: theme.variables.defaultBoxShadow,
  },
  icons: {
    borderRadius: '50%',
    backgroundColor: theme.variables.cOrange,
    border: `1px solid ${theme.variables.cOrange}`,
    color: theme.variables.cWhite,
    margin: 0,
    '&>button': {
      padding: 8,
    },
  },
  button: {
    color: theme.variables.cWhite,
    textTransform: 'none',
    textAlign: 'left',
  },
  invertedIcon: {
    backgroundColor: 'inherit',
    color: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  disableMargin: {
    padding: 0,
    margin: 0,
  },
  selector: {
    maxWidth: '50%',
  },
  logoContainer: {
    width: 40,
    height: 40,
  },
  divider: {
    backgroundColor: theme.variables.cWhite,
    marginLeft: 32,
    marginRight: 32,
    width: 1,
    height: 40,
  },
  menuItems: {
    textDecoration: 'none',
    color: theme.variables.cRichOcean,
  },
  finishSettingsLink: {
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 500,
    marginLeft: '260px',
    fontSize: '0.875rem',
  },
  logOutButtons: {
    marginRight: 10,
    color: '#024559',
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.variables.VHGreyLight}`,
    boxShadow: theme.variables.defaultBoxShadow,
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const NavigationBar = ({ user, jobTitle, isDemoUser, history, activeProjects }) => {
  const [logoutMutation] = useMutation(logoutMutationGQL);
  const classes = useStyles();
  const [userAnchorEl, setUserAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isUserMenuOpen = Boolean(userAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  /* Check if activeProject settings is completed */
  const toFinishSettings =
    activeProjects.length === 1 &&
    activeProjects[0].containerTypes &&
    (activeProjects[0].containerTypes.edges.length === 0 ||
      activeProjects[0].measurementsettingsSet.edges.length === 0 ||
      activeProjects[0].wasteFractions.edges.length === 0);

  const handleProfileMenuOpen = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setUserAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logout = async () => {
    const {
      data: { refuseToken = {} },
    } = await logoutMutation({});

    if (!refuseToken.token) {
      window.localStorage.removeItem('reactQLJWT');
      history.push('/login');
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <StyledMenu anchorEl={userAnchorEl} id={menuId} open={isUserMenuOpen} onClose={handleMenuClose}>
      <NavLink to="/app/user-management/account-settings" className={classes.menuItems}>
        <MenuItem onClick={() => handleMenuClose()}>
          <AccountCircle className={classes.logOutButtons} />
          <FormattedMessage id="my_account" defaultMessage="My account" />
        </MenuItem>
        <MenuItem onClick={() => logout()}>
          <ExitToAppIcon className={classes.logOutButtons} />
          <FormattedMessage id="logout" defaultMessage="Logout" />
        </MenuItem>
      </NavLink>
    </StyledMenu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>
          <FormattedMessage id="profile" defaultMessage="Profile" />
        </p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classNames(classes.toolbar, classes.grow, classes.root)}>
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
          {isDemoUser && (
            <div
              style={{
                whiteSpace: 'normal',
                color: 'white',
                margin: '20px',
                padding: '10px',
                fontWeight: 600,
                borderRadius: 50,
                marginLeft: '245px',
              }}
            >
              <span>
                {user.daysBeforeDemoExpires}
                &nbsp;
                {<FormattedMessage id="days_of_demo_left" defaultMessage="days left" />}
              </span>
            </div>
          )}
          {isDemoUser && (
            <div>
              <span>
                &nbsp;
                <ContactInfoDialog />
              </span>
            </div>
          )}
          {toFinishSettings && (
            <NavLink
              to={`/app/settings/${activeProjects[0].id}`}
              className={classes.finishSettingsLink}
            >
              <FormattedMessage
                id="remember_to_finish_settings"
                defaultMessage="Remember to finish settings click here"
              />
            </NavLink>
          )}
          <div className={classes.grow} />
          <div className={classes.selector}>
            <ActiveProjectSelector variant="full" />
          </div>
          <Divider orientation="vertical" color="inherit" className={classes.divider} />
          <Button
            className={classNames(classes.sectionDesktop, classes.button)}
            onClick={handleProfileMenuOpen}
          >
            {user.logo ? (
              <div className={classes.logoContainer}>
                <UserLogo logoUrl={user.logo} />
              </div>
            ) : (
              <div
                className={classNames(classes.icons, classes.invertedIcon, classes.logoContainer)}
              >
                <PersonIcon />
              </div>
            )}
            <div className={classes.usernameContainer}>
              <Typography className={classNames(classes.userName, classes.text)}>
                {user.firstName || user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.username}
              </Typography>
              {jobTitle && (
                <Typography className={classNames(classes.jobTitle, classes.text)}>
                  {jobTitle}
                </Typography>
              )}
            </div>
            <div className={classes.arrowContainer}>
              <ArrowDropDownIcon />
            </div>
          </Button>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

NavigationBar.propTypes = {
  jobTitle: PropTypes.string.isRequired,
  user: PropTypes.shape({
    isMaster: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    logo: PropTypes.string,
    username: PropTypes.string.isRequired,
    daysBeforeDemoExpires: PropTypes.number,
  }).isRequired,
  isDemoUser: PropTypes.bool.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  activeProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      containerTypes: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
            }),
          }),
        ),
      }).isRequired,
      wasteFractions: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
            }),
          }),
        ),
      }).isRequired,
      measurementsettingsSet: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
            }),
          }),
        ),
      }).isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  isSidebarOpened: state.settings.isSidebarOpened,
  user: state.settings.user,
  activeProjects: state.settings.activeProjects,
  jobTitle: getJobTitle(state),
  isDemoUser: checkUserIsDemo(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleToggleSidebarOpen: (isSidebarOpened) => {
    dispatch(toggleSidebarOpen(isSidebarOpened));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar));
