import React from 'react';
import { connect, getIn } from 'formik';
import { connect as reduxConnect } from 'react-redux';
import PropTypes from 'prop-types';
import AttachDeviceToContainerPanel from '../../shared/attachDeviceToContainer';
import { handleRemoveAttachedDevice } from '../../shared/utils';
import {
  formikInjectedPropsTypes,
  formikComponentMemoizeValueCheck,
  memoizeFields,
} from '../../../../../../shared/inputs/formik';

const InlineAttachedDevice = ({ allMeasurementSettings, formik, me }) => {
  const { setFieldValue } = formik;

  const attachedDevices = getIn(formik.values, 'attachedDevices');
  const selectedProject = getIn(formik.values, 'selectedProject');
  const containerId = getIn(formik.values, 'id');

  return attachedDevices.map((attachedDevice, index) => (
    <AttachDeviceToContainerPanel
      key={attachedDevice.deviceId}
      pathToField={`attachedDevices[${index}].`}
      itemReadonly
      canUnAssign={!me.isDemo}
      values={attachedDevice}
      handleRemoveAttachedDevice={handleRemoveAttachedDevice(
        index,
        (value) => setFieldValue('attachedDevices', value),
        attachedDevices,
      )}
      allMeasurementSettings={[...allMeasurementSettings, ...attachedDevice.customMeasurement]}
      projectId={selectedProject}
      setFieldValue={setFieldValue}
      containerId={containerId}
    />
  ));
};

InlineAttachedDevice.propTypes = {
  allMeasurementSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  me: PropTypes.shape({
    isDemo: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default reduxConnect(mapStateToProps)(
  connect(
    React.memo(
      InlineAttachedDevice,
      memoizeFields([
        'allMeasurementSettings',
        formikComponentMemoizeValueCheck('attachedDevices'),
        formikComponentMemoizeValueCheck('selectedProject'),
      ]),
    ),
  ),
);
