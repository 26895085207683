import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 'none',
    fontSize: 15,
    color: '#939393',
  },
  body: {
    color: theme.variables.cTextDark,
    fontSize: 18,
  },
  root: {
    height: 27,
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  head: {
    height: '32px !important',
  },
  root: {
    height: 27,
  },
}))(TableRow);

export const StyledTableHead = withStyles(() => ({
  root: {
    background: '#f0f2f7',
  },
}))(TableHead);
