import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import autoSuggestMatch from 'autosuggest-highlight/match';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from './styles';

const SupportSearch = ({ categoryData, history, match }) => {
  const loading = false;
  const classes = useStyles();
  const [searchObjects] = useState([]);

  useEffect(() => {
    categoryData.map((nav) =>
      nav.children.map((subCat) =>
        subCat.keyWords.map((keyword) =>
          searchObjects.push({
            link: subCat.routeLink,
            title: subCat.label,
            keyword,
            heading: nav.label,
            routelink: nav.routeLink,
          }),
        ),
      ),
    );
  }, [searchObjects, categoryData]);

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      filterSelectedOptions
      freeSolo
      className={classes.searchSize}
      options={searchObjects}
      getOptionLabel={(option) => option.keyword}
      onChange={(event, option) => {
        if (categoryData.length < 2) {
          history.push({
            pathname: `${match.url}${option.link}`,
          });
        } else {
          history.push({
            pathname: `${match.url}${option.routelink}${option.link}`,
          });
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          className={classes.searchInput}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(option, { inputValue }) => {
        const parts = parse(option.keyword, autoSuggestMatch(option.keyword, inputValue));
        return (
          <Grid container alignItems="center" className={classes.primaryText}>
            <Grid item xs container direction="column">
              <Typography className={classes.searchText}>
                {option.heading}
                &nbsp;&gt;&nbsp;
                {option.title}
              </Typography>
              <Grid item>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
SupportSearch.propTypes = {
  categoryData: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(SupportSearch);
