import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import useStyles from '../../shared/styles';
import SupportTextPage from '../../shared/supportTextPage';

const AppOverview = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      <SupportTextPage
        title={<FormattedMessage id="app_overview_title" defaultMessage="Mobile App Overview" />}
        texts={[
          <FormattedMessage
            id="app_overview_content_1"
            defaultMessage="WasteHero’s handy mobile installation app can be used to register sensors and attach them to containers, unpair sensors, and change container settings."
          />,
          <FormattedMessage
            id="app_overview_content_2"
            defaultMessage="The WasteHero Installation app is directly connected to your account and projects on the platform. Before you can get started using the app, you’ll need to have created a project and configured its settings on the platform."
          />,
          <FormattedMessage
            id="app_overview_content_3"
            defaultMessage="Your account login details from the platform also act as your login for the mobile installation app."
          />,
        ]}
      />
    </Grid>
  );
};

export default AppOverview;
