import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Transition from '../../../../shared/animations/transition';
import MeasurementSettings from './measurementSettings';

const useStyles = makeStyles(() => ({
  dialogBody: {
    padding: 20,
    minHeight: '60vh',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const RegisterMeasurementDialog = ({ open, onClose, projectId, editId }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth
      scroll="body"
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="register-container-dialog"
      aria-describedby="register-container-description"
    >
      <DialogTitle id="register-container-dialog" className={classes.dialogTitle}>
        <FormattedMessage
          id="adding_measurement_settings"
          defaultMessage="Adding measurement settings"
        />
      </DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <Grid container className="m-0">
          <MeasurementSettings editId={editId} onSubmit={onClose} projectId={projectId} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

RegisterMeasurementDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  editId: PropTypes.string,
};

RegisterMeasurementDialog.defaultProps = {
  editId: '',
};

export default RegisterMeasurementDialog;
