import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ContainerIcon } from '../../../../../images/icons/containers.svg';
import { ReactComponent as WasteStation } from '../../../../../images/icons/baseline-home-24px.svg';

const HelpBar = () => (
  <div className="help-bar">
    <div className="container-icon-inside status-green">
      <div className="icon-holder">
        <ContainerIcon className="icon" />
      </div>
      <span className="status-green">0-25%</span>
    </div>
    <div className="container-icon-inside status-yellow">
      <div className="icon-holder">
        <ContainerIcon className="icon" />
      </div>
      <span className="status-yellow">25-50%</span>
    </div>
    <div className="container-icon-inside status-orange">
      <div className="icon-holder">
        <ContainerIcon className="icon" />
      </div>
      <span className="status-orange">50-75%</span>
    </div>
    <div className="container-icon-inside status-red">
      <div className="icon-holder">
        <ContainerIcon className="icon" />
      </div>
      <span className="status-red">&gt;75%</span>
    </div>
    <div className="container-icon-inside status-grey">
      <div className="icon-holder">
        <ContainerIcon className="icon" />
      </div>
      <span className="status-grey">
        <FormattedMessage id="disabled" defaultMessage="Disabled" />
      </span>
    </div>
    <div className="container-icon-inside blue">
      <div className="icon-holder">
        <WasteStation className="icon" />
      </div>
      <span className="blue">
        <FormattedMessage id="waste_station" defaultMessage="Waste station" />
      </span>
    </div>
  </div>
);

export default HelpBar;
