import { withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PropTypes from 'prop-types';
import React from 'react';

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    backgroundColor: '#f0f2f7',
    padding: theme.spacing(0, 1),
    flexGrow: 1,
    width: '16%',
    color: 'black',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      backgroundColor: '#df9838',
      color: 'black',
      '&:hover': {
        backgroundColor: '#df9838',
        color: 'black',
      },
    },
  },
}))(ToggleButtonGroup);

const HourPickerRow = ({
  format12,
  marker,
  selected,
  handleChange,
  valueModifier,
  labelModifier,
}) => {
  const array6 = Array(6).fill(0);

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid item xs={1}>
        {format12 && marker && (
          <Typography variant="subtitle2" className="p-l-5">
            {marker}
          </Typography>
        )}
      </Grid>
      <Grid item xs={11}>
        <StyledToggleButtonGroup
          className="w-100"
          value={selected}
          onChange={handleChange}
          exclusive
        >
          {array6.map((_, index) => {
            const value = valueModifier * 6 + index + 1;
            const label = labelModifier * 6 + index + 1;
            return (
              <ToggleButton key={value} value={value}>
                {label}
              </ToggleButton>
            );
          })}
        </StyledToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

HourPickerRow.propTypes = {
  format12: PropTypes.bool,
  marker: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.number),
  handleChange: PropTypes.func.isRequired,
  valueModifier: PropTypes.number.isRequired,
  labelModifier: PropTypes.number.isRequired,
};

HourPickerRow.defaultProps = {
  format12: false,
  marker: '',
  selected: [],
};

export default React.memo(HourPickerRow);
