import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { VehicleList, VehicleEdit, VehicleDetails } from '.';
import { getUsersAvailableProjects } from '../../../../../shared/utils';

const VehicleManagementRouter = ({ match, type }) => {
  const usersAvailableProjects = useSelector((state) => getUsersAvailableProjects(state));

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={(props) => <VehicleList {...props} type={type} />}
      />
      {!usersAvailableProjects?.length && <Redirect to={`${match.url}`} />}
      <Route exact path={`${match.url}/add`} component={VehicleEdit} />
      <Route exact path={`${match.url}/:id/edit`} component={VehicleEdit} />
      <Route path={`${match.url}/:id`} component={VehicleDetails} />
    </Switch>
  );
};

VehicleManagementRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  type: PropTypes.string.isRequired,
};

export default VehicleManagementRouter;
