import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from '../../shared/styles';
import FaqContainer from '../../shared/faqContainer';

const AppFaqs = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <FaqContainer
        title={<FormattedMessage id="app_faqs_title" defaultMessage="FAQs" />}
        objects={[
          {
            header: (
              <FormattedMessage
                id="app_faqs_question_1"
                defaultMessage="Which devices can I use to download the WasteHero Install app?"
              />
            ),
            texts: [
              <FormattedMessage
                id="app_faqs_question_1_content_1"
                defaultMessage="The WasteHero Installation app is available for download on your smartphone or tablet."
              />,
              <FormattedMessage
                id="app_faqs_question_1_content_2"
                defaultMessage="<bold>APPLE</bold> - Requires iOS 10.0 or later. Compatible with iPhone, iPad and iPod touch."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
              <FormattedMessage
                id="app_faqs_question_1_content_3"
                defaultMessage="<bold>ANDROID</bold> – Requires Android 4.3 and up."
                values={{ bold: (text) => <b>{text}</b> }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="app_faqs_question_2"
                defaultMessage="How do I scan and activate the sensor using the app?"
              />
            ),
            texts: [
              <FormattedMessage
                id="app_faqs_question_2_content_1"
                defaultMessage="<link>Follow the instructions here</link> to use the WasteHero Install app to scan and activate sensors and assign them to specific containers."
                values={{
                  link: (text) => (
                    <Link className={classes.links} to="app-scan-activate">
                      {text}
                    </Link>
                  ),
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="app_faqs_question_3"
                defaultMessage="How do I download the WasteHero Installation app?"
              />
            ),
            texts: [
              <FormattedMessage
                id="app_faqs_question_4_content_1"
                defaultMessage="Follow the instructions in the <link>Download WasteHero Install App</link> section of this support center."
                values={{
                  link: (text) => (
                    <Link className={classes.links} to="app-download-app">
                      {text}
                    </Link>
                  ),
                }}
              />,
            ],
          },
          {
            header: (
              <FormattedMessage
                id="app_faqs_question_5"
                defaultMessage="My phone isn’t NFC enabled. Can I still activate the sensors using the Installation app?"
              />
            ),
            texts: [
              <FormattedMessage
                id="app_faqs_question_5_content_1"
                defaultMessage="Yes, you can scan the QR code on the sensor or manually enter the Sensor ID."
              />,
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default AppFaqs;
