import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import classNames from 'classnames';
import { Subscription } from '@apollo/client/react/components';
import { useLazyQuery } from '@apollo/client';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import RoomIcon from '@material-ui/icons/Room';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import useSupercluster from 'use-supercluster';
import GoogleMapReact from 'google-map-react';
import MarkerWithModal from './components/markerWithModal';
import ClusterMarker from './components/clusterMarker';
import RouteStopMarker from './components/routeStopMarker/RouteStopMarker';
import styles from './styles';
import StopMarker from './components/stopMarker/StopMarker';
import OrangePin from '../../../images/icons/pinOrange.svg';
import HelpBar from './components/helpBar';
import VehicleIcon from './icons/Vehicle.svg';
import ContainerIcon from './icons/Container.svg';
import DepotIcon from './icons/Depot.svg';
import WasteStationIcon from './icons/WasteStation.svg';
import StreetSweeperIcon from './icons/StreetSweeper.svg';
import { TYPES_CHOICES_VALUES } from '../../main/routes/fleet-management/components/utils';
import SearchPlaces from '../mapDialog/SearchPlaces';
import { getSavedActiveProjects } from '../utils';
import { getMapCenter } from '../utils/settings';
import { setSelectedContainerFromDashboard } from '../../../actions';

const allRoutesQuery = loader('../../graphql/queries/operation_management/all_routes.graphql');
const onVehiclePositionChange = loader('../../graphql/subscriptions/vehiclesPositions.graphql');
const polylines = [];
const ClusterMarkerWrapped = withStyles(styles, { withTheme: true })(ClusterMarker);
const MarkerWithModalWrapped = withStyles(styles, { withTheme: true })(MarkerWithModal);

const useStyles = makeStyles(() => ({
  routeContainerBlock: {
    width: '25px',
    height: '25px',
    borderRadius: '25px',
    backgroundColor: '#fff',
    position: 'relative',
  },
  routeContainerImg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  marker: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
  markerButton: {
    padding: '5px 10px',
    height: '38px',
    '&:hover': {
      opacity: 0.8,
    },
  },
  searchPlaces: {
    position: 'absolute !important',
    zIndex: 1,
    marginTop: 10,
    marginLeft: 15,
    width: '60%',
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

const MapComponent = (props) => {
  const [bounds, setBounds] = useState(null);
  const [stopsBounds, setStopsBounds] = useState(null);
  const [localZoom, setLocalZoom] = useState(10);
  const [mapApi, setMapApi] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [vehiclesIds, setVehiclesIds] = useState(null);
  const [customMarkerClicked, setCustomMarkerClicked] = useState(false);
  const classes = useStyles();

  const {
    mapCenter,
    zoom,
    selectedContainer,
    onSelectContainer,
    containersCluster,
    showHelpBar,
    withCenteredPin,
    onMapClick,
    hideContainerInfoPopup,
    children,
    containerStyle,
    selectedContainerCoords,
    selectedAddressCoords,
    stopDirections,
    orangePinCoords,
    activeProjects,
    showRoutes,
    selectedRoute,
    mapHeight,
    showOnlySelectedRoute,
    selectedRouteCoords,
    setRouteDistance,
    setRouteDuration,
    setRouteInfoLoading,
    dashboardRoutes,
    onlyActiveRoutes,
    showVehicles,
    showDepots,
    showWasteStations,
    showContainersOnRoutes,
    showContainers,
    clickedAddress,
    onLocationSelect,
    useSearchPlace,
    handleSetSelectedContainerFromDashboard,
  } = props;

  const [fullScreen, setFullScreen] = useState(false);
  const [openContainersTable, setOpenContainersTable] = useState(false);

  const [getAllRoutes, { data: { allRoutes = {} } = {} }] = useLazyQuery(allRoutesQuery, {
    variables: {
      active: onlyActiveRoutes,
      activeProjects,
      filterByDay: dashboardRoutes,
    },
    onCompleted: (data) => {
      const vehiclesIdsData = data?.allRoutes?.edges?.map(({ node }) => node.vehicle.id);
      if (vehiclesIdsData) {
        setVehiclesIds(vehiclesIdsData.join(','));
      }
    },
  });

  useEffect(() => {
    if (allRoutes?.edges?.length) {
      setVehiclesIds(allRoutes.edges.map(({ node }) => node.vehicle.id).join(','));
    }
  }, [allRoutes]);

  let centerSet = useMemo(() => {
    if (
      selectedAddressCoords?.length === 2 &&
      selectedAddressCoords[0] &&
      selectedAddressCoords[1]
    ) {
      return selectedAddressCoords;
    }
    return mapCenter || [56.13573, 8.96548];
  }, [selectedAddressCoords, mapCenter]);

  if (selectedContainer) {
    centerSet = [selectedContainer.location.longitude, selectedContainer.location.latitude];
  }

  const chunkStops = (stops) => {
    const arrayLength = stops.length;
    const tempArray = [];

    for (let i = 0; i < arrayLength; i += 26) {
      const myChunk = stops.slice(i, i + 26);

      if (stops.slice(i + 26).length === 1) {
        myChunk.push(stops[stops.length - 1]);
        tempArray.push(myChunk);
        break;
      }

      tempArray.push(myChunk);
    }

    return tempArray;
  };

  const formatRouteDuration = (sec) => {
    if (sec < 90) {
      return `1 min`;
    }

    if (sec < 3600) {
      return `${Math.round(sec / 60)} mins`;
    }

    const hours = Math.floor(sec / 3600);
    const mins = Math.round((sec - hours * 3600) / 60);
    const hourWord = hours > 1 ? 'hours' : 'hour';
    const minWord = mins > 1 ? 'mins' : 'min';

    return mins > 0 ? `${hours} ${hourWord} ${mins} ${minWord}` : `${hours} ${hourWord}`;
  };

  const drawRoute = useCallback(
    (route) => {
      const { edges: stops } = route.stops;
      if (stops.length === 0) {
        setRouteDistance('-');
        setRouteDuration('-');
        return;
      }

      const wayptsSet = new Set();

      stops.forEach(({ node }) => {
        const { location } = node.container ? node.container : node.depot;
        wayptsSet.add(`${location.latitude},${location.longitude}`);
      });

      const wayptsArray = Array.from(wayptsSet);

      if (wayptsArray.length < 2) {
        setRouteDistance('-');
        setRouteDuration('-');
        return;
      }

      const { map, maps } = mapApi;
      const chunkedStops = chunkStops(wayptsArray);

      const routeInfo = {
        distance: 0,
        duration: 0,
      };

      let counter = chunkedStops.length;
      setRouteInfoLoading(true);
      chunkedStops.forEach((stopItems) => {
        const startLocation = stopItems.shift();
        const endLocation = stopItems.pop();

        const waypts = stopItems.map((item) => ({ location: item, stopover: true }));

        directionsService.route(
          {
            origin: startLocation,
            destination: endLocation,
            waypoints: waypts,
            travelMode: maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
          },
          (response, status) => {
            if (status === 'OK') {
              /* eslint-disable */
              var defaultLineColor = '#50B42C';
              response.routes[0].legs.forEach((item) => {
                routeInfo.distance += item.distance.value;
                routeInfo.duration += item.duration.value;
              });
              directionsRenderer.setDirections(response);
              //UHJvamVjdFR5cGU6MTU5 prod alu3zone
              if (route.project.id === 'UHJvamVjdFR5cGU6MTU5') {
                defaultLineColor = '#e58e3e';
              }
              const routePolyline = new maps.Polyline({
                path: response.routes[0].overview_path,
                strokeWeight: 4,
                strokeColor: defaultLineColor,
              });
              routePolyline.setMap(map);
              polylines.push(routePolyline);
              counter -= 1;
              if (counter === 0) {
                setRouteDuration(formatRouteDuration(routeInfo.duration));
                setRouteDistance(`${(routeInfo.distance / 1000).toFixed(1)} km`);
                setRouteInfoLoading(false);
              }
            } else {
              /* eslint-disable-next-line no-console */
              window.console.log(`Directions request failed due to ${status}`);
            }
          },
        );
      });
    },
    [mapApi],
  );

  useEffect(() => {
    polylines.forEach((polyline) => polyline.setMap(null));
    if (mapApi && allRoutes?.edges?.length > 0) {
      allRoutes.edges.forEach(({ node }) => {
        if (showOnlySelectedRoute) {
          if (selectedRoute) {
            if (node.id === selectedRoute.id) {
              drawRoute(node, directionsService, directionsRenderer);
            }
          }
        } else if (showRoutes) {
          drawRoute(node, directionsService, directionsRenderer);
        }
      });
    }
  }, [mapApi, allRoutes, drawRoute, showRoutes, selectedRoute]);

  useEffect(() => {
    if (mapApi?.map && selectedContainerCoords) {
      mapApi.map.setCenter({ lat: selectedContainerCoords[0], lng: selectedContainerCoords[1] });
    }
    if (mapApi?.map && selectedAddressCoords.length) {
      mapApi.map.setCenter({ lat: selectedAddressCoords[0], lng: selectedAddressCoords[1] });
    }
    if (mapApi?.map && selectedRouteCoords) {
      mapApi.map.setCenter({ lat: selectedRouteCoords[0], lng: selectedRouteCoords[1] });
    }
  }, [mapApi, selectedContainerCoords, selectedAddressCoords, selectedRouteCoords]);

  const allRouteStops = useMemo(() => {
    const allStops = [];

    if (!showOnlySelectedRoute) {
      allRoutes?.edges?.forEach(({ node: route }) => {
        route.stops.edges.forEach(({ node: stop }) => {
          if (
            (stop.orderType === 'depot' && showDepots) ||
            (stop.orderType === 'drop_off' && showWasteStations) ||
            (stop.orderType === 'pickup' && showContainersOnRoutes)
          ) {
            allStops.push(stop);
          }
        });
      });
    }

    if (showOnlySelectedRoute && selectedRoute) {
      selectedRoute.stops.edges.forEach(({ node: stop }) => {
        if (
          (stop.orderType === 'depot' && showDepots) ||
          (stop.orderType === 'drop_off' && showWasteStations) ||
          (stop.orderType === 'pickup' && showContainersOnRoutes)
        ) {
          allStops.push(stop);
        }
      });
    }

    return allStops;
  }, [allRoutes, selectedRoute, showDepots, showWasteStations, showContainersOnRoutes]);

  const allRouteContainerIds = useMemo(() => {
    return allRouteStops.filter((node) => node.container).map((node) => node.container.id);
  }, [allRoutes]);

  const points = containersCluster
    ? containersCluster
        .filter(({ node: container }) => !allRouteContainerIds.includes(container.id))
        .map(({ node: container } = {}) => {
          return {
            type: 'Feature',
            properties: {
              cluster: false,
              routeId: container.id,
              container,
            },
            geometry: {
              type: 'Point',
              coordinates: [
                parseFloat(container.location?.longitude),
                parseFloat(container.location?.latitude),
              ],
            },
          };
        })
    : [];

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: localZoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const stopsPoints = useMemo(() => {
    if (!allRouteStops) return [];

    const uniqueCoords = [];

    const routeStops = [];

    allRouteStops.forEach((stop) => {
      const longitude = parseFloat(
        stop.container ? stop.container.location.longitude : stop.depot.location.longitude,
      );
      const latitude = parseFloat(
        stop.container ? stop.container.location.latitude : stop.depot.location.latitude,
      );

      if (!uniqueCoords.includes(`${longitude},${latitude}`)) {
        uniqueCoords.push(`${longitude},${latitude}`);

        routeStops.push({
          type: 'Feature',
          properties: {
            cluster: false,
            routeId: stop.id,
            stop,
          },
          geometry: {
            type: 'Point',
            coordinates: [longitude, latitude],
          },
        });
      }
    });
    return routeStops;
  }, [allRouteStops]);

  const { clusters: stopsClusters } = useSupercluster({
    points: stopsPoints,
    bounds: stopsBounds,
    zoom: localZoom,
    options: { radius: 75, maxZoom: 20 },
  });

  useEffect(() => {
    if (mapApi?.maps) {
      polylines.forEach((polyline) => polyline.setMap(null));
      stopDirections.forEach((stopDirection) => {
        const stopInfo = stopDirection?.node?.direction?.destination;
        if (!stopInfo) {
          return null;
        }

        if (!stopInfo?.latitude || stopInfo?.longitude) {
          return null;
        }

        const geodesicPolyline = new mapApi.maps.Polyline({
          path: [{ lat: stopInfo?.latitude, lng: stopInfo?.longitude }],
          geodesic: true,
          strokeColor: '#629AF8',
          strokeOpacity: 1.0,
          strokeWeight: 8,
        });
        geodesicPolyline.setMap(mapApi.map);
        polylines.push(geodesicPolyline);
        return null;
      });
    }
  }, [mapApi, stopDirections]);

  const drawRouteMarkers = () =>
    stopDirections.map((stopDirection) => {
      const stopInfo = stopDirection?.node?.direction?.destination;
      if (!stopInfo) {
        return null;
      }

      if (!stopInfo?.latitude || stopInfo?.longitude) {
        return null;
      }
      return (
        <StopMarker
          lat={stopInfo.latitude}
          lng={stopInfo.longitude}
          key={`marker${stopDirection?.node?.id}`}
        />
      );
    });

  const OrangePinComponent = () => <img alt="pin" src={OrangePin} />;

  const CenterPin = () => (
    <div anchor="center">
      <img src={OrangePin} style={{ marginBottom: 22 }} alt="" />
    </div>
  );

  const handleSetMapApi = (map, maps) => {
    getAllRoutes();
    setDirectionsService(new maps.DirectionsService());
    setDirectionsRenderer(new maps.DirectionsRenderer());
    setMapApi({ map, maps });
  };

  const getIconDependOnOrderType = (orderType) => {
    switch (orderType) {
      case 'pickup':
        return ContainerIcon;
      case 'depot':
        return DepotIcon;
      case 'drop_off':
        return WasteStationIcon;
      default:
        return null;
    }
  };

  const drawIcon = (stop) => {
    if (
      (stop.orderType === 'depot' && !showDepots) ||
      (stop.orderType === 'drop_off' && !showWasteStations) ||
      (stop.orderType === 'pickup' && !showContainersOnRoutes)
    ) {
      return;
    }
    const node = stop.container ? stop.container : stop.depot;
    const icon = getIconDependOnOrderType(stop.orderType);

    if (showRoutes && icon) {
      return (
        <div
          style={{
            border: node.completedAt ? '3px solid #50B42C' : '3px solid #EC6B6B',
          }}
          className={classNames(classes.routeContainerBlock, classes.marker)}
          lat={node.location.latitude}
          lng={node.location.longitude}
        >
          <img className={classes.routeContainerImg} alt={node.id} src={icon} />
        </div>
      );
    }
    return <></>;
  };

  const handleOnSubscriptionData = async ({
    client,
    subscriptionData: {
      data: {
        onVehiclePositionChange: { vehicle },
      },
    },
  }) => {
    const allRoutesFullQuery = {
      query: allRoutesQuery,
      variables: {
        active: true,
        activeProjects,
      },
    };

    const { allRoutes: allRoutesData } = await client.readQuery(allRoutesFullQuery);

    const newAllRoutesData = allRoutesData.edges.map((route) => {
      if (vehicle.id === route.node.vehicle.id) {
        return {
          node: {
            ...route.node,
            vehicle: {
              ...route.node.vehicle,
              realtimeLocation: vehicle.realtimeLocation,
            },
          },
        };
      }
      return route;
    });

    await client.writeQuery({
      ...allRoutesFullQuery,
      data: {
        allRoutes: {
          ...allRoutesData,
          edges: newAllRoutesData,
        },
      },
    });
  };

  const handleCustomMarkerClicked = () => {
    setCustomMarkerClicked(!customMarkerClicked);
  };

  return (
    <>
      {useSearchPlace && (
        <div className={classes.searchPlaces}>
          <SearchPlaces
            clickedAddress={clickedAddress}
            onLocationSelect={onLocationSelect}
            mapApi={mapApi || {}}
          />
          <IconButton className={classes.markerButton} onClick={handleCustomMarkerClicked}>
            <RoomIcon style={customMarkerClicked ? { color: '#E58E3E' } : {}} />
          </IconButton>
        </div>
      )}
      <div className="mapboxgl-map">
        {vehiclesIds && showRoutes && (
          <Subscription
            subscription={onVehiclePositionChange}
            variables={{ vehiclesIds }}
            onSubscriptionData={handleOnSubscriptionData}
          />
        )}

        <GoogleMapReact
          center={selectedRouteCoords || selectedContainerCoords || centerSet}
          defaultZoom={zoom[0]}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleSetMapApi(map, maps)}
          onClick={(map, evt) => {
            if (onMapClick) {
              onMapClick(map, customMarkerClicked, evt);
            }
          }}
          options={{ scrollwheel: !openContainersTable }}
          onChange={({ zoom: changedZoom, bounds: changedBounds, size }) => {
            if (size.width === window.innerWidth && size.height === window.innerHeight) {
              setFullScreen(true);
            } else {
              setFullScreen(false);
            }

            setLocalZoom(changedZoom);
            setBounds([
              changedBounds.nw.lng,
              changedBounds.se.lat,
              changedBounds.se.lng,
              changedBounds.nw.lat,
            ]);
            setStopsBounds([
              changedBounds.nw.lng,
              changedBounds.se.lat,
              changedBounds.se.lng,
              changedBounds.nw.lat,
            ]);
          }}
          style={{
            height: mapHeight || '100%',
            flex: 1,
            minHeight: mapHeight || '550px',
            ...containerStyle,
          }}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        >
          {withCenteredPin && (
            <CenterPin lat={centerSet?.lat || centerSet[0]} lng={centerSet?.lng || centerSet[1]} />
          )}

          {showContainers &&
            clusters.map((cluster, i) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount, container } = cluster.properties;
              if (isCluster) {
                return (
                  <ClusterMarkerWrapped
                    lat={latitude}
                    lng={longitude}
                    key={cluster.id}
                    fullScreen={fullScreen}
                    setOpenContainersTable={setOpenContainersTable}
                    leaves={supercluster?.getLeaves(cluster.id, Infinity)}
                    pointCount={pointCount}
                    onSelectItem={props.onSelectContainer}
                  />
                );
              }
              return (
                <MarkerWithModalWrapped
                  lat={latitude}
                  lng={longitude}
                  key={`${i}_${container.id}`}
                  container={container}
                  noModal={hideContainerInfoPopup}
                  isActive={selectedContainer && selectedContainer.id === container.id}
                  handleSetSelectedContainerFromDashboard={handleSetSelectedContainerFromDashboard}
                  onSelectContainer={onSelectContainer}
                />
              );
            })}
          {showRoutes &&
            stopsClusters?.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount, stop } = cluster.properties;
              if (isCluster) {
                return (
                  <RouteStopMarker
                    lat={latitude}
                    lng={longitude}
                    key={cluster.id}
                    pointCount={pointCount}
                  />
                );
              }
              return drawIcon(stop);
            })}
          {children && <div className="map-search-field">{children}</div>}
          {mapApi && drawRouteMarkers()}
          {orangePinCoords?.length && (
            <OrangePinComponent lat={orangePinCoords[0]} lng={orangePinCoords[1]} />
          )}
          {showRoutes &&
            !showOnlySelectedRoute &&
            showVehicles &&
            allRoutes?.edges?.map(
              ({ node }) =>
                node.vehicle.realtimeLocation && (
                  <img
                    lat={node.vehicle.realtimeLocation.latitude}
                    lng={node.vehicle.realtimeLocation.longitude}
                    src={
                      node.vehicle.vehicleType.Type === TYPES_CHOICES_VALUES.collector
                        ? VehicleIcon
                        : StreetSweeperIcon
                    }
                    className={classes.marker}
                    alt={node.id}
                  />
                ),
            )}
          {showRoutes &&
            selectedRoute &&
            allRoutes?.edges
              ?.filter(({ node }) => node.id === selectedRoute.id)
              ?.map(
                ({ node }) =>
                  node.vehicle.realtimeLocation && (
                    <img
                      lat={node.vehicle.realtimeLocation.latitude}
                      lng={node.vehicle.realtimeLocation.longitude}
                      className={classes.marker}
                      src={
                        node.vehicle.vehicleType.Type === TYPES_CHOICES_VALUES.collector
                          ? VehicleIcon
                          : StreetSweeperIcon
                      }
                      alt={node.id}
                    />
                  ),
              )}
        </GoogleMapReact>
        {showHelpBar && <HelpBar />}
      </div>
    </>
  );
};
MapComponent.propTypes = {
  withCenteredPin: PropTypes.bool,
  hideContainerInfoPopup: PropTypes.bool,
  showHelpBar: PropTypes.bool,
  onMapClick: PropTypes.func,
  onSelectContainer: PropTypes.func,
  children: PropTypes.element,
  mapCenter: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ]).isRequired,
  zoom: PropTypes.arrayOf(PropTypes.number),
  containersCluster: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        location: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
      }),
    }),
  ),
  selectedContainer: PropTypes.shape({
    id: PropTypes.string,
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
  containerStyle: PropTypes.objectOf(PropTypes.string),
  selectedContainerCoords: PropTypes.arrayOf(PropTypes.number),
  selectedAddressCoords: PropTypes.arrayOf(PropTypes.number),
  stopDirections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  orangePinCoords: PropTypes.arrayOf(PropTypes.number),
  activeProjects: PropTypes.string.isRequired,
  showRoutes: PropTypes.bool,
  mapHeight: PropTypes.string,
  selectedRoute: PropTypes.string,
  showOnlySelectedRoute: PropTypes.bool,
  selectedRouteCoords: PropTypes.arrayOf(PropTypes.number),
  setRouteDistance: PropTypes.func,
  setRouteDuration: PropTypes.func,
  setRouteInfoLoading: PropTypes.func,
  dashboardRoutes: PropTypes.bool,
  onlyActiveRoutes: PropTypes.bool,
  showVehicles: PropTypes.bool,
  showDepots: PropTypes.bool,
  showWasteStations: PropTypes.bool,
  showContainersOnRoutes: PropTypes.bool,
  showContainers: PropTypes.bool,
  clickedAddress: PropTypes.string,
  onLocationSelect: PropTypes.func,
  useSearchPlace: PropTypes.bool,
  handleSetSelectedContainerFromDashboard: PropTypes.func.isRequired,
};

MapComponent.defaultProps = {
  containersCluster: [],
  zoom: [9],
  children: null,
  hideContainerInfoPopup: false,
  withCenteredPin: false,
  showHelpBar: false,
  onMapClick: () => {},
  onSelectContainer: () => {},
  selectedContainer: null,
  containerStyle: {},
  selectedContainerCoords: null,
  selectedAddressCoords: [],
  stopDirections: [],
  orangePinCoords: [],
  showRoutes: false,
  mapHeight: null,
  showOnlySelectedRoute: false,
  selectedRoute: null,
  selectedRouteCoords: null,
  setRouteDuration: () => {},
  setRouteDistance: () => {},
  setRouteInfoLoading: () => {},
  dashboardRoutes: false,
  onlyActiveRoutes: false,
  showVehicles: false,
  showDepots: false,
  showWasteStations: false,
  showContainersOnRoutes: false,
  showContainers: false,
  clickedAddress: null,
  onLocationSelect: () => {},
  useSearchPlace: false,
};

const mapStateToProps = (state) => ({
  mapCenter: getMapCenter(state),
  user: state.settings.user,
  activeProjects: getSavedActiveProjects(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetSelectedContainerFromDashboard: (container) => {
    dispatch(setSelectedContainerFromDashboard(container));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(MapComponent));
