import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import { Tab, Tabs, Typography, Button } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Settings, { getUserSettings } from '../../../../../shared/utils/settings';
import ReusableTable from '../../../../../shared/table/table';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import HeaderRow from '../../../../../shared/table/headerRow';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';
import BlackPlusIcon from '../../../../../../images/icons/plusBlack.svg';
import MapComponent from '../../../../../shared/map';
import ConfirmDeletionDialog from '../../../../../shared/dialog/ConfirmDeletionDialog';
import RouteDetailInformation from '../../components/routeDetailInformation';
import PickupDetailsModal from './components/PickupDetailsModal';
import {
  getRoutesTableFilterableColumns,
  routesTableColumns,
} from '../../../../../shared/utils/constants';
import { getSavedActiveProjects, toastifyError } from '../../../../../shared/utils';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';
import { StyledListLink } from '../../../../../shared/link/link';
import LoadingLayout from '../../../../../shared/loading';
import { TYPES_CHOICES_VALUES } from '../../../fleet-management/components/utils';
import HeaderTab from './components/HeaderTab';

const allRoutes = loader(
  './../../../../../graphql/queries/operation_management/all_routes.graphql',
);
const routeQuery = loader('./../../../../../graphql/queries/operation_management/route.graphql');
const allRoutesSettings = loader(
  './../../../../../graphql/queries/operation_management/all_route_settings.graphql',
);

const deleteRouteSettingsMutation = loader(
  './../../../../../graphql/mutations/operation_management/delete_route_settings.graphql',
);

const generateRoutesMutation = loader(
  './../../../../../graphql/mutations/operation_management/generate_routes.graphql',
);

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    minHeight: 'calc(100% - 74px)',
  },
  indicator: {
    display: 'none',
  },
  scrollButtonsRoot: {
    '& > .Mui-disabled': {
      opacity: 1,
    },
  },
  selected: {
    border: '1px solid',
    borderColor: theme.variables.cAntiFlashWhite,
    backgroundColor: theme.variables.cAntiFlashWhite,
    borderRadius: '4px',
  },
  addTabRoot: {
    minWidth: 52,
    minHeight: 44,
    backgroundColor: '#F8F9FD',
    borderRadius: '22px 22px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
  },
  tabRoot: {
    minWidth: 0,
    textTransform: 'none',
  },
  routeDescriptionContainer: {
    backgroundColor: theme.variables.VHGreyBackground,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
    position: 'relative',
  },
  descriptionTitle: {
    color: theme.variables.cTextDark,
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '32px',
    userSelect: 'none',
    marginBottom: '24px',
  },
  mapWrapper: {
    position: 'relative',
    borderRadius: 2,
    overflow: 'hidden',
  },
  settingsEdit: {
    maxWidth: '70%',
    right: 0,
  },
  tabTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  dateTabs: {
    marginLeft: '20px',
    minHeight: '0',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  dateTab: {
    '&.Mui-selected': {
      backgroundColor: '#ccc',
    },
    opacity: 1,
    minWidth: 0,
    fontSize: '14px',
    fontWeight: 500,
    color: '#474e62',
    width: '100px',
    height: '36px',
    padding: 0,
    minHeight: 0,
    borderRadius: '4px',
    backgroundColor: theme.variables.cAntiFlashWhite,
    marginRight: '4px',
    textTransform: 'none',
  },
  generateRoutesBtn: {
    height: '30px',
    marginRight: '15px',
    textTransform: 'none',
    fontSize: '0.88rem',
    fontWeight: 'bold',
  },
}));

const StyledTab = withStyles((theme) => ({
  root: {
    background: theme.variables.cAntiFlashWhite,
    borderRadius: '22px 22px 0 0',
    marginRight: 1,
    height: 44,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 14,
    lineHeight: '16px',
    color: theme.variables.cSlate,
  },
  selected: {
    backgroundColor: theme.variables.cSnow,
    color: `${theme.variables.cEmperor} !important`,
  },
}))(Tab);

const mockTabs = [
  {
    name: 'Fixed Routes',
    id: 'fixed',
  },
];

const RoutesMap = ({
  userLayoutConfig,
  handleConfigChange,
  history,
  settings,
  savedActiveProjects,
  match,
  me,
  type,
  projectIds,
}) => {
  const { params: { tabId: newRouteSettingsID } = {} } = match;

  const classes = useStyles();
  const intl = useIntl();
  const [routeTabs, setRouteTabs] = useState(mockTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [isPickupTimesModalOpen, setIsPickupTimesModalOpen] = useState(false);
  const [routeSettingsToDelete, setRouteSettingsToDelete] = useState(null);
  const [routeDistance, setRouteDistance] = useState(null);
  const [routeDuration, setRouteDuration] = useState(null);
  const [routeInfoLoading, setRouteInfoLoading] = useState(false);
  const [period, setPeriod] = useState('month');

  const getPeriodRange = useCallback(() => {
    const dateRange = {
      startDate: moment().startOf('day'),
      endDate: null,
    };

    switch (period) {
      case 'day':
        dateRange.endDate = moment().endOf('day');
        break;
      case 'week':
        dateRange.endDate = moment().add(1, 'week');
        break;
      case 'month':
        dateRange.endDate = moment().add(1, 'month');
        break;
      case 'quarter':
        dateRange.endDate = moment().add(3, 'months');
        break;
      case 'year':
        dateRange.endDate = moment().add(1, 'year');
        break;
      default:
        break;
    }

    return dateRange;
  }, [period]);

  const [generateRoutes] = useMutation(generateRoutesMutation);

  const isUserAdmin = me.isAdmin || me.isSuperuser || me.isReseller || me.isMaster;

  const { data: routeSettings, refetch } = useQuery(allRoutesSettings, {
    variables: {
      activeProjects: savedActiveProjects,
    },
  });

  const handleGenerateRoutes = useCallback(
    (e) => {
      e.preventDefault();
      generateRoutes({ variables: { projectIds } })
        .then(() => {
          toast.info(
            intl.formatMessage({
              id: 'toast.generating_routes_started',
              defaultMessage: 'Generating routes successfully started',
            }),
          );
        })
        .catch((error) => {
          toastifyError(error);
        });
    },
    [projectIds, generateRoutes, intl],
  );

  const [deleteRouteSettings] = useMutation(deleteRouteSettingsMutation, {
    refetchQueries: [
      {
        query: allRoutesSettings,
        variables: {
          activeProjects: savedActiveProjects,
        },
      },
    ],
    onCompleted: () => {
      setActiveTab(0);
      setRouteSettingsToDelete(null);
      toast.info(
        intl.formatMessage({
          id: 'toast.route_settings_removed',
          defaultMessage: 'Route settings successfully removed',
        }),
      );
    },
  });

  useEffect(() => {
    refetch({
      activeProjects: savedActiveProjects,
    });
  }, [refetch, savedActiveProjects, type]);

  useEffect(() => {
    const routeSettingsEdges = routeSettings?.allRouteSettings?.edges;
    if (routeSettingsEdges?.length && type !== TYPES_CHOICES_VALUES.streetSweeper) {
      setRouteTabs(
        mockTabs.concat(
          routeSettingsEdges.map((routeSettingsEdge) => ({
            id: routeSettingsEdge.node.id,
            name: routeSettingsEdge.node.name,
            projectId: routeSettingsEdge.node.project?.id,
          })),
        ),
      );
    } else {
      setRouteTabs(mockTabs);
      setActiveTab(0);
    }
  }, [routeSettings, type]);

  useEffect(() => {
    if (newRouteSettingsID) {
      const tabIndex = routeTabs.findIndex((tab) => tab.id === newRouteSettingsID);
      if (tabIndex > -1) setActiveTab(tabIndex);
    }
  }, [routeTabs, newRouteSettingsID]);

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    routesTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );

  const handleDeleteRouteSettings = () => {
    deleteRouteSettings({
      variables: {
        selfId: routeSettingsToDelete.id,
      },
    });
  };

  const centerCoords = useMemo(() => {
    const [, firstStop] = selectedRoute?.stops?.edges || [];
    return firstStop && firstStop?.node?.direction?.destination
      ? [
          firstStop?.node?.direction?.destination?.latitude,
          firstStop?.node?.direction?.destination?.longitude,
        ]
      : [56.13573, 8.96548];
  }, [selectedRoute]);

  const [getRouteDetails, { data: detailsData }] = useLazyQuery(routeQuery, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const detailsSerializer = ({ route }) => ({
      id: route.id,
      vehicle: {
        id: route.vehicle.id,
        name: route.vehicle.name,
      },
      driver: {
        id: route.driver?.id,
        fullName: `${route.driver?.user?.firstName} ${route.driver?.user?.lastName}`,
        email: route.driver?.user?.email,
      },
      project: route?.project,
      stopsCount: route?.stopsCount,
      distance: settings[route?.project?.id]?.getDistance(route.totalTraveledDistance),
      startTime: settings[route?.project?.id]?.getTime(route.startTime),
      endTime: settings[route?.project?.id]?.getTime(route.endTime),
      wasteFractions: route?.wasteFractions?.map((wasteFraction) => ({
        wasteTypes: wasteFraction?.wasteTypes?.edges?.map((wasteType) => ({
          name: `${wasteType?.node?.name || ''} - ${wasteFraction?.wasteCategory || ''}`,
          id: wasteType?.node?.id,
        })),
      })),
      wasteStation: route?.stops?.edges
        .filter((stopNode) => stopNode?.node?.depot?.depotType === 'waste_station')
        .map((stopNode) => ({
          name: stopNode?.node?.depot?.name,
          id: stopNode?.node?.depot?.id,
        })),
      depot: route?.stops?.edges
        .filter((stopNode) => stopNode?.node?.depot?.depotType === 'depot')
        .map((stopNode) => ({
          name: stopNode?.node?.depot?.name,
          id: stopNode?.node?.depot?.id,
        })),
      stops: route?.stops,
    });
    if (detailsData) {
      setSelectedRoute(detailsSerializer(detailsData));
    }
  }, [detailsData, settings, setSelectedRoute]);

  const queryExtraVariables = useMemo(
    () => ({
      name: searchString,
      startDate: period === 'all_time' ? null : getPeriodRange().startDate.format('YYYY-MM-DD'),
      endDate: period === 'all_time' ? null : getPeriodRange().endDate.format('YYYY-MM-DD'),
      routeSettings: routeTabs[activeTab].id !== 'fixed' ? routeTabs[activeTab].id : null,
      routeSettingsId_Isnull: routeTabs[activeTab].id === 'fixed',
      activeProjects: savedActiveProjects,
      Type: type,
    }),
    [searchString, routeTabs, activeTab, savedActiveProjects, type, getPeriodRange, period],
  );

  const updateCache = useCallback(async () => {
    //  ToDo remove obj from cache
  }, []);

  const routesSerializer = ({ allRoutes: { edges: arr = [], totalCount = 0 } = {} }) => ({
    totalCount,
    items: arr.map(({ node }) => ({
      id: node.id,
      vehicleName: (
        <StyledListLink
          text={node.vehicle?.name}
          to={`/app/fleet-management/${
            node?.vehicle?.vehicleType.Type === TYPES_CHOICES_VALUES.streetSweeper
              ? 'street-sweeper-vehicle'
              : 'collector-vehicle'
          }/${node.vehicle?.id}`}
          disableFlex
        />
      ),
      driverFullName: (
        <StyledListLink
          text={
            `${node.driver?.user?.firstName} ${node.driver?.user?.lastName}`.trim() ||
            node.driver?.user?.email
          }
          to={`/app/fleet-management/driver-management/${node.driver?.id}`}
          disableFlex
        />
      ),
      projectName: (
        <StyledListLink
          text={node.project.name}
          to={`/app/settings/${node.project?.id}=/project-settings`}
          disableFlex
        />
      ),
      stopNumber: node.stopsCount,
      distance: settings[node?.project?.id]?.getDistance(node.totalTraveledDistance),
      startTime: settings[node?.project?.id]?.getTime(node.startTime),
      endTime: settings[node?.project?.id]?.getTime(node.endTime),
      scheduledDay: node.scheduledDay,
    })),
  });

  const cellsConfig = [
    {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getRoutesTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'id',
      label: <FormattedMessage id="route_id" defaultMessage="Route ID" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'vehicleName',
      label: <FormattedMessage id="vehicle" defaultMessage="Vehicle" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'driverFullName',
      label: <FormattedMessage id="Driver" defaultMessage="Driver" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'projectName',
      label: <FormattedMessage id="projectName" defaultMessage="Project Name" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'stopNumber',
      label: <FormattedMessage id="stops" defaultMessage="Stops" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'scheduledDay',
      label: <FormattedMessage id="scheduledDay" defaultMessage="Scheduled day" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'startTime',
      label: <FormattedMessage id="start_time" defaultMessage="Starts at" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'endTime',
      label: <FormattedMessage id="end_time" defaultMessage="Ends at" />,
      numeric: false,
      disablePadding: false,
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tableTabs = useMemo(
    () => (
      <>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label="settings tab"
          variant="scrollable"
          classes={{
            indicator: classes.indicator,
            root: classes.scrollButtonsRoot,
          }}
        >
          {type !== TYPES_CHOICES_VALUES.streetSweeper &&
            routeTabs?.map((routeTab, index) => (
              <StyledTab
                key={routeTab.id}
                label={
                  <div className={classes.tabTitle}>
                    {routeTab.name}
                    {(isUserAdmin || me.ownProjectsIds.includes(routeTab.projectId)) &&
                      activeTab === index &&
                      routeTab.id !== 'fixed' && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              history.push(`/app/routes/set-route-settings/${routeTab.id}/edit`);
                            }}
                          >
                            <SettingsIcon className={classes.settingsEdit} />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => setRouteSettingsToDelete(routeTab)}
                          >
                            <DeleteIcon className={classes.settingsEdit} />
                          </IconButton>
                        </>
                      )}
                  </div>
                }
                classes={{
                  selected: classes.selected,
                  root: classes.tabRoot,
                }}
              />
            ))}
        </Tabs>
        {isUserAdmin || me.ownProjectsIds.length ? (
          <>
            <Button
              className={classes.addTabRoot}
              onClick={() => history.push('/app/routes/set-route-settings')}
              disabled={type === TYPES_CHOICES_VALUES.streetSweeper}
            >
              <img src={BlackPlusIcon} alt="+" />
            </Button>
          </>
        ) : (
          <> </>
        )}
      </>
    ),
    [
      activeTab,
      classes.addTabRoot,
      classes.indicator,
      classes.scrollButtonsRoot,
      classes.selected,
      classes.settingsEdit,
      classes.tabRoot,
      classes.tabTitle,
      me.ownProjectsIds,
      history,
      routeTabs,
      setRouteSettingsToDelete,
      isUserAdmin,
      type,
    ],
  );

  const pageTitle = intl.formatMessage({
    id: 'route_page_name',
    defaultMessage: 'Overview - route list',
  });

  const selectTableRowCallback = (routeId) => {
    if (routeId) {
      getRouteDetails({
        variables: {
          id: routeId,
        },
      });
    } else {
      setSelectedRoute(null);
    }
  };

  const headCellsToDisplay = cellsConfig.filter((cell) =>
    activeTableColumns.some((tableColumn) => tableColumn === cell.id),
  );

  const selectedRouteCoords = useMemo(() => {
    if (selectedRoute?.stops?.edges) {
      const coordinates = {
        maxLat: 0,
        maxLng: 0,
        minLat: Infinity,
        minLng: Infinity,
      };

      selectedRoute.stops.edges.forEach(({ node }) => {
        const location = node.container?.location || node.depot?.location;

        if (location) {
          coordinates.maxLat =
            location.latitude > coordinates.maxLat ? location.latitude : coordinates.maxLat;

          coordinates.minLat =
            location.latitude < coordinates.minLat ? location.latitude : coordinates.minLat;

          coordinates.maxLng =
            location.longitude > coordinates.maxLng ? location.longitude : coordinates.maxLng;

          coordinates.minLng =
            location.longitude < coordinates.minLng ? location.longitude : coordinates.minLng;
        }
      });

      if (coordinates.maxLat && coordinates.minLat && coordinates.maxLng && coordinates.minLng) {
        return [
          (coordinates.maxLat + coordinates.minLat) / 2,
          (coordinates.maxLng + coordinates.minLng) / 2,
        ];
      }
    }
    return null;
  }, [selectedRoute]);

  return (
    <Grid container spacing={0} className="containers h-100">
      <div className="w-100">
        <HeaderRow pageTitle={pageTitle}>
          <HeaderTab />
        </HeaderRow>
      </div>

      <Grid container className={classes.contentContainer}>
        <Grid xs={12} sm={12} md={12} lg={8} xl={8} item>
          <Tabs
            onChange={(e, value) => setPeriod(value)}
            value={period}
            className={classes.dateTabs}
            aria-label="tabs.date_tabs"
          >
            <Tab
              label={intl.formatMessage({
                id: 'tabs.day',
                defaultMessage: 'Day',
              })}
              value="day"
              className={classes.dateTab}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.week',
                defaultMessage: 'Week',
              })}
              value="week"
              className={classes.dateTab}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.month',
                defaultMessage: 'Month',
              })}
              value="month"
              className={classes.dateTab}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.quarter',
                defaultMessage: 'Quarter',
              })}
              value="quarter"
              className={classes.dateTab}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.year',
                defaultMessage: 'Year',
              })}
              value="year"
              className={classes.dateTab}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.all_time',
                defaultMessage: 'All time',
              })}
              value="all_time"
              className={classes.dateTab}
            />
          </Tabs>
          <ReusableTable
            userLayoutConfig={userLayoutConfig}
            updateUserConfig={handleConfigChange}
            cellsConfig={headCellsToDisplay}
            autoCompleteQuery={allRoutes}
            queryExtraVariables={queryExtraVariables}
            // TODO: add delete mutation
            deleteItemMutation={() => {}}
            query={allRoutes}
            itemsSerializer={routesSerializer}
            updateCache={updateCache}
            tableTabs={tableTabs}
            chooseLogoSize={false}
            noLogoLink
            searchSize={3}
            noCheckboxes
            tableContainerStyles={{ padding: '0 32px 32px 32px' }}
            tableComponentWrapperStyles={{ paddingTop: '20px' }}
            multiSelectable={false}
            selectTableRowCallback={selectTableRowCallback}
            activeTableColumns={activeTableColumns}
            excludeEditOption
            setSearchString={setSearchString}
            tableFontSize={14}
            paginationClass="all-routes-pagination"
            isSearchAutocomplete={false}
            noActionMenu
            queryName="allRoutes"
            generateButton={
              <Button
                variant="contained"
                color="secondary"
                className={classNames({ hidden: !me.isBeta }, classes.generateRoutesBtn)}
                onClick={handleGenerateRoutes}
              >
                <FormattedMessage id="generate" defaultMessage="Generate" />
              </Button>
            }
          />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          className={classes.routeDescriptionContainer}
          item
        >
          <LoadingLayout isLoading={routeInfoLoading} />

          <Typography className={classes.descriptionTitle}>
            <FormattedMessage id="route_description" defaultMessage="Route description" />
          </Typography>
          <div className={classes.mapWrapper}>
            <MapComponent
              hideContainerInfoPopup
              containerStyle={{ minHeight: '321px' }}
              selectedContainerCoords={centerCoords}
              zoom={[10]}
              selectedRoute={selectedRoute}
              showRoutes
              selectedRouteCoords={selectedRouteCoords}
              stopDirections={selectedRoute?.stops?.edges}
              showOnlySelectedRoute
              setRouteDistance={setRouteDistance}
              setRouteDuration={setRouteDuration}
              setRouteInfoLoading={setRouteInfoLoading}
              showVehicles
              showContainersOnRoutes
              showDepots
              showWasteStations
            />
            {/* TODO uncomment when edit would be needed */}
            {/* <EditRouteButton
              color="secondary"
              variant="contained"
            >
              <img src={PencilWhiteIcon} alt="edit" />
            </EditRouteButton> */}
          </div>
          <Grid container justify="center">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setIsPickupTimesModalOpen(true)}
              disabled={!selectedRoute}
              style={{
                textTransform: 'none',
                borderWidth: 2,
                borderRadius: 24,
                marginBottom: 40,
                marginTop: 24,
                fontWeight: 'bold',
                fontSize: 18,
                padding: '6px 24px',
              }}
            >
              <FormattedMessage id="seePickUpTimes" defaultMessage="See pickup times" />
            </Button>
          </Grid>
          <PickupDetailsModal
            isPickupTimesModaOpen={isPickupTimesModalOpen}
            setIsPickupTimesModaOpen={setIsPickupTimesModalOpen}
            selectedRouteStops={selectedRoute?.stops?.edges}
            projectId={selectedRoute?.project?.id}
            settings={settings}
          />
          <RouteDetailInformation
            selectedRoute={selectedRoute}
            routeDistance={routeDistance}
            routeDuration={routeDuration}
            routeInfoLoading={routeInfoLoading}
          />
          <ConfirmDeletionDialog
            open={!!routeSettingsToDelete?.id}
            onConfirm={handleDeleteRouteSettings}
            onClose={() => setRouteSettingsToDelete(null)}
            title={routeSettingsToDelete?.name || ''}
            helpText={intl.formatMessage({
              id: 'help_text.remove_item',
              defaultMessage: 'It will remove item from your project!',
            })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

RoutesMap.propTypes = {
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  settings: PropTypes.objectOf(PropTypes.instanceOf(Settings).isRequired).isRequired,
  savedActiveProjects: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string).isRequired,
    PropTypes.string,
  ]).isRequired,
  me: PropTypes.shape({
    isAdmin: PropTypes.bool,
    isMaster: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isReseller: PropTypes.bool,
    isBeta: PropTypes.bool,
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  type: PropTypes.string.isRequired,
  projectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
  settings: getUserSettings(state),
  savedActiveProjects: getSavedActiveProjects(state),
  projectIds: state.settings.user.activeProjects.edges.map(({ node }) => node.id),
});

export default connect(mapStateToProps)(withUserLayoutConfig('route')(RoutesMap));
