import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { AppBar, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import FavIcon from '../../../images/favicon-32x32.png';

const useStyles = makeStyles((theme) => ({
  appbar: {
    paddingRight: 20,
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.15)',
    paddingLeft: 40,
    fontWeight: 'bold',
    background: theme.variables.cAntiFlashWhite,
    marginBottom: 20,
    paddingTop: 6,
    height: 74,
  },
  pageTitle: {
    color: theme.variables.cTextDark,
    fontWeight: 'bold',
    fontSize: 26,
    marginLeft: 8,
    userSelect: 'none',
  },
  appBarContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    height: '100%',
  },
}));

const HeaderRow = ({ pageTitle, children, endAdornment }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {pageTitle}
          &nbsp; | &nbsp;
          {intl.formatMessage({ id: 'wastehero_platform', defaultMessage: 'WasteHero Platform' })}
        </title>
        <meta name="description" content="WasteHero" />
        <link rel="icon" type="image/png" href={FavIcon} sizes="16x16" />
      </Helmet>
      <AppBar position="static" className={classes.appbar}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          direction="row"
          className={classes.appBarContainer}
        >
          <Grid container item xs={12} md={5} alignItems="center" justify="space-between">
            <Typography className={classes.pageTitle}>{pageTitle}</Typography>
          </Grid>
          {endAdornment}
          {children}
        </Grid>
      </AppBar>
    </>
  );
};

HeaderRow.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.element,
  endAdornment: PropTypes.element,
};

HeaderRow.defaultProps = {
  children: null,
  endAdornment: null,
};

export default HeaderRow;
