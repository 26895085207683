import * as Yup from 'yup';
import { maxLengthStringMessage } from '../../../../../shared/utils';
import {
  BODY_MECHANIZATION_TYPE_VALUES,
  LIFT_MECHANIZATION_TYPE_VALUES,
  TYPES_CHOICES_VALUES,
} from '../../components/utils';

const vehicleTypeEditValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().max(35, maxLengthStringMessage(35)).required(),
    Type: Yup.string().required(),
    crane: Yup.string()
      .when('Type', {
        is: TYPES_CHOICES_VALUES.collector,
        then: Yup.string(),
        otherwise: Yup.string().notRequired(),
      })
      .when('liftingMechanization', {
        is: LIFT_MECHANIZATION_TYPE_VALUES.crane,
        then: Yup.string().required(),
      }),
    body: Yup.string().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    liftingMechanization: Yup.string().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    bodyMechanization: Yup.string().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    loadingLocation: Yup.string().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    compactor: Yup.string()
      .when('bodyMechanization', {
        is: BODY_MECHANIZATION_TYPE_VALUES.withCompactor,
        then: Yup.string().required(),
      })
      .when('Type', {
        is: TYPES_CHOICES_VALUES.collector,
        then: Yup.string(),
        otherwise: Yup.string().notRequired(),
      }),
  });

export default vehicleTypeEditValidationSchema;
