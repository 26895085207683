import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import WhenNotFound from '../../../shared/whenNotFound';
import SupportWelcome from './supportWelcome';
import SupportCategoryPage from './supportCategoryPage';
import navData from './navData';

const SupportRouter = ({ match, tabSelect }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={() => <SupportWelcome tabSelect={tabSelect} />} />
    {navData.map((nav) => (
      <Route
        path={`${match.url}${nav.routeLink}`}
        key={nav.name}
        component={() => <SupportCategoryPage supportCatName={nav.name} tabSelect={tabSelect} />}
      />
    ))}
    <Route component={WhenNotFound} />
  </Switch>
);
SupportRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  tabSelect: PropTypes.func.isRequired,
};

export default withRouter(SupportRouter);
