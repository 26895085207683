import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Placeholder from '../../../images/WasteHero_man.svg';

const useStyles = makeStyles({
  img: {
    position: 'absolute',
    bottom: 35,
    right: 35,
    zIndex: 10000,
  },
  dummyDiv: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginBottom: 350,
    marginRight: 300,
  },
});

const Mascot = ({ displayMascot }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.dummyDiv} tourid="mascot" />
      {displayMascot && (
        <div className={classes.img}>
          <img src={Placeholder} alt="WasteHero" />
        </div>
      )}
    </>
  );
};

Mascot.propTypes = {
  displayMascot: PropTypes.bool,
};

Mascot.defaultProps = {
  displayMascot: false,
};
export default Mascot;
