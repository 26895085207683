import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import ReactRouterPropTypes from 'react-router-prop-types';
import { loader } from 'graphql.macro';
import SubmitButtonsSet, { actionHandler } from '../../../../../shared/buttons/submitButtons';
import HeaderRow from '../../components/headerRow';
import TooltippedUserControl from '../../../../../shared/tooltip/TooltippedUserControl';
import { FormikSelect } from '../../../../../shared/inputs/formik';
import FormikTextField from '../../../../../shared/inputs/formik/FormikTextField';
import { getJobTitles, JOB_TITLE_VALUES } from '../../../../../shared/utils/constants';
import {
  allProjectLevelUserPermissions,
  getAvailableUserPermissions,
  getUserPermissionForProject,
  MASTER,
  SUPER,
  USER,
  getActiveProjects,
} from '../../../../../shared/utils/settings';
import FixedLoadingLayout from '../../../../../shared/loading/fixed';
import SelectProject from '../../../../../shared/apiPopulatedSelects/selectProjects';
import useRegularUser from '../../../../../shared/hooks/useRegularUser';

const assignUser = loader('./../../../../../graphql/mutations/core/assign_user.graphql');

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  form: {
    margin: 0,
    padding: theme.spacing(5),
    flexWrap: 'nowrap',
    flexBasis: '100%',
    flexGrow: 0,
  },
  title: {
    color: theme.variables.cEmperor,
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
  },
}));

const DriverInvite = ({ me, history, activeProjects }) => {
  const intl = useIntl();
  const classes = useStyles();

  const JOB_TITLES = getJobTitles(intl);
  const [assignUserMutation] = useMutation(assignUser);
  const [isRegularUser] = useRegularUser();

  const pageTitle = intl.formatMessage({
    id: 'drivers.settings',
    defaultMessage: 'Invite driver',
  });

  const handleSubmit = useCallback(
    (values, { setSubmitting, resetForm, setFieldError }) => {
      const isMaster = values.role === MASTER;
      const isSuper = values.role === SUPER;
      return assignUserMutation({
        variables: {
          projectIds: [values.projectId],
          isSuper,
          isMaster,
          isDemo: false,
          email: values.email,
          jobTitle: values.jobTitle,
        },
      })
        .then(({ data: { assignUser: { userProject: { user } = {} } = {} } = {} }) => {
          toast.info(
            intl.formatMessage({
              id: 'toast.driver_assigned',
              defaultMessage: 'Driver assigned to project',
            }),
          );
          return {
            action: values.action,
            obj: user,
          };
        })
        .then(
          actionHandler({
            reInitForm: () => resetForm(),
            resetForm: () => resetForm(),
            baseUrl: '/app/fleet-management/driver-management',
            history,
            onEditPage: false,
            toDetail: false,
          }),
        )
        .catch((e) => {
          if (e.graphQLErrors && e.graphQLErrors.length) {
            e.graphQLErrors.forEach((graphQLError) => {
              if (graphQLError?.context?.field) {
                setFieldError(graphQLError.context.field, graphQLError.message);
              }
            });
          }
          setSubmitting(false);
        });
    },
    [assignUserMutation, history, intl],
  );

  const permissionForProject = getUserPermissionForProject(me);
  const availableUserPermissions = getAvailableUserPermissions(permissionForProject);
  const renderAllGroups = allProjectLevelUserPermissions.filter((group) =>
    availableUserPermissions.includes(group.value),
  );
  const initialValues = {
    projectId: activeProjects?.length > 1 ? '' : activeProjects[0],
    role: USER,
    jobTitle: JOB_TITLE_VALUES.driver,
    email: '',
    action: '',
  };
  return (
    <Grid container>
      <HeaderRow pageTitle={pageTitle} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          role: Yup.string().required(),
          projectId: Yup.string().required(),
          jobTitle: Yup.string().required(),
          email: Yup.string()
            .email(
              <FormattedMessage id="validation.invalid_email" defaultMessage="Invalid email" />,
            )
            .required(),
        })}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <Grid container className={classes.root} spacing={3}>
              <FixedLoadingLayout open={isSubmitting} />
              {isRegularUser && (
                <Grid item xs={12} className="text-align-center">
                  <FormattedMessage
                    id="no_permission_add_driver"
                    defaultMessage="You don’t have permission to add driver"
                  >
                    {(text) => <span className="no-permission-text">{text}</span>}
                  </FormattedMessage>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography className={classes.title}>Add driver</Typography>
              </Grid>
              <Grid container spacing={3} item xs={12} md={6}>
                <SelectProject md={12} name="projectId" />
                <Grid item xs={12}>
                  <TooltippedUserControl
                    tooltipText={
                      <FormattedMessage
                        id="tooltip.account_type"
                        defaultMessage="Select type of account to be created. A regular user has basic access to the platform features. The project owner can create containers and change project settings. A partner can have the project owners accessibility and can create new projects."
                      />
                    }
                  />
                  <FormikSelect
                    name="role"
                    label={intl.formatMessage({
                      id: 'label.account_type',
                      defaultMessage: 'Select account type',
                    })}
                    required
                    valuesList={renderAllGroups}
                    filledStyle
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TooltippedUserControl
                    tooltipText={
                      <FormattedMessage
                        id="tooltip.jobTitle"
                        defaultMessage="Choose a job title for the person to be invited"
                      />
                    }
                    tooltipStyle="w-100 h-100"
                  >
                    <FormikSelect
                      label={intl.formatMessage({
                        id: 'label.jobTitle',
                        defaultMessage: 'Job title',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'placeholder.jobTitle',
                        defaultMessage: 'Job title',
                      })}
                      required
                      name="jobTitle"
                      valuesList={JOB_TITLES}
                      filledStyle
                      disabled
                    />
                  </TooltippedUserControl>
                </Grid>
                <Grid item xs={12}>
                  <TooltippedUserControl
                    tooltipText={
                      <FormattedMessage
                        id="tooltip.email"
                        defaultMessage="Enter email address of the person to be invited. The person will get an email containing a link that can be opened to register here as a new user."
                      />
                    }
                  >
                    <FormikTextField
                      label={intl.formatMessage({
                        id: 'label.email',
                        defaultMessage: 'Invite user',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'placeholder.email',
                        defaultMessage: 'Username',
                      })}
                      required
                      type="email"
                      name="email"
                    />
                  </TooltippedUserControl>
                </Grid>
              </Grid>
              {!isRegularUser && <SubmitButtonsSet saveAndEdit={false} />}
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
  activeProjects: getActiveProjects(state),
});

DriverInvite.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  me: PropTypes.shape({
    isMaster: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
  }).isRequired,
  activeProjects: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default connect(mapStateToProps)(withRouter(DriverInvite));
