import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { connect, getIn } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Collapse from '@material-ui/core/Collapse';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import RegisterContainerTooltipedField from '../../main/routes/containers/routes/register/components/registerContainerTooltipedField';
import {
  formikComponentMemoizeFieldCheck,
  formikInjectedPropsTypes,
  FormikSelect,
  memoizeFields,
} from '../inputs/formik';
import { getActiveProjects } from '../utils/settings';

const SelectProject = ({
  onChange,
  name,
  md,
  formik,
  isProjectOwner,
  availableProjects,
  classes,
}) => {
  const activeProjects = useSelector((state) => getActiveProjects(state));
  const projectsToSelect = isProjectOwner ? availableProjects : activeProjects;

  const { values, setFieldValue } = formik;
  const selectedProject = getIn(values, name);
  const [allProjects, setAllProjects] = useState([]);

  useEffect(() => {
    if (allProjects.length === 1) {
      setFieldValue(name, allProjects[0].value);
    }
  }, [allProjects, name, setFieldValue]);

  useEffect(() => {
    // If user switch project in top bar this will switch it in select
    const projects = projectsToSelect?.map(({ node: activeProject = {} }) => ({
      value: activeProject.id,
      label: activeProject.name,
    }));
    setAllProjects(projects);
    if (projects && selectedProject && projectsToSelect.length) {
      const selectedInAll = projectsToSelect.some(({ node }) => node.id === selectedProject);
      if (!selectedInAll) {
        setFieldValue(name, '');
      }
    }
  }, [projectsToSelect, name, selectedProject, setFieldValue]);

  const intl = useIntl();

  return (
    <Grid item xs={12} md={md} className={classNames({ 'p-0': allProjects.length < 1 }, classes)}>
      <Collapse className="w-100" in={allProjects.length > 1}>
        <RegisterContainerTooltipedField
          tooltipText={
            <FormattedMessage
              id="tooltip.selectedProject"
              defaultMessage="Choose the project to which the this belongs to"
            />
          }
          md={12}
        >
          <FormikSelect
            label={intl.formatMessage({ id: 'label.project', defaultMessage: 'Project' })}
            placeholder={intl.formatMessage({
              id: 'label.placeholder',
              defaultMessage: 'Choose project',
            })}
            required
            filledStyle
            name={name}
            valuesList={allProjects}
            onChange={onChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </RegisterContainerTooltipedField>
      </Collapse>
    </Grid>
  );
};

SelectProject.propTypes = {
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
  onChange: PropTypes.func,
  md: PropTypes.number,
  name: PropTypes.string,
  isProjectOwner: PropTypes.bool,
  classes: PropTypes.arrayOf(PropTypes.string),
  availableProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

SelectProject.defaultProps = {
  name: 'selectedProject',
  onChange: async () => {},
  md: 12,
  isProjectOwner: false,
  availableProjects: [],
  classes: [],
};

export default connect(
  React.memo(SelectProject, memoizeFields(['name', 'md', formikComponentMemoizeFieldCheck()])),
);
