import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: theme.variables.cRichOcean,
    backgroundColor: theme.variables.cWhite,
    borderColor: theme.variables.cRichOcean,
    '&:hover': {
      borderColor: theme.variables.VHBlue,
      backgroundColor: theme.variables.cWhite,
      color: theme.variables.VHBlue,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.variables.cWhite,
      borderColor: theme.variables.VHBlue,
      color: theme.variables.VHBlue,
    },
    '&:focus': {
      boxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    },
  },
}))(Button);

export default StyledButton;
