import { getWasteFractionFromContainer } from '../utils';

export const generateContainers = (containersArray) =>
  containersArray.map(({ node }) => ({
    value: node.id,
    label: node.containerId,
    containerType: (node.containerType || { name: '' }).name,
    wasteType: getWasteFractionFromContainer(node),
  }));

export const generateContainerTypes = (containersTypesArray) =>
  containersTypesArray.reduce((filterOptions, containerType) => {
    if (containerType.id && !filterOptions.find((el) => el.key === containerType.id)) {
      filterOptions.push({
        value: containerType.id,
        label: containerType.name,
        project: containerType.project.id,
      });
    }
    return filterOptions;
  }, []);
