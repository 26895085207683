import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useStyles from '../../shared/styles';
import ImpactDriver from '../../../../../../images/support/sensor_support/equipment/ImpactDriver.png';
import SupportTextPage from '../../shared/supportTextPage';
import StepsContainer from '../../shared/stepsContainer';
import DrillBitTypes from '../../../../../../images/support/sensor_support/equipment/DrillsBitTypes.png';
import RivetGun from '../../../../../../images/support/sensor_support/equipment/RivetGun.jpeg';
import BlindRivets from '../../../../../../images/support/sensor_support/equipment/BlindRivets.png';
import LaserRangeFinder from '../../../../../../images/support/sensor_support/equipment/LaserRangeFinder.png';
import CuttingFluid from '../../../../../../images/support/sensor_support/equipment/CuttingFluid.png';
import Sandpaper from '../../../../../../images/support/sensor_support/equipment/Sandpaper.png';
import CleaningCloth from '../../../../../../images/support/sensor_support/equipment/CleaningCloth.png';
import PlasticSafetyGoggles from '../../../../../../images/support/sensor_support/equipment/PlasticSafetyGoggles.png';
import IsopropylAlcohol from '../../../../../../images/support/sensor_support/equipment/IsopropylAlcohol.png';
import ListStyled from '../../shared/listStyled';

const EquipmentInstallation = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root} alignItems="center">
      <SupportTextPage
        title={
          <FormattedMessage
            id="equipment_for_installation"
            defaultMessage="Equipment for installation"
          />
        }
        texts={[
          <FormattedMessage
            id="equipment_for_installation_text"
            defaultMessage="WasteHero takes no responsibility for the installation and maintenance of the WasteHero device. The abovementioned information is a recommendation only and does not represent any warranties from WasteHero."
          />,
        ]}
      />
      <StepsContainer
        number={1}
        subheader={
          <FormattedMessage
            id="equipment_for_installation_header"
            defaultMessage="Impact Driver or Power Drill"
          />
        }
        image={ImpactDriver}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="equipment_for_installation_item_first"
            defaultMessage="WasteHero recommends using an <bold>Impact Driver</bold>. However, you may also use a <bold>Cordless Drill</bold> or <bold>Standard Drill</bold> (if you have access to electricity)."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="equipment_for_installation_item_second"
            defaultMessage="Impact Drivers are more compact, lightweight and have more torque/twisting force in comparison to a standard drill. You’ll find that an Impact Driver will drill more easily through materials, while preventing the drill bit from slipping off the drilling point."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={2}
        subheader={<FormattedMessage id="drill_bit" defaultMessage="Drill Bit" />}
        image={DrillBitTypes}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="drill_bit_item_first"
            defaultMessage="Drill bit sizes refer to their diameter. For installation of the sensor, you will need a drill bit <bold>4mm in diameter</bold>."
            values={{ bold: (text) => <b>{text}</b> }}
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="drill_bit_item_second"
            defaultMessage="The type of drill bit you need to use depends on the surface you are drilling."
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="drill_bit_item_third"
            defaultMessage="Look through the classifications of the drill bits based on their function to match the surface you are drilling:"
          />
        </Typography>
        <ListStyled
          items={[
            <FormattedMessage
              id="drill_bit_item_fourth"
              defaultMessage="<bold>High-Speed Steel (HSS)</bold> drill bits are used for drilling wood, light metals, fiberglass and PVC."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="drill_bit_item_fifth"
              defaultMessage="<bold>Black oxide-coated (Brad Point bit) drill</bold> bits are more durable than standard HSS bits and the coating helps the drill bit resist rust. These are best for hardwood, softwood, PVC, fiberglass and steel."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="drill_bit_item_sixth"
              defaultMessage="<bold>Titanium-coated drill bits (Multi-Purpose bit)</bold> have reduced friction and require less effort. These are best for hardwood, softwood, PVC, fiberglass and steel."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="drill_bit_item_seventh"
              defaultMessage="<bold>Cobalt drill bits (Countersink bit)</bold> are used for drilling hard metal and steel. They dissipate heat quickly and are highly resistant to abrasion, making them better for drilling into hard metals."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="drill_bit_item_eigth"
              defaultMessage="<bold>Cobalt drill bits (Countersink bit)</bold> are used for drilling hard metal and steel. They dissipate heat quickly and are highly resistant to abrasion, making them better for drilling into hard metals."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
            <FormattedMessage
              id="drill_bit_item_nineth"
              defaultMessage="<bold>Carbide-tipped drill bits (Masonry bit)</bold> stay very sharp over long periods of use and are used mainly for concrete, tile and masonry."
              values={{ bold: (text) => <b>{text}</b> }}
            />,
          ]}
        />
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="drill_bit_item_tenth"
            defaultMessage="Note that you need special hex shank impact rated drill bits to drill with an impact driver."
          />
        </Typography>
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="drill_bit_item_eleventh"
            defaultMessage="Choose the type of drill bit that matches the surface you are drilling. Whichever drill bit you use, the diameter must be 4mm."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={3}
        subheader={<FormattedMessage id="rivet_gun" defaultMessage="Rivet Gun" />}
        image={RivetGun}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="rivet_gun_text"
            defaultMessage="A rivet gun is a type of tool used to drive rivets. Your WasteHero mounting kit will include blind rivets so this is a necessary tool for installation."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={4}
        subheader={<FormattedMessage id="blind_rivets" defaultMessage="Blind Rivets" />}
        image={BlindRivets}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="blind_rivets_item_first"
            defaultMessage="WasteHero supplies the blind rivets used for installation of the sensor in your mounting kit."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={5}
        subheader={
          <FormattedMessage
            id="laser_distance_measure_tape_measure"
            defaultMessage="Laser Distance Measurer or Tape Measure"
          />
        }
        image={LaserRangeFinder}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="laser_distance_measure_tape_measure_text"
            defaultMessage="You'll need to measure the distance from the bottom surface of the container to the installed device. You can always use a tape measure, however for accurate measurements that can be taken with speed you may wish to use a laser distance measurer."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={6}
        subheader={
          <FormattedMessage id="protective_eye_wear" defaultMessage="Protective Eye-wear" />
        }
        image={PlasticSafetyGoggles}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="protective_eye_wear_text"
            defaultMessage="Wear plastic safety goggles to protect your eyes when drilling and riveting. Goggles can be worn over prescription glasses and contact lenses."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={7}
        subheader={<FormattedMessage id="cutting_fluid" defaultMessage="Cutting Fluid" />}
        image={CuttingFluid}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="protective_cutting_fluid_text"
            defaultMessage="We recommend using cutting fluid to carry away the heat generated during drilling in order to reduce damage caused by friction, extend tool life and to improve the surface finish."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={8}
        subheader={<FormattedMessage id="sandpaper" defaultMessage="Sandpaper" />}
        image={Sandpaper}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="sandpaper_text"
            defaultMessage="Sandpaper may be needed to remove foreign material from contaminated surfaces prior to installation. We recommend using 220 grit sandpaper."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={9}
        subheader={<FormattedMessage id="cleaning_cloth" defaultMessage="Cleaning Cloth" />}
        image={CleaningCloth}
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="cleaning_clotht_text"
            defaultMessage="Use an ultra-fine microfiber cloth suitable for cleaning glass and lenses to avoid damaging the sensor and surface area."
          />
        </Typography>
      </StepsContainer>
      <StepsContainer
        number={10}
        subheader={<FormattedMessage id="isopropyl_alcohol" defaultMessage="Isopropyl Alcohol" />}
        image={IsopropylAlcohol}
        checkLast
      >
        <Typography className={classes.textContent} variant="body1" gutterBottom>
          <FormattedMessage
            id="isopropyl_alcohol_text"
            defaultMessage="Installation and ongoing maintenance of the sensor may require some form of cleaning agent. We suggest using isopropyl alcohol as it evaporates quickly and is safe for cleaning contaminated container surfaces as well as the optical glass on the sensors."
          />
        </Typography>
      </StepsContainer>
    </Grid>
  );
};

export default EquipmentInstallation;
