import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { maxLengthStringMessage, messageNoNegativeValues } from '../../../../../shared/utils';
import { TYPES_CHOICES_VALUES } from '../../components/utils';

const MAX_VALUE = 999999;
const maxValueMessage = (
  <FormattedMessage
    id="vechicle.validation.maxValue"
    defaultMessage="Value should be less then - {max}!"
    values={{
      max: MAX_VALUE,
    }}
  />
);

const invalidNumberMessage = (
  <FormattedMessage id="not_valid_number" defaultMessage="Invalid number!" />
);

const vehiclesEditValidationSchema = () =>
  Yup.object().shape({
    licensePlateNumber: Yup.string().max(64, maxLengthStringMessage(64)).required(),
    name: Yup.string().max(64, maxLengthStringMessage(64)).required(),
    Type: Yup.string(),
    manufacturer: Yup.string().max(64, maxLengthStringMessage(64)).required(),
    model: Yup.string().max(64, maxLengthStringMessage(64)).required(),
    manufacturerYear: Yup.string()
      .test(
        '',
        <FormattedMessage
          id="not_valid_year"
          defaultMessage="Not valid year of vehicle manufacture"
        />,
        (value) => Number(value) >= 1970 && Number(value) <= new Date().getFullYear(),
      )
      .required(),
    trim: Yup.string().max(64, maxLengthStringMessage(64)).required(),
    vehicleType: Yup.string().required(),
    vin: Yup.string().label('VIN').length(17, 'VIN must be exactly 17 characters').required(),
    project: Yup.string().required(),
    hours: Yup.array().of(Yup.string()).required(),
    maxPayload: Yup.number().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.number()
        .required()
        .positive(messageNoNegativeValues)
        .test(
          '',
          invalidNumberMessage,
          (value) => Number(value) >= 0 && Number(value) <= MAX_VALUE,
        ),
      otherwise: Yup.number().notRequired(),
    }),
    cargoVolume: Yup.number().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.number()
        .required()
        .positive(messageNoNegativeValues)
        .test(
          '',
          invalidNumberMessage,
          (value) => Number(value) >= 0 && Number(value) <= MAX_VALUE,
        ),
      otherwise: Yup.number().notRequired(),
    }),
    status: Yup.string().required(),
    daysAvailable: Yup.array().of(Yup.string()).required(),
    wasteStations: Yup.array().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.array().of(Yup.string()).required(),
      otherwise: Yup.array().notRequired(),
    }),
    depots: Yup.string().required(),
    fuelTankCapacity: Yup.array()
      .transform((fuelTankCapacity) => fuelTankCapacity.filter((el) => el !== ''))
      .of(Yup.number().positive(messageNoNegativeValues))
      .test('', invalidNumberMessage, (value) => Number(value) >= 0 && Number(value) <= MAX_VALUE),
    fuelType: Yup.string(),
    fuelCostPerKm: Yup.number().positive(messageNoNegativeValues).max(MAX_VALUE, maxValueMessage),
    fuelConsumption: Yup.number().positive(messageNoNegativeValues).max(MAX_VALUE, maxValueMessage),
    fuelCostPerLtr: Yup.number().positive(messageNoNegativeValues).max(MAX_VALUE, maxValueMessage),
    fixedCost: Yup.number().positive(messageNoNegativeValues).max(MAX_VALUE, maxValueMessage),
    vehicleWasteCapacity: Yup.array().when('Type', {
      is: TYPES_CHOICES_VALUES.collector,
      then: Yup.array()
        .of(
          Yup.object().shape({
            wasteFraction: Yup.string().required(),
            maxAllowedVolume: Yup.number()
              .required()
              .positive(messageNoNegativeValues)
              .test(
                '',
                invalidNumberMessage,
                (value) => Number(value) >= 0 && Number(value) <= MAX_VALUE,
              ),
            maxAllowedWeight: Yup.number()
              .required()
              .positive(messageNoNegativeValues)
              .test(
                '',
                invalidNumberMessage,
                (value) => Number(value) >= 0 && Number(value) <= MAX_VALUE,
              ),
          }),
        )
        .required(),
      otherwise: Yup.array().notRequired(),
    }),
  });

export default vehiclesEditValidationSchema;
