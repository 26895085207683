import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from '../../shared/styles';
import StepOneImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_1_open_app.png';
import StepTwoImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_2_login.png';
import StepThreeImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_3_select_project.png';
import StepFourImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_4_register.png';
import StepFiveImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_5_scan_device.png';
import StepSixImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_6_assign_device.png';
import StepSevenImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_7_choose_container_type.png';
import StepEightImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_8_choose_waste_fraction.png';
import StepNineImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_9_measurement_settings.png';
import StepTenImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_10_container_settings.png';
import StepElevenImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_1/step_11_successfully_added.png';
import SectionTwoStepThreeImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_2/section_2_step_3_select_project.png';
import SectionTwoStepFourImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_2/section_2_step_4_register_sensor.png';
import SectionTwoStepSixImage from '../../../../../../images/support/app_support/scan_activate_sensor/section_2/section_2_step_6_assign_sensor.png';
import SectionTwoStepSeven from '../../../../../../images/support/app_support/scan_activate_sensor/section_2/section_2_step_7.png';
import SectionTwoStepEight from '../../../../../../images/support/app_support/scan_activate_sensor/section_2/section_2_step_8.png';
import StepsContainer from '../../shared/stepsContainer';
import FaqContainer from '../../shared/faqContainer';
import ImageContainer from './components/imageContainer';
import QrCode from '../../../../../../images/support/app_support/logos/QR_Code_App_Install.png';

const AppScanActivateSensor = () => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Grid container spacing={1} className={classes.root} alignItems="center">
      {/* Section 1 */}

      <Grid item xs={12}>
        <FaqContainer
          title={
            <FormattedMessage
              id="app_scan_activate_pagetitle"
              defaultMessage="Scanning and activation of sensor"
            />
          }
          text={[
            <FormattedMessage
              id="app_scan2_section_1a_text"
              defaultMessage="The easiest way to scan and activate your sensors is by downloading the WasteHero Installation app on your smartphone."
            />,
          ]}
          objects={[
            {
              header: (
                <FormattedMessage
                  id="app_scan_activate_content_section_1_title"
                  defaultMessage="1. Scanning and activating your WasteHero sensor with a new container"
                />
              ),
              texts: [
                <StepsContainer
                  number={1}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_1_subTitle"
                      defaultMessage="Open the WasteHero App"
                    />
                  }
                  image={StepOneImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_1_content_1"
                      defaultMessage="Haven’t downloaded the app? Download the smartphone app from the <appstoreLink>Apple Store</appstoreLink> or <playstoreLink>Google Play</playstoreLink>."
                      values={{
                        appstoreLink: (text) => (
                          <Link
                            className={classes.links}
                            href="https://apps.apple.com/au/app/wastehero-installed/id1498769501"
                            target="_blank"
                          >
                            {text}
                          </Link>
                        ),
                        playstoreLink: (text) => (
                          <Link
                            className={classes.links}
                            href="https://play.google.com/store/apps/details?id=com.wastehero_mobileapp_installation"
                            target="_blank"
                          >
                            {text}
                          </Link>
                        ),
                      }}
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_1_content_2"
                      defaultMessage="Alternatively, scan this QR code from your smartphone:"
                    />
                  </Typography>
                  <ImageContainer>
                    <img
                      src={QrCode}
                      alt={intl.formatMessage({ id: 'qr.code', defaultMessage: 'QR Code' })}
                    />
                  </ImageContainer>
                </StepsContainer>,
                <StepsContainer
                  number={2}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_2_subTitle"
                      defaultMessage="Login with your credentials"
                    />
                  }
                  image={StepTwoImage}
                >
                  <Typography className={classes.textBold} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_2_content_1"
                      defaultMessage="Username"
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_2_content_2"
                      defaultMessage="The email address you used to create your account on the platform, e.g. yourname@company.com"
                    />
                  </Typography>
                  <Typography className={classes.textBold} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_2_content_3"
                      defaultMessage="Password"
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_2_content_4"
                      defaultMessage="Type your password"
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={3}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_4_subTitle"
                      defaultMessage="Select Project"
                    />
                  }
                  image={StepThreeImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_4_content_1"
                      defaultMessage="Select the <bold>Project</bold> that you would like to assign the sensor to and hit <bold>Apply</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={4}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_3_subTitle"
                      defaultMessage="Select Register Sensor"
                    />
                  }
                  image={StepFourImage}
                />,
                <StepsContainer
                  number={5}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_5_subTitle"
                      defaultMessage="Scan the sensor"
                    />
                  }
                  image={StepFiveImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_5_content_1"
                      defaultMessage="Scan the sensor using the NFC tag, the QR code, or manually type in the Sensor ID."
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_5_content_2"
                      defaultMessage="<bold>For scanning via NFC</bold>, hold your NFC enabled smartphone on the QR code (the NFC tag is located behind the label). Press the orange icon on the app to scan the NFC tag."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_5_content_3"
                      defaultMessage="<bold>For scanning via QR code</bold>, switch to the QR tab in the app and position the camera over the QR code."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_5_content_4"
                      defaultMessage="<bold>For scanning via Sensor ID</bold>, simply type the Sensor ID located on the label of the sensor into the Sensor ID field on the app. Select Enter to confirm."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={6}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_6_subTitle"
                      defaultMessage="Assign the sensor."
                    />
                  }
                  image={StepSixImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_6_content_1"
                      defaultMessage="Select <bold>+ Attach to new container</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={7}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_7_subTitle"
                      defaultMessage="Choose container type"
                    />
                  }
                  image={StepSevenImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_7_content_1"
                      defaultMessage="Choose the <bold>container type</bold> from your predefined list that the device will be installed within."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={8}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_8_subTitle"
                      defaultMessage="Choose Waste fraction"
                    />
                  }
                  image={StepEightImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_8_content_1"
                      defaultMessage="Choose the <bold>waste fraction</bold> type of the container from your predefined list."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={9}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_9_subTitle"
                      defaultMessage="Choose Measurement Setting"
                    />
                  }
                  image={StepNineImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_9_content_1"
                      defaultMessage="Choose the <bold>measurement setting</bold> for the sensor from your predefined list."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={10}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_10_subTitle"
                      defaultMessage="Enter Container Settings"
                    />
                  }
                  image={StepTenImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_10_content_1"
                      defaultMessage="Enter the Container ID and a description."
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_10_content_2"
                      defaultMessage="Tap set container location – type in the container address in the search bar and a red pin will drop to the given location. 
                      You can also move the map to find the correct location, then tap to drop the pin. Once you have the correct location, click <bold>Select Location</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_10_content_3"
                      defaultMessage="Choose where the sensor will be placed within the container; on the top or side of the container. 
                      Enter the distance from where the sensor will be installed to the bottom surface of the container. "
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_10_content_4"
                      defaultMessage="If your sensor will be installed on the side of the container, you will also need to enter the angle of the sensor (e.g. 90 degrees if you are installing flat on the side of the container)."
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_10_content_5"
                      defaultMessage="Take a photo of the container (optional)."
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_10_content_6"
                      defaultMessage="Click <bold>Create</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={11}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_11_subTitle"
                      defaultMessage="Successfully added sensor"
                    />
                  }
                  image={StepElevenImage}
                  checkLast
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_step_11_content_1"
                      defaultMessage="The device has been successfully added when you reach this success message. Click <bold>Done</bold>. That's it - you're done! You are now ready to start optimising your waste management. "
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
              ],
            },

            /* Section 2 */

            {
              header: (
                <FormattedMessage
                  id="app-scan_activate_section_2_content_step_1_title"
                  defaultMessage="2. Scanning and activating your WasteHero sensor with an existing container "
                />
              ),
              texts: [
                <StepsContainer
                  number={1}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_step_1_subTitle"
                      defaultMessage="Open the WasteHero Installation App"
                    />
                  }
                  image={StepOneImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_1_content_1"
                      defaultMessage="Haven’t downloaded the app? Download the smartphone app from the <appstoreLink>Apple Store</appstoreLink> or <playstoreLink>Google Play</playstoreLink>."
                      values={{
                        appstoreLink: (text) => (
                          <Link
                            className={classes.links}
                            href="https://apps.apple.com/au/app/wastehero-installed/id1498769501"
                            target="_blank"
                          >
                            {text}
                          </Link>
                        ),
                        playstoreLink: (text) => (
                          <Link
                            className={classes.links}
                            href="https://play.google.com/store/apps/details?id=com.wastehero_mobileapp_installation"
                            target="_blank"
                          >
                            {text}
                          </Link>
                        ),
                      }}
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_1_content_2"
                      defaultMessage="Alternatively, scan this QR code from your smartphone:"
                    />
                  </Typography>
                  <ImageContainer>
                    <img
                      src={QrCode}
                      alt={intl.formatMessage({ id: 'qr.code', defaultMessage: 'QR Code' })}
                    />
                  </ImageContainer>
                </StepsContainer>,
                <StepsContainer
                  number={2}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_1_content_1"
                      defaultMessage="Login with your credentials"
                    />
                  }
                  image={StepTwoImage}
                >
                  <Typography className={classes.textBold} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_2_content_1"
                      defaultMessage="Username"
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_2_content2"
                      defaultMessage="The email address you used to create your account on the platform, e.g. yourname@company.com"
                    />
                  </Typography>
                  <Typography className={classes.textBold} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_2_content3"
                      defaultMessage="Password"
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_2_content4"
                      defaultMessage="Type your password"
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={3}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_3_subTitle"
                      defaultMessage="Select Project"
                    />
                  }
                  image={SectionTwoStepFourImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_3_content_1"
                      defaultMessage="Select the <bold>Project</bold> that you would like to assign the sensor to and hit <bold>Apply</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={4}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_4_subTitle"
                      defaultMessage="Select Register Sensor"
                    />
                  }
                  image={SectionTwoStepThreeImage}
                />,
                <StepsContainer
                  number={5}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_5_subTitle"
                      defaultMessage="Scan the device"
                    />
                  }
                  image={StepFiveImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_5_content_1"
                      defaultMessage="Scan the sensor using the NFC tag, the QR code, or manually type in the Sensor ID."
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_5_content_2"
                      defaultMessage="<bold>For scanning via NFC</bold>, hold your NFC enabled smartphone on the QR code (the NFC tag is located behind the label). Press the orange icon on the app to scan the NFC tag."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_5_content_3"
                      defaultMessage="<bold>For scanning via QR code</bold>, switch to the QR tab in the app and position the camera over the QR code."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_5_content_4"
                      defaultMessage="<bold>For scanning via Sensor ID</bold>, simply type the Sensor ID located on the label of the sensor into the Sensor ID field on the app. Select Enter to confirm."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={6}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_6_subTitle"
                      defaultMessage="Assign the sensor"
                    />
                  }
                  image={SectionTwoStepSixImage}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_6_content_1"
                      defaultMessage="Select <bold>Attach to new container</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={7}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_7_subTitle"
                      defaultMessage="Choose Container"
                    />
                  }
                  image={SectionTwoStepSeven}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_7_content_1"
                      defaultMessage="Choose a container from the list, or search for a container by its ID or location. Select the desired container from the list."
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={8}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_9_subTitle"
                      defaultMessage="Edit Sensor Settings"
                    />
                  }
                  image={SectionTwoStepEight}
                >
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_9_content_1"
                      defaultMessage="Choose where the sensor will be placed within the container; on the top or side of the container. 
                      Enter the distance from where the sensor will be installed to the bottom surface of the container."
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_9_content_2"
                      defaultMessage="If your sensor will be installed on the side of the container, you will also need to enter the angle of the sensor (e.g. 90 degrees if you are installing flat on the side of the container)."
                    />
                  </Typography>
                  <Typography className={classes.textContent} variant="body1" gutterBottom>
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_9_content_3"
                      defaultMessage="Click <bold>Add Sensor</bold>."
                      values={{ bold: (text) => <b>{text}</b> }}
                    />
                  </Typography>
                </StepsContainer>,
                <StepsContainer
                  number={9}
                  subheader={
                    <FormattedMessage
                      id="app_scanactivate_content_section_2_step_10_subTitle"
                      defaultMessage="Successfully added device"
                    />
                  }
                  image={StepElevenImage}
                  checkLast
                >
                  <FormattedMessage
                    id="app_scanactivate_content_section_2_step_10_content_1"
                    defaultMessage="The device has been successfully added when you reach this success message. Click <bold>Done</bold>. 
                    That's it - you're done! You are now ready to start optimising your waste management."
                    values={{ bold: (text) => <b>{text}</b> }}
                  />
                </StepsContainer>,
              ],
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default AppScanActivateSensor;
