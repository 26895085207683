import React, { useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useSelector, connect } from 'react-redux';
import InviteButton from '../../components/inviteButton';
import SettingsButton from '../../components/settingsButton';
import FilledSelect from '../../../../../shared/inputs/FilledSelect';
import { STATUSES, STATUSES_VALUES, TYPES_CHOICES_VALUES } from '../../components/utils';
import ListPageTemplate from '../../components/listPageTemplate';
import StyledStatus from '../../components/styledStatus';
import { StyledListLink } from '../../components/linkHoverStyle';
import withUserLayoutConfig from '../../../../../shared/hoc/WithUserLayoutConfig';
import ColumnFilter from '../../../../../shared/table/columnFilter';
import {
  driversTableColumns,
  getDriversTableFilterableColumns,
} from '../../../../../shared/utils/constants';
import useColumnFilter from '../../../../../shared/hooks/useColumnFilter';
import { getSavedActiveProjects, saveCacheRead } from '../../../../../shared/utils';
import useIsDriver from '../../hooks/useIsDriver';
import useIsRegularUser from '../../hooks/useIsRegularUser';

const allDriversQuery = loader(
  './../../../../../graphql/queries/fleet_management/all_drivers.graphql',
);
const deleteDriverQuery = loader(
  './../../../../../graphql/mutations/fleet_management/delete_driver.graphql',
);
const filterDriversQuery = loader(
  './../../../../../graphql/queries/fleet_management/filter_drivers.graphql',
);

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    height: '100%',
  },
  select: {
    textAlign: 'center',
  },
}));

const driverAutocompleteSerializer = ({ allDrivers: { edges: arr = [] } = {} }) =>
  arr.map(({ node }) => ({
    primaryText: `${node.user.firstName} ${node.user.lastName}`,
    secondaryText: node.vehicle?.name,
    logo: node.user.logo,
    id: node.id,
  }));

const DriverList = ({ userLayoutConfig, handleConfigChange, me }) => {
  const savedActiveProjects = useSelector((state) => getSavedActiveProjects(state));
  const classes = useStyles();
  const intl = useIntl();
  const [driverFilter, setDriverFilter] = useState('');

  const driverSerializer = ({ allDrivers: { edges: arr = [], totalCount = 0 } = {} }) => ({
    totalCount,
    items: arr.map(({ node }) => ({
      name: `${node.user.firstName} ${node.user.lastName} `,
      status: <StyledStatus status={node.status} />,
      collectedBins: node.collectedBins,
      logo: node.user.logo,
      id: node.id,
      vehicle: (
        <StyledListLink
          to={`/app/fleet-management/${
            node.vehicle?.vehicleType?.Type === TYPES_CHOICES_VALUES.streetSweeper
              ? 'street-sweeper-vehicle'
              : 'collector-vehicle'
          }/${node.vehicle?.id}`}
          text={node.vehicle?.name}
        />
      ),
      allowActions: me.ownProjectsIds.includes(node.vehicle?.project?.id),
    })),
  });

  const queryExtraVariables = useMemo(
    () => ({
      status: driverFilter === STATUSES_VALUES.all ? null : driverFilter,
      activeProjects: savedActiveProjects,
    }),
    [driverFilter, savedActiveProjects],
  );

  const [activeTableColumns, handleFilterColumns] = useColumnFilter(
    driversTableColumns,
    userLayoutConfig,
    handleConfigChange,
  );

  const [deleteDriverMutation] = useMutation(deleteDriverQuery, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: allDriversQuery }],
  });

  const isDriver = useIsDriver();
  const isRegularUser = useIsRegularUser(isDriver);

  const extraFilter = useMemo(
    () => (
      <>
        <Grid item xs={6}>
          <FilledSelect
            name="location_type"
            placeholder={intl.formatMessage({ id: 'status', defaultMessage: 'Driver statuses' })}
            valuesList={STATUSES}
            required
            className={classes.select}
            value={driverFilter}
            onChange={(e) => setDriverFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={5} tourid="driverSettings">
          <SettingsButton
            text={<FormattedMessage id="invite.driver" defaultMessage="Driver settings" />}
            isRegularUser={isRegularUser}
          />
        </Grid>
      </>
    ),
    [driverFilter, intl, classes.select, isRegularUser],
  );

  const cellsConfig = [
    !isDriver && {
      id: 'actionMenu',
      noFilter: true,
      label: (
        <ColumnFilter
          tableColumns={getDriversTableFilterableColumns(intl)}
          activeTableColumns={activeTableColumns}
          handleConfigChange={handleConfigChange}
          userLayoutConfig={userLayoutConfig}
          handleFilterColumns={handleFilterColumns}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      label: <FormattedMessage id="location_list.name" defaultMessage="Name" />,
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'status',
      label: <FormattedMessage id="driver_list.status" defaultMessage="Status" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'collectedBins',
      label: <FormattedMessage id="driver_list.collectedBins" defaultMessage="Collected bins" />,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'vehicle',
      label: <FormattedMessage id="driver_list.vehicle_id" defaultMessage="Vehicle ID" />,
      numeric: false,
      disablePadding: false,
    },
  ];

  const updateCache = useCallback(
    (driver) => async (cache) => {
      const allDriversFullQuery = {
        query: allDriversQuery,
        variables: {
          status: driverFilter === STATUSES_VALUES.all ? null : driverFilter,
          activeProjects: savedActiveProjects,
        },
      };

      const {
        data: { allDrivers: allDriversData },
      } = await saveCacheRead(allDriversFullQuery);

      const newDriversData = {
        ...allDriversData,
        edges: allDriversData.edges.filter(({ node }) => node.id !== driver.id),
      };

      cache.writeQuery({
        ...allDriversFullQuery,
        data: {
          allDrivers: newDriversData,
        },
      });
    },
    [savedActiveProjects, driverFilter],
  );

  return (
    <ListPageTemplate
      userLayoutConfig={userLayoutConfig}
      updateUserConfig={handleConfigChange}
      cellsConfig={cellsConfig}
      autocompleteSerializer={driverAutocompleteSerializer}
      autoCompleteQuery={filterDriversQuery}
      queryExtraVariables={queryExtraVariables}
      extraFilter={extraFilter}
      deleteItemMutation={deleteDriverMutation}
      query={allDriversQuery}
      updateCache={updateCache}
      itemsSerializer={driverSerializer}
      pageTitle={intl.formatMessage({ id: 'drivers.list', defaultMessage: 'Driver list' })}
      endAdornment={
        <Grid className="h-70" item lg={2} xs={5} tourid="inviteDriver">
          <InviteButton
            text={<FormattedMessage id="drivers.invite" defaultMessage="Invite driver" />}
          />
        </Grid>
      }
      activeTableColumns={activeTableColumns}
      disableSelect
    />
  );
};

DriverList.propTypes = {
  handleConfigChange: PropTypes.func.isRequired,
  userLayoutConfig: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    iconsSize: PropTypes.string.isRequired,
    disabledColumns: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  me: PropTypes.shape({
    ownProjectsIds: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isMaster: PropTypes.bool,
    isReseller: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  me: state.settings.user,
});

export default connect(mapStateToProps)(withUserLayoutConfig('driver')(DriverList));
