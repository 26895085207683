import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import alexandreBonde from '../../../images/hussam.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  img: {
    marginRight: 'auto',
    width: '100%',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 20,
  },
  text: {
    textAlign: 'center',
    margin: 0,
    fontWeight: 500,
    fontSize: 15,
  },
  button: {
    marginBottom: 20,
    borderRadius: 50,
    border: '4 solid inherit',
    color: 'white',
    textTransform: 'none',
  },
}));

const ContactInfoDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClickOpen}
        className={classes.button}
      >
        <FormattedMessage id="upgrade_platform" defaultMessage="Upgrade now" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleClose();
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>
            <FormattedMessage id="contact_us" defaultMessage="Contact us" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0} justify="center" alignItems="center" direction="column">
            <Grid item xs={12}>
              <img src={alexandreBonde} alt="Hussam Mansour" className={classes.img} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>
                <FormattedMessage
                  id="sales_person_contact_hussam"
                  defaultMessage="Hussam Mansour"
                />
              </Typography>
              <Typography className={classes.text}>
                <FormattedMessage id="direct_of_sales_hussam" defaultMessage="CEO & Founder" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>
                <FormattedMessage
                  id="email_of_sales_person_hussam"
                  defaultMessage="<bold>E-mail:</bold> hmansour@wastehero.io"
                  values={{
                    bold: (text) => <b style={{ color: '#809298' }}>{text}</b>,
                  }}
                />
              </Typography>
              <Typography className={classes.text}>
                <FormattedMessage
                  id="phone_number_hussam"
                  defaultMessage="<bold>Phone:</bold> +45 60 51 55 34"
                  values={{
                    bold: (text) => <b style={{ color: '#809298' }}>{text}</b>,
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="ok" defaultMessage="ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ContactInfoDialog;
