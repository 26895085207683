import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { JOB_TITLE_VALUES } from '../../../../shared/utils/constants';

const checkDriverMayEditQuery = loader(
  '../../../../graphql/queries/fleet_management/check_driver_may_edit.graphql',
);

export default function useMayDriverEdit() {
  const [userCanEdit, setUserCanEdit] = useState(true);
  const isDriver = useSelector(
    (state) => state.settings.user.jobtitle.title === JOB_TITLE_VALUES.driver,
  );
  const [checkDriverMayEdit] = useLazyQuery(checkDriverMayEditQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ isDriverEditable }) => setUserCanEdit(isDriverEditable.canEdit),
  });

  useEffect(() => {
    if (isDriver) {
      checkDriverMayEdit();
    }
  }, [isDriver, checkDriverMayEdit]);

  return userCanEdit;
}
