import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Redirect } from 'react-router-dom';
import qs from 'qs';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useApolloClient, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';
import { setUser } from '../../../actions';
import styles from '../styles';
import NotLoginDefaultPageLayout from '../../layout/notLoginDefaultPageLayout';
import { toastifyError } from '../../shared/utils';
import LoadingLayout from '../../shared/loading';
import { FormikTextField, FormikCheckbox } from '../../shared/inputs/formik';

const checkTokenMutation = loader('./../../graphql/mutations/core/check_token.graphql');
const logoutMutation = loader('./../../graphql/mutations/core/logout.graphql');
const loginMutation = loader('./../../graphql/mutations/core/login.graphql');
const meType = loader('./../../graphql/queries/core/me.graphql');

const Login = ({ location, classes, handleSetUser, history }) => {
  const [notification, setNotification] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const intl = useIntl();

  const [loginMutationQuery, { loading: loginLoading, error }] = useMutation(loginMutation);
  const [checkTokenMutationQuery, { loading: checkTokenLoading }] = useMutation(checkTokenMutation);
  const [logoutMutationQuery, { loading: logoutLoading }] = useMutation(logoutMutation);
  const client = useApolloClient();

  const isLoading = loginLoading || checkTokenLoading || logoutLoading;
  const { checkEmail, newPass } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (checkEmail) {
      setNotification(
        <FormattedMessage id="notification.check_email" defaultMessage="Please check your email" />,
      );
    } else if (newPass) {
      setNotification(
        <FormattedMessage
          id="notification.use_new_password"
          defaultMessage="Now you can use your new password!"
        />,
      );
    }
  }, [checkEmail, newPass]);

  useEffect(() => {
    const currentReactQLJWTToken = window.localStorage.getItem('reactQLJWT');
    const rememberMe = window.localStorage.getItem('rememberMe');

    if (rememberMe === 'true') {
      if (currentReactQLJWTToken) {
        checkTokenMutationQuery({
          variables: {
            token: currentReactQLJWTToken,
          },
        })
          .then(({ data: { verifyToken = null } = {} }) => {
            if (verifyToken) {
              window.localStorage.removeItem('reactQLJWT');
            } else {
              setRedirectToReferrer(true);
            }
          })
          .catch(() => {
            window.localStorage.removeItem('reactQLJWT');
          });
      } else {
        window.localStorage.removeItem('reactQLJWT');
      }
    } else {
      logoutMutationQuery({}).then(() => {
        window.localStorage.removeItem('reactQLJWT');
      });
    }
  }, [logoutMutationQuery, checkTokenMutationQuery]);

  const handleCloseNotification = () => {
    setNotification('');
  };

  const getMe = async () => {
    await client.query({ query: meType }).then((response) => {
      if (response.data && response.data.me) {
        handleSetUser(response.data.me);
        if (!response.data.me.company) {
          history.push('/company/create/');
        }
      }
    });
  };

  const tryLogin = async ({ username, password, rememberMe }) => {
    await client.resetStore();
    try {
      const {
        data: { tokenAuth },
      } = await loginMutationQuery({
        variables: {
          username,
          password,
        },
      });

      window.localStorage.setItem('reactQLJWT', tokenAuth.token);
      window.localStorage.setItem('rememberMe', rememberMe);
      await getMe();

      setRedirectToReferrer(true);
    } catch (e) {
      toastifyError(e);
      throw e;
    }
  };

  const { from } = location.state || { from: { pathname: '/app/dashboard' } };
  if (redirectToReferrer === true) {
    return <Redirect to={from} />;
  }

  return (
    <NotLoginDefaultPageLayout loading={isLoading}>
      <Grid container justify="center" alignItems="center" className="h-100">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!notification}
          onClick={handleCloseNotification}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{notification}</span>}
        />
        <Formik
          initialValues={{
            username: '',
            password: '',
            rememberMe: false,
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required(),
            password: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting }) =>
            tryLogin(values).catch(() => {
              window.localStorage.removeItem('reactQLJWT');
              setSubmitting(false);
            })
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.wrap}>
              <Grid container justify="center">
                <Grid item xs={12} md={9} lg={7} className={classes.paddedRow}>
                  <Typography variant="h4" align="center" className={classes.signInText}>
                    <FormattedMessage id="pageName.signIn" defaultMessage="Sign in" />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} lg={7} className={classes.paddedRow}>
                  <FormikTextField
                    name="username"
                    type="text"
                    InputProps={{
                      autoComplete: 'current-username',
                    }}
                    label={intl.formatMessage({
                      id: 'signIn.label.username',
                      defaultMessage: 'Username',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={7}>
                  <FormikTextField
                    type="password"
                    name="password"
                    InputProps={{
                      autoComplete: 'current-password',
                    }}
                    label={intl.formatMessage({
                      id: 'signIn.label.password',
                      defaultMessage: 'Password',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={7}>
                  <div className={classes.submitRow}>
                    <div className={classes.rememberMe}>
                      <FormikCheckbox name="rememberMe" className={classes.checkbox} />
                      <FormattedMessage id="checkbox.remember_me" defaultMessage="Remember Me" />
                    </div>
                    <Link to="/forgot-password" className={classes.forgotPassword}>
                      <FormattedMessage
                        id="link.forgot_your_password"
                        defaultMessage="Forgot your password?"
                      />
                    </Link>
                  </div>
                  {error && <div className={classes.errorMessage}>{error?.message?.slice(15)}</div>}
                </Grid>
                <Grid item container xs={12} md={9} lg={7} justify="center">
                  <Grid item xs={12} md={6}>
                    <Button
                      data-testid="submit-login-button"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.submitBtn}
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      <FormattedMessage id="button.sign_in" defaultMessage="SIGN IN" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <LoadingLayout isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Grid>
    </NotLoginDefaultPageLayout>
  );
};

Login.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  handleSetUser: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  handleSetUser: (user) => {
    dispatch(setUser(user));
  },
});

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Login));
