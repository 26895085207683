import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import { connect, getIn } from 'formik';
import useStyles from './styles';
import formikInjectedPropsTypes from './formikPropTypes';
import { formikFieldMemoizeCheck, memoizeFields } from './memoraize';

const BaseFormikInput = ({
  name,
  required,
  id,
  className,
  onChange,
  children,
  filledStyle,
  formik,
}) => {
  const classes = useStyles({ backgroundColor: 'cAntiFlashWhite' });
  const isTouched = getIn(formik.touched, name);
  const isError = getIn(formik.errors, name) && isTouched;
  const value = getIn(formik.values, name);
  const [prevValue, setPrevValue] = useState(false);

  const { setFieldTouched, setFieldValue } = formik;

  useEffect(() => {
    let canceled;
    if (value !== prevValue && !canceled) {
      onChange(value);
      setPrevValue(value);
    }
    return () => {
      canceled = true;
    };
  }, [prevValue, onChange, value]);

  const inputId = id || `formikSelect${name}`;

  return (
    <FormControl
      required={required}
      className={classNames(classes.formControl, className, { [classes.input]: filledStyle })}
    >
      {children({
        isError: !!isError,
        inputId,
        classes,
        isTouched,
        value,
        setFieldValue,
        setFieldTouched,
      })}
    </FormControl>
  );
};

BaseFormikInput.propTypes = {
  required: PropTypes.bool,
  filledStyle: PropTypes.bool,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.func.isRequired,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

BaseFormikInput.defaultProps = {
  required: false,
  filledStyle: false,
  className: '',
  id: '',
  onChange: () => {},
};

export default connect(
  React.memo(
    BaseFormikInput,
    memoizeFields([
      'name',
      'filledStyle',
      'required',
      'className',
      'onChange',
      'children',
      'id',
      ...formikFieldMemoizeCheck(),
    ]),
  ),
);
