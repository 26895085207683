import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import useStyles from '../../shared/styles';
import SupportTextPage from '../../shared/supportTextPage';

const NavOverview = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      <SupportTextPage
        title={<FormattedMessage id="app_overview_title" defaultMessage="Navigation Overview" />}
        texts={[
          <FormattedMessage
            id="app_overview_content_1"
            defaultMessage="The WasteHero mobile application is used to activate sensors and pair them to containers. To activate the sensor the mobile must support NFC."
          />,
          <FormattedMessage
            id="app_overview_content_2"
            defaultMessage="The application is directly connected to the WasteHero platform. This means that the application can only be used if the user already has an account and an assigned project on the WasteHero platform."
          />,
        ]}
      />
    </Grid>
  );
};

export default NavOverview;
