import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { connect, getIn } from 'formik';
import Button from '@material-ui/core/Button';
import { loader } from 'graphql.macro';
import NoItemsForSelect from './noItemsForSelect';
import MultiRowSelect from './multiRowSelect';
import {
  formikComponentMemoizeFieldCheck,
  formikInjectedPropsTypes,
  memoizeFields,
} from '../inputs/formik';
import CreateSkillsDialog from './createSkillDialog';

const allSkills = loader('./../../graphql/queries/fleet_management/all_skills.graphql');

const SelectOption = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0.75, 2, 0),
    textTransform: 'none',
    textAlign: 'left',
    justifyContent: 'flex-start',
    fontWeight: 400,
    fontSize: '1rem',
  },
}))(Button);

const addNewSkill = 'Add new skill';

const MultiSelectDriverSkill = ({
  inputClassName,
  name,
  extraInputs,
  defaultValue,
  serializer,
  formik,
}) => {
  const intl = useIntl();
  const value = getIn(formik.values, name);
  const [addSkillDialogOpen, setAddSkillDialogOpen] = useState(false);
  const [newSkillToField, setNewSkillToField] = useState('');

  const { setFieldValue } = formik;

  const { loading, data: { allSkills: { edges: skills = [] } = {} } = {}, refetch } = useQuery(
    allSkills,
    {
      suspend: false,
      notifyOnNetworkStatusChange: true,
    },
  );

  const serializedSkills = useMemo(
    () =>
      skills.map(({ node: skill }) => ({
        value: skill.id,
        label: `${skill.code}: ${skill.name}`,
      })),
    [skills],
  );

  useEffect(() => {
    const addNewSkillIndex = value.findIndex(({ skillId }) => addNewSkill === skillId);
    if (addNewSkillIndex !== -1) {
      const fieldId = `${name}[${addNewSkillIndex}].skillId`;
      setFieldValue(fieldId, '');
      setNewSkillToField(fieldId);
      setAddSkillDialogOpen(true);
    }
  }, [setFieldValue, value, defaultValue, name]);

  const noItems = useMemo(
    () => (
      <NoItemsForSelect
        link="/app/settings/"
        message={<FormattedMessage id="no_skills" defaultMessage="No skills registered." />}
        linkText={<FormattedMessage id="add_skill" defaultMessage="Click to add new skill." />}
      />
    ),
    [],
  );

  return (
    <>
      <MultiRowSelect
        getIdFromFormikValue={serializer}
        getItem={(id) => skills.find(({ node }) => node.id === id)?.node}
        required={false}
        inputClassName={inputClassName}
        defaultValue={defaultValue}
        noItems={noItems}
        label={intl.formatMessage({
          id: 'multiSkillsSelect.label.driverSkills',
          defaultMessage: 'Skills',
        })}
        placeholder={intl.formatMessage({
          id: 'multiSkillsSelect.placeholder.driverSkills',
          defaultMessage: 'Select driver skill',
        })}
        loading={loading}
        valuesList={[
          ...serializedSkills,
          {
            value: addNewSkill,
            label: (
              <SelectOption>
                <FormattedMessage id="add_new_skill" defaultMessage="+ Add new skill" />
              </SelectOption>
            ),
            className: 'p-0',
          },
        ]}
        name={name}
        fieldName="skillId"
        renderRow={extraInputs}
      />
      <CreateSkillsDialog
        open={addSkillDialogOpen}
        onClose={useCallback(
          (newSkillId) => {
            if (newSkillId) {
              refetch().then(() => {
                setFieldValue(newSkillToField, newSkillId);
                setAddSkillDialogOpen(false);
              });
            } else {
              setFieldValue(newSkillToField, '');
              setAddSkillDialogOpen(false);
            }
          },
          [newSkillToField, refetch, setFieldValue],
        )}
      />
    </>
  );
};

MultiSelectDriverSkill.propTypes = {
  inputClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  extraInputs: PropTypes.func,
  serializer: PropTypes.func,
  formik: PropTypes.shape(formikInjectedPropsTypes).isRequired,
};

MultiSelectDriverSkill.defaultProps = {
  inputClassName: '',
  defaultValue: '',
  serializer: (val) => val,
};

export default connect(
  React.memo(
    MultiSelectDriverSkill,
    memoizeFields([
      'inputClassName',
      'name',
      'defaultValue',
      'extraInputs',
      'serializer',
      formikComponentMemoizeFieldCheck(),
    ]),
  ),
);
